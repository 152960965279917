import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatRipple } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService, ConfigurationService, VentasService, ComprasService, GeneralService, AlmacenService, ContabilidadService } from 'src/app/services';
import { formatDateWithDash } from 'src/app/utils/formats/date.format';
import { Store } from '@ngrx/store';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';
import { DescuentosPD } from 'src/app/models/ventas/order';
import { forkObs } from '@utils/observables/fork';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { HistorialPreciosDialog } from '@shared/components/dialogs/ventas/historial-precios/historial-precios.dialog';
import { Cliente } from 'src/app/models/ventas';
import { PromocionVentaService } from 'src/app/services/api/ventas/precio-descuento-promociones/promocion-venta.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { Cotizacion, DetallePcd } from 'src/app/models/ventas/cotizacion';
import { CotizacionService } from 'src/app/services/api/ventas/operaciones/cotizacion.service';
import { CotizacionesBottomSheet } from '../cotizaciones-bottom-sheet/cotizaciones-bottom-sheet';
import { UnidadMedidaService } from 'src/app/services/api/almacen/configuracion/unidad-medida.service';
import * as pdfBuilder from '@utils/pdfmaker/builder/ventas.pdfmaker';
import { CanalService } from 'src/app/services/api/crm/maestros/canal.service';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-cotizaciones-form',
  templateUrl: './cotizaciones-form.component.html',
  styleUrls: ['./cotizaciones-form.component.scss']
})
export class CotizacionesFormComponent implements OnInit, OnDestroy {

  selected: any;

  selectRow(row: DetallePcd) {
    this.selected = row;
    this.dataSourceDescuento = fillTable(row.descuentos_pds, this.paginatorDescuento, this.sortDescuento);
  }

  vendedores: any[] = [];
  transportistas: any[] = [];
  agencias: any[] = [];
  condiciones: any[] = [];
  zonas: any[] = [];
  productos: any[] = [];

  loaderDocumentos: boolean = false;
  loaderDiasEntrega: boolean = false;
  loaderProductos: boolean = false;
  loaderDescuentos: boolean = false;
  loaderStock: boolean = false;
  loaderFormato: boolean = false;

  displayedColumns: string[] = ['PCD_CORPCD', 'PRD_CODPRD', 'sec', 'PRM_CODPRM', 'UME_CODVEN', 'PCD_CANSOL', 'PCD_PREUNI', 'PCD_PRUIGV', 'PCD_IMPBRU', 'PCD_PORDES', 'PCD_IMPDES', 'PCD_VALVTA', 'PCD_IMPIGV', 'PCD_IMPTOT', 'PCD_CANATE', 'PCD_CANSUS', 'PCD_GLOPCD'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsDescuento: string[] = ['acciones', 'cod_desc', 'desc', 'porc_desc', 'importe_desc'];
  dataSourceDescuento: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorDescuento: MatPaginator;
  @ViewChild(MatSort) sortDescuento: MatSort;

  columnsDocumentos: string[] = ['campo1', 'campo2', 'campo3'];
  documentos: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorDocumento: MatPaginator;
  @ViewChild(MatSort) sortDocumento: MatSort;

  columnsDiasEntrega: string[] = ['campo1'];
  dias: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorDiasEntrega: MatPaginator;
  @ViewChild(MatSort) sortDiasEntrega: MatSort;

  cotizacion: Cotizacion;

  loaderData: boolean;
  loaderReg: boolean;

  canales: any = [];
  motivos: any = [];
  companias: any = [];
  monedas: any = [];
  zonasVenta: any = [];
  tiposPrecio: any = [];
  clientes: any = [];
  lugaresDespacho: any = [];
  diasEntrega: any = [];
  centros: any = [];
  formasCalculoItem: any = [];
  almacenes: any = [];
  sucursales: any = [];
  unidadesMedida: any = [];
  cotizaciones: any = [];
  promociones: any = [];
  descuentos: any = [];

  cod_prod: String;
  prod: String;
  fisico: Number = 0;
  disponible: Number = 0;
  reservado: Number = 0;

  @ViewChild(MatRipple) ripple: MatRipple;
  direccion: string = "";

  usuario: any;
  fecha: Date = new Date();

  tipoCambio$: Subscription;
  consultaStock$: Subscription;
  centroCosto$: Subscription;
  diasEntrega$: Subscription;
  lugarDespacho$: Subscription;
  sucursal$: Subscription;
  formaCalcula$: Subscription;
  listaPrecio$: Subscription;
  clientes$: Subscription;
  sendForm$: Subscription;
  cotizacion$: Subscription;
  detalleCliente$: Subscription;

  year: string;
  month: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  period$: Subscription;
  loading$: Subscription;

  uid: string;

  minDate: Date;
  maxDate: Date

  tabSeleccionado: number;

  imprimirFormato$: Subscription;
  canales$: Subscription;
  motivos$: Subscription;
  companias$: Subscription;
  vendedores$: Subscription;
  centros$: Subscription;
  productos$: Subscription;
  unidadesMedida$: Subscription;
  cotizaciones$: Subscription;
  promociones$: Subscription;
  descuentos$: Subscription;
  condiciones$: Subscription;
  zonas$: Subscription;
  tiposPrecio$: Subscription;
  transportistas$: Subscription;
  formasCalculo$: Subscription;
  almacenes$: Subscription;

  constructor(
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private _clienteService: ClienteService,
    private _productoService: ProductoService,
    private _comprasService: ComprasService,
    private _canalService: CanalService,
    private _contabilidadService: ContabilidadService,
    private _almacenService: AlmacenService,
    private _unidadMedidaService: UnidadMedidaService,
    private _generalService: GeneralService,
    private _configurationService: ConfigurationService,
    private _authenticationService: AuthenticationService,
    private _cotizacionService: CotizacionService,
    private _snackBarService: SnackBarService,
    private _promocionVentaService: PromocionVentaService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private store: Store<PavsoState>
  ) {

    this.cotizacion = new Cotizacion();
    this.initialize();

  }

  initialize(): void {
    this.period$ = this.store.select('period').subscribe(({ year, month }) => {
      this.year = year;
      this.month = month;
      this.cotizacion.ANO_CODANO = this.year;
      this.cotizacion.MES_CODMES = this.month;

      this.minDate = new Date(parseInt(this.year), parseInt(this.month) - 1, 1);
      this.maxDate = new Date(parseInt(this.year), parseInt(this.month), 0);

      const now = new Date();
      const mes = now.getMonth() + 1 > 9 ? `${now.getMonth() + 1}` : `0${now.getMonth() + 1}`;
      const anio = now.getFullYear().toString();

      this.cotizacion.PCC_FECDOC = this.maxDate;
      this.cotizacion.PCC_FECENT = this.maxDate;

      console.log('mes', mes)
      console.log('anio', anio)
      console.log('this.month', this.month)
      console.log('this.year', this.year)
      if (mes.toString() == this.month.toString() && anio.toString() == this.year.toString()) {
        console.log('INGRESÓ')
        this.cotizacion.PCC_FECDOC = new Date();
        this.cotizacion.PCC_FECENT = new Date();
      }
    })

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.dataSourceDescuento = fillTable([], this.paginatorDescuento, this.sortDescuento);

    this.documentos = fillTable([], this.paginatorDocumento, this.sortDocumento);

    this.dias = fillTable([], this.paginatorDiasEntrega, this.sortDiasEntrega);

    this.cotizacion.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();

    this.cotizacion.PCC_CODUSE = this._authenticationService.getUsuarioSistema();
  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData();
    });

  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;
    })

    this.btnName = this.uid == '0' ? this.buttonsName.BTN_STORE : this.buttonsName.BTN_UPDATE;

    this.loadMaestros();
  }



  loadMaestros(): void {

    this.clientes$ = this._clienteService.obtenerClientes().subscribe(
      clientes => {
        this.clientes = clientes.filter(item => item.cli_indcli == 1);

        this.canales$ = this._canalService.obtenerCanales().subscribe(
          canales => {
            this.canales = canales;

            this.motivos$ = this._almacenService.obtenerMotivos().subscribe(
              motivos => {
                this.motivos = motivos;

                this.companias = this._generalService.obtenerCompanias().subscribe(
                  companias => {
                    this.companias = companias;

                    this.vendedores$ = this._ventasService.obtenerVendedores().subscribe(
                      vendedores => {
                        this.vendedores = vendedores;

                        this.condiciones$ = this._comprasService.obtenerCondicionesPago().subscribe(
                          condiciones => {
                            this.condiciones = condiciones;
                            console.log('condiciones pago', this.condiciones)

                            this.zonas$ = this._ventasService.obtenerZonasVenta().subscribe(
                              zonas => {
                                this.zonas = zonas;

                                this.tiposPrecio$ = this._ventasService.obtenerTiposPrecio().subscribe(
                                  tiposPrecio => {
                                    this.tiposPrecio = tiposPrecio;
                                    console.log('tipos precio', this.tiposPrecio)

                                    this.transportistas$ = this._almacenService.obtenerTransportistas().subscribe(
                                      transportistas => {
                                        this.transportistas = transportistas;
                                        this.agencias = transportistas;

                                        this.formasCalculo$ = this._ventasService.obtenerFormaCalculaItem().subscribe(
                                          formasCalculo => {
                                            this.formasCalculoItem = formasCalculo;
                                            console.log('formas de calculof', this.formasCalculoItem)

                                            this.almacenes$ = this._almacenService.obtenerAlamacenes().subscribe(
                                              almacenes => {
                                                !almacenes ? this.almacenes = [] : this.almacenes = almacenes;
                                                console.log('almacenes', almacenes)
                                                this.cotizacion.ALM_CODALM = '0001'

                                                this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.cotizacion.PCC_FECDOC)).subscribe(
                                                  tipoCambio => {
                                                    this.cotizacion.PCC_TIPCAM = tipoCambio[0].tipcam;
                                                    localStorage.setItem("tipo-cambio", this.cotizacion.PCC_TIPCAM.toFixed(4));

                                                    this.centros$ = this._contabilidadService.listarCentroCostos().subscribe(
                                                      centros => {
                                                        this.centros = centros;

                                                        this.productos$ = this._productoService.obtenerProductos().subscribe(
                                                          productos => {
                                                            this.productos = productos;

                                                            this.unidadesMedida$ = this._unidadMedidaService.obtenerUnidadesDeMedida().subscribe(
                                                              unidadesMedida => {
                                                                this.unidadesMedida = unidadesMedida;

                                                                this.cotizaciones$ = this._cotizacionService.obtenerCotizaciones('01').subscribe(
                                                                  cotizaciones => {
                                                                    this.cotizaciones = cotizaciones;
                                                                    console.log('cotizaciones', this.cotizaciones)

                                                                    this.promociones$ = this._promocionVentaService.obtenerPromocionesVenta().subscribe(
                                                                      promociones => {
                                                                        this.promociones = promociones;

                                                                        this.descuentos$ = this._ventasService.obtenerDescuentos().subscribe(
                                                                          descuentos => {
                                                                            this.descuentos = descuentos;

                                                                            if (this.uid != '0') {
                                                                              this.obtenerCotizacion()
                                                                              return;
                                                                            };
                                                                            this.seleccionDeLista(this.cotizacion.TLP_CODTLP)

                                                                            this.loaderData = false;
                                                                          },
                                                                          error => {
                                                                            this.loaderData = false;
                                                                            this._snackBarService.showError('Error al obtener descuentos', 'Ok');
                                                                          }
                                                                        )
                                                                      },
                                                                      error => {
                                                                        this.loaderData = false;
                                                                        this._snackBarService.showError('Error al obtener promociones', 'Ok');
                                                                      }
                                                                    )
                                                                  },
                                                                  error => {
                                                                    this.loaderData = false;
                                                                    this._snackBarService.showError('Error al obtener cotizaciones', 'Ok');
                                                                  }
                                                                )
                                                              },
                                                              error => {
                                                                this.loaderData = false;
                                                                this._snackBarService.showError('Error al obtener unidades de medida', 'Ok');
                                                              }
                                                            )
                                                          },
                                                          error => {
                                                            this.loaderData = false;
                                                            this._snackBarService.showError('Error al obtener productos', 'Ok');
                                                          }
                                                        )
                                                      },
                                                      error => {
                                                        this.loaderData = false;
                                                        this._snackBarService.showError('Error al obtener centros de costo', 'Ok');
                                                      }
                                                    )
                                                  },
                                                  error => {
                                                    this.loaderData = false;
                                                    this._snackBarService.showError('Error al obtener tipos de cambio', 'Ok');
                                                  }
                                                )
                                              },
                                              error => {
                                                this.loaderData = false;
                                                this._snackBarService.showError('Error al obtener almacenes', 'Ok');
                                              }
                                            )
                                          },
                                          error => {
                                            this.loaderData = false;
                                            this._snackBarService.showError('Error al obtener formas de cálculo', 'Ok');
                                          }
                                        )
                                      },
                                      error => {
                                        this.loaderData = false;
                                        this._snackBarService.showError('Error al obtener transportistas', 'Ok');
                                      }
                                    )
                                  },
                                  error => {
                                    this.loaderData = false;
                                    this._snackBarService.showError('Error al obtener tipos de precio', 'Ok');
                                  }
                                )
                              },
                              error => {
                                this.loaderData = false;
                                this._snackBarService.showError('Error al obtener zonas de venta', 'Ok');
                              }
                            )
                          },
                          error => {
                            this.loaderData = false;
                            this._snackBarService.showError('Error al obtener condiciones', 'Ok');
                          }
                        )
                      },
                      error => {
                        this.loaderData = false;
                        this._snackBarService.showError('Error al obtener vendedores', 'Ok');
                      }
                    )
                  },
                  error => {
                    this.loaderData = false;
                    this._snackBarService.showError('Error al obtener compañías', 'Ok');
                  }
                )
              },
              error => {
                this.loaderData = false;
                this._snackBarService.showError('Error al obtener motivos', 'Ok');
              }
            )
          },
          error => {
            this.loaderData = false;
            this._snackBarService.showError('Error al obtener canales', 'Ok');
          }
        )
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError('Error al obtener clientes', 'Ok');
      }
    )
  }

  obtenerCotizacion(): void {

    this.cotizacion$ = this._cotizacionService.obtenerCotizacion('01', this.uid).subscribe(
      cotizacion => {
        console.log('-====== cotizacion ======', cotizacion)
        this.cotizacion = cotizacion;

        this.seleccionarCliente(this.cotizacion.CLI_CODCLI);

        this.cotizacion.detalle_pcd.forEach(element => {
          if (!element.descuentos_pds) element.descuentos_pds = []
        })

        this.dataSource = fillTable(this.cotizacion.detalle_pcd, this.paginator, this.sort);

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener cotizacion', 'Ok');
        this.loaderData = false;
      }
    )

  }

  seleccionarFecha(): void {

    this.cotizacion.PCC_FECENT = this.cotizacion.PCC_FECDOC;
    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.cotizacion.PCC_FECDOC)).subscribe(
      response => {
        this.cotizacion.PCC_TIPCAM = response[0].tipcam;
        localStorage.setItem("tipo-cambio", this.cotizacion.PCC_TIPCAM.toFixed(4));
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )

  }

  nuevoRegistro(): void {
    this.cotizacion = new Cotizacion();
    this.uid = '0';
    this.initialize();

    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.cotizacion.PCC_FECDOC)).subscribe(
      tipoCambio => {
        this.cotizacion.PCC_TIPCAM = tipoCambio[0].tipcam;
      },
      error => {
        this._snackBarService.showError('Error al obtener tipo de cambio', 'OK');
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;

    if (!this.validarFormulario()) {
      this.loaderReg = false;
      return;
    }

    this.uid == '0' ? this.registrarCotizacion(f) : this.editarCotizacion(f);

  }

  validarFormulario(): boolean {

    if (!this.cotizacion.CPA_CODCPA) {
      this._snackBarService.showError('Seleccionar la condición de pago para la cotización.', 'OK');
      return false;
    }

    if (!this.cotizacion.CPD_CODCPD) {
      this._snackBarService.showError('Seleccionar un canal para la cotización.', 'OK');
      return false;
    }

    if (!this.cotizacion.CLI_CODCLI) {
      this._snackBarService.showError('Seleccionar cliente para la cotización.', 'OK');
      return false;
    }

    if (!this.cotizacion.TMO_CODTMO) {
      this._snackBarService.showError('Seleccionar moneda para la cotización.', 'OK');
      return false;
    }

    if (!this.cotizacion.MMO_CODMMO) {
      this._snackBarService.showError('Seleccionar motivo para la cotización.', 'OK');
      return false;
    }

    if (!this.cotizacion.VDE_CODVDE) {
      this._snackBarService.showError('Seleccionar un vendedor para la cotización.', 'OK');
      return false;
    }

    if (this.cotizacion.detalle_pcd.length == 0) {
      this._snackBarService.showError('Ingresar al menos un producto', 'OK');
      return false;
    }

    if (!this.cotizacion.LPC_CODLPC) {
      this._snackBarService.showError('Seleccionar un tipo de lista precio y número de lista', 'OK');
      return false;
    }

    console.log('tipo cambio', this.cotizacion.PCC_TIPCAM)
    if (!(this.cotizacion.PCC_TIPCAM > 0)) {
      this._snackBarService.showError('Establecer un tipo de cambio para la cotización.', 'OK');
      return false;
    }

    return true;
  }

  registrarCotizacion(f: NgForm): void {

    console.log('cotizacion', this.cotizacion);

    this.generarCorrelativo();

    this.cotizacion.LPC_CODLPC = this.cotizacion.LPC_CODLPC.toString();
    this.cotizacion.LPC_CODLPC.replace(/"/g, "'");;

    console.log('this.cotizacion', this.cotizacion)

    this.sendForm$ = this._cotizacionService.registrarCotizacion(this.cotizacion).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Cotización Registrada', '350px', '', '');

        f.resetForm();
        this.volver();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderReg = false;
      }
    )

  }

  editarCotizacion(f: NgForm): void {

    this.generarCorrelativo();

    this.sendForm$ = this._cotizacionService.editarCotizacion(this.cotizacion).subscribe(
      response => {
        console.log('response', response)
        this._dialogService.openDialog(SuccessComponent, 'Cotización Actualizado', '350px', '', '');
        this.loaderReg = false;

      },
      error => {
        this._snackBarService.showError('Error al actualizar cotizacion', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  obtenerDatosCotizacion(): void {

    if (!this.cotizacion.pcc_numcot) {
      this._snackBarService.showError('Seleccionar una cotización', 'Ok');
      return;
    }

    this._dialogService.openDialog(ConfirmationComponent, '¿Desea obtener datos de la cotización?', '', '', '').subscribe(result => {
      if (result) {
        this.cotizacion$ = this._cotizacionService.obtenerCotizacion('01', this.cotizacion.pcc_numcot).subscribe(
          cotizacion => {
            console.log('cotizacion', cotizacion)
            this.cotizacion = cotizacion;
            this.cotizacion.PCC_NUMPCC = null;
            // this.cotizacion.detalle_pcd = [];
            // cotizacion.detalle_pcd.forEach(element => {

            //   const producto = new DetallePcd();
            //   producto.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
            //   producto.PCD_CODUSE = this._configurationService.obtenerIdUsuario();
            //   producto.PRD_CODPRD = element.PRD_CODPRD;
            //   producto.UME_CODVEN = element.UME_CODVEN;
            //   producto.PCD_CANSOL = element.PCD_CANSOL;
            //   producto.PCD_CANVEN = element.PCD_CANVEN;
            //   producto.PCD_GLOPCD = element.PCD_GLOPCD;
            //   producto.PCD_PREUNI = element.PCD_PREUNI;
            //   producto.PCD_PRUIGV = element.PCD_PRUIGV;
            //   producto.PCD_IMPBRU = element.PCD_IMPBRU;
            //   producto.PCD_PORDES = element.PCD_PORDES;
            //   producto.PCD_IMPDES = element.PCD_IMPDES;
            //   producto.PCD_VALVTA = element.PCD_VALVTA;
            //   producto.PCD_IMPIGV = element.PCD_IMPIGV;
            //   producto.PCD_IMPTOT = element.PCD_IMPTOT;

            //   this.cotizacion.detalle_pcd.push(producto);
            // });

            this.cotizacion.detalle_pcd.forEach((element, key) => {
              element.PCD_CORPCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
            })

            this.dataSource = fillTable(this.cotizacion.detalle_pcd, this.paginator, this.sort);

            this.calcularTotales();
          },
          error => {
            this._snackBarService.showError('No se encontró los datos de la cotización', 'Ok')
          }
        )
      }
    })

  }

  blurPrecio(row: DetallePcd): void {
    console.log('precio', row.PCD_PREUNI.toFixed(2))
    row.PCD_PREUNI = Number(row.PCD_PREUNI.toFixed(2))
  }

  generarCorrelativo(): void {
    this.cotizacion.detalle_pcd.forEach((element, index) => {

      element.PCD_CORPCD = (index + 1).toString();
      element.PCD_CANVEN = element.PCD_CANSOL;
      element.PCD_CODUSE = (this._authenticationService.getIdentity()).usuariosistema;
      element.PCD_PRELIS = element.PCD_PRUIGV;

      if (element.PCD_CORPCD.length == 1) element.PCD_CORPCD = "00" + element.PCD_CORPCD
      if (element.PCD_CORPCD.length == 2) element.PCD_CORPCD = "0" + element.PCD_CORPCD

    });
  }

  seleccionDeLista(tipo_lista): void {
    this.listaPrecio$ = this._ventasService.listaDePrecios(tipo_lista).subscribe(
      response => {
        console.log('LISTA PRECIO', response)
        const listas = response.reverse();

        this.cotizacion.LPC_CODLPC = listas.length > 0 ? listas[0].LPC_CODLPC : null;
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  agregarProducto(): void {

    if (!this.cotizacion.TLP_CODTLP) {
      this._dialogService.openDialog(ConfirmationComponent, '¿Continuar sin seleccionar una lista de precio?', '', '', '').subscribe(result => {

        if (result) {

          const producto = new DetallePcd();
          console.log('producto', producto)

          producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
          producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
          producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
          producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;

          this.cotizacion.detalle_pcd.push(producto)

          this.calcularTotales();

          this.cotizacion.detalle_pcd.forEach((element, key) => {
            element.PCD_CORPCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
          })

          this.dataSource = fillTable(this.cotizacion.detalle_pcd, this.paginator, this.sort);

        } else {
          this.tabSeleccionado = 2;
        }

      })

      return;
    }

    const producto = new DetallePcd();

    console.log('productoss', producto)

    producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
    producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
    producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
    producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;

    this.cotizacion.detalle_pcd.push(producto)

    this.calcularTotales();

    this.cotizacion.detalle_pcd.forEach((element, key) => {
      element.PCD_CORPCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.dataSource = fillTable(this.cotizacion.detalle_pcd, this.paginator, this.sort);

  }

  consultarPrecioProducto(producto: DetallePcd): void {
    let now = new Date();

    let fecha = formatDateWithDash(now);

    if (!this.cotizacion.TLP_CODTLP) {
      this._snackBarService.showError('Seleccionar lista', 'OK');
    }

    if (!this.cotizacion.LPC_CODLPC) {
      this._snackBarService.showError('Establecer Nro de lista', 'OK');
    }

    this.listaPrecio$ = this._ventasService.listaDePreciosProducto(producto.PRD_CODPRD, this.cotizacion.TMO_CODTMO, this.cotizacion.CLI_CODCLI, this.cotizacion.TLP_CODTLP, this.cotizacion.LPC_CODLPC, fecha).subscribe(
      response => {
        console.log('precio producto', response)
        if (this.cotizacion.TMO_CODTMO == "SO") {
          producto.PCD_PREUNI = response[0].pre_prenac;
          producto.PCD_PRUIGV = response[0].pre_vtanac;
        } else {
          producto.PCD_PREUNI = response[0].pre_predol;
          producto.PCD_PRUIGV = response[0].pre_vtadol;
        }

        producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;

        producto.PCD_IMPDES = producto.PCD_IMPBRU * (producto.PCD_PORDES / 100)

        producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;

        producto.PCD_IMPIGV = this.cotizacion.PCC_INDCAL == '0' ? 0 : producto.PCD_VALVTA * this.cotizacion.PCC_TASIGV / 100;

        producto.PCD_IMPTOT = producto.PCD_IMPIGV + producto.PCD_VALVTA;

        this.calcularTotales();

      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )

  }

  eliminarProducto(): void {

    let row = this.cotizacion.detalle_pcd.find(item => item == this.selected);

    console.log('fila seleccionada', row);

    if (!row && this.cotizacion.detalle_pcd.length > 0) row = this.cotizacion.detalle_pcd[0];

    let details = [];
    this.cotizacion.detalle_pcd.forEach(element => {
      if (element.PCD_CORPCD != row.PCD_CORPCD) {
        details.push(element)
      }
    });

    this.cotizacion.detalle_pcd = details;

    this.cotizacion.detalle_pcd.forEach((element, key) => {
      element.PCD_CORPCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.dataSource = fillTable(details, this.paginator, this.sort);

    this.selected = null;

    // this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

    //   if(result) {

    //     const productos = this.order.detalle_pcd.filter((element, i) => index != i );

    //     this.order.detalle_pcd = productos;

    //     this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);
    //     this.calcularTotales();

    //   }

    // })

    this.calcularTotales();
  }

  consultarStock(): void {
    let row = this.cotizacion.detalle_pcd.find(item => item == this.selected);

    if (!row) {
      this._snackBarService.showError('Seleccionar una fila del detalle', 'Ok');
      return;
    };

    const now = new Date();

    if (!this.cotizacion.SUC_CODSUC) {
      this._snackBarService.showError('No se seleccionó la sucursal', 'OK');
      return;
    }

    if (!this.cotizacion.ALM_CODALM) {
      this._snackBarService.showError('No se seleccionó el almacen', 'OK');
      return;
    }

    if (!row.PRD_CODPRD) {
      this._snackBarService.showError('No se seleccionó el producto', 'OK');
      return;
    }

    this.consultaStock$ = this._ventasService.consultaStock(this.cotizacion.SUC_CODSUC, now.getFullYear(), now.getMonth() + 1, this.cotizacion.ALM_CODALM, row.PRD_CODPRD).subscribe(
      response => {

        this.cod_prod = row.PRD_CODPRD;
        const producto = this.productos.find(item => item.prd_codprd == row.PRD_CODPRD)
        this.prod = producto ? producto.DESC_ESPA : '';
        this.fisico = response[0].fisico;
        this.disponible = response[0].disponible;

        const rippleRef = this.ripple.launch({
          persistent: true,
          centered: true
        });

        rippleRef.fadeOut();
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  insertarProducto(): void {

    if (!this.cotizacion.TLP_CODTLP) {
      this._dialogService.openDialog(ConfirmationComponent, '¿Continuar sin seleccionar una lista de precio?', '', '', '').subscribe(result => {
        const producto = new DetallePcd();

        producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
        producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
        producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
        producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;

        let i = 0;

        if (this.selected) {
          this.cotizacion.detalle_pcd.forEach((item, index) => {
            if (item.PCD_CORPCD == this.selected.PCD_CORPCD) i = index;
          })

          this.cotizacion.detalle_pcd.splice(i, 0, producto);
        } else {
          this.cotizacion.detalle_pcd.push(producto);
          this.cotizacion.detalle_pcd = this.cotizacion.detalle_pcd.reverse();
        }

        this.cotizacion.detalle_pcd.forEach((element, key) => {
          console.log('ids', key)
          element.PCD_CORPCD = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
        })

        console.log('detalle', this.cotizacion.detalle_pcd)

        this.dataSource = fillTable(this.cotizacion.detalle_pcd, this.paginator, this.sort);

        this.calcularTotales();
      });

      return;
    }
    const producto = new DetallePcd();

    producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
    producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
    producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
    producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;

    let i = 0;

    if (this.selected) {
      this.cotizacion.detalle_pcd.forEach((item, index) => {
        if (item.PCD_CORPCD == this.selected.PCD_CORPCD) i = index;
      })

      this.cotizacion.detalle_pcd.splice(i, 0, producto);
    } else {
      this.cotizacion.detalle_pcd.push(producto);
      this.cotizacion.detalle_pcd = this.cotizacion.detalle_pcd.reverse();
    }

    this.cotizacion.detalle_pcd.forEach((element, key) => {
      console.log('ids', key)
      element.PCD_CORPCD = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    console.log('detalle', this.cotizacion.detalle_pcd)

    this.dataSource = fillTable(this.cotizacion.detalle_pcd, this.paginator, this.sort);

    this.calcularTotales();
  }

  agregarDescuento(): void {
    const seleccionado = this.cotizacion.detalle_pcd.find(item => item == this.selected);
    console.log('seleccionado', seleccionado)
    if (!seleccionado) {
      this._snackBarService.showError('Seleccionar una fila del detalle', 'Ok');
      return;
    }

    if (!seleccionado.PRD_CODPRD) {
      this._snackBarService.showError('Seleccionar un producto', 'Ok');
      return;
    }

    if (seleccionado.PCD_PREUNI == 0) {
      this._snackBarService.showError('Establecer un precio del producto', 'Ok');
      return;
    }

    const descuento = new DescuentosPD();

    seleccionado.descuentos_pds = seleccionado.descuentos_pds ? seleccionado.descuentos_pds : [];
    seleccionado.descuentos_pds.push(descuento);

    this.dataSourceDescuento = fillTable(seleccionado.descuentos_pds, this.paginatorDescuento, this.sortDescuento);

    seleccionado.descuentos_pds.forEach((element, index) => {
      element.dco_coddco = (index + 1).toString();
      if (element.dco_coddco.length == 1) {
        element.dco_coddco = "000" + element.dco_coddco;
      }
      if (element.dco_coddco.length == 2) {
        element.dco_coddco = "00" + element.dco_coddco;
      }
      if (element.dco_coddco.length == 3) {
        element.dco_coddco = "0" + element.dco_coddco;
      }
    })
  }

  eliminarDescuento(i): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de eliminar el descuento?', '', '', '').subscribe(result => {

      const producto: DetallePcd = this.cotizacion.detalle_pcd.find(item => item.seleccionado);

      // let descuentos = []
      // producto.descuentos_pds.forEach((element, index) => {
      //   if(i != index) descuentos.push(element)
      // })

      producto.descuentos_pds = producto.descuentos_pds.filter((el, index) => i != index);

      this.dataSourceDescuento = fillTable(producto.descuentos_pds, this.paginator, this.sort);

      let totalDes = 1;
      producto.descuentos_pds.forEach(descuento => {

        totalDes *= (1 - descuento.pds_pordes / 100);
        console.log('totalDes', totalDes);

      });

      producto.PCD_PORDES = (1 - totalDes) * 100;
      producto.PCD_IMPDES = producto.PCD_IMPBRU * (producto.PCD_PORDES / 100);
      producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
      producto.PCD_IMPIGV = this.cotizacion.PCC_INDCAL == '0' ? 0 : producto.PCD_VALVTA * this.cotizacion.PCC_TASIGV / 100;
      producto.PCD_IMPTOT = producto.PCD_IMPIGV + producto.PCD_VALVTA;

      this.calcularTotales()

      producto.descuentos_pds.forEach((element, index) => {
        element.dco_coddco = (index + 1).toString();
        if (element.dco_coddco.length == 1) {
          element.dco_coddco = "000" + element.dco_coddco;
        }
        if (element.dco_coddco.length == 2) {
          element.dco_coddco = "00" + element.dco_coddco;
        }
        if (element.dco_coddco.length == 3) {
          element.dco_coddco = "0" + element.dco_coddco;
        }
      })

    })

  }

  cambiarFilaDescuento(row: DescuentosPD): void {

    let producto = this.cotizacion.detalle_pcd.find(item => item == this.selected);

    row.pds_impdes = producto.PCD_IMPBRU * (row.pds_pordes / 100);
    row.pds_coduse = this._configurationService.obtenerIdUsuario();
    row.pds_incdes = (row.pds_impdes * 1.18);

    row.pds_porori = 0;
    row.pds_impcom = 0;

    let totalDes = 1;
    producto.descuentos_pds.forEach(descuento => {

      totalDes *= (1 - descuento.pds_pordes / 100);

    });

    producto.PCD_PORDES = (1 - totalDes) * 100;
    producto.PCD_IMPDES = producto.PCD_IMPBRU * (producto.PCD_PORDES / 100);
    producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
    producto.PCD_IMPIGV = this.cotizacion.PCC_INDCAL == '0' ? 0 : producto.PCD_VALVTA * this.cotizacion.PCC_TASIGV / 100;
    producto.PCD_IMPTOT = producto.PCD_IMPIGV + producto.PCD_VALVTA;

    this.calcularTotales()

  }

  seleccionarCliente(valor): void {

    const cliente: Cliente = this.clientes.find(item => item.cli_codcli == valor);

    if (cliente) {
      this.cotizacion.ZVE_CODZVE = cliente.dato_clc.ZVE_CODZVE;
      this.direccion = cliente.cli_dircli;

      console.log('TIPO LISTA PRECIO', cliente.tpl_codtlp)

      if (this.uid == '0') {
        if (cliente.tpl_codtlp) this.cotizacion.TLP_CODTLP = cliente.tpl_codtlp;
      }

      if (cliente.cli_numruc) {
        this.cotizacion.TDO_CODTDO = "FAC";
      }

      if (cliente.cli_numdni) {
        this.cotizacion.TDO_CODTDO = "BVE";
      }

      forkObs(
        this._ventasService.obtenerDiasEntrega(this.cotizacion.CLI_CODCLI, this.cotizacion.LDE_CODLDE),
        this._contabilidadService.listarCentroCostos(),
        this._ventasService.obtenerSucursalAgencias(this.cotizacion.CLI_AGETRA),
        this._ventasService.obtenerLugaresDespacho(this.cotizacion.CLI_CODCLI)
      ).then(data => {
        this.diasEntrega = data[0];
        this.centros = data[1];
        this.sucursales = data[2];
        this.lugaresDespacho = data[3];

        this.seleccionDeLista(this.cotizacion.TLP_CODTLP);

      }).catch(error => {
        this._snackBarService.showError("Error al obtener maestros", 'OK')
      })

    }

  }

  seleccionarProducto(valor, row: DetallePcd): void {
    const producto = this.productos.find(item => item.prd_codprd == valor);
    row.UME_CODVEN = producto.UNIDAD;
    console.log('ssss')

    if (this.cotizacion.TLP_CODTLP) {
      let now = new Date();

      let fecha = formatDateWithDash(now);

      if (!this.cotizacion.TLP_CODTLP) {
        this._snackBarService.showError('Seleccionar lista', 'OK');
        return;
      }

      if (!this.cotizacion.LPC_CODLPC) {
        this._snackBarService.showError('Establecer Nro de lista', 'OK');
        return;
      }

      if (!row.PRD_CODPRD) {
        this._snackBarService.showError('Seleccionar producto para calcular el precio', 'OK');
        return;
      }
      console.log('fasdf')

      this.listaPrecio$ = this._ventasService.listaDePreciosProducto(row.PRD_CODPRD, this.cotizacion.TMO_CODTMO, null, this.cotizacion.TLP_CODTLP, this.cotizacion.LPC_CODLPC, fecha).subscribe(
        response => {
          if (this.cotizacion.TMO_CODTMO == "SO") {
            row.PCD_PREUNI = response[0].pre_prenac;
            row.PCD_PRUIGV = response[0].pre_vtanac;
          } else {
            row.PCD_PREUNI = response[0].pre_predol;
            row.PCD_PRUIGV = response[0].pre_vtadol;
          }

          row.PCD_IMPBRU = row.PCD_CANSOL * row.PCD_PREUNI;

          row.PCD_IMPDES = row.PCD_IMPBRU * (row.PCD_PORDES / 100)

          row.PCD_VALVTA = row.PCD_IMPBRU - row.PCD_IMPDES;

          row.PCD_IMPIGV = this.cotizacion.PCC_INDCAL == '0' ? 0 : row.PCD_VALVTA * this.cotizacion.PCC_TASIGV / 100;

          row.PCD_IMPTOT = row.PCD_IMPIGV + row.PCD_VALVTA;

          this.calcularTotales();

          this.consultaStock$ = this._ventasService.consultaStock(this.cotizacion.SUC_CODSUC, this.year, this.month, this.cotizacion.ALM_CODALM, row.PRD_CODPRD).subscribe(
            response => {
              console.log('stock', response)
              this.fisico = response[0].fisico;
              this.disponible = response[0].disponible;
              this.reservado = response[0].reservado;
            },
            error => {
              this._snackBarService.showError(error, 'error');
            }
          )
        },
        error => this._snackBarService.showError(error.error.msg, 'OK')
      )
    };
    // this.calcularTotales;
  }

  checkProducto(value: boolean, productoSeleccionado: DetallePcd): void {
    if (value) {
      this.cotizacion.detalle_pcd.forEach(element => element.seleccionado = false);

      productoSeleccionado.seleccionado = value;

      this.dataSourceDescuento = fillTable(productoSeleccionado.descuentos_pds ? productoSeleccionado.descuentos_pds : [], this.paginatorDescuento, this.sortDescuento);
      // this.dataSourceLote = fillTable(productoSeleccionado.detalle_lote, this.paginatorLote, this.sortLote);
      // this.dataSourceSerie = fillTable(productoSeleccionado.detalle_serie, this.paginatorSerie, this.sortSerie);

    }
  }

  seleccionarPromocion(codigo, fila): void {

  }

  seleccionarLugarDespacho(lugar_id): void {
    this.lugaresDespacho.forEach(element => {
      if (element.LDE_CODLDE == lugar_id) this.cotizacion.ZVE_CODZVE = element.zve_codzve;
    });
  }



  extraerPromociones(): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de verificar si tiene alguna promoción?', '', '', '').subscribe(result => {

    })
  }

  verHistorialPrecios(): void {
    const dialogRef = this.dialog.open(HistorialPreciosDialog, {
      width: '560px',
      data: this.cotizacion,
      // panelClass: 'pav-dialog-container-option-1'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  suspenderCotizacion(): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de suspender cotizacion?', '', '', '').subscribe(result => {

    })
  }

  seleccionarTipoDescuento(value, row: DescuentosPD): void {
    row.dco_coddco = (value == "0001") ? "DESCUENTO ESPECIAL" : "DESCUENTO POR REGALO";
  }

  clickMasOpciones(): void {
    this._bottomSheet.open(CotizacionesBottomSheet).afterDismissed().subscribe(data => {

      if (data) {
        this.loaderData = true;
        this._cotizacionService.obtenerCotizacion('01', data).subscribe(
          cotizacion => {

            this.cotizacion = cotizacion;

            this.cotizacion.detalle_pcd.forEach(element => {
              if (!element.descuentos_pds) element.descuentos_pds = []
            })

            this.seleccionarCliente(this.cotizacion.CLI_CODCLI);

            this.dataSource = fillTable(this.cotizacion.detalle_pcd, this.paginator, this.sort);

            this.loaderData = false;
          },
          error => {

            this.loaderData = false;
            this._snackBarService.showError("Error al obtener cotizacion", 'OK');

          }
        )
      }

    });
  }

  generarFormatoCotizacion(): void {
    if (this.uid != '0') {
      this.loaderFormato = true;

      this.imprimirFormato$ = this._cotizacionService.obtenerPDFCotizacion(this.uid).subscribe(
        response => {
          if (response.length > 0) {

            const headers = {
              CIA_NOMCIA: this._configurationService.obtenerNombreCliente(),
              CIA_NUMRUC: response[0].CIA_NUMRUC,
              CIA_DIRCIA: response[0].CIA_DIRCIA,
            }

            pdfBuilder.generarFormatoCotizacion(headers, response[0])
            this.loaderFormato = false;
            return;

          }

          this._snackBarService.showError('No se encontro el pdf de cotización', 'OK');
          this.loaderFormato = false;

        },
        error => {
          console.log('error', error)
          this.loaderFormato = false;
          this._snackBarService.showError(error.error.msg, 'OK');
        }
      )
    }
  }

  calcularMontosFila(row: DetallePcd): void {

    row.PCD_IMPBRU = row.PCD_CANSOL * row.PCD_PREUNI;

    if (this.cotizacion.PCC_INDCAL == '1') row.PCD_PRUIGV = (this.cotizacion.PCC_TASIGV / 100 + 1) * row.PCD_PREUNI;

    row.PCD_IMPDES = row.PCD_IMPBRU * (row.PCD_PORDES / 100)

    row.PCD_VALVTA = row.PCD_IMPBRU - row.PCD_IMPDES;

    row.PCD_IMPIGV = this.cotizacion.PCC_INDCAL == '0' ? 0 : row.PCD_VALVTA * this.cotizacion.PCC_TASIGV / 100;

    row.PCD_IMPTOT = row.PCD_IMPIGV + row.PCD_VALVTA;

    this.calcularTotales();
  }

  calcularTotales(): void {
    let valorVenta = 0;
    let impBruto = 0;
    let impIGV = 0;
    let descuento = 0;
    let impTotal = 0;

    this.cotizacion.detalle_pcd.forEach(element => {
      valorVenta += Number(element.PCD_VALVTA);
      impBruto += Number(element.PCD_IMPBRU);
      impIGV += Number(element.PCD_IMPIGV);
      descuento += Number(element.PCD_IMPDES);
      impTotal += Number(element.PCD_IMPTOT);
    })

    this.cotizacion.PCC_VALVTA = valorVenta;
    this.cotizacion.PCC_VALVTA = this.cotizacion.PCC_VALVTA.toFixed(2);

    this.cotizacion.PCC_IMPBRU = impBruto;
    this.cotizacion.PCC_IMPBRU = this.cotizacion.PCC_IMPBRU.toFixed(2);

    this.cotizacion.PCC_IMPIGV = impIGV;
    this.cotizacion.PCC_IMPIGV = this.cotizacion.PCC_IMPIGV.toFixed(2);

    this.cotizacion.PCC_IMPDES = descuento;
    this.cotizacion.PCC_IMPDES = this.cotizacion.PCC_IMPDES.toFixed(2);

    this.cotizacion.PCC_IMPTOT = impTotal;
    this.cotizacion.PCC_IMPTOT = this.cotizacion.PCC_IMPTOT.toFixed(2);
  }

  seleccionarCalculaItem(calculaItem): void {
    if (calculaItem == '0') {
      this.cotizacion.detalle_pcd.forEach(item => {
        item.PCD_PRUIGV = 0;
        item.PCD_IMPIGV = 0;
        this.calcularMontosFila(item);
      })

      this.dataSource = fillTable(this.cotizacion.detalle_pcd, this.paginator, this.sort);
      this.calcularTotales();

      return;
    }

    this.cotizacion.detalle_pcd.forEach(item => {
      item.PCD_PRUIGV = (this.cotizacion.PCC_TASIGV / 100 + 1) * item.PCD_PREUNI;
      item.PCD_IMPIGV = item.PCD_IMPBRU * item.PCD_PRUIGV;
      this.calcularMontosFila(item);
    })

    this.dataSource = fillTable(this.cotizacion.detalle_pcd, this.paginator, this.sort);

    this.calcularTotales();

  }

  reset(): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Desea restablecer el formulario?', '', '', '').subscribe(
      response => {
        if (response) {

          this.direccion = '';

          this.cotizacion = new Cotizacion();

          this.minDate = new Date(parseInt(this.year), parseInt(this.month) - 1, 1);
          this.maxDate = new Date(parseInt(this.year), parseInt(this.month), 0);

          this.cotizacion.PCC_FECDOC = this.minDate;
          this.cotizacion.PCC_FECENT = this.cotizacion.PCC_FECDOC;

          this.dataSource = fillTable([], this.paginator, this.sort);
          this.dataSourceDescuento = fillTable([], this.paginatorDescuento, this.sortDescuento);

          this.documentos = fillTable([], this.paginatorDocumento, this.sortDocumento);

          this.dias = fillTable([], this.paginatorDiasEntrega, this.sortDiasEntrega);


          this.cotizacion.ANO_CODANO = this.year;
          this.cotizacion.MES_CODMES = this.month;
          this.cotizacion.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();

          this.cotizacion.PCC_CODUSE = this._authenticationService.getUsuarioSistema();

        }
      }
    )
  }

  aplicarDescuentoGeneral(descuento): void {
    if (descuento == 0) {
      this._snackBarService.showError('Establecer un descuento mayor a 0', 'Ok');
      return;
    }

    if (this.cotizacion.detalle_pcd.length == 0) {
      this._snackBarService.showError('Agregar al menos un ítem al detalle', 'Ok');
      return;
    }

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de agregar un (%)descuento general?', '', '', '').subscribe(result => {
      if (result) {
        this.cotizacion.detalle_pcd.forEach(item => {
          console.log('seleccionado', item)



          if (!item.PRD_CODPRD) {
            this._snackBarService.showError('Seleccionar un producto', 'Ok');
            return;
          }

          if (item.PCD_PREUNI == 0) {
            this._snackBarService.showError('Establecer un precio del producto', 'Ok');
            return;
          }

          item.descuentos_pds = [];

          const desc = new DescuentosPD();
          desc.pds_pordes = descuento;

          item.descuentos_pds = item.descuentos_pds ? item.descuentos_pds : [];
          item.descuentos_pds.push(desc);
          this.cambiarFilaDescuento(desc);

          this.calcularMontosFila(item);

          this.dataSourceDescuento = fillTable(item.descuentos_pds, this.paginatorDescuento, this.sortDescuento);

          item.descuentos_pds.forEach((element, index) => {
            element.dco_coddco = (index + 1).toString();
            if (element.dco_coddco.length == 1) {
              element.dco_coddco = "000" + element.dco_coddco;
            }
            if (element.dco_coddco.length == 2) {
              element.dco_coddco = "00" + element.dco_coddco;
            }
            if (element.dco_coddco.length == 3) {
              element.dco_coddco = "0" + element.dco_coddco;
            }
          })

        });

      }
    })



  }

  focusCampo(evento): void {
    evento.target.select();
  }

  generarPedidoDesdeCotizacion(): void {
    this._router.navigate(['/modulo-ventas/pedido/cotizacion=' + this.uid])

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/cotizaciones']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoCambio$,
      this.consultaStock$,
      this.centroCosto$,
      this.diasEntrega$,
      this.lugarDespacho$,
      this.sucursal$,
      this.formaCalcula$,
      this.listaPrecio$,
      this.sendForm$,
      this.tipoCambio$,
      this.period$,
      this.cotizacion$,
      this.imprimirFormato$,
      this.canales$,
      this.motivos$,
      this.companias$,
      this.vendedores$,
      this.centros$,
      this.productos$,
      this.unidadesMedida$,
      this.cotizaciones$,
      this.promociones$,
      this.descuentos$,
      this.condiciones$,
      this.zonas$,
      this.tiposPrecio$,
      this.transportistas$,
      this.formasCalculo$,
      this.almacenes$,
    ])
  }

}
