import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from "ng-apexcharts";
import { Subscription } from "rxjs";
import { VentasService } from "src/app/services";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'grafico-resumen-anual-ventas',
  templateUrl: './grafico-resumen-anual.component.html',
  styleUrls: ['./grafico-resumen-anual.component.scss']
})
export class GraficoResumenAnualComponent implements OnInit, OnDestroy {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartOptionsPeriodo: Partial<ChartOptions>;

  resumenAnual$: Subscription;
  resumenPeriodo$: Subscription;
  loading$: Subscription;

  series: any[] = [];

  constructor(
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {

    this.chartOptions = {
      series: [],
      chart: {
        type: "bar",
        height: 350,
        events: {
          dataPointSelection: function(event, chartContext, config) {

            console.log({event, chartContext, config})
            const seriesIndex = config.seriesIndex;
            console.log()

          },
          legendClick: function(chartContext, seriesIndex, config) {
            console.log({chartContext, seriesIndex, config})
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          // endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          "",
          "",
          "",
        ]
      },
      yaxis: {
        title: {
          text: "Importe "
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return "Importe " + val;
          }
        }
      }
    };

    this.chartOptionsPeriodo = {
      series: [],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          // endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          "",
          "",
          "",
        ]
      },
      yaxis: {
        title: {
          text: "Importe "
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return "Importe " + val + " ";
          }
        }
      }
    };
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerResumenAnual();
    })
  }

  /**
   * Renderiza el gráfico del resumen anual
   */
  obtenerResumenAnual(): void {

    const payload = { moneda: 'SO' };

    this.resumenAnual$ = this._ventasService.obtenerResumenAnual(payload).subscribe(
      response => {
        console.log('response resumen anual', response)
        this.chartOptions.series = response;
        this.series = response;
      },
      error => {
        this._snackBarService.showError('Error al obtener resumen anual', 'Ok');
      }
    )

  }

  /**
   * Renderiza el gráfico del resumen período
   * @param event
   */
  seleccionarAnio(event): void {
    const payload = { moneda: 'SO' };

    this.resumenPeriodo$ = this._ventasService.obtenerResumenPeriodo(event.value, payload).subscribe(
      response => {
        console.log('resumen periodo', response)
        this.chartOptionsPeriodo.series = response;
      },
      error => {
        this._snackBarService.showError('Error al obtener resumen de período', 'Ok');
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.resumenAnual$,
      this.resumenPeriodo$,
      this.loading$
    ])
  }
}
