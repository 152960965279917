<app-pavso-title-section [title]="'Reportes Analítico'" [module]="'Informe'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <select-report [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event); loaderData = false;"></select-report>

  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Filtros</div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <div class="pav-filtro-container">
      <div class="row">
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Moneda</mat-label>
            <mat-select [disabled]="loaderData" [(ngModel)]="filtro.moneda" name="moneda">
              <mat-option *ngFor="let mon of monedas" [value]="mon.TMO_CODTMO">
                {{mon.tmo_deslar}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">

          <select-search
            [label]="'Cuenta'"
            [placeholder]="'Ej. 42'"
            [name]="'ccn_codccn'"
            [value]="'ccn_codccn'"
            [description]="'ccn_desabr'"
            [data]="cuentas"
            [multiple]="true"
            [disabled]="loaderData"
            (cambioSelect)="filtro.cuenta = $event;"
            [model]="filtro.cuenta"
          ></select-search>

        </div>

        <div class="col s12 m12 l4">

          <select-search
            [label]="'Auxiliar'"
            [placeholder]="'Ej. PEREZ'"
            [name]="'cli_codcli'"
            [value]="'cli_codcli'"
            [description]="'cli_nomcli'"
            [data]="auxiliares"
            [multiple]="true"
            [disabled]="loaderData"
            (cambioSelect)="filtro.auxiliar = $event"
            [model]="filtro.auxiliar"
            >
          </select-search>

        </div>

        <div class="col s12 m12 l4">
          <select-search
            [label]="'Subdiario'"
            [placeholder]="'Ej. sub'"
            [name]="'sco_codsco'"
            [value]="'sco_codsco'"
            [description]="'sco_descri'"
            [data]="subdiarios"
            [multiple]="true"
            [disabled]="loaderData"
            (cambioSelect)="filtro.subdiario = $event"
            [model]="filtro.subdiario"
            >
          </select-search>

        </div>

        <div class="col s12 m12 l4">
          <select-search
            [label]="'Centro de costo'"
            [placeholder]="'Ej. 1001'"
            [name]="'ccs_codccs'"
            [value]="'ccs_codccs'"
            [description]="'ccs_descri'"
            [data]="centros"
            [multiple]="true"
            [disabled]="loaderData"
            (cambioSelect)="filtro.centro = $event"
            [model]="filtro.centro"
            >
          </select-search>

        </div>

        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Desde - Hasta</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [disabled]="loaderData">
              <input matStartDate formControlName="start" placeholder="Desde">
              <input matEndDate formControlName="end" placeholder="Hasta">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Dígitos</mat-label>
            <mat-select [disabled]="loaderData">
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="6">6</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </div>


</div>

<br>

<div align="center">
  <button class="pav-button-action" mat-flat-button color="primary" [class.spinner]="loading" [disabled]="loading || loaderData" (click)="verReporte()">{{buttonsName.BTN_SHOW_REPORT}}</button>
</div>
