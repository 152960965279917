<app-pavso-title-section [title]="'apps.crm.operaciones.oportunidad.title' | translate"
  [module]="'CRM'"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">


    <mat-tab-group>
      <mat-tab label="Generales" labelClass="mat-tab-label-0-0">
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.oportunidad.form.ticket' | translate}}</mat-label>
                <input type="text" [(ngModel)]="registro.idtkt" matInput [disabled]="loaderData"
                  [placeholder]="'apps.crm.operaciones.oportunidad.form.ticket' | translate" name="idtkt" readonly>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.oportunidad.form.date' | translate}}</mat-label>
                <input [disabled]="loaderData" [(ngModel)]="registro.fecreg" name="fecreg" [min]="minDate" matInput [matDatepicker]="fechahoy">
                <mat-datepicker-toggle matSuffix [for]="fechahoy"></mat-datepicker-toggle>
                <mat-datepicker #fechahoy></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.oportunidad.form.channel' | translate}}</mat-label>
                <mat-select [disabled]="loaderData" [(ngModel)]="registro.idmtk" name="idmtk" required>
                  <mat-option *ngFor="let canal of canales; trackBy: trackByCanal" [value]="canal.idmtk">
                    {{canal.descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.oportunidad.form.seller' | translate}}</mat-label>
                <mat-select [disabled]="loaderData" [(ngModel)]="registro.codvde" name="codvde" required>
                  <mat-option *ngFor="let vendedor of vendedores; trackBy: trackByVendedor"
                    [value]="vendedor.VDE_CODVDE">
                    {{vendedor.VDE_NOMVDE}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l6">
              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.oportunidad.form.subject' | translate}}</mat-label>
                <input type="text" [(ngModel)]="registro.asunto" matInput [disabled]="loaderData"
                  [placeholder]="'apps.crm.operaciones.oportunidad.form.subject' | translate" name="asunto" required>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <select-search [label]="'apps.crm.operaciones.oportunidad.form.business-line' | translate"
                [placeholder]="'Ej. Línea'" [name]="'codoln'" [value]="'codoln'" [description]="'descri'"
                [data]="lineas" (cambioSelect)="registro.codoln = $event" [model]="registro.codoln" [disabled]="loaderData">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.oportunidad.form.type-negociation' | translate}}</mat-label>
                <mat-select [disabled]="loaderData" [(ngModel)]="registro.codotn" name="codotn" required>
                  <mat-option *ngFor="let tipo of tipos; trackBy: trackByTipoNegociacion" [value]="tipo.codotn">
                    {{tipo.descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l6">

              <select-search [label]="'apps.crm.operaciones.oportunidad.form.client' | translate"
                [placeholder]="'Ej. TORRES'" [name]="'cli_codcli'" [value]="'cli_codcli'" [description]="'cli_nomcli'"
                [data]="clientes" (cambioSelect)="registro.codcli = $event; seleccionarCliente($event)" [model]="registro.codcli">
              </select-search>

            </div>
            <div class="col s12 m12 l3">

              <select-search [disabled]="loaderData" [label]="'apps.crm.operaciones.oportunidad.form.good-or-service' | translate"
                [placeholder]="'Ej. TOLPA'" [name]="'prd_codprd'" [value]="'prd_codprd'" [description]="'DESC_ESPA'"
                [data]="productos" (cambioSelect)="registro.codprd = $event" [model]="registro.codprd">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.oportunidad.form.unity' | translate}}</mat-label>
                <input [disabled]="loaderData" #iCantidadReferencial class="pav-input-align-right" type="number" [(ngModel)]="registro.numkwp" matInput
                  [placeholder]="'apps.crm.operaciones.oportunidad.form.unity' | translate" name="numkwp" required
                  (focus)="inputCantidadReferencial.nativeElement.select()" step="any">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l6">

              <select-search [disabled]="loaderData" [label]="'apps.crm.operaciones.oportunidad.form.contact' | translate"
                [placeholder]="'Ej. TALARA'" [name]="'codccl'" [value]="'codccl'" [description]="'nomccl'"
                [data]="contactos" (cambioSelect)="registro.codccl = $event" [model]="registro.codccl">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
              <app-currency-select [moneda]="registro.codtmo"
                (cambioMoneda)="registro.codtmo = $event"></app-currency-select>
            </div>
            <div class="col s12 m12 l3">

              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.oportunidad.form.referencial-value' | translate}}</mat-label>
                <input [disabled]="loaderData" #iValorReferencial type="number" class="pav-input-align-right" [(ngModel)]="registro.cosopo" matInput
                  [placeholder]="'apps.crm.operaciones.oportunidad.form.referencial-value' | translate" name="cosopo"
                  required (focus)="inputValorReferencial.nativeElement.select()" step="any">
              </mat-form-field>
            </div>


          </div>

          <div class="row">
            <div class="col s12 m12 l3">

              <select-search [disabled]="loaderData" [label]="'Campaña'" [placeholder]="'Ej. TALARA'" [name]="'codcso'" [value]="'codcso'"
                [description]="'glosa'" [data]="campanias" (cambioSelect)="registro.codcso = $event"
                [model]="registro.codcso">
              </select-search>

            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.oportunidad.form.creation-date' | translate}}</mat-label>
                <input [(ngModel)]="registro.feccre" matInput [matDatepicker]="fechacreacion" name="feccrea" disabled>
                <mat-datepicker-toggle matSuffix [for]="fechacreacion"></mat-datepicker-toggle>
                <mat-datepicker #fechacreacion></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'Cantidad trabajadores'}}</mat-label>
                <input [disabled]="loaderData" #iCantidadTrabajadoresReferencial type="number" class="pav-input-align-right" [(ngModel)]="registro.numtra" matInput
                  [placeholder]="'Eg. 2' | translate" name="numtra"
                  required (focus)="inputCantidadTrabajadoresReferencial.nativeElement.select()" step="any">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'Cantidad de usuarios'}}</mat-label>
                <input [disabled]="loaderData" #iCantidadUsuariosReferencial type="number" class="pav-input-align-right" [(ngModel)]="registro.numusu" matInput
                  [placeholder]="'Eg. 2' | translate" name="numusu"
                  required (focus)="inputCantidadUsuariosReferencial.nativeElement.select()" step="any">
              </mat-form-field>

            </div>


          </div>

          <div class="row">
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>{{'Fecha de inicio de empresa' | translate}}</mat-label>
                <input [(ngModel)]="registro.feclic" matInput [matDatepicker]="fechaini" name="feclic">
                <mat-datepicker-toggle matSuffix [for]="fechaini"></mat-datepicker-toggle>
                <mat-datepicker #fechaini></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l9">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.oportunidad.form.observation' | translate}}</mat-label>
                <textarea [disabled]="loaderData" [(ngModel)]="registro.detalle" matInput name="detalle"
                  [placeholder]="'apps.crm.operaciones.oportunidad.form.observation' | translate"></textarea>
              </mat-form-field>
            </div>

          </div>

          <div class="row">
            <div class="col s12 m12 l3" *ngIf="uid != '0'">
              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.oportunidad.form.situation' | translate}}</mat-label>
                <mat-select [(ngModel)]="registro.idstk" name="idstk" disabled>
                  <mat-option *ngFor="let situacion of situaciones; trackBy: trackBySituacion"
                    [value]="situacion.idstk">
                    {{situacion.descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>


            <div class="col s12 m12 l3" *ngIf="uid != '0'">
              <button class="pav-button-flex" type="button" mat-flat-button color="primary" (click)="nuevaEtapa()">
                {{'apps.crm.operaciones.oportunidad.button.new-stage' | translate}}
              </button>
            </div>

            <div class="col s12 m12 l3" *ngIf="uid != '0'">
              <button class="pav-button-flex" type="button" mat-flat-button color="primary"
                (click)="nuevoSeguimientoEtapa()">
                {{'apps.crm.operaciones.oportunidad.button.tracing-stage' | translate}}
              </button>
            </div>

          </div>

        </mat-card>
        <log-updated *ngIf="uid!='0'" [coduse]="registro.coduse" [fecupd]="registro.fecupd"></log-updated>

        <br>
        <br>
        <br>
        <br>
      </mat-tab>
      <mat-tab label="Especificaciones" labelClass="mat-tab-label-0-1">
        <mat-card class="mat-elevation-z0">

          <div class="pav-table-header-container">
            <div class="pav-table-header-icon">
            </div>
            <div class="pav-table-header-title">
              {{'apps.crm.maestros.plantilla.table.title' | translate}}
            </div>
          </div>

          <div class="overflow-x">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

              <ng-container matColumnDef="sec">
                <th mat-header-cell *matHeaderCellDef> {{'apps.crm.maestros.plantilla.table.headers.sec' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.secopv}} </td>
              </ng-container>

              <ng-container matColumnDef="order">
                <th mat-header-cell *matHeaderCellDef> {{'apps.crm.maestros.plantilla.table.headers.order' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.ordpod}} </td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> {{'apps.crm.maestros.plantilla.table.headers.description' |
                  translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.otr_descri}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

        </mat-card>
      </mat-tab>
    </mat-tab-group>

  </div>
  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
    urlBack="/modulo-crm/registros-de-oportunidad">
    <div id="botonesAdicionales">

    </div>
  </pav-form-actions>
</form>
