<app-pavso-title-section [title]="'Proveedor'" [module]="'Compras'" [usuario]="proveedor.cli_coduse"
  [fecha]="proveedor.cli_fecupd" [ngClass]="ngClassTitle" [estado]="proveedor.cli_indsta" [ngClass]="isDialog ? 'pav-dialog': 'pav-form'"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
    urlBack="/modulo-compras/proveedores" [ngStyle]="ngStyleControls">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>

  <div class="pavso-content-form fade-in-image">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input [(ngModel)]="proveedor.cli_codcli" type="text" matInput placeholder="Código"
              name="cli_codcli" [disabled]="loaderData">
            <button *ngIf="!loaderBuscarRUC" type="button" mat-icon-button matSuffix
              matTooltip="Buscar desde Consulta SUNAT" (click)="buscarEnRENIEC()">
              <mat-icon>search</mat-icon>
            </button>
            <button *ngIf="loaderBuscarRUC" type="button" mat-icon-button matSuffix>
              <mat-spinner diameter="20"></mat-spinner>
            </button>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo persona</mat-label>
            <mat-select [(ngModel)]="proveedor.cli_tipper" name="cli_tipper"
              (selectionChange)="seleccionarTipoPersona($event.value)">
              <mat-option *ngFor="let tipo of tiposPersona" [value]="tipo.tpe_codtpe">
                {{tipo.tpe_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo Doc.</mat-label>
            <mat-select required name="tipo_doc" [(ngModel)]="proveedor.did_coddid"
              (selectionChange)="seleccionarTipoDocumento($event.value)">
              <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.DID_CODDID">
                {{tipo.DID_DESABR}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3" *ngIf="proveedor.cli_tipper == 'N'">
          <mat-form-field>
            <mat-label>Nro. Documento</mat-label>
            <input type="text" matInput placeholder="Ej 70707070" name="cli_numdni" [(ngModel)]="proveedor.cli_numdni"
              (keyup)="validarDni()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="proveedor.cli_tipper == 'J'">
          <mat-form-field>
            <mat-label>Nro. Documento</mat-label>
            <input type="text" matInput placeholder="Ej. 2020202020203" name="cli_numruc"
              [(ngModel)]="proveedor.cli_numruc" min="0" max="99999999999" (keyup)="validarRuc()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l9" *ngIf="proveedor.cli_tipper == 'J'">
          <mat-form-field>
            <mat-label>Razon social</mat-label>
            <input type="text" matInput placeholder="Razon social" name="razonsocial"
              [(ngModel)]="proveedor.cli_nomcli">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="proveedor.cli_tipper == 'N'">
          <mat-form-field>
            <mat-label>Ape. Paterno</mat-label>
            <input type="text" matInput placeholder="Ape. Paterno" name="cli_apepat" [(ngModel)]="proveedor.cli_apepat">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="proveedor.cli_tipper == 'N'">
          <mat-form-field>
            <mat-label>Ape. Materno</mat-label>
            <input type="text" matInput placeholder="Ape. Materno" name="cli_apemat" [(ngModel)]="proveedor.cli_apemat">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3" *ngIf="proveedor.cli_tipper == 'N'">
          <mat-form-field>
            <mat-label>Nombres</mat-label>
            <input type="text" matInput placeholder="Nombres" name="cli_nombre" [(ngModel)]="proveedor.cli_nombre">
          </mat-form-field>
        </div>

      </div>

    </mat-card>

    <br>
    <mat-tab-group>
      <mat-tab label="Datos Generales" labelClass="mat-tab-label-0-0">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12">
              <mat-form-field>
                <mat-label>Nombre Comercial</mat-label>
                <input type="text" matInput [(ngModel)]="proveedor.cli_nomcom" placeholder="Nombre Comercial"
                  name="cli_nomcom" required>
              </mat-form-field>
            </div>
            <div class="col s12">
              <mat-form-field>
                <mat-label>Dirección</mat-label>
                <input type="text" [(ngModel)]="proveedor.cli_dircli" matInput placeholder="Dirección" name="cli_dircli"
                  required>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Pais</mat-label>
                <mat-select required name="pai_codpai" [(ngModel)]="proveedor.pai_codpai">
                  <mat-option *ngFor="let pais of paises" [value]="pais.PAI_CODPAI">
                    {{pais.pai_nomlar}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <select-search [label]="'Ubicación'" [placeholder]="'Ej. PROVEEDOR'" [name]="'codubi'" [value]="'codubi'"
                [description]="'desubi'" [data]="ubicaciones" (cambioSelect)="proveedor.ubi_codubi = $event"
                [model]="proveedor.ubi_codubi">
              </select-search>
              <!-- <mat-form-field>
                <mat-label>Ubicación</mat-label>
                <mat-select required name="ubicacion">
                  <mat-option *ngFor="let ubicacion of ubicaciones" [value]="ubicacion.ubi_codubi">
                    {{ubicacion.ubi_desubi}}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
            </div>
            <div class="col s12 m12 l3">
              <select-search [label]="'Ubigeo'" [placeholder]="'Ej. UBIGEO'" [name]="'ubs_codubs'"
                [value]="'ubs_codubs'" [description]="'ubs_descri'" [data]="ubigeos"
                (cambioSelect)="proveedor.ubs_codubs = $event" [model]="proveedor.ubs_codubs">
              </select-search>
              <!-- <mat-form-field>
                <mat-label>Ubigeo</mat-label>
                <mat-select required name="ubs_codubs" [(ngModel)]="proveedor.ubs_codubs">
                  <mat-option *ngFor="let ubigeo of ubigeos" [value]="ubigeo.ubs_codubs">
                    {{ubigeo.ubs_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
            </div>
            <div class="col s12 m3 l3">
              <mat-form-field>
                <mat-label>Registro Nacional</mat-label>
                <input type="text" matInput placeholder="Registro Nacional" name="registro_nacional" >
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m12 l3">
              <select-search [label]="'Condi. Pago'" [placeholder]="'Ej. CONTADO'" [name]="'CPA_CODCPA'"
                [value]="'CPA_CODCPA'" [description]="'CPA_DESABR'" [data]="condiciones"
                (cambioSelect)="proveedor.dato_clc.CPA_CODCPA = $event"
                [model]="proveedor.dato_clc.CPA_CODCPA"></select-search>
            </div>
            <div class="col s12 m12 l6">
              <mat-checkbox class="example-margin" [(ngModel)]="proveedor.cli_inddom"
                name="cli_inddom">Domiciliado</mat-checkbox>
              &nbsp;
              <mat-checkbox class="example-margin" [(ngModel)]="proveedor.cli_doccom" name="cli_doccom">Doc.
                Completos</mat-checkbox>

            </div>
            <div class="col s12 m3 l3">
              <mat-form-field>
                <mat-label>Importe de línea de crédito</mat-label>
                <input type="number" matInput placeholder="Ej. 10.00" name="monto">
              </mat-form-field>
            </div>
          </div>
        </mat-card>
        <!-- <mat-card class="mat-elevation-z0">
          <div class="row">

            <div class="col s12 m12 l6">
              <mat-form-field>
                <mat-label>Dirección</mat-label>
                <input type="text" matInput placeholder="Direccion" name="cli_dircli" [(ngModel)]="proveedor.cli_dircli">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <select-search [label]="'Sucursales'" [placeholder]="'Ej. Lima'" [name]="'SUC_CODSUC'"
                [value]="'SUC_CODSUC'" [description]="'suc_nomsuc'" [data]="[]"
                (cambioSelect)="proveedor.suc_codsuc = $event" [model]="proveedor.suc_codsuc"></select-search>
            </div>

            <div class="col s12 m12 l3">
              <select-search [label]="'País'" [placeholder]="'Ej. Perú'"
                [name]="'PAI_CODPAI'" [value]="'PAI_CODPAI'" [description]="'pai_nomlar'" [data]="paises"
                (cambioSelect)="proveedor.pai_codpai = $event; seleccionarPais($event)"
                [model]="proveedor.pai_codpai"></select-search>
            </div>

            <div class="col s12 m12 l3">
              <select-search [required]="proveedor.pai_codpai == '01'" [disabled]="proveedor.pai_codpai != '01'"
                [label]="'Ubicación'" [placeholder]="'Ej. Ubicación'" [name]="'codubi'" [value]="'codubi'"
                [description]="'desubi'" [data]="ubicaciones" (cambioSelect)="proveedor.ubi_codubi = $event"
                [model]="proveedor.ubi_codubi"></select-search>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Teléfono</mat-label>
                <input required type="tel" type="tel" matInput placeholder="Teléfono" name="cli_numtlf"
                  [(ngModel)]="proveedor.cli_numtlf">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Teléfono 2</mat-label>
                <input type="tel" type="tel" matInput placeholder="Teléfono 2" name="cli_numfax"
                  [(ngModel)]="proveedor.cli_numfax">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3" *ngIf="proveedor.cli_tipper == 'J'">
              <mat-form-field>
                <mat-label>Contacto</mat-label>
                <input type="text" matInput placeholder="Contacto" name="cli_nomcon" [(ngModel)]="proveedor.cli_nomcon">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input type="email" matInput placeholder="E-mail" name="cli_corele" [(ngModel)]="proveedor.cli_corele">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3" *ngIf="proveedor.cli_tipper == 'J'">
              <select-search [label]="'Cargo'" [placeholder]="'Ej. Cargo'" [name]="'cca_codcca'"
                [description]="'cca_descri'" [data]="[]" (cambioSelect)="proveedor.cli_carcon = $event"
                [value]="proveedor.cli_carcon">
              </select-search>

            </div>

            <div class="col s12 m12 l3" *ngIf="proveedor.cli_tipper == 'J'">
              <select-search [label]="'Giro de Negocio'" [placeholder]="'Ej. Ejemplo'" [name]="'gno_codgno'"
                [description]="'gno_desesp'" [data]="[]" (cambioSelect)="proveedor.gno_codgno = $event"
                [value]="proveedor.gno_codgno">
              </select-search>
            </div>

            <div class="col s12 m12 l3">
              <select-search [required]="proveedor.pai_codpai == '01'" [disabled]="proveedor.pai_codpai != '01'"
                [label]="'Ubig. Sunat'" [placeholder]="'Ubig. Sunat'" [name]="'ubs_codubs'" [value]="'ubs_codubs'"
                [description]="'ubs_descri'" [data]="[]" (cambioSelect)="proveedor.ubs_codubs = $event"
                [model]="proveedor.ubs_codubs"></select-search>
            </div>

            <div class="col s12">
              <mat-form-field>
                <mat-label>Obligaciones tributarias</mat-label>
                <textarea readonly matInput placeholder="Obligaciones tributarias" name="obligaciones"></textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-card> -->
      </mat-tab>

      <mat-tab label="Contactos" labelClass="mat-tab-label-0-1">
        <div class="container">

          <div class="row">
            <div class="col s12">
              <div class="pav-table-header-container">
                <div class="pav-table-header-icon">

                </div>
                <div class="pav-table-header-title">
                  Contactos
                </div>
                <div class="pav-table-header-add">
                  <div class="isMobile">
                    <div class="pav-button-icon-add" (click)="agregarContacto(null)">
                      <mat-icon svgIcon="add"></mat-icon>
                    </div>
                  </div>
                  <div class="isWeb">
                    <button mat-flat-button class="pav-button-add-item" type="button"
                      (click)="agregarContacto(null)">Agregar ítem</button>
                  </div>
                </div>
              </div>
              <div class="mat-elevation-z0 overflow-x">
                <table mat-table [dataSource]="dataSourceContactos" matSort>

                  <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td mat-cell *matCellDef="let row" data-label="Acciones">
                      <div class="pav-btns-container">
                        <div (click)="agregarContacto(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                          <mat-icon svgIcon="edit-white"></mat-icon>
                        </div>
                        <div class="pav-btn-circle-delete" (click)="quitarContacto(row)" matTooltip="Eliminar">
                          <mat-icon svgIcon="delete"></mat-icon>
                        </div>
                      </div>

                    </td>
                  </ng-container>
                  <ng-container matColumnDef="codigo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Código"> {{row.ccl_codccl}} </td>
                  </ng-container>

                  <ng-container matColumnDef="nombre">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Nombre">
                      <ng-container>
                        {{row.ccl_nomccl}}
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="cargo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cargo </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Cargo">

                      <ng-container>
                        {{row.cca_desabr}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="telefono">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Teléfono">

                      <ng-container>
                        {{row.ccl_numtlf}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="correo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Correo </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Correo">

                      <ng-container>
                        {{row.ccl_corele}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fecha_nacimiento">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de nacimiento </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Fecha de nacimiento">

                      <ng-container>
                        {{row.ccl_cumple | date: 'dd/MM/yyyy': 'UTC'}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="referencia">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Referencia">

                      <ng-container>
                        {{row.ccl_glosa}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="cobranza">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cobranza </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Cobranza">

                      <ng-container>
                        {{(row.ccl_indcob)?"SI": "NO"}}
                      </ng-container>

                    </td>
                  </ng-container>


                  <tr mat-header-row *matHeaderRowDef="displayedColumnsContactos"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsContactos;"></tr>

                  <tr class="mat-row" *matNoDataRow>

                    <td class="mat-cell" colspan="9">No se encontraron registros</td>

                  </tr>

                </table>
                <mat-paginator #paginatorContactos showFirstLastButtons #paginatorContacto
                  [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
              </div>
            </div>
          </div>
        </div>
        `
      </mat-tab>

      <mat-tab label="Establecimientos" labelClass="mat-tab-label-0-2">
        <div class="container">
          <div class="row">
            <div class="col s12">
              <div class="pav-table-header-container">
                <div class="pav-table-header-icon">

                </div>
                <div class="pav-table-header-title">
                  Establecimientos
                </div>
                <div class="pav-table-header-add">
                  <div class="isMobile">
                    <div class="pav-button-icon-add" (click)="agregarEstablecimiento(null)">
                      <mat-icon svgIcon="add"></mat-icon>
                    </div>
                  </div>
                  <div class="isWeb">
                    <button mat-flat-button class="pav-button-add-item" type="button"
                      (click)="agregarEstablecimiento(null)">Agregar ítem</button>
                  </div>
                </div>
              </div>
              <div class="mat-elevation-z0 overflow-x">
                <table mat-table [dataSource]="dataSourceEstablecimientos" matSort>

                  <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td mat-cell *matCellDef="let row" data-label="Acciones">
                      <div class="pav-btns-container">
                        <div (click)="agregarEstablecimiento(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                          <mat-icon svgIcon="edit-white"></mat-icon>
                        </div>
                        <div class="pav-btn-circle-delete" (click)="quitarEstablecimiento(row)" matTooltip="Eliminar">
                          <mat-icon svgIcon="delete"></mat-icon>
                        </div>
                      </div>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="codigo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. </th>
                    <td mat-cell *matCellDef="let row" data-label="Cod."> {{row.LDE_CODLDE}} </td>
                  </ng-container>

                  <ng-container matColumnDef="principal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Es Principal </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Principal">
                      <ng-container>
                        {{(row.LDE_INDPRI)?"SI": "NO"}}
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="direccion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Dirección </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Dirección">

                      <ng-container>
                        {{row.LDE_DIRLDE}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="referencia">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Referencia">

                      <ng-container>
                        {{row.LDE_REFDIR}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="pais">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> País </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Pais">

                      <ng-container>
                        {{row.PAI_DESPAI}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="ubicacion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Ubicación </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Ubicación">

                      <ng-container>
                        {{row.UBI_DESUBI}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="zona">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Zona </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Zona">

                      <ng-container>
                        {{row.ZVE_DESZVE}}
                      </ng-container>

                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsEstablecimientos"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsEstablecimientos;"></tr>

                  <tr class="mat-row" *matNoDataRow>

                    <td class="mat-cell" colspan="8">No se encontraron registros</td>

                  </tr>

                </table>
                <mat-paginator showFirstLastButtons #paginatorEstablecimientos
                  [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Servicios Ofrecidos" labelClass="mat-tab-label-0-3">

        <div class="container">
          <div class="row">
            <div class="col s12">
              <div class="pav-table-header-container">
                <div class="pav-table-header-icon">

                </div>
                <div class="pav-table-header-title">
                  Servicios Ofrecidos
                </div>
                <div class="pav-table-header-add">
                  <div class="isMobile">
                    <div class="pav-button-icon-add" (click)="agregarServicio()">
                      <mat-icon svgIcon="add"></mat-icon>
                    </div>
                  </div>
                  <div class="isWeb">
                    <button mat-flat-button class="pav-button-add-item" type="button"
                      (click)="agregarServicio()">Agregar ítem</button>
                  </div>
                </div>
              </div>
              <div class="mat-elevation-z0 overflow-x">
                <table mat-table [dataSource]="dataSourceServicios" matSort>

                  <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td mat-cell *matCellDef="let row" data-label="Acciones">
                      <div class="pav-btns-container">
                        <div class="pav-btn-circle-delete" (click)="quitarServicio(row)" matTooltip="Eliminar">
                          <mat-icon svgIcon="delete"></mat-icon>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="codigo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Servicio </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                      <ng-container *ngIf="row.isEditing">
                        <mat-form-field class="mat-form-field-item">
                          <mat-label></mat-label>
                          <mat-select required [(ngModel)]="row.tse_codtse" [name]="'tse_codtse'+i"
                            (selectionChange)="seleccionarServicio($event, row)">
                            <mat-option *ngFor="let servicio of servicios"
                              [value]="servicio.TSE_CODTSE">{{servicio.TSE_DESTSE}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngIf="!row.isEditing">
                        {{row.tse_descri}}
                      </ng-container>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsServicios"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsServicios;"></tr>

                  <tr class="mat-row" *matNoDataRow>

                    <td class="mat-cell" colspan="2">No se encontraron registros</td>

                  </tr>
                </table>
                <mat-paginator #paginatorServicios [pageSizeOptions]="[5, 10, 25, 100]"
                  aria-label="Select page of users">

                </mat-paginator>

              </div>
            </div>
          </div>
        </div>

      </mat-tab>

      <mat-tab label="Cuentas Bancarias" labelClass="mat-tab-label-0-4">

        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">

          </div>
          <div class="pav-table-header-title">
            Cuentas bancarias
          </div>
          <div class="pav-table-header-add">
            <div class="isMobile">
              <div class="pav-button-icon-add" (click)="agregarCuenta()">
                <mat-icon svgIcon="add"></mat-icon>
              </div>
            </div>
            <div class="isWeb">
              <button mat-flat-button class="pav-button-add-item" type="button" (click)="agregarCuenta()">Agregar
                ítem</button>
            </div>
          </div>
        </div>

        <div class="mat-elevation-z0">
          <table mat-table [dataSource]="dataSourceCuenta" matSort>
            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">

                <div class="pav-btns-container">
                  <div class="pav-btn-circle-delete" (click)="quitarCuenta(row)" matTooltip="Quitar cuenta">
                    <mat-icon svgIcon="delete"></mat-icon>
                  </div>
                </div>

              </td>
            </ng-container>

            <ng-container matColumnDef="correlativo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlativo </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Correlativo">
                <mat-form-field>
                  <mat-label></mat-label>
                  <input matInput placeholder="Ej. 001" readonly [(ngModel)]="row.correlativo"
                    [name]="'correlativo' + i">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="banco">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Banco </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Banco">
                <select-search [label]="''" [placeholder]="'Ej. BCP'" [name]="'BAN_CODBAN' + i" [value]="'BAN_CODBAN'"
                  [description]="'BAN_NOMABR'" [data]="bancos" (cambioSelect)="row.banco = $event" [model]="row.banco">
                </select-search>
              </td>
            </ng-container>

            <ng-container matColumnDef="moneda">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Moneda">
                <mat-form-field>
                  <mat-label></mat-label>
                  <mat-select [(ngModel)]="row.moneda" [name]="'moneda' + i">
                    <mat-option value="SO">Soles</mat-option>
                    <mat-option value="DO">Dólares</mat-option>
                  </mat-select>
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="nro_cuenta">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Cuenta </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Nro. Cuenta">
                <mat-form-field>
                  <mat-label></mat-label>
                  <input matInput placeholder="Ej. 001" readonly [(ngModel)]="row.nroCuenta" [name]="'nroCuenta' + i">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="cuenta_interbancaria">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Interbancaria </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Cuenta Interbancaria">
                <mat-form-field>
                  <mat-label></mat-label>
                  <input matInput placeholder="Ej. 001" readonly [(ngModel)]="row.cuentaInterbancaria"
                    [name]="'cuentaInterbancaria' + i">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="glosa">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Glosa">
                <mat-form-field>
                  <mat-label></mat-label>
                  <input matInput placeholder="Ej. 001" readonly [(ngModel)]="row.glosa" [name]="'glosa' + i">
                </mat-form-field>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsCuenta"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsCuenta;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="7">No se encontraron registros</td>
            </tr>

          </table>

          <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </mat-tab>
    </mat-tab-group>

  </div>

</form>
