export class FiltroReporteCuentaPagar {

  moneda: string;
  proveedores: Array<any>;
  sucursales: Array<any>;
  tiposDocumento: Array<any>;
  cuentasContable: Array<any>;
  fechaCorte: Date;
  estado: string;
  ordenadoPor: string;

  constructor() {
    this.proveedores = [];
    this.sucursales = [];
    this.tiposDocumento = [];
    this.cuentasContable = [];
    this.fechaCorte = new Date();
    this.moneda = 'SO';
  }

}
