import { AprobacionPedidosComponent } from "./aprobacion-pedidos/aprobacion-pedidos.component";
import { ComprobanteFormComponent, ComprobanteListComponent } from "./comprobante";
import { ComprobanteBasicoFormComponent, ComprobanteBasicoListComponent } from "./comprobante-basico";
import { CotizacionesBottomSheet, CotizacionesFormComponent, CotizacionesListComponent } from "./cotizaciones";
import { EventosVentaComponent } from "./eventos-venta/eventos-venta.component";
import { FacturaModule } from "./factura/factura.module";
import { GeneraFacturaPedidoComponent } from "./genera-facura-pedido";
import { CopyFromOrderComponent, PedidoBottomSheet, PedidoFormComponent, PedidoListComponent, PedidoListDialog, ProductoMultipleDialog } from "./pedido";
import { PermisosAprobacionPedidoComponent } from "./permisos-aprobacion-pedido/permisos-aprobacion-pedido.component";
import { CopiarPresupuestoDialog, PresupuestoCotizacionDecoracionFormComponent, PresupuestoCotizacionDecoracionListComponent } from "./presupuesto-cotizacion-decoracion";

export const operacionesComponentsVentas = [
  GeneraFacturaPedidoComponent,
  CotizacionesFormComponent,
  CotizacionesListComponent,
  CotizacionesBottomSheet,
  PedidoFormComponent,
  PedidoListComponent,
  PedidoListDialog,
  PedidoBottomSheet,
  ComprobanteFormComponent,
  ComprobanteListComponent,
  ComprobanteBasicoFormComponent,
  ComprobanteBasicoListComponent,
  EventosVentaComponent,
  AprobacionPedidosComponent,
  PermisosAprobacionPedidoComponent,
  PresupuestoCotizacionDecoracionListComponent,
  PresupuestoCotizacionDecoracionFormComponent,
  CopiarPresupuestoDialog,
  ProductoMultipleDialog,
  CopyFromOrderComponent
]

export const operacionesVentasModules = [
  FacturaModule
]
