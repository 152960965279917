<div [ngStyle]="{display: 'flex', width: '100%'}">
  <mat-form-field [ngClass]="clase" [ngStyle]="getFormFieldStyle()" [appearance]="appearance">
    <mat-label>{{label}}</mat-label>
    <mat-select [ngClass]="setColorSelected? 'selected-option': ''" [(ngModel)]="model" [required]="required" (selectionChange)="seleccionar($event)"
      [name]="name" [placeholder]="placeholder" #inputSelect [multiple]="multiple" [disabled]="disabled">

      <mat-option>
        <ngx-mat-select-search noEntriesFoundLabel="'No se encontraron coincidencias'" placeholderLabel="Buscar ..."
          [formControl]="filterCtrl"></ngx-mat-select-search>
      </mat-option>

      <mat-option *ngIf="!multiple" (click)="model=null">Ninguno</mat-option>
      <div *ngFor="let item of filtered | async ; trackBy: trackByFn" [ngStyle]="{display: 'flex'}">
        <mat-option [value]="item[value]" [ngStyle]="{flexGrow: 1}" >
          {{item[description]}}
        </mat-option>
      </div>
    </mat-select>
    <!-- <mat-hint *ngIf="model">CÓDIGO: {{model}}</mat-hint> -->

  </mat-form-field>
  <button (click)="clickButton()" *ngIf="tooltipAction && iconAction" mat-icon-button matSuffix [matTooltip]="showTooltip ? tooltipAction: ''"  type="button" [ngStyle]="{marginTop: '5px', color: '#00205F'}">
    <mat-icon>{{iconAction}}</mat-icon>
  </button>
  <!-- <mat-icon *ngIf="!refreshing" [ngStyle]="{fontSize: '20px', marginTop: '12px', cursor: 'pointer'}" (click)="refreshing = true">refresh</mat-icon>
  <mat-spinner *ngIf="refreshing" [ngStyle]="{marginTop: '12px'}" diameter="16"></mat-spinner> -->

</div>



