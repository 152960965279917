import { Component, OnDestroy, OnInit } from '@angular/core';
import { CajaBancoService, ConfigurationService, ContabilidadService, VentasService, ExportExcelService, GeneralService, ComprasService } from 'src/app/services';
import { formatDateClean } from 'src/app/utils/formats/date.format';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { number2month } from 'src/app/utils/conversions/number2month.conversion';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { REPORTES_DINAMICOS_CONTABILIDAD } from '@data/json/reportes/contabilidad.reporte.json';

type binario = 0 | 1;

class FiltroReporteDinamico {
  monedaSelected: any;
  cuentaSelected: any;
  subdiarioSelected: any;
  centroSelected: any;
  auxiliarSelected: any;
  clienteSelected: any;
  proveedorSelected: any;
  flujoSelected: any;
  cuentasBancoSelected: any;
  digitoSelected: any;

  constructor() {
    this.monedaSelected = 'SO';
    this.digitoSelected = '6';
  }
}
@Component({
  selector: 'app-reporte-dinamico-contabilidad',
  templateUrl: './reporte-dinamico-contabilidad.component.html',
  styleUrls: ['./reporte-dinamico-contabilidad.component.css']
})
export class ReporteDinamicoContabilidadComponent implements OnInit, OnDestroy {

  loaderFields: boolean = false;

  task: any = {
    name: `Columnas`,
    completed: false,
    color: 'primary',
    subtasks: []
  };

  allComplete: boolean = false;

  updateAllComplete(): void {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) return false;

    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) return;

    this.task.subtasks.forEach(t => t.completed = completed);
  }

  centros: any[] = [];
  flujosCaja: any[] = [];
  cuentasBanco: any[] = [];
  monedas: any[] = [];

  dataForExcel = [];

  buttonsName: INameConstant = NAMES_CONSTANTS;

  range: FormGroup;

  columnas: any[] = [];

  reporteSeleccionado: any = ['CONTABILIDAD_RC'];

  loaderData: boolean = false;
  loaderReg: boolean = false;

  ind_centrocosto: binario = 0;
  ind_auxiliar: binario = 0;
  ind_cuenta: binario = 0;
  ind_digitos: binario = 0;
  ind_flujocaja: binario = 0;
  ind_cuenta_banco: binario = 0;
  ind_fecha: binario = 0;
  ind_subdiario: binario = 0;
  ind_monedaS: binario = 0;
  ind_cabecera: binario = 0;

  cuentas: any[] = [];
  subdiarios: any[] = [];
  clientes: any[] = [];
  proveedores: any[] = [];
  auxiliares: any[] = [];

  year: any;
  month: any;

  reportes: any[] = REPORTES_DINAMICOS_CONTABILIDAD;

  period$: Subscription;
  loading$: Subscription;
  cuentas$: Subscription;
  subdiarios$: Subscription;
  clientes$: Subscription;
  monedas$: Subscription;
  centros$: Subscription;
  cuentasBancarias$: Subscription;
  flujos$: Subscription;

  filtro: FiltroReporteDinamico;

  constructor(
    private _snackBarService: SnackBarService,
    private _contabilidadService: ContabilidadService,
    private _ventasService: VentasService,
    private _comprasService: ComprasService,
    private _cajaBancoService: CajaBancoService,
    private _generalService: GeneralService,
    private _configurationService: ConfigurationService,
    public ete: ExportExcelService,
    private _clienteService: ClienteService,
    private store: Store<PavsoState>
  ) {

    this.filtro = new FiltroReporteDinamico();

    this.period$ = this.store.select('period').subscribe(({ year, month }) => {
      this.year = year;
      this.month = month;
    })

    const today = new Date();

    const month = today.getMonth();
    const year = today.getFullYear();

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, today.getDate()))
    });
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) {
        this.loadData()
      };
    })
  }

  loadData(): void {

    this.loaderData = true;

    this.cuentas$ = this._contabilidadService.listarPlanDeCuentas().subscribe(
      cuentas => {
        this.cuentas = cuentas;

        this.subdiarios$ = this._contabilidadService.obtenerSubdiarios().subscribe(
          subdiarios => {
            this.subdiarios = subdiarios;

            this.clientes$ = this._clienteService.obtenerClientes().subscribe(
              clientes => {
                this.clientes = clientes;
                this.auxiliares = clientes.filter(item => item.cli_indtra == 1);
                this.proveedores = clientes.filter(item => item.cli_indpro == 1);

                this.monedas$ = this._generalService.obtenerMonedas().subscribe(
                  monedas => {
                    this.monedas = monedas;

                    this.centros$ = this._contabilidadService.listarCentroCostos().subscribe(
                      centros => {
                        this.centros = centros;

                        this.cuentasBancarias$ = this._cajaBancoService.obtenerCuentasBancarias().subscribe(
                          cuentasBancarias => {
                            this.cuentasBanco = cuentasBancarias;

                            this.flujos$ = this._cajaBancoService.obtenerFlujoCajas().subscribe(
                              flujos => {
                                this.flujosCaja = flujos;
                                this.loaderData = false;
                              },
                              error => {
                                this._snackBarService.showError('Error al obtener flujo de caja', 'Ok');
                                this.loaderData = false;
                              }
                            )
                          },
                          error => {
                            this._snackBarService.showError('Error al obtener cuentas bancarias', 'Ok');
                            this.loaderData = false;

                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener centros de costos', 'Ok');
                        this.loaderData = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener monedas', 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener clientes', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener subdiarios', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener cuentas', 'Ok');
        this.loaderData = false;
      }
    )

  }

  seleccionarReporte(event): void {
    this.reporteSeleccionado = [event.id];
    this.ind_digitos = 0;
    this.ind_monedaS = 0;
    this.ind_cabecera = 1;

    if (this.reporteSeleccionado[0] == "CONTABILIDAD_RC") {
      console.log('reporte registro compra')
      this.loaderFields = true;

      this._comprasService.listarColumnasInformeRegCompras('41').subscribe(
        columnas => {
          this.columnas = columnas;
          this.columnas.forEach(element => {
            element['name'] = element.TDR_DESTDR;
            element['completed'] = true;
          });

          this.task.subtasks = this.columnas;

          this._contabilidadService.obtenerInidicadoresFiltro("41001").subscribe(
            indicadores => {
              this.ind_centrocosto = indicadores[0].ind_centrocosto;
              this.ind_auxiliar = indicadores[0].ind_auxiliar;
              this.ind_cuenta = indicadores[0].ind_cuenta;
              this.ind_digitos = indicadores[0].ind_digitos;
              this.ind_flujocaja = indicadores[0].ind_flujocaja;
              this.ind_cuenta_banco = indicadores[0].ind_cuenta_banco;
              this.ind_fecha = indicadores[0].ind_fecha;
              this.ind_subdiario = indicadores[0].ind_subdiario;

              this.loaderFields = false;
            },
            error => {
              this._snackBarService.showError("Error al obtener indicadores de filtro", "OK");
              this.loaderFields = false;
            }
          )
        },
        error => {
          this._snackBarService.showError('Error al obtener columnas de informe facturación detallada', 'OK');
          this.loaderFields = false;
        }
      )
    } else if (this.reporteSeleccionado[0] == "CONTABILIDAD_RV") {
      this.loaderFields = true;
      this._generalService.listarColumnasInformeDinamico('41', '003').subscribe(
        columnas => {
          console.log('columnas', columnas)
          this.columnas = columnas;
          this.columnas.forEach(element => {
            element['name'] = element.TDR_DESTDR;
            element['completed'] = true;
          });

          this.task.subtasks = this.columnas;
          this._contabilidadService.obtenerInidicadoresFiltro("41003").subscribe(
            indicadores => {
              this.loaderFields = false;

              this.ind_centrocosto = indicadores[0].ind_centrocosto;
              this.ind_auxiliar = indicadores[0].ind_auxiliar;
              this.ind_cuenta = indicadores[0].ind_cuenta;
              this.ind_digitos = indicadores[0].ind_digitos;
              this.ind_flujocaja = indicadores[0].ind_flujocaja;
              this.ind_cuenta_banco = indicadores[0].ind_cuenta_banco;
              this.ind_fecha = indicadores[0].ind_fecha;
              this.ind_subdiario = indicadores[0].ind_subdiario;
            },
            error => {
              this._snackBarService.showError("Error al obtener indicadores de filtro", "OK");
              this.loaderFields = false;
            }
          )
        },
        error => {
          this._snackBarService.showError('Error al obtener columnas de informe facturación detallada', 'OK');
          this.loaderFields = false;
        }
      )
    } else if (this.reporteSeleccionado[0] == "CONTABILIDAD_AC") {
      console.log('reporte asiento contable')
      this.loaderFields = true;
      this._contabilidadService.listarColumnasInformeRegAsientos('41').subscribe(
        columnas => {
          console.log('columnas', columnas)
          this.columnas = columnas;
          this.columnas.forEach(element => {
            element['name'] = element.TDR_DESTDR;
            element['completed'] = true;
          });

          this.task.subtasks = this.columnas;
          this._contabilidadService.obtenerInidicadoresFiltro("41002").subscribe(
            indicadores => {
              this.loaderFields = false;

              this.ind_centrocosto = indicadores[0].ind_centrocosto;
              this.ind_auxiliar = indicadores[0].ind_auxiliar;
              this.ind_cuenta = indicadores[0].ind_cuenta;
              this.ind_digitos = indicadores[0].ind_digitos;
              this.ind_flujocaja = indicadores[0].ind_flujocaja;
              this.ind_cuenta_banco = indicadores[0].ind_cuenta_banco;
              this.ind_fecha = indicadores[0].ind_fecha;
              this.ind_subdiario = indicadores[0].ind_subdiario;
            },
            error => {
              this._snackBarService.showError("Error al obtener indicadores de filtro", "OK");
              this.loaderFields = false;
            }
          )
        },
        error => {
          this._snackBarService.showError('Error al obtener columnas de informe facturación detallada', 'OK');
          this.loaderFields = false;
        }
      )

    } else if (this.reporteSeleccionado[0] == "CONTABILIDAD_RH") {
      console.log('reporte registro honorarios')
      this.ind_monedaS = 1;
      this.ind_cabecera = 0;
      this.columnas = [
        {
          name: "AÑO",
          TDR_DESTDR: "AÑO",
          TDR_COLTDR: "ano_codano",
          completed: true
        },
        {
          name: "MES",
          TDR_DESTDR: "MES",
          TDR_COLTDR: "mes_codmes",
          completed: true
        },
        {
          name: "NÚMERO CORRELATIVO",
          TDR_DESTDR: "NÚMERO CORRELATIVO",
          TDR_COLTDR: "ldc_corldc",
          completed: true
        },
        {
          name: "FECHA DE EMISIÓN",
          TDR_DESTDR: "FECHA DE EMISIÓN",
          TDR_COLTDR: "cco_fecemi",
          completed: true
        },
        {
          name: "FECHA DE PAGO",
          TDR_DESTDR: "FECHA DE PAGO",
          TDR_COLTDR: "cco_fecven",
          completed: true
        },
        {
          name: "TIPO",
          TDR_DESTDR: "TIPO",
          TDR_COLTDR: "tdo_codofi",
          completed: true
        },
        {
          name: "SERIE",
          TDR_DESTDR: "SERIE",
          TDR_COLTDR: "serie",
          completed: true
        },
        {
          name: "N° DEL COMPRO.",
          TDR_DESTDR: "N° DEL COMPRO.",
          TDR_COLTDR: "cco_numdoc",
          completed: true
        },
        {
          name: "TIPO DOC. IDENT.",
          TDR_DESTDR: "TIPO DOC. IDENT.",
          TDR_COLTDR: "t02_coddoc",
          completed: true
        },
        {
          name: "NÚMERO DOC.",
          TDR_DESTDR: "NÚMERO DOC.",
          TDR_COLTDR: "cli_codcli",
          completed: true
        },
        {
          name: "APELLIDOS Y NOMBRES",
          TDR_DESTDR: "APELLIDOS Y NOMBRES",
          TDR_COLTDR: "cli_nomcli",
          completed: true
        },
        {
          name: "BASE IMPONIBLE",
          TDR_DESTDR: "BASE IMPONIBLE",
          TDR_COLTDR: "bas_basimp",
          completed: true
        },
        {
          name: "IMPUESTO A LA RENTA",
          TDR_DESTDR: "IMPUESTO A LA RENTA",
          TDR_COLTDR: "imp_impren",
          completed: true
        },
        {
          name: "IMPORTE NETO",
          TDR_DESTDR: "IMPORTE NETO",
          TDR_COLTDR: "imp_impnet",
          completed: true
        },
      ];
      this.task.subtasks = this.columnas;

      this.ind_centrocosto = 0;
      this.ind_auxiliar = 0;
      this.ind_cuenta = 0;
      this.ind_digitos = 0;
      this.ind_flujocaja = 0;
      this.ind_cuenta_banco = 0;
      this.ind_fecha = 0;
      this.ind_subdiario = 0;

    } else if (this.reporteSeleccionado[0] == "CONTABILIDAD_BCEF") {
      console.log('reporte registro comprobante ee.ff.')
      this.ind_monedaS = 1;
      this.ind_cabecera = 0;

      this.columnas = [
        {
          name: "CUENTA",
          TDR_DESTDR: "CUENTA",
          TDR_COLTDR: "cuenta",
          completed: true
        },
        {
          name: "DESCRIPCIÓN",
          TDR_DESTDR: "DESCRIPCIÓN",
          TDR_COLTDR: "descri",
          completed: true
        },
        {
          name: "SALDO INICIAL DEBE",
          TDR_DESTDR: "SALDO INICIAL DEBE",
          TDR_COLTDR: "saldoIniDebe",
          completed: true
        },
        {
          name: "SALDO INICIAL HABER",
          TDR_DESTDR: "SALDO INICIAL HABER",
          TDR_COLTDR: "saldoIniHabe",
          completed: true
        },
        {
          name: "MOVIMIENTO MES DEBE",
          TDR_DESTDR: "MOVIMIENTO MES DEBE",
          TDR_COLTDR: "movimientoDebe",
          completed: true
        },
        {
          name: "MOVIMIENTO MES HABER",
          TDR_DESTDR: "MOVIMIENTO MES HABER",
          TDR_COLTDR: "movimientoHaber",
          completed: true
        },
        {
          name: "SALDO CIERRE DEUDOR",
          TDR_DESTDR: "SALDO CIERRE DEUDOR",
          TDR_COLTDR: "saldoCierreDeudor",
          completed: true
        },
        {
          name: "SALDO CIERRE ACREEDOR",
          TDR_DESTDR: "SALDO CIERRE ACREEDOR",
          TDR_COLTDR: "saldoCierreAcreedor",
          completed: true
        },
        {
          name: "CUENTA INVENTARIO ACTIVO",
          TDR_DESTDR: "CUENTA INVENTARIO ACTIVO",
          TDR_COLTDR: "cuentaInventarioActivo",
          completed: true
        },
        {
          name: "CUENTA INVENTARIO PASIVO",
          TDR_DESTDR: "CUENTA INVENTARIO PASIVO",
          TDR_COLTDR: "cuentaInventarioPasivo",
          completed: true
        },
        {
          name: "RESULTADO FUNCIÓN PÉRDIDAS",
          TDR_DESTDR: "RESULTADO FUNCIÓN PÉRDIDAS",
          TDR_COLTDR: "resultadoFuncionPerdida",
          completed: true
        },
        {
          name: "RESULTADO FUNCIÓN GANANCIAS",
          TDR_DESTDR: "RESULTADO FUNCIÓN GANANCIAS",
          TDR_COLTDR: "resultadoFuncionGanancia",
          completed: true
        },
        {
          name: "RESULTADO NATURALEZA PERDIDAS",
          TDR_DESTDR: "RESULTADO NATURALEZA PERDIDAS",
          TDR_COLTDR: "resultadoNaturalezaPerdida",
          completed: true
        },
        {
          name: "RESULTADO NATURALEZA GANANCIAS",
          TDR_DESTDR: "RESULTADO NATURALEZA GANANCIAS",
          TDR_COLTDR: "resultadoNaturalezaGanancia",
          completed: true
        },

      ];
      this.task.subtasks = this.columnas;

      this.ind_centrocosto = 0;
      this.ind_auxiliar = 0;
      this.ind_cuenta = 0;
      this.ind_digitos = 1;
      this.ind_flujocaja = 0;
      this.ind_cuenta_banco = 0;
      this.ind_fecha = 0;
      this.ind_subdiario = 0;
    }

  }

  verReporte(): void {
    const consulta = this.columnas.map(item => item.TDR_COLTDR).join(',')
    this.loaderReg = true;

    let campos = [];
    this.columnas.forEach(element => {
      campos.push({
        completado: element.completed,
        campo: element.TDR_COLTDR,
      })
    })

    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    let body = {
      clientes: [],
      vendedores: [],
      condicion_pago: [],
      tipo_documentos: [],
      motivos: [],
      series: [],
      tiendas: [],
      fechas: {
        fecha_ini: formatDateClean(new Date(year, month, 1)),
        fecha_fin: formatDateClean(new Date(year, month, today.getDate()))
      },
      monedas: [],
      campos,
      productos: [],
      inventarios: [],
      lineas: [],
      marcas: [],
      sqlFields: consulta
    }

    if (this.reporteSeleccionado[0] == "CONTABILIDAD_RV") {

      this._contabilidadService.obtenerReporteDinamicoRegVenta(this.year, this.month, body).subscribe(
        reporteVentas => {
          this.loaderReg = false;

          this.exportToExcel(reporteVentas);
        },
        error => {
          this._snackBarService.showError(error.error.msg, 'OK')
          this.loaderReg = false;
        }

      )
    } else if (this.reporteSeleccionado[0] == "CONTABILIDAD_RC") {

      this._contabilidadService.obtenerReporteDinamicoRegCompra(this.year, this.month, body).subscribe(
        reporteCompras => {
          this.loaderReg = false;
          this.exportToExcel(reporteCompras);
        },
        error => {
          this._snackBarService.showError(error.error.msg, 'OK')
          this.loaderReg = false;
        }
      )
    } else if (this.reporteSeleccionado[0] == "CONTABILIDAD_AC") {

      this._contabilidadService.obtenerReporteDinamicoAsientoContable(this.year, this.month, body).subscribe(
        reporteAsientos => {
          this.loaderReg = false;
          this.exportToExcel(reporteAsientos);
        },
        error => {
          this.loaderReg = false;
          this._snackBarService.showError(error.error.msg, 'OK')
        }
      )
    } else if (this.reporteSeleccionado[0] == "CONTABILIDAD_RH") {

      this._contabilidadService.obtenerReporteDinamicoRegistroHonorario(this.year, this.month, "1501", this.filtro.monedaSelected, body).subscribe(
        response => {
          this.loaderReg = false;
          this.exportToExcel(response);
        },
        error => {
          this.loaderReg = false;
          this._snackBarService.showError(error.error.msg, 'OK')
        }
      )

    } else if (this.reporteSeleccionado[0] == "CONTABILIDAD_BCEF") {

      this._contabilidadService.obtenerReporteDinamicoBalanceComprobacion(this.year, this.month, this.filtro.monedaSelected, body).subscribe(
        response => {

          // filtro
          let responseFiltrado = [];
          if (this.filtro.digitoSelected == "6") {

            responseFiltrado = response.map(item => {
              const saldoIniDebe = (item.DEBE_INI > item.HABER_INI) ? item.DEBE_INI - item.HABER_INI : 0;
              const saldoIniHabe = (item.HABER_INI > item.DEBE_INI) ? item.HABER_INI - item.DEBE_INI : 0;
              const saldoCierreDeudor = ((saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA) > 0) ? saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA : 0;
              const saldoCierreAcreedor = ((saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA) < 0) ? Math.abs(saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA) : 0;
              const cuentaInventarioActivo = (item.CCN_INDCLB == '1') ? saldoCierreDeudor : 0;
              const cuentaInventarioPasivo = (item.CCN_INDCLB == '1') ? saldoCierreAcreedor : 0;
              const resultadoFuncionPerdida = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '4' || item.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
              const resultadoFuncionGanancia = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '4' || item.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;
              const resultadoNaturalezaPerdida = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '3' || item.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
              const resultadoNaturalezaGanancia = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '3' || item.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;

              return {
                cuenta: item.CCN_CODCCN,
                descri: item.CCN_DESCRI,
                saldoIniDebe,
                saldoIniHabe,
                movimientoDebe: item.SCU_DEBMNA,
                movimientoHaber: item.SCU_HABMNA,
                saldoCierreDeudor,
                saldoCierreAcreedor,
                cuentaInventarioActivo,
                cuentaInventarioPasivo,
                resultadoFuncionPerdida,
                resultadoFuncionGanancia,
                resultadoNaturalezaPerdida,
                resultadoNaturalezaGanancia,
              }

            });
          } else if (this.filtro.digitoSelected == "4") {
            response.forEach(item => {
              item["CCN_CODCCN4"] = item.CCN_CODCCN.substr(0, 4);
            });

            let agrupado = this.agrupar(response, 'CCN_CODCCN4');
            let keys = Object.keys(agrupado);

            let primeraAgrupacion = [];
            keys.forEach(key => {
              let encontrado = response.find(element => element.CCN_CODCCN4 == key)

              let [tSID, tSIH, tMD, tMH, tSCD, tSCA, tCIA, tCIP, tRFP, tRFG, tRNP, tRNG] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

              agrupado[key].forEach(item => {
                const saldoIniDebe = (item.DEBE_INI > item.HABER_INI) ? item.DEBE_INI - item.HABER_INI : 0;
                const saldoIniHabe = (item.HABER_INI > item.DEBE_INI) ? item.HABER_INI - item.DEBE_INI : 0;
                const saldoCierreDeudor = ((saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA) > 0) ? saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA : 0;
                const saldoCierreAcreedor = ((saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA) < 0) ? Math.abs(saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA) : 0;
                const cuentaInventarioActivo = (item.CCN_INDCLB == '1') ? saldoCierreDeudor : 0;
                const cuentaInventarioPasivo = (item.CCN_INDCLB == '1') ? saldoCierreAcreedor : 0;
                const resultadoFuncionPerdida = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '4' || item.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
                const resultadoFuncionGanancia = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '4' || item.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;
                const resultadoNaturalezaPerdida = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '3' || item.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
                const resultadoNaturalezaGanancia = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '3' || item.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;

                tSID += saldoIniDebe;
                tSIH += saldoIniHabe;
                tMD += item.SCU_DEBMNA;
                tMH += item.SCU_HABMNA;
                tSCD += saldoCierreDeudor;
                tSCA += saldoCierreAcreedor;
                tCIA += cuentaInventarioActivo;
                tCIP += cuentaInventarioPasivo;
                tRFP += resultadoFuncionPerdida;
                tRFG += resultadoFuncionGanancia;
                tRNP += resultadoNaturalezaPerdida;
                tRNG += resultadoNaturalezaGanancia;
              });

              primeraAgrupacion.push({
                cuenta: key,
                descri: encontrado.DESCRICTA_4,
                saldoIniDebe: tSID.toFixed(2),
                saldoIniHabe: tSIH.toFixed(2),
                movimientoDebe: tMD.toFixed(2),
                movimientoHaber: tMH.toFixed(2),
                saldoCierreDeudor: tSCD.toFixed(2),
                saldoCierreAcreedor: tSCA.toFixed(2),
                cuentaInventarioActivo: tCIA.toFixed(2),
                cuentaInventarioPasivo: tCIP.toFixed(2),
                resultadoFuncionPerdida: tRFP.toFixed(2),
                resultadoFuncionGanancia: tRFG.toFixed(2),
                resultadoNaturalezaPerdida: tRNP.toFixed(2),
                resultadoNaturalezaGanancia: tRNG.toFixed(2),
              })
            });
            responseFiltrado = primeraAgrupacion;
          } else if (this.filtro.digitoSelected == "3") {
            response.forEach(item => {
              item["CCN_CODCCN3"] = item.CCN_CODCCN.substr(0, 3);
            });

            let agrupado = this.agrupar(response, 'CCN_CODCCN3');
            let keys = Object.keys(agrupado);

            let primeraAgrupacion = [];
            keys.forEach(key => {
              let encontrado = response.find(element => element.CCN_CODCCN3 == key)

              let [tSID, tSIH, tMD, tMH, tSCD, tSCA, tCIA, tCIP, tRFP, tRFG, tRNP, tRNG] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

              agrupado[key].forEach(item => {
                const saldoIniDebe = (item.DEBE_INI > item.HABER_INI) ? item.DEBE_INI - item.HABER_INI : 0;
                const saldoIniHabe = (item.HABER_INI > item.DEBE_INI) ? item.HABER_INI - item.DEBE_INI : 0;
                const saldoCierreDeudor = ((saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA) > 0) ? saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA : 0;
                const saldoCierreAcreedor = ((saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA) < 0) ? Math.abs(saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA) : 0;
                const cuentaInventarioActivo = (item.CCN_INDCLB == '1') ? saldoCierreDeudor : 0;
                const cuentaInventarioPasivo = (item.CCN_INDCLB == '1') ? saldoCierreAcreedor : 0;
                const resultadoFuncionPerdida = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '4' || item.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
                const resultadoFuncionGanancia = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '4' || item.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;
                const resultadoNaturalezaPerdida = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '3' || item.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
                const resultadoNaturalezaGanancia = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '3' || item.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;

                tSID += saldoIniDebe;
                tSIH += saldoIniHabe;
                tMD += item.SCU_DEBMNA;
                tMH += item.SCU_HABMNA;
                tSCD += saldoCierreDeudor;
                tSCA += saldoCierreAcreedor;
                tCIA += cuentaInventarioActivo;
                tCIP += cuentaInventarioPasivo;
                tRFP += resultadoFuncionPerdida;
                tRFG += resultadoFuncionGanancia;
                tRNP += resultadoNaturalezaPerdida;
                tRNG += resultadoNaturalezaGanancia;
              });

              primeraAgrupacion.push({
                cuenta: key,
                descri: encontrado.DESCRICTA_3,
                saldoIniDebe: tSID.toFixed(2),
                saldoIniHabe: tSIH.toFixed(2),
                movimientoDebe: tMD.toFixed(2),
                movimientoHaber: tMH.toFixed(2),
                saldoCierreDeudor: tSCD.toFixed(2),
                saldoCierreAcreedor: tSCA.toFixed(2),
                cuentaInventarioActivo: tCIA.toFixed(2),
                cuentaInventarioPasivo: tCIP.toFixed(2),
                resultadoFuncionPerdida: tRFP.toFixed(2),
                resultadoFuncionGanancia: tRFG.toFixed(2),
                resultadoNaturalezaPerdida: tRNP.toFixed(2),
                resultadoNaturalezaGanancia: tRNG.toFixed(2),
              })
            });
            responseFiltrado = primeraAgrupacion;
          } else if (this.filtro.digitoSelected == "2") {
            response.forEach(item => {
              item["CCN_CODCCN2"] = item.CCN_CODCCN.substr(0, 2);
            });

            let agrupado = this.agrupar(response, 'CCN_CODCCN2');
            let keys = Object.keys(agrupado);

            let primeraAgrupacion = [];
            keys.forEach(key => {
              let encontrado = response.find(element => element.CCN_CODCCN2 == key)

              let [tSID, tSIH, tMD, tMH, tSCD, tSCA, tCIA, tCIP, tRFP, tRFG, tRNP, tRNG] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

              agrupado[key].forEach(item => {
                const saldoIniDebe = (item.DEBE_INI > item.HABER_INI) ? item.DEBE_INI - item.HABER_INI : 0;
                const saldoIniHabe = (item.HABER_INI > item.DEBE_INI) ? item.HABER_INI - item.DEBE_INI : 0;
                const saldoCierreDeudor = ((saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA) > 0) ? saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA : 0;
                const saldoCierreAcreedor = ((saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA) < 0) ? Math.abs(saldoIniDebe - saldoIniHabe + item.SCU_DEBMNA - item.SCU_HABMNA) : 0;
                const cuentaInventarioActivo = (item.CCN_INDCLB == '1') ? saldoCierreDeudor : 0;
                const cuentaInventarioPasivo = (item.CCN_INDCLB == '1') ? saldoCierreAcreedor : 0;
                const resultadoFuncionPerdida = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '4' || item.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
                const resultadoFuncionGanancia = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '4' || item.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;
                const resultadoNaturalezaPerdida = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '3' || item.CCN_INDEXR == 1) ? saldoCierreDeudor : 0;
                const resultadoNaturalezaGanancia = (item.CCN_INDCLB == '2' || item.CCN_INDCLB == '3' || item.CCN_INDEXR == 1) ? saldoCierreAcreedor : 0;

                tSID += saldoIniDebe;
                tSIH += saldoIniHabe;
                tMD += item.SCU_DEBMNA;
                tMH += item.SCU_HABMNA;
                tSCD += saldoCierreDeudor;
                tSCA += saldoCierreAcreedor;
                tCIA += cuentaInventarioActivo;
                tCIP += cuentaInventarioPasivo;
                tRFP += resultadoFuncionPerdida;
                tRFG += resultadoFuncionGanancia;
                tRNP += resultadoNaturalezaPerdida;
                tRNG += resultadoNaturalezaGanancia;
              });

              primeraAgrupacion.push({
                cuenta: key,
                descri: encontrado.DESCRICTA_2,
                saldoIniDebe: tSID.toFixed(2),
                saldoIniHabe: tSIH.toFixed(2),
                movimientoDebe: tMD.toFixed(2),
                movimientoHaber: tMH.toFixed(2),
                saldoCierreDeudor: tSCD.toFixed(2),
                saldoCierreAcreedor: tSCA.toFixed(2),
                cuentaInventarioActivo: tCIA.toFixed(2),
                cuentaInventarioPasivo: tCIP.toFixed(2),
                resultadoFuncionPerdida: tRFP.toFixed(2),
                resultadoFuncionGanancia: tRFG.toFixed(2),
                resultadoNaturalezaPerdida: tRNP.toFixed(2),
                resultadoNaturalezaGanancia: tRNG.toFixed(2),
              })
            });
            responseFiltrado = primeraAgrupacion;
          }

          this.exportToExcel(responseFiltrado);
          this.loaderReg = false;
        },
        error => {
          this._snackBarService.showError(error.error.msg, 'OK')
          this.loaderReg = false;
        }
      )
    }
  }

  agrupar(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  exportToExcel(data: any[]) {

    if (!(data && data.length > 0)) {
      this._snackBarService.showError("No se Encontraron Registros para Generar Reporte", "OK");
      return;
    }

    let keys = Object.keys(data[0]);
    let keysHeader = keys.map(item => {
      const columna = this.columnas.find(element => element.TDR_COLTDR.toUpperCase() == item.toUpperCase());
      return columna ? columna.TDR_DESTDR : item;
    })

    this.dataForExcel = [];

    data.forEach((row: any) => this.dataForExcel.push(Object.values(row)))

    let type = "Ventas";

    if (this.reporteSeleccionado[0] == "CONTABILIDAD_RV") type = "Ventas";

    if (this.reporteSeleccionado[0] == "CONTABILIDAD_RC") type = "Compras";

    if (this.reporteSeleccionado[0] == "CONTABILIDAD_RH") type = "Registro de Honorarios";

    if (this.reporteSeleccionado[0] == "CONTABILIDAD_BCEF") type = "Balance de Comprobación";

    if (this.reporteSeleccionado[0] == "CONTABILIDAD_AC") type = "Asientos Contables";

    const reportData = {
      title: `Informe de Registro de ${type} - ${number2month(this.month)} ${this.year}`,
      data: this.dataForExcel,
      ruc: this._configurationService.obtenerClienteId(),
      razonSocial: this._configurationService.obtenerNombreCliente(),
      headers: keysHeader,
      moneda: "SO"
    }

    if (this.reporteSeleccionado == "BCEF") {
      this.ete.exportExcelBalanceComprobacion(reportData);
      return;
    }

    this.ete.exportExcel(reportData);

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$,
      this.cuentas$,
      this.subdiarios$,
      this.clientes$,
      this.monedas$,
      this.centros$,
      this.cuentasBancarias$,
      this.flujos$,
    ]);

  }

}
