import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { SnackBarService } from "@shared/services/snackbar.service";
import { CuentaCobrarService } from "../../api/cuentaxcobrar.service";
import { Subscription } from "rxjs";
import { unsubscribeSubscription } from "@utils/others/subscription";
import * as pdfBuilder from "@utils/pdfmaker/builder/cobranzas.pdfmaker";
import { ConfigurationService } from "../../configuration.service";
import { CuentaCobrarExcelService } from "../../reporte-excel/cuenta-cobrar-excel.service";

@Injectable({
  providedIn: 'root'
})
export class CuentaCobrarReportService implements OnInit, OnDestroy {

  reporte$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _cuentaCobrarService: CuentaCobrarService,
    private _configurationService: ConfigurationService,
    public ete: CuentaCobrarExcelService,
  ) {}

  ngOnInit(): void {}

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporte(reportSelected: string, headers: any, payload: any, otherData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case "COBRANZAS_DC":

          this.reporte$ = this._cuentaCobrarService.obtenerDataReporteDocumentoCobrarDocConSaldo(payload).subscribe(
            response => {

              // let filtrados = response.filter(item => item.cco_impsal > 0)
              let filtrados = response.filter(item => item)
              console.log('response', response)

              console.log('response', filtrados)

              if(filtrados.length > 0) {
                pdfBuilder.generarPDFDocumentoCobrarDocConSaldo(headers, filtrados);
                resolve(true);
                return;
              }

              this._snackBarService.showError(`No se encontró información para este período o filtro`, "OK");
              reject(false);

            },
            error => {
              this._snackBarService.showError("Error al obtener reporte acumulado flujo de caja por categoría", "OK");
              reject(false);
            }
          )

          break;
        case "COBRANZAS_MC":

            this.reporte$ = this._cuentaCobrarService.obtenerDataReporteMovimientosCobranza(headers.codano, headers.codmes).subscribe(
              response => {
                if(response.length > 0) {
                  pdfBuilder.generarPDFMovimientosDeCobranza(headers, response);
                  resolve(true);
                  return;
                }

                this._snackBarService.showError(`No se encontró información para este período ${headers.codano}`, "OK");
                reject(false);
              },
              error => {
                this._snackBarService.showError("Error al obtener reporte movimientos de cobranza", 'OK');
                reject(false);
              }
            )

          break;
        case "COBRANZAS_PEC":
          pdfBuilder.generarPDFPagoEfectuadoCliente(headers, {});
          resolve(true)

          return;
          this.reporte$ = this._cuentaCobrarService.obtenerDataReportePagoEfectuadoCliente(headers.year).subscribe(
            response => {
              if(response.data.length > 0) {
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.year} - ${headers.month}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError('Error al obtener reporte de detallado de flujo de caja', 'OK');
              reject(false);
            }
          )
          break;
        case "COBRANZAS_ECCli":

          this.reporte$ = this._cuentaCobrarService.obtenerDataReporteEstadoCuentaCliente(headers.codano, headers.codmes).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.generarPDFEstadoCuentaCliente(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.codano} - ${headers.codmes}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de movimiento de caja y banco saldo inicial", "OK");
              reject(false);
            }
          )
          break;
        case "COBRANZAS_TCC":

          this.reporte$ = this._cuentaCobrarService.obtenerDataReporteTabuladoCuentaPorCobrar(payload).subscribe(
            response => {
              if(response.length > 0) {
                pdfBuilder.generarPDFTabuladoCuentaPorCobrar(headers, response);
                resolve(true);
                return;
              }
              this._snackBarService.showError(`No se encontró información para este período ${headers.codano} - ${headers.codmes}`, "OK");
              reject(false);
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte de cuenta por pagar tabulado con saldo", "OK");
              reject(false);
            }
          )
          break;
        default:
          break;
      }
    })
  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param ohterData
   * @returns
   */
  verReporteDinamico(reportSelected: string, headers: any, payload: any, ohterData: any) : Promise<any> {
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case 'COBRANZAS_ECC':
          this.reporte$ = this._cuentaCobrarService.obtenerDataReporteDinamicoDocumentoCobrar().subscribe(
            data => {

              console.log('informe de documentos cobrar dinamico', data)
              if(data.length <= 0) {
                this._snackBarService.showError('Seleccionar otro período para obtener registros', 'Ok');
                reject(false)
                return;
              }

              let dataForExcel = [];

              data.forEach((row: any) => {

                // DOCUMENTO
                row['DOCUMENTO'] = `${row['TDO_CODTDO']}/${row['CCO_NUMDOC']}`;
                delete row['TDO_CODTDO'];
                delete row['CCO_NUMDOC'];

                // F. EMI.
                row['F. EMI.'] = row['CCO_FECEMI'];
                delete row['CCO_FECEMI'];

                // F. VEN.
                row['F. VEN.'] = row['cco_fecven'];
                delete row['cco_fecven'];

                // CTA. CTE.
                row['CTA. CTE.'] = row['CCO_CODCCO'];
                delete row['CCO_CODCCO'];

                // MONEDA
                row['MONEDA'] = row['TMO_CODTMO'];
                delete row['TMO_CODTMO'];

                // IMPORTE
                row['IMPORTE'] = row['CCO_IMPDOC'] * row['TDO_INDSIG'];
                delete row['CCO_IMPDOC'];

                // ABONO
                row['ABONO'] = row['CCO_IMPABO'] * row['TDO_INDSIG'];
                delete row['CCO_IMPABO'];

                // SALDO
                row['SALDO'] = row['cco_impsal'] * row['TDO_INDSIG'];
                delete row['TDO_INDSIG'];
                delete row['cco_impsal'];

                // REFERENCIA
                row['REFERENCIA'] = 'Generación Automatica de Pedido';



                delete row['ANO_CODANO'];
                delete row['BCO_CODBCO'];
                delete row['CBA_CODCBA'];
                delete row['CCN_CODCCN'];
                delete row['CCO_ABORET'];
                delete row['CCO_GLOCCO'];
                delete row['CCO_IMPCCO'];
                delete row['CCO_IMPIGV'];
                delete row['CCO_IMPINA'];
                delete row['CCO_IMPRET'];
                delete row['CCO_INDSTA'];
                delete row['CCO_RETGAS'];
                delete row['CCO_SALCCO'];
                delete row['CCO_SALLBA'];
                delete row['CCO_SALRES'];
                delete row['CCO_TIPCAM'];
                delete row['CIA_CODCIA'];
                delete row['CLI_CODCLI'];
                delete row['CPA_DESCRI'];
                delete row['DCC_FECDES'];
                delete row['DCC_IMPBRU'];
                delete row['DCC_NUMOPE'];
                delete row['EDO_CODEDO'];
                delete row['GCL_CODGCL'];
                delete row['GCL_DESCRI'];
                delete row['IRI_CODIRI'];
                delete row['LDC_CORLDC'];
                delete row['MES_CODMES'];
                delete row['PCC_NUMPCC'];
                delete row['SCO_CODSCO'];
                delete row['SEC_CODSEC'];
                delete row['SUC_CODSUC'];
                delete row['TDO_DESLAR'];
                delete row['TMO_CODREP'];
                delete row['TMO_DESLAR'];
                delete row['VDE_CODVDE'];
                delete row['ZVE_CODZVE'];
                delete row['cco_diapla'];
                delete row['cco_diaven'];
                delete row['cco_fecemi_char'];
                delete row['cco_fecini'];
                delete row['cco_fecven_char'];
                delete row['cco_saldol'];
                delete row['cco_salnac'];
                delete row['cco_salrea'];
                delete row['cco_valvta'];
                delete row['clc_creasi'];
                delete row['cli_nomcli'];
                delete row['cpa_descpa'];
                delete row['dcc_desdia'];
                delete row['dcc_desven'];
                delete row['dcc_indent'];
                delete row['dcd_glodcd'];
                delete row['dtosfecemi'];
                delete row['dtosfecreg'];
                delete row['dtosfecven'];
                delete row['tmo_lincre'];
                delete row['vde_codlde'];
                delete row['vde_nomvde'];
                delete row['vds_codvds'];
                delete row['vds_descri'];


                dataForExcel.push(Object.values(row))
              })

              let reportData = {
                title: `Informe de documentos por cobrar`,
                data: dataForExcel,
                headers: Object.keys(data[0]),
                razonSocial: this._configurationService.obtenerNombreCompaniaCliente(),
                ruc: this._configurationService.obtenerClienteId()
              }

              this.ete.exportExcelDocumentosPorCobrar(reportData);

              resolve(true)
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte dinámico de documentos por cobrar", "OK");
              reject(false);
            }
          )
          break;
        case 'COBRANZAS_MC':
            // TODO; PAX_Vweb_REPORTE_ANALITICO_COBRANZA store procedure to implement
          this.reporte$ = this._cuentaCobrarService.obtenerDataReporteDinamicoMovimientosCobranza(headers.codano, headers.codmes).subscribe(
            data => {
              console.log('informe de documentos cobrar dinamico', data)
              if(data.length <= 0) {
                this._snackBarService.showError('Seleccionar otro período para obtener registros', 'Ok');
                reject(false)
                return;
              }

              let dataForExcel = [];

              data.forEach((row: any) => {

                // FEC. OPERACIÓN
                row['FEC. OPERACIÓN'] = row['cco_fecemi'];
                delete row['cco_fecemi'];

                // FEC. VENC.
                row['FEC. VENC.'] = row['cco_fecven'];
                delete row['cco_fecven'];

                // # CORRELATIVO DE ASIENTO
                row['# CORRELATIVO DE ASIENTO'] = `${row['ANO_CODANO_LDI']}-${row['MES_CODMES_LDI']}-${row['sco_codsco']}-${row['ldc_CORldc']}-${row['ldi_corldi']}`;
                delete row['ANO_CODANO_LDI'];
                delete row['MES_CODMES_LDI'];
                delete row['sco_codsco'];
                delete row['ldc_CORldc'];
                delete row['ldi_corldi'];

                // TIP. DOC.
                row['TIP. DOC.'] = row['tdo_codtdo'];
                delete row['tdo_codtdo'];

                // NRO. DOC.
                row['NRO. DOC.'] = row['ldi_docref'];
                delete row['ldi_docref'];

                // DOC. BCO.
                row['DOC. BCO.'] = row['tdo_codban'];
                delete row['tdo_codban'];

                // # OPERACIÓN
                row['# OPERACIÓN'] = row['PBA_NUMDOC'];
                delete row['PBA_NUMDOC'];

                // BANCO
                row['BANCO'] = row['cba_descri'];
                delete row['cba_descri'];

                // SUBDIARIO
                row['SUBDIARIO'] = row['sco_descri'];
                delete row['sco_descri'];

                // GLOSA
                row['GLOSA'] = row['ldi_gloldi'];
                delete row['ldi_gloldi'];

                // DEBE M.N.
                row['DEBE M.N.'] = (row['ldi_INDDHA'] == "D")? row['LDI_IMPNAC']: 0;

                // HABER M.N.
                row['HABER M.N.'] = (row['ldi_INDDHA'] == "H")? (row['LDI_IMPNAC'])*(-1): 0;

                // SALDO M.N.
                row['SALDO M.N.'] = (row['DEBE M.N.'] == 0)? row['HABER M.N.']: row['DEBE M.N.'];

                // DEBE M.E.
                row['DEBE M.E.'] = (row['ldi_INDDHA'] == "D")? row['LDI_IMPMEX']: 0;

                // HABER M.E.
                row['HABER M.E.'] = (row['ldi_INDDHA'] == "H")? (row['LDI_IMPMEX'])*(-1): 0;

                // SALDO M.E.
                row['SALDO M.E.'] = (row['DEBE M.E.'] == 0)? row['HABER M.E.']: row['DEBE M.E.'];

                delete row['ldi_INDDHA'];
                delete row['LDI_IMPMEX'];
                delete row['LDI_IMPNAC'];

                delete row['LDC_CODUSE'];
                delete row['LDC_FECDIA'];
                delete row['LDI_DOCREF_ADE'];
                delete row['LDI_NUMREF'];
                delete row['TDO_CODTDO_LDI'];
                delete row['TMO_CODTMO'];
                delete row['ano_codano'];
                delete row['ccn_codccn'];
                delete row['ccn_descri'];
                delete row['cco_codcco'];
                delete row['cco_fecemi_112'];
                delete row['cia_codcia'];
                delete row['cli_codcli'];
                delete row['cli_nomcli'];
                delete row['ldi_tipcam'];
                delete row['mes_codmes'];
                delete row['tmo_codtmo_docu'];

                dataForExcel.push(Object.values(row))
              })

              let reportData = {
                title: `Informe de movimientos cobranza`,
                data: dataForExcel,
                headers: Object.keys(data[0]),
                razonSocial: this._configurationService.obtenerNombreCompaniaCliente(),
                ruc: this._configurationService.obtenerClienteId()
              }

              this.ete.exportExcelMovimientosCobranza(reportData);

              resolve(true)
            },
            error => {
              this._snackBarService.showError("Error al obtener reporte dinámico de documentos por cobrar", "OK");
              reject(false);
            }
          )
          break;
        default:
          break;
      }
    })
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.reporte$
    ])
  }
}
