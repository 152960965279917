<app-pavso-title-section [title]="'Facturas'" [module]="'Ventas'"></app-pavso-title-section>
<div class="pav-table-search-container">

  <div></div>
  <div class="pav-separator"></div>
  <div class="pav-table-search">
    <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
  </div>


</div>
<div class="pavso-content-form fade-in-image">
  <table-title-action title="Todas las facturas" url="/modulo-ventas/factura" entity=""
    [params]="['0']"></table-title-action>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
  <div class="mat-elevation-z0 overflow-x">

    <table class="mat-elevation-z0 overflow-x" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
          <div class="pav-btns-container">
            <a class="pav-btn-circle-edit" matTooltip="Editar"
              [routerLink]="['/modulo-ventas/factura', row.CCO_CODCCO]">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </a>
            <div class="pav-btn-circle-edit" matTooltip="" [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="more-vertical"></mat-icon>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="generarPDF(row.CCO_CODCCO)">Descargar PDF</button>
              <button mat-menu-item (click)="generarXML(row)">Descargar XML</button>
            </mat-menu>
            <!-- <button (click)="generarXML(row.CCO_CODCCO)" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                xml
            </button> -->

          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="CCO_CODCCO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Fatura </th>
        <td mat-cell *matCellDef="let row" data-label="Nro. Pedido"> {{row.CCO_CODCCO}} </td>
      </ng-container>

      <ng-container matColumnDef="CLI_CODCLI">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. Cliente </th>
        <td mat-cell *matCellDef="let row" data-label="Doc. Cliente"> {{row.CLI_CODCLI}} </td>
      </ng-container>

      <ng-container matColumnDef="cli_nomcli">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
        <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cliente.cli_nomcli}} </td>
      </ng-container>

      <ng-container matColumnDef="CCO_FECEMI">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
        <td mat-cell *matCellDef="let row" data-label="Fecha"> {{row.CCO_FECEMI | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="CCO_FECVEN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Vencimiento </th>
        <td mat-cell *matCellDef="let row" data-label="Fecha"> {{row.CCO_FECVEN | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="TMO_CODTMO">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de Moneda </th>
        <td mat-cell *matCellDef="let row" data-label="Tipo de Moneda"> {{(row.TMO_CODTMO == 'SO')? 'Soles': 'Dolares'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="CCO_IMPDOC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total </th>
        <td mat-cell *matCellDef="let row" data-label="Importe Total">
          <div [ngStyle]="{width: '90px', textAlign: 'right'}">
            {{row.CCO_IMPDOC.toFixed(2)}}

          </div>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">No se encontraron registros "{{input.value}}"</td>

      </tr>

    </table>

  </div>

  <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

</div>
