export class FiltroConsultaSeguimiento {

  responsables: Array<any>;
  lineas: Array<any>;
  tiposNegociacion: Array<any>;
  clientes: Array<any>;
  etapas: Array<any>;
  canales: Array<any>;
  desde: Date;
  hasta: Date;
  situacion: string;
  situaciones: Array<any>;
  moneda: string;

  constructor() {
    this.responsables = [];
    this.lineas = [];
    this.tiposNegociacion = [];
    this.clientes = [];
    this.etapas = [];
    this.canales = [];
    this.situaciones = [];
    this.desde = new Date();
    this.hasta = new Date();
    this.situacion = 'PENDIENTES';
    this.moneda = 'SO'
  }

}
