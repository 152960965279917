import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Subscription } from 'rxjs';
import { ApiCRMOperacionesSeguimientoService } from 'src/app/services/api/crm/operaciones/crm.operaciones.seguimiento.service';

@Component({
  selector: 'app-seguimiento-list',
  templateUrl: './seguimiento-list.component.html',
  styleUrls: ['./seguimiento-list.component.css']
})
export class SeguimientoListComponent implements OnInit {

  displayedColumns: string[] = ['acciones', 'otk_idtkt', 'ost_idset', 'desoet', 'ost_descri'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;

  idTkt: string;

  constructor(
    private _snackBarService: SnackBarService,
    private _apiSeguimientoCrmService: ApiCRMOperacionesSeguimientoService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private store: Store<PavsoState>
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({tkt})=>{
      this.idTkt = tkt;
      this._apiSeguimientoCrmService.obtenerSeguimientosOportunidad(tkt).subscribe(
        response => this.dataSource = fillTable(response, this.paginator, this.sort),
        error => this._snackBarService.showError(error.error.msg, 'OK')
      )
    });
  }

  volver(): void {

    this._router.navigate(['/registros-de-oportunidades']);

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource );
  }

}
