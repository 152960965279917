import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { ordenerArregloJSONxLlave } from '@utils/array/order';
import { formatDateWithDash, formatDateWithDashLocal } from '@utils/formats/date.format';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { Subdiario } from 'src/app/models/contabilidad';
import { CajaBancoService, ContabilidadService } from 'src/app/services';
import { OrdenPagoService } from 'src/app/services/api/tesoreria/orden-pago.service';
import { IParam } from 'src/app/services/api/utilities/params';
import { BancosVentasService } from 'src/app/services/api/ventas/maestros/bancos.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';

class FiltroIngresoBanco {
  subdiario: string;
  banco: string;
  cuentaBancaria: string;
  tipoDoc: string;
  nroDoc: string;
  proveedor: string;
  montoDesde: string;
  montoHasta: string;
  fechaDesde: string;
  fechaHasta: string;
}

export enum TipoMovimientoEnum {
  TRANSFERENCIA_ENTRE_CUENTAS = 'TEC',
  INGRESOS = 'ING',
  EGRESOS = 'EGR',
  CAJA_CHICA = 'CCH',
  RENDICION_CUENTA = 'RC',
  APLICACIONES_VARIAS = 'AV',
  EXTORNO_TESORERIA = 'ET',
  CONTRATOS_FINANCIAMIENTO = 'CF',
  PLANILLA_COBRANZAS = 'PC'
}

@Component({
  selector: 'movimiento-banco-list',
  templateUrl: 'movimiento-banco-list.component.html',
  styleUrls: ['movimiento-banco-list.component.scss']
})

export class MovimientoBancoListComponent implements OnInit {

  displayedColumns: string[] = ['acciones', 'SCO_CODSCO', 'LDC_CORLDC', 'PBA_FECORD', 'CBA_CODCBA', 'TDO_CODTDO', 'PBA_NUMDOC', 'TMO_CODTMO', 'CLI_CODCLI', 'PBA_IMPSOL', 'PBA_IMPDOL', 'PBA_INDSTA'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loaderData: boolean;

  loading$: Subscription;
  subdiarios$: Subscription;
  bancos$: Subscription;
  cuentasBancarias$: Subscription;
  tiposDoc$: Subscription;
  proveedores$: Subscription;

  filtro: FiltroIngresoBanco;

  subdiarios: any[] = [];
  bancos: any[] = [];
  cuentasBancarias: any[] = [];
  tiposDoc: any[] = [];
  proveedores: any[] = [];

  subdiario: Subdiario = new Subdiario();

  egresos$: Subscription;
  anioPeriodo: string;
  mesPeriodo: string;
  routePage: string;
  titlePage: string;
  tipoMovimiento: TipoMovimientoEnum;

  constructor(
    private store: Store<PavsoState>,
    private _contabilidadService: ContabilidadService,
    private _cuentaPagarService: CajaBancoService,
    private _clienteService: ClienteService,
    private _snackBarService: SnackBarService,
    private _ordenPagoService: OrdenPagoService,
    private _activatedRoute: ActivatedRoute,
    private readonly _bancoService: BancosVentasService
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
    this.filtro = new FiltroIngresoBanco();
  }

  ngOnInit() {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadMaestros();
    })

    this.store.select('period').subscribe(state => {
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
    })

    this.routePage = this._activatedRoute.snapshot.url.join('/');
    console.log('routePath', this.routePage)
  }

  loadMaestros(): void {
    this.loaderData = true;
    this.subdiarios$ = this._contabilidadService.obtenerSubdiarios().subscribe(
      subdiarios => {

        this.subdiarios = subdiarios;
        console.log('subdiarios', subdiarios)

        this.cuentasBancarias$ = this._cuentaPagarService.obtenerCuentasBancarias().subscribe(
          cuentasBancarias => {
            this.cuentasBancarias = cuentasBancarias;
            console.log('cuentasBancarias', cuentasBancarias)

            this.tiposDoc$ = this._contabilidadService.obtenerTiposDocumento().subscribe(
              tiposDoc => {
                this.tiposDoc = tiposDoc;
                console.log('tiposDoc', tiposDoc)


                this.proveedores$ = this._clienteService.obtenerClientes().subscribe(
                  proveedores => {
                    this.proveedores = proveedores;
                    console.log('proveedores', proveedores)

                    this.bancos$ = this._bancoService.obtenerBancos().subscribe(
                      bancos => {
                        this.bancos = bancos;
                        console.log('bancos', bancos)
                        this.obtenerIngresosBanco();
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener bancos', 'Ok');
                        this.loaderData = false;
                      }
                    )

                  },
                  error => {
                    this._snackBarService.showError('Error al obtener clientes', 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener tipos de documento', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener bancos', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener subdiarios', 'Ok');
        this.loaderData = false;
      }
    )
  }

  filtrar(): void {
    console.log('filtrar')
    console.log('filtro subdiario', this.filtro.subdiario)
    let params: IParam[] = [];

    if (this.filtro.subdiario) {
      params.push({ param: 'subdiario', value: this.filtro.subdiario });
    }

    if (this.filtro.banco) {
      params.push({ param: 'banco', value: this.filtro.banco });
    }

    if (this.filtro.cuentaBancaria) {
      params.push({ param: 'cuentabancaria', value: this.filtro.cuentaBancaria });
    }

    if (this.filtro.proveedor) {
      params.push({ param: 'cliente', value: this.filtro.proveedor });
    }

    if (this.filtro.tipoDoc) {
      params.push({ param: 'tipodocumento', value: this.filtro.tipoDoc });
    }

    if (this.filtro.nroDoc) {
      params.push({ param: 'nrodocumento', value: this.filtro.nroDoc });
    }

    if (this.filtro.montoDesde) {
      params.push({ param: 'montodesde', value: this.filtro.montoDesde });
    }

    if (this.filtro.montoHasta) {
      params.push({ param: 'montohasta', value: this.filtro.montoHasta });
    }

    if (this.filtro.fechaDesde) {
      params.push({ param: 'desde', value: formatDateWithDash(this.filtro.fechaDesde) });
    }

    if (this.filtro.fechaHasta) {
      params.push({ param: 'hasta', value: formatDateWithDash(this.filtro.fechaHasta) });
    }

    this.egresos$ = this._ordenPagoService.obtenerOrdenesPago(this.anioPeriodo, this.mesPeriodo, { params }).subscribe(
      ordenes => {
        console.log('ordenes', ordenes)
        this.dataSource = fillTable(ordenes, this.paginator, this.sort);
      },
      error => {
        this._snackBarService.showError('Error al obtener ordenes de pago', 'Ok');
      }
    )
  }

  obtenerIngresosBanco(): void {

    this.loaderData = true;
    this.egresos$ = this._ordenPagoService.obtenerOrdenesPago(this.anioPeriodo, this.mesPeriodo).subscribe(
      ordenes => {
        console.log('ordenes', ordenes)
        const movimientosBanco = this.obtenerListadoMovimientoBanco(ordenes);
        this.dataSource = fillTable(movimientosBanco, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener ordenes de pago', 'Ok');
        this.loaderData = false;
      }
    )

  }

  obtenerListadoMovimientoBanco(movimientosTotales): Array<any> {

    let movimientos = [];

    if (this.routePage == 'transferencia-entre-cuentas') {
      movimientos = movimientosTotales.filter(item => item.SCO_CODSCO.substring(0, 2) == '17')
      this.titlePage = 'Transferencia entre cuentas';
      this.tipoMovimiento = TipoMovimientoEnum.TRANSFERENCIA_ENTRE_CUENTAS;
      this.subdiarios = this.subdiarios.filter(item => item.sco_codsco.substring(0, 2) == '17')

    }

    if (this.routePage == 'egresos-banco') {
      movimientos = movimientosTotales.filter(item => item.subdiario.sco_indtee == 1)
      this.titlePage = 'Egresos banco';
      this.tipoMovimiento = TipoMovimientoEnum.EGRESOS;
      this.subdiarios = this.subdiarios.filter(item => item.sco_indtes == 1 && item.sco_indtee == 1);

    }

    if (this.routePage == 'ingresos-banco') {
      movimientos = movimientosTotales.filter(item => item.subdiario.sco_indtei == 1)
      this.titlePage = 'Ingresos banco';
      this.tipoMovimiento = TipoMovimientoEnum.INGRESOS;
      this.subdiarios = this.subdiarios.filter(item => item.sco_indtes == 1 && item.sco_indtei == 1);

    }

    if (this.routePage == 'rendicion-de-cuentas') {
      movimientos = movimientosTotales.filter(item => item.subdiario.sco_indcre == 1)
      this.titlePage = 'Rendición de cuentas';
      this.tipoMovimiento = TipoMovimientoEnum.RENDICION_CUENTA;
      this.subdiarios = this.subdiarios.filter(item => item.sco_indtes == 1 && item.sco_indcre == 1);

    }

    if (this.routePage == 'liquidaciones-de-caja') {
      movimientos = movimientosTotales.filter(item => item.subdiario.sco_indcch == 1)
      this.titlePage = 'Liquidaciones de caja';
      this.tipoMovimiento = TipoMovimientoEnum.CAJA_CHICA;
      this.subdiarios = this.subdiarios.filter(item => item.sco_indtes == 1 && item.sco_indcch == 1);

    }

    if (this.routePage == 'aplicaciones-varias') {
      movimientos = movimientosTotales.filter(item => item.subdiario.sco_indtap == 1)
      this.titlePage = 'Aplicaciones varias';
      this.tipoMovimiento = TipoMovimientoEnum.APLICACIONES_VARIAS;
      this.subdiarios = this.subdiarios.filter(item => item.sco_indtap == 1);

    }

    if (this.routePage == 'extorno-tesoreria') {
      movimientos = movimientosTotales.filter(item => item.subdiario.sco_indtex == 1)
      this.titlePage = 'Extorno de egresos';
      this.tipoMovimiento = TipoMovimientoEnum.EXTORNO_TESORERIA;
      this.subdiarios = this.subdiarios.filter(item => item.sco_indtex == 1);

    }

    if (this.routePage == 'contratos-financiamiento') {
      movimientos = movimientosTotales.filter(item => item.subdiario.sco_indfin == 1)
      this.titlePage = 'Contrato de financiamiento';
      this.tipoMovimiento = TipoMovimientoEnum.CONTRATOS_FINANCIAMIENTO;
      this.subdiarios = this.subdiarios.filter(item => item.sco_indfin == 1);

    }

    if (this.routePage == 'planilla-de-cobranzas') {
      movimientos = movimientosTotales.filter(item => item.subdiario.sco_indcob == 1)
      this.titlePage = 'Planilla de cobranzas';
      this.tipoMovimiento = TipoMovimientoEnum.PLANILLA_COBRANZAS;
      this.subdiarios = this.subdiarios.filter(item => item.sco_indcob == 1 && item.sco_indban == 1);

    }

    if (this.subdiarios.length == 1) {
      console.log('subdiarios', this.subdiarios[0].sco_codsco)
      this.filtro.subdiario = this.subdiarios[0].sco_codsco;
      this.subdiario = this.subdiarios[0];
      // this.subdiario = this.subdiarios.find(item => item.sco_codsco == this.movimientoBanco.SCO_CODSCO);
      // console.log('subdiario', this.subdiario)
    }

    if (this.tipoMovimiento == TipoMovimientoEnum.RENDICION_CUENTA) {
      this.tiposDoc = this.tiposDoc.filter(item => item.TDO_CODTDO == 'RCR' || item.TDO_CODTDO == 'CRE'); // RCR CRE
      // this.tiposDocumento = this.tiposDoc.filter(item => item.TDO_INDSTA == '1' && item.tdo_indren == 1)
      // this.tiposDocumento = ordenerArregloJSONxLlave('TDO_DESLAR', tiposDocumento);
    } else {
      this.tiposDoc = this.tiposDoc.filter(item => item.tdo_indban == 1);
      // this.tiposDocumento = ordenerArregloJSONxLlave('TDO_DESLAR', tiposDocumento);
    }

    return ordenerArregloJSONxLlave('LDC_CORLDC', movimientos);

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])
  }
}
