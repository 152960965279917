import { Injectable, OnDestroy } from "@angular/core";
import { HeaderReport } from "@data/interfaces/headers.report";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { formatDateWithSlash, getHourMinuteSecondsCurrent } from "@utils/formats/date.format";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { ConfigurationService } from "../../configuration.service";

@Injectable({
  providedIn: 'root'
})
export class HeaderReportService implements OnDestroy {

  year: string;
  month: string;

  period$: Subscription;

  constructor(
    private _configurationService: ConfigurationService,
    private store: Store<PavsoState>
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })
  }

  obtenerCabecera(): HeaderReport {
    const esUsuarioPavo = this._configurationService.esUsuarioPavso();

    return {
      year: this.year,
      month: this.month,
      clientName: this._configurationService.obtenerNombreCompaniaCliente(),
      client: this._configurationService.obtenerClienteId(),
      date: formatDateWithSlash(new Date()),
      time: getHourMinuteSecondsCurrent(new Date()),
      currency: '',
      username: this._configurationService.obtenerNombreUsuario(),
      codePage: esUsuarioPavo ? this._configurationService.obtenerCodigoPagina(): ''
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$])
  }
}
