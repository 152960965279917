import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { Order, Item } from 'src/app/models';
import { ConfigurationService, VentasService, AlmacenService } from 'src/app/services';
import { IlumiProductEditComponent } from '@shared/components/dialogs/ilumi/ilumi-product-edit/ilumi-product-edit.component';
import { environment } from 'src/environments/environment';
import { formatDateWithDash } from 'src/app/utils/formats/date.format';
import { Store } from '@ngrx/store';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Router } from '@angular/router';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { ApiVentasOperacionesFacturaService } from 'src/app/services/api/ventas/operaciones/ventas.operaciones.factura.service';
import { GeneraFactura } from 'src/app/models/ventas/genera-factura';
import { FiltroGeneraFactura } from 'src/app/models/ventas/filtro-genera-factura';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-genera-facura-pedido',
  templateUrl: './genera-facura-pedido.component.html',
  styleUrls: ['./genera-facura-pedido.component.css']
})
export class GeneraFacturaPedidoComponent implements OnInit, OnDestroy {
  fechaActual = new Date();

  loaderData: boolean;
  loaderReg: boolean;

  productos: Array<Item> = [];

  vendedores: any[] = [];

  transportistas: any[] = [];

  zonas: any[] = [];

  clientes: any[] = [];

  tiposDocumento: any[] = [];
  seriesDocumento: any[] = [];
  seriesDocumentoGuia: any[] = [];

  series: any[] = [];

  protected _onDestroy = new Subject<void>();

  pedido: Order;

  loaderOrders: boolean = false;

  displayedColumns: string[] = ['acciones', 'campo1', 'campo2', 'campo3', 'campo4', 'campo5', 'campo6', 'campo7'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsProductos: string[] = ['campo1', 'campo2', 'campo3', 'campo4', 'campo5', 'campo6', 'campo7','campo8','campo9','campo10','campo11','campo12','campo13','campo14'];
  dataSourceProductos: MatTableDataSource<any>;

  @ViewChild("paginatorDetalle") paginatorProductos: MatPaginator;
  @ViewChild(MatSort) sortProductos: MatSort;

  loaderProductos: boolean = false;

  generarSalida: boolean = true;
  pedidoSeleccionado: any;

  range = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))
  });

  environment = environment;

  year: string;
  month: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  listaPrecio$: Subscription;
  period$: Subscription;
  loading$: Subscription;
  pedidosAFacturar$: Subscription;
  detallePedido$: Subscription;
  pedidosAFacturarFiltro$: Subscription;
  generaFactura$: Subscription;
  vendedores$: Subscription;
  transportistas$: Subscription;
  zonas$: Subscription;
  clientes$: Subscription;
  tiposDocumento$: Subscription;
  seriesFacturacion$: Subscription;

  serie: any;

  generaFactura: GeneraFactura;

  filtroGeneraFactura: FiltroGeneraFactura;

  constructor(
    private _configurationService: ConfigurationService,
    private _ventasService: VentasService,
    private _almacenService: AlmacenService,
    private _snackBarService: SnackBarService,
    private _apiPedidoOperacionesService: ApiVentasOperacionesFacturaService,
    public dialog: MatDialog,
    private store: Store<PavsoState>,
    private _router: Router
  ) {

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.month = month;
    })

    this.dataSourceProductos = fillTable([], this.paginatorProductos, this.sortProductos);

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.pedido = new Order();
    this.pedido.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    this.pedido.PCC_TASIGV = 18.00;
    this.pedido.PCC_TIPCAM = 3.640;

    this.generaFactura = new GeneraFactura();
    this.generaFactura.codcia = this._configurationService.obtenerCompaniaCliente();

    this.generaFactura.fechaGuia = new Date(Number(this.year), Number(this.month) - 1, 1);

    this.filtroGeneraFactura = new FiltroGeneraFactura();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerPedidosAFacturar();
    })
  }

  obtenerPedidosAFacturar(): void {
    this.loaderData = true;
    this.pedidosAFacturar$ = this._apiPedidoOperacionesService.obtenerPedidosAFacturar().subscribe(
      pedidos => {
        this.dataSource = fillTable(pedidos, this.paginator, this.sort);
        this.loadMaestro();
      },
      error => {
        this._snackBarService.showError('Error al obtener pedidos a facturar', 'Ok');
        this.loaderData = false;
      }
    )
  }

  obtenerDetallePedido(row, numpcc): void {
    this.detallePedido$ = this._apiPedidoOperacionesService.obtenerDetallePedidoAFacturar(numpcc).subscribe(
      detalle => {
        this.dataSourceProductos = fillTable(detalle, this.paginatorProductos, this.sortProductos);

        this.generaFactura.codtdo = row.pcc_codtdo;
        this.generaFactura.indfac = row.mmo_indfac;
        this.generaFactura.indgui = row.mmo_indgui;
        this.generaFactura.numpcc = row.pcc_numpcc;
        this.generaFactura.fecdoc = row.pcc_fecdoc;
        this.generaFactura.codsuc = '01';

        this.seleccionarDocumento(row.pcc_codtdo);
      },
      error => {
        this._snackBarService.showError('Error al obtener detalle de pedido', 'Ok')
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.listaPrecio$,
      this.pedidosAFacturar$,
      this.pedidosAFacturarFiltro$,
      this.period$,
      this.loading$,
      this.detallePedido$,
      this.generaFactura$
    ])

    localStorage.removeItem('temp');

  }

  verMasOpciones(): void {}

  loadMaestro(): void {

    this.vendedores$ = this._ventasService.obtenerVendedores().subscribe(
      vendedores => {
        this.vendedores = vendedores;

        this.transportistas$ = this._almacenService.obtenerTransportistas().subscribe(
          transportistas => {
            this.transportistas = transportistas;

            this.clientes$ = this._ventasService.obtenerClientes().subscribe(
              clientes => {
                this.clientes = clientes;

                this.zonas$ = this._ventasService.obtenerZonasVenta().subscribe(
                  zonas => {
                    this.zonas = zonas;

                    this.tiposDocumento$ = this._ventasService.listarTipoDocumentosVenta().subscribe(
                      tiposDocumento => {
                        this.tiposDocumento = tiposDocumento;

                        this.seriesFacturacion$ = this._ventasService.obtenerSerieFacturacion().subscribe(
                          series => {
                            this.series = series;

                            this.generaFactura.documentoGuia = "GRE";
                            this.generaFactura.sergui = this.seriesDocumentoGuia[0].sdo_codsdo;
                            this.obtenerNroDocGuia();
                          },
                          error => {
                            this._snackBarService.showError('Error al obtener series de facturacion', 'Ok');
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener tipos de documento', 'Ok');
                        this.loaderData = false;

                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener zonas', 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener clientes', 'Ok');
                this.loaderData = false;

              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener transportistas', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener vendedores', 'Ok');
        this.loaderData = false;
      }
    )

  }

  seleccionarDocumento(id): void {

    this.seriesDocumento = this.series.filter(item => item.tdo_codtdo == id);
    this.generaFactura.numser = this.seriesDocumento[0].sdo_codsdo;
    this.obtenerNroDocFactura();

  }

  seleccionarSerie(id): void {

  }

  cargarPedido(): void {
    let existePedido = (localStorage.getItem('temp'))?true: false;

    if(existePedido) {

      this.loaderOrders = true;

      let pedido = JSON.parse(localStorage.getItem('temp')).temp.order;

      this._ventasService.obtenerPedidoComprobante(pedido.pcc_numpcc).subscribe(
        response => {
          if(response) {
            this.dataSource = fillTable(response, this.paginator, this.sort);
          }
          this.loaderOrders = false;


        },
        error => {
          this._snackBarService.showError('Error al obtener pedidos filtrados','OK');
          this.loaderOrders = false;
        }
      )

    } else {

      this.loaderOrders = true;

    }
  }

  buscarPedidos(): void {

    this.filtroGeneraFactura.atencionCompleta? this.filtroGeneraFactura.atencionCompleta = 1: this.filtroGeneraFactura.atencionCompleta = 0;
    this.filtroGeneraFactura.entregaVendedor? this.filtroGeneraFactura.entregaVendedor = 1: this.filtroGeneraFactura.entregaVendedor = 0;

    console.log('filtro', this.filtroGeneraFactura)

    this.pedidosAFacturarFiltro$ = this._apiPedidoOperacionesService.obtenerPedidosAFacturarConFiltro(this.filtroGeneraFactura).subscribe(
      pedidos => {
        this.dataSource = fillTable(pedidos, this.paginator, this.sort);
      },
      error => {
        this._snackBarService.showError('Error al obtener pedidos con filtro', 'Ok');
      }
    )

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  generarFactura(f: NgForm): void {

    this.generaFactura.indfac ? this.generaFactura.indfac = 1: this.generaFactura.indfac = 0;
    this.generaFactura.indgui ? this.generaFactura.indgui = 1: this.generaFactura.indgui = 0;
    this.generaFactura.fecdoc = '20200731'; //new Date(2020, 6, 1).toISOString();
    console.log('genera factura data', this.generaFactura);

    this.generaFactura$ = this._apiPedidoOperacionesService.generarFactura(this.generaFactura).subscribe(
      response => {
        console.log('response', response);
      },
      error => {
        this._snackBarService.showError('Error al generar factura', 'OK');
      }
    )

  }

  consultarPrecioProducto(producto: Item): void {
    let now = new Date();

    let fecha = formatDateWithDash(now);

    if(!this.pedido.TLP_CODTLP) {
      this._snackBarService.showError('Seleccionar lista', 'OK');
    }

    if(!this.pedido.LPC_CODLPC) {
      this._snackBarService.showError('Establecer Nro de lista', 'OK');
    }

    this.listaPrecio$ = this._ventasService.listaDePreciosProducto(producto.PRD_CODPRD, this.pedido.TMO_CODTMO, this.pedido.CLI_CODCLI, this.pedido.TLP_CODTLP, this.pedido.LPC_CODLPC,fecha ).subscribe(
      response => {
        if(this.pedido.TMO_CODTMO == "SO") {
          producto.PCD_PREUNI = response[0].pre_prenac;
          producto.PCD_PRUIGV = response[0].pre_vtanac;
        } else {
          producto.PCD_PREUNI = response[0].pre_predol;
          producto.PCD_PRUIGV = response[0].pre_vtadol;
        }

        producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
        producto.PCD_IMPBRU = producto.PCD_IMPBRU.toFixed(2);
        producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
        producto.PCD_VALVTA = producto.PCD_VALVTA.toFixed(2);
        producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
        producto.PCD_IMPTOT = producto.PCD_IMPTOT.toFixed(2);
        producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;
        producto.PCD_IMPIGV = producto.PCD_IMPIGV.toFixed(2);

        this.calcularTotales();

      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  obtenerNroDocFactura(): void {
    this._ventasService.obtenerNroDocFactura(this.generaFactura.codtdo, this.generaFactura.numser).subscribe(
      response => {
        console.log('nro doc factura', response)

        if(response.length > 0) this.generaFactura.numfac = response[0].nro_doc;
      },
      error => {
        this._snackBarService.showError('Error al obtener Nro de Documento Factura', 'Ok');
      }
    )
  }

  obtenerNroDocGuia(): void {
    this._ventasService.obtenerNroDocGuia(this.generaFactura.documentoGuia, this.generaFactura.sergui).subscribe(
      response => {
        console.log('nro doc guia', response)
        if(response.length > 0) this.generaFactura.nrogui = response[0].nro_doc;
      },
      error => {
        this._snackBarService.showError('Error al obtener Nro de Documento Guía', 'Ok');
      }
    )
  }

  calcularTotales(): void {
    let valorVenta = 0;
    let impBruto = 0;
    let impIGV = 0;
    let descuento = 0;
    let impTotal = 0;

    this.productos.forEach(element => {
      valorVenta += Number(element.PCD_VALVTA);
      impBruto += Number(element.PCD_IMPBRU);
      impIGV += Number(element.PCD_IMPIGV);
      descuento += Number(element.PCD_IMPDES);
      impTotal += Number(element.PCD_IMPTOT);
    })

    this.pedido.PCC_VALVTA = valorVenta;
    this.pedido.PCC_VALVTA = this.pedido.PCC_VALVTA.toFixed(2);

    this.pedido.PCC_IMPBRU = impBruto;
    this.pedido.PCC_IMPBRU = this.pedido.PCC_IMPBRU.toFixed(2);

    this.pedido.PCC_IMPIGV = impIGV;
    this.pedido.PCC_IMPIGV = this.pedido.PCC_IMPIGV.toFixed(2);

    this.pedido.PCC_IMPDES = descuento;
    this.pedido.PCC_IMPDES = this.pedido.PCC_IMPDES.toFixed(2);

    this.pedido.PCC_IMPTOT = impTotal;
    this.pedido.PCC_IMPTOT = this.pedido.PCC_IMPTOT.toFixed(2);
  }

  editarProducto(row): void {
    const dialogRef = this.dialog.open(IlumiProductEditComponent, {
      width: '800px',
      data: row,
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => this.calcularTotales())
  }

  volver(): void {
    this._router.navigate(['']);
  }
}
