import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { CajaBancoService, ContabilidadService, AlmacenService, GeneralService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-consultas-movimientos-contables',
  templateUrl: './consultas-movimientos-contables.component.html',
  styleUrls: ['./consultas-movimientos-contables.component.css']
})
export class ConsultasMovimientosContablesComponent implements OnInit, OnDestroy {

  meses: any[] = [{id: "01", name: "Enero"}, {id: "02", name: "Febrero"}, {id: "03", name: "Marzo"}, {id: "04", name: "Abril"}, {id: "05", name: "Mayo"}, {id: "06", name: "Junio"}, {id: "07", name: "Julio"}, {id: "08", name: "Agosto"}, {id: "09", name: "Setiembre"}, {id: "10", name: "Octubre"}, {id: "11", name: "Noviembre"}, {id: "12", name: "Diciembre"}];
  mes: string;

  displayedColumns: string[] = ['ldi_messco', 'ldi_numdoc', 'ccn_codccn', 'cli_nomcli', 'ccs_codccs', 'cco_fecreg_103', 'cco_fecemi_103', 'ldi_gloldi'];
  dataSource: MatTableDataSource<any>;
  totalDataStandar: any[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsBanco: string[] = ['ldi_messco', 'ldi_numdoc', 'cco_fecemi_103', 'cli_nomcli', 'tmo_codtmo', 'importe'];
  dataSourceBanco: MatTableDataSource<any>;
  totalDataBancos: any[];

  @ViewChild("pBanco") paginatorBanco: MatPaginator;
  @ViewChild(MatSort) sortBanco: MatSort;

  displayedColumnsCompras: string[] = ['ldi_messco', 'ldi_numdoc', 'ccn_codccn', 'cco_fecreg_103', 'cco_fecemi_103', 'cli_nomcli', 'ccs_codccs', 'tmo_codtmo', 'importe'];
  dataSourceCompras: MatTableDataSource<any>;
  totalDataCompras: any[];

  @ViewChild("pCompras") paginatorCompras: MatPaginator;
  @ViewChild(MatSort) sortCompras: MatSort;

  displayedColumnsVentas: string[] = ['ldi_messco', 'ldi_numdoc', 'ccn_codccn', 'ccs_codccs', 'cco_fecreg_103', 'cco_fecemi_103', 'cli_nomcli', 'ldi_numdoc2', 'tmo_codtmo', 'importe'];
  dataSourceVentas: MatTableDataSource<any>;
  totalDataVentas: any[];

  @ViewChild("pVentas") paginatorVentas: MatPaginator;
  @ViewChild(MatSort) sortVentas: MatSort;

  displayedColumnsHonorario: string[] = ['ldi_messco', 'ldi_numdoc', 'ccn_codccn', 'ccs_codccs', 'cco_fecreg_103', 'cco_fecemi_103', 'cli_nomcli', 'ldi_numdoc2', 'tmo_codtmo', 'importe'];
  dataSourceHonorario: MatTableDataSource<any>;
  totalDataHonorarios: any[];

  @ViewChild("pHonorarios") paginatorHonorario: MatPaginator;
  @ViewChild(MatSort) sortHonorario: MatSort;

  loaderStandar: boolean = false;
  loaderBancos: boolean = false;
  loaderCompras: boolean = false;
  loaderVentas: boolean = false;
  loaderHonorarios: boolean = false;
  loaderData: boolean = false;
  loaderReg: boolean = false;

  comprobante: string = "estandar";
  subdiario: string = "0301";
  cuentaBancaria: string;
  auxiliar: string;
  tipoDoc: string;
  cuenta: string;

  selectedIndex: number = 0;


  subdiarios: any[] = [];
  cuentas: any[] = [];
  cuentasContable: any[] = [];
  auxiliares: any[] = [];
  bancos: any[] = [];
  tiposDocumento: any[] = [];
  cuentasBancaria: any[] = [];

  subdiario$: Subscription;
  cuenta$: Subscription;
  cuentaContable$: Subscription;
  auxiliar$: Subscription;
  banco$: Subscription;
  tiposDoc$: Subscription;
  cuentaBancaria$: Subscription;

  year: string;

  selectedRowIndex = -1;

  period$: Subscription;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _almacenService: AlmacenService,
    private _snackBarService: SnackBarService,
    private _cajaBancoService: CajaBancoService,
    private _generalService: GeneralService,
    private store: Store<PavsoState>,
    private _router: Router
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.mes = state.month;
      this.seleccionarSubdiario(this.subdiario);
      this.cargarDataTable();
    });

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSourceBanco = fillTable([], this.paginatorBanco, this.sortBanco);

    this.dataSourceCompras = fillTable([], this.paginatorCompras, this.sortCompras);

    this.dataSourceVentas = fillTable([], this.paginatorVentas, this.sortVentas);

    this.dataSourceHonorario = fillTable([], this.paginatorHonorario, this.sortHonorario);
  }

  ngOnInit(): void {
    this.loadMaestros();
  }

  /**
   * Carga del listado de maestros
   */
  loadMaestros(): void {
    this.loaderData = true;

    forkObs(
      this._contabilidadService.obtenerSubdiarios(),
      this._generalService.listarBancos(),
      this._contabilidadService.obtenerCuentasContable(),
      this._contabilidadService.listarPlanDeCuentas(),
      this._almacenService.listarAuxiliares(),
      this._contabilidadService.obtenerTiposDocumento(),
      this._cajaBancoService.obtenerCajayBancos()
    ).then(data => {
      this.subdiarios = data[0];
      this.bancos = data[1];
      this.cuentasContable = data[2];
      this.cuentas = data[3];
      this.auxiliares = data[4];
      this.tiposDocumento = data[5];
      this.cuentasBancaria = data[6];
      this.loaderData = false;
    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", "OK");
      this.loaderData = false;
    })

  }

  /**
   * Seleccionar Comprobante
   * @param valor valor a filtrar
   */
  seleccionarComprobante(valor): void {
    switch (valor) {
      case "estandar":
        this.selectedIndex = 0;
        break;
      case "bancos":
        this.cargarDataTable();
        this.selectedIndex = 1;
        break;
      case "compras":
        this.selectedIndex = 2;
        break;
      case "ventas":
        this.selectedIndex = 3;
        break;
      case "honorarios":
        this.selectedIndex = 4;
        break;

      default:
        break;
    }
  }

  /**
   * Seleccionar Subdiario
   * @param valor subdiario ID
   */
  seleccionarSubdiario(valor): void {
    this.loaderStandar = true;
    this._contabilidadService.verConsultaMovimientoContableStandar(this.year, valor).subscribe(
      standar => {
        this.dataSource = fillTable(standar, this.paginator, this.sort);
        this.totalDataStandar = standar;
        this.filtrarStandar();
        this.loaderStandar = false;
      },
      error => {
        this._snackBarService.showError("Error al obtener Consulta Movimiento Contable Standar", "OK");
        this.loaderStandar = false;
      }
    )
  }

  /**
   * Seleccionar Auxiliar
   * @param valor auxiliar ID
   */
  seleccionarAuxiliar(valor): void {
    switch (this.comprobante) {
      case "estandar":
        this.filtrarStandar();
        break;
      case "bancos":
        this.filtrarBanco();
        break;
      case "compras":
        this.filtrarCompras();
        break;
      case "ventas":
        this.filtrarVentas();
        break;
      case "honorarios":
        this.filtrarHonorarios();
        break;

      default:
        break;
    }
  }

  /**
   * Seleccionar Tipo de Documento
   * @param valor Tipo de docuemnto ID
   */
  seleccionarTipoDocumento(valor): void {
    switch (this.comprobante) {
      case "estandar":
        this.filtrarStandar();
        break;
      case "bancos":
        this.filtrarBanco();
        break;
      case "compras":
        this.filtrarCompras();
        break;
      case "ventas":
        this.filtrarVentas();
        break;
      case "honorarios":
        this.filtrarHonorarios();
        break;

      default:
        break;
    }
  }

  /**
   * Seleccionar Banco
   * @param valor Banco ID
   */
  seleccionarBanco(valor): void {
    this.filtrarBanco();
  }

  /**
   * Seleccionar Cuenta Bancaria
   * @param valor Cuenta Bancaria ID
   */
  seleccionarCuentaBancaria(valor): void {
    this.filtrarBanco();
  }

  seleccionarCuenta(valor): void {
    switch (this.comprobante) {
      case "estandar":
        this.filtrarStandar();
        break;
      case "bancos":
        // this.filtrarBanco();
        break;
      case "compras":
        this.filtrarCompras();
        break;
      case "ventas":
        this.filtrarVentas();
        break;
      case "honorarios":
        this.filtrarHonorarios();
        break;

      default:
        break;
    }
  }

  /**
   * Seleccionar Mes
   * @param valor Mes in Number
   */
  seleccionarMes(valor): void {
    switch (this.comprobante) {
      case "estandar":
        this.filtrarStandar();
        break;
      case "bancos":
        this.filtrarBanco();
        break;
      case "compras":
        this.filtrarCompras();
        break;
      case "ventas":
        this.filtrarVentas();
        break;
      case "honorarios":
        this.filtrarHonorarios();
        break;

      default:
        break;
    }
  }

  /**
   * Filtrar tabla de la pestaña standar
   */
  filtrarStandar(): void {
    let filtrados = this.totalDataStandar;
    if(this.mes) {
      filtrados = filtrados.filter(item => item.mes_codmes == this.mes);
    }

    if(this.auxiliar) {
      filtrados = filtrados.filter(item => item.cli_codcli.trim() == this.auxiliar)
    }

    if(this.tipoDoc) {
      filtrados = filtrados.filter(item => item.tdo_codtdo == this.tipoDoc)
    }

    if(this.cuenta) {
      filtrados = filtrados.filter(item => item.ccn_codccn == this.cuenta)
    }
    this.dataSource = fillTable(filtrados, this.paginator, this.sort);
  }

  /**
   * Filtrar tabla de la pestaña banco
   */
  filtrarBanco(): void {
    let filtrados = this.totalDataBancos;
    if(this.mes) {
      filtrados = filtrados.filter(item => item.mes_codmes == this.mes);
    }
    if(this.cuentaBancaria) {
      filtrados = filtrados.filter(item => item.Cba_codcba == this.cuentaBancaria);
    }
    if(this.auxiliar) {
      filtrados = filtrados.filter(item => item.cli_codcli.trim() == this.auxiliar);
    }
    if(this.tipoDoc) {
      filtrados = filtrados.filter(item => item.tdo_codtdo == this.tipoDoc);
    }
    this.dataSourceBanco = fillTable(filtrados, this.paginatorBanco, this.sortBanco);
  }

  /**
   * Filtrar tabla de la pestaña compras
   */
  filtrarCompras(): void {
    let filtrados = this.totalDataCompras;
    if(this.mes) {
      filtrados = filtrados.filter(item => item.mes_codmes == this.mes);
    }
    if(this.auxiliar) {
      filtrados = filtrados.filter(item => item.cli_codcli.trim() == this.auxiliar);
    }
    if(this.tipoDoc) {
      filtrados = filtrados.filter(item => item.tdo_codtdo == this.tipoDoc);
    }
    if(this.cuenta) {
      filtrados = filtrados.filter(item => item.ccn_codccn == this.cuenta)
    }
    this.dataSourceCompras = fillTable(filtrados, this.paginatorCompras, this.sortCompras);
  }

  /**
   * Filtrar tabla de la pestaña Ventas
   */
  filtrarVentas(): void {
    let filtrados = this.totalDataVentas;
    if(this.mes) {
      filtrados = filtrados.filter(item => item.mes_codmes == this.mes);
    }
    if(this.auxiliar) {
      filtrados = filtrados.filter(item => item.cli_codcli.trim() == this.auxiliar);
    }
    if(this.tipoDoc) {
      filtrados = filtrados.filter(item => item.tdo_codtdo == this.tipoDoc);
    }
    if(this.cuenta) {
      filtrados = filtrados.filter(item => item.ccn_codccn == this.cuenta)
    }
    this.dataSourceVentas = fillTable(filtrados, this.paginatorVentas, this.sortVentas);
  }

  /**
   * Filtrar tabla de la pestaña de honorarios
   */
  filtrarHonorarios(): void {
    let filtrados = this.totalDataHonorarios;
    if(this.mes) {
      filtrados = filtrados.filter(item => item.mes_codmes == this.mes);
    }
    if(this.auxiliar) {
      filtrados = filtrados.filter(item => item.cli_codcli.trim() == this.auxiliar);
    }
    if(this.tipoDoc) {
      filtrados = filtrados.filter(item => item.tdo_codtdo == this.tipoDoc);
    }
    if(this.cuenta) {
      filtrados = filtrados.filter(item => item.ccn_codccn == this.cuenta)
    }
    this.dataSourceHonorario = fillTable(filtrados, this.paginatorHonorario, this.sortHonorario);
  }

  /**
   * Carga de datos en las pestañas de bancos, compras, ventas y honorarios
   */
  cargarDataTable(): void {
    this.loaderBancos = true;
    this.loaderCompras = true;
    this.loaderVentas = true;
    this.loaderHonorarios = true;

    this._contabilidadService.verConsultaMovimientoContableBancos(this.year).subscribe(
      dataBancos => {
        this.dataSourceBanco = fillTable(dataBancos, this.paginatorBanco, this.sortBanco);
        this.totalDataBancos = dataBancos;
        this.filtrarBanco();
        this.loaderBancos = false;
        this._contabilidadService.verConsultaMovimientoContableCompras(this.year).subscribe(
          dataCompras => {
            this.dataSourceCompras = fillTable(dataCompras, this.paginatorCompras, this.sortCompras);
            this.loaderCompras = false;
            this.totalDataCompras = dataCompras;
            this.filtrarCompras();
            this._contabilidadService.verConsultaMovimientoContableVentas(this.year).subscribe(
              dataVentas => {
                this.dataSourceVentas = fillTable(dataVentas, this.paginatorVentas, this.sortVentas);
                this.totalDataVentas = dataVentas;
                this.filtrarVentas();
                this.loaderVentas = false;
                this._contabilidadService.verConsultaMovimientoContableHonorarios(this.year).subscribe(
                  dataHonorarios => {
                    this.dataSourceHonorario = fillTable(dataHonorarios, this.paginatorHonorario, this.sortHonorario);
                    this.totalDataHonorarios = dataHonorarios;
                    this.filtrarHonorarios();
                    this.loaderHonorarios = false;
                  },
                  _ => {
                    this._snackBarService.showError("Error al obtener Consulta Movimiento contable Honorarios", "OK");
                    this.loaderHonorarios = false;
                  }
                )
              },
              _ => {
                this._snackBarService.showError("Error al obtener Consulta Movimiento Contable Ventas", "OK");
                this.loaderVentas = false;
              }
            )
          },
          _ => {
            this._snackBarService.showError("Error al obtener consulta movimiento contable Compras","OK");
            this.loaderCompras = false;
          }
        )
      },
      _ => {
        this._snackBarService.showError("Error al obtener Consulta movimiento contable Bancos", "OK");
        this.loaderBancos = false;
      }
    )
  }

  verConsultaMovimientoCtaCte(): void {
    if(this.selectedRowIndex != -1 ) {
      this._router.navigate(['/modulo-contabilidad/movimientos-cta-cte', this.selectedRowIndex])
    }
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.subdiario$,
      this.cuenta$,
      this.cuentaContable$,
      this.auxiliar$,
      this.banco$,
      this.tiposDoc$,
      this.cuentaBancaria$,
      this.period$
    ])


  }
}
