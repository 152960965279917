import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Subdiario } from 'src/app/models/contabilidad/subdiario';
import { AuthenticationService, ContabilidadService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-sub-cuenta-form',
  templateUrl: './sub-cuenta-form.component.html',
  styleUrls: ['./sub-cuenta-form.component.css']
})
export class SubCuentaFormComponent implements OnInit {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;
  subdiario: Subdiario;

  uid: string;

  loading$: Subscription;

  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private _contabilidadService: ContabilidadService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService
  ) {
    this.subdiario = new Subdiario();

    this.usuario = this._authService.getUsuarioSistema();
    this.fecha = new Date();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      if(id == '0') return

      this._contabilidadService.verSubdiario(id).subscribe(
        ([subdiario]) => {
          this.subdiario.sco_codsco = subdiario.sco_codsco;
        },
        error => this._snackBarService.showError(error.error.msg, "OK")
      )

    })
  }

  loadMaestros(): void {}

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarSubdiario(f): this.actualizarSubdiario(f);
  }

  registrarSubdiario(f: NgForm): void {}

  actualizarSubdiario(f: NgForm): void {}

  volver(): void {
    this._router.navigate(['/modulo-contabilidad/subdiarios-contable']);
  }
}
