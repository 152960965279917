<mat-sidenav-container [ngClass]="
    isDarkMode ? 'sidenav-container alternate-theme' : 'sidenav-container'
  ">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="isMobile || isTablet ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
    <mat-toolbar class="mat-elevation-z0">
      <mat-toolbar-row>

        <div>
          <img class="pav-image" [src]="srcLogo" alt="" width="85px" />
        </div>

        <!-- <div class="isMobile" align="end">
          <div class="pav-sidenav-btn-close" (click)="drawer.close()">
            <mat-icon>close</mat-icon>
          </div>
        </div> -->

      </mat-toolbar-row>
    </mat-toolbar>

    <side-greeting></side-greeting>

    <div *ngIf="isMobile || isTablet">
      <side-controls ></side-controls>
    </div>

    <side-menu (seleccionarItemMenu)="cerrarMenu()"></side-menu>

    <div *ngIf="isMobile || isTablet">
      <div class="pav-list-menu-aditional">
        <!-- <div class="isMobile">
          <div class="pav-container-button" (click)="buscarModulo()">
            <mat-icon svgIcon="search"></mat-icon>
          </div>
        </div> -->
        <div class="isMobile">
          <div class="pav-container-button" (click)="drawerConfiguration.toggle()">
            <mat-icon svgIcon="configuration"></mat-icon>
          </div>
        </div>
        <div class="isMobile">
          <notification-button></notification-button>
        </div>
      </div>
    </div>

    <side-setting></side-setting>

  </mat-sidenav>
  <mat-sidenav-content>

    <mat-toolbar class="mat-elevation-z0" class="pav-toolbar" [ngStyle]="(!isActiveSidenav) ? {width: !(isHandset$ | async) && !isTablet ? 'calc(100% - 0px)' : '' } :{width: !(isHandset$ | async) && !isTablet? 'calc(100% - 287px)': '' }">
      <mat-toolbar-row>
        <div *ngIf="(isHandset$ | async) || isTablet">
          <img [src]="srcLogo" alt="" width="85px" />

        </div>
<!-- <button (click)="button()">button</button> -->
        <div *ngIf="!isMobile && !isTablet" class="pav-btn-burger">
          <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle(); isActiveSidenav = !isActiveSidenav">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
        </div>

        <div *ngIf="!isMobile && !isTablet">
          <period-controls [ngStyle]="{marginLeft: '15px'}"></period-controls>
        </div>

        <span class="spacer"></span>

        <div [ngStyle]="{marginRight: '15px'}">
          <pav-establishment></pav-establishment>
        </div>

        <div class="isWeb">
          <pav-language></pav-language>
        </div>

        <!-- <div class="isWeb">
          <div class="pav-container-button" (click)="buscarModulo()">
            <mat-icon svgIcon="search"></mat-icon>
          </div>
        </div> -->

        <div class="isWeb">
          <div class="pav-container-button" (click)="abrirSideNavConfiguracion()">
            <mat-icon svgIcon="configuration"></mat-icon>
          </div>
        </div>

        <div class="isWeb">
          <notification-button></notification-button>
        </div>

        <header-profile></header-profile>

        <div class="pav-icon-menu" *ngIf="(isHandset$ | async) || isTablet" (click)="drawer.toggle()">
          <mat-icon svgIcon="menu"></mat-icon>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <div class="pavso-card-container">
      <!-- <div tourAnchor="panel1">...aa</div>

      <div tourAnchor="panel2">...bb</div> -->

      <router-outlet #outlet="outlet"></router-outlet>
    </div>

  </mat-sidenav-content>
  <mat-sidenav #drawerConfiguration class="sidenav_notification" fixedInViewport [mode]="'over'" [position]="'end'"
    [opened]="false">
    <side-configuration (cambiarTema)="cambiarTema($event)">
      <button class="pav-button-drawer-close" (click)="drawerConfiguration.toggle()" mat-icon-button
        aria-label="Example icon button with a home icon">
        <mat-icon>close</mat-icon>
      </button>
    </side-configuration>
  </mat-sidenav>
</mat-sidenav-container>
