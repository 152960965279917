import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PavTutorialCarousel } from '@shared/components/dialogs/pav-tutorial-carousel/pav-tutorial-carousel.component';
import { DialogService } from '@shared/services/dialog.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { TourService } from 'ngx-ui-tour-md-menu';
import Push from 'push.js';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { ConfigurationService } from '../services/configuration.service';
import { FCMService } from '../services/fcm.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit, AfterViewInit {

  @ViewChild('drawerChatIA') public sidenavChatIA: MatSidenav;
  @ViewChild('drawerConfiguration') public sidenavConfiguration: MatSidenav;
  @ViewChild('drawerMasOpciones') public sidenavMasOpciones: MatSidenav;
  @ViewChild('drawer') sidenav: MatSidenav;

  user: any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => {
        console.log('============ RESULT HANDSET ============', result)

        this.isMobile = result.matches;

        return result.matches;
      }),
      shareReplay()
    );


  isDarkMode: boolean = false;

  language$: Subscription;

  srcLogo: string;

  isActiveSidenav: boolean = true;
  isActiveSidenavIA: boolean = false;

  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _authService: AuthenticationService,
    private _configurationService: ConfigurationService,
    private pagination: MatPaginatorIntl,
    private _dialogService: DialogService,
    private store: Store<PavsoState>,
    public dialog: MatDialog,
    private translate: TranslateService,
    private _hotkeysService: HotkeysService,
    private tourService: TourService,
    private FCMService: FCMService,
    private _snackBar: MatSnackBar
  ) {
    // this.FCMService.requestPermission();
    // this.FCMService.currentMessage.subscribe(
    //   (message) => {
    //     console.log("Received FCM message: ", message);
    //     if(message) {
    //       this._snackBar.open(message.notification.title, 'Ok', {
    //         horizontalPosition: 'end',
    //         verticalPosition: 'top',
    //       });

    //     }
    //   }
    // )

    this._hotkeysService.add(new Hotkey('ctrl+shift+c', (event: KeyboardEvent): boolean => {
      console.log('Typed hotkey');
      this.abrirSideNavConfiguracion();
      return false;
    }));

    this._hotkeysService.add(new Hotkey('ctrl+shift+f', (event: KeyboardEvent): boolean => {
      console.log('Typed hotkey');
      this.buscarModulo();
      return false;
    }));

    this.isDarkMode = this._configurationService.obtenerTema() === 'true';

    if (this.isDarkMode) document.body.classList.toggle('dark-theme');

    (this.isDarkMode) ? this.srcLogo = '/assets/images/logo-light.png' : this.srcLogo = '/assets/images/logo.png';


    this.language$ = this.store.select('language').subscribe(state => {
      if (state.language == 'ES') {
        this.translate.setDefaultLang('es');
        this.translate.use('es');
        this.pagination.itemsPerPageLabel = "Registros por página";
        this.pagination.nextPageLabel = "Página siguiente";
        this.pagination.previousPageLabel = "Página anterior";
        this.pagination.firstPageLabel = "Primera página";
        this.pagination.lastPageLabel = "Última página";
        return;
      }

      this.translate.setDefaultLang('en');
      this.translate.use('en');
      this.pagination.itemsPerPageLabel = "Items per page";
      this.pagination.nextPageLabel = "Next page";
      this.pagination.previousPageLabel = "Previous page";
      this.pagination.firstPageLabel = "First page";
      this.pagination.lastPageLabel = "Last page";

    })

    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        // this.isMobile = result.matches;
        console.log('isTablet => ', result)
        this.isTablet = result.matches;
      });
  }

  showNotification(): void {
    Push.create('Custom Notification', {
      body: '<h1>This is a custom notification</h1>',
      icon: 'https://static-00.iconduck.com/assets.00/angular-icon-2048x2048-24b236vf.png',
      timeout: 5000
    });

  }
  dimension;
  obtenerDimensionesToolbar() {
    if(!this.isActiveSidenav) {

      if(!this.isActiveSidenavIA) {
        console.log('1')
        this.dimension = {
          width: !(this.isMobile) && !this.isTablet ? 'calc(100% - 0px)' : ''
        }
      } else {
        console.log('2')
        this.dimension = {
          width: !(this.isMobile) && !this.isTablet ? 'calc(100% - 287px)' : ''
        }
      }

    } else {

      if(!this.isActiveSidenavIA) {
        console.log('3')
        this.dimension = {
          width: !(this.isMobile) && !this.isTablet? 'calc(100% - 287px)': ''
        }
      } else {
        console.log('4')
        this.dimension = ({
          width: !(this.isMobile) && !this.isTablet? 'calc(100% - 574px)': ''
        })
      }
      console.log(this.dimension)
    }
  }

  ngAfterViewInit(): void {
    this.isHandset$.subscribe(data => {
      if (this.isHandset$) {
        if (!this.sidenav.opened) this.sidenav.toggle();

      }
    });

    this.obtenerDimensionesToolbar()
  }


  button(): void {
    this.sidenav.toggle();
  }

  cerrarMenu(): void {
    if (this.isMobile) {
      this.sidenav.toggle();
    }
  }

  ngOnInit() {
    this.user = this._authService.getIdentity();

    if (localStorage.getItem('active-tutorial') == 'true') {
      // this.openTutorial();
    }

    this.tourService.initialize([{
      anchorId: 'panel1',
      content: 'Some content',
      title: 'First',
    }, {
      anchorId: 'panel2',
      content: 'Other content',
      title: 'Second',
    }]);

    this.tourService.start();

  }



  cambiarTema(isDark) {
    this.isDarkMode = isDark;
    (isDark) ? this.srcLogo = '/assets/images/logo-light.png' : this.srcLogo = '/assets/images/logo.png';

  }

  openTutorial(): void {
    const dialogRef = this.dialog.open(PavTutorialCarousel, {
      width: '500px',
      data: {},
      disableClose: true,
      panelClass: 'pav-tutorial-container'
    });

    dialogRef.afterClosed().subscribe(result => {
    });

    localStorage.setItem('active-tutorial', 'false');
  }

  buscarModulo(): void {
    this._dialogService.openDialogSearch();
  }

  abrirSideNavMasOpciones(): void {
    this.sidenavMasOpciones.toggle();
  }

  abrirSideNavConfiguracion(): void {
    this.sidenavConfiguration.toggle();
  }

  abrirSideNavChatIA(): void {
    this.sidenavChatIA.toggle();
  }
}
