export class SeguimientoOportunidad {
  codcia: any;
  idtkt: any;
  idset: any;
  codcli: string;
  nomcli: string;
  idres: any;
  fecreg: any;
  horset: any;
  tieset: any;
  minset: any;
  idass: any;
  idref: any;
  idstk: any;
  coduse: any;
  descri: any;
  rutadj: any;
  idmso: any;
  fecupd: Date;
  indsta: any;
  codume: any;
  idusc: any;
  fecpro: any;
  horini: any;
  horfin: any;
  horind: any;
  hortot: any;
  indas: number;
  asist: any;
  indact: any;
  procie: any;
  cararc: any;
  nomarc: any;
  fecace: any;
  feccot: any;
  numocm: any;
  impcot: any;
  codoet: any;
  desmot: any;
  codosv: any;
  numins: any;
  numkwp: any;
  codccl: string;

  constructor() {
    this.fecupd = new Date();
    this.fecreg = new Date();
    this.fecpro = new Date();

    this.indas = 0;
    this.indsta = '1';
  }
}
