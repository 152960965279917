<app-pavso-title-section [title]="'Informe de compras'" [module]="'Compras'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <select-report [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event)"></select-report>

<!--
  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Cabeceras</div>
  </div>
  <div class="pav-filtro-container">
    <div class="row">
      <div class="col s12">
        <section class="example-section" *ngIf="!loaderFields">
          <span class="example-list-section">
              <mat-checkbox class="example-margin"
                  [checked]="allComplete"
                  [indeterminate]="someComplete()"
                  (change)="setAll($event.checked)">
                  {{task.name}}
              </mat-checkbox>
          </span>
          <span class="example-list-section">
              <ul>
                  <li *ngFor="let subtask of task.subtasks">
                  <mat-checkbox [(ngModel)]="subtask.completed"
                                (ngModelChange)="updateAllComplete()">
                      {{subtask.name}}
                  </mat-checkbox>
                  </li>
              </ul>
          </span>
      </section>
      </div>
    </div>
  </div> -->

  <br>
  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Filtros</div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderFields"></mat-progress-bar>
  <div class="pav-filtro-container">
    <div class="row">
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Tiendas</mat-label>
          <mat-select multiple>
            <mat-option *ngFor="let topping of tiendas" [value]="topping.tie_codtie">{{topping.tie_destie}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">

        <select-search
          [label]="'Cliente'"
          [placeholder]="'Ej. PEREZ'"
          [name]="'cli_codcli'"
          [description]="'cli_nomcli'"
          [data]="clientes"
          (cambioSelect)="filtro.clientes = $event"
          [value]="filtro.clientes"
          ></select-search>

      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Series</mat-label>
          <mat-select multiple>
            <mat-option *ngFor="let topping of series" [value]="topping.tdo_codtdo">{{topping.sdo_codsdo}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Motivos</mat-label>
          <mat-select multiple>
            <mat-option *ngFor="let topping of motivos" [value]="topping.mmo_codmmo">{{topping.mmo_descri}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Vendedores</mat-label>
          <mat-select multiple>
            <mat-option *ngFor="let topping of vendedores" [value]="topping.vde_codvde">{{topping.vde_nomvde}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Productos</mat-label>
          <mat-select multiple>
            <mat-option *ngFor="let topping of productos" [value]="topping.prd_codprd">{{topping.prd_desesp}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Condiciones</mat-label>
          <mat-select multiple>
            <mat-option *ngFor="let topping of condiciones" [value]="topping.CPA_CODCPA">{{topping.cpa_descri}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Inventarios</mat-label>
          <mat-select multiple>
            <mat-option *ngFor="let topping of inventarios" [value]="topping.tin_codtin">{{topping.tin_descri}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Líneas</mat-label>
          <mat-select multiple>
            <mat-option *ngFor="let topping of lineas" [value]="topping.lpd_codlpd">{{topping.lpd_descri}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Documentos</mat-label>
          <mat-select multiple>
            <mat-option *ngFor="let topping of documentos" [value]="topping.TDO_CODTDO">{{topping.TDO_DESLAR}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Marcas</mat-label>
          <mat-select multiple>
            <mat-option *ngFor="let topping of marcas" [value]="topping.MPR_CODMPR">{{topping.mpr_descri}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Rango de Fechas</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Monedas</mat-label>
          <mat-select multiple>
            <mat-option *ngFor="let topping of monedas" [value]="topping.TMO_CODTMO">{{topping.tmo_deslar}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <mat-card class="mat-elevation-z0">

    <button class="pav-button-action" mat-flat-button color="primary" (click)="verReporte()" [class.spinner]="loading" [disabled]="loading">Generar Reporte</button>

  </mat-card>

</div>
