<app-pavso-title-section [title]="'Procesos generales'" [module]="'Contabilidad'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <mat-horizontal-stepper>
    <mat-step label="Genera Reportes Contables - Analíticos (1)">
        <div class="pavso-content-stepper" align="center">
            <button mat-flat-button color="primary">Generar</button>
        </div>

        <br>
        <div>
            <button mat-stroked-button matStepperNext>Siguiente</button>
        </div>
    </mat-step>

    <mat-step label="Diferencia de Cambio - Ctas Analíticas (2)">
      <div class="pavso-content-stepper" align="center">
        <button mat-flat-button color="primary">Realizar</button>
      </div>
      <br>
      <div>
        <button mat-button matStepperPrevious>Atrás</button>
        <button mat-stroked-button matStepperNext>Siguiente</button>
      </div>
    </mat-step>

    <mat-step label="Diferencia de Cambio, Cta (3)">
        <div class="pavso-content-stepper" align="center">
          <button mat-flat-button color="primary">Realizar</button>
        </div>
        <br>
        <div>
          <button mat-button matStepperPrevious>Atrás</button>
          <button mat-stroked-button matStepperNext>Siguiente</button>
        </div>
    </mat-step>
  </mat-horizontal-stepper>

  <button mat-stroked-button color="primary">Realizar Proceso Global</button>
  &nbsp;
  <button mat-stroked-button color="primary">Verificar Tipo de Cambio</button>

</div>
