import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { validarPeriodo } from 'src/app/utils/validations/period';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { AsientoContable } from 'src/app/models/index';
import { MatSelect } from '@angular/material/select';
import { MatButton } from '@angular/material/button';
import { Subject, Subscription } from 'rxjs';
import { AuthenticationService, ConfigurationService, ContabilidadService, ComprasService, VentasService } from 'src/app/services';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { orderKeyStringJSON } from 'src/app/utils/sorts/sort';
import { formatDateWithDash } from 'src/app/utils/formats/date.format';
import { MatTableDataSource } from '@angular/material/table';
import { DetalleAsientoContable, DetalleLDI } from 'src/app/models/contabilidad/asiento_contable';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { roundToDecimals } from 'src/app/utils/formats/round.format';
import { CuentaPagarDialogComponent } from '@shared/components/dialogs/cuenta-por-pagar/cuenta-pagar-dialog/cuenta-pagar-dialog.component';
import { CuentaCobrarDialogComponent } from '@shared/components/dialogs/cuenta-por-pagar/cuenta-cobrar-dialog/cuenta-cobrar-dialog.component';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable, searchInTable } from '@utils/tables/table';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { AsientoContableService } from 'src/app/services/api/contabilidad/operaciones/asiento-contable.service';
import { AsientoContableCopyDialog } from '../asiento-contable-copy-dialog/asiento-contable-copy-dialog.component';
import { TipoOperacionService } from 'src/app/services/api/contabilidad/maestros/tipo-operacion.service';

@Component({
  selector: 'app-asiento-contable-form',
  templateUrl: './asiento-contable-form.component.html',
  styleUrls: ['./asiento-contable-form.component.scss']
})
export class AsientoContableFormComponent implements OnInit, OnDestroy {

  selected: DetalleLDI;

  selectRow(row: any) {
    this.selected = row;
  }

  displayedColumns: string[] = ['ldi_corldi', 'ccn_codccn', 'cli_codcli', 'ccs_codccs', 'tdo_codtdo', 'ldi_docref', 'tmo_codtmo', 'ldi_tipcam', 'ldi_inddha', 'ldi_impnac', 'ldi_impmex', 'ldi_gloldi', 'cco_codcco'];
  dataSource: MatTableDataSource<DetalleLDI>;

  @ViewChild('paginator1') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns3: string[] = ['name', 'mn', 'me'];
  dataSource3: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator3: MatPaginator;
  @ViewChild(MatSort) sort3: MatSort;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  fecha: Date = new Date();

  year: string;
  month: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;

  asientoContable: AsientoContable;

  subdiarios: any[] = [];
  cuentasContable: any[] = [];
  cuentasContablesTotales: any[] = [];
  tiposBienServicio: any[] = [];
  tiposImpuesto: any[] = [];
  monedas: any[] = [];
  ordenesCompra: any[] = [];
  ordenesCompraTotales: any[] = [];
  vouchers: any[] = [];
  centros: any[] = [];
  tiposRetencion: any[] = [];
  tiposImpRetencion: any[] = [];
  tempTiposImpRetencion: any[] = [];
  signosctacte: any[] = [];


  cuentasTotales: any[] = [];

  cuentas: any[] = [];

  proveedores: any[] = [];

  auxiliares: any[] = [];
  tiposOperacion: any[] = [];
  tiposRenta: any[] = [];
  condiciones: any[] = [];
  tiposDoc: any[] = [];

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  @ViewChild('iAno') anoElement: ElementRef;
  @ViewChild('iMes') mesElement: ElementRef;
  @ViewChild('iSubdiario') subdiarioElement: MatSelect;
  @ViewChild('iFecha') fechaElement: ElementRef;
  @ViewChild('iTc') tcElement: ElementRef;
  @ViewChild('iGlosa') glosaElement: ElementRef;

  @ViewChild('btnAdd') buttonAddRefElement: MatButton;
  @ViewChild('btnGeneraAsiento') buttonGeneraAsientoRefElement: MatButton;

  isNew: boolean = true;

  subdiarios$: Subscription;
  tipoOperacion$: Subscription;
  cuentas$: Subscription;
  tiposBienServicio$: Subscription;
  tiposRenta$: Subscription;
  tiposImpuesto$: Subscription;
  tiposDocumento$: Subscription;
  proveedor$: Subscription;
  moneda$: Subscription;
  ordenesCompra$: Subscription;
  condiciones$: Subscription;
  voucher$: Subscription;
  centro$: Subscription;
  auxiliar$: Subscription;
  tipoRetencion$: Subscription;
  tipoImpRetencion$: Subscription;
  sendForm$: Subscription;
  permisos$: Subscription;
  tiposOperacion$: Subscription;

  permision: any = { ind_Nuevo: '0' };

  @ViewChildren("cuentaTotalSelect") selectCuentas: QueryList<MatSelect>;
  @ViewChildren("descripcionInput") inputDescripcion: QueryList<ElementRef>;
  @ViewChildren("costoSelect") selectCosto: QueryList<MatSelect>;

  montosDebeHaber: any;

  btnName: string;

  period$: Subscription;
  loading$: Subscription;

  uid: string;

  constructor(
    private _dialogService: DialogService,
    private _contabilidadService: ContabilidadService,
    private _tipoOperacionService: TipoOperacionService,
    private _asientoContableService: AsientoContableService,
    private _comprasService: ComprasService,
    private _ventasService: VentasService,
    private _configurationService: ConfigurationService,
    private _clienteService: ClienteService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    public dialog: MatDialog,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute
  ) {
    this.asientoContable = new AsientoContable();
    this.asientoContable.LDC_TIPCAM = 3;
    this.asientoContable.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    this.asientoContable.LDC_CODUSE = this._configurationService.obtenerIdUsuario();

    this.period$ = this.store.select('period').subscribe(({ year, month }) => {
      this.year = year;
      this.asientoContable.ANO_CODANO = year;
      this.month = month;
      this.asientoContable.MES_CODMES = month;
    })

    this.montosDebeHaber = [
      {
        name: "Debe",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Haber",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Diferencia",
        mn: 0.00,
        me: 0.00
      }
    ];

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource3 = fillTable(this.montosDebeHaber, this.paginator, this.sort);

  }

  ngOnInit(): void {

    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) {
        this.loadData();
      }
    })
  }

  establecerCamposPorDefectoEnNuevo(): void {
    const now = new Date();

    this.asientoContable.detalle_ldi = [];
    // this.asientoContable.ordenes = [];

    this.obtenerTasaCambio();

    if (this.year == now.getFullYear().toString() && this.month == (now.getMonth() + 1).toString()) {
      // this.asientoContable.fec = formatDateWithDash(new Date());
      return;
    }

    // this.asientoContable.cco_fecreg = formatDateWithDash(new Date(parseInt(this.year), parseInt(this.month), 0));

  }

  filaSeleccionada(row, event): void {
    this.asientoContable.detalle_ldi.forEach(element => {
      if (element.LDI_CORLDI != row.LDI_CORLDI) {
        // element.isSelected = false;
      }
    });
  }

  obtenerTasaCambio(): void {

    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.asientoContable.LDC_FECDIA)).subscribe(
      response => this.asientoContable.LDC_TIPCAM = response[0].tipcam,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    );

  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({ id }) => {
      this.loadMaestros();
      this.uid = id;

      (id == '0') ? this.btnName = this.buttonsName.BTN_STORE : this.btnName = this.buttonsName.BTN_UPDATE;

    })
  }

  obtenerAsientoContable(): void {

    const [year, month, subdiario, correlativo] = [this.uid.substring(0, 4), this.uid.substring(4, 6), this.uid.substring(6, 10), this.uid.substring(10, 15)];

    this._asientoContableService.obtenerAsientoContable(year, month, subdiario, correlativo).subscribe(
      asiento => {
        this.asientoContable = asiento;


        const detalle = this.asientoContable.detalle_ldi ? asiento.detalle_ldi : [];

        this.dataSource = fillTable(detalle, this.paginator, this.sort);

        this.establecerDebeHaber();

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK");
        this.loaderData = false;
      }
    )
  }



  loadMaestros(): void {

    this.loaderData = true;

    this.permisos$ = this._configurationService.getUserPermisions().subscribe(
      permisos => {
        // this.permision = permisos[0].find(element => element.usuario == this._authService.getUsuarioSistema().toString().toUpperCase())

        this.condiciones$ = this._comprasService.obtenerCondicionesPago().subscribe(
          condiciones => {
            this.condiciones = condiciones;

            this.subdiarios$ = this._contabilidadService.obtenerSubdiarios().subscribe(
              subdiarios => {
                this.subdiarios = subdiarios.filter(element => element.sco_indcom == 1);

                this.tiposOperacion$ = this._tipoOperacionService.obtenerTiposOperacion().subscribe(
                  tiposOperacion => {
                    this.tiposOperacion = tiposOperacion;

                    this.cuentas$ = this._contabilidadService.listarPlanDeCuentas().subscribe(
                      cuentas => {
                        this.cuentasTotales = cuentas;
                        this.cuentasContable = cuentas.filter(cuenta => cuenta.ind_ctacte == "P");
                        this.cuentas = cuentas;

                        this.tiposDocumento$ = this._contabilidadService.obtenerTiposDocumento().subscribe(
                          tiposDocumento => {
                            this.tiposDoc = tiposDocumento.filter(item => item.tdo_indcom == 1);
                            this.tiposDoc = tiposDocumento.sort(orderKeyStringJSON("TDO_CODTDO"));

                            this.centro$ = this._contabilidadService.listarCentroCostos().subscribe(
                              centro => {
                                this.centros = centro;

                                this.auxiliar$ = this._clienteService.obtenerClientes().subscribe(
                                  clientes => {
                                    this.auxiliares = clientes.filter(cliente => cliente.cli_indtra == 1);
                                    this.proveedores = clientes.filter(cliente => cliente.cli_indpro == 1);

                                    this.establecerCamposPorDefectoEnNuevo();

                                    if (this.uid != '0') {
                                      this.obtenerAsientoContable();
                                      return;
                                    }

                                    this.loaderData = false;
                                  },
                                  error => {
                                    this._snackBarService.showError('Error al obtener clientes', 'Ok');
                                    this.loaderData = false;
                                  }
                                )
                              },
                              error => {
                                this._snackBarService.showError('Error al obtener centros de costo', 'Ok');
                                this.loaderData = false;

                              }
                            )
                          },
                          error => {
                            this._snackBarService.showError('Error al obtener tipos de documento', 'Ok');
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener cuentas', 'Ok');
                        this.loaderData = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener tipos de operacion', 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener subdiarios', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener condiciones de pago', 'Ok');
            this.loaderData = false;
          }
        )

      },
      error => {
        this._snackBarService.showError('Error al obtener permisos', 'Ok');
        this.loaderData = false;
      }
    )


  }

  agregarItem(): void {
    const item = new DetalleLDI();

    item.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    item.LDI_CODUSE = this._configurationService.obtenerIdUsuario();
    item.ANO_CODANO = this.year;
    item.MES_CODMES = this.month;
    item.ldi_anoccl = this.year;
    item.ldi_mesccl = this.month;
    item.SCO_CODSCO = this.asientoContable.SCO_CODSCO;
    item.LDI_TIPCAM = this.asientoContable.LDC_TIPCAM;
    item.LDI_GLOLDI = this.asientoContable.LDC_GLODIA;

    this.asientoContable.detalle_ldi.push(item);
    this.dataSource = fillTable(this.asientoContable.detalle_ldi, this.paginator, this.sort);

    this.asientoContable.detalle_ldi.forEach((element, key) => {
      element.LDI_CORLDI = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    this.establecerDebeHaber();
  }

  insertarItem(): void {

    const item = new DetalleLDI();

    item.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    item.LDI_CODUSE = this._configurationService.obtenerIdUsuario();
    item.ANO_CODANO = this.year;
    item.MES_CODMES = this.month;
    item.ldi_anoccl = this.year;
    item.ldi_mesccl = this.month;
    item.SCO_CODSCO = this.asientoContable.SCO_CODSCO;
    item.LDI_TIPCAM = this.asientoContable.LDC_TIPCAM;
    item.LDI_GLOLDI = this.asientoContable.LDC_GLODIA;

    let i = 0;

    if (this.selected) {
      this.asientoContable.detalle_ldi.forEach((item, index) => {
        if (item.LDI_CORLDI == this.selected.LDI_CORLDI) i = index;
      })

      this.asientoContable.detalle_ldi.splice(i, 0, item);
    } else {
      this.asientoContable.detalle_ldi.push(item);
      this.asientoContable.detalle_ldi = this.asientoContable.detalle_ldi.reverse();
    }

    this.asientoContable.detalle_ldi.forEach((element, key) => {
      console.log('ids', key)
      element.LDI_CORLDI = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    console.log('detalle', this.asientoContable.detalle_ldi)

    this.dataSource = fillTable(this.asientoContable.detalle_ldi, this.paginator, this.sort);

    this.establecerDebeHaber();

  }

  quitarItem(): void {
    let row = this.asientoContable.detalle_ldi.find(item => item == this.selected);

    console.log('fila seleccionada', row);

    if (!row && this.asientoContable.detalle_ldi.length > 0) row = this.asientoContable.detalle_ldi[0];

    let details = [];
    this.asientoContable.detalle_ldi.forEach(element => {
      if (element.LDI_CORLDI != row.LDI_CORLDI) {
        details.push(element)
      }
    });

    this.asientoContable.detalle_ldi = details;

    this.asientoContable.detalle_ldi.forEach((element, key) => {
      element.LDI_CORLDI = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    this.dataSource = fillTable(details, this.paginator, this.sort);

    this.selected = null;

    this.establecerDebeHaber();
  }

  establecerDebeHaber(): void {

    this.montosDebeHaber[0].mn = 0;
    this.montosDebeHaber[0].me = 0;

    this.montosDebeHaber[1].mn = 0;
    this.montosDebeHaber[1].me = 0;

    this.montosDebeHaber[2].mn = 0;
    this.montosDebeHaber[2].me = 0;

    this.asientoContable.detalle_ldi.forEach(element => {
      if (element.LDI_INDDHA == "D") {
        this.montosDebeHaber[0].mn += Number(element.LDI_IMPNAC);
        this.montosDebeHaber[0].me += Number(element.LDI_IMPMEX);
      } else {
        this.montosDebeHaber[1].mn += Number(element.LDI_IMPNAC);
        this.montosDebeHaber[1].me += Number(element.LDI_IMPMEX);
      }
    });

    this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
    this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

  }

  enviarFormulario(f: NgForm): void {

    this.loaderReg = true;
    this.uid == '0' ? this.registrarAsientoContable(f) : this.editarAsientoContable(f);
  }

  registrarAsientoContable(f: NgForm): void {

    this.sendForm$ = this._asientoContableService.registrarAsientoContable(this.asientoContable).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Asiento contable registrado exitosamente', '350px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError('Error al registrar asiento contable', 'Ok');
        this.loaderReg = false;
      }
    )

  }

  editarAsientoContable(f: NgForm): void {
    this.sendForm$ = this._asientoContableService.actualizarAsientoContable(this.asientoContable).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Asiento contable actualizado exitosamente', '350px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError('Error al actualizar asiento contable', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  seleccionarSubdiario(event): void {

  }

  cambiarFecha(event): void {
    this.obtenerTasaCambio();
  }

  establecerFecha(): void {
    this.obtenerTasaCambio();
  }

  establecerTC(): void {

    this.asientoContable.detalle_ldi.forEach(item => {
      item.LDI_TIPCAM = this.asientoContable.LDC_TIPCAM;
    })
  }

  establecerAno(): void {

  }

  establecerMes(): void {

  }

  establecerGlosa(): void {
  }

  focusCampo(evento): void {
    evento.target.select();
  }

  applyFilter(event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  confirmarNuevoItem(item: DetalleAsientoContable): void {
    if (
      item.ccn_codccn &&
      item.tdo_codtdo &&
      item.ldi_docref &&
      item.tmo_codtmo &&
      item.ldi_inddha &&
      item.ldi_impnac &&
      item.ldi_impmex &&
      item.ldi_gloldi
    ) {
      item.isEditing = !item.isEditing;
      this.montosDebeHaber[0].mn = 0;
      this.montosDebeHaber[0].me = 0;
      this.montosDebeHaber[1].mn = 0;
      this.montosDebeHaber[1].me = 0;
      this.montosDebeHaber[2].mn = 0;
      this.montosDebeHaber[2].me = 0;

      this.asientoContable.detalle_ldi.forEach(element => {
        if (element.LDI_INDDHA == "D") {
          this.montosDebeHaber[0].mn += element.LDI_IMPNAC;
          this.montosDebeHaber[0].me += element.LDI_IMPMEX;
        } else {
          this.montosDebeHaber[1].mn += element.LDI_IMPNAC;
          this.montosDebeHaber[1].me += element.LDI_IMPMEX;
        }
      });

      this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
      this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

      this.buttonAddRefElement.focus();
    } else {
      this._snackBarService.showError("Es necesario completar todos los campos", "OK");
    }
  }



  ajusteDiferencia(): void {
    const diferencia_mn = this.dataSource3.data[2].mn;
    const diferencia_me = this.dataSource3.data[2].me;
    console.table({ diferencia_mn, diferencia_me })
    if (Math.abs(diferencia_mn) <= 0.05 && diferencia_mn > 0 && Math.abs(diferencia_me) <= 0.05 && diferencia_me > 0) {
      let ps_tmo_mn;
      let pn_impnac;
      let pn_impmex;
      let ps_codtdo;
      let ps_codccs;
      let ps_cuenta;
      // let ls_cad;

      (Math.abs(diferencia_mn) != 0) ? ps_tmo_mn = "SO" : ps_tmo_mn = "DO";

      pn_impnac = Math.abs(diferencia_mn);
      pn_impmex = Math.abs(diferencia_me);

      ps_codtdo = (diferencia_mn < 0 || diferencia_me < 0) ? "AJP" : "AJP";
      ps_codccs = "";

      this._contabilidadService.configuraTipoDocCtaContable(ps_codtdo).subscribe(
        response => {

          ps_cuenta = response[0].ccn_codmn;
          ps_codccs = response[0].ccs_codccs;

          if (ps_cuenta) {

            let detailItem = new DetalleLDI();

            detailItem.LDI_INDDHA = "D";
            detailItem.LDI_TCAMEU = 0;
            detailItem.LDI_INDDES = 0;
            detailItem.fca_codfca = "";
            detailItem.ldi_impsal = 0;
            detailItem.ldi_indccl = 0;
            detailItem.LDI_INDCCO = "N";
            detailItem.LDI_INDDES = 0;
            detailItem.ldi_mesccl = "";
            detailItem.SCO_CODSCO = this.asientoContable.SCO_CODSCO;
            detailItem.SUC_CODSUC = "01";
            detailItem.TDO_DOCREF = "";
            detailItem.VDE_CODVDE = "";

            detailItem.CCN_CODCCN = ps_cuenta;
            detailItem.LDI_GLOLDI = this.asientoContable.LDC_GLODIA;
            detailItem.TMO_CODTMO = ps_tmo_mn;
            detailItem.TDO_CODTDO = ps_codtdo;
            // detailItem.LDI_DOCREF = this.asientoContable.cco_numdoc;
            detailItem.LDI_TIPCAM = roundToDecimals(pn_impmex / pn_impnac, 3);
            detailItem.LDI_INDDHA = (ps_codtdo == "AJP") ? "D" : "H";
            detailItem.LDI_IMPNAC = roundToDecimals(pn_impnac, 2);
            detailItem.LDI_IMPMEX = roundToDecimals(pn_impmex, 2);
            detailItem.LDI_INDCCO = "N";

            if (ps_codtdo == "AJP" && ps_codccs > 0) {
              detailItem.CCS_CODCCS = ps_codccs;
            }

            this.asientoContable.detalle_ldi.push(detailItem);

            this.dataSource = fillTable(this.asientoContable.detalle_ldi, this.paginator, this.sort);

            this.asientoContable.detalle_ldi.forEach((element, key) => {
              element.LDI_CORLDI = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
            })


            this.montosDebeHaber[0].mn = 0;
            this.montosDebeHaber[0].me = 0;
            this.montosDebeHaber[1].mn = 0;
            this.montosDebeHaber[1].me = 0;
            this.montosDebeHaber[2].mn = 0;
            this.montosDebeHaber[2].me = 0;

            this.asientoContable.detalle_ldi.forEach(element => {
              if (element.LDI_INDDHA == "D") {
                this.montosDebeHaber[0].mn += element.LDI_IMPNAC;
                this.montosDebeHaber[0].me += element.LDI_IMPMEX;
              } else {
                this.montosDebeHaber[1].mn += element.LDI_IMPNAC;
                this.montosDebeHaber[1].me += element.LDI_IMPMEX;
              }
            });

            this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
            this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

          }
        },
        error => this._snackBarService.showError(error.error.msg, "OK")
      )
    } else {
      this._snackBarService.showError("La diferencia tiene que ser > 0 y <= a 0.05", "OK");
    }


    // if(this.purchaseRecord.tot)
  }

  borrarAsientosDestino(): void {
    // this.purchaseRecord.detalle = [];
    let detalleNuevo = [];
    this.asientoContable.detalle_ldi.forEach(item => {
      if (item.LDI_INDDES != 1) {
        detalleNuevo.push(item);
      }
    });
    this.asientoContable.detalle_ldi = detalleNuevo;
  }

  documentosPorPagar(): void {
    const dialogRef = this.dialog.open(CuentaPagarDialogComponent, {
      width: '1000px',
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        result.forEach(item => {
          let detailItem = new DetalleLDI();
          detailItem.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
          detailItem.ANO_CODANO = this.year;
          detailItem.MES_CODMES = this.month;
          detailItem.LDI_CORLDI = `0000${this.asientoContable.detalle_ldi.length + 1}`;
          detailItem.TMO_CODTMO = item.moneda;
          detailItem.LDI_INDDHA = "D";
          detailItem.TDO_CODTDO = item.tip_doc;
          detailItem.LDI_IMPNAC = (item.moneda == "SO") ? item.importe_saldo : item.importe_saldo / item.tipo_cambio;
          detailItem.LDI_IMPMEX = (item.moneda == "SO") ? item.importe_saldo * item.tipo_cambio : item.importe_saldo;
          detailItem.LDI_TCAMEU = 0;
          detailItem.LDI_INDDES = 0;
          detailItem.fca_codfca = "";
          detailItem.ldi_anoccl = item.ano;
          detailItem.ldi_mesccl = item.mes;
          detailItem.LDI_FECEMI = item.fec_emision;
          detailItem.LDI_FECVEN = item.fec_vencimiento;
          detailItem.ldi_impsal = 0;
          detailItem.ldi_indccl = 0;
          detailItem.LDI_INDCCO = "N";
          detailItem.LDI_INDDES = 0;
          detailItem.ldi_mesccl = "";
          detailItem.SCO_CODSCO = item.subdiario;
          detailItem.SUC_CODSUC = "01";
          detailItem.TDO_DOCREF = "";
          detailItem.VDE_CODVDE = "";
          detailItem.CCO_CODCCO = item.cta_cte;
          detailItem.CCN_CODCCN = item.cuenta;
          detailItem.CLI_CODCLI = item.auxiliar;
          detailItem.LDI_DOCREF = item.nro_docu;
          detailItem.LDI_TIPCAM = item.tipo_cambio;
          detailItem.LDI_GLOLDI = item.glosa;

          this.asientoContable.detalle_ldi.push(detailItem);
          this.dataSource = fillTable(this.asientoContable.detalle_ldi, this.paginator, this.sort);

        });

      }
    })
  }

  documentosPorCobrar(): void {
    const dialogRef = this.dialog.open(CuentaCobrarDialogComponent, {
      width: '1000px',
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        result.forEach(item => {
          let detailItem = new DetalleLDI();

          detailItem.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
          detailItem.ANO_CODANO = this.year;
          detailItem.MES_CODMES = this.month;
          detailItem.LDI_CORLDI = `0000${this.asientoContable.detalle_ldi.length + 1}`;
          detailItem.TMO_CODTMO = item.moneda;
          detailItem.LDI_INDDHA = "D";
          detailItem.TDO_CODTDO = item.tip_doc;
          detailItem.LDI_IMPNAC = (item.moneda == "SO") ? item.importe_saldo : item.importe_saldo / item.tipo_cambio;
          detailItem.LDI_IMPMEX = (item.moneda == "SO") ? item.importe_saldo * item.tipo_cambio : item.importe_saldo;
          detailItem.LDI_TCAMEU = 0;
          detailItem.LDI_INDDES = 0;
          detailItem.fca_codfca = "";
          detailItem.ldi_anoccl = item.ano;
          detailItem.ldi_mesccl = item.mes;
          detailItem.LDI_FECEMI = item.fec_emision;
          detailItem.LDI_FECVEN = item.fec_vencimiento;
          detailItem.ldi_impsal = 0;
          detailItem.ldi_indccl = 0;
          detailItem.LDI_INDCCO = "N";
          detailItem.LDI_INDDES = 0;
          detailItem.ldi_mesccl = "";
          detailItem.SCO_CODSCO = item.subdiario;
          detailItem.SUC_CODSUC = "01";
          detailItem.TDO_DOCREF = "";
          detailItem.VDE_CODVDE = "";
          detailItem.CCO_CODCCO = item.cta_cte;
          detailItem.CCN_CODCCN = item.cuenta;
          detailItem.CLI_CODCLI = item.auxiliar;
          detailItem.LDI_DOCREF = item.nro_docu;
          detailItem.LDI_TIPCAM = item.tipo_cambio;
          detailItem.LDI_GLOLDI = item.glosa;

          this.asientoContable.detalle_ldi.push(detailItem);
          this.dataSource = fillTable(this.asientoContable.detalle_ldi, this.paginator, this.sort);

        });

      }
    })
  }

  copiarAsientoContable(): void {
    const dialogRef = this.dialog.open(AsientoContableCopyDialog, {
      width: '350px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(asiento => {
      console.log('asiento', asiento)
      if (asiento) {
        console.log('asiento', asiento)
        this.asientoContable = asiento;
        this.dataSource = fillTable(this.asientoContable.detalle_ldi ? this.asientoContable.detalle_ldi : [], this.paginator, this.sort);
      }
    });
  }

  calculoImportesMN(row: DetalleLDI): void {
    row.LDI_IMPMEX = roundToDecimals(Number(row.LDI_IMPNAC) / Number(row.LDI_TIPCAM), 2);
    this.establecerDebeHaber();
  }
  calculoImportesME(row: DetalleLDI): void {
    row.LDI_IMPNAC = roundToDecimals(Number(row.LDI_IMPMEX) * Number(row.LDI_TIPCAM), 2);
    this.establecerDebeHaber();
  }

  volver(): void {
    this._router.navigate(['/modulo-contabilidad/asientos-contables']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.subdiarios$,
      this.tipoOperacion$,
      this.cuentas$,
      this.tiposBienServicio$,
      this.tiposRenta$,
      this.tiposImpuesto$,
      this.tiposDocumento$,
      this.proveedor$,
      this.moneda$,
      this.ordenesCompra$,
      this.condiciones$,
      this.voucher$,
      this.centro$,
      this.auxiliar$,
      this.tipoRetencion$,
      this.tipoImpRetencion$,
      this.sendForm$,
      this.permisos$,
      this.tiposOperacion$
    ])

  }

}
