<app-pavso-title-section [title]="'Dinámicos'" [module]="'VENTAS'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <select-report [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event)"></select-report>

  <div align="center">
    <button class="pav-button-action" mat-flat-button color="primary" (click)="verReporteDinamico()"
      [class.spinner]="loading" [disabled]="loading">Generar Reporte</button>
  </div>
  <br>
  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">{{'apps.crm.informe.filter' | translate}}</div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <div class="pav-filtro-container">

    <div class="row">
      <div class="col s12 m12 l3">
        <select-search [label]="'Moneda'" [placeholder]="'Ej. SOLES'" [name]="'TMO_CODTMO'" [description]="'tmo_deslar'"
          [data]="monedas" (cambioSelect)="filtroReporteVenta.moneda = $event" [value]="filtroReporteVenta.moneda"
          [disabled]="loading"></select-search>
      </div>
      <div class="col s12 m12 l3">
        <select-search [label]="'Documento'" [placeholder]="'Ej. FACTURA'" [name]="'tdo_codtdo'"
          [description]="'tdo_deslar'" [data]="documentos" (cambioSelect)="filtroReporteVenta.documento = $event"
          [value]="filtroReporteVenta.documento" [disabled]="loading" [multiple]="true"></select-search>

      </div>
      <div class="col s12 m12 l3">
        <select-search [label]="'Supervisor'" [placeholder]="'Ej. OFICINA LIMA'" [name]="'sup_codsup'"
          [description]="'sup_descri'" [data]="supervisores" (cambioSelect)="filtroReporteVenta.supervisor = $event"
          [value]="filtroReporteVenta.supervisor" [disabled]="loading" [multiple]="true"></select-search>
      </div>
      <div class="col s12 m12 l3">
        <select-search [label]="'Tienda'" [placeholder]="'Ej. LIMA'" [name]="'tie_codtie'" [description]="'tie_destie'"
          [data]="tiendas" (cambioSelect)="filtroReporteVenta.tienda = $event" [value]="filtroReporteVenta.tienda"
          [disabled]="loading" [multiple]="true"></select-search>
      </div>

      <div class="col s12 m12 l3">

        <select-search [label]="'Serie'" [placeholder]="'Ej. 001'" [name]="'serie'" [description]="'serie'"
          [data]="series" (cambioSelect)="filtroReporteVenta.serie = $event" [value]="filtroReporteVenta.serie"
          [disabled]="loading" [multiple]="true"></select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search [label]="'Motivo'" [placeholder]="'Ej. OTROS'" [name]="'mmo_codmmo'" [description]="'mmo_descri'"
          [data]="motivos" (cambioSelect)="filtroReporteVenta.motivo = $event" [value]="filtroReporteVenta.motivo"
          [disabled]="loading" [multiple]="true"></select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search [label]="'Vendedor'" [placeholder]="'Ej. OTROS'" [name]="'codvde'" [description]="'nomvde'"
          [data]="vendedores" (cambioSelect)="filtroReporteVenta.vendedor = $event"
          [value]="filtroReporteVenta.vendedor" [disabled]="loading" [multiple]="true"></select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search [label]="'Cliente'" [placeholder]="'Ej. Cliente'" [name]="'cli_codcli'"
          [description]="'cli_nomcli'" [data]="clientes" (cambioSelect)="filtroReporteVenta.cliente = $event"
          [value]="filtroReporteVenta.cliente" [disabled]="loading" [multiple]="true"></select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search [label]="'Condición de pago'" [placeholder]="'Ej. Condición de pago'" [name]="'CPA_CODCPA'"
          [description]="'CPA_DESCRI'" [data]="condiciones" (cambioSelect)="filtroReporteVenta.condicion = $event"
          [value]="filtroReporteVenta.condicion" [disabled]="loading" [multiple]="true"></select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search [label]="'Producto'" [placeholder]="'Ej. 0001'" [name]="'prd_codprd'" [description]="'DESC_ESPA'"
          [data]="productos" (cambioSelect)="filtroReporteVenta.producto = $event" [value]="filtroReporteVenta.producto"
          [disabled]="loading" [multiple]="true"></select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search [label]="'Inventario'" [placeholder]="'Ej. 0001'" [name]="'TIN_CODTIN'"
          [description]="'TIN_DESCRI'" [data]="inventarios" (cambioSelect)="filtroReporteVenta.inventario = $event"
          [value]="filtroReporteVenta.inventario" [disabled]="loading" [multiple]="true"></select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search [label]="'Canal'" [placeholder]="'Ej. 0001'" [name]="'vds_codvds'" [description]="'vds_descri'"
          [data]="canales" (cambioSelect)="filtroReporteVenta.canal = $event" [value]="filtroReporteVenta.canal"
          [disabled]="loading" [multiple]="true"></select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search [label]="'Línea'" [placeholder]="'Ej. 0001'" [name]="'lpd_codlpd'" [description]="'lpd_descri'"
          [data]="lineas" (cambioSelect)="filtroReporteVenta.linea = $event" [value]="filtroReporteVenta.linea"
          [disabled]="loading" [multiple]="true"></select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search [label]="'Marca'" [placeholder]="'Ej. 0001'" [name]="'MPR_CODMPR'" [description]="'MPR_DESABR'"
          [data]="marcas" (cambioSelect)="filtroReporteVenta.marca = $event" [value]="filtroReporteVenta.marca"
          [disabled]="loading" [multiple]="true"></select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search [label]="'Color'" [placeholder]="'Ej. 0001'" [name]="'col_codcol'" [description]="'col_descri'"
          [data]="colores" (cambioSelect)="filtroReporteVenta.color = $event" [value]="filtroReporteVenta.color"
          [disabled]="loading" [multiple]="true"></select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search [label]="'Modelo'" [placeholder]="'Ej. 0001'" [name]="'res_idres'" [description]="'res_nombre'"
          [data]="modelos" (cambioSelect)="filtroReporteVenta.modelo = $event" [value]="filtroReporteVenta.modelo"
          [disabled]="loading" [multiple]="true"></select-search>

      </div>

      <div class="col s12 m12 l3">

        <select-search [label]="'Zona'" [placeholder]="'Ej. 0001'" [name]="'ZVE_CODZVE'" [description]="'ZVE_NOMZVE'"
          [data]="zonas" (cambioSelect)="filtroReporteVenta.zona = $event" [value]="filtroReporteVenta.zona"
          [disabled]="loading" [multiple]="true"></select-search>

      </div>

      <div class="col s12 m12 l3">
        <mat-checkbox class="example-margin" color="accent" [(ngModel)]="filtroReporteVenta.incluidoIGV"
          name="incluidoIGV" [ngStyle]="{marginTop: '15px'}">Incluido IGV</mat-checkbox>

      </div>

    </div>

  </div>
  <br>
  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Seleccionar columnas</div>
  </div>

  <mat-progress-bar *ngIf="loaderColumnas" mode="indeterminate"></mat-progress-bar>

  <div class="pav-filtro-container">
    <div class="row">
      <div class="col s12 m12 l5">
        <mat-form-field>
          <mat-label>Selección registrada</mat-label>
          <mat-select multiple [disabled]="loading" [(ngModel)]="seleccion" name="seleccion">
            <mat-option *ngFor="let seleccion of selecciones"
              [value]="seleccion.FPR_CODFPR">{{seleccion.fpr_descri}}</mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-stroked-button (click)="grabar()" color="primary" [disabled]="loading"
          [ngStyle]="{background: '#6cd6df', width: isMobile || isTablet ? '100%': '180px', height: '45px', color: '#222'}"
          matTooltip="Agregar registro manualmente">
          <mat-icon>add</mat-icon>
          Grabar
        </button>
      </div>
      <div class="col s12 m12 l7">
        <section class="example-section">
          <span class="example-list-section">
            <mat-checkbox class="example-margin" [checked]="allComplete" [indeterminate]="someComplete()"
              (change)="setAll($event.checked)">
              {{task.name}}
            </mat-checkbox>
          </span>
          <span class="example-list-section">
            <div class="row">
              <ul>
                <div *ngFor="let subtask of task.subtasks" class="col s12 m12 l4">
                  <li>
                    <mat-checkbox class="pav-cb-item" [(ngModel)]="subtask.completed"
                      (ngModelChange)="updateAllComplete()">
                      {{subtask.name}}
                    </mat-checkbox>
                  </li>
                </div>
              </ul>
            </div>
          </span>
        </section>
      </div>
    </div>
  </div>
  <br>
  <div class="pav-filtro-header"
    *ngIf=" reporteSeleccionado[0] == 'VENTAS_RA' || reporteSeleccionado[0] == 'VENTAS_RT' ">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">{{'apps.ventas.informe.period' | translate}}</div>
  </div>
  <div class="pav-filtro-container"
    *ngIf=" reporteSeleccionado[0] == 'VENTAS_RA' || reporteSeleccionado[0] == 'VENTAS_RT' ">
    <div class="row">
      <div class="col s12 m12 l3" *ngIf="reporteSeleccionado[0] == 'VENTAS_RT'">
        <mat-form-field>
          <mat-label>{{'general.dialog.activity-period.form.year' | translate}}</mat-label>
          <mat-select [(ngModel)]="yearPeriod" name="yearPeriod">
            <mat-option *ngFor="let year of years" [value]="year.id">
              {{year.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l3" *ngIf="reporteSeleccionado[0] == 'VENTAS_RT'">
        <mat-form-field>
          <mat-label>{{'general.dialog.activity-period.form.month' | translate}}</mat-label>
          <mat-select [(ngModel)]="monthPeriod" name="monthPeriod">
            <div *ngFor="let month of months">
              <mat-option [value]="month.id">
                {{month.name}}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l1" *ngIf="reporteSeleccionado[0] == 'VENTAS_RT'">
        <button mat-mini-fab color="primary" type="button" (click)="agregarPeriodo()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="col s12 m12 l2" *ngIf="reporteSeleccionado[0] == 'VENTAS_RA'">
        <mat-form-field>
          <mat-label>Grupo 1</mat-label>
          <mat-select [(ngModel)]="filtroReporteVenta.agrupador1" name="agrupador1"
            (selectionChange)="seleccionarAgrupador($event)">
            <div *ngFor="let agrupador of agrupadores">
              <mat-option [value]="agrupador.grv_campo">
                {{agrupador.grv_descri}}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l2" *ngIf="reporteSeleccionado[0] == 'VENTAS_RA'">
        <mat-form-field>
          <mat-label>Grupo 2</mat-label>
          <mat-select [(ngModel)]="filtroReporteVenta.agrupador2" name="agrupador2"
            [disabled]="!filtroReporteVenta.agrupador1">
            <div *ngFor="let agrupador of agrupadores2">
              <mat-option [value]="agrupador.grv_campo">
                {{agrupador.grv_descri}}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l2" *ngIf="reporteSeleccionado[0] == 'VENTAS_RA'">
        <mat-checkbox [ngStyle]="{marginTop: '15px'}" class="example-margin" [(ngModel)]="filtroReporteVenta.verDetalle"
          name="verDetalle">Ver detalle</mat-checkbox>

      </div>
      <div class="col s12 m12 l2">

        <button mat-icon-button (click)="cleanAgrupadores()"
          aria-label="Example icon button with a vertical three dot icon">
          <mat-icon>cleaning_services</mat-icon>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let periodo of filtroReporteVenta.periodos">
            <mat-icon>date_range</mat-icon> &nbsp;
            {{periodo.year}} - {{periodo.month}}
            &nbsp;
            <button (click)="quitarPeriodo(periodo.id)" mat-icon-button color="warn">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>
</div>
