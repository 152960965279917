import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ComprasService, VentasService } from 'src/app/services';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { Subscription } from 'rxjs';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'tipo-servicio-list',
  templateUrl: './tipo-servicio-list.component.html',
  styleUrls: ['./tipo-servicio-list.component.css']
})
export class TipoServicioListComponent implements OnInit, OnDestroy {

  loaderData: boolean;

  displayedColumns: string[] = ['acciones', 'TSE_CODTSE', 'TSE_DESTSE'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;
  typeService$: Subscription;

  isCreate: boolean;
  isEdit: boolean;
  isDownLoadToExcel: boolean;

  loading$: Subscription;

  constructor(
    private _comprasService: ComprasService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>
  ) {
    this.store.select('permissions').subscribe(state => {
      this.isCreate          = state.isCreate;
      this.isEdit            = state.isEdit;
      this.isDownLoadToExcel = state.isDownLoadToExcel;
    })

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.obtenerTiposDeServicio();
    })
  }

  obtenerTiposDeServicio(): void {
    this.loaderData = true;
    this._comprasService.listarTiposServicioProveedor().subscribe(
      response => {
        this.dataSource = fillTable(response, this.paginator, this.sort);
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
        this.loaderData = false;
      }
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy() {
    unsubscribeSubscription([
      this.typeService$,
      this.loading$
    ])

  }

}
