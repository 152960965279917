<app-pavso-title-section [title]="'apps.crm.operaciones.presupuesto.title' | translate" [module]="'CRM'" [estado]="presupuesto.indsta"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

  <div class="row">
    <div class="col s12 m12 l8">
      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l2">
            <mat-form-field>
              <mat-label>{{'apps.crm.operaciones.presupuesto.form.nro-budget' | translate}}</mat-label>
              <input
                type="text"
                matInput
                [placeholder]="'apps.crm.operaciones.presupuesto.form.nro-budget' | translate"
                name="numopc"
                readonly
                [(ngModel)]="presupuesto.numopc"
              />
            </mat-form-field>
          </div>

          <div class="col s12 m12 l2">
            <mat-form-field>
              <mat-label>{{'apps.crm.operaciones.presupuesto.form.date' | translate}}</mat-label>
              <input
                [(ngModel)]="presupuesto.fecopc"
                name="fecopc"
                (dateChange)="cambiarFecha()"
                maxlength="10"
                matInput
                [matDatepicker]="fechahoy"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fechahoy"
              ></mat-datepicker-toggle>
              <mat-datepicker #fechahoy></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l2">
            <mat-form-field>
              <mat-label>{{'apps.crm.operaciones.presupuesto.form.exchange-rate' | translate}}</mat-label>
              <input
                appTwoDigitDecimal
                class="pav-input-align-right"
                type="text"
                [(ngModel)]="presupuesto.tipcam"
                matInput
                placeholder="T/C"
                name="tipcam"
                required
                min="0"
                (blur)="blurTipoCambio()"
              />
            </mat-form-field>
          </div>

          <div class="col s12 m12 l2">
            <mat-form-field>
              <mat-label>{{'apps.crm.operaciones.presupuesto.form.margin' | translate}}</mat-label>
              <input
                appTwoDigitDecimal
                class="pav-input-align-right"
                type="text"
                [(ngModel)]="presupuesto.margen"
                matInput
                [placeholder]="'apps.crm.operaciones.presupuesto.form.margin' | translate"
                name="margen"
                required
                min="0"
                step='0.01'
                (blur)="blurMargen()"
              />
            </mat-form-field>
          </div>

          <div class="col s12 m12 l2">
            <mat-form-field>
              <mat-label>{{'apps.crm.operaciones.presupuesto.form.exchange-rate-eu' | translate}}</mat-label>
              <input
                appTwoDigitDecimal
                type="text"
                class="pav-input-align-right"
                [(ngModel)]="presupuesto.tipeur"
                matInput
                [placeholder]="'apps.crm.operaciones.presupuesto.form.exchange-rate-eu' | translate"
                name="tipeur"
                required
                min="0"
                (blur)="blurTipoCambioEur()"
              />
            </mat-form-field>
          </div>

          <div class="col s12 m12 l12">
            <mat-form-field>
              <mat-label>{{'apps.crm.operaciones.presupuesto.form.detail' | translate}}</mat-label>
              <input
                type="text"
                [(ngModel)]="presupuesto.obsopc"
                matInput
                [placeholder]="'apps.crm.operaciones.presupuesto.form.detail' | translate"
                name="detalle"
                required
              />
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>{{'apps.crm.operaciones.presupuesto.form.authorization' | translate}}</mat-label>
              <mat-select [(ngModel)]="presupuesto.cliaut" required name="cliaut">
                <mat-option *ngFor="let responsable of responsables; trackBy: trackByResponsable" [value]="responsable.idres"> {{responsable.nombre}} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>{{'apps.crm.operaciones.presupuesto.form.currency' | translate}}</mat-label>
              <mat-select [(ngModel)]="presupuesto.codtmo" required name="codtmo">
                <mat-option *ngFor="let moneda of monedas; trackBy: trackByMoneda" [value]="moneda.TMO_CODTMO"> {{moneda.tmo_deslar}} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3" *ngIf="btnName == 'general.button.update'">
            <button
              type="button"
              mat-flat-button
              color="primary"
              class="pav-button-flex"
              (click)="generarCotizacion()"
            >
            {{'apps.crm.operaciones.presupuesto.button.generate-quotation' | translate}}
            </button>
          </div>

          <div class="col s12 m12 l3" *ngIf="btnName == 'general.button.update'">
            <button
              type="button"
              mat-flat-button
              color="primary"
              class="pav-button-flex"
            >
            {{'apps.crm.operaciones.presupuesto.button.list-quotation' | translate}}
            </button>
          </div>

        </div>
      </mat-card>
    </div>
    <div class="col s12 m12 l4">
      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l10">
            <mat-form-field>
              <mat-label>{{'apps.crm.operaciones.presupuesto.form.nro-budget-copy' | translate}}</mat-label>
              <input
                type="text"
                matInput
                #numopc
                (keyup.enter)="copiarPresupuesto(numopc)"
                [placeholder]="'apps.crm.operaciones.presupuesto.form.nro-budget-copy' | translate"
                name="numopc"

              />
            </mat-form-field>
          </div>
          <div class="col s12 m12 l2">
            <button
              type="button"
              mat-mini-fab
              color="primary"
              matTooltip="Copiar Presupuesto"
              (click)="copiarPresupuesto(numopc)"
            >
              <mat-icon>description</mat-icon>
            </button>
          </div>
        </div>
      </mat-card>
      <br>
      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l6">
            <mat-form-field>
              <mat-label>{{'apps.crm.operaciones.presupuesto.form.opportunity-ticket' | translate}}</mat-label>
              <input
                type="text"
                [(ngModel)]="presupuesto.idtkt"
                matInput
                [placeholder]="'apps.crm.operaciones.presupuesto.form.opportunity-ticket' | translate"
                name="idtkt"
                required
                readonly
              />
            </mat-form-field>
          </div>

          <div class="col s12 m12 l6">
            <mat-form-field>
              <mat-label>{{'apps.crm.operaciones.presupuesto.form.num-tracing' | translate}}</mat-label>
              <input
                type="text"
                [(ngModel)]="presupuesto.idset"
                matInput
                [placeholder]="'apps.crm.operaciones.presupuesto.form.num-tracing' | translate"
                name="idset"
                required
                readonly
              />
            </mat-form-field>
          </div>
        </div>
      </mat-card>

    </div>
  </div>
  <br>
  <div class="row">
    <div class="col s12">
      <div class="pav-table-header-container">
        <div class="pav-table-header-icon">
        </div>
        <div class="pav-table-header-title">
          {{'apps.crm.operaciones.presupuesto.table.title' | translate}}
        </div>
        <div class="pav-table-header-add">
          <div class="isMobile">
            <div class="pav-button-icon-add" (click)="agregarItem()">
              <mat-icon svgIcon="add"></mat-icon>
            </div>
          </div>
          <div class="isWeb">
            <button mat-flat-button class="pav-button-add-item" type="button" (click)="agregarItem()">{{'general.button.add' | translate}} ítem</button>
          </div>
        </div>
      </div>
      <div class="mat-elevation-z0 overflow-x">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.presupuesto.table.header.actions' | translate}}</th>
            <td
              mat-cell
              *matCellDef="let row"
              [style.color]="row.color"
              data-label="Acciones"
            >
              <div class="pav-btns-container">
                <div *ngIf="!row.isEditing" (click)="editarItem(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                  <mat-icon svgIcon="edit-white"></mat-icon>
                </div>
                <div *ngIf="row.isEditing" (click)="confirmarItem(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                  <mat-icon svgIcon="confirm"></mat-icon>
                </div>
                <div class="pav-btn-circle-delete" (click)="quitarItem(row)" matTooltip="Eliminar">
                  <mat-icon svgIcon="delete"></mat-icon>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="item">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.presupuesto.table.header.item' | translate}}</th>
            <td mat-cell *matCellDef="let row" data-label="Item">
              {{ row.coropd }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cod_grupo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'apps.crm.operaciones.presupuesto.table.header.cod-grupo' | translate}}
            </th>
            <td mat-cell *matCellDef="let row; let i = index;" data-label="Cod. Grupo">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="pav-field-presupuesto-select mff-cod-grupo">
                  <mat-label></mat-label>
                  <mat-select (selectionChange)="seleccionarGrupo(row)" required [(ngModel)]="row.codopg" [name]="'codopg'+i">
                    <mat-option *ngFor="let grupo of grupos; trackBy: trackByGrupo" [value]="grupo.opg_codopg">{{grupo.opg_descri}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{row.descriopg}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.presupuesto.table.header.code' | translate}}</th>
            <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
              <ng-container *ngIf="row.isEditing">

                <select-search
                  [label]="'apps.crm.operaciones.seguimiento.form.contact' | translate"
                  [placeholder]="'Ej. contacto'"
                  [name]="'prd_codprd' + i"
                  [value]="'prd_codprd'"
                  [description]="'DESC_ESPA'"
                  [data]="productos"
                  (cambioSelect)="row.codprd = $event; seleccionarProducto(row)"
                  [model]="row.codprd">
                </select-search>

              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{row.descri}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="texto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'apps.crm.operaciones.presupuesto.table.header.text' | translate}}</th>
            <td mat-cell *matCellDef="let row; let i = index;" data-label="Texto">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="pav-field-presupuesto-select mff-texto">
                  <mat-label></mat-label>
                  <input required type="text" matInput placeholder="Texto" [(ngModel)]="row.descriopd" min="0"
                  [name]="'texto' + i.toString()">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{row.descriopd}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="cantidad_total">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'apps.crm.operaciones.presupuesto.table.header.amount' | translate}}
            </th>
            <td class="pav-format-number" mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="pav-field-presupuesto-input-number mff-cantidad-total">
                  <mat-label></mat-label>
                  <input class="pav-input-align-right" required type="number" (keyup)="cambiarCantidad(row)" matInput placeholder="Cantidad" [(ngModel)]="row.canopd" (blur)="blurCantidadProducto(row)" min="0"
                  [name]="'cantidadTotal' + i.toString()">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{ convertirAFloat(row.canopd).toFixed(2) }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="costo_unit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'apps.crm.operaciones.presupuesto.table.header.unit-cost' | translate}}
            </th>
            <td class="pav-format-number" mat-cell *matCellDef="let row; let i = index;" data-label="Costo Unit.">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="pav-field-presupuesto-input-number mff-costo-unit">
                  <mat-label></mat-label>
                  <input class="pav-input-align-right" required type="number" (keyup)="cambiarCosto(row)" matInput placeholder="Costo Unit." [(ngModel)]="row.cosuni" (blur)="blurCostoUnitarioProducto(row)" min="0"
                  [name]="'costoUnitUS' + i.toString()">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{convertirAFloat(row.cosuni).toFixed(2)}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="total_nac">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'apps.crm.operaciones.presupuesto.table.header.total-cost' | translate}}
            </th>
            <td class="pav-format-number" mat-cell *matCellDef="let row; let i = index;" data-label="Costo Total">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="pav-field-presupuesto-input-number mff-total-nac">
                  <mat-label></mat-label>
                  <input class="pav-input-align-right" required type="number" matInput [placeholder]="'apps.crm.operaciones.presupuesto.table.header.total-cost' | translate" [value]="row.totnac ? row.totnac.toFixed(2): (0).toFixed(2)" min="0"
                  [name]="'USTotalNac' + i.toString()" disabled>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{convertirAFloat(row.totnac).toFixed(2)}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="incidencia_costo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'apps.crm.operaciones.presupuesto.table.header.cost-incidence' | translate}}
            </th>
            <td class="pav-format-number" mat-cell *matCellDef="let row; let i = index;" data-label="Incidencia Costo %">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="pav-field-presupuesto-input-number mff-incidencia-costo">
                  <mat-label></mat-label>
                  <input class="pav-input-align-right" (keyup)="cambiarPorIncidencia(row)" required type="number" matInput placeholder="Incidencia Costo %" [(ngModel)]="row.porinc" min="0"
                  [name]="'incidenciaCosto' + i.toString()">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{convertirAFloat(row.porinc).toFixed(2)}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="margen_ganancia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'apps.crm.operaciones.presupuesto.table.header.profit-margin' | translate}}
            </th>
            <td class="pav-format-number" mat-cell *matCellDef="let row; let i = index;" data-label="Margen Ganancia %">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="pav-field-presupuesto-input-number mff-margen-ganancia">
                  <mat-label></mat-label>
                  <input class="pav-input-align-right" required type="number" matInput placeholder="Margen Ganancia %" [(ngModel)]="row.margen" min="0"
                  [name]="'margenGanancia' + i.toString()">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{convertirAFloat(row.margen).toFixed(2)}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="imp_margen_us">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'apps.crm.operaciones.presupuesto.table.header.margin-amount' | translate}}
            </th>
            <td class="pav-format-number" mat-cell *matCellDef="let row; let i = index;" data-label="Imp. Margen">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="pav-field-presupuesto-input-number mff-margen-us">
                  <mat-label></mat-label>
                  <input class="pav-input-align-right" required type="number" matInput [placeholder]="'apps.crm.operaciones.presupuesto.table.header.margin-amount' | translate" [value]="row.impmar ? row.impmar.toFixed(2): (0).toFixed(2)" min="0" disabled
                  [name]="'impMargenUS' + i.toString()">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{convertirAFloat(row.impmar).toFixed(2)}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="dolar_unit_oferta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'apps.crm.operaciones.presupuesto.table.header.dolar-unit-offer' | translate}}
            </th>
            <td class="pav-format-number" mat-cell *matCellDef="let row; let i = index;" data-label="US$ Unit. Oferta">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="pav-field-presupuesto-input-number mff-dolar-unit-oferta">
                  <mat-label></mat-label>
                  <input class="pav-input-align-right" required type="number" matInput placeholder="US$ Unit. Oferta" [(ngModel)]="row.cosofd" min="0"
                  [name]="'USUnitOferta' + i.toString()">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{convertirAFloat(row.cosofd).toFixed(2)}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="soles_unit_oferta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'apps.crm.operaciones.presupuesto.table.header.soles-unit-offer' | translate}}
            </th>
            <td class="pav-format-number" mat-cell *matCellDef="let row; let i = index;" data-label="/. Unit. Oferta">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="pav-field-presupuesto-input-number mff-soles-unit-oferta">
                  <mat-label></mat-label>
                  <input class="pav-input-align-right" required type="number" matInput placeholder="S/. Unit. Oferta" [(ngModel)]="row.cosofs" min="0"
                  [name]="'SUnitOferta' + i.toString()">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{convertirAFloat(row.cosofs).toFixed(2)}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="total_oferta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'apps.crm.operaciones.presupuesto.table.header.soles-total-offer' | translate}}
            </th>
            <td class="pav-format-number" mat-cell *matCellDef="let row; let i = index;" data-label="S/. Total Oferta">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="pav-field-presupuesto-input-number mff-total-oferta">
                  <mat-label></mat-label>
                  <input class="pav-input-align-right" required type="number" matInput placeholder="S/. Total Oferta" [(ngModel)]="row.totofs" min="0"
                  [name]="'STotalOferta' + i.toString()">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{convertirAFloat(row.totofs).toFixed(2)}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="total_oferta_dolar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'apps.crm.operaciones.presupuesto.table.header.dolar-total-offer' | translate}}
            </th>
            <td class="pav-format-number" mat-cell *matCellDef="let row; let i = index;" data-label="US $ Total Oferta">
              <ng-container *ngIf="row.isEditing">
                <mat-form-field class="pav-field-presupuesto-input-number mff-total-oferta-dolar">
                  <mat-label></mat-label>
                  <input class="pav-input-align-right" required type="number" matInput placeholder="US $ Total Oferta" [(ngModel)]="row.totofd" min="0"
                  [name]="'USTotalOferta' + i.toString()">
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!row.isEditing">
                {{convertirAFloat(row.totofd).toFixed(2)}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="sec">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'apps.crm.operaciones.presupuesto.table.header.sec' | translate}}
            </th>
            <td mat-cell *matCellDef="let row; let i = index;" data-label="Sec.">
              <ng-container>
                {{row.secopd}}
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="16">{{'general.table.data-not-found' | translate}}</td>
          </tr>
        </table>
      </div>
      <mat-paginator
        showFirstLastButtons
        [pageSizeOptions]="[5, 10, 25, 100]"
      ></mat-paginator>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col s12 m12 l2">
      <div class="pav-card-header blue-dark">
        <div class="pav-block"></div>
        <div class="pav-card-header-title title-card">
          {{'apps.crm.operaciones.presupuesto.box.header.assets' | translate}}
        </div>
      </div>
      <mat-card class="mat-elevation-z0">
        <mat-form-field>
          <mat-label>{{presupuesto.codtmo == 'SO'? 'S/. ': 'US$ '}}Total Nac.</mat-label>
          <input
            class="pav-input-align-right"
            [value]="totalNacBien.toFixed(2)"
            type="text"
            matInput
            placeholder="US$/Total Nac."
            name="us_total_nac_bien"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Margen Ganancia</mat-label>
          <input
            [value]="costoBien.toFixed(2)"
            class="pav-input-align-right"
            type="text"
            matInput
            placeholder="Margen ganancia"
            name="incidencia_costo_bien"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{presupuesto.codtmo == 'SO'? 'S/. ': 'US$ '}}Total Ofertada</mat-label>
          <input
            class="pav-input-align-right"
            [value]="totalOfeBien.toFixed(2)"
            type="text"
            matInput
            placeholder="US$/Total Ofertada"
            name="total_ofertada_bien"
            disabled
          />
        </mat-form-field>
      </mat-card>
    </div>
    <div class="col s12 m12 l2">
      <div class="pav-card-header green">
        <div class="pav-block"></div>
        <div class="pav-card-header-title title-card">
          {{'apps.crm.operaciones.presupuesto.box.header.equipment' | translate}}
        </div>
      </div>
      <mat-card class="mat-elevation-z0">
        <mat-form-field>
          <mat-label>{{presupuesto.codtmo == 'SO'? 'S/. ': 'US$ '}}Total Nac.</mat-label>
          <input
            class="pav-input-align-right"
            [value]="totalNacEquipo.toFixed(2)"
            type="text"
            matInput
            placeholder="US$/Total Nac."
            name="us_total_nac_equipo"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Margen ganancia</mat-label>
          <input
            class="pav-input-align-right"
            [value]="costoEquipo.toFixed(2)"
            type="text"
            matInput
            placeholder="Margen ganancia"
            name="incidencia_costo_equipo"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{presupuesto.codtmo == 'SO'? 'S/. ': 'US$ '}}Total Ofertada</mat-label>
          <input
            class="pav-input-align-right"
            [value]="totalOfeEquipo.toFixed(2)"
            type="text"
            matInput
            placeholder="US$/Total Ofertada"
            name="total_ofertada_equipo"
            disabled
          />
        </mat-form-field>
      </mat-card>
    </div>
    <div class="col s12 m12 l2">
      <div class="pav-card-header blue">
        <div class="pav-block"></div>
        <div class="pav-card-header-title title-card">
          {{'apps.crm.operaciones.presupuesto.box.header.service' | translate}}
        </div>
      </div>
      <mat-card class="mat-elevation-z0">

        <mat-form-field>
          <mat-label>{{presupuesto.codtmo == 'SO'? 'S/. ': 'US$ '}}Total Nac.</mat-label>
          <input
            class="pav-input-align-right"
            [value]="totalNacServicio.toFixed(2)"
            type="text"
            matInput
            placeholder="US$/Total Nac."
            name="us_total_nac_servicio"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Margen ganancia</mat-label>
          <input
            class="pav-input-align-right"
            [value]="costoServicio.toFixed(2)"
            type="text"
            matInput
            placeholder="Margen ganancia"
            name="incidencia_costo_servicio"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{presupuesto.codtmo == 'SO'? 'S/. ': 'US$ '}}Total Ofertada</mat-label>
          <input
            class="pav-input-align-right"
            [value]="totalOfeServicio.toFixed(2)"
            type="text"
            matInput
            placeholder="US$/Total Ofertada"
            name="total_ofertada_servicio"
            disabled
          />
        </mat-form-field>
      </mat-card>
    </div>
    <div class="col s12 m12 l2">
      <div class="pav-card-header red">
        <div class="pav-block"></div>
        <div class="pav-card-header-title title-card">
          {{'apps.crm.operaciones.presupuesto.box.header.estimated-profit-margin' | translate}}
        </div>
      </div>
      <mat-card class="mat-elevation-z0">
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.presupuesto.box.form.amount' | translate}}</mat-label>
          <input
            class="pav-input-align-right"
            [value]="margenGanancia.toFixed(2)"
            type="text"
            matInput
            [placeholder]="'apps.crm.operaciones.presupuesto.box.form.amount' | translate"
            name="monto"
            disabled
          />
        </mat-form-field>
      </mat-card>
    </div>
    <div class="col s12 m12 l2">
      <div class="pav-card-header yellow">
        <div class="pav-block"></div>
        <div class="pav-card-header-title title-card">
          {{'apps.crm.operaciones.presupuesto.box.header.other' | translate}}
        </div>
      </div>
      <mat-card class="mat-elevation-z0">
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.presupuesto.box.form.indicator' | translate}}</mat-label>
          <input
            class="pav-input-align-right"
            type="number"
            matInput
            [placeholder]="'apps.crm.operaciones.presupuesto.box.form.indicator' | translate"
            name="indicador_kwp"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.presupuesto.form.admin-cost' | translate}}</mat-label>
          <input
            appTwoDigitDecimal
            class="pav-input-align-right"
            type="text"
            [(ngModel)]="presupuesto.maradm"
            matInput
            [placeholder]="'apps.crm.operaciones.presupuesto.form.admin-cost' | translate"
            name="costo_admin"
            required
            min="0"
            (blur)="blurCostoAdmin()"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.presupuesto.form.service-cost' | translate}}</mat-label>
          <input
            appTwoDigitDecimal
            class="pav-input-align-right"
            type="text"
            [(ngModel)]="presupuesto.marser"
            matInput
            [placeholder]="'apps.crm.operaciones.presupuesto.form.service-cost' | translate"
            name="costo_servicio"
            required
            min="0"
            (blur)="blurCostoServicio()"
          />
        </mat-form-field>

      </mat-card>
    </div>
    <div class="col s12 m12 l2">
      <div class="pav-card-header blue">
        <div class="pav-block"></div>
        <div class="pav-card-header-title title-card">
          {{'apps.crm.operaciones.presupuesto.box.header.other' | translate}}
        </div>
      </div>
      <mat-card class="mat-elevation-z0">
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.presupuesto.box.form.subtotal' | translate}}</mat-label>
          <input
            class="pav-input-align-right"
            [value]="presupuesto.subtot ? presupuesto.subtot.toFixed(2): (0).toFixed(2)"
            type="text"
            matInput
            [placeholder]="'apps.crm.operaciones.presupuesto.box.form.subtotal' | translate"
            name="sub_total"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.presupuesto.box.form.administrative-cost' | translate}}</mat-label>
          <input
            class="pav-input-align-right"
            [value]="presupuesto.impadm? presupuesto.impadm.toFixed(2): (0).toFixed(2)"
            type="text"
            matInput
            [placeholder]="'apps.crm.operaciones.presupuesto.box.form.administrative-cost' | translate"
            name="costo_administrativo"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.presupuesto.box.form.service-cost' | translate}}</mat-label>
          <input
            class="pav-input-align-right"
            [value]="presupuesto.impser? presupuesto.impser.toFixed(2): (0).toFixed(2)"
            type="text"
            matInput
            [placeholder]="'apps.crm.operaciones.presupuesto.box.form.service-cost' | translate"
            name="costo_servicio_impser"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.presupuesto.box.form.total-budget-value' | translate}}</mat-label>
          <input
            class="pav-input-align-right"
            [value]="presupuesto.valvta? presupuesto.valvta.toFixed(2): (0).toFixed(2)"
            type="text"
            matInput
            [placeholder]="'apps.crm.operaciones.presupuesto.box.form.total-budget-value' | translate"
            name="total_val_presupuesto"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.presupuesto.box.form.total-igv-budget' | translate}}</mat-label>
          <input
            class="pav-input-align-right"
            [value]="presupuesto.impigv? presupuesto.impigv.toFixed(2): (0).toFixed(2)"
            type="text"
            matInput
            [placeholder]="'apps.crm.operaciones.presupuesto.box.form.total-igv-budget' | translate"
            name="total_presupuesto_igv"
            disabled
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.presupuesto.box.form.total-budget' | translate}}</mat-label>
          <input
            class="pav-input-align-right"
            [value]="presupuesto.imptot? presupuesto.imptot.toFixed(2): (0).toFixed(2)"
            type="text"
            matInput
            [placeholder]="'apps.crm.operaciones.presupuesto.box.form.total-budget' | translate"
            name="total_presupuesto"
            disabled
          />
        </mat-form-field>
      </mat-card>
    </div>
  </div>
  <br>
  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.presupuesto.form.situation' | translate}}</mat-label>
          <input
            type="text"
            matInput
            [placeholder]="'apps.crm.operaciones.presupuesto.form.situation' | translate"
            name="situacion"
            disabled
          />
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.presupuesto.form.approval-date' | translate}}</mat-label>
          <input
            type="text"
            matInput
            [placeholder]="'apps.crm.operaciones.presupuesto.form.approval-date' | translate"
            name="fecha_aprob"
            disabled
          />
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>{{'apps.crm.operaciones.presupuesto.form.approved-by' | translate}}</mat-label>
          <input
            type="text"
            matInput
            [placeholder]="'apps.crm.operaciones.presupuesto.form.approved-by' | translate"
            name="aprobado_por"
            disabled
          />
        </mat-form-field>
      </div>
    </div>
  </mat-card>

  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" urlBack="/modulo-crm/presupuesto">
    <div id="botonesAdicionales">

    </div>
  </pav-form-actions>
</form>
