<app-pavso-title-section [title]="'Consulta de movimientos contables'" [module]="'Contabilidad'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s4">
        <mat-form-field>
          <mat-label>Comprobantes</mat-label>
          <mat-select [(ngModel)]="comprobante" name="comprobante" (selectionChange)="seleccionarComprobante($event.value)" [disabled]="loaderData">
            <mat-option value="estandar">
              ESTANDAR
            </mat-option>
            <mat-option value="bancos">
              BANCOS
            </mat-option>
            <mat-option value="compras">
              COMPRAS
            </mat-option>
            <mat-option value="ventas">
              VENTAS
            </mat-option>
            <mat-option value="honorarios">
              HONORARIOS
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s4">
        <mat-form-field>
          <mat-label>Mes</mat-label>
          <mat-select (selectionChange)="seleccionarMes($event.value)" [(ngModel)]="mes" name="mes" [disabled]="loaderData">
            <mat-option value="">Ninguno</mat-option>
            <mat-option *ngFor="let mes of meses" [value]="mes.id">
              {{mes.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s3">
        <mat-form-field>
          <mat-label>Auxiliar</mat-label>
          <mat-select [(ngModel)]="auxiliar" name="auxiliar" (selectionChange)="seleccionarAuxiliar($event.value)" [disabled]="loaderData">
            <mat-option value="">Ninguno</mat-option>
            <mat-option *ngFor="let auxiliar of auxiliares" [value]="auxiliar.CODIGO">
              {{auxiliar.NOMBRE}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s3">
        <mat-form-field>
          <mat-label>Tipo Doc.</mat-label>
          <mat-select [(ngModel)]="tipoDoc" name="tipoDoc" (selectionChange)="seleccionarTipoDocumento($event.value)" [disabled]="loaderData">
            <mat-option value="">Ninguno</mat-option>
            <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.TDO_CODTDO">
              {{tipo.TDO_DESCOR}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s3">
        <mat-form-field>
          <mat-label>Nro. Documento</mat-label>
          <input matInput placeholder="Nro. Documento" [disabled]="loaderData">
        </mat-form-field>
      </div>
      <div class="col s3">
        <mat-form-field>
          <mat-label>Cuenta</mat-label>
          <mat-select (selectionChange)="seleccionarCuenta($event.value)" [(ngModel)]="cuenta" name="cuenta">
            <mat-option *ngFor="let cuentaContable of cuentasContable" [value]="cuentaContable.ccn_codccn">
              {{cuentaContable.ccn_descri}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s3">
        <mat-form-field>
          <mat-label>Subdiario</mat-label>
          <mat-select [(ngModel)]="subdiario" name="subdiario" (selectionChange)="seleccionarSubdiario($event.value)" [disabled]="loaderData">
            <mat-option *ngFor="let subdiario of subdiarios" [value]="subdiario.sco_codsco">
              {{subdiario.sco_codsco}} - {{subdiario.sco_descri}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <br>
  <mat-tab-group [selectedIndex]="selectedIndex">
    <mat-tab label="Standar" [disabled]="comprobante != 'estandar'" labelClass="mat-tab-label-0-0">
      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSource" matSort >

          <ng-container matColumnDef="ldi_messco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año - Mes - SubDiario - Correlativo </th>
            <td mat-cell *matCellDef="let row" data-label="Año - Mes - SubDiario - Correlativo"> {{row.ldi_messco}} </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Comprobante </th>
            <td mat-cell *matCellDef="let row" data-label="Comprobante"> {{row.ldi_numdoc}} </td>
          </ng-container>

          <ng-container matColumnDef="ccn_codccn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
              <td mat-cell *matCellDef="let row" data-label="Cuenta"> {{row.ccn_codccn}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
            <td mat-cell *matCellDef="let row" data-label="Auxiliar"> {{row.cli_nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="ccs_codccs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Centro Costo </th>
            <td mat-cell *matCellDef="let row" data-label="Centro Costo"> {{row.ccs_codccs}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecreg_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Registro </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha de Registro"> {{row.cco_fecreg_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecemi_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de emisión </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha de emisión"> {{row.cco_fecemi_103}} </td>
          </ng-container>

          <ng-container matColumnDef="ldi_gloldi">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
            <td mat-cell *matCellDef="let row" data-label="Glosa"> {{row.ldi_gloldi}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'highlight': selectedRowIndex == row.ano_codano + row.ano_codano + row.sco_codsco + row.ldi_corldi + row.ldi_numdoc}" (click)="selectedRowIndex = row.ldi_messco + row.ldi_numdoc"></tr>

          <tr class="mat-row" *matNoDataRow>

              <td class="mat-cell" colspan="8" *ngIf="!loaderStandar">No se encontraron registros</td>

          </tr>

        </table>
        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>

    </mat-tab>
    <mat-tab label="Bancos" [disabled]="comprobante != 'bancos'" labelClass="mat-tab-label-0-1">
      <div class="row">
        <div class="col s4">
          <mat-form-field>
            <mat-label>Bancos</mat-label>
            <mat-select (selectionChange)="seleccionarBanco($event.value)">
              <mat-option *ngFor="let banco of bancos" [value]="banco.codigo_banco">
                {{banco.codigo_banco}} - {{banco.descri_banco}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s4">
          <mat-form-field>
            <mat-label>Cuentas Bancarias</mat-label>
            <mat-select [(ngModel)]="cuentaBancaria" name="cuentaBancaria" (selectionChange)="seleccionarCuentaBancaria($event.value)">
              <mat-option value="">Ninguno</mat-option>
              <mat-option *ngFor="let cuenta of cuentasBancaria" [value]="cuenta.codigo_ctabanco">
                {{cuenta.descri_ctabanco}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSourceBanco" matSort >

          <ng-container matColumnDef="ldi_messco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año - Mes - SubDiario - Correlativo </th>
            <td mat-cell *matCellDef="let row" data-label="Año - Mes - SubDiario - Correlativo"> {{row.ldi_messco}} </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cheque </th>
            <td mat-cell *matCellDef="let row" data-label="Cheque"> {{row.ldi_numdoc}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecemi_103">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha"> {{row.cco_fecemi_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
            <td mat-cell *matCellDef="let row" data-label="Auxiliar"> {{row.cli_nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="tmo_codtmo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
            <td mat-cell *matCellDef="let row" data-label="Moneda"> {{row.tmo_codtmo}} </td>
          </ng-container>

          <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
            <td mat-cell *matCellDef="let row" data-label="Monto"> {{row.importe}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsBanco"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsBanco;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="6" *ngIf="!loaderBancos">No se encontraron registros</td>

          </tr>

        </table>

        <mat-paginator #pBanco [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-tab>
    <mat-tab label="Compras" [disabled]="comprobante != 'compras'" labelClass="mat-tab-label-0-2">
      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSourceCompras" matSort >

          <ng-container matColumnDef="ldi_messco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año - Mes - SubDiario - Correlativo </th>
            <td mat-cell *matCellDef="let row" data-label="Año - Mes - SubDiario - Correlativo"> {{row.ldi_messco}} </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Comprobante </th>
            <td mat-cell *matCellDef="let row" data-label="Comprobante"> {{row.ldi_numdoc}} </td>
          </ng-container>

          <ng-container matColumnDef="ccn_codccn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
              <td mat-cell *matCellDef="let row" data-label="Cuenta"> {{row.ccn_codccn}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecreg_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Registro </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha Registro"> {{row.cco_fecreg_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecemi_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha Emisión"> {{row.cco_fecemi_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor </th>
            <td mat-cell *matCellDef="let row" data-label="Proveedor"> {{row.cli_nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="ccs_codccs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Centro de costo </th>
            <td mat-cell *matCellDef="let row" data-label="Centro de costo"> {{row.ccs_codccs}} </td>
          </ng-container>

          <ng-container matColumnDef="tmo_codtmo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
            <td mat-cell *matCellDef="let row" data-label="Moneda"> {{row.tmo_codtmo}} </td>
          </ng-container>

          <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
            <td mat-cell *matCellDef="let row" data-label="Moneda"> {{row.importe}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsCompras"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsCompras;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="8" *ngIf="!loaderCompras">No se encontraron registros</td>

          </tr>

        </table>

        <mat-paginator #pCompras [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-tab>
    <mat-tab label="Ventas" [disabled]="comprobante != 'ventas'" labelClass="mat-tab-label-0-3">
      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSourceVentas" matSort >

          <ng-container matColumnDef="ldi_messco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año - Mes - SubDiario - Correlativo </th>
            <td mat-cell *matCellDef="let row" data-label="Año - Mes - SubDiario - Correlativo"> {{row.ldi_messco}} </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Comprobante </th>
            <td mat-cell *matCellDef="let row" data-label="Comprobante"> {{row.ldi_numdoc}} </td>
          </ng-container>

          <ng-container matColumnDef="ccn_codccn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
              <td mat-cell *matCellDef="let row" data-label="Cuenta"> {{row.ccn_codccn}} </td>
          </ng-container>

          <ng-container matColumnDef="ccs_codccs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Centro Costo </th>
            <td mat-cell *matCellDef="let row" data-label="Centro Costo"> {{row.ccs_codccs}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecreg_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Registro </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha de Registro"> {{row.cco_fecreg_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecemi_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Emisión </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha de Emisión"> {{row.cco_fecemi_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.cli_nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
            <td mat-cell *matCellDef="let row" data-label="Documento"> {{row.ldi_numdoc}} </td>
          </ng-container>

          <ng-container matColumnDef="tmo_codtmo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
            <td mat-cell *matCellDef="let row" data-label="Moneda"> {{row.tmo_codtmo}} </td>
          </ng-container>

          <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
            <td mat-cell *matCellDef="let row" data-label="Monto"> {{row.importe}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsVentas"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsVentas;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="8" *ngIf="!loaderVentas">No se encontraron registros</td>

          </tr>

        </table>

        <mat-paginator #pVentas [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-tab>
    <mat-tab label="Honorarios" [disabled]="comprobante != 'honorarios'" labelClass="mat-tab-label-0-4">
      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSourceHonorario" matSort >

          <ng-container matColumnDef="ldi_messco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año - Mes - SubDiario - Correlativo </th>
            <td mat-cell *matCellDef="let row" data-label="Año - Mes - SubDiario - Correlativo"> {{row.ldi_messco}} </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Comprobante </th>
            <td mat-cell *matCellDef="let row" data-label="Comprobante"> {{row.ldi_numdoc}} </td>
          </ng-container>

          <ng-container matColumnDef="ccn_codccn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
              <td mat-cell *matCellDef="let row" data-label="Cuenta"> {{row.ccn_codccn}} </td>
          </ng-container>

          <ng-container matColumnDef="ccs_codccs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Centro Costo </th>
            <td mat-cell *matCellDef="let row" data-label="Centro Costo"> {{row.ccs_codccs}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecreg_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Registro </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha de Registro"> {{row.cco_fecreg_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cco_fecemi_103">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de emisión </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha de emisión"> {{row.cco_fecemi_103}} </td>
          </ng-container>

          <ng-container matColumnDef="cli_nomcli">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Honorario </th>
            <td mat-cell *matCellDef="let row" data-label="Honorario"> {{row.cli_nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="ldi_numdoc2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento </th>
            <td mat-cell *matCellDef="let row" data-label="Documento"> {{row.ldi_numdoc}} </td>
          </ng-container>

          <ng-container matColumnDef="tmo_codtmo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
            <td mat-cell *matCellDef="let row" data-label="Moneda"> {{row.tmo_codtmo}} </td>
          </ng-container>

          <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
            <td mat-cell *matCellDef="let row" data-label="Monto"> {{row.importe}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsHonorario"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsHonorario;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="8" *ngIf="!loaderHonorarios">No se encontraron registros</td>

          </tr>

        </table>

        <mat-paginator #pHonorarios [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </mat-tab>
  </mat-tab-group>

  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</div>
