<app-pavso-title-section [title]="'Tipo de operación'" [module]="'Contabilidad'" [usuario]="tipoOperacion.tga_coduse" [fecha]="tipoOperacion.tga_fecupd" [ngClass]="'pav-form'" [estado]="tipoOperacion.tga_indsta"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" [(ngModel)]="tipoOperacion.tga_codtga" matInput placeholder="Código" name="tga_codtga"
              required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" [(ngModel)]="tipoOperacion.tga_descri" matInput placeholder="Descripción"
              name="tga_descri" required>
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Ctas. B&S</mat-label>
            <mat-select [(ngModel)]="tipoOperacion.T30_CODT30" required name="T30_CODT30">
              <mat-option value="1">
                ctas 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cta. Cta Cte MN</mat-label>
            <input type="text" [(ngModel)]="tipoOperacion.ccn_codmna" matInput placeholder="Cta. Cta Cte MN"
              name="ccn_codmna" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cta. Cta Cte ME</mat-label>
            <input type="text" [(ngModel)]="tipoOperacion.ccn_codmex" matInput placeholder="Cta. Cta Cte ME"
              name="ccn_codmex" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Cta. Imp. MN</mat-label>
            <input type="text" [(ngModel)]="tipoOperacion.ccn_impmna" matInput placeholder="Cta. Imp. MN"
              name="ccn_impmna" required>
          </mat-form-field>
        </div>
      </div>

    </mat-card>
    <br>
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Tabla</div>
    </div>
    <div class="mat-elevation-z0 overflow-x">
      <table mat-table [dataSource]="dataSource" matSort >

        <ng-container matColumnDef="aau_iteaau">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> It. </th>
          <td mat-cell *matCellDef="let row" data-label="Código"> {{row.aau_iteaau}} </td>
        </ng-container>

        <ng-container matColumnDef="ccn_codccn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
          <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.ccn_codccn}} </td>
        </ng-container>

        <ng-container matColumnDef="ccn_descri">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ccn_descri}} </td>
        </ng-container>

        <ng-container matColumnDef="ccs_descri">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> C. Costo </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ccs_descri}} </td>
        </ng-container>

        <ng-container matColumnDef="aau_inddha">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> D/H </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.aau_inddha}} </td>
        </ng-container>

        <ng-container matColumnDef="aau_poraau">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> % Distrib. </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.aau_poraau}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="6">No se encontraron registros</td>

        </tr>

      </table>

    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Tipo Doc. Req. Pago</mat-label>
            <mat-select [(ngModel)]="tipoOperacion.tdo_codtdo" required name="tipo">
              <mat-option value="1">
                tipo 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Total %</mat-label>
            <input type="text" matInput placeholder="Total %" name="total" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-checkbox class="example-margin" [(ngModel)]="tipoOperacion.tga_indrqp" name="tga_indrqp">Requerimiento de Pago</mat-checkbox>
          <mat-checkbox class="example-margin" [(ngModel)]="tipoOperacion.tga_indfij" name="tga_indfij">Gasto Fijo</mat-checkbox>
          <mat-checkbox class="example-margin" [(ngModel)]="tipoOperacion.tga_indimp" name="tga_indimp  ">Gastos de Importación</mat-checkbox>
        </div>
      </div>

    </mat-card>
  </div>
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg"
    urlBack="/modulo-contabilidad/tipos-de-operacion">
    <div id="botonesAdicionales">
    </div>

  </pav-form-actions>
</form>
