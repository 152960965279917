<app-pavso-title-section [title]="titlePage" [module]="'Tesorería'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l3">
        <select-search [label]="'Subdiario'" [placeholder]="'Ej.  '" [value]="'sco_codsco'" [name]="'sco_codsco'"
          [description]="'description'" [disabled]="loaderData" [data]="subdiarios"
          (cambioSelect)="filtro.subdiario = $event; " [model]="filtro.subdiario">
        </select-search>
      </div>

      <div class="col s12 m12 l3" *ngIf="subdiario.sco_indban == 1">
        <select-search [label]="'Banco'" [placeholder]="'Ej.  '" [value]="'BAN_CODBAN'" [name]="'BAN_CODBAN'"
          [description]="'BAN_NOMBRE'" [disabled]="loaderData" [data]="bancos" (cambioSelect)="filtro.banco = $event; "
          [model]="filtro.banco">
        </select-search>
      </div>
      <div class="col s12 m12 l3" *ngIf="subdiario.sco_indban == 1">
        <select-search [label]="'Cuenta Bancaria'" [placeholder]="'Ej.  '" [value]="'CBA_CODCBA'" [name]="'CBA_CODCBA'"
          [description]="'cba_deslar'" [disabled]="loaderData" [data]="cuentasBancarias" (cambioSelect)="filtro.cuentaBancaria = $event; "
          [model]="filtro.cuentaBancaria">
        </select-search>
      </div>
      <div class="col s12 m12 l3">
        <select-search [label]="'Auxiliar'" [placeholder]="'Ej.  '" [value]="'cli_codcli'" [name]="'cli_codcli'"
          [description]="'description'" [disabled]="loaderData" [data]="proveedores"
          (cambioSelect)="filtro.proveedor = $event; " [model]="filtro.proveedor">
        </select-search>
      </div>
      <div class="col s12 m12 l3">
        <select-search [label]="'Tipo Doc'" [placeholder]="'Ej.  '" [value]="'TDO_CODTDO'" [name]="'TDO_CODTDO'"
          [description]="'TDO_DESLAR'" [disabled]="loaderData" [data]="tiposDoc"
          (cambioSelect)="filtro.tipoDoc = $event; " [model]="filtro.tipoDoc">
        </select-search>
      </div>
      <div class="col s12 m12 l3">
        <mat-form-field class="example-full-width">
          <mat-label>Número Doc.</mat-label>
          <input matInput placeholder="Ej. F00100000345" [(ngModel)]="filtro.nroDoc" name="nroDoc">
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3" *ngIf="subdiario.sco_indban == 1">
        <mat-form-field class="example-full-width">
          <mat-label>Monto Desde</mat-label>
          <input matInput placeholder="Ej. 1000" [(ngModel)]="filtro.montoDesde" name="montodesde">
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3" *ngIf="subdiario.sco_indban == 1">
        <mat-form-field class="example-full-width">
          <mat-label>Monto Hasta</mat-label>
          <input matInput placeholder="Ej. 1000" [(ngModel)]="filtro.montoHasta" name="montohasta">
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Fecha Desde</mat-label>
          <input matInput [matDatepicker]="pickerDesde" [(ngModel)]="filtro.fechaDesde" name="fechadesde">
          <mat-datepicker-toggle matSuffix [for]="pickerDesde"></mat-datepicker-toggle>
          <mat-datepicker #pickerDesde></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Fecha Hasta</mat-label>
          <input matInput [min]="filtro.fechaDesde" [matDatepicker]="pickerHasta" [(ngModel)]="filtro.fechaHasta" name="fechahasta">
          <mat-datepicker-toggle matSuffix [for]="pickerHasta"></mat-datepicker-toggle>
          <mat-datepicker #pickerHasta></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div align="center">
      <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon" (click)="filtrar()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </mat-card>


  <div class="mat-elevation-z0">

    <table-title-action title="Movimientos" url="/modulo-cajabanco/movimiento-banco" entity="" [params]="[tipoMovimiento, '0', '0', '0', '0']" (clickDescargar)="exporter.exportTable('csv')"></table-title-action>

    <div class="overflow-x">

      <table mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter">

        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
          <td mat-cell *matCellDef="let row" data-label="Subdiario">
            <div class="pav-btns-container">
              <div class="pav-btn-circle-edit" matTooltip="Editar"
                [routerLink]="['/modulo-cajabanco/movimiento-banco', tipoMovimiento, row.ANO_CODANO, row.MES_CODMES, row.SCO_CODSCO, row.LDC_CORLDC]"
                mat-icon-button color="primary">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </div>

            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="SCO_CODSCO">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Subdiario </th>
          <td mat-cell *matCellDef="let row" data-label="Subdiario"> {{row.SCO_CODSCO}} </td>
        </ng-container>

        <ng-container matColumnDef="LDC_CORLDC">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Correlativo </th>
          <td mat-cell *matCellDef="let row" data-label="Subdiario"> {{row.LDC_CORLDC}} </td>
        </ng-container>

        <ng-container matColumnDef="PBA_FECORD">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
          <td mat-cell *matCellDef="let row" data-label="Fecha">
            <div [ngStyle]="{width: '85px'}">
              {{row.PBA_FECORD}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="CBA_CODCBA">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Banco </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Banco">
            {{row.CBA_CODCBA}}
          </td>
        </ng-container>

        <ng-container matColumnDef="TDO_CODTDO">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo. Doc. </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono">
            <div [ngStyle]="{width: '50px', textAlign: 'center', paddingRight: '5px'}">
              {{row.TDO_CODTDO}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="PBA_NUMDOC">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Num. Doc. </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.PBA_NUMDOC}} </td>
        </ng-container>

        <ng-container matColumnDef="TMO_CODTMO">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono">
            <div [ngStyle]="{textAlign: 'center'}">
              {{row.TMO_CODTMO ? 'S/': '$'}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="CLI_CODCLI">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.auxiliar.cli_nomcli}} </td>
        </ng-container>

        <ng-container matColumnDef="PBA_IMPSOL">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto M.N. </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono">
            <div [ngStyle]="{width: '65px', paddingRight: '10px', textAlign: 'right'}">
              {{row.PBA_IMPSOL.toFixed(2)}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="PBA_IMPDOL">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto M.E. </th>
          <td mat-cell *matCellDef="let row" data-label="Teléfono">
            <div [ngStyle]="{width: '65px', paddingRight: '10px', textAlign: 'right'}">
              {{row.PBA_IMPDOL.toFixed(2)}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="PBA_INDSTA">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
          <td mat-cell *matCellDef="let row" data-label="Estado"> {{row.PBA_INDSTA == '1' ? 'Activo': 'Anulado'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="12">No se encontraron registros</td>

        </tr>

      </table>
    </div>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>
