<h1 mat-dialog-title>Buscar</h1>
<div mat-dialog-content>

  <mat-form-field appearance="legacy">
    <mat-label>{{data.label}}</mat-label>
    <input matInput placeholder="Escriba aquí" [(ngModel)]="input" name="input" (keyup)="keyup()">
  </mat-form-field>

  <mat-selection-list #shoes [multiple]="false">
    <mat-list-option [ngStyle]="{fontSize: '12px'}" *ngFor="let item of listado" [value]="item.codigo" (dblclick)="seleccionar(item)">
      {{item.descripcion}} | {{item.codigo}}
    </mat-list-option>
  </mat-selection-list>
  <!-- <mat-list role="list">
    <mat-list-item role="listitem" [ngStyle]="{fontSize: '12px'}" *ngFor="let cliente of clientes">{{cliente.cli_nomcli}}</mat-list-item>
  </mat-list> -->

</div>
<div mat-dialog-actions>
  <button mat-button (click)="cerrar()" type="button">Cerrar</button>
</div>
