<app-pavso-title-section [title]="'Orden de Compra'" [module]="'Compras'" [estado]="ordenCompra.ocm_indsta"
  [estadoAprOrdenCompra]="ordenCompra.ocm_aprcom" [ngClass]="'pav-form'"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">
    <mat-tab-group>
      <mat-tab label="Datos Generales" labelClass="mat-tab-label-0-0">
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
        <div class="row">
          <div class="col s12 m12 l7">
            <mat-card class="mat-elevation-z0">
              <div class="row">
                <div class="col s12 m12 l3">
                  <mat-form-field>
                    <mat-label>Tipo O/C</mat-label>
                    <mat-select [(ngModel)]="ordenCompra.toc_codtoc" name="toc_codtoc" [disabled]="loaderData">
                      <mat-option *ngFor="let orden of ordenesCompra" [value]="orden.toc_codtoc">
                        {{orden.toc_descri}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
                <div class="col s12 m12 l3">
                  <mat-form-field>
                    <mat-label>Código</mat-label>
                    <input matInput placeholder="Código" [(ngModel)]="ordenCompra.ocm_numocm" name="ocm_numocm"
                      readonly>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l3">
                  <mat-form-field>
                    <mat-label>Fecha</mat-label>
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker"
                      [(ngModel)]="ordenCompra.ocm_fecocm" name="ocm_fecocm" (dateChange)="seleccionarFecha()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l2">
                  <mat-form-field>
                    <mat-label>T/C</mat-label>
                    <input type="number" min="0" matInput placeholder="T/C" [(ngModel)]="ordenCompra.ocm_tipcam"
                      name="ocm_tipcam" readonly [ngStyle]="{textAlign: 'right'}">
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l1">
                  <mat-form-field>
                    <mat-label>IGV</mat-label>
                    <input type="number" min="0" matInput placeholder="IGV" [(ngModel)]="ordenCompra.ocm_tasigv"
                      name="ocm_tasigv" readonly [ngStyle]="{textAlign: 'right'}">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col s12 m12 l6">

                  <proveedor-select [label]="'Proveedor'" [placeholder]="'Ej. GARCÍA'" [name]="'cli_codcli'"
                    [value]="'cli_codcli'" [description]="'description_reverse'" [disabled]="loaderData"
                    [data]="proveedores" (cambioSelect)="ordenCompra.cli_codcli = $event; seleccionarProveedor($event)"
                    [model]="ordenCompra.cli_codcli" [canAdd]="true" [canEdit]="true">

                  </proveedor-select>

                </div>

                <div class="col s12 m12 l6">
                  <select-search [label]="'Condi. Pago'" [placeholder]="'Ej. CONTADO'" [name]="'CPA_CODCPA'"
                    [value]="'CPA_CODCPA'" [description]="'CPA_DESABR'" [data]="condiciones"
                    (cambioSelect)="ordenCompra.cpa_codcpa = $event" [model]="ordenCompra.cpa_codcpa">
                  </select-search>

                </div>
                <div class="col s12 m12 l6">
                  <app-currency-select [moneda]="ordenCompra.tmo_codtmo" [disable]="loaderData"
                    (cambioMoneda)="ordenCompra.tmo_codtmo = $event"></app-currency-select>
                </div>
                <div class="col s12 m12 l6">
                  <mat-form-field>
                    <mat-label>Entrega en</mat-label>
                    <mat-select [(ngModel)]="ordenCompra.ldc_codldc" name="ldc_codldc" [disabled]="loaderData">
                      <mat-option *ngFor="let entrega of lugaresEntrega" [value]="entrega.LDC_CODLDC">
                        {{entrega.LDC_DIRLDC}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col s12 m12 l12">
                  <mat-form-field class="example-full-width">
                    <mat-label>Detalle</mat-label>
                    <textarea matInput placeholder="Detalle" [(ngModel)]="ordenCompra.ocm_gloocm" name="ocm_gloocm"
                      [disabled]="loaderData"></textarea>
                  </mat-form-field>
                </div>

                <div class="col s12 m12 l4" *ngIf="configuracionTecnica.s70_almcom">
                  <select-search [label]="'Almacenes'" [placeholder]="'Ej. 0001'" [name]="'ALM_CODALM'"
                    [value]="'ALM_CODALM'" [description]="'description_reverse'" [data]="almacenes"
                    (cambioSelect)="ordenCompra.alm_codalm = $event;" [model]="ordenCompra.alm_codalm"
                    [disabled]="loaderData"></select-search>
                </div>
              </div>

            </mat-card>
          </div>
          <div class="col s12 m12 l5">
            <mat-card class="mat-elevation-z0">
              <div class="row">

                <div class="col s12 m12 l3">
                  <mat-form-field class="example-full-width">
                    <mat-label>Año</mat-label>
                    <input type="number" min="0" matInput placeholder="Año" [(ngModel)]="ordenCompra.ano_codano"
                      name="ano_codano" readonly>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l3">
                  <mat-form-field class="example-full-width">
                    <mat-label>Mes</mat-label>
                    <input type="number" min="0" matInput placeholder="Mes" [(ngModel)]="ordenCompra.mes_codmes"
                      name="mes_codmes" readonly>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l6">
                  <select-search [label]="'Centro de Costo'" [placeholder]="'Ej. ASCENSOR'" [name]="'ccs_codccs'"
                    [value]="'ccs_codccs'" [description]="'description'" [data]="centros"
                    (cambioSelect)="ordenCompra.ccs_codccs = $event" [model]="ordenCompra.ccs_codccs">
                  </select-search>

                </div>
                <div class="col s12 m12 l6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Nro. Cotización</mat-label>
                    <input matInput placeholder="Nro. Cotización" [(ngModel)]="ordenCompra.ocm_numcot" name="ocm_numcot"
                      [disabled]="loaderData">
                  </mat-form-field>
                </div>

                <div class="col s12 m12 l6">
                  <mat-form-field>
                    <mat-label>Fecha de entrega</mat-label>
                    <input matInput [matDatepicker]="picker1" [(ngModel)]="ordenCompra.ocm_fecent" name="ocm_fecent"
                      required [disabled]="loaderData">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

            </mat-card>
            <br>
            <mat-card class="mat-elevation-z0">
              <div class="row">
                <div class="col s12 m12 l6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Flete / Seguro</mat-label>
                    <input type="number" min="0" matInput placeholder="Flete / Seguro" [ngStyle]="{textAlign: 'right'}"
                      [(ngModel)]="ordenCompra.ocm_impfle" name="ocm_impfle" step="any" [disabled]="loaderData">
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Otros Gastos</mat-label>
                    <input type="number" min="0" matInput placeholder="Otros Gastos" [ngStyle]="{textAlign: 'right'}"
                      [(ngModel)]="ordenCompra.ocm_impseg" name="ocm_impseg" step="any" [disabled]="loaderData">
                  </mat-form-field>
                  <mat-form-field class="pav-total-field">
                    <mat-label>Total Cantidad</mat-label>
                    <input type="text" matInput placeholder="Total Cantidad" [ngStyle]="{textAlign: 'right'}"
                      [value]="formatoMonto(ordenCompra.ocm_cantot)" name="ocm_cantot" readonly step="any">
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l6">
                  <mat-form-field class="pav-total-field">
                    <mat-label>Total Val. Vta.</mat-label>
                    <input type="text" matInput placeholder="Total Val. Vta." readonly
                      [ngStyle]="{textAlign: 'right'}" [value]="formatoMonto(ordenCompra.ocm_valvta)" name="ocm_valvta"
                      step="any">
                  </mat-form-field>
                  <mat-form-field class="pav-total-field">
                    <mat-label>Total IGV</mat-label>
                    <input type="text" matInput placeholder="Total IGV" [value]="formatoMonto(ordenCompra.ocm_impigv)"
                      name="ocm_impigv" readonly step="any" [ngStyle]="{textAlign: 'right'}">
                  </mat-form-field>
                  <mat-form-field class="pav-total-field">
                    <mat-label>Total Compra</mat-label>
                    <input type="text" matInput placeholder="Total Compra" [ngStyle]="{textAlign: 'right'}"
                      [value]="formatoMonto(ordenCompra.ocm_imptot)" name="ocm_imptot" step="any" readonly>
                  </mat-form-field>
                </div>
                <br>
                <!-- <div class="col s12 m12 l6">
                  <mat-checkbox class="example-margin" name="ocm_jeflog" [disabled]="loaderData">Dpto.
                    Logística</mat-checkbox>
                  <br>
                </div> -->
                <!-- <br>
                <div class="col s12">
                  <br>
                  <button type="button" mat-flat-button color="primary" [disabled]="loaderData">
                    Extrae excel
                  </button>
                  &nbsp;

                </div> -->
              </div>
            </mat-card>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col s12">

            <div class="pav-filtro-header">
              <div class="pav-filtro-header-icon"></div>
              <div class="pav-filtro-header-title">Detalle</div>
              <div class="pav-separator"></div>
              <div class="pav-table-search">
                <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
              </div>
              &nbsp;
              <div>
                <div class="pav-button-icon-add-multiple" matTooltip="Agregar múltiple" (click)="agregarProductoMultiple()"
                  [ngStyle]="{display: loaderData? 'none': ''}">
                  <mat-icon [ngStyle]="{color: 'white'}">library_add</mat-icon>
                </div>
                <!-- <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                  <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
                </p> -->
              </div>
              &nbsp;
              <div class="pav-button-icon-add" matTooltip="Agregar ítem" (click)="agregarProducto()"
                [ngStyle]="{display: loaderData? 'none': ''}">
                <mat-icon svgIcon="add-circle"></mat-icon>
              </div>
              &nbsp;
              <div class="pav-button-icon-delete" matTooltip="Eliminar ítem" (click)="eliminarProducto()"
                [ngStyle]="{display: loaderData? 'none': ''}">
                <mat-icon svgIcon="remove"></mat-icon>
              </div>

              &nbsp;
              <div>
                <div class="pav-button-icon-insert" matTooltip="Insertar ítem" (click)="insertarProducto()"
                  [ngStyle]="{display: loaderData? 'none': ''}">
                  <mat-icon svgIcon="add-outline"></mat-icon>
                </div>
                <!-- <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                  <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Insertar</span>
                </p> -->
              </div>
            </div>

            <div class="mat-elevation-z0 overflow-x">
              <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="item">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Item">
                    <mat-form-field [ngStyle]="{width: '50px'}" [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input matInput placeholder="Ej. item" [(ngModel)]="row.ocd_corocd" [name]="'ocd_corocd' + i" readonly>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="codigo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Porducto">
                    <div [ngStyle]="{width: '250px'}">
                      <select-search
                        [clase]="row === selected? 'form-field-small form-field-cell select-width-all mat-form-field-row': 'form-field-small form-field-cell select-width-all'"
                        [label]="''" [placeholder]="'Ej. CANASTA'" [name]="'prd_codprd' + i" [value]="'prd_codprd'"
                        [description]="'DESC_ESPA'" [data]="productos"
                        (cambioSelect)="row.prd_codprd = $event; seleccionarProducto($event, row)"
                        [model]="row.prd_codprd">
                      </select-search>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="descripcion">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Descripción">
                    <mat-form-field [class.mat-form-field-row]="row === selected" [ngStyle]="{width: '120px'}"
                      class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input [(ngModel)]="row.prd_codprd" [name]="'description' + i" matInput
                        placeholder="Ej. Descripción">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="um">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> U. M. </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="U. M.">
                    <mat-form-field [ngStyle]="{width: '100px'}" [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input [(ngModel)]="row.eum_codume" [name]="'eum_codume' + i" matInput placeholder="Ej. UND">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="part_number">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Part number </th>
                  <td mat-cell *matCellDef="let row" data-label="Part number">
                    <mat-form-field [ngStyle]="{width: '100px'}" [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input matInput placeholder="Ej. part number">
                    </mat-form-field>
                  </td>
                </ng-container>



                <ng-container matColumnDef="texto">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Texto </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Texto">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input [(ngModel)]="row.ocd_descri" [name]="'ocd_descri' + i" matInput placeholder="Ej. texto">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="sec">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Sec. </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Sec.">
                    <mat-form-field [ngStyle]="{width: '50px'}" [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input [(ngModel)]="row.ocd_secocd" [name]="'ocd_secocd' + i" matInput placeholder="Ej. sec">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="cantidad">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad">
                    <mat-form-field [ngStyle]="{width: '100px', textAlign: 'right'}"
                      [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input (focus)="selectContent($event)" type="number" min="0" [(ngModel)]="row.ocd_canocd" [name]="'ocd_canocd' + i" matInput
                        placeholder="Ej. 10" step="any" (keyup)="calcularTotalesFila(row)">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="suspendida">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Suspendida </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Suspendida">
                    <mat-form-field [ngStyle]="{width: '100px', textAlign: 'right'}"
                      [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input type="number" min="0" [(ngModel)]="row.ocd_cansus" [name]="'ocd_cansus' + i" matInput
                        placeholder="Ej. 10" readonly step="any">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="atendida">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Atentida </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Atentida">
                    <mat-form-field [ngStyle]="{width: '100px', textAlign: 'right'}"
                      [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input type="number" min="0" [(ngModel)]="row.ocd_canate" [name]="'ocd_canate' + i" matInput
                        placeholder="Ej. 10" readonly step="any">
                    </mat-form-field>

                  </td>

                </ng-container>

                <ng-container matColumnDef="pu_prov">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> P. U. Prov. </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="P. U. Prov.">
                    <mat-form-field [ngStyle]="{width: '100px', textAlign: 'right'}"
                      [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input (focus)="selectContent($event)" type="number" min="0" [(ngModel)]="row.ocd_preprv" [name]="'ocd_preprv' + i" matInput
                        placeholder="Ej. 100.00" (keyup)="calcularTotalesFila(row)" step="any">
                    </mat-form-field>
                  </td>

                </ng-container>

                <ng-container matColumnDef="por_prov">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> % Dscto. 1 </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="% Dscto. 1">
                    <mat-form-field [ngStyle]="{width: '100px', textAlign: 'right'}"
                      [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input (focus)="selectContent($event)" type="number" [(ngModel)]="row.ocd_desprv" [name]="'ocd_desprv' + i" matInput
                        placeholder="Ej. description" (keyup)="calcularTotalesFila(row)" step="any">
                    </mat-form-field>
                  </td>
                </ng-container>


                <ng-container matColumnDef="por_prov2">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> % Dscto. 2 </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="% Dscto. 2">
                    <mat-form-field [ngStyle]="{width: '100px', textAlign: 'right'}"
                      [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input (focus)="selectContent($event)" type="number" [(ngModel)]="row.ocd_desprv2" [name]="'ocd_desprv2' + i" matInput
                        placeholder="Ej. description" (keyup)="calcularTotalesFila(row)" step="any">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="precio">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Precio">
                    <mat-form-field [ngStyle]="{width: '100px', textAlign: 'right'}"
                      [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input (focus)="selectContent($event)" type="number" min="0" [(ngModel)]="row.ocd_preuni" [name]="'ocd_preuni' + i" matInput
                        placeholder="Ej. 10.00" (keyup)="calcularTotalesFila(row)" step="any">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="valor_venta">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Venta </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Valor Venta">
                    <mat-form-field [ngStyle]="{width: '100px', textAlign: 'right'}"
                      [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input type="text" [value]="formatoMonto(row.ocd_valvta)" [name]="'ocd_valvta' + i" matInput
                        placeholder="Ej. 10.00" readonly step="any">
                    </mat-form-field>
                  </td>

                </ng-container>

                <ng-container matColumnDef="mot_compra">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Mot. Compra </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Mod. Compra">
                    <mat-form-field [ngStyle]="{width: '150px'}" [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input [(ngModel)]="row.ocd_motcom" [name]="'ocd_motcom' + i" matInput placeholder="Ej. Motivo">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="not">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Centro de costo </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Centro de costo">
                    <select-search
                      [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                      [label]="''" [placeholder]="'Ej. 00001'" [name]="'ccs_codccs' + i" [value]="'ccs_codccs'"
                      [description]="'description'" [data]="centros"
                      (cambioSelect)="row.ccs_codccs = $event; "
                      [model]="row.ccs_codccs">
                    </select-search>
                  </td>

                </ng-container>

                <ng-container matColumnDef="fecreg">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Requerida </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Fecha Requerida">
                    <mat-form-field [ngStyle]="{width: '150px'}" [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input [(ngModel)]="row.ocd_fecreq" [name]="'ocd_fecreq' + i" matInput [matDatepicker]="picker">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                  </td>
                </ng-container>

                <ng-container matColumnDef="peso">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Peso </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Peso">
                    <mat-form-field [ngStyle]="{width: '100px', textAlign: 'right'}"
                      [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input type="number" min="0" [(ngModel)]="row.ocd_pesrcd" [name]="'ocd_pesrcd' + i" matInput
                        placeholder="Ej. 10.00" step="any">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="precio_igv">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Prec. IGV </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Prec. IGV">
                    <mat-form-field [ngStyle]="{width: '100px', textAlign: 'right'}"
                      [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input type="text" min="0" [value]="formatoMonto(row.ocd_pruigv)" [name]="'ocd_pruigv' + i" matInput
                        placeholder="Ej. 10.00" readonly step="any">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="imp_igv">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. IGV </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Imp. IGV">
                    <mat-form-field [ngStyle]="{width: '100px', textAlign: 'right'}"
                      [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input type="text" [value]="formatoMonto(row.ocd_impigv)" [name]="'ocd_impigv' + i"
                        matInput placeholder="Ej. 100.00" readonly step="any">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="imp_total">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Total </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Imp. Total">
                    <mat-form-field [ngStyle]="{ width: '100px', textAlign: 'right'}"
                      [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input type="text" [value]="formatoMonto(row.ocd_imptot)" [name]="'ocd_imptot' + i" matInput
                        placeholder="Ej. 100.00" readonly step="any">
                    </mat-form-field>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="row === selected"
                  (click)="selectRow(row)"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="23">No se encontraron registros</td>
                </tr>

              </table>

            </div>
            <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>
        </div>
        <br>
        <div class="row">

          <div class="col s12 m12 l6">
            <div class="pav-filtro-header">
              <div class="pav-filtro-header-icon"></div>
              <div class="pav-filtro-header-title">Detalle de producto</div>
              <div class="pav-separator"></div>

              <div class="pav-button-icon-add" matTooltip="Agregar detalle producto" (click)="agregarDetalleProducto()"
                [ngStyle]="{display: loaderData? 'none': ''}">
                <mat-icon svgIcon="add-circle"></mat-icon>
              </div>
              &nbsp;
              <div *ngIf="dataSource1.data.length > 0" class="pav-button-icon-delete" matTooltip="Eliminar Detalle"
                (click)="eliminarDetalle()" [ngStyle]="{display: loaderData? 'none': ''}">
                <mat-icon svgIcon="remove"></mat-icon>
              </div>
              <!-- <div>
                    <button mat-flat-button class="pav-button-add" (click)="agregarDetalleProducto()" type="button"
                      color="primary">Agregar</button>
                  </div> -->
            </div>
            <div class="mat-elevation-z0">
              <table mat-table [dataSource]="dataSource1" matSort>

                <ng-container matColumnDef="item">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Código"> {{i + 1}} </td>
                </ng-container>

                <ng-container matColumnDef="descripcion">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                  <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ocf_descri}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4" *ngIf="!loader1">No se encontraron registros</td>
                  <td class="mat-cell" colspan="4" *ngIf="loader1">
                    <mat-spinner diameter="40"></mat-spinner>
                  </td>
                </tr>

              </table>

            </div>
            <mat-paginator #matPagDetalleProducto class="mat-elevation-z0"
              [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

          </div>
          <div class="col s12 m12 l6">

            <div class="pav-filtro-header">
              <div class="pav-filtro-header-icon"></div>
              <div class="pav-filtro-header-title">Cuotas</div>
              <div class="pav-separator"></div>
              <div class="pav-button-icon-add" matTooltip="Agregar cuota" (click)="agregarCuota()"
                [ngStyle]="{display: loaderData? 'none': ''}">
                <mat-icon svgIcon="add-circle"></mat-icon>
              </div>
              &nbsp;
              <div class="pav-button-icon-delete" matTooltip="Eliminar cuota" (click)="eliminarCuota()"
                [ngStyle]="{display: loaderData? 'none': ''}">
                <mat-icon svgIcon="remove"></mat-icon>
              </div>
              <!-- <div>
                    <button mat-flat-button class="pav-button-add" (click)="agregarCuota()" type="button"
                      color="primary">Agregar</button>
                  </div> -->
            </div>

            <div class="mat-elevation-z0 overflow-x">
              <table mat-table [dataSource]="dataSourceCuota" matSort>

                <ng-container matColumnDef="item">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Item"> {{i + 1}} </td>
                </ng-container>

                <ng-container matColumnDef="descripcion">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                  <td mat-cell *matCellDef="let row" data-label="Descripción">

                    <mat-form-field [class.mat-form-field-row]="row === selectedCuota" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input matInput placeholder="Descripción" type="text" [(ngModel)]="row.OCE_DESCRI"
                        name="'OCE_DESCRI' + i" required>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="imp_cuota">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Cuota </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Imp. Cuota">

                    <mat-form-field [class.mat-form-field-row]="row === selectedCuota" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input matInput type="number" placeholder="Ej. 0" [(ngModel)]="row.OCE_IMPCUO" [name]="'OCE_IMPCUO' + i" step="any"
                        required />
                    </mat-form-field>
                  </td>

                </ng-container>

                <ng-container matColumnDef="fecha_pago">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de pago </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Fecha de pago">
                    <mat-form-field [class.mat-form-field-row]="row === selectedCuota" class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input matInput [matDatepicker]="picker" [(ngModel)]="row.OCE_FECPAG" [name]="'OCE_FECPAG' + i"
                        required>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsCuota"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsCuota;"
                  [class.selected-row]="row === selectedCuota" (click)="selectRowCuota(row)"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="5" *ngIf="!loader2">No se encontraron registros</td>
                  <td class="mat-cell" colspan="5" *ngIf="loader2">
                    <mat-spinner diameter="40"></mat-spinner>
                  </td>
                </tr>

              </table>

              <mat-paginator #matPagDetalleCuotas showFirstLastButtons
                [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
          </div>
          <!--
          <div class="col s12 m12 l4">
            <div class="pav-filtro-header">
              <div class="pav-filtro-header-icon"></div>
              <div class="pav-filtro-header-title">Resumen</div>
            </div>

          </div> -->
        </div>
      </mat-tab>
      <mat-tab label="Datos Complementarios" labelClass="mat-tab-label-0-1">
        <div class="pavso_content fade-in-image">
          <mat-card class="mat-elevation-z0">
            <div class="row">
              <div class="col s12 m12 l4">
                <mat-form-field>
                  <mat-label>Fecha Requerida por el Usuario</mat-label>
                  <input matInput [matDatepicker]="pickerFechaRequeridaUsuario" name="pickerFechaRequeridaUsuario">
                  <mat-datepicker-toggle matSuffix [for]="pickerFechaRequeridaUsuario"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFechaRequeridaUsuario></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <mat-form-field>
                  <mat-label>Fecha Ofertada al Cliente</mat-label>
                  <input matInput [matDatepicker]="pickerFechaOfertadaCliente" name="pickerFechaOfertadaCliente">
                  <mat-datepicker-toggle matSuffix [for]="pickerFechaOfertadaCliente"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFechaOfertadaCliente></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <mat-form-field class="example-full-width">
                  <mat-label>Peso Total</mat-label>
                  <input matInput placeholder="ej. 20 Kg." type="number" name="pesoTotal">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <mat-form-field class="example-full-width">
                  <mat-label>Medidas</mat-label>
                  <input matInput placeholder="ej. 20 Kg." type="number" name="medidas">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <mat-form-field>
                  <mat-label>Motivo de Compra</mat-label>
                  <mat-select>
                    <mat-option *ngFor="let food of []" [value]="food.value">
                      {{food.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <select-search [label]="'Cliente'" [placeholder]="'Ej. GARCÍA'" [name]="'cli_codcli'"
                  [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="clientes">
                </select-search>

              </div>
              <div class="col s12 m12 l4">
                <mat-form-field class="example-full-width">
                  <mat-label>OC. del Cliente</mat-label>
                  <input matInput placeholder="ej. 20 Kg." type="number" name="ocCliente">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <mat-form-field class="example-full-width">
                  <mat-label>Valor Mercadería a Transportar</mat-label>
                  <input matInput placeholder="ej. 20.00" type="number" name="valorMercaderiaTransportar">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <mat-form-field>
                  <mat-label>Fecha LLegada</mat-label>
                  <input matInput [matDatepicker]="pickerFechaLlegada" name="pickerFechaLlegada">
                  <mat-datepicker-toggle matSuffix [for]="pickerFechaLlegada"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFechaLlegada></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <mat-form-field class="example-full-width">
                  <mat-label>Punto Partida</mat-label>
                  <textarea matInput placeholder="..." name="puntoPartida"></textarea>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <mat-form-field class="example-full-width">
                  <mat-label>Punto Llegada</mat-label>
                  <textarea matInput placeholder="..." name="puntoLlegada"></textarea>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <mat-form-field class="example-full-width">
                  <mat-label>Estado de la Compra</mat-label>
                  <textarea matInput placeholder="..." name="estadoCompra"></textarea>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
          <br>
          <div class="pav-filtro-header">
            <div class="pav-filtro-header-icon"></div>
            <div class="pav-filtro-header-title">Requerimiento</div>
            <div class="pav-separator"></div>
            <div>
              <button mat-flat-button class="pav-button-add" (click)="agregarRequerimiento()" type="button"
                color="primary">Agregar</button>
            </div>
          </div>
          <div class="mat-elevation-z0">

            <table mat-table [dataSource]="dataSource3" matSort>

              <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row; let i = index;" data-label="Código"> {{i + 1}} </td>
              </ng-container>

              <ng-container matColumnDef="requerimiento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.ocf_descri}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4" *ngIf="!loader1">No se encontraron registros</td>
                <td class="mat-cell" colspan="4" *ngIf="loader1">
                  <mat-spinner diameter="40"></mat-spinner>
                </td>
              </tr>
            </table>

            <mat-paginator #requerimiento showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

          </div>

          <br>
          <br>
          <br>
          <br>
        </div>
      </mat-tab>
      <mat-tab label="Consulta Proveedor" labelClass="mat-tab-label-0-2">
        <mat-card>
          <div class="row">
            <div class="col s12 m12 l6">
              <mat-form-field class="example-full-width">
                <mat-label>Dirección</mat-label>
                <input type="text" matInput placeholder="Dirección" [(ngModel)]="direccion" name="direccion" disabled
                  [disabled]="loaderData">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Contacto</mat-label>
                <input matInput placeholder="Contacto" [(ngModel)]="ordenCompra.ocm_nomcon" name="ocm_nomcon"
                  [disabled]="loaderData">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Teléfono</mat-label>
                <input matInput placeholder="Teléfono" [(ngModel)]="telefono" name="telefono" readonly>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Autorización" labelClass="mat-tab-label-0-3">
        <mat-card>
          <div class="row">
            <div class="col s12 m12 l6">
              <mat-form-field>
                <mat-label>Calcula item</mat-label>
                <mat-select [(ngModel)]="ordenCompra.ocm_indcal" name="ocm_indcal" [disabled]="loaderData">
                  <mat-option *ngFor="let forma of formas" [value]="forma.fci_codfci">
                    {{forma.fci_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Autorización</mat-label>
                <mat-select [(ngModel)]="ordenCompra.ocm_cliaut" name="ocm_cliaut" [disabled]="loaderData">
                  <mat-option *ngFor="let aprobador of aprobadores" [value]="aprobador.cli_codcli">
                    {{aprobador.cli_nomcli}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <select-search [label]="'Solicitado por'" [placeholder]="'Ej. RAMIREZ'" [name]="'CODIGO'"
                [value]="'CODIGO'" [description]="'NOMBRE'" [data]="solicitantes"
                (cambioSelect)="ordenCompra.cli_codref = $event" [model]="ordenCompra.cli_codref">
              </select-search>

            </div>
          </div>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
  </div>
  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
    urlBack="/modulo-compras/ordenes-de-compra" (clickFormato)="generarFormatoOrdenCompra()"
    (clickMasOpciones)="verMasOpciones()" (clickNuevo)="nuevoRegistro()" [loaderFormato]="loaderFormato">
    <div id="botonesAdicionales">
      <button mat-flat-button color="primary" type="button" [disabled]="loaderData">Cerrar O/C</button>
      &nbsp;
      <button type="button" mat-flat-button color="primary" [disabled]="loaderData">
        Desaprobar OC
      </button>
    </div>
  </pav-form-actions>
</form>
