import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable } from '@utils/tables/table';
import { ContabilidadService, GeneralService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';

@Component({
  selector: 'app-consulta-saldos-mensuales',
  templateUrl: './consulta-saldos-mensuales.component.html',
  styleUrls: ['./consulta-saldos-mensuales.component.css']
})
export class ConsultaSaldosMensualesComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['mes_desmes', 'scu_salini', 'scu_debmna', 'scu_habmna', 'saldo_final'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  cuentas: any[] = [];
  monedas: any[] = [];

  year: string;

  cuenta: any;

  totalSaldoInicial: number = 0;
  totalDebe: number = 0;
  totalHaber: number = 0;
  totalSaldoFinal: number = 0;

  period$: Subscription;
  loading$: Subscription;

  loaderData: boolean;
  loaderReg: boolean;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _generalService: GeneralService,
  ) {
    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.seleccionarCuentaContable(this.cuenta);
    })

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.loadMaestro();
    })
  }

  loadMaestro(): void {
    this._contabilidadService.listarPlanDeCuentas().subscribe(
      cuentas => {
        this.cuentas = cuentas;

        this._generalService.obtenerMonedas().subscribe(
          monedas => {
            this.monedas = monedas;
          },
          _ => {
            this._snackBarService.showError("Error al obtener monedas", "OK");
          }
        )
      },
      _ => {
        this._snackBarService.showError("Error al obtener cuentas", "OK")
      }
    )
  }

  seleccionarCuentaContable(cuenta): void {
    this._contabilidadService.verConsultaSaldosMensuales(this.year, cuenta).subscribe(
      consulta => {
        this.dataSource = fillTable(consulta, this.paginator, this.sort);

        this.totalSaldoInicial = 0;
        this.totalDebe = 0;
        this.totalHaber = 0;
        this.totalSaldoFinal = 0;

        this.dataSource.data.forEach(element => {
          this.totalSaldoInicial += element.scu_salini;
          this.totalDebe += element.scu_debmna;
          this.totalHaber += element.scu_habmna;
          this.totalSaldoFinal += element.saldo_final;
        });
      },
      _ => {
        this._snackBarService.showError("Error al obtener consulta de saldos mensuales", "OK");
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$]);
  }

}
