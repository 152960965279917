<app-pavso-title-section [title]="'Registro de compra masivo'" [module]="'Compras'"></app-pavso-title-section>

<mat-tab-group #tabGroupFirst>
  <mat-tab label="1. Descarga masiva" labelClass="mat-tab-label-left">

    <registro-compra-descarga-masiva [loaderData]="loaderDataDescargas"
      (moverCargaMasiva)="moverATabCargaMasiva($event)" (actualizarRecepciones)="actualizarRecepciones()"
      [maestros]="maestrosRegistroCompra" [maestrosDescarga]="maestrosDescarga"></registro-compra-descarga-masiva>

  </mat-tab>

  <mat-tab label="2. Recepción" labelClass="mat-tab-label-center">

    <br>
    <ng-container>
      <div [ngStyle]="{position: isMobile? '': 'absolute', right: '0', zIndex: '1000'}">

        <div [ngStyle]="{display: 'flex', flexDirection: isMobile? 'column': 'row'}">

          <mat-form-field appearance="outline">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. 0002">
          </mat-form-field>
          &nbsp;

          <mat-form-field>
            <mat-label>Estado Recepcion</mat-label>
            <mat-select [(ngModel)]="estadoRecepcion" name="estadoRecepcion"
              (selectionChange)="seleccionarEstadoRecepcion($event.value)">
              <mat-option value="TODOS">
                TODOS
              </mat-option>
              <mat-option value="PENDIENTES">
                PENDIENTES
              </mat-option>
              <mat-option value="REGISTRADOS">
                REGISTRADOS
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

      </div>
    </ng-container>

    <mat-tab-group #tabGroup>
      <mat-tab label="Lista de documentos" labelClass="mat-tab-label-unico">
        <div class="overflow-x">
          <mat-progress-bar mode="indeterminate" *ngIf="loaderListarRecepcion"
            [ngStyle]="{position: 'absolute', marginTop: '55px'}"></mat-progress-bar>

          <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="correlativo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Sec. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Correlativo" class="td-responsive">
                {{ (i + 1) > 9 ? i + 1 : "0" + (i + 1) }}
              </td>
            </ng-container>

            <ng-container matColumnDef="accion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Acción" class="td-responsive"
                (click)=" !loaderListarRecepcion && seleccionarItem(row)">
                <div class="pav-btns-container">
                  <div matTooltip="Editar recepción" (click)=" !loaderListarRecepcion && seleccionarItem(row)"
                    class="pav-btn-circle-edit" [ngStyle]="{cursor: 'pointer'}">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="estado_recepcion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado Recepción </th>
              <td mat-cell *matCellDef="let row" data-label="Estado Recepción" class="td-responsive">

                <div class="pav-tag-green" *ngIf="row.CCO_CODCCO != '' && row.CCO_CODCCO">REGISTRADO</div>
                <div class="pav-tag-blue" *ngIf="row.CCO_CODCCO == '' || (!row.CCO_CODCCO)">PENDIENTE</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="CLI_CODCLI">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> RUC </th>
              <td mat-cell *matCellDef="let row" data-label="RUC" class="td-responsive">
                {{row.CLI_CODCLI}}
              </td>
            </ng-container>

            <ng-container matColumnDef="CLI_NOMCLI">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Proveedor </th>
              <td mat-cell *matCellDef="let row" data-label="Proveedor" class="td-responsive">
                {{row.CLI_NOMCLI}}
              </td>
            </ng-container>

            <ng-container matColumnDef="TDO_CODTDO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
              <td mat-cell *matCellDef="let row" data-label="Tipo Doc." class="td-responsive">
                {{row.TDO_CODTDO}}
              </td>
            </ng-container>

            <ng-container matColumnDef="sad_codsad">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Serie </th>
              <td mat-cell *matCellDef="let row" data-label="Serie" class="td-responsive">
                {{row.sad_codsad}}
              </td>
            </ng-container>

            <ng-container matColumnDef="CCO_NUMDOC">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Número Doc. </th>
              <td mat-cell *matCellDef="let row" data-label="Número Doc." class="td-responsive">
                {{row.CCO_NUMDOC}}
              </td>
            </ng-container>

            <ng-container matColumnDef="CCO_FECEMI">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha Emi." class="td-responsive">
                {{row.CCO_FECEMI | date: 'dd/MM/yyyy': 'UTC'}}
              </td>
            </ng-container>

            <ng-container matColumnDef="CCO_GLOCCO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
              <td mat-cell *matCellDef="let row" data-label="Glosa">
                <div [ngStyle]="{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '150px'}"
                  [matTooltip]="row.CCO_GLOCCO">
                  {{row.CCO_GLOCCO}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="CCO_TASIGV">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tasa </th>
              <td mat-cell *matCellDef="let row" data-label="Tasa">
                {{row.CCO_TASIGV}}
              </td>
            </ng-container>

            <ng-container matColumnDef="TMO_CODTMO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mon. </th>
              <td mat-cell *matCellDef="let row" data-label="Moneda">
                {{row.TMO_CODTMO}}
              </td>
            </ng-container>

            <ng-container matColumnDef="CCO_IMPINA">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Inafecto </th>
              <td mat-cell *matCellDef="let row" data-label="Inafecto">
                <div [ngStyle]="{textAlign: 'right', paddingRight: '12px'}">
                  {{ formatNumberWithComma(row.CCO_IMPINA.toFixed(2)) }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="CCO_IMPAFE">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Afecto </th>
              <td mat-cell *matCellDef="let row" data-label="Afecto">
                <div [ngStyle]="{textAlign: 'right', paddingRight: '12px'}">

                  {{ formatNumberWithComma(row.CCO_IMPAFE.toFixed(2)) }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="CCO_IMPIGV">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> IGV </th>
              <td mat-cell *matCellDef="let row" data-label="IGV">
                <div [ngStyle]="{textAlign: 'right', paddingRight: '12px'}">

                  {{ formatNumberWithComma(row.CCO_IMPIGV.toFixed(2)) }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="CCO_IMPDOC">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total </th>
              <td mat-cell *matCellDef="let row" data-label="Importe Total">
                <div [ngStyle]="{ textAlign: 'right', paddingRight: '12px'}">

                  {{formatNumberWithComma(row.CCO_IMPDOC.toFixed(2))}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="CCO_CODCCO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Cte. </th>
              <td mat-cell *matCellDef="let row" data-label="Importe Total">
                <div [ngStyle]="{ textAlign: 'right', paddingRight: '12px'}">

                  {{row.CCO_CODCCO}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="CCO_CODUSE">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
              <td mat-cell *matCellDef="let row" data-label="Usuario"> {{row.CCO_CODUSE}} </td>
            </ng-container>

            <ng-container matColumnDef="CCO_FECUPD">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha">
                {{row.CCO_FECUPD | date: 'dd/MM/yyyy': 'UTC'}}
              </td>
            </ng-container>

            <ng-container matColumnDef="condicion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Situación Proveedor </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha">
                <div class="pav-tag-green" *ngIf="row.condicion == 'HABIDO'">{{ row.condicion }}</div>
                <div class="pav-tag-red" *ngIf="row.condicion != 'HABIDO'">{{ row.condicion }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="estado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado Proveedor </th>
              <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">
                <div class="pav-tag-green" *ngIf="row.estado == 'ACTIVO'">{{ row.estado }}</div>

              </td>
            </ng-container>

            <ng-container matColumnDef="reencion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Agente Retenedor </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha">
                <div class="pav-tag-green" *ngIf="row.reencion == 'SI'">{{ row.reencion }}</div>
                <div class="pav-tag-red" *ngIf="row.reencion != 'SI'">{{ row.reencion }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
              <td mat-cell *matCellDef="let row" data-label="Imagen">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">

                  <!-- <button mat-menu-item type="button" (click)="verPdf(row.dpc_urlpdf)" *ngIf="row.ind_xml == 1">
                    <mat-icon>download</mat-icon>
                    <span>Ver PDF</span>
                  </button> -->
                  <button mat-menu-item type="button" (click)="abrirModalCargarImagenes(row)">
                    <mat-icon>file_upload</mat-icon>
                    <span>Cargar imagenes</span>
                  </button>
                  <button mat-menu-item type="button" (click)="abrirModalCargarXML(row)">
                    <mat-icon>file_upload</mat-icon>
                    <span>Cargar XML</span>
                  </button>

                </mat-menu>
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="proveedor_existente">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Existe Proveedor ? </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha">
                <div class="pav-tag-green" *ngIf="row.existeProveedor == 'SI'">{{ row.existeProveedor }}</div>
                <div class="pav-tag-red" *ngIf="row.existeProveedor == 'NO'">{{ row.existeProveedor }}</div>

              </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="20">No se encontraron registros</td>
            </tr>

          </table>

        </div>

        <mat-paginator #paginatorRecepcion showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

      </mat-tab>
      <mat-tab label="" disabled>
        <recepcion-compra-form [recepcion]="recepcion" (onVolverListadoRecepcion)="volverAListadoRecepcion($event)"
          [maestros]="maestrosRegistroCompra"></recepcion-compra-form>
      </mat-tab>
    </mat-tab-group>
  </mat-tab>

  <mat-tab label="+ Opciones" labelClass="mat-tab-label-right">
    <!-- <h3>Cargar XML o Ver Proveedores Nuevos</h3> -->
    <mat-tab-group>
      <mat-tab label="Archivos XML" labelClass="mat-tab-label-cargar-xml">
        <br>
        <cargar-xml-file-component [tiposDocumentoSUNAT]="tiposDocumento"
          (onMoverXMLARecepcion)="establecerXMLARecepcion($event)"></cargar-xml-file-component>

      </mat-tab>
      <mat-tab label="Nuevos proveedores" labelClass="mat-tab-label-nuevos-proveedores">
        <br>
        <registro-compra-proveedor-no-registrado
          [proveedores]="proveedoresNoExistentes"></registro-compra-proveedor-no-registrado>
      </mat-tab>
    </mat-tab-group>

  </mat-tab>
</mat-tab-group>

<br>
<div>

  <!-- <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar> -->
</div>
