import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { Driver } from 'src/app/models';
import { AuthenticationService, GeneralService, ComprasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { fillTable } from '@utils/tables/table';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-provider-create',
  templateUrl: './provider-create.component.html',
  styleUrls: ['./provider-create.component.css']
})
export class ProviderCreateComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  tiposPersona: any[] = [];
  tiposDocumento: any[] = [];
  paises: any[] = [];
  ubicaciones: any[] = [];
  condiciones: any[] = [];
  ubigeos: any[] = [];

  fecha: Date = new Date();
  usuario: any;

  displayedColumns: string[] = ['acciones', 'codigo', 'descripcion'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsContacto: string[] = ['acciones', 'codigo', 'colaborador', 'dni', 'cargo', 'correo', 'telefono', 'celular', 'web', 'referencia', 'estado'];
  dataSourceContacto: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorContacto: MatPaginator;
  @ViewChild(MatSort) sortContacto: MatSort;

  displayedColumnsEstablecimiento: string[] = ['acciones', 'codigo', 'establecimiento', 'direccion', 'pais', 'ubicacion'];
  dataSourceEstablecimiento: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorEstablecimiento: MatPaginator;
  @ViewChild(MatSort) sortEstablecimiento: MatSort;

  displayedColumnsCuenta: string[] = ['acciones', 'codigo', 'banco', 'moneda', 'nro_cuenta', 'cuenta_interbancaria', 'glosa'];
  dataSourceCuenta: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorCuenta: MatPaginator;
  @ViewChild(MatSort) sortCuenta: MatSort;

  tipoPersona$: Subscription;
  tipoDocumentoIdentidad$: Subscription;
  pais$: Subscription;
  ubicacion$: Subscription;
  condicionPago$: Subscription;
  ubigeo$: Subscription;
  loading$: Subscription;

  condicionPago: any
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private _comprasService: ComprasService,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>
  ) {

    this.driver = new Driver();
    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSourceContacto = fillTable([], this.paginatorContacto, this.sortContacto);

    this.dataSourceCuenta = fillTable([], this.paginatorCuenta, this.sortCuenta);

    this.dataSourceEstablecimiento = fillTable([], this.paginatorEstablecimiento, this.sortEstablecimiento);

    this.condicionPago = "";
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id})=>{
      this.uid = id;
      if(this.uid == '0') return;
      this.loadMaestro();
    })
  }

  loadMaestro(): void {
    this.loaderData = true;

    this.driver.CHO_INDSTA = "1";

    forkObs(
      this._generalService.listarTipoDePersona(),
      this._generalService.listarTipoDocumentoIdentidad(),
      this._generalService.listarPaises(),
      this._generalService.listarUbicacion(null),
      this._comprasService.obtenerCondicionesPago(),
      this._generalService.listarUbigeos()
    ).then(data => {
      this.tiposPersona = data[0];
      this.tiposDocumento = data[1];
      this.paises = data[2];
      this.ubicaciones = data[3];
      this.condiciones = data[4];
      this.ubigeos = data[5];

      this.loaderData = false;
    }).catch(err => {
      this.loaderData = false;
      this._snackBarService.showError('Error al obtener ubigeos', 'OK');

    })

  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarProveedor(f): this.actualizarProveedor(f);
  }

  registrarProveedor(f: NgForm): void {}

  actualizarProveedor(f: NgForm): void {}

  volver(): void {
    this._router.navigate(['/modulo-compras/proveedores']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoPersona$,
      this.tipoDocumentoIdentidad$,
      this.pais$,
      this.ubicacion$,
      this.condicionPago$,
      this.ubigeo$,
    ])
  }

}
