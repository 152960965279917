<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

  <mat-card [ngStyle]="{backgroundColor: '#eaf0f9'}" class="mat-elevation-z0">
    <div [ngStyle]="{display: 'flex', alignItems: 'center'}">
      <mat-icon [ngStyle]="{cursor: 'pointer'}" (click)="volver()">arrow_back</mat-icon>
      &nbsp;
      <p [ngStyle]="{fontWeight: 'bold'}">Detalle Recepción</p>

      <div class="pav-separator"></div>
      <button [class.spinner]="loaderReg" mat-button matTooltip="Nuevo" [disabled]="loaderReg"
        class="mat-elevation-z4 pav-button-action-general" [ngStyle]="{backgroundColor: '#2360FF'}" type="button" [routerLink]="['/modulo-compras/registro-de-compra', '0']">
        <mat-icon svgIcon="add" *ngIf="!loaderReg"></mat-icon>
      </button>
      <button [class.spinner]="loaderReg" mat-button matTooltip="Grabar" [disabled]="loaderReg"
        class="mat-elevation-z4 pav-button-action-general" [ngStyle]="{backgroundColor: '#2360FF', marginLeft: '5px'}" type="submit">
        <mat-icon svgIcon="grabar" *ngIf="!loaderReg"></mat-icon>
      </button>
    </div>
  </mat-card>
  <mat-card class="mat-elevation-z0">
    <div class="row">

      <div class="col s6 m6 l1">
        <mat-form-field class="example-full-width custom-focus-color">
          <mat-label>Año</mat-label>
          <input #iAno min="1900" type="number" [(ngModel)]="recepcionCompra.ANO_CODANO" name="ano_codano" matInput
            placeholder="Año" [disabled]="loaderData" readonly required>
        </mat-form-field>
      </div>
      <div class="col s6 m6 l1">
        <mat-form-field class="example-full-width">
          <mat-label>Mes</mat-label>
          <input #iMes min="0" type="number" [(ngModel)]="recepcionCompra.MES_CODMES" name="mes_codmes" matInput
            placeholder="Mes" [disabled]="loaderData" readonly required>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l2 xl2">
        <select-search [label]="'SubDiario'" [placeholder]="'Ej. CAJA CHICA'" [name]="'sco_codsco'"
          [value]="'sco_codsco'" [disabled]="loaderData" [description]="'sco_descri'" [data]="subdiarios"
          [required]="true" (cambioSelect)="recepcionCompra.SCO_CODSCO = $event; seleccionarSubdiario($event)"
          [model]="recepcionCompra.SCO_CODSCO">
        </select-search>

      </div>

      <div class="col s12 m6 l1">
        <mat-form-field class="example-full-width">
          <mat-label>Código</mat-label>
          <input name="codigo" [value]="recepcionCompra.CCO_CODCCO? recepcionCompra.CCO_CODCCO.slice(-5): null" matInput placeholder="Código" [disabled]="loaderData" readonly>
        </mat-form-field>
      </div>

      <div class="col s6 m6 l2 xl2">
        <mat-form-field>
          <mat-label>Fecha de Registro</mat-label>
          <input #iFecha [min]="minDate" [max]="maxDate" maxlength="10" matInput [matDatepicker]="fecha"
            [disabled]="loaderData" [(ngModel)]="recepcionCompra.CCO_FECREG" name="CCO_FECREG">
          <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
          <mat-datepicker #fecha></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col s6 m6 l2">
        <mat-form-field>
          <mat-label>Fecha de Emisión</mat-label>
          <input #iEmision maxlength="10" matInput [matDatepicker]="emision" [disabled]="loaderData"
            [(ngModel)]="recepcionCompra.CCO_FECEMI" [max]="recepcionCompra.CCO_FECREG"
            name="cco_fecemi" (dateChange)="cambiarFechaEmision($event)" (keyup.enter)="establecerEmision()">
          <mat-datepicker-toggle matSuffix [for]="emision"></mat-datepicker-toggle>
          <mat-datepicker #emision></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3 xl1">
        <mat-form-field>
          <mat-label>T.C.</mat-label>
          <input (focus)="focusInput($event)" #iTc min="0" type="number" matInput placeholder="T.C."
            [ngStyle]="{textAlign: 'right'}" [disabled]="loaderData" mask="separator.3"
            [(ngModel)]="recepcionCompra.CCO_TIPCAM" name="cco_tipcam" appTipoCambioFormat step="any" min="0" required>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l1 xl2">
        <select-search [label]="'Cuenta'" [placeholder]="'Ej. 401112'" [name]="'cuenta'" [value]="'cuenta'"
          [description]="'description'" [disabled]="loaderData" [data]="cuentas" [required]="true"
          (cambioSelect)="recepcionCompra.CCN_CODCCN = $event" [model]="recepcionCompra.CCN_CODCCN">
        </select-search>


      </div>

      <div class="col s12 m12 l5">

        <proveedor-select [label]="'Proveedor'" [placeholder]="'Ej. GARCÍA'" [name]="'cli_codcli'"
          [disabled]="loaderData" [value]="'cli_codcli'" [description]="'description_reverse'" [data]="proveedores"
          [required]="true" (cambioSelect)="recepcionCompra.CLI_CODCLI = $event; seleccionarProveedor()"
          [model]="recepcionCompra.CLI_CODCLI" [canAdd]="true" [canEdit]="true">
        </proveedor-select>

      </div>

      <div class="col s12 m12 l6 xl2">

        <select-search [label]="'Orden de compra'" [placeholder]="'Ej. 000000000'" [name]="'ocm_numocm'"
          [value]="'ocm_numocm'" [disabled]="loaderData" [description]="'description'" [data]="ordenesCompra"
          [required]="true" (cambioSelect)="recepcionCompra.OCM_NUMOCM = $event; seleccionarOrdenCompra()"
          [model]="recepcionCompra.OCM_NUMOCM">
        </select-search>

      </div>

      <div class="col s4 m4 l1 xl1">
        <mat-form-field>
          <mat-label>Moneda</mat-label>
          <mat-select #iMoneda required [disabled]="loaderData" [(ngModel)]="recepcionCompra.TMO_CODTMO"
            name="tmo_codtmo" name="tmo_codtmo" (selectionChange)="seleccionarMoneda($event)">
            <mat-option value="SO">
              Soles
            </mat-option>
            <mat-option value="DO">
              Dólares
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col s4 m4 l3 xl1">
        <mat-form-field>
          <mat-label>% IGV</mat-label>
          <input #iIgv min="0" type="number" matInput placeholder="% IGV" [disabled]="loaderData" required
            [(ngModel)]="recepcionCompra.CCO_TASIGV" name="cco_tasigv" step="any" (keyup.enter)="calculaTotal()">
        </mat-form-field>
      </div>

      <div class="col s4 m4 l3 xl1">
        <mat-form-field>
          <mat-label>Der Crédito</mat-label>
          <mat-select #iDerCredito required [disabled]="loaderData" [(ngModel)]="recepcionCompra.DPC_INDDER"
            name="dpc_indder">
            <mat-option value="1">
              SI
            </mat-option>
            <mat-option value="0">
              NO
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l2">
        <mat-form-field>
          <mat-label>Cta. Corriente</mat-label>
          <input readonly [(ngModel)]="recepcionCompra.CCO_CODCCO" name="CCO_CODCCO" type="text" matInput placeholder="Cta. Corriente" [disabled]="loaderData"
            >
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3">
        <select-search [label]="'Tipo de Operación'" [placeholder]="'Ej. OTROS'" [name]="'tga_codtga'"
          [value]="'tga_codtga'" [description]="'tga_descri'" [disabled]="loaderData" [data]="tiposOperacion"
          [required]="true" (cambioSelect)="recepcionCompra.TGA_CODTGA = $event; seleccionarTipoOperacion()"
          [model]="recepcionCompra.TGA_CODTGA">
        </select-search>

      </div>

      <div class="col s6 m6 l1 xl1">

        <select-search [label]="'Tipo Doc.'" [placeholder]="'Ej. FAC'" [name]="'TDO_CODTDO'" [value]="'TDO_CODTDO'"
          [disabled]="loaderData" [description]="'description'" [data]="tiposDocumento" [required]="true"
          (cambioSelect)="recepcionCompra.TDO_CODTDO = $event; seleccionarTipoDoc()"
          [model]="recepcionCompra.TDO_CODTDO">
        </select-search>

      </div>

      <div class="col s6 m6 l3 xl1">
        <mat-form-field>
          <mat-label>Serie</mat-label>
          <input required #iSerie type="text" maxlength="4" matInput placeholder="Serie" [disabled]="loaderData"
            [(ngModel)]="recepcionCompra.sad_codsad" name="sad_codsad" (keyup.enter)="establecerSerie()">
        </mat-form-field>
      </div>

      <div class="col s12 m12 l4 xl2">
        <mat-form-field>
          <mat-label>Nro. Doc.</mat-label>
          <input #iNroDoc type="text" maxlength="12" matInput placeholder="Nro. Doc." required
            [(ngModel)]="recepcionCompra.CCO_NUMDOC" name="cco_numdoc" [disabled]="loaderData"
            (keyup)="autocompletarNroDoc()">
        </mat-form-field>
      </div>

      <div class="col s12 m12 l1 xl1">
        <mat-form-field>
          <mat-label>Año Dua</mat-label>
          <input #iAnoDua min="0" type="number" matInput placeholder="Año Dua" [readonly]="disableAnoDua"
            [disabled]="loaderData" [(ngModel)]="recepcionCompra.DPC_ANODUA" name="dpc_anodua">
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3 xl2" *ngIf="configuracionTecnica.s72_indcpa == 1">

        <select-search [label]="'Condi. Pago'" [placeholder]="'Ej. CONTADO'" [name]="'CPA_CODCPA'"
          [value]="'CPA_CODCPA'" [description]="'CPA_DESABR'" [data]="condiciones" [disabled]="loaderData"
          [required]="true" (cambioSelect)="recepcionCompra.CPA_CODCPA = $event; seleccionarCondicionPago($event)"
          [model]="recepcionCompra.CPA_CODCPA">
        </select-search>

      </div>

      <div class="col s12 m12 l3 xl2">
        <mat-form-field>
          <mat-label>Fecha de Vcto.</mat-label>
          <input #iVcto [min]="recepcionCompra.CCO_FECEMI" matInput [matDatepicker]="vcto" [disabled]="loaderData"
            [(ngModel)]="recepcionCompra.CCO_FECVEN" name="cco_fecven" required>
          <mat-datepicker-toggle matSuffix [for]="vcto"></mat-datepicker-toggle>
          <mat-datepicker #vcto></mat-datepicker>
        </mat-form-field>
      </div>

    </div>
    <div class="row">
      <div class="col s12 m12 l5">
        <mat-form-field>
          <mat-label>Glosa</mat-label>
          <input #iGlosa type="text" matInput placeholder="Glosa" [disabled]="loaderData" required
            [(ngModel)]="recepcionCompra.CCO_GLOCCO" name="cco_glocco">
        </mat-form-field>
      </div>

      <div class="col s12 m12 l3">
        <mat-form-field>
          <mat-label>Clasificación de bienes y servicios</mat-label>
          <mat-select #iClasificacion required [disabled]="loaderData" [(ngModel)]="recepcionCompra.t30_codt30"
            name="t30_codt30">
            <mat-option *ngFor="let tipo of tiposBienServicio" [value]="tipo.t30_codt30" [matTooltip]="tipo.t30_dest30">
              {{tipo.t30_dest30}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l2">

        <select-search [label]="'Tipo de Renta'" [placeholder]="'Ej. BIENES'" [name]="'t31_codt31'"
          [disabled]="loaderData" [value]="'t31_codt31'" [description]="'description'" [data]="tiposRenta"
          (cambioSelect)="recepcionCompra.t31_codt31 = $event" [model]="recepcionCompra.t31_codt31">
        </select-search>

      </div>

      <div class="col s12 m12 l2">

        <select-search [label]="'Centro de costo'" [placeholder]="'Ej. Centro de costo'" [name]="'ccs_codccs'"
          [disabled]="loaderData" [value]="'ccs_codccs'" [description]="'description'" [data]="centros"
          (cambioSelect)="recepcionCompra.CCS_CODCCS = $event" [model]="recepcionCompra.CCS_CODCCS">
        </select-search>

      </div>

    </div>

  </mat-card>
  <br>

  <div class="row">

    <div class="col s12 m12 l6 xl6">
      <mat-tab-group>
        <mat-tab label="Importes" labelClass="mat-tab-label-0-0">
          <!-- <div class="pav-card-header green">
            <div class="pav-block"></div>
            <div class="pav-card-header-title">
              Importes
            </div>
          </div> -->
          <mat-card class="mat-elevation-z0">
            <mat-card-content>

              <div class="row">
                <div class="col s6 m4 l3">
                  <mat-form-field>
                    <mat-label>Afecto C/D</mat-label>
                    <input #iAfectoCD min="0" type="number" matInput placeholder="Afecto C/D" appDecimalPlaces
                      [(ngModel)]="recepcionCompra.DPC_AFCDER" name="dpc_afcder" [disabled]="loaderData"
                      [ngStyle]="{textAlign: 'right'}" step="any">
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Afecto S/D</mat-label>
                    <input #iAfectoSD min="0" type="number" matInput placeholder="Afecto S/D" [disabled]="loaderData"
                      [ngStyle]="{textAlign: 'right'}" appDecimalPlaces [(ngModel)]="recepcionCompra.DPC_AFSDER"
                      name="dpc_afsder" step="any">
                  </mat-form-field>

                  <mat-form-field *ngIf="recepcionCompra.TDO_CODTDO == 'dd'">
                    <mat-label>% Renta</mat-label>
                    <input #iRenta min="0" type="number" matInput placeholder="% Renta" appDecimalPlaces
                      [(ngModel)]="recepcionCompra.DPC_TASREN" name="dpc_tasren" [disabled]="loaderData"
                      [ngStyle]="{textAlign: 'right'}" step="any">
                  </mat-form-field>

                  <mat-form-field *ngIf="recepcionCompra.TDO_CODTDO == 'dd'">
                    <mat-label>Val Renta</mat-label>
                    <input #iValRenta min="0" type="number" matInput placeholder="Val Renta" [disabled]="loaderData"
                      appDecimalPlaces [(ngModel)]="recepcionCompra.DPC_IMPREN" name="dpc_impren" step="any"
                      [ngStyle]="{textAlign: 'right'}" (keyup)="calculaTotal()">
                  </mat-form-field>
                </div>

                <div class="col s6 m4 l3">
                  <mat-form-field>
                    <mat-label>Inafecto</mat-label>
                    <input #iInafecto min="0" appDecimalPlaces type="number" [ngStyle]="{textAlign: 'right'}"
                      [(ngModel)]="recepcionCompra.CCO_IMPINA" (focus)="focusInput($event)" name="cco_impina" matInput
                      placeholder="Inafecto" [disabled]="loaderData" step="any" (keyup.enter)="establecerInafecto()">
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Afecto</mat-label>
                    <input #iAfecto min="0" appDecimalPlaces type="number" [ngStyle]="{textAlign: 'right'}"
                      [(ngModel)]="recepcionCompra.CCO_IMPAFE" (focus)="focusInput($event)" name="cco_impafe" matInput
                      placeholder="Afecto" [disabled]="loaderData" step="any" (keyup.enter)="establecerAfecto()">
                  </mat-form-field>


                </div>

                <div class="col s12 m4 l3">

                  <mat-form-field>
                    <mat-label>Val. Compra</mat-label>
                    <input #iValCompra min="0"
                      [value]="recepcionCompra.valvta ? recepcionCompra.valvta.toFixed(2): '0.00'"
                      [ngStyle]="{textAlign: 'right'}" name="valCompra" type="number" matInput placeholder="Val. Compra"
                      readonly [disabled]="loaderData" step="any">
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Imp. IGV</mat-label>
                    <input #iImpIgv min="0" [value]="recepcionCompra.CCO_IMPIGV.toFixed(2)"
                      [ngStyle]="{textAlign: 'right'}" name="cco_impigv" type="number" matInput readonly
                      placeholder="Imp. IGV" [disabled]="loaderData" (keyup)="calculaTotal()" step="any">
                  </mat-form-field>


                </div>

                <div class="col s12 m4 l3">
                  <mat-form-field>
                    <mat-label>Ajuste IGV</mat-label>
                    <input #iAjusteIgv appDecimalPlaces type="number" (focus)="focusInput($event)"
                      [ngStyle]="{textAlign: 'right'}" matInput placeholder="Aju IGV" [disabled]="loaderData"
                      [(ngModel)]="recepcionCompra.DPC_DIFIGV" name="dpc_difigv" step="any"
                      (keyup.enter)="calculaTotal()">
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>TOTAL</mat-label>
                    <input #iTotal min="0" [value]="recepcionCompra.CCO_IMPDOC.toFixed(2)"
                      [ngStyle]="{textAlign: 'right'}" name="cco_impdoc" type="number" matInput readonly
                      placeholder="TOTAL" [disabled]="loaderData" step="any">
                  </mat-form-field>
                </div>

              </div>
            </mat-card-content>
          </mat-card>
        </mat-tab>
        <mat-tab label="Referencias" labelClass="mat-tab-label-0-2" *ngIf="indDocReferencia == 1">
          <mat-card class="mat-elevation-z0">
            <div class="row">
              <div class="col s12 m12 l4">
                <select-search [label]="'Tipo Doc. Ref'" [placeholder]="'Ej. FAC'" [name]="'TDO_CODTDO'"
                  [value]="'TDO_CODTDO'" [disabled]="loaderData" [description]="'description'" [data]="tiposDocumento"
                  [required]="true" (cambioSelect)="recepcionCompra.TDO_DOCREF = $event; seleccionarTipoDocRef()"
                  [model]="recepcionCompra.TDO_DOCREF">
                </select-search>

              </div>

              <div class="col s12 m12 l4">
                <mat-form-field>
                  <mat-label>Doc. Ref.</mat-label>
                  <input #iDocRef required type="text" matInput placeholder="Doc. Ref." [disabled]="loaderData"
                    [(ngModel)]="recepcionCompra.CCO_NUMREF" name="cco_numref" (keyup.tab)="autocompletarNroDocRef()" (keyup.enter)="autocompletarNroDocRef()">
                </mat-form-field>
              </div>

              <div class="col s12 m12 l4">
                <mat-form-field>
                  <mat-label>Fec. Ref.</mat-label>
                  <input #iFecRef [max]="recepcionCompra.CCO_FECEMI" required matInput [matDatepicker]="fecref"
                    [disabled]="loaderData" [(ngModel)]="recepcionCompra.CCO_FECREF" name="cco_fecref">
                  <mat-datepicker-toggle matSuffix [for]="fecref"></mat-datepicker-toggle>
                  <mat-datepicker #fecref></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </mat-tab>
        <mat-tab label="Otros Impuestos" labelClass="mat-tab-label-0-1" class="tab-1">
          <mat-card class="mat-elevation-z0">
            <mat-card-content>
              <div class="row">
                <div class="col s6 m6 l3">
                  <mat-form-field>
                    <mat-label>Importe Sise</mat-label>
                    <input #iImporteSise min="0" type="number" [ngStyle]="{textAlign: 'right'}" matInput
                      placeholder="Importe Sise" appDecimalPlaces [disabled]="loaderData"
                      [(ngModel)]="recepcionCompra.dpc_impsis" name="dpc_impsis" step="any" (keyup)="calculaTotal()">
                  </mat-form-field>

                </div>
                <div class="col s6 m6 l3">
                  <mat-form-field>
                    <mat-label>Importe Fise</mat-label>
                    <input #iImporteFise min="0" type="number" [ngStyle]="{textAlign: 'right'}" matInput
                      placeholder="Importe Fise" appDecimalPlaces [disabled]="loaderData"
                      [(ngModel)]="recepcionCompra.dpc_impfis" name="dpc_impfis" step="any" (keyup)="calculaTotal()">
                  </mat-form-field>

                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-tab>


      </mat-tab-group>
    </div>
    <div class="col s12 m12 l6 xl6">
      <mat-tab-group>
        <mat-tab label="Obligaciones Tributarias" labelClass="mat-tab-label-0-7">
          <mat-card class="mat-elevation-z0">

            <mat-card-content>
              <div class="row">
                <div class="col s12 m12 l4">
                  <mat-form-field>
                    <mat-label>Tipo Retención</mat-label>
                    <mat-select #iTipoRetencion required [disabled]="loaderData"
                      [(ngModel)]="recepcionCompra.IRI_CODIRI" name="iri_codiri"
                      (selectionChange)="cambiarTipoRetencion($event)">
                      <mat-option *ngFor="let tipo of tiposRetencion" [value]="tipo.IRI_CODIRI">
                        {{tipo.IRI_DESCRI}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l4">

                  <select-search [label]="'Tipo Impuesto Detracción'" [placeholder]="'Ej. 0'" [value]="'codigo'"
                    [name]="'codigo'" [disabled]="loaderData" [description]="'descri'" [data]="tiposImpRetencion"
                    (cambioSelect)="recepcionCompra.TIM_CODTIM = $event; cambiarTipoImpuestoRetencion()"
                    [model]="recepcionCompra.TIM_CODTIM">
                  </select-search>

                </div>
                <div class="col s12 m12 l4">
                  <select-search [label]="'Tipo Operacion de Retención'" [placeholder]="'Ej. 0'" [value]="'tsd_codtsd'"
                    [name]="'tsd_codtsd'" [disabled]="loaderData" [description]="'tsd_descri'"
                    [data]="tiposOperacionRetencion">
                  </select-search>

                </div>
                <div class="col s12 m12 l3" *ngIf="!esPercepcion">
                  <mat-form-field>
                    <mat-label>% {{tituloTasa}}</mat-label>
                    <input #iTasa min="0" type="number" [ngStyle]="{textAlign: 'right'}" matInput
                      [placeholder]="'%' + tituloTasa" [(ngModel)]="recepcionCompra.DPC_TASDET" name="dpc_tasdet" step="any">
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l3" *ngIf="!esPercepcion && recepcionCompra.IRI_CODIRI != '02'">
                  <mat-form-field>
                    <mat-label>Importe</mat-label>
                    <input appDecimalPlaces (focus)="focusInput($event)" #iImporte min="0" type="text" matInput
                      placeholder="Importe" [ngStyle]="{textAlign: 'right'}" [disabled]="loaderData"
                      [value]="recepcionCompra.DPC_IMPDET? recepcionCompra.DPC_IMPDET.toFixed(0) : '0'" name="dpc_impdet" step="any">
                  </mat-form-field>
                </div>

                <div class="col s12 m12 l3" *ngIf="!esPercepcion && recepcionCompra.IRI_CODIRI == '02'">
                  <mat-form-field>
                    <mat-label>Importe Ret.</mat-label>
                    <input appDecimalPlaces (focus)="focusInput($event)" #iImporte min="0" type="text" matInput
                      placeholder="Importe" [ngStyle]="{textAlign: 'right'}" [disabled]="loaderData"
                      [value]="recepcionCompra.DPC_IMPPER? recepcionCompra.DPC_IMPPER.toFixed(2) : '0'" name="dpc_impdet" step="any">
                  </mat-form-field>
                </div>

                <div *ngIf="esPercepcion" [ngClass]="esPercepcion?'col s12 m12':  'col s12 m12 l6'">
                  <mat-form-field>
                    <mat-label>Imp. Percepción</mat-label>
                    <input #iImpPercepcion min="0" type="number" [(ngModel)]="recepcionCompra.DPC_IMPPER"
                      [ngStyle]="{textAlign: 'right'}" name="DPC_IMPPER" matInput placeholder="Imp. Percepción"
                      [disabled]="loaderData" step="any" (keyup)="calculaTotal()">
                  </mat-form-field>
                </div>
                <div [ngClass]="esPercepcion?'col s12 m12 l3':  'col s12 m12 l3'" *ngIf="!esPercepcion">
                  <mat-form-field>
                    <mat-label>Depósito</mat-label>
                    <input appDecimalPlaces (focus)="focusInput($event)" #iDeposito min="0" type="text"
                      [ngStyle]="{textAlign: 'right'}" matInput placeholder="Depósito" [disabled]="loaderData"
                      [(ngModel)]="recepcionCompra.DPC_DEPDET" name="dpc_depdet" step="any">
                  </mat-form-field>
                </div>
                <div [ngClass]="esPercepcion?'col s12 m12 l3':  'col s12 m12 l3'" *ngIf="!esPercepcion">
                  <mat-form-field>
                    <mat-label>Fecha</mat-label>
                    <input #iFecha [min]="recepcionCompra.CCO_FECEMI" matInput [matDatepicker]="fechaRetencion"
                      [disabled]="loaderData" [(ngModel)]="recepcionCompra.DPC_FECDET" name="dpc_fecdet">
                    <mat-datepicker-toggle matSuffix [for]="fechaRetencion"></mat-datepicker-toggle>
                    <mat-datepicker #fechaRetencion></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

        </mat-tab>
        <mat-tab label="Validación Doc." labelClass="mat-tab-label-0-3">
          <mat-card class="mat-elevation-z0"></mat-card>
        </mat-tab>
        <mat-tab label="Total Contable" labelClass="mat-tab-label-0-6">
          <!-- <div class="pav-table-header-container">
            <div class="pav-table-header-icon">

            </div>
            <div class="pav-table-header-title">
              Totales
            </div>

            <div class="pav-input-search">
            </div>


          </div> -->
          <div class="mat-elevation-z0 b-r-15">
            <table mat-table [dataSource]="dataSource3">

              <ng-container matColumnDef="name" sticky>
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let row" data-label="Código"> {{row.name}} </td>
              </ng-container>

              <ng-container matColumnDef="mn">
                <th mat-header-cell *matHeaderCellDef> M.N. </th>
                <td mat-cell *matCellDef="let row" data-label="Código">
                  <div [ngStyle]="{width: '60px', textAlign: 'right'}">
                    {{row.mn ? numeroConDosDecimales(row.mn): '0.00'}}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="me">
                <th mat-header-cell *matHeaderCellDef> M.E. </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente">
                  <div [ngStyle]="{width: '60px', textAlign: 'right'}">
                    {{row.me ? numeroConDosDecimales(row.me): '0.00'}}
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns3; sticky"
                [ngStyle]="{background: 'rgb(108, 214, 223)'}"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros</td>
              </tr>

            </table>
          </div>
        </mat-tab>
        <!-- <mat-tab label="Documentos de conformidad" labelClass="mat-tab-label-0-6">
          <div class="pav-table-header-container">
            <div class="pav-table-header-icon">

            </div>
            <div class="" [ngStyle]="{marginRight: '20px', color: 'white'}">
              Documentos
            </div>
            <div class="pav-separator"></div>

            <div class="pav-button-icon-add" matTooltip="Agregar ítem" (click)="agregarDocumento()"
              [ngStyle]="{display: loaderData? 'none': ''}">
              <mat-icon svgIcon="add-circle"></mat-icon>
            </div>
            &nbsp;
            <div class="pav-button-icon-delete" matTooltip="Eliminar ítem" (click)="quitarDocumento()"
              [ngStyle]="{display: loaderData? 'none': ''}">
              <mat-icon svgIcon="remove"></mat-icon>
            </div>
            &nbsp;
            <div class="pav-button-icon-insert" matTooltip="Insertar ítem" (click)="insertarDocumento()"
              [ngStyle]="{display: loaderData? 'none': ''}">
              <mat-icon svgIcon="add-outline"></mat-icon>
            </div>
            <div class="pav-table-header-add">

            </div>

          </div>
          <div class="overflow-x">
            <table mat-table [dataSource]="dataSourceDocumentos" matSort class="table-cell">

              <ng-container matColumnDef="item">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                <td mat-cell *matCellDef="let row; let i = index" [style.color]="row.color" data-label="Item"
                  style="width: 100px">
                  <p [ngStyle]="{color: 'white', marginLeft: '10px'}">{{i + 1}}</p>


                </td>
              </ng-container>

              <ng-container matColumnDef="tipo_documento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de documento </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Tipo de documento">
                  <select-search
                    [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                    [label]="''" [placeholder]="'Ej. TORRES'" [name]="'tipo_documento' + i" [value]="'cuenta'"
                    [description]="'description'" [data]="cuentas" (cambioSelect)="row.ccn_codccn = $event"
                    [model]="row.tipo_documento">
                  </select-search>


                </td>
              </ng-container>

              <ng-container matColumnDef="comentario">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Comentario </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Comentario">
                  <div>
                    <mat-form-field style="width: 60px; padding-left: 12px !important"
                      [class.mat-form-field-row]="row === selected"
                      class="mat-form-field-table form-field-small form-field-cell">
                      <input [ngStyle]="{textAlign: 'right'}" matInput placeholder="" [(ngModel)]="row.comentario"
                        [name]="'comentario' + i">
                    </mat-form-field>
                  </div>

                </td>
              </ng-container>

              <ng-container matColumnDef="adjuntar">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Adjuntar </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Adjuntar">
                  <div [ngStyle]="{display: 'flex'}">

                    <img (click)="openFile(row.file, row.typeFile)" *ngIf="row.file" [src]="row.file" width="40" height="40" alt="Imagen" [ngStyle]="{cursor: 'pointer'}">

                    <pav-button-upload-file accept="image/*, application/pdf" (onUploadFile)="handleOnUploadFile(row, $event)"></pav-button-upload-file>
                  </div>


                </td>
              </ng-container>

              <ng-container matColumnDef="visualizar">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Visualizar </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Visualizar">
                  <button [ngStyle]="{marginLeft: '10px'}" mat-mini-fab color="primary"
                    aria-label="Example icon button with a menu icon">
                    <mat-icon>visibility</mat-icon>
                  </button>

                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsDocumentos"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsDocumentos;"
                [class.selected-row]="row === selected" (click)="selectRow(row)"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="15">No se encontraron registros</td>
              </tr>

            </table>
            <mat-paginator #paginatorDocumentos [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
          </div>

        </mat-tab> -->
      </mat-tab-group>

    </div>

  </div>
  <br>
  <ng-container *ngIf="isMobile || isTablet">
    <mat-card class="mat-elevation-z0" [ngStyle]="{marginBottom: '18px'}">
      <div align="center">
        <button [disabled]="recepcionCompra.detalle.length > 0" class="pav-btn-action-table" #btnGeneraAsiento color="primary" matTooltip="Generar Asiento"
          type="button" (click)="generarAsiento()" (keyup.enter)="generarAsiento()">
          <mat-icon class="pav-icon-btn-table" svgIcon="generate"></mat-icon>
        </button>
        <button class="pav-btn-action-table" color="primary" matTooltip="Ajuste < 0.05" type="button"
          (click)="ajusteDiferencia()">
          <mat-icon class="pav-icon-btn-table" svgIcon="setting"></mat-icon>
        </button>
        <button class="pav-btn-action-table" color="primary" matTooltip="Borrar Destinos" type="button"
          (click)="borrarAsientosDestino()">
          <mat-icon class="pav-icon-btn-table" svgIcon="document-error"></mat-icon>
        </button>
        <button class="pav-btn-action-table" color="primary" matTooltip="Documentos por pagar" type="button"
          (click)="documentosPorPagar()">
          <mat-icon class="pav-icon-btn-table" svgIcon="money-donation-icon"></mat-icon>
        </button>
        <button class="pav-btn-action-table" color="primary" matTooltip="Copiar item" type="button"
          (click)="copiarItem()">
          <mat-icon class="pav-icon-btn-table" svgIcon="copy"></mat-icon>
        </button>

      </div>

      <div class="pav-separator"></div>

      <div class="pav-table-search" align="center" [ngStyle]="{marginTop: '10px'}">
        <input type="text" (keyup)="applyFilter($event)" [placeholder]="'Buscar'" #input [ngStyle]="{width: '225px'}">
      </div>
    </mat-card>
  </ng-container>

  <div class="row">
    <div class="col s12">
      <div class="mat-elevation-z0">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">

          </div>
          <div class="" [ngStyle]="{marginRight: '20px', color: 'white'}">
            Detalle
          </div>

          <div class="pav-separator"></div>

          <div  *ngIf="!isMobile && !isTablet">
            <button class="pav-btn-action-table-genera-asiento" #btnGeneraAsiento color="primary" matTooltip="Generar Asiento"
              type="button" (click)="generarAsiento()" (keyup.enter)="generarAsiento()">
              <mat-icon class="pav-icon-btn-table" svgIcon="generate"></mat-icon>
            </button>
            <button class="pav-btn-action-table-ajuste" color="primary" matTooltip="Ajuste < 0.05" type="button"
              (click)="ajusteDiferencia()">
              <mat-icon class="pav-icon-btn-table" svgIcon="setting"></mat-icon>
            </button>
            <button class="pav-btn-action-table-borrar-destino" color="primary" matTooltip="Borrar Destinos" type="button"
              (click)="borrarAsientosDestino()">
              <mat-icon class="pav-icon-btn-table" svgIcon="document-error"></mat-icon>
            </button>
            <button class="pav-btn-action-table-cuenta-pagar" color="primary" matTooltip="Documentos por pagar" type="button"
              (click)="documentosPorPagar()">
              <mat-icon class="pav-icon-btn-table" svgIcon="money-donation-icon"></mat-icon>
            </button>
            <button class="pav-btn-action-table-copiar-item" color="primary" matTooltip="Copiar item" type="button"
              (click)="copiarItem()">
              <mat-icon class="pav-icon-btn-table" svgIcon="copy"></mat-icon>
            </button>

          </div>

          <div class="pav-table-search" *ngIf="!isMobile && !isTablet">
            <input type="text" (keyup)="applyFilter($event)" [placeholder]="'Buscar'" #input>
          </div>
          &nbsp;
          &nbsp;
          <div class="pav-button-icon-add" matTooltip="Agregar ítem" (click)="agregarItem()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="add-circle"></mat-icon>
          </div>
          &nbsp;
          <div class="pav-button-icon-delete" matTooltip="Eliminar ítem" (click)="quitarItem()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="remove"></mat-icon>
          </div>
          &nbsp;
          <div class="pav-button-icon-insert" matTooltip="Insertar ítem" (click)="insertarItem()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="add-outline"></mat-icon>
          </div>
          <div class="pav-table-header-add">
            <!-- <div class="isMobiles">
            </div> -->

            <!-- <div class="isWeb">
              <button [disabled]="loaderData" type="button" mat-flat-button class="pav-button-add-item"
                (click)="agregarItem()">{{'general.button.add' | translate}}</button>
            </div> -->
          </div>
          <!-- <button #btnAdd mat-flat-button class="pav-btn-add-item" type="button" (click)="agregarItem()"
          (keyup.enter)="agregarItem()">Nuevo item</button> -->
        </div>

        <div class="overflow-x">
          <table mat-table [dataSource]="dataSource" matSort class="table-cell">

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
              <td mat-cell *matCellDef="let row; let i = index" [style.color]="row.color" data-label="Acciones"
                style="width: 100px">
                <mat-icon svgIcon="quitar-item"
                  [ngStyle]="{cursor: 'pointer', height: '18px', width: '40px'}"></mat-icon>
                <div class="pav-btns-container">

                </div>

              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_corldi">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> It </th>
              <td mat-cell *matCellDef="let row; let ind = index" data-label="Código">

                <mat-form-field style="width: 60px; padding-left: 12px !important"
                  [class.mat-form-field-row]="row === selected"
                  class="mat-form-field-table form-field-small form-field-cell">
                  <input [ngStyle]="{textAlign: 'right'}" readonly matInput placeholder="" [value]="row.ldi_corldi"
                    [name]="'ldi_corldi' + ind">
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="ccn_codccn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Cuenta">
                <div>
                  <select-search
                    [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                    [label]="''" [placeholder]="'Ej. TORRES'" [name]="'cuenta' + i" [value]="'cuenta'"
                    [description]="'description'" [data]="cuentas" (cambioSelect)="row.ccn_codccn = $event"
                    [model]="row.ccn_codccn">
                  </select-search>
                </div>

              </td>
            </ng-container>

            <ng-container matColumnDef="cli_codcli">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Auxiliar">
                <div>

                  <select-search
                    [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                    [label]="''" [placeholder]="'Ej. GARCÍA'" [name]="'cli_codcli' + i" [value]="'cli_codcli'"
                    [description]="'description'" [disabled]="loaderData" [data]="auxiliares"
                    (cambioSelect)="recepcionCompra.CLI_CODCLI = $event" [model]="recepcionCompra.CLI_CODCLI">
                  </select-search>

                </div>

              </td>
            </ng-container>

            <ng-container matColumnDef="ccs_codccs">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> C. Costo </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="C. Costo">
                <select-search
                  [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                  [label]="''" [placeholder]="'Ej. 100001'" [name]="'ccs_codccs' + i" [value]="'ccs_codccs'"
                  [description]="'description'" [disabled]="loaderData" [data]="centros"
                  (cambioSelect)="row.ccs_codccs = $event" [model]="row.ccs_codccs">
                </select-search>

              </td>
            </ng-container>

            <ng-container matColumnDef="tdo_codtdo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Doc.">
                <select-search [label]="''" [placeholder]="'Ej. FAC'"
                  [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                  [name]="'TDO_CODTDO' + i" [value]="'TDO_CODTDO'" [disabled]="loaderData" [description]="'description'"
                  [data]="tiposDocumento" [required]="false" (cambioSelect)="row.tdo_codtdo = $event;"
                  [model]="row.tdo_codtdo">
                </select-search>

              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_docref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Nro. Doc.">
                <mat-form-field [class.mat-form-field-row]="row === selected" style="width: 100px;"
                  class="mat-form-field-table form-field-small form-field-cell" [class.mat-focused]="row.focused">
                  <mat-label></mat-label>
                  <input matInput placeholder="Nro. Doc." [(ngModel)]="row.ldi_docref" (focus)="focusInput($event)"
                    [name]="'nro_doc' + i.toString()">
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="tmo_codtmo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mon. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Mon.">
                <mat-form-field [class.mat-form-field-row]="row === selected" style="width: 50px;"
                  class="mat-form-field-table form-field-small form-field-cell">

                  <mat-select #iMoneda [disabled]="loaderData" [(ngModel)]="row.tmo_codtmo" name="tmo_codtmo"
                    [name]="'moneda' + i.toString()">
                    <mat-option value="SO">
                      S/
                    </mat-option>
                    <mat-option value="DO">
                      $
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_tipcam">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> T. Cambio </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="T. Cambio">
                <mat-form-field [class.mat-form-field-row]="row === selected" style="width: 70px;"
                  class="mat-form-field-table form-field-small form-field-cell">

                  <input [ngStyle]="{textAlign: 'right'}" min="0" type="number" step="any" matInput
                    placeholder="T. Cambio" [(ngModel)]="row.ldi_tipcam" [name]="'t_cambio' + i.toString()">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_inddha">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> D/H </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="D/H" style="width: 100px;">
                <mat-form-field [class.mat-form-field-row]="row === selected" style="width: 70px;"
                  class="mat-form-field-table form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <mat-select [(ngModel)]="row.ldi_inddha" [name]="'dh' + i.toString()"
                    (selectionChange)="seleccionarDH($event.value)">
                    <mat-option value="D">
                      D
                    </mat-option>
                    <mat-option value="H">
                      H
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_impnac">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe MN </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Importe MN">
                <mat-form-field [class.mat-form-field-row]="row === selected" style="width: 80px;"
                  class="mat-form-field-table form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input (focus)="focusInput($event)" [ngStyle]="{textAlign: 'right'}"
                    (keyup.enter)="calculoImportesMN(row)" min="0" type="number" matInput placeholder="Importe MN"
                    [(ngModel)]="row.ldi_impnac" [name]="'ldi_impnac' + i.toString()" step="any">
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_impmex">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe ME </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Importe ME">
                <mat-form-field [class.mat-form-field-row]="row === selected" style="width: 80px;"
                  class="mat-form-field-table form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input (focus)="focusInput($event)" [ngStyle]="{textAlign: 'right'}"
                    (keyup.enter)="calculoImportesME(row)" min="0" type="number" matInput placeholder="Importe ME"
                    appDecimalPlaces [(ngModel)]="row.ldi_impmex" [name]="'ldi_impmex' + i.toString()" step="any">
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_gloldi">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Glosa">
                <mat-form-field [class.mat-form-field-row]="row === selected"
                  class="mat-form-field-table form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input (focus)="focusInput($event)" type="text" matInput placeholder="Glosa"
                    [(ngModel)]="row.ldi_gloldi" [name]="'glosa' + i.toString()">
                </mat-form-field>

              </td>
            </ng-container>

            <ng-container matColumnDef="cco_codcco">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Cte. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Cta. Cte.">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  style="width: 130px">
                  <mat-label></mat-label>
                  <input (focus)="focusInput($event)" type="text" matInput placeholder="Cta. Cte."
                    [(ngModel)]="row.cco_codcco" [name]="'cco_codcco' + i.toString()" readonly>
                </mat-form-field>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="row === selected"
              (click)="selectRow(row)"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="15">No se encontraron registros</td>
            </tr>

          </table>
        </div>

        <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>

</form>
