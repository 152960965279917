<app-pavso-title-section [title]="'Registro de compra'" [module]="'Compras'" [usuario]="registro.dpc_coduse"
  [estado]="registro.dpc_indsta" [ngClass]="'pav-form'"></app-pavso-title-section>

<mat-card class="mat-elevation-z0 pav-alert-success" *ngIf="documentToUpload.xmlFile">
  Datos cargados desde xml
  <button (click)="documentToUpload.xmlFile = null; resetAutocompleteDocument();"
    [ngStyle]="{float: 'right', marginTop: '-11px'}" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</mat-card>

<mat-card class="mat-elevation-z0 pav-alert-info" *ngIf="documentToUpload.invoiceFile">
  Datos cargados desde imágen
  <button (click)="documentToUpload.invoiceFile = null; resetAutocompleteDocument();"
    [ngStyle]="{float: 'right', marginTop: '-11px'}" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</mat-card>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()"
  (keyup.enter)="$event.preventDefault()">

  <pav-form-actions [disabled]="loaderData" (clickNuevo)="nuevoRegistro()" (anular)="anularRegistroCompra()"
    [loaderAnular]="loaderAnular" [isLoading]="loaderReg" urlBack="/modulo-compras/registros-de-compra"
    (clickMasOpciones)="verMasOpciones()">
    <ng-container id="botonesAdicionales">

      <button class="pav-btn-action" color="primary" matTooltip="Reg. Fact. Importación" type="button">
        <mat-icon class="pav-icon-btn" svgIcon="document-payment"></mat-icon>
      </button>

    </ng-container>

  </pav-form-actions>

  <div class="pavso-content-form fade-in-image">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">

        <div class="col s12 m6 l1">
          <mat-form-field class="example-full-width custom-focus-color">
            <mat-label>Año</mat-label>
            <input #iAno min="1900" type="number" [(ngModel)]="registro.ano_codano" name="ano_codano" matInput
              placeholder="Año" [disabled]="loaderData" readonly (keyup.enter)="establecerAno()" required>
          </mat-form-field>
        </div>
        <div class="col s12 m6 l1">
          <mat-form-field class="example-full-width">
            <mat-label>Mes</mat-label>
            <input #iMes min="0" type="number" [(ngModel)]="registro.mes_codmes" name="mes_codmes" matInput
              placeholder="Mes" [disabled]="loaderData" readonly (keyup.enter)="establecerMes()" required>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l2 xl2">
          <select-search [label]="'SubDiario'" [placeholder]="'Ej. CAJA CHICA'" [name]="'sco_codsco'"
            [description]="'sco_descri'" [data]="subsidiarios" [value]="'sco_codsco'"
            [disabled]="loaderData || uid != '0'" [required]="true"
            (cambioSelect)="registro.sco_codsco = $event; seleccionarSubdiario($event)" [model]="registro.sco_codsco">
          </select-search>

          <!-- <mat-form-field>
              <mat-label>SubDiario</mat-label>
              <mat-select #iSubdiario (selectionChange)="seleccionarSubdiario($event)" required [disabled]="loaderData"
                [(ngModel)]="registro.sco_codsco" name="sco_codsco">
                <mat-option *ngFor="let subdiario of subsidiarios" [value]="subdiario.sco_codsco">
                  {{subdiario.sco_descri}}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
        </div>

        <div class="col s12 m6 l1">
          <mat-form-field class="example-full-width">
            <mat-label>Código</mat-label>
            <input [value]="registro.cco_codcco? registro.cco_codcco.slice(-5): null" name="cco_codcco" matInput
              placeholder="Código" [disabled]="loaderData" readonly>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l2 xl2">
          <mat-form-field>
            <mat-label>Fecha de Registro</mat-label>
            <input #iFecha [min]="minDate" required [max]="maxDate" maxlength="10" matInput [matDatepicker]="fecha"
              [disabled]="loaderData" [(ngModel)]="registro.cco_fecreg" name="cco_fecreg"
              (dateChange)="cambiarFecha($event)" (keyup.enter)="establecerFecha()">
            <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
            <mat-datepicker #fecha></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Fecha de Emisión</mat-label>
            <input #iEmision [max]="registro.cco_fecreg" maxlength="10" matInput [matDatepicker]="emision"
              [ngStyle]="{color: documentToUpload.xmlFile || documentToUpload.invoiceFile ? 'green': ''}  "
              [disabled]="loaderData" [(ngModel)]="registro.cco_fecemi" name="cco_fecemi" required
              (dateChange)="cambiarFechaEmision($event)" (keyup.enter)="establecerEmision()">
            <mat-datepicker-toggle matSuffix [for]="emision"></mat-datepicker-toggle>
            <mat-datepicker #emision></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3 xl1">
          <mat-form-field>
            <mat-label>T.C.</mat-label>
            <input (focus)="focusInput($event)" #iTc min="0" type="number" matInput placeholder="T.C."
              [ngStyle]="{textAlign: 'right'}" [disabled]="loaderData" type='number' mask="separator.3"
              [(ngModel)]="registro.cco_tipcam" name="cco_tipcam" appTipoCambioFormat step="any" min="0" (keyup.enter)="establecerTC()" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l1 xl2">
          <select-search [label]="'Cuenta'" [placeholder]="'Ej. 401112'" [name]="'cuenta'" [value]="'cuenta'"
            [description]="'description'" [disabled]="loaderData" [data]="cuentas" [required]="true"
            (cambioSelect)="registro.ccn_codccn = $event" [model]="registro.ccn_codccn">
          </select-search>

        </div>

        <div class="col s12 m12 l3">

          <proveedor-select [label]="'Proveedor'" [placeholder]="'Ej. GARCÍA'" [name]="'cli_codcli'"
            [disabled]="loaderData" [value]="'cli_codcli'" [description]="'description'" [data]="proveedores"
            [required]="true"
            [setColorSelected]="(documentToUpload.xmlFile != null || documentToUpload.invoiceFile != null)"
            (cambioSelect)="registro.cli_codcli = $event; seleccionarProveedor()" [model]="registro.cli_codcli"
            [canAdd]="true" [canEdit]="true">
          </proveedor-select>

        </div>

        <div class="col s12 m12 l6 xl4">
          <select-search [label]="'Orden de compra'" [placeholder]="'Ej. 000000000'" [name]="'ocm_numocm'"
            [value]="'ocm_numocm'" [disabled]="loaderData" [description]="'description'" [data]="ordenesCompra"
            [required]="true" (cambioSelect)="registro.ocm_numocm = $event" [model]="registro.ocm_numocm">
          </select-search>
        </div>

        <div class="col s12 m12 l1 xl1">
          <mat-form-field>
            <mat-label>Moneda</mat-label>
            <mat-select [ngClass]="documentToUpload.invoiceFile || documentToUpload.xmlFile? 'selected-option': ''"
              #iMoneda required [disabled]="loaderData" [(ngModel)]="registro.tmo_codtmo" name="tmo_codtmo"
              name="tmo_codtmo" (selectionChange)="seleccionarMoneda($event)">
              <mat-option value="SO">
                Soles
              </mat-option>
              <mat-option value="DO">
                Dólares
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3 xl1">
          <mat-form-field>
            <mat-label>% IGV</mat-label>
            <input #iIgv min="0" type="number" matInput placeholder="% IGV" [disabled]="loaderData"
              [ngStyle]="{color: documentToUpload.xmlFile || documentToUpload.invoiceFile ? 'green': '', textAlign: 'right'}"
              required [(ngModel)]="registro.cco_tasigv" name="cco_tasigv" step="any" (keyup.enter)="establecerIgv()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3 xl1">
          <mat-form-field>
            <mat-label>Der Crédito</mat-label>
            <mat-select #iDerCredito required [disabled]="loaderData" [(ngModel)]="registro.dpc_indder"
              name="dpc_indder" (selectionChange)="seleccionarDerDebito($event)">
              <mat-option value="1">
                SI
              </mat-option>
              <mat-option value="0">
                NO
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Cta. Corriente</mat-label>
            <input [(ngModel)]="registro.cco_codcco" name="cco_codcco" readonly type="text" matInput
              placeholder="Cta. Corriente" [disabled]="loaderData">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <select-search [label]="'Tipo de Operación'" [placeholder]="'Ej. OTROS'" [name]="'tga_codtga'"
            [value]="'tga_codtga'" [description]="'description'" [disabled]="loaderData" [data]="tiposOperacion"
            [required]="true" (cambioSelect)="registro.tga_codtga = $event; seleccionarTipoOperacion()"
            [model]="registro.tga_codtga">
          </select-search>

        </div>

        <div class="col s12 m12 l1 xl1">

          <select-search [label]="'Tipo Doc.'" [placeholder]="'Ej. FAC'" [name]="'TDO_CODTDO'" [value]="'TDO_CODTDO'"
            [disabled]="loaderData" [description]="'description'" [data]="tiposDocumento" [required]="true"
            (cambioSelect)="registro.tdo_codtdo = $event; seleccionarTipoDoc()"
            [setColorSelected]="(documentToUpload.xmlFile != null || documentToUpload.invoiceFile != null)"
            [model]="registro.tdo_codtdo">
          </select-search>

        </div>

        <div class="col s12 m12 l3 xl1">
          <mat-form-field>
            <mat-label>Serie</mat-label>
            <input required #iSerie
              [ngStyle]="{color: documentToUpload.xmlFile || documentToUpload.invoiceFile ? 'green': ''}" type="text"
              maxlength="4" matInput placeholder="Serie" [disabled]="loaderData" [(ngModel)]="registro.sad_codsad"
              name="sad_codsad" (keyup.enter)="establecerSerie()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4 xl2">
          <mat-form-field>
            <mat-label>Nro. Doc.</mat-label>
            <input #iNroDoc type="text" maxlength="12" matInput placeholder="Nro. Doc." required
              [ngStyle]="{color: documentToUpload.xmlFile || documentToUpload.invoiceFile ? 'green': ''}"
              [(ngModel)]="registro.cco_numdoc" name="cco_numdoc" [disabled]="loaderData"
              (keyup.tab)="autocompletarNroDoc()" (keyup.enter)="autocompletarNroDoc()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l1 xl1">
          <mat-form-field>
            <mat-label>Año Dua</mat-label>
            <input #iAnoDua min="0" type="number" matInput placeholder="Año Dua" [readonly]="disableAnoDua"
              [disabled]="loaderData" [(ngModel)]="registro.dpc_anodua" name="dpc_anodua"
              (keyup.enter)="establecerAnoDua()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3 xl2" *ngIf="configuracionTecnica.s72_indcpa == 1">

          <select-search [label]="'Condi. Pago'" [placeholder]="'Ej. CONTADO'" [name]="'CPA_CODCPA'"
            [value]="'CPA_CODCPA'" [description]="'CPA_DESABR'" [data]="condiciones" [disabled]="loaderData"
            [required]="true" (cambioSelect)="registro.cpa_codcpa = $event; seleccionarCondicionPago($event)"
            [model]="registro.cpa_codcpa">
          </select-search>

        </div>

        <div class="col s12 m12 l3 xl2">
          <mat-form-field>
            <mat-label>Fecha de Vcto.</mat-label>
            <input #iVcto [min]="registro.cco_fecemi" matInput [matDatepicker]="vcto" [disabled]="loaderData"
              [(ngModel)]="registro.cco_fecven" name="cco_fecven" required (dateChange)="cambiarFechaVcto($event)"
              (keyup.enter)="establecerVcto()">
            <mat-datepicker-toggle matSuffix [for]="vcto"></mat-datepicker-toggle>
            <mat-datepicker #vcto></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l2">
          <!-- <mat-form-field>
            <mat-label>Cta. Corriente Ref.</mat-label>
            <input [(ngModel)]="registro.cco_codcco" name="cco_codcco" readonly type="text" matInput
              placeholder="Cta. Corriente Ref." [disabled]="loaderData">
          </mat-form-field> -->

          <select-search [label]="'Cta. Corriente Ref.'" [placeholder]="'Ej. 401112'" [name]="'cuenta'" [value]="'cuenta_referencia'"
            [description]="'description'" [disabled]="loaderData" [data]="cuentasPorCobrar" [required]="true"
            (cambioSelect)="registro.cco_codref = $event" [model]="registro.cco_codref">
          </select-search>
        </div>

        <div class="col s12 m12 l12 xl5">
          <mat-form-field>
            <mat-label>Glosa</mat-label>
            <input #iGlosa type="text" matInput placeholder="Glosa" [disabled]="loaderData" required
              [(ngModel)]="registro.cco_glocco" name="cco_glocco" (keyup.enter)="establecerGlosa()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4 xl2" *ngIf="indDocReferencia == 1">
          <select-search [label]="'Tipo Doc. Ref'" [placeholder]="'Ej. FAC'" [name]="'TDO_CODTDO'"
            [value]="'TDO_CODTDO'" [disabled]="loaderData" [description]="'description'" [data]="tiposDocumentoRef"
            [required]="true" (cambioSelect)="registro.tdo_docref = $event; seleccionarTipoDocRef()"
            [model]="registro.tdo_docref">
          </select-search>
        </div>

        <div class="col s12 m12 l4 xl2" *ngIf="indDocReferencia == 1">
          <mat-form-field>
            <mat-label>Doc. Ref.</mat-label>
            <input #iDocRef required type="text" matInput placeholder="Doc. Ref." [disabled]="loaderData"
              [(ngModel)]="registro.cco_numref" name="cco_numref" (keyup.tab)="autocompletarNroDocRef()"
              (keyup.enter)="autocompletarNroDocRef(); calcularFechaReferencia()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4 xl2" *ngIf="indDocReferencia == 1">
          <mat-form-field>
            <mat-label>Fec. Ref.</mat-label>
            <input #iFecRef [max]="registro.cco_fecemi" required matInput [matDatepicker]="fecref"
              [disabled]="loaderData" [(ngModel)]="registro.cco_fecref" name="cco_fecref">
            <mat-datepicker-toggle matSuffix [for]="fecref"></mat-datepicker-toggle>
            <mat-datepicker #fecref></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4">
          <mat-form-field>
            <mat-label>Clasificación de bienes y servicios</mat-label>
            <mat-select #iClasificacion required [disabled]="loaderData" [(ngModel)]="registro.t30_codt30"
              name="t30_codt30" (selectionChange)="seleccionarClasificacion($event)">
              <mat-option *ngFor="let tipo of tiposBienServicio" [value]="tipo.t30_codt30"
                [matTooltip]="tipo.t30_dest30">
                {{tipo.t30_dest30}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6 xl3">

          <select-search [label]="'Tipo de Renta'" [placeholder]="'Ej. BIENES'" [name]="'t31_codt31'"
            [disabled]="loaderData" [value]="'t31_codt31'" [description]="'description'" [data]="tiposRenta"
            (cambioSelect)="registro.t31_codt31 = $event" [model]="registro.t31_codt31">
          </select-search>

        </div>

      </div>

    </mat-card>

    <br>

    <div class="row">

      <div class="col s12 m12 l6 xl6">
        <mat-tab-group>
          <mat-tab label="Importes" labelClass="mat-tab-label-0-0">
            <!-- <div class="pav-card-header green">
                <div class="pav-block"></div>
                <div class="pav-card-header-title">
                  Importes
                </div>
              </div> -->
            <mat-card class="mat-elevation-z0">
              <mat-card-content>

                <div class="row">
                  <div class="col s12 m4 l4">
                    <mat-form-field>
                      <mat-label>Afecto C/D</mat-label>
                      <input #iAfectoCD min="0" type="number" matInput placeholder="Afecto C/D" appDecimalPlaces
                        [(ngModel)]="registro.dpc_afcder" name="dpc_afcder" [disabled]="loaderData"
                        [ngStyle]="{textAlign: 'right'}" step="any">
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Afecto S/D</mat-label>
                      <input #iAfectoSD min="0" type="number" matInput placeholder="Afecto S/D" [disabled]="loaderData"
                        [ngStyle]="{textAlign: 'right'}" appDecimalPlaces [(ngModel)]="registro.dpc_afsder"
                        name="dpc_afsder" step="any">
                    </mat-form-field>

                    <mat-form-field *ngIf="registro.tdo_codtdo == 'dd'">
                      <mat-label>% Renta</mat-label>
                      <input #iRenta min="0" type="number" matInput placeholder="% Renta" appDecimalPlaces
                        [(ngModel)]="registro.dpc_tasren" name="dpc_tasren" [disabled]="loaderData"
                        [ngStyle]="{textAlign: 'right'}" step="any">
                    </mat-form-field>

                    <mat-form-field *ngIf="registro.tdo_codtdo == 'dd'">
                      <mat-label>Val Renta</mat-label>
                      <input #iValRenta min="0" type="number" matInput placeholder="Val Renta" [disabled]="loaderData"
                        appDecimalPlaces [(ngModel)]="registro.dpc_impren" name="dpc_impren" step="any"
                        [ngStyle]="{textAlign: 'right'}" (keyup)="calculaTotal()">
                    </mat-form-field>
                  </div>

                  <div class="col s12 m4 l4">
                    <mat-form-field>
                      <mat-label>Inafecto</mat-label>
                      <input #iInafecto min="0" appDecimalPlaces type="number" [ngStyle]="{textAlign: 'right'}"
                        [(ngModel)]="registro.cco_impina" (focus)="focusInput($event)"
                        [ngStyle]="{color: documentToUpload.xmlFile || documentToUpload.invoiceFile ? 'green': '', textAlign: 'right'}"
                        name="cco_impina" matInput placeholder="Inafecto" [disabled]="loaderData" step="any"
                        (keyup.enter)="establecerInafecto()">
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Afecto</mat-label>
                      <input #iAfecto min="0" appDecimalPlaces type="number" [ngStyle]="{textAlign: 'right'}"
                        [(ngModel)]="registro.cco_impafe" (focus)="focusInput($event)"
                        [ngStyle]="{color: documentToUpload.xmlFile || documentToUpload.invoiceFile ? 'green': '', textAlign: 'right'}"
                        name="cco_impafe" matInput placeholder="Afecto" [disabled]="loaderData" step="any"
                        (keyup.enter)="establecerAfecto()">
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Ajuste IGV</mat-label>
                      <input #iAjusteIgv appDecimalPlaces type="number" (focus)="focusInput($event)"
                        [ngStyle]="{textAlign: 'right'}" matInput placeholder="Aju IGV" [disabled]="loaderData"
                        [(ngModel)]="registro.dpc_difigv" name="dpc_difigv" step="any"
                        (keyup.enter)="establecerAjusteIGV()">
                    </mat-form-field>
                  </div>

                  <div class="col s12 m4 l4">

                    <mat-form-field>
                      <mat-label>Val. Compra</mat-label>
                      <input #iValCompra min="0" [value]="registro.valvta.toFixed(2)" [ngStyle]="{textAlign: 'right'}"
                        name="valCompra" type="number" matInput
                        [ngStyle]="{color: documentToUpload.xmlFile || documentToUpload.invoiceFile ? 'green': '', textAlign: 'right'}"
                        placeholder="Val. Compra" readonly [disabled]="loaderData" step="any">
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Imp. IGV</mat-label>
                      <input #iImpIgv min="0" [value]="registro.cco_impigv.toFixed(2)" [ngStyle]="{textAlign: 'right'}"
                        name="cco_impigv" type="number"
                        [ngStyle]="{color: documentToUpload.xmlFile || documentToUpload.invoiceFile ? 'green': '', textAlign: 'right'}"
                        matInput readonly placeholder="Imp. IGV" [disabled]="loaderData" (keyup)="calculaTotal()"
                        step="any">
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>TOTAL</mat-label>
                      <input #iTotal min="0" [value]="registro.cco_impdoc.toFixed(2)" [ngStyle]="{textAlign: 'right'}"
                        name="cco_impdoc" type="number"
                        [ngStyle]="{color: documentToUpload.xmlFile || documentToUpload.invoiceFile ? 'green': '', textAlign: 'right'}"
                        matInput readonly placeholder="TOTAL" [disabled]="loaderData" step="any">
                    </mat-form-field>
                  </div>

                </div>
              </mat-card-content>
            </mat-card>
          </mat-tab>
          <mat-tab label="Otros Impuestos" labelClass="mat-tab-label-0-1" class="tab-1">
            <otros-impuestos [registroCompra]="registro" [loaderData]="loaderData"
              (calcular)="registro.dpc_impsis = $event.dpc_impsis; registro.dpc_impfis = $event.dpc_impfis; calculaTotal()"></otros-impuestos>

          </mat-tab>
          <mat-tab label="Importaciones" labelClass="mat-tab-label-0-2">

            <mat-card class="mat-elevation-z0" color="primary">
              <mat-card-content>

                <div class="row">
                  <div class="col s12 m12 l3 xl3">
                    <mat-form-field>
                      <mat-label>FOB</mat-label>
                      <input #iFob min="0" type="number" matInput placeholder="FOB" appDecimalPlaces
                        [disabled]="loaderData" [readonly]="disableImportaciones" [(ngModel)]="registro.dpc_impfob"
                        [ngStyle]="{textAlign: 'right'}" name="dpc_impfob" step="any" (keyup)="calculaTotal()"
                        (keyup.enter)="establecerFob()">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m12 l3 xl3">
                    <mat-form-field>
                      <mat-label>Flete</mat-label>
                      <input #iFlete min="0" type="number" matInput placeholder="Flete" appDecimalPlaces
                        [disabled]="loaderData" [readonly]="disableImportaciones" [(ngModel)]="registro.dpc_impfle"
                        [ngStyle]="{textAlign: 'right'}" name="dpc_impfle" step="any" (keyup)="calculaTotal()"
                        (keyup.enter)="establecerFlete()">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m12 l3 xl3">
                    <mat-form-field>
                      <mat-label>Seguro</mat-label>
                      <input #iSeguro min="0" type="number" matInput placeholder="Seguro" appDecimalPlaces
                        [disabled]="loaderData" [readonly]="disableImportaciones" [(ngModel)]="registro.dpc_impseg"
                        [ngStyle]="{textAlign: 'right'}" name="dpc_impseg" step="any" (keyup)="calculaTotal()"
                        (keyup.enter)="establecerSeguro()">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m12 l3 xl3">
                    <mat-form-field>
                      <mat-label>CIF</mat-label>
                      <input #iCif min="0" type="number" matInput placeholder="CIF" [disabled]="loaderData" readonly
                        [ngStyle]="{textAlign: 'right'}" appDecimalPlaces [(ngModel)]="registro.dpc_impcif"
                        name="dpc_impcif" step="any">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m12 l3 xl3">
                    <mat-form-field>
                      <mat-label>Advalorem</mat-label>
                      <input #iAdvalorem min="0" type="number" matInput placeholder="Advalorem" appDecimalPlaces
                        [disabled]="loaderData" [readonly]="disableImportaciones" [(ngModel)]="registro.dpc_impadv"
                        [ngStyle]="{textAlign: 'right'}" name="dpc_impadv" step="any" (keyup)="calculaTotal()"
                        (keyup.enter)="establecerAdvalorem()">
                    </mat-form-field>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-tab>
          <mat-tab label="Distribución O/C" labelClass="mat-tab-label-0-3">
            <div class="pav-table-header-container">
              <div class="pav-table-header-icon">

              </div>
              <div class="pav-table-header-title">
                Distribución O/C
              </div>

              <div class="pav-separator">
                <button class="pav-btn-action-table" color="primary" matTooltip="Distribuir" type="button">
                  <mat-icon class="pav-icon-btn-table" svgIcon="distribute"></mat-icon>
                </button>
              </div>

              <div class="pav-table-search">
                <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
              </div>
              &nbsp;
              &nbsp;
              <div class="pav-table-header-add">
                <div class="isMobile">
                  <div class="pav-button-icon-add" (click)="agregarOrdenCompra()"
                    [ngStyle]="{display: loaderData? 'none': ''}">
                    <mat-icon svgIcon="add"></mat-icon>
                  </div>
                </div>
                <div class="isWeb">
                  <div class="pav-button-icon-add" [matTooltip]="'general.button.add' | translate"
                    (click)="agregarOrdenCompra()" [ngStyle]="{display: loaderData? 'none': ''}">
                    <mat-icon svgIcon="add-circle"></mat-icon>
                  </div>

                </div>
              </div>

            </div>

            <div class="mat-elevation-z0 b-r-15 overflow-x">
              <table mat-table [dataSource]="dataSource2" matSort>
                <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                  <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                    <div style="width: 100px;">

                      <button mat-icon-button color="primary" type="button" matTooltip="Eliminar Ítem"
                        (click)="eliminarOrdenCompra(row.ocm_numocm)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="ord_compra">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ord. Compra </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Ord. Compra"> {{row.ocm_numocm}} </td>
                </ng-container>

                <ng-container matColumnDef="fac_importacion">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fac. Importación </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Fac. Importación">
                    <ng-container>
                      <mat-form-field>
                        <mat-label>Fac. Importación</mat-label>
                        <input matInput placeholder="Fac. Importación" [(ngModel)]="row.cco_codcco"
                          [name]="'fac_imp_'+ i">
                      </mat-form-field>
                    </ng-container>
                    <ng-container>
                      {{row.cco_codcco}}
                    </ng-container>
                  </td>
                </ng-container>

                <ng-container matColumnDef="num_documento">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Num. Documento </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Num. Documento">
                    <ng-container>
                      <mat-form-field>
                        <mat-label>Num. Documento</mat-label>
                        <input matInput placeholder="Num. Documento" [(ngModel)]="row.oim_numoim"
                          [name]="'num_doc_'+ i">
                      </mat-form-field>
                    </ng-container>
                    <ng-container>
                      {{row.oim_numoim}}
                    </ng-container>
                  </td>
                </ng-container>

                <ng-container matColumnDef="monto">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Monto">
                    <ng-container>
                      <mat-form-field>
                        <mat-label>Monto</mat-label>
                        <input min="0" type="number" matInput placeholder="Monto" [(ngModel)]="row.occ_impdis"
                          [name]="'monto_'+ i">
                      </mat-form-field>
                    </ng-container>
                    <ng-container>
                      {{row.occ_impdis}}
                    </ng-container>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="6" *ngIf="!loaderOrdenes">No se encontraron registros</td>
                  <td class="mat-cell" colspan="6" *ngIf="loaderOrdenes">
                    <mat-spinner diameter="40"></mat-spinner>
                  </td>
                </tr>

              </table>

              <mat-paginator #paginator2 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="col s12 m12 l6 xl6">
        <mat-tab-group>
          <mat-tab label="Obligaciones Tributarias" labelClass="mat-tab-label-0-7">
            <mat-card class="mat-elevation-z0">

              <mat-card-content>

                <div class="row">
                  <div class="col s12 m12 l4">
                    <mat-form-field>
                      <mat-label>Tipo Retención</mat-label>
                      <mat-select #iTipoRetencion required [disabled]="loaderData" [(ngModel)]="registro.iri_codiri"
                        name="iri_codiri" (selectionChange)="cambiarTipoRetencion($event)">
                        <mat-option *ngFor="let tipo of tiposRetencion" [value]="tipo.IRI_CODIRI">
                          {{tipo.IRI_DESCRI}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col s12 m12 l4">

                    <select-search [label]="'Tipo Impuesto Detracción'" [placeholder]="'Ej. 0'" [value]="'codigo'"
                      [name]="'codigo'" [disabled]="loaderData" [description]="'descri'" [data]="tiposImpRetencion"
                      (cambioSelect)="registro.tim_codtim = $event; cambiarTipoImpuestoRetencion()"
                      [model]="registro.tim_codtim">
                    </select-search>

                  </div>
                  <div class="col s12 m12 l4">
                    <select-search [label]="'Tipo Operacion de Retención'" [placeholder]="'Ej. 0'"
                      [value]="'tsd_codtsd'" [name]="'tsd_codtsd'" [disabled]="loaderData" [description]="'tsd_descri'"
                      [data]="tiposOperacionRetencion">
                    </select-search>

                  </div>
                  <div class="col s12 m12 l3" *ngIf="!esPercepcion">
                    <mat-form-field>
                      <mat-label>% {{tituloTasa}}</mat-label>
                      <input #iTasa min="0" type="number" [ngStyle]="{textAlign: 'right'}" matInput
                        [placeholder]="'%' + tituloTasa" [(ngModel)]="registro.dpc_tasdet" name="dpc_tasdet" step="any">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m12 l3" *ngIf="!esPercepcion && registro.iri_codiri != '02'">
                    <mat-form-field>
                      <mat-label>Importe</mat-label>
                      <input appDecimalPlaces (focus)="focusInput($event)" #iImporte min="0" type="text" matInput
                        placeholder="Importe" [ngStyle]="{textAlign: 'right'}" [disabled]="loaderData"
                        [value]="registro.dpc_impdet? registro.dpc_impdet.toFixed(0) : '0'" name="dpc_impdet"
                        step="any">
                    </mat-form-field>
                  </div>

                  <div class="col s12 m12 l3" *ngIf="!esPercepcion && registro.iri_codiri == '02'">
                    <mat-form-field>
                      <mat-label>Importe Ret.</mat-label>
                      <input appDecimalPlaces (focus)="focusInput($event)" #iImporte min="0" type="text" matInput
                        placeholder="Importe" [ngStyle]="{textAlign: 'right'}" [disabled]="loaderData"
                        [value]="registro.dpc_impper? registro.dpc_impper.toFixed(2) : '0'" name="dpc_impdet"
                        step="any">
                    </mat-form-field>
                  </div>

                  <div *ngIf="esPercepcion" [ngClass]="esPercepcion?'col s12 m12':  'col s12 m12 l6'">
                    <mat-form-field>
                      <mat-label>Imp. Percepción</mat-label>
                      <input #iImpPercepcion min="0" type="number" [(ngModel)]="registro.dpc_impper"
                        [ngStyle]="{textAlign: 'right'}" name="DPC_IMPPER" matInput placeholder="Imp. Percepción"
                        [disabled]="loaderData" step="any" (keyup)="calculaTotal()">
                    </mat-form-field>
                  </div>

                  <div [ngClass]="esPercepcion?'col s12 m12 l3':  'col s12 m12 l3'" *ngIf="!esPercepcion">
                    <mat-form-field>
                      <mat-label>Depósito</mat-label>
                      <input appDecimalPlaces (focus)="focusInput($event)" #iDeposito min="0" type="number"
                        [ngStyle]="{textAlign: 'right'}" matInput placeholder="Depósito" [disabled]="loaderData"
                        [(ngModel)]="registro.dpc_depdet" name="dpc_depdet" step="any">
                    </mat-form-field>
                  </div>
                  <div [ngClass]="esPercepcion?'col s12 m12 l3':  'col s12 m12 l3'" *ngIf="!esPercepcion">
                    <mat-form-field>
                      <mat-label>Fecha</mat-label>
                      <input #iFecha matInput [min]="registro.cco_fecemi" [matDatepicker]="fechaRetencion"
                        [disabled]="loaderData" [(ngModel)]="registro.dpc_fecdet" name="dpc_fecdet">
                      <mat-datepicker-toggle matSuffix [for]="fechaRetencion"></mat-datepicker-toggle>
                      <mat-datepicker #fechaRetencion></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>

          </mat-tab>
          <mat-tab label="Total Contable" labelClass="mat-tab-label-0-6">

            <total-contable [montosDebeHaber]="montosDebeHaber"></total-contable>

          </mat-tab>
        </mat-tab-group>

      </div>

    </div>

    <br>

    <div class="row">
      <div class="col s12">
        <div class="mat-elevation-z0">
          <div class="pav-table-header-container">
            <div class="pav-table-header-icon">

            </div>
            <div class="" [ngStyle]="{marginRight: '20px', color: 'white'}">
              Detalle
            </div>

            <div class="pav-separator">
              <button class="pav-btn-action-table-genera-asiento" #btnGeneraAsiento color="primary"
                matTooltip="Generar Asiento" type="button" (click)="generarAsiento()" (keyup.enter)="generarAsiento()">
                <mat-icon class="pav-icon-btn-table" svgIcon="generate"></mat-icon>
              </button>
              <button class="pav-btn-action-table-ajuste" color="primary" matTooltip="Ajuste < 0.05" type="button"
                (click)="ajusteDiferencia()">
                <mat-icon class="pav-icon-btn-table" svgIcon="setting"></mat-icon>
              </button>
              <button class="pav-btn-action-table-borrar-destino" color="primary" matTooltip="Borrar Destinos"
                type="button" (click)="borrarAsientosDestino()">
                <mat-icon class="pav-icon-btn-table" svgIcon="document-error"></mat-icon>
              </button>
              <button class="pav-btn-action-table-cuenta-pagar" color="primary" matTooltip="Documentos por pagar"
                type="button" (click)="documentosPorPagar()">
                <mat-icon class="pav-icon-btn-table" svgIcon="money-donation-icon"></mat-icon>
              </button>
              <button class="pav-btn-action-table-copiar-item" color="primary" matTooltip="Copiar item" type="button"
                (click)="copiarItem()">
                <mat-icon class="pav-icon-btn-table" svgIcon="copy"></mat-icon>
              </button>

            </div>

            <div class="pav-table-search">
              <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
            </div>
            &nbsp;
            &nbsp;
            <div class="pav-button-icon-add" matTooltip="Agregar ítem" (click)="agregarItem()"
              [ngStyle]="{display: loaderData? 'none': ''}">
              <mat-icon svgIcon="add-circle"></mat-icon>
            </div>
            &nbsp;
            <div class="pav-button-icon-delete" matTooltip="Eliminar ítem" (click)="quitarItem()"
              [ngStyle]="{display: loaderData? 'none': ''}">
              <mat-icon svgIcon="remove"></mat-icon>
            </div>
            &nbsp;
            <div class="pav-button-icon-insert" matTooltip="Insertar ítem" (click)="insertarItem()"
              [ngStyle]="{display: loaderData? 'none': ''}">
              <mat-icon svgIcon="add-outline"></mat-icon>
            </div>
            <div class="pav-table-header-add">
              <!-- <div class="isMobiles">
                </div> -->

              <!-- <div class="isWeb">
                  <button [disabled]="loaderData" type="button" mat-flat-button class="pav-button-add-item"
                    (click)="agregarItem()">{{'general.button.add' | translate}}</button>
                </div> -->
            </div>
            <!-- <button #btnAdd mat-flat-button class="pav-btn-add-item" type="button" (click)="agregarItem()"
              (keyup.enter)="agregarItem()">Nuevo item</button> -->
          </div>

          <div class="overflow-x">
            <table mat-table [dataSource]="dataSource" matSort class="table-cell">

              <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                <td mat-cell *matCellDef="let row; let i = index" [style.color]="row.color" data-label="Acciones"
                  style="width: 100px">
                  <mat-icon svgIcon="quitar-item"
                    [ngStyle]="{cursor: 'pointer', height: '18px', width: '40px'}"></mat-icon>
                  <div class="pav-btns-container">

                  </div>

                </td>
              </ng-container>

              <ng-container matColumnDef="ldi_corldi">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> It. </th>
                <td mat-cell *matCellDef="let row; let ind = index" data-label="Código">

                  <mat-form-field style="width: 60px; padding-left: 12px !important"
                    [class.mat-form-field-row]="row === selected"
                    class="mat-form-field-table form-field-small form-field-cell">
                    <input [ngStyle]="{textAlign: 'right'}" readonly matInput placeholder="" [value]="row.ldi_corldi"
                      [name]="'ldi_corldi' + ind">
                  </mat-form-field>

                </td>
              </ng-container>

              <ng-container matColumnDef="ccn_codccn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Cuenta">
                  <div>
                    <select-search
                      [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                      [label]="''" [placeholder]="'Ej. TORRES'" [name]="'cuenta' + i" [value]="'cuenta'"
                      [description]="'description'" [data]="cuentasTotales" (cambioSelect)="row.ccn_codccn = $event"
                      [model]="row.ccn_codccn">
                    </select-search>
                  </div>

                </td>
              </ng-container>

              <ng-container matColumnDef="cli_codcli">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Auxiliar">
                  <div>

                    <select-search
                      [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                      [label]="''" [placeholder]="'Ej. GARCÍA'" [name]="'cli_codcli' + i" [value]="'cli_codcli'"
                      [description]="'description'" [disabled]="loaderData" [data]="auxiliares"
                      (cambioSelect)="registro.cli_codcli = $event" [model]="registro.cli_codcli">
                    </select-search>

                  </div>

                </td>
              </ng-container>

              <ng-container matColumnDef="ccs_codccs">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> C. Costo </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="C. Costo">
                  <select-search
                    [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                    [label]="''" [placeholder]="'Ej. 100001'" [name]="'ccs_codccs' + i" [value]="'ccs_codccs'"
                    [description]="'description'" [disabled]="loaderData" [data]="centros"
                    (cambioSelect)="row.ccs_codccs = $event" [model]="row.ccs_codccs">
                  </select-search>

                </td>
              </ng-container>

              <ng-container matColumnDef="tdo_codtdo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Doc.">
                  <select-search [label]="''" [placeholder]="'Ej. FAC'"
                    [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                    [name]="'TDO_CODTDO' + i" [value]="'TDO_CODTDO'" [disabled]="loaderData"
                    [description]="'description'" [data]="tiposDoc" [required]="false"
                    (cambioSelect)="row.tdo_codtdo = $event;" [model]="row.tdo_codtdo">
                  </select-search>

                </td>
              </ng-container>

              <ng-container matColumnDef="ldi_docref">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Nro. Doc.">
                  <mat-form-field [class.mat-form-field-row]="row === selected" style="width: 100px;"
                    class="mat-form-field-table form-field-small form-field-cell" [class.mat-focused]="row.focused">
                    <mat-label></mat-label>
                    <input matInput placeholder="Nro. Doc." [(ngModel)]="row.ldi_docref" (focus)="focusInput($event)"
                      (keydown.arrowup)="focusElement(i - 1, $event); $event.preventDefault()"
                      (keydown.arrowdown)="focusElement(i + 1, $event); $event.preventDefault()"
                      [name]="'nro_doc' + i.toString()">
                  </mat-form-field>

                </td>
              </ng-container>

              <ng-container matColumnDef="tmo_codtmo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Mon. </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Mon.">
                  <mat-form-field [class.mat-form-field-row]="row === selected" style="width: 50px;"
                    class="mat-form-field-table form-field-small form-field-cell">

                    <mat-select #iMoneda [disabled]="loaderData" [(ngModel)]="row.tmo_codtmo" name="tmo_codtmo"
                      [name]="'moneda' + i.toString()">
                      <mat-option value="SO">
                        S/
                      </mat-option>
                      <mat-option value="DO">
                        $
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                </td>
              </ng-container>

              <ng-container matColumnDef="ldi_tipcam">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> T. Cambio </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="T. Cambio">
                  <mat-form-field [class.mat-form-field-row]="row === selected" style="width: 70px;"
                    class="mat-form-field-table form-field-small form-field-cell">

                    <input [ngStyle]="{textAlign: 'right'}" min="0" type="number" step="any" matInput
                      placeholder="T. Cambio" [(ngModel)]="row.ldi_tipcam" [name]="'t_cambio' + i.toString()">
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="ldi_inddha">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> D/H </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="D/H" style="width: 100px;">
                  <mat-form-field [class.mat-form-field-row]="row === selected" style="width: 70px;"
                    class="mat-form-field-table form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="row.ldi_inddha" [name]="'dh' + i.toString()"
                      (selectionChange)="seleccionarDH($event.value)">
                      <mat-option value="D">
                        D
                      </mat-option>
                      <mat-option value="H">
                        H
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="ldi_impnac">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe MN </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Importe MN">
                  <mat-form-field [class.mat-form-field-row]="row === selected" style="width: 80px;"
                    class="mat-form-field-table form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input (focus)="focusInput($event)" [ngStyle]="{textAlign: 'right'}"
                      (keyup.enter)="calculoImportesMN(row)" min="0" type="number" matInput placeholder="Importe MN"
                      [(ngModel)]="row.ldi_impnac" [name]="'ldi_impnac' + i.toString()" step="any">
                  </mat-form-field>

                </td>
              </ng-container>

              <ng-container matColumnDef="ldi_impmex">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe ME </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Importe ME">
                  <mat-form-field [class.mat-form-field-row]="row === selected" style="width: 80px;"
                    class="mat-form-field-table form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input (focus)="focusInput($event)" [ngStyle]="{textAlign: 'right'}"
                      (keyup.enter)="calculoImportesME(row)" min="0" type="number" matInput placeholder="Importe ME"
                      appDecimalPlaces [(ngModel)]="row.ldi_impmex" [name]="'ldi_impmex' + i.toString()" step="any">
                  </mat-form-field>

                </td>
              </ng-container>

              <ng-container matColumnDef="ldi_gloldi">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Glosa">
                  <mat-form-field [class.mat-form-field-row]="row === selected"
                    class="mat-form-field-table form-field-small form-field-cell">
                    <mat-label></mat-label>
                    <input (focus)="focusInput($event)" type="text" matInput placeholder="Glosa"
                      [(ngModel)]="row.ldi_gloldi" [name]="'glosa' + i.toString()">
                  </mat-form-field>

                </td>
              </ng-container>

              <ng-container matColumnDef="cco_codcco">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Cte. </th>
                <td mat-cell *matCellDef="let row; let i = index" data-label="Cta. Cte.">
                  <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                    style="width: 130px">
                    <mat-label></mat-label>
                    <input (focus)="focusInput($event)" type="text" matInput placeholder="Cta. Cte."
                      [(ngModel)]="row.cco_codcco" [name]="'cco_codcco' + i.toString()" readonly>
                  </mat-form-field>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="row === selected"
                (click)="selectRow(row)"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="15">No se encontraron registros</td>
              </tr>

            </table>
          </div>

          <mat-paginator #paginator1 [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        </div>
      </div>
    </div>
  </div>

</form>
