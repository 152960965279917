import { Contact } from "../ventas/contact";
import { Establishment } from "../ventas/establishment";

export class Proveedor {
  cia_codcia: any;
  cli_codcli: string;
  cli_tipper: any;
  cli_nomcli: any;
  cli_apepat: any;
  cli_apemat: any;
  cli_nombre: any;
  cli_dircli: any;
  cli_discli: any;
  cli_indciv: any;
  cli_indsex: any;
  cli_numruc: any;
  cli_numdni: any;
  cli_numtlf: any;
  cli_numfax: any;
  t09_coddoc: any;
  cli_corele: any;
  cli_nomcon: any;
  cli_carcon: any;
  pai_codpai: any;
  ubi_codubi: any;
  zve_codzve: any;
  tlp_codtlp: any;
  gno_codgno: any;
  cli_indcli: any;
  cli_indpro: any;
  cli_indtra: any;
  cli_indgas: any;
  cli_indsta: string;
  cli_coduse: string;
  cli_fecupd: any;
  cli_indpnj: any;
  cli_nomcom: any;
  lde_dirfis: any;
  suc_codsuc: any;
  cli_inddom: any;
  cli_numcel: any;
  did_coddid: any;
  cli_refcpa: any;
  cli_impcre: any;
  tmo_codtmo: any;
  cli_doccom: any;
  cli_banco: any;
  cli_nrocta: any;
  cli_fecnac: any;
  cli_indper: any;
  cli_indrfp: any;
  afp_codafp: any;
  cli_comafp: any;
  cli_rutfot: any;
  cli_indrec: any;
  cli_codrlj: any;
  ubs_codubs: any;
  cli_indvin: any;
  cli_idecli: any;
  cli_excper: any;
  cli_ageper: any;
  cli_ageret: any;
  cli_indigv: any;
  cli_regnac: any;
  cli_inddis: any;
  cli_indind: any;
  cli_refcli: any;
  cli_indnue: any;
  dato_clc: CustomerComercial;
  detalle_lugar_despacho: Array<Establishment>;
  detalle_contacto: Array<Contact>;
  detalle_tipser_prov: Array<ServiceSupplier>;
  detalle_bancos_cbp: Array<BancosCBP>;
  detalle_dias_cds: any;
  detalle_almacen_cla: any;
  constructor(
  ) {
    this.detalle_lugar_despacho = [];
    this.detalle_tipser_prov = [];
    this.detalle_contacto = [];
    this.detalle_bancos_cbp = [];
    this.dato_clc = new CustomerComercial();

    this.cli_indcli = 0;
    this.cli_indpro = 1;
    this.cli_indtra = 0;
    this.cli_indgas = 0;
    this.cli_tipper = "J";
    this.did_coddid = "001";
    this.cli_fecupd = new Date();
    this.cli_fecnac = new Date();
    this.cli_indpnj = 0;
    this.cli_inddom = 1;
    this.cli_indvin = 0;
    this.cli_idecli = 22351;
    this.cli_indigv = 1;
    this.cli_indsta = "1";
    this.pai_codpai = "01";
  }

  static fromCONEFACTToProvider(proveedor: ProveedorCONEFACTSUNAT): Proveedor {
    const provider = new Proveedor();
    provider.cli_codcli = proveedor.numeroDocumento;
    provider.cli_numruc = proveedor.numeroDocumento;
    provider.cli_nomcli = proveedor.nombre;
    provider.cli_dircli = proveedor.direccion;
    provider.cli_nomcom = proveedor.comercial;
    provider.cli_numtlf = proveedor.telefono;

    return provider;
  }
}
export class CustomerComercial {
  CIA_CODCIA: any;
  CLI_CODCLI: string;
  TCL_CODTCL: any;
  ZVE_CODZVE: any;
  VDE_CODVDE: any;
  SEC_CODSEC: any;
  CCL_CODCCL: any;
  CLC_PORDES: any;
  CLC_DIAGRA: any;
  TMO_CODTMO: any;
  CLC_CREASI: any;
  CLC_FLAVEN: any;
  CLC_CODNEM: any;
  TLP_CODTLP: any;
  CLI_CODAVA: any;
  CLI_CODAV1: any;
  CPA_CODCPA: any;
  CLC_INDCOD: any;
  CLC_INDSTA: string;
  CLC_CODUSE: string;
  CLC_FECUPD: any;
  CLC_CODGRU: any;
  GCL_CODGCL: any;
  CLC_INOPED: any;
  TCC_CODTCC: any;
  CLI_INDPRD: any;
  CLC_INDUME: any;
  VDS_CODVDS: any;
  constructor(
  ) {
    this.CLC_INDSTA = '1';
    this.CLC_FECUPD = new Date();
    this.CLC_PORDES = 0;
    this.CLC_DIAGRA = 0;
    this.CLC_CREASI = 0;
    this.CLC_FLAVEN = 0;
    this.CLC_CODUSE = "";
    this.CLC_INOPED = 0;
    this.CLI_INDPRD = "";
    this.CLC_INDUME = 0;
    this.VDE_CODVDE = null;
  }
}

export class ServiceSupplier {
  cia_codcia: any;
  cli_codcli: string;
  tse_codtse: any;
  pts_indsta: string;
  pts_fecupd: any;
  pts_coduse: string;
  tse_descri: any;
  isEditing: boolean;
  constructor(
  ) {
    this.pts_indsta = '1';
    this.isEditing = true;
    this.pts_fecupd = new Date();
  }
}

export class BancosCBP {
  cbp_corcbp: string;
  ban_descri: string;
  tmo_codtmo: string;
  cbp_numcta: string;
  cbp_numcci: string;
  cbd_glocbp: any;
}

export class ProveedorCONEFACTSUNAT {
  cod: string;
  numeroDocumento: string;
  nombre: string;
  tipo: string;
  profesion: string;
  comercial: string;
  condicion: string;
  estado: string;
  fecha_insc: string;
  fecha_act: string;
  dpto: string;
  departamento: string;
  provincia: string;
  distrito: string;
  direccion: string;
  telefono: string;
  fax: string;
  com_exterior: string;
  ciiu_p: string;
  ciiu_s1: string;
  ciiu_s2: string;
  rus: string;
  buen_cont: string;
  reencion: string;
  percepcion_vi: string;
  percepcion_lc: string;
  ws: string



// tipoDocumento
// :
// "6"
// ubigeo
// :
// "150104"
// viaNombre
// :
// "ALMIRANTE GRAU"
// viaTipo
// :
// "AV."
// zonaCodigo
// :
// "URB."
// zonaTipo
// :
// "ALMIRANTE GRAU"
}
