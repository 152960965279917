import { Component, OnInit } from '@angular/core';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { abrirCtsAcumuladaPlanilla, abrirCtsPagarPlanilla } from '@utils/pdfmaker/builder/planilla.pdfmaker';

@Component({
  selector: 'app-reportes-cts',
  templateUrl: './reportes-cts.component.html',
  styleUrls: ['./reportes-cts.component.css']
})
export class ReportesCtsComponent implements OnInit {

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  constructor() {

  }

  ngOnInit(): void {
  }

  abrirCtsPagar(): void {
    const headers = {
      year: 'year',
      month: 'month',
      clientName: 'clientName',
      client: 'client',
      date: 'date',
      time: 'time',
      currency: 'currency',
      username: 'username',
      codePage: 'codePage',
    }

    abrirCtsPagarPlanilla(headers, null);
  }

  abrirCtsAcumulada(): void {
    const headers = {
      year: 'year',
      month: 'month',
      clientName: 'clientName',
      client: 'client',
      date: 'date',
      time: 'time',
      currency: 'currency',
      username: 'username',
      codePage: 'codePage',
    }
    abrirCtsAcumuladaPlanilla(headers, null);
  }
}
