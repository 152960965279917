<app-pavso-title-section [title]="'Registro de ventas'" [module]="'Contabilidad'" [usuario]="saleRecord.dpc_coduse" [fecha]="saleRecord.dpc_fecupd" [ngClass]="'pav-form'" [estado]="saleRecord.cco_indsta"></app-pavso-title-section>
<button (click)="nuevo()" color="primary" mat-mini-fab style="float: right;
  margin-top: -50px;
  margin-right: 10px;">
  <mat-icon>add</mat-icon>
</button>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarSeguimiento(f)" (keydown.enter)="$event.preventDefault()">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l1">
          <mat-form-field>
            <mat-label>Año</mat-label>
            <input #iAno matInput placeholder="Año" [(ngModel)]="saleRecord.ano_codano" name="ano_codano" disabled>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l1">
          <mat-form-field>
            <mat-label>Mes</mat-label>
            <input #iMes matInput placeholder="Mes" [(ngModel)]="saleRecord.mes_codmes" name="mes_codmes" disabled>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>Subdiario</mat-label>
            <mat-select #iSubdiario name="sco_codsco" [(ngModel)]="saleRecord.sco_codsco"
              (selectionChange)="seleccionarSubdiario($event)">
              <mat-option *ngFor="let subdiario of subdiarios" [value]="subdiario.sco_codsco">
                {{subdiario.sco_descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Fecha</mat-label>
            <input #iFecha maxlength="10" matInput [matDatepicker]="fecha" [disabled]="loaderData"
              [(ngModel)]="saleRecord.cco_fecreg" name="cco_fecreg" (dateChange)="cambiarFecha($event)"
              (keyup.enter)="establecerFecha()">
            <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
            <mat-datepicker #fecha></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Cta. Cte.</mat-label>
            <input type="text" matInput placeholder="Cta. Cte." name="cta_cte" required disabled>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l5">
          <select-search
            [label]="'Proveedor'"
            [placeholder]="'Ej. Torres'"
            [name]="'cli_codcli'"
            [value]="'cli_codcli'"
            [description]="'description'"
            [data]="proveedores"
            (cambioSelect)="saleRecord.cli_codcli = $event;"
            [model]="saleRecord.cli_codcli"
          ></select-search>

        </div>

        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Emisión</mat-label>
            <input #iEmision [max]="saleRecord.cco_fecreg" maxlength="10" matInput [matDatepicker]="emision"
              [disabled]="loaderData" [(ngModel)]="saleRecord.cco_fecemi" name="cco_fecemi"
              (dateChange)="cambiarFechaEmision($event)" (keyup.enter)="establecerEmision()">
            <mat-datepicker-toggle matSuffix [for]="emision"></mat-datepicker-toggle>
            <mat-datepicker #emision></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l1">
          <mat-form-field>
            <mat-label>T.C.</mat-label>
            <input #iTc min="0" type="number" matInput placeholder="T.C." [disabled]="loaderData"
              [(ngModel)]="saleRecord.cco_tipcam" name="cco_tipcam" step="any" (keyup.enter)="establecerTC()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l2">
          <mat-form-field>
            <mat-label>Vcto.</mat-label>
            <input #iVcto  [min]="saleRecord.cco_fecemi" maxlength="10" matInput [matDatepicker]="vcto"
              [disabled]="loaderData" [(ngModel)]="saleRecord.cco_fecven" name="cco_fecven"
              (dateChange)="cambiarFechaVcto($event)" (keyup.enter)="establecerVcto()">
            <mat-datepicker-toggle matSuffix [for]="vcto"></mat-datepicker-toggle>
            <mat-datepicker #vcto></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3 xl2">
          <mat-form-field>
            <mat-label>Moneda</mat-label>
            <mat-select #iMoneda required [disabled]="loaderData" [(ngModel)]="saleRecord.tmo_codtmo" name="tmo_codtmo"
              name="tmo_codtmo" (selectionChange)="seleccionarMoneda($event)">
              <mat-option value="SO">
                Soles
              </mat-option>
              <mat-option value="DO">
                Dólares
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>



        <div class="col s12 m12 l5">
          <select-search
            [label]="'Tipo de Operación'"
            [placeholder]="'Ej. tipo'"
            [name]="'tga_codtga'"
            [description]="'tga_descri'"
            [data]="tiposOperacion"
            (cambioSelect)="saleRecord.tga_codtga = $event"
            [value]="saleRecord.tga_codtga">
          </select-search>
        </div>

        <div class="col s12 m12 l3 xl1">
          <mat-form-field>
            <mat-label>% IGV</mat-label>
            <input #iIgv min="0" type="number" matInput placeholder="% IGV" disabled [(ngModel)]="saleRecord.cco_tasigv"
              name="cco_tasigv" step="any" (keyup.enter)="establecerIgv()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">

          <select-search
            [label]="'Tipo de Documento'"
            [placeholder]="'Ej. tipo'"
            [name]="'TDO_CODTDO'"
            [value]="'TDO_CODTDO'"
            [description]="'description'"
            [data]="tiposDoc"
            (cambioSelect)="saleRecord.tdo_codtdo = $event"
            [model]="saleRecord.tdo_codtdo">
          </select-search>

        </div>

        <div class="col s12 m12 l3 xl1">
          <mat-form-field>
            <mat-label>Serie</mat-label>
            <input #iSerie type="text" maxlength="4" matInput placeholder="Serie" [disabled]="loaderData"
              [(ngModel)]="saleRecord.sad_codsad" name="sad_codsad" (keyup.enter)="establecerSerie()">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4 xl2">
          <mat-form-field>
            <mat-label>Nro. Doc.</mat-label>
            <input #iNroDoc type="text" maxlength="13" matInput placeholder="Nro. Doc."
              [(ngModel)]="saleRecord.cco_numdoc" name="cco_numdoc" [disabled]="loaderData"
              (keyup)="autocompletarNroDoc()" (keyup.enter)="establecerNroDoc($event)">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l5">

          <select-search
            [label]="'Cuenta'"
            [placeholder]="'Ej. 42'"
            [name]="'cuenta'"
            [value]="'cuenta'"
            [description]="'description'"
            [data]="cuentas"
            (cambioSelect)="saleRecord.ccn_codccn = $event"
            [model]="saleRecord.ccn_codccn">
          </select-search>

        </div>

        <div class="col s12 m12 l12 xl7">
          <mat-form-field>
            <mat-label>Glosa</mat-label>
            <input #iGlosa type="text" matInput placeholder="Glosa" [disabled]="loaderData"
              [(ngModel)]="saleRecord.cco_glocco" name="cco_glocco" (keyup.enter)="establecerGlosa()">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl2" *ngIf="saleRecord.tdo_codtdo == 'NCR'">
          <mat-form-field>
            <mat-label>Tipo Doc. Ref.</mat-label>
            <mat-select #iTipoDocRef required [disabled]="loaderData" [(ngModel)]="saleRecord.tdo_docref"
              name="tdo_docref" (selectionChange)="seleccionarTipoDocRef($event)">
              <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.TDO_CODTDO">
                {{tipo.TDO_CODTDO}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl2" *ngIf="saleRecord.tdo_codtdo == 'NCR'">
          <mat-form-field>
            <mat-label>Doc. Ref.</mat-label>
            <input #iDocRef type="text" matInput placeholder="Doc. Ref." [disabled]="loaderData"
              [(ngModel)]="saleRecord.cco_numref" name="cco_numref">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl2" *ngIf="saleRecord.tdo_codtdo == 'NCR'">
          <mat-form-field>
            <mat-label>Fec. Ref.</mat-label>
            <input #iFecRef matInput [matDatepicker]="fecref" [disabled]="loaderData"
              [(ngModel)]="saleRecord.cco_fecref" name="cco_fecref">
            <mat-datepicker-toggle matSuffix [for]="fecref"></mat-datepicker-toggle>
            <mat-datepicker #fecref></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
    <div class="row">
      <div class="col s12 m12 l7">
        <mat-card style="padding: 0px;" color="primary">
          <h4 style="background-color: #164e7d; color: white; padding: 15px 20px; margin-bottom: 0px;">Importes</h4>

          <mat-card-content style="padding: 20px;">
            <div class="row">
              <div class="col s12 m12 l4">
                <mat-form-field>
                  <mat-label>Inafecto</mat-label>
                  <input #iInafecto min="0" type="number" [(ngModel)]="saleRecord.cco_impina" (keyup)="calculaTotal()"
                    name="cco_impina" matInput placeholder="Inafecto" [disabled]="loaderData" step="any"
                    (keyup.enter)="establecerInafecto()">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Ajuste IGV</mat-label>
                  <input #iAjusteIgv min="0" type="number" matInput placeholder="Aju IGV" [disabled]="loaderData"
                    [(ngModel)]="saleRecord.dpc_difigv" name="dpc_difigv" step="any">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <mat-form-field>
                  <mat-label>Afecto</mat-label>
                  <input #iAfecto min="0" type="number" [(ngModel)]="saleRecord.cco_impafe" (keyup)="calculaTotal()"
                    name="cco_impafe" matInput placeholder="Afecto" [disabled]="loaderData" step="any"
                    (keyup.enter)="establecerAfecto()">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Imp. IGV</mat-label>
                  <input #iImpIgv min="0" [(ngModel)]="saleRecord.cco_impigv" name="cco_impigv" type="number" matInput
                    placeholder="Imp. IGV" disabled (keyup)="calculaTotal()" step="any">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">
                <mat-form-field>
                  <mat-label>Val. Compra</mat-label>
                  <input #iValCompra min="0" [(ngModel)]="valCompra" name="valCompra" type="number" matInput
                    placeholder="Val. Compra" disabled="true" step="any">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>TOTAL</mat-label>
                  <input #iTotal min="0" [(ngModel)]="saleRecord.cco_impdoc" name="cco_impdoc" type="number" matInput
                    placeholder="TOTAL" disabled="true" step="any">
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col s12 m12 l5">
        <table mat-table [dataSource]="dataSource3" class="mat-elevation-z2">

          <ng-container matColumnDef="name" sticky>
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row" data-label="Código"> {{row.name}} </td>
          </ng-container>

          <ng-container matColumnDef="mn">
            <th mat-header-cell *matHeaderCellDef> Soles </th>
            <td mat-cell *matCellDef="let row" data-label="Soles"> {{row.mn}} </td>
          </ng-container>

          <ng-container matColumnDef="me">
            <th mat-header-cell *matHeaderCellDef> Dolares </th>
            <td mat-cell *matCellDef="let row" data-label="Dolares"> {{row.me}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns3; sticky"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No se encontraron registros</td>

          </tr>

        </table>
      </div>
    </div>
    <br>
    <div class="row" style="margin-bottom: 90px !important;">
      <div class="col s12">

        <button #btnAdd mat-icon-button color="primary" type="button" (click)="agregarItem()"
          (keyup.enter)="agregarItem()">
          <mat-icon>add</mat-icon>
        </button>
        &nbsp;
        <mat-form-field class="pavso_input_search">
          <mat-label>{{LABELS_NAME.BTN_SEARCH}}</mat-label>
          <input #code matInput [placeholder]="LABELS_NAME.BTN_SEARCH" (keyup)="applyFilter($event)">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>

        <div class="mat-elevation-z2 overflow-x">
          <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="ldi_corldi">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> It </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Código">

                <ng-container>
                  {{row.ldi_corldi}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="ccn_codccn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Cuenta">
                <ng-container *ngIf="row.isEditing">
                  <select-search
                    [label]="'Cuenta'"
                    [placeholder]="'Ej. 42'"
                    [name]="'cuenta' + i"
                    [value]="'cuenta'"
                    [description]="'description'"
                    [data]="cuentasTotales"
                    (cambioSelect)="row.ccn_codccn = $event"
                    [model]="row.ccn_codccn">
                  </select-search>

                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.ccn_codccn}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="cli_codcli">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Auxiliar">
                <ng-container *ngIf="row.isEditing">

                  <select-search
                    [label]="'Auxiliar'"
                    [placeholder]="'Ej. Torres'"
                    [name]="'cli_codcli' + i"
                    [value]="'cli_codcli'"
                    [description]="'cli_nomcli'"
                    [data]="auxiliares"
                    (cambioSelect)="row.cli_codcli = $event"
                    [model]="row.cli_codcli">
                  </select-search>

                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.cli_codcli}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="ccs_codccs">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> C. Costo </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="C. Costo">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field style="width: 100px;" class="mat-form-field-table">
                    <mat-label>C. Costo</mat-label>
                    <mat-select #costoSelect [(ngModel)]="row.ccs_codccs" [name]="'centro_costo' + i.toString()">
                      <mat-option *ngFor="let centro of centros" [value]="centro.ccs_codccs">
                        {{centro.ccs_codccs}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.ccs_codccs}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="tdo_codtdo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Doc.">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field style="width: 70px;" class="mat-form-field-table">
                    <mat-label>Doc.</mat-label>
                    <mat-select [(ngModel)]="row.tdo_codtdo" [name]="'doc' + i.toString()">
                      <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.TDO_CODTDO">
                        {{tipo.TDO_CODTDO}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.tdo_codtdo}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_docref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Nro. Doc.">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field style="width: 100px;" class="mat-form-field-table">
                    <mat-label>Nro. Doc.</mat-label>
                    <input matInput placeholder="Nro. Doc." [(ngModel)]="row.ldi_docref"
                      [name]="'nro_doc' + i.toString()">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.ldi_docref}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="tmo_codtmo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mon. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Mon.">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field style="width: 70px;" class="mat-form-field-table">
                    <mat-label>Moneda</mat-label>
                    <mat-select #iMoneda required [disabled]="loaderData" [(ngModel)]="row.tmo_codtmo" name="tmo_codtmo"
                      [name]="'moneda' + i.toString()">
                      <mat-option value="SO">
                        Soles
                      </mat-option>
                      <mat-option value="DO">
                        Dólares
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.tmo_codtmo}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_tipcam">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> T. Cambio </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="T. Cambio">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field style="width: 50px;" class="mat-form-field-table">
                    <mat-label>T. Cambio</mat-label>
                    <input min="0" type="number" matInput placeholder="T. Cambio" [(ngModel)]="row.ldi_tipcam"
                      [name]="'t_cambio' + i.toString()">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.ldi_tipcam}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_inddha">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> D/H </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="D/H" style="width: 100px;">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field style="width: 50px;" class="mat-form-field-table">
                    <mat-label>D/H</mat-label>
                    <mat-select [(ngModel)]="row.ldi_inddha" [name]="'dh' + i.toString()">
                      <mat-option value="D">
                        D
                      </mat-option>
                      <mat-option value="H">
                        H
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.ldi_inddha}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_impnac">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe MN </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Importe MN">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field style="width: 60px;" class="mat-form-field-table">
                    <mat-label>Importe MN</mat-label>
                    <input (keyup)="calculoImportesMN(row)" min="0" type="number" matInput placeholder="Importe MN"
                      [(ngModel)]="row.ldi_impnac" [name]="'importe_mn' + i.toString()" step="any">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.ldi_impnac}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_impmex">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe ME </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Importe ME">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field style="width: 60px;" class="mat-form-field-table">
                    <mat-label>Importe ME</mat-label>
                    <input (keyup)="calculoImportesME(row)" min="0" type="number" matInput placeholder="Importe ME"
                      [(ngModel)]="row.ldi_impmex" [name]="'importe_me' + i.toString()" step="any">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.ldi_impmex}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="ldi_gloldi">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Glosa">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field class="mat-form-field-table">
                    <mat-label>Glosa</mat-label>
                    <input type="text" matInput placeholder="Glosa" [(ngModel)]="row.ldi_gloldi"
                      [name]="'glosa' + i.toString()" (keyup.enter)="confirmarNuevoItem(row)">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.ldi_gloldi}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="cco_codcco">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Cte. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Cta. Cte." style="width: 100px">
                {{row.cco_codcco}}
              </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row; let i = index" [style.color]="row.color" data-label="Acciones"
                style="width: 100px">
                <div style="width: 100px;">
                  <mat-checkbox [(ngModel)]="row.isSelected" [name]="'seleccionable' + i" class="example-margin"
                    (click)="filaSeleccionada(row, $event)"></mat-checkbox>

                  <button *ngIf="!row.isEditing" mat-icon-button color="primary" type="button" matTooltip="Editar Ítem"
                    (click)="row.isEditing = !row.isEditing">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button *ngIf="row.isEditing" mat-icon-button color="primary" type="button"
                    matTooltip="Actualizar Ítem" (click)="confirmarNuevoItem(row)">
                    <mat-icon>done</mat-icon>
                  </button>
                  <button mat-icon-button color="primary" type="button" matTooltip="Eliminar Ítem"
                    (click)="quitarItem(row.ldi_corldi)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="15">No se encontraron registros</td>
            </tr>

          </table>

          <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>
      </div>
    </div>
    <br>
    <mat-card class="mat-elevation-z4 pavso-bottom-actions" align="end">
      <div class="row">
        <div class="col s12 m12 l8 xl6">

          <button #btnGeneraAsiento mat-mini-fab color="primary" matTooltip="Generar Asiento" type="button"
            (click)="generarAsiento()" (keyup.enter)="generarAsiento()">
            <mat-icon>show_chart</mat-icon>
          </button>
          &nbsp;
          <button mat-mini-fab color="primary" matTooltip="Ajuste < 0.05" type="button" (click)="ajusteDiferencia()">

            <mat-icon>miscellaneous_services</mat-icon>
          </button>
          &nbsp;
          <input #xmlInput hidden="true" type="file" onclick="this.value=null" (change)="xmlInputChange($event)"
            accept="text/xml" />

          <button mat-mini-fab color="primary" matTooltip="Subir XML" type="button" (click)="xmlInput.click()">
            XML
          </button>
        </div>
        <div class="col s12 m12 l4 xl6" align="end">
          <mat-spinner diameter="40" *ngIf="loaderReg"></mat-spinner>
          <button class="pav-button-cancel" *ngIf="!loaderReg" mat-flat-button (click)="volver()" type="button">
            {{LABELS_NAME.BTN_BACK | translate}}
          </button>
          &nbsp;
          <button class="pav-button-action" *ngIf="!loaderReg" mat-flat-button color="primary" type="submit">
            {{btnName | translate}}
          </button>
        </div>
      </div>
    </mat-card>

  </form>
</div>
