import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { CuentaPagarDialogComponent } from '@shared/components/dialogs/cuenta-por-pagar/cuenta-pagar-dialog/cuenta-pagar-dialog.component';
import { Subscription } from 'rxjs';
import { AsientoContable } from 'src/app/models';
import { DetalleAsientoContable, DetalleLDI } from 'src/app/models/contabilidad/asiento_contable';
import { AuthenticationService, ConfigurationService, ContabilidadService, ComprasService, AlmacenService, VentasService } from 'src/app/services';
import { formatDateWithDash } from 'src/app/utils/formats/date.format';
import { roundToDecimals } from 'src/app/utils/formats/round.format';
import { orderKeyStringJSON } from 'src/app/utils/sorts/sort';
import { validarPeriodo } from 'src/app/utils/validations/period';
import { CuentaCobrarDialogComponent } from '@shared/components/dialogs/cuenta-por-pagar/cuenta-cobrar-dialog/cuenta-cobrar-dialog.component';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable, searchInTable } from '@utils/tables/table';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { forkObs } from '@utils/observables/fork';
import { TipoOperacionService } from 'src/app/services/api/contabilidad/maestros/tipo-operacion.service';

@Component({
  selector: 'app-conciliacion-bancaria-general-contabilidad',
  templateUrl: './conciliacion-bancaria-general-contabilidad.component.html',
  styleUrls: ['./conciliacion-bancaria-general-contabilidad.component.css']
})
export class ConciliacionBancariaGeneralContabilidadComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'ldi_corldi', 'ind', 'cli_codcli', 'cco_codcco', 'tdo_codtdo', 'ldi_docref', 'dias', 'fec_emision','fec_vencimiento', 'ccn_codccn', 'ccs_codccs', 'tmo_codtmo', 'ldi_tipcam', 'ldi_impnac', 'ldi_impmex', 'ldi_inddha', 'ldi_gloldi', 'ret', 'imp_ret'];
  dataSource: MatTableDataSource<any>;

  @ViewChild('paginator1') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns3: string[] = ['name', 'mn', 'me'];
  dataSource3: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator3: MatPaginator;
  @ViewChild(MatSort) sort3: MatSort;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  fecha: Date = new Date();

  year: string;
  month: string;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  asientoContable: AsientoContable;

  subsidiarios: any[] = [];
  cuentasContable: any[] = [];
  cuentasContablesTotales: any[] = [];
  tiposBienServicio: any[] = [];
  tiposImpuesto: any[] = [];
  tiposDocumento: any[] = [];
  monedas: any[] = [];
  ordenesCompra: any[] = [];
  ordenesCompraTotales: any[] = [];
  vouchers: any[] = [];
  centros: any[] = [];
  tiposRetencion: any[] = [];
  tiposImpRetencion: any[] = [];
  tempTiposImpRetencion: any[] = [];
  // condicionesPago: any[] = [];
  signosctacte: any[] = [];

  /** filtrar cuentas totales */
  cuentasTotales: any[] = [];

  /** filtrar cuentas */
  cuentas: any[] = [];

  /** filtrar proveedores */
  proveedores: any[] = [];

  /** filtrar proveedores */
  auxiliares: any[] = [];

  /** filtrar tipos de operación */
  tiposOperacion: any[] = [];

  /** filtrar tipos de renta */
  tiposRenta: any[] = [];

  /** filtrar tipos de renta */
  condiciones: any[] = [];

  /** filtrar tipos de documento */
  tiposDoc: any[] = [];

  @ViewChild('iAno') anoElement: ElementRef;
  @ViewChild('iMes') mesElement: ElementRef;
  @ViewChild('iSubdiario') subdiarioElement: MatSelect;
  @ViewChild('iFecha') fechaElement: ElementRef;
  @ViewChild('iTc') tcElement: ElementRef;
  @ViewChild('iGlosa') glosaElement: ElementRef;
  @ViewChild('iProveedor') proveedorElement: MatSelect;
  @ViewChild('iMoneda') monedaElement: MatSelect;

  @ViewChild('btnAdd') buttonAddRefElement: MatButton;
  @ViewChild('btnGeneraAsiento') buttonGeneraAsientoRefElement: MatButton;

  isNew: boolean = true;

  subdiarios$: Subscription;
  tipoOperacion$: Subscription;
  cuentas$: Subscription;
  tiposBienServicio$: Subscription;
  tiposRenta$: Subscription;
  tiposImpuesto$: Subscription;
  tiposDocumento$: Subscription;
  proveedor$: Subscription;
  moneda$: Subscription;
  ordenesCompra$: Subscription;
  condiciones$: Subscription;
  voucher$: Subscription;
  centro$: Subscription;
  auxiliar$: Subscription;
  tipoRetencion$: Subscription;
  tipoImpRetencion$: Subscription;
  loading$: Subscription;

  permision: any= {ind_Nuevo: '0'};

  @ViewChildren("cuentaTotalSelect") selectCuentas: QueryList<MatSelect>;
  @ViewChildren("descripcionInput") inputDescripcion: QueryList<ElementRef>;
  @ViewChildren("costoSelect") selectCosto: QueryList<MatSelect>;

  montosDebeHaber: any;

  btnName: string;

  @ViewChild('iNroDoc') nroDocElement: ElementRef;
  @ViewChild('iSerie') serieElement: ElementRef;

  period$: Subscription;

  constructor(
    private _dialogService: DialogService,
    private _contabilidadService: ContabilidadService,
    private _tipoOperacionService: TipoOperacionService,
    private _almacenService: AlmacenService,
    private _clienteService: ClienteService,
    private _comprasService: ComprasService,
    private _ventasService: VentasService,
    private _configurationService: ConfigurationService,
    private _authService: AuthenticationService,
    private _router: Router,
    private _snackBarService: SnackBarService,
    public dialog: MatDialog,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute
  ) {
    this.asientoContable = new AsientoContable();


    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.asientoContable.ANO_CODANO = year;
      this.month = month;
      this.asientoContable.MES_CODMES = month;
    })

    this.montosDebeHaber = [
      {
        name: "DEBE",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "HABER",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "DIFERENCIA",
        mn: 0.00,
        me: 0.00
      }
    ];

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource3 = fillTable(this.montosDebeHaber, this.paginator3, this.sort3);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  {
        this.loadData();
        this.setup();
      }
    })
  }

  establecerCamposPorDefectoEnNuevo(): void {
    const now = new Date();

    if(this.year == now.getFullYear().toString() && this.month == (now.getMonth() + 1).toString()) {
      // this.asientoContable.LDC_FECREG = formatDateWithDash(new Date());
    } else {
      // this.asientoContable.LDC_FECREG = formatDateWithDash(new Date(parseInt(this.year), parseInt(this.month), 0));
    }

    this.asientoContable.detalle_ldi = [];
    // this.asientoContable.ordenes = [];

    this.obtenerTasaCambio();
  }

  calculoImportesMN(row): void {}

  calculoImportesME(row): void {}

  filaSeleccionada(row, event):void {
    this.asientoContable.detalle_ldi.forEach(element => {
      if(element.LDI_CORLDI != row.ldi_corldi) {
        // element.isSelected = false;
      }
    });
  }

  setup() {
    this._activatedRoute.params.subscribe(
      ({id}) => {
        this.btnName = id == '0'? NAMES_CONSTANTS.BTN_STORE: NAMES_CONSTANTS.BTN_UPDATE

      }
    )
  }

  obtenerTasaCambio(): void {

    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.asientoContable.LDC_FECDIA)).subscribe(
      response => this.asientoContable.LDC_TIPCAM = response[0].tipcam,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  loadData(): void {
    this.loaderData = true;

    forkObs(
      this._configurationService.getUserPermisions(),
      this._contabilidadService.listarSignoCtaCte(),
      this._comprasService.obtenerCondicionesPago(),
      this._contabilidadService.obtenerSubdiarios(),
      this._tipoOperacionService.obtenerTiposOperacion(),
      this._contabilidadService.listarPlanDeCuentas(),
      this._contabilidadService.obtenerTiposDocumento(),
      this._contabilidadService.listarCentroCostos(),
      this._clienteService.obtenerClientes(),
      this._comprasService.listarTiposRetencion(),
      this._comprasService.listarTiposImpuestoRetencion()
    ).then(data => {
      this.permision = data[0].find(element => element.usuario == this._authService.getUsuarioSistema().toString().toUpperCase());
      this.signosctacte = data[1];
      this.condiciones = data[2];
      this.subsidiarios = data[3].filter(element => element.sco_indcom == 1);
      this.tiposOperacion = data[4];
      this.cuentasTotales = data[5];
      this.cuentasContable = data[5].filter( cuenta=>cuenta.ind_ctacte == "P" );
      this.cuentas = data[5];
      this.tiposDoc = data[6].filter(item => item.indcom == 1);
      this.tiposDoc = data[6].sort(orderKeyStringJSON("TDO_CODTDO"));
      this.centros = data[7];
      this.auxiliares = data[8].filter(cliente => cliente.cli_indtra);
      this.proveedores = data[8].filter(cliente => cliente.cli_indpro);
      this.tiposRetencion = data[9];
      this.tiposImpRetencion = data[10];
      this.tempTiposImpRetencion = data[10];
      this.loaderData = false;
      this.establecerCamposPorDefectoEnNuevo();
    }).catch(err => {
       this._snackBarService.showError("Error al obtener maestros", "OK")
    })

  }

  agregarItem(): void {
    let detailItem = new DetalleLDI();
    detailItem.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    detailItem.ANO_CODANO = this.year;
    detailItem.MES_CODMES = this.month;
    detailItem.LDI_CORLDI = `0000${this.asientoContable.detalle_ldi.length + 1}`;

    detailItem.TMO_CODTMO = "SO";
    detailItem.LDI_INDDHA = "D";
    detailItem.TDO_CODTDO = "FAC";
    detailItem.LDI_IMPNAC = 0;
    detailItem.LDI_IMPMEX = 0;
    detailItem.LDI_TCAMEU = 0;
    detailItem.LDI_INDDES = 0;
    detailItem.fca_codfca = "";
    detailItem.ldi_anoccl = this.year;
    detailItem.ldi_mesccl = this.month;
    // detailItem.LDI_FECEMI = this.asientoContable.cco_fecemi;
    // detailItem.LDI_FECVEN = this.asientoContable.cco_fecven;
    detailItem.ldi_impsal = 0;
    detailItem.ldi_indccl = 0;
    detailItem.LDI_INDCCO = "N";
    detailItem.LDI_INDDES = 0;
    detailItem.ldi_mesccl = "";
    detailItem.SCO_CODSCO = this.asientoContable.SCO_CODSCO;
    detailItem.SUC_CODSUC = "01";
    detailItem.TDO_DOCREF = "";
    detailItem.VDE_CODVDE = "";
    detailItem.LDI_TIPCAM = this.asientoContable.LDC_TIPCAM;
    detailItem.LDI_GLOLDI = this.asientoContable.LDC_GLODIA;

    this.asientoContable.detalle_ldi.push(detailItem);
    this.dataSource = fillTable(this.asientoContable.detalle_ldi, this.paginator, this.sort);

    this.asientoContable.detalle_ldi.forEach((element, key) => {
      element.LDI_CORLDI = (key > 9)?`000${key + 1}`: `0000${key + 1}`;
    })
    setTimeout(() => {
      // this.cuentaTotalSelect.open();
      this.selectCuentas.last.open();
      this.selectCuentas.last.focus();
    }, 1);

  }

  registrarAsientoContable(f: NgForm): void {
    if(validarPeriodo(this.fecha, this.year, this.month)) {
      this._dialogService.openDialog(SuccessComponent, 'Asiento Contable Registrado', '300px', '', '');
      return;
    }

    this._snackBarService.showError('La Fecha de Registro Debe de Coincidir con la Fecha de Actividad', 'OK');

  }

  seleccionarSubdiario(event): void {
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.fechaElement.nativeElement.focus();
      this.fechaElement.nativeElement.select();
    },1);

  }

  cambiarFecha(event): void {
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.tcElement.nativeElement.focus();
      this.tcElement.nativeElement.select();
    },1);

  }

  establecerFecha(): void {
    setTimeout(() => {
      this.tcElement.nativeElement.focus();
      this.tcElement.nativeElement.select();
    }, 1);
  }

  establecerTC(): void {
    setTimeout(() => {
      this.glosaElement.nativeElement.focus();
      this.glosaElement.nativeElement.select();
    }, 1);
  }

  establecerAno(): void {
    setTimeout(() => {
      this.mesElement.nativeElement.select();
    }, 1);
  }

  establecerMes(): void {
    setTimeout(() => {
      this.subdiarioElement.open();
      this.subdiarioElement.focus();
    }, 1);
  }

  establecerGlosa(): void {
    setTimeout(() => {
      this.buttonGeneraAsientoRefElement.focus();
    }, 1);
  }

  applyFilter(event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  copiarAsiento(): void {
    // const dialogRef = this.dialog.open(CopiarAsientoComponent, {
    //   width: '250px',
    //   data: {},
    // });

    // dialogRef.afterClosed().subscribe(result => {});
  }

  confirmarNuevoItem(item: DetalleAsientoContable): void {
    if(
      item.ccn_codccn &&
      item.tdo_codtdo &&
      item.ldi_docref &&
      item.tmo_codtmo &&
      item.ldi_inddha &&
      item.ldi_impnac &&
      item.ldi_impmex &&
      item.ldi_gloldi
      ) {
      item.isEditing = !item.isEditing;
      this.montosDebeHaber[0].mn = 0;
      this.montosDebeHaber[0].me = 0;
      this.montosDebeHaber[1].mn = 0;
      this.montosDebeHaber[1].me = 0;
      this.montosDebeHaber[2].mn = 0;
      this.montosDebeHaber[2].me = 0;

      this.asientoContable.detalle_ldi.forEach(element => {
        if(element.LDI_INDDHA == "D") {
          this.montosDebeHaber[0].mn += element.LDI_IMPNAC;
          this.montosDebeHaber[0].me += element.LDI_IMPMEX;
        } else {
          this.montosDebeHaber[1].mn += element.LDI_IMPNAC;
          this.montosDebeHaber[1].me += element.LDI_IMPMEX;
        }
      });

      this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
      this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

      this.buttonAddRefElement.focus();
    } else {
      this._snackBarService.showError("Es necesario completar todos los campos", "OK");
    }
  }

  quitarItem(id): void {
    let details = [];
    this.asientoContable.detalle_ldi.forEach(element => {
      if(element.LDI_CORLDI != id) {
        details.push(element)
      }
    });

    this.asientoContable.detalle_ldi = details;
    this.dataSource = fillTable(details, this.paginator, this.sort);

    this.asientoContable.detalle_ldi.forEach((element, key) => {
      element.LDI_CORLDI = `0000${key + 1}`;
    });
  }

  ajusteDiferencia(): void {
    const diferencia_mn =  this.dataSource3.data[2].mn;
    const diferencia_me =  this.dataSource3.data[2].me;
    console.table({diferencia_mn, diferencia_me})
    if(Math.abs(diferencia_mn) <= 0.05 && diferencia_mn > 0 && Math.abs(diferencia_me) <= 0.05 && diferencia_me > 0) {
      let ps_tmo_mn;
      let pn_impnac;
      let pn_impmex;
      let ps_codtdo;
      let ps_codccs;
      let ps_cuenta;
      let ls_cad;

      (Math.abs(diferencia_mn) != 0)? ps_tmo_mn = "SO": ps_tmo_mn = "DO";

      pn_impnac = Math.abs(diferencia_mn);
      pn_impmex = Math.abs(diferencia_me);

      ps_codtdo = (diferencia_mn < 0 || diferencia_me < 0)? "AJP": "AJP";
      ps_codccs = "";

      this._contabilidadService.configuraTipoDocCtaContable(ps_codtdo).subscribe(
        response => {

          ps_cuenta = response[0].ccn_codmn;
          ps_codccs = response[0].ccs_codccs;

          if(ps_cuenta) {

            let detailItem = new DetalleLDI();

            detailItem.LDI_INDDHA = "D";
            detailItem.LDI_TCAMEU = 0;
            detailItem.LDI_INDDES = 0;
            detailItem.fca_codfca = "";
            detailItem.ldi_impsal = 0;
            detailItem.ldi_indccl = 0;
            detailItem.LDI_INDCCO = "N";
            detailItem.LDI_INDDES = 0;
            detailItem.ldi_mesccl = "";
            detailItem.SCO_CODSCO = this.asientoContable.SCO_CODSCO;
            detailItem.SUC_CODSUC = "01";
            detailItem.TDO_DOCREF = "";
            detailItem.VDE_CODVDE = "";

            detailItem.CCN_CODCCN = ps_cuenta;
            detailItem.LDI_GLOLDI = this.asientoContable.LDC_GLODIA;
            detailItem.TMO_CODTMO = ps_tmo_mn;
            detailItem.TDO_CODTDO = ps_codtdo;
            detailItem.LDI_DOCREF = this.asientoContable.LDC_CORLDC;
            detailItem.LDI_TIPCAM = roundToDecimals(pn_impmex / pn_impnac, 3);
            detailItem.LDI_INDDHA = (ps_codtdo == "AJP")? "D": "H";
            detailItem.LDI_IMPNAC = roundToDecimals(pn_impnac, 2);
            detailItem.LDI_IMPMEX = roundToDecimals(pn_impmex, 2);
            detailItem.LDI_INDCCO = "N";



            if(ps_codtdo == "AJP" && ps_codccs > 0) {
              detailItem.CCS_CODCCS = ps_codccs;
            }

            this.asientoContable.detalle_ldi.push(detailItem);

            this.dataSource = fillTable(this.asientoContable.detalle_ldi, this.paginator, this.sort);

            this.asientoContable.detalle_ldi.forEach((element, key) => {
              element.LDI_CORLDI = (key > 9)?`000${key + 1}`: `0000${key + 1}`;
            })

            setTimeout(() => {
              this.inputDescripcion.last.nativeElement.focus();
              this.inputDescripcion.last.nativeElement.slect();
            }, 1);

            this.montosDebeHaber[0].mn = 0;
            this.montosDebeHaber[0].me = 0;
            this.montosDebeHaber[1].mn = 0;
            this.montosDebeHaber[1].me = 0;
            this.montosDebeHaber[2].mn = 0;
            this.montosDebeHaber[2].me = 0;

            this.asientoContable.detalle_ldi.forEach(element => {
              if(element.LDI_INDDHA == "D") {
                this.montosDebeHaber[0].mn += element.LDI_IMPNAC;
                this.montosDebeHaber[0].me += element.LDI_IMPMEX;
              } else {
                this.montosDebeHaber[1].mn += element.LDI_IMPNAC;
                this.montosDebeHaber[1].me += element.LDI_IMPMEX;
              }
            });

            this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
            this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;
          }
        },
        error => this._snackBarService.showError(error.error.msg, "OK")
      )
    } else {
      this._snackBarService.showError("La diferencia tiene que ser > 0 y <= a 0.05", "OK");
    }

    // if(this.purchaseRecord.tot)
  }

  borrarAsientosDestino(): void {
    // this.purchaseRecord.detalle = [];
    let detalleNuevo = [];
    this.asientoContable.detalle_ldi.forEach(item => {
      if(item.LDI_INDDES != 1) {
        detalleNuevo.push(item);
      }
    });
    this.asientoContable.detalle_ldi = detalleNuevo;
  }

  documentosPorPagar(): void {
    const dialogRef = this.dialog.open(CuentaPagarDialogComponent, {
      width: '1000px',
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {

        result.forEach(item => {
          let detailItem = new DetalleLDI();

          detailItem.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
          detailItem.ANO_CODANO = this.year;
          detailItem.MES_CODMES = this.month;
          detailItem.LDI_CORLDI = `0000${this.asientoContable.detalle_ldi.length + 1}`;
          detailItem.TMO_CODTMO = item.moneda;
          detailItem.LDI_INDDHA = "D";
          detailItem.TDO_CODTDO = item.tip_doc;
          detailItem.LDI_IMPNAC = (item.moneda == "SO")? item.importe_saldo: item.importe_saldo / item.tipo_cambio;
          detailItem.LDI_IMPMEX = (item.moneda == "SO")? item.importe_saldo * item.tipo_cambio: item.importe_saldo;
          detailItem.LDI_TCAMEU = 0;
          detailItem.LDI_INDDES = 0;
          detailItem.fca_codfca = "";
          detailItem.ldi_anoccl = item.ano;
          detailItem.ldi_mesccl = item.mes;
          detailItem.LDI_FECEMI = item.fec_emision;
          detailItem.LDI_FECVEN = item.fec_vencimiento;
          detailItem.ldi_impsal = 0;
          detailItem.ldi_indccl = 0;
          detailItem.LDI_INDCCO = "N";
          detailItem.LDI_INDDES = 0;
          detailItem.ldi_mesccl = "";
          detailItem.SCO_CODSCO = item.subdiario;
          detailItem.SUC_CODSUC = "01";
          detailItem.TDO_DOCREF = "";
          detailItem.VDE_CODVDE = "";
          detailItem.CCO_CODCCO = item.cta_cte;
          detailItem.CCN_CODCCN = item.cuenta;
          detailItem.CLI_CODCLI = item.auxiliar;
          detailItem.LDI_DOCREF = item.nro_docu;
          detailItem.LDI_TIPCAM = item.tipo_cambio;
          detailItem.LDI_GLOLDI = item.glosa;

          this.asientoContable.detalle_ldi.push(detailItem);
          this.dataSource = fillTable(this.asientoContable.detalle_ldi, this.paginator, this.sort);

          setTimeout(() => {
            this.selectCosto.first.open();
          }, 1);
        });

      }
    })
  }

  documentosPorCobrar(): void {
    const dialogRef = this.dialog.open(CuentaCobrarDialogComponent, {
      width: '1000px',
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result) {

        result.forEach(item => {
          let detailItem = new DetalleLDI();

          detailItem.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
          detailItem.ANO_CODANO = this.year;
          detailItem.MES_CODMES = this.month;
          detailItem.LDI_CORLDI = `0000${this.asientoContable.detalle_ldi.length + 1}`;
          detailItem.TMO_CODTMO = item.moneda;
          detailItem.LDI_INDDHA = "D";
          detailItem.TDO_CODTDO = item.tip_doc;
          detailItem.LDI_IMPNAC = (item.moneda == "SO")? item.importe_saldo: item.importe_saldo / item.tipo_cambio;
          detailItem.LDI_IMPMEX = (item.moneda == "SO")? item.importe_saldo * item.tipo_cambio: item.importe_saldo;
          detailItem.LDI_TCAMEU = 0;
          detailItem.LDI_INDDES = 0;
          detailItem.fca_codfca = "";
          detailItem.ldi_anoccl = item.ano;
          detailItem.ldi_mesccl = item.mes;
          detailItem.LDI_FECEMI = item.fec_emision;
          detailItem.LDI_FECVEN = item.fec_vencimiento;
          detailItem.ldi_impsal = 0;
          detailItem.ldi_indccl = 0;
          detailItem.LDI_INDCCO = "N";
          detailItem.LDI_INDDES = 0;
          detailItem.ldi_mesccl = "";
          detailItem.SCO_CODSCO = item.subdiario;
          detailItem.SUC_CODSUC = "01";
          detailItem.TDO_DOCREF = "";
          detailItem.VDE_CODVDE = "";
          detailItem.CCO_CODCCO = item.cta_cte;
          detailItem.CCN_CODCCN = item.cuenta;
          detailItem.CLI_CODCLI = item.auxiliar;
          detailItem.LDI_DOCREF = item.nro_docu;
          detailItem.LDI_TIPCAM = item.tipo_cambio;
          detailItem.LDI_GLOLDI = item.glosa;

          this.asientoContable.detalle_ldi.push(detailItem);
          this.dataSource = fillTable(this.asientoContable.detalle_ldi, this.paginator, this.sort);

          setTimeout(() => {
            this.selectCosto.first.open();
          }, 1);
        });

      }
    })
  }

  seleccionarTipoDoc(event): void {
    let valor;

    if(event) {
      valor = event.value;
      // let tipo = this.tiposDoc.find(item => item.TDO_CODTDO == valor);


      this._almacenService.listarSerieDocumentos(valor).subscribe(
        series => {
          if(series && series.length > 0) {
            // this.asientoContable.cco_numdoc = `${series[0].sdo_codsdo}-`;
            // this.asientoContable.sad_codsad = series[0].sdo_codsdo;
            setTimeout(() => {
              this.nroDocElement.nativeElement.focus();
              this.nroDocElement.nativeElement.select();
            }, 1);
            return;
          }

          setTimeout(() => {
            this.serieElement.nativeElement.focus();
            this.serieElement.nativeElement.select();
          }, 1);

        },
        error => this._snackBarService.showError(error.error.msg, "OK")

      )
    } else {
      this.nroDocElement.nativeElement.focus()
      this.nroDocElement.nativeElement.select()
    }

  }


  seleccionarProveedor(event): void {

    this.ordenesCompra = this.ordenesCompraTotales.filter(item => item.cli_codcli == event.value || item.ocm_indgen == 1);

    if(event){
      // this.asientoContable.cli_coddoc = event.value;
    }

    setTimeout(() => {
      this.glosaElement.nativeElement.focus();
      this.glosaElement.nativeElement.select();
    }, 1);
  }

  seleccionarMoneda(event): void {
    setTimeout(() => {
      this.proveedorElement.focus();
      this.proveedorElement.open();
    }, 1);
  }

  establecerSerie(): void {
    // this.asientoContable.cco_numdoc = this.asientoContable.sad_codsad + '-' + this.asientoContable.cco_numdoc.substring(this.asientoContable.sad_codsad.length + 1, this.asientoContable.cco_numdoc.length + 1);

    setTimeout(() => {
      this.nroDocElement.nativeElement.focus();
      this.nroDocElement.nativeElement.select();
    }, 1);
  }

  extraerExcel(): void {

  }

  retenciones(): void {

  }

  volver(): void {
    this._router.navigate(['/asientos-contables']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.subdiarios$,
      this.tipoOperacion$,
      this.cuentas$,
      this.tiposBienServicio$,
      this.tiposRenta$,
      this.tiposImpuesto$,
      this.tiposDocumento$,
      this.proveedor$,
      this.moneda$,
      this.ordenesCompra$,
      this.condiciones$,
      this.voucher$,
      this.centro$,
      this.auxiliar$,
      this.tipoRetencion$,
      this.tipoImpRetencion$,
      this.period$
    ])

  }

}
// vivo enamorado de tu forma mas bonita enamorado de tus besos vivo yoooo
