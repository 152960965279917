<app-pavso-title-section [title]="'apps.crm.operaciones.seguimiento.title' | translate" [module]="'CRM'" [estado]="seguimiento.indsta"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>{{'apps.crm.operaciones.seguimiento.form.ticket' | translate}}</mat-label>
            <input [(ngModel)]="seguimiento.idtkt" type="text" matInput [placeholder]="'apps.crm.operaciones.seguimiento.form.ticket' | translate" name="idtkt" readonly>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>{{'apps.crm.operaciones.seguimiento.form.nro' | translate}}.</mat-label>
            <input type="text" [(ngModel)]="seguimiento.idset" matInput [placeholder]="'apps.crm.operaciones.seguimiento.form.nro' | translate" name="ost_idset" readonly>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>{{'apps.crm.operaciones.seguimiento.form.date' | translate}}</mat-label>
            <input [(ngModel)]="seguimiento.fecreg" name="ost_fecreg"  matInput [matDatepicker]="fechahoy" required>
            <mat-datepicker-toggle matSuffix [for]="fechahoy"></mat-datepicker-toggle>
            <mat-datepicker #fechahoy></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">

          <select-search
            [label]="'Etapa'"
            [placeholder]="'Ej. '"
            [name]="'codoet'"
            [value]="'codoet'"
            [description]="'descri'"
            [data]="etapas"
            (cambioSelect)="seguimiento.codoet = $event"
            [model]="seguimiento.codoet">
          </select-search>

        </div>
      </div>
      <div class="row">

        <div class="col s12 m12 l3">

          <select-search
            [label]="'apps.crm.operaciones.seguimiento.form.responsible' | translate"
            [placeholder]="'Ej. TORRES'"
            [name]="'idres'"
            [value]="'idres'"
            [description]="'nombre'"
            [data]="responsables"
            (cambioSelect)="seguimiento.idres = $event"
            [model]="seguimiento.idres">
          </select-search>

        </div>
        <div class="col s12 m12 l3">
          <select-search
            [label]="'apps.crm.operaciones.seguimiento.form.responsible' | translate"
            [placeholder]="'Ej. TORRES'"
            [name]="'idres'"
            [value]="'idres'"
            [description]="'nombre'"
            [data]="responsables"
            (cambioSelect)="seguimiento.idres = $event"
            [model]="seguimiento.idres">
          </select-search>

        </div>

        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>{{'apps.crm.operaciones.seguimiento.form.scheduled-date' | translate}}</mat-label>
            <input [(ngModel)]="seguimiento.fecpro" name="ost_fecpro" matInput [matDatepicker]="fechaprogramada">
            <mat-datepicker-toggle matSuffix [for]="fechaprogramada"></mat-datepicker-toggle>
            <mat-datepicker #fechaprogramada></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l3">

          <select-search
            [label]="'apps.crm.operaciones.seguimiento.form.situation' | translate"
            [placeholder]="'Ej. INICIADO'"
            [name]="'idstk'"
            [value]="'idstk'"
            [description]="'descri'"
            [data]="situaciones"
            (cambioSelect)="seguimiento.idstk = $event"
            [model]="seguimiento.idstk">
          </select-search>

        </div>

        <div class="col s12 m12 l3">

          <select-search
            [label]="'Actividad'"
            [placeholder]="'Ej. ACTIVIDAd'"
            [name]="'idass'"
            [value]="'idass'"
            [description]="'descri'"
            [data]="actividades"
            (cambioSelect)="seguimiento.idass = $event"
            [model]="seguimiento.idass">
          </select-search>

        </div>
        <div class="col s12 m12 l3">
          <select-search
            [label]="'Modalidad'"
            [placeholder]="'Ej. modalidad'"
            [name]="'codigo'"
            [value]="'codigo'"
            [description]="'descrimodalidad'"
            [data]="modalidades"
            (cambioSelect)="seguimiento.idmso = $event"
            [model]="seguimiento.idmso">
          </select-search>

        </div>
        <div class="col s12 m12 l3">

          <select-search
            [label]="'apps.crm.operaciones.seguimiento.form.contact' | translate"
            [placeholder]="'Ej. contacto'"
            [name]="'codccl'"
            [value]="'codccl'"
            [description]="'nomccl'"
            [data]="contactos"
            (cambioSelect)="seguimiento.codccl = $event"
            [model]="seguimiento.codccl">
          </select-search>

        </div>
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>{{'apps.crm.operaciones.seguimiento.form.reason-rate-suspended' | translate}}</mat-label>
            <mat-select [(ngModel)]="seguimiento.codosv" name="codosv" [disabled]="seguimiento.codoet != '09'">
              <mat-option *ngFor="let motivo of motivos; trackBy: trackByMotivo" [value]="motivo.codosv">
                {{motivo.descri}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l6">
          <mat-form-field>
            <mat-label>{{'apps.crm.operaciones.seguimiento.form.description' | translate}}</mat-label>
            <textarea [(ngModel)]="seguimiento.descri" name="descri" matInput [placeholder]="'apps.crm.operaciones.seguimiento.form.description' | translate" required></textarea>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l2">
          <mat-form-field class="example-full-width">
            <mat-label>Prob. Cierre (%)</mat-label>
            <input matInput placeholder="Prob. Cierre" value="5.00">
          </mat-form-field>
        </div>

        <div class="col s12 m12 l2" *ngIf="uid != '0'" style="display: flex; align-items: center; justify-content: center;">
          <button type="button" (click)="abrirAgregarContacto()" mat-mini-fab color="primary" matTooltip="Agregar Contacto">
            <mat-icon>contact_page</mat-icon>
          </button>
          &nbsp;
          <button type="button" (click)="abrirListaRequisitos()" mat-mini-fab color="primary"
            matTooltip="Lista de Requisitos">
            <mat-icon>fact_check</mat-icon>
          </button>
        </div>

        <div class="col s12 m12 l2" align="end">
          <button style="height: 45px; width: 172px;" color="warn" mat-stroked-button type="button">
            <mat-icon>picture_as_pdf</mat-icon>
            Cargar pdf
          </button>
        </div>
      </div>

    </mat-card>

    <br>

    <mat-card class="mat-elevation-z0" *ngIf="uid == '0'">
      <div class="row">
        <div class="col s12 m12 l3">
          <mat-form-field>
            <mat-label>{{'apps.crm.operaciones.seguimiento.form.nro-tracing' | translate}}.</mat-label>
            <input [(ngModel)]="nroSeguimiento" maxlength="3" type="text" matInput [placeholder]="'apps.crm.operaciones.seguimiento.form.nro' | translate" name="ost_idset">
          </mat-form-field>
        </div>
        <div class="col s12 m12 l3">
          <button (click)="copiarSeguimiento()" mat-mini-fab type="button" color="primary" aria-label="Example icon button with a menu icon">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </div>
    </mat-card>

    <log-updated *ngIf="uid!='0'" [coduse]="seguimiento.coduse" [fecupd]="seguimiento.fecupd"></log-updated>

    <br>

    <button *ngIf="uid != '0' && !isCotizacion" type="button" (click)="abrirPresupuesto()" class="pav-button-action" mat-flat-button color="primary">
      {{'apps.crm.operaciones.seguimiento.button.budget' | translate}}
    </button>

    <button *ngIf="isCotizacion" type="button" class="pav-button-action" mat-flat-button color="primary">
      Ver cotización
    </button>

    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" [urlBack]="'/modulo-crm/seguimientos-de-registro-de-oportunidad/' + this.seguimiento.idtkt">
      <div id="botonesAdicionales">

      </div>
    </pav-form-actions>

  </form>
</div>

