<ng-container>
  <app-pavso-title-section [title]="'Plan de cuenta'" [module]="'Contabilidad'" [usuario]="planCuenta.ccn_coduse" [fecha]="planCuenta.ccn_fecupd" [ngClass]="'pav-form'" [estado]="planCuenta.ccn_indsta"></app-pavso-title-section>
  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
    <div class="pavso-content-form fade-in-image">


      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Código</mat-label>
              <input [(ngModel)]="planCuenta.ccn_codccn" type="text" matInput placeholder="Código" name="ccn_codccn"
                required>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l6">
            <mat-form-field>
              <mat-label>Descripción</mat-label>
              <input [(ngModel)]="planCuenta.ccn_descri" type="text" matInput placeholder="Descripción"
                name="ccn_descri">
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Des. Abrev.</mat-label>
              <input [(ngModel)]="planCuenta.ccn_desabr" type="text" matInput placeholder="Des. Abrev."
                name="ccn_desabr">
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Cuenta SUNAT</mat-label>
              <input [(ngModel)]="planCuenta.csu_codcsu" type="text" matInput placeholder="Cuenta SUNAT"
                name="csu_codcsu">
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Tipo de cuenta</mat-label>
              <mat-select [(ngModel)]="planCuenta.ccn_indcco" required name="ccn_indcco">
                <mat-option value="1">
                  tipo 1
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <app-currency-select [moneda]="planCuenta.tmo_codtmo" [disable]="loaderData"
              (cambioMoneda)="planCuenta.tmo_codtmo = $event"></app-currency-select>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Col. Balance</mat-label>
              <mat-select [(ngModel)]="planCuenta.ccn_indclb" required name="ccn_indclb">
                <mat-option value="1">
                  col balance 1
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Control Cta. Cte.</mat-label>
              <mat-select [(ngModel)]="planCuenta.ccn_codclb" required name="ccn_codclb">
                <mat-option value="1">
                  control cta cte 1
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Ind. PCGE</mat-label>
              <input [(ngModel)]="planCuenta.ccn_indpcg" type="text" matInput placeholder="Ind. PCGE" name="ccn_indpcg">
            </mat-form-field>
          </div>

          <div class="col s12 m12 l12">
            <section>
              <mat-checkbox class="example-margin" name="movimiento">Movimiento</mat-checkbox>
              <mat-checkbox class="example-margin" name="ctaAnalitica">Cta. Analítica</mat-checkbox>
              <mat-checkbox class="example-margin" name="centroCosto">Centro de costo</mat-checkbox>
              <mat-checkbox class="example-margin" name="centroCostoFijo">
                <mat-form-field>
                  <mat-label>Centro de Costo Fijo</mat-label>
                  <input [(ngModel)]="planCuenta.ccs_codccs" type="text" matInput placeholder="Centro de Costo Fijo"
                    name="ccs_codccs">
                </mat-form-field>
              </mat-checkbox>
              <mat-checkbox class="example-margin" name="ctaPlanillas">Cta. Planillas</mat-checkbox>
              <mat-checkbox class="example-margin" name="cierre">
                <mat-form-field>
                  <mat-label>Cierre</mat-label>
                  <input [(ngModel)]="planCuenta.ccn_codcie" type="text" matInput placeholder="Cierre"
                    name="ccn_codcie">
                </mat-form-field>
              </mat-checkbox>
            </section>
          </div>

          <div class="col s12 m12 l12">
            <button class="pav-button-action" mat-flat-button color="primary" type="button">
              Modificaciones
            </button>
            &nbsp;
            <button class="pav-button-action" mat-flat-button color="primary" type="button">
              Validación Ctas Diferencia Cambio
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col s12">
            <h3>AJUSTE DE DIFERENCIA DE CAMBIO</h3>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Tipo de ajuste</mat-label>
              <mat-select [(ngModel)]="planCuenta.ccn_tipaju" required name="ccn_tipaju">
                <mat-option value="1">
                  tipo ajuste 1
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Tipo de cambio</mat-label>
              <mat-select [(ngModel)]="planCuenta.ccn_tcaaju" required name="ccn_tcaaju">
                <mat-option *ngFor="let tipo of tiposCambio" [value]="tipo.ANO_CODANO+tipo.MES_CODMES+tipo.DIA_CODDIA">
                  {{tipo.TCA_COMOFI}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <mat-checkbox class="example-margin" [(ngModel)]="activateCuentaDestino" name="cuentaDestino">
              <h3>
                CUENTA DE DESTINO
              </h3>
            </mat-checkbox>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Tipo de destino</mat-label>
              <mat-select [(ngModel)]="planCuenta.ccn_tipdes" required name="ccn_tipdes"
                [disabled]="!activateCuentaDestino">
                <mat-option value="1">
                  tipo destino 1
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Cuenta transferencia</mat-label>
              <mat-select [(ngModel)]="planCuenta.ccn_codtra" required name="ccn_codtra">
                <mat-option value="1">
                  cuenta transferencia 1
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Cuenta debe</mat-label>
              <mat-select [(ngModel)]="planCuenta.ccn_coddbe" required name="ccn_coddbe">
                <mat-option value="1">
                  cuenta debe 1
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Descrip. Banco</mat-label>
              <input [(ngModel)]="planCuenta.ccn_banco" type="text" matInput placeholder="Descrip. Banco"
                name="ccn_banco">
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Nro. Cuenta</mat-label>
              <input [(ngModel)]="planCuenta.ccn_ctaban" type="text" matInput placeholder="Nro. Cuenta"
                name="ccn_ctaban">
            </mat-form-field>
          </div>

          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Flujo de Caja</mat-label>
              <mat-select [(ngModel)]="planCuenta.fca_codfca" required name="fca_codfca">
                <mat-option *ngFor="let flujo of flujos" [value]="flujo.codigo_flujocaja">
                  {{flujo.descri_flujocaja}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <br>

    </div>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-contabilidad/plan-de-cuentas">
      <div id="botonesAdicionales">
      </div>
    </pav-form-actions>
  </form>
</ng-container>
