import { REPORT_HEADER_COLOR } from "@data/constants/configuration/configuration.constant";
import { PavsoColors } from "@data/enums/color.enum";
import { formatDateWithSlash } from "@utils/formats/date.format";
import { horizontalBorder, noBorders, openLeftBorder, openRightBorder, topBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { obtenerNulls } from "@utils/pdfmaker/utils/pdfmake.function";
import { Cell, PdfMakeWrapper, Stack, Table, Txt } from "pdfmake-wrapper";
import { number2month } from '../../conversions/number2month.conversion';
import { numberWithCommas } from "@utils/formats/number.format";

/**
 *
 * @param headers
 * @param data
 */
export async function generarPDFDocumentoCobrarDocConSaldo(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [10, 10, 25, 20], fontSize: 8, color: 'gray', bold: true },
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let detalle = [];

  let [totalImporteSO, totalImporteDO, totalAbonoSO, totalAbonoDO, totalSaldoSO, totalSaldoDO] = [0, 0, 0, 0, 0, 0]

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt('CLIENTE:').fontSize(8).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.cli_codcli).fontSize(8).bold().end
      ).border(noBorders).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(element.cli_nomcli).fontSize(8).bold().end
      ).border(noBorders).colSpan(6).end,
      ...obtenerNulls(5),
    ],)

    let nroItems = element.detalle.length;
    let importeSO = 0;
    let importeDO = 0;
    let abonoSO = 0;
    let abonoDO = 0;
    let saldoSO = 0;
    let saldoDO = 0;


    element.detalle.forEach(item => {

      item.tmo_codtmo == 'SO' ? totalImporteSO += (item.cco_impdoc * item.tdo_indsig) : totalImporteDO += (item.cco_impdoc * item.tdo_indsig)
      item.tmo_codtmo == 'SO' ? totalAbonoSO += (item.cco_impabo * item.tdo_indsig) : totalAbonoDO += (item.cco_impabo * item.tdo_indsig)
      item.tmo_codtmo == 'SO' ? totalSaldoSO += (item.cco_impsal * item.tdo_indsig) : totalSaldoDO += (item.cco_impsal * item.tdo_indsig)

      item.tmo_codtmo == 'SO' ? importeSO += (item.cco_impdoc * item.tdo_indsig) : importeDO += (item.cco_impdoc * item.tdo_indsig)
      item.tmo_codtmo == 'SO' ? abonoSO += (item.cco_impabo * item.tdo_indsig) : abonoDO += (item.cco_impabo * item.tdo_indsig)
      item.tmo_codtmo == 'SO' ? saldoSO += (item.cco_impsal * item.tdo_indsig) : saldoDO += (item.cco_impsal * item.tdo_indsig)

      detalle.push([
        new Cell(
          new Txt(`${item.tdo_codtdo}/${item.cco_numdoc}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.cco_fecemi)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.cco_fecven)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cco_codcco).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tmo_codtmo).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas((item.cco_impdoc * item.tdo_indsig).toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas((item.cco_impabo * item.tdo_indsig).toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas((item.cco_impsal * item.tdo_indsig).toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.dcd_glodcd).fontSize(7).end
        ).border(noBorders).end,
      ],)
    })

    detalle.push([
      new Cell(
        new Txt(`Nro Items: ${nroItems}`).fontSize(8).end
      ).border(topBorder).end,
      new Cell(
        new Stack([
          new Txt('S/').alignment('right').fontSize(7).end,
          new Txt('USD').alignment('right').fontSize(7).end
        ]).end
      ).border(noBorders).colSpan(4).end,
      ...obtenerNulls(3),
      new Cell(
        new Stack([
          new Txt(importeSO.toFixed(2)).fontSize(7).bold().alignment('right').end,
          new Txt(importeDO.toFixed(2)).fontSize(7).bold().alignment('right').end
        ]).end
      ).border(topBorder).end,
      new Cell(
        new Stack([
          new Txt(abonoSO.toFixed(2)).fontSize(7).bold().alignment('right').end,
          new Txt(abonoDO.toFixed(2)).fontSize(7).bold().alignment('right').end
        ]).end
      ).border(topBorder).end,
      new Cell(
        new Stack([
          new Txt(saldoSO.toFixed(2)).fontSize(7).bold().alignment('right').end,
          new Txt(saldoDO.toFixed(2)).fontSize(7).bold().alignment('right').end
        ]).end
      ).border(topBorder).end,
      new Cell(
        new Txt(``).fontSize(8).end
      ).border(noBorders).end,
    ])

  });

  const mes = new Date().getMonth() + 1;
  const mesStr = mes > 9 ? `${mes}` : `0${mes}`

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt("DEMO PAVSO").fontSize(7).end,
            new Txt("RFIN_DOCU_COBR").fontSize(7).end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('DOCUMENTOS POR COBRAR **Doc. con Saldo').fontSize(10).bold().alignment('center').end,
            new Txt(`${new Date().getUTCDate()} de ${number2month(mesStr)} de ${new Date().getUTCFullYear()}`).fontSize(8).bold().alignment('center').end
          ]).end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(
          new Stack([
            new Txt("").end,
            new Txt("").end,
          ]).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('Documento').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F. Emisión').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F. Vcto.').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Cta. Cte.').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Mon.').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Importe').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Abono').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Saldo').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Referencia').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end
      ],

      ...detalle,

      [
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('TOTAL GENERAL').fontSize(8).bold().alignment('right').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt('S/.').fontSize(8).bold().end,
            new Txt('US$').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(numberWithCommas(totalImporteSO.toFixed(2))).alignment('right').fontSize(8).bold().end,
            new Txt(numberWithCommas(totalImporteDO.toFixed(2))).alignment('right').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(numberWithCommas(totalAbonoSO.toFixed(2))).alignment('right').fontSize(8).bold().end,
            new Txt(numberWithCommas(totalAbonoDO.toFixed(2))).alignment('right').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(numberWithCommas(totalSaldoSO.toFixed(2))).alignment('right').fontSize(8).bold().end,
            new Txt(numberWithCommas(totalSaldoDO.toFixed(2))).alignment('right').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
      ]
    ]).headerRows(2).widths([100, 50, 50, 75, 35, 65, 65, 65, 215]).end
  );

  pdf.create().open();
}

/**
 *
 * @param headers
 * @param data
 */
export async function generarPDFDocumentoCobrarDocConSaldoPorGrupo(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [10, 10, 25, 20], fontSize: 8, color: 'gray', bold: true },
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let detalle = [];

  let [totalImporteSO, totalImporteDO, totalAbonoSO, totalAbonoDO, totalSaldoSO, totalSaldoDO] = [0, 0, 0, 0, 0, 0]

  data.forEach(grupo => {

    detalle.push([
      new Cell(
        new Txt('GRUPO:').fontSize(8).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(grupo.gcl_codgcl).fontSize(8).bold().end
      ).border(noBorders).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(grupo.gcl_descri).fontSize(8).bold().end
      ).border(noBorders).colSpan(6).end,
      ...obtenerNulls(5),
    ],)

    grupo.detalle.forEach(element => {
      detalle.push([
        new Cell(
          new Txt('CLIENTE:').fontSize(8).bold().end
        ).border(noBorders).end,
        new Cell(
          new Txt(element.cli_codcli).fontSize(8).bold().end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Txt(element.cli_nomcli).fontSize(8).bold().end
        ).border(noBorders).colSpan(6).end,
        ...obtenerNulls(5),
      ],)

      let nroItems = element.detalle.length;
      let importeSO = 0;
      let importeDO = 0;
      let abonoSO = 0;
      let abonoDO = 0;
      let saldoSO = 0;
      let saldoDO = 0;


      element.detalle.forEach(item => {

        item.tmo_codtmo == 'SO' ? totalImporteSO += (item.cco_impdoc * item.tdo_indsig) : totalImporteDO += (item.cco_impdoc * item.tdo_indsig)
        item.tmo_codtmo == 'SO' ? totalAbonoSO += (item.cco_impabo * item.tdo_indsig) : totalAbonoDO += (item.cco_impabo * item.tdo_indsig)
        item.tmo_codtmo == 'SO' ? totalSaldoSO += (item.cco_impsal * item.tdo_indsig) : totalSaldoDO += (item.cco_impsal * item.tdo_indsig)

        item.tmo_codtmo == 'SO' ? importeSO += (item.cco_impdoc * item.tdo_indsig) : importeDO += (item.cco_impdoc * item.tdo_indsig)
        item.tmo_codtmo == 'SO' ? abonoSO += (item.cco_impabo * item.tdo_indsig) : abonoDO += (item.cco_impabo * item.tdo_indsig)
        item.tmo_codtmo == 'SO' ? saldoSO += (item.cco_impsal * item.tdo_indsig) : saldoDO += (item.cco_impsal * item.tdo_indsig)

        detalle.push([
          new Cell(
            new Txt(`${item.tdo_codtdo}/${item.cco_numdoc}`).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(item.cco_fecemi)).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(formatDateWithSlash(item.cco_fecven)).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(item.cco_codcco).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(item.tmo_codtmo).fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas((item.cco_impdoc * item.tdo_indsig).toFixed(2))).alignment('right').fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas((item.cco_impabo * item.tdo_indsig).toFixed(2))).alignment('right').fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(numberWithCommas((item.cco_impsal * item.tdo_indsig).toFixed(2))).alignment('right').fontSize(7).end
          ).border(noBorders).end,
          new Cell(
            new Txt(item.dcd_glodcd).fontSize(7).end
          ).border(noBorders).end,
        ],)
      })

      detalle.push([
        new Cell(
          new Txt(`Nro Items: ${nroItems}`).fontSize(8).end
        ).border(topBorder).end,
        new Cell(
          new Stack([
            new Txt('S/').alignment('right').fontSize(7).end,
            new Txt('USD').alignment('right').fontSize(7).end
          ]).end
        ).border(noBorders).colSpan(4).end,
        ...obtenerNulls(3),
        new Cell(
          new Stack([
            new Txt(importeSO.toFixed(2)).fontSize(7).bold().alignment('right').end,
            new Txt(importeDO.toFixed(2)).fontSize(7).bold().alignment('right').end
          ]).end
        ).border(topBorder).end,
        new Cell(
          new Stack([
            new Txt(abonoSO.toFixed(2)).fontSize(7).bold().alignment('right').end,
            new Txt(abonoDO.toFixed(2)).fontSize(7).bold().alignment('right').end
          ]).end
        ).border(topBorder).end,
        new Cell(
          new Stack([
            new Txt(saldoSO.toFixed(2)).fontSize(7).bold().alignment('right').end,
            new Txt(saldoDO.toFixed(2)).fontSize(7).bold().alignment('right').end
          ]).end
        ).border(topBorder).end,
        new Cell(
          new Txt(``).fontSize(8).end
        ).border(noBorders).end,
      ])

    });

  });

  const mes = new Date().getMonth() + 1;
  const mesStr = mes > 9 ? `${mes}` : `0${mes}`

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt("DEMO PAVSO").fontSize(7).end,
            new Txt("RFIN_DOCU_COBR").fontSize(7).end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('DOCUMENTOS POR COBRAR **Doc. con Saldo Por Grupo').fontSize(10).bold().alignment('center').end,
            new Txt(`${new Date().getUTCDate()} de ${number2month(mesStr)} de ${new Date().getUTCFullYear()}`).fontSize(8).bold().alignment('center').end
          ]).end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(
          new Stack([
            new Txt("").end,
            new Txt("").end,
          ]).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('Documento').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F. Emisión').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F. Vcto.').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Cta. Cte.').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Mon.').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Importe').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Abono').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Saldo').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Referencia').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end
      ],

      ...detalle,

      [
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('TOTAL GENERAL').fontSize(8).bold().alignment('right').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt('S/.').fontSize(8).bold().end,
            new Txt('US$').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(numberWithCommas(totalImporteSO.toFixed(2))).alignment('right').fontSize(8).bold().end,
            new Txt(numberWithCommas(totalImporteDO.toFixed(2))).alignment('right').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(numberWithCommas(totalAbonoSO.toFixed(2))).alignment('right').fontSize(8).bold().end,
            new Txt(numberWithCommas(totalAbonoDO.toFixed(2))).alignment('right').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt(numberWithCommas(totalSaldoSO.toFixed(2))).alignment('right').fontSize(8).bold().end,
            new Txt(numberWithCommas(totalSaldoDO.toFixed(2))).alignment('right').fontSize(8).bold().end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
      ]
    ]).headerRows(2).widths([100, 50, 50, 75, 35, 65, 65, 65, 215]).end
  );

  pdf.create().open();
}

/**
 *
 * @param headers
 * @param data
 */
export async function generarPDFPagoEfectuadoCliente(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [10, 10, 25, 20], fontSize: 8, color: 'gray', bold: true },
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  pdf.create().open();

}

/**
 *
 * @param headers
 * @param data
 */
export async function generarPDFTabuladoCuentaPorCobrar(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [10, 10, 25, 20], fontSize: 8, color: 'gray', bold: true },
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let detalle = [];

  let [totSal, tot01, tot02, tot03, tot04, tot05] = [0, 0, 0, 0, 0, 0, 0];

  let [totGNac, totGDol] = [0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(element.cli_codcli).bold().fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.cli_nomcli).fontSize(8).bold().end
      ).colSpan(6).border(noBorders).end,
      ...obtenerNulls(5),
      new Cell(
        new Txt(`Vendedor ${element.vde_nomvde}`).bold().fontSize(8).end
      ).colSpan(5).border(noBorders).end,
      ...obtenerNulls(4)
    ],)

    let [tSal, t01, t02, t03, t04, t05] = [0, 0, 0, 0, 0, 0, 0];

    element.detalle.forEach(item => {

      let [imp01, imp02, imp03, imp04, imp05] = [0, 0, 0, 0, 0];

      if (item.grupo_cobranza == '01') {
        imp01 = item.cco_salcco + item.cco_sallba + item.cco_salres;
      }
      if (item.grupo_cobranza == '02') {
        imp02 = item.cco_salcco + item.cco_sallba + item.cco_salres;
      }
      if (item.grupo_cobranza == '03') {
        imp03 = item.cco_salcco + item.cco_sallba + item.cco_salres;
      }
      if (item.grupo_cobranza == '04') {
        imp04 = item.cco_salcco + item.cco_sallba + item.cco_salres;
      }
      if (item.grupo_cobranza == '05') {
        imp05 = item.cco_salcco + item.cco_sallba + item.cco_salres;
      }

      tSal += item.cco_salrea;
      t01 += imp01;
      t02 += imp02;
      t03 += imp03;
      t04 += imp04;
      t05 += imp05;

      totGNac += item.cco_salnac;
      totGDol += item.cco_saldol;

      detalle.push([
        new Cell(
          new Txt(`${item.tdo_codtdo}/${item.cco_numdoc}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.cco_fecemi)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.cco_fecven)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.CPA_DESCRI).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.TMO_CODTMO).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas((imp01 + imp02 + imp03 + imp04 + imp05).toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cco_diaven2).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(imp01.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(imp02.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(imp03.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(imp04.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(numberWithCommas(imp05.toFixed(2))).alignment('right').fontSize(7).end
        ).border(noBorders).end,
      ],)
    })

    detalle.push([
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(tSal.toFixed(2))).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt('').alignment('right').bold().fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(numberWithCommas(t01.toFixed(2))).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(t02.toFixed(2))).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(t03.toFixed(2))).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(t04.toFixed(2))).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt(numberWithCommas(t05.toFixed(2))).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
    ],)

    totSal += tSal;
    tot01 += t01;
    tot02 += t02;
    tot03 += t03;
    tot04 += t04;
    tot05 += t05;
  });

  detalle.push([
    new Cell(
      new Txt('TOTAL SOLES').bold().alignment('right').fontSize(7).end
    ).colSpan(5).border(noBorders).end,
    ...obtenerNulls(4),
    new Cell(
      new Txt(numberWithCommas(totSal.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt(numberWithCommas(tot01.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tot02.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tot03.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tot04.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt(numberWithCommas(tot05.toFixed(2))).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
  ],)

  detalle.push([
    new Cell(
      new Txt('TOTAL GENERAL').bold().alignment('right').fontSize(7).end
    ).colSpan(5).border(noBorders).end,
    ...obtenerNulls(4),
    new Cell(
      new Stack([
        new Txt(`S/. ${numberWithCommas(totGNac.toFixed(2))}`).alignment('right').bold().fontSize(7).end,
        new Txt(`US$ ${numberWithCommas(totGDol.toFixed(2))}`).alignment('right').bold().fontSize(7).end,
      ]).end
    ).border(topBorder).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').bold().fontSize(8).end,
            new Txt('RFIN_FACT_COBR').bold().fontSize(8).end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Cobrar Tabulado **Doc. con Saldo').alignment('center').bold().fontSize(12).end,
            new Txt('01 de Junio de 2023').alignment('center').bold().fontSize(10).end
          ]).end
        ).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(
          new Stack([
            new Txt('Fecha : 1/06/2023').alignment('right').bold().fontSize(8).end,
            new Txt('Hora : 09:53:48').alignment('right').bold().fontSize(8).end,
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('Documento').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F.Emisión').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F.Vcto.').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Condición Pago').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Mon.').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Saldo').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Atraso(+Ó-)').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Vigentes').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Venc. <30').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Venc. <60').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Venc. <90').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('Venc. >90').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,
    ]).headerRows(2).widths([80, 40, 40, 80, 30, 60, 60, 60, 60, 60, 60, 60]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Movimientos de cobranza
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function generarPDFMovimientosDeCobranza(headers: any, response) {
  // Variables

  // Diseño
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [10, 10, 25, 20], fontSize: 8, color: 'gray', bold: true },
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let items = [];

  let totalMNCuenta = 0
  let totalMECuenta = 0

  response.forEach(auxiliar => {
    items.push([
      new Cell(
        new Txt("Auxiliar").bold().fontSize(7).end
      ).fillColor("#eee").colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(auxiliar.auxiliar_code).bold().fontSize(7).end
      ).fillColor("#eee").colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(auxiliar.auxiliar_descri).bold().fontSize(7).end
      ).fillColor("#eee").border(noBorders).colSpan(12).end,
      ...obtenerNulls(11),
    ])

    let totalMNSaldo = 0
    let totalMESaldo = 0

    auxiliar.detalle.forEach(item => {

      let impDebeSoles = (item.ldi_INDDHA == "D") ? item.LDI_IMPNAC : 0
      let impDebeDolares = (item.ldi_INDDHA == "D") ? item.LDI_IMPMEX : 0
      let impHaberSoles = (item.ldi_INDDHA == "H") ? (item.LDI_IMPNAC) * (-1) : 0
      let impHaberDolares = (item.ldi_INDDHA == "H") ? (item.LDI_IMPMEX) * (-1) : 0
      let saldoSoles = (impDebeSoles == 0) ? impHaberSoles : impDebeSoles;
      let saldoDolares = (impDebeDolares == 0) ? impHaberDolares : impDebeDolares;

      totalMNSaldo += saldoSoles
      totalMESaldo += saldoDolares

      items.push([
        new Cell(
          new Txt((item.cco_fecemi) ? item.cco_fecemi.substring(0, 10) : "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.cco_fecven) ? item.cco_fecven.substring(0, 10) : "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${item.ANO_CODANO_LDI}-${item.MES_CODMES_LDI}-${item.sco_codsco}-${item.ldc_CORldc}-${item.ldi_corldi}`).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tdo_codtdo).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_docref).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tdo_codban).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.PBA_NUMDOC).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cba_descri).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.sco_descri).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_gloldi).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impDebeSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impHaberSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(saldoSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impDebeDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impHaberDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(saldoDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
      ])
    });

    items.push([
      new Cell(
        new Txt("TOTAL AUXILIAR          70484848").alignment('right').fontSize(7).end
      ).colSpan(10).border(noBorders).end,
      ...obtenerNulls(9),
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(totalMNSaldo.toFixed(2)).alignment('right').fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(totalMESaldo.toFixed(2)).alignment('right').fontSize(7).end
      ).border(topBorder).end,
    ])

    totalMNCuenta += totalMNSaldo
    totalMECuenta += totalMESaldo
  });

  items.push([
    new Cell(
      new Txt(`TOTAL CUENTA          ${response[0].cuenta}`).alignment('right').fontSize(6).end
    ).colSpan(10).border(noBorders).end,
    ...obtenerNulls(9),
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(totalMNCuenta.toFixed(2)).alignment('right').fontSize(6).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(totalMECuenta.toFixed(2)).alignment('right').fontSize(6).end
    ).border(topBorder).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(10).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(new Txt(headers.date).alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(10).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Movimientos de cobranza").alignment('center').bold().fontSize(14).end).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(new Txt("").alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`Acumulado hasta ${headers.codano} ${headers.codmes}`).alignment('center').bold().fontSize(10).end,
          ]).end,
        ).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(new Txt("").alignment('left').end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("F. Operación").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("F. Vcto.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("# Correlativo de Aasiento").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Tip. Doc.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Nro. Doc.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Doc. Bco.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("# Operación").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Banco").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Subdiario").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Glosa").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Movimiento Moneda Nacional").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("Movimiento Moneda Extranjero").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        ...obtenerNulls(10),
        new Cell(
          new Txt("Debe").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Debe").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
      ],

      ...items,
    ]).headerRows(5).widths([40, 32, 80, 15, 50, 20, 40, 45, 75, 70, 35, 35, 35, 35, 35, 35]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Estado cuenta cliente
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function generarPDFEstadoCuentaCliente(headers: any, response) {

  // Diseño
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([20, 20, 20, 20]);
  pdf.header(function (currentPage, pageCount) {
    return [
      { text: currentPage.toString() + '/' + pageCount, alignment: 'right', margin: [10, 10, 25, 20], fontSize: 8, color: 'gray', bold: true },
    ]
  });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 8, bold: true, margin: [5, 2, 10, 10], alignment: 'right', color: 'gray' },
    ];
  })

  let items = [];

  let totalMNCuenta = 0
  let totalMECuenta = 0

  response.forEach(auxiliar => {
    items.push([
      new Cell(
        new Txt("Auxiliar").bold().fontSize(7).end
      ).fillColor("#eee").colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(auxiliar.auxiliar_code).bold().fontSize(7).end
      ).fillColor("#eee").colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(auxiliar.auxiliar_descri).bold().fontSize(7).end
      ).fillColor("#eee").border(noBorders).colSpan(11).end,
      ...obtenerNulls(10),
    ])

    let totalMNSaldo = 0
    let totalMESaldo = 0

    auxiliar.detalle.forEach(item => {

      let impDebeSoles = (item.ldi_INDDHA == "D") ? item.LDI_IMPNAC : 0
      let impDebeDolares = (item.ldi_INDDHA == "D") ? item.LDI_IMPMEX : 0
      let impHaberSoles = (item.ldi_INDDHA == "H") ? (item.LDI_IMPNAC) * (-1) : 0
      let impHaberDolares = (item.ldi_INDDHA == "H") ? (item.LDI_IMPMEX) * (-1) : 0
      let saldoSoles = (impDebeSoles == 0) ? impHaberSoles : impDebeSoles;
      let saldoDolares = (impDebeDolares == 0) ? impHaberDolares : impDebeDolares;

      totalMNSaldo += saldoSoles
      totalMESaldo += saldoDolares

      items.push([
        new Cell(
          new Txt((item.cco_fecemi) ? item.cco_fecemi.substring(0, 10) : "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.cco_fecven) ? item.cco_fecven.substring(0, 10) : "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${item.ANO_CODANO_LDI}-${item.MES_CODMES_LDI}-${item.sco_codsco}-${item.ldc_CORldc}-${item.ldi_corldi}`).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tdo_codtdo).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_docref).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.PBA_NUMDOC).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cba_descri).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.sco_descri).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_gloldi).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impDebeSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impHaberSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(saldoSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impDebeDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impHaberDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(saldoDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
      ])
    });

    items.push([
      new Cell(
        new Txt("TOTAL AUXILIAR          70484848").alignment('right').fontSize(7).end
      ).colSpan(9).border(noBorders).end,
      ...obtenerNulls(8),
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(totalMNSaldo.toFixed(2)).alignment('right').fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(totalMESaldo.toFixed(2)).alignment('right').fontSize(7).end
      ).border(topBorder).end,
    ])

    totalMNCuenta += totalMNSaldo
    totalMECuenta += totalMESaldo
  });

  items.push([
    new Cell(
      new Txt(`TOTAL CUENTA          ${response[0].cuenta}`).alignment('right').fontSize(6).end
    ).colSpan(9).border(noBorders).end,
    ...obtenerNulls(8),
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(totalMNCuenta.toFixed(2)).alignment('right').fontSize(6).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(totalMECuenta.toFixed(2)).alignment('right').fontSize(6).end
    ).border(topBorder).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(7).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt(headers.date).alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(6).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Estado de cuenta cliente").alignment('center').bold().fontSize(14).end).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt("").alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`Acumulado hasta ${headers.codano} ${headers.codmes}`).alignment('center').bold().fontSize(10).end,
          ]).end,
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt("").alignment('left').end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("F. Operación").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Fec. Venc.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("# Correlativo de Asiento").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Tipo Doc.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Nro. Doc.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("# Operación").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Banco").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Subdiario").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Glosa").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("Movimiento Moneda Nacional").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("Movimiento Moneda Extranjera").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        ...obtenerNulls(9),
        new Cell(
          new Txt("Debe").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Debe").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Haber").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("Saldo").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
      ],

      ...items,
    ]).headerRows(5).widths([40, 40, 80, 20, 50, 40, 40, 40, 110, 35, 35, 35, 35, 35, 35]).end
  )

  pdf.create().open();

}
