import { SelectionModel } from "@angular/cdk/collections";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { ComprasService, ConfigurationService } from "src/app/services";
import { RegistroCompraService } from "src/app/services/api/compras/operaciones/registro-compra.service";
import { ContefactService } from "src/app/services/api/others/contefact.service";
import { FileService } from "src/app/services/api/compras/operaciones/file.service";
import { FileUpload } from "src/app/models/compras/file-upload";
import { numberWithCommas } from "@utils/formats/number.format";
import { ModalAgregarDocumentoDialog } from "./modal-agregar-documento/modal-agregar-documento.dialog";
import * as JSZip from 'jszip';
import { ReceptionCompraService } from "src/app/services/api/compras/operaciones/reception.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { RecepcionCompra } from "src/app/models/compras/recepcion";
import { retornaDiferenciaDias } from "@utils/date/date";
import { ClienteService } from "src/app/services/api/ventas/maestros/clientes.service";
import { Proveedor } from "src/app/models";
import { SunatService } from "src/app/services/api/sunat/sunat.service";
import { ProveedorService } from "src/app/services/api/compras/maestros/proveedor.service";

class DocumentoADescargar {
  ruc: string;
  tipo: string;
  codofi: string;
  serie: string;
  numeroDoc: string;
}

@Component({
  selector: 'registro-compra-descarga-masiva',
  templateUrl: './registro-compra-descarga-masiva.component.html',
  styleUrls: ['./registro-compra-descarga-masiva.component.scss']
})
export class RegistroCompraDescargaMasivaComponent implements OnInit, OnDestroy, OnChanges {

  @Input() loaderData: boolean = true;
  @Input() maestros: { tiposDocumento: Array<any> };
  @Input() maestrosDescarga: { recepciones: Array<any>, registrosCompra: Array<any> };

  tiposDoc: Array<any> = [];
  recepciones: Array<any> = [];
  registrosCompra: Array<any> = [];

  tiposDoc$: Subscription;
  obtenerXMLMasivo$: Subscription;
  serie$: Subscription;
  datosXML$: Subscription;
  consultaSIRE$: Subscription;
  estadoSIRE$: Subscription;
  loadingStore$: Subscription;
  periodStore$: Subscription;
  registrarDocumentosXML$: Subscription;
  obtenerRecepciones$: Subscription;
  obtenerRegistrosCompra$: Subscription;
  registrarRecepcion$: Subscription;

  documento: DocumentoADescargar;

  displayedColumnsDocumentos: string[] = ['select', 'action', 'razonSocial', 'ruc', 'tipo', 'serie', 'numeroDoc', 'fechaEmision', 'fechaVencimiento', 'moneda', 'importe'];
  dataSourceDocumentos: MatTableDataSource<DocumentoADescargar>;

  @ViewChild(MatPaginator) paginatorDocumentos: MatPaginator;
  @ViewChild(MatSort) sortDocumentos: MatSort;

  selection = new SelectionModel<any>(true, []);

  clientId: string;

  downloading: boolean = false;

  anioPeriodo: string;
  mesPeriodo: string;

  loaderSIRE: boolean;
  loaderEnvioRecepcion: boolean;
  documentoAEnviar: string;
  loaderEnviarCargaMasiva: boolean;
  @Output() moverCargaMasiva: EventEmitter<any>;
  @Output() actualizarRecepciones: EventEmitter<any>;

  documentosValidos: Array<any> = [];
  documentosNoValidos: Array<any> = [];

  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(
    private _snackBarService: SnackBarService,
    private _sunatService: SunatService,
    private _contefactService: ContefactService,
    private _clientService: ClienteService,
    private _proveedorService: ProveedorService,
    private _fileService: FileService,
    private _configurationService: ConfigurationService,
    private _registroCompraService: RegistroCompraService,
    private _recepcionService: ReceptionCompraService,
    private _comprasService: ComprasService,
    private store: Store<PavsoState>,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver

  ) {
    this.dataSourceDocumentos = fillTable([], this.paginatorDocumentos, this.sortDocumentos)
    this.documento = new DocumentoADescargar();

    this.moverCargaMasiva = new EventEmitter<any>();
    this.actualizarRecepciones = new EventEmitter<any>();

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });
  }

  ngOnInit(): void {

    this.periodStore$ = this.store.select('period').subscribe(state => {
      console.log('ingreso')
      this.anioPeriodo = state.year;
      this.mesPeriodo = state.month;
      this.loaderEnviarCargaMasiva = false;
      this.loaderSIRE = false;
      if (this.consultaSIRE$) this.consultaSIRE$.unsubscribe()

    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maestros) {
      // this.tiposDoc = changes.maestros.currentValue.tiposDocumento;
      this.tiposDoc = changes.maestros.currentValue.tiposDocumento.filter(item => item.TDO_CODTDO == 'FAC' || item.TDO_CODTDO == 'NCR' || item.TDO_CODTDO == 'NDB');
    }

    if (changes.maestrosDescarga) {
      console.log('changes.maestrosDescarga', changes.maestrosDescarga)
      this.recepciones = changes.maestrosDescarga.currentValue.recepciones;
      this.registrosCompra = changes.maestrosDescarga.currentValue.registrosCompra;
      console.log('RECEPCIONES', this.recepciones)
      console.log('REGISTROS DE COMPRA', this.registrosCompra)
    }

    if (changes.loaderData) {
      this.loaderData = changes.loaderData.currentValue;
    }
  }

  applyFilter(event: Event): void {
    this.dataSourceDocumentos = searchInTable(event, this.dataSourceDocumentos)
  }

  enviarARecepcion(row) {

    this.loaderEnvioRecepcion = true;
    console.log(row)

    this.documentoAEnviar = row.ruc + row.serie + row.numeroDoc;

    const recepcion = new RecepcionCompra();

    recepcion.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
    recepcion.CCO_CODUSE = this._configurationService.obtenerIdUsuario();
    recepcion.CLI_CODCLI = row.psc_numide;
    recepcion.ANO_CODANO = row.ano_codano;
    recepcion.MES_CODMES = row.mes_codmes;
    recepcion.estado = 'En recepción';
    recepcion.CLI_CODDOC = row.psc_numide;
    recepcion.sad_codsad = row.psc_serdoc;
    recepcion.CCO_NUMDOC = row.psc_serdoc + this.completarCerosIzquierda(row.psc_numini);
    recepcion.CCO_FECEMI = row.psc_fecemi;
    recepcion.TMO_CODTMO = row.psc_codtmo == 'PEN' ? 'SO': 'DO';
    recepcion.TDO_CODTDO = this.tiposDoc.find(item => item.TDO_CODOFI == row.psc_tipdoc).TDO_CODTDO;
    recepcion.CCO_GLOCCO = 'glosa';
    recepcion.CCO_TASIGV = 0;
    recepcion.CCO_IMPIGV = row.psc_igvdg;
    recepcion.CCO_IMPDOC = recepcion.CCO_IMPDOC = row.importe;
    recepcion.CCO_IMPAFE = 0;

    if (recepcion.CCO_IMPIGV == 0) {
      recepcion.CCO_IMPINA = recepcion.CCO_IMPAFE;
      recepcion.CCO_IMPAFE = 0;
    } else {
      recepcion.CCO_IMPAFE = recepcion.CCO_IMPAFE;
      recepcion.CCO_IMPINA = 0;

      recepcion.CCO_IMPDOC = row.importe;
      // TODO: Validaciones pendientes
      // if ((recepcion.CCO_IMPAFE + recepcion.CCO_IMPIGV) != recepcion.CCO_IMPDOC) {
      //   if (INVOICE["CAC:ALLOWANCECHARGE"]) {
      //     recepcion.dpc_impfis = parseFloat(INVOICE["CAC:ALLOWANCECHARGE"][0]["CBC:AMOUNT"][0]["_"]);
      //   } else {
      //     recepcion.CCO_IMPINA = parseFloat((recepcion.CCO_IMPDOC - (recepcion.CCO_IMPAFE + recepcion.CCO_IMPIGV)).toFixed(2))

      //   }
      // }
    }

    recepcion.valvta = recepcion.CCO_IMPINA + recepcion.CCO_IMPAFE + recepcion.dpc_impfis + recepcion.dpc_impsis;
    recepcion.CCO_IMPDOC = recepcion.valvta + recepcion.CCO_IMPIGV;

    recepcion.CCO_CODUSE = this._configurationService.obtenerIdUsuario();
    recepcion.CCO_FECUPD = new Date();

    const maxDate = new Date(parseInt(this.anioPeriodo), parseInt(this.mesPeriodo), 0);

    recepcion.CCO_FECREG = maxDate;
    recepcion.CCO_FECVEN = recepcion.CCO_FECEMI;
    if (recepcion.CCO_FECEMI == recepcion.CCO_FECVEN) recepcion.CPA_CODCPA = '112';

    // TODO: Validaciones pendientes
    // if (INVOICE["CAC:PAYMENTTERMS"]) {
    //   const formaPago = INVOICE["CAC:PAYMENTTERMS"].find(item => item["CBC:ID"] == 'FormaPago');
    //   if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Contado') {
    //     recepcion.CPA_CODCPA = '112';
    //   }

    //   if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Credito') {

    //     console.log('formapago', formaPago)
    //     const fecVen = INVOICE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'] ?
    //       new Date(INVOICE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]).toUTCString()
    //       : new Date(INVOICE["CAC:PAYMENTTERMS"][2]['CBC:PAYMENTDUEDATE'][0]).toUTCString();
    //     const fecToday = new Date().toUTCString();
    //     recepcion.CCO_FECVEN = INVOICE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'] ?
    //       new Date(INVOICE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]) :
    //       new Date(INVOICE["CAC:PAYMENTTERMS"][2]['CBC:PAYMENTDUEDATE'][0]);
    //     console.log('fecha hoy', fecToday)
    //     console.log('fecha ven', fecVen)

    //     const dias = retornaDiferenciaDias(fecVen, fecToday);
    //     console.log('dias', dias)
    //   }

    // }

    console.log('recepcion', recepcion);
    // this.loaderEnvioRecepcion = false;

    const cliente = this._clientService.obtenerCliente(recepcion.CLI_CODCLI.trim()).subscribe(
      cliente => {
        this.registrarRecepcion$ = this._recepcionService.registrarRecepcion(recepcion).subscribe(
          response => {

            const filtrados = this.dataSourceDocumentos.data.filter(item => item.ruc + item.serie + item.numeroDoc != this.documentoAEnviar);
            console.log(filtrados)
            this.dataSourceDocumentos = fillTable(filtrados, this.paginatorDocumentos, this.sortDocumentos)
            this.actualizarRecepciones.emit([]);
            this._snackBarService.showSuccess('Recepción registrada', 'Ok');
            this.loaderEnvioRecepcion = false;
          },
          error => {
            this._snackBarService.showError('Error al registrar recepción', 'Ok');
            this.loaderEnvioRecepcion = false;
          }
        )

      },
      error => {

        this._sunatService.obtenerDatosDesdeRUC(recepcion.CLI_CODCLI).subscribe(
          datos => {
            console.log('datos', datos)

            const proveedor = new Proveedor()
            proveedor.cli_codcli = recepcion.CLI_CODCLI;
            proveedor.cli_numruc = recepcion.CLI_CODCLI;
            proveedor.cli_nomcli = datos.nombre;
            proveedor.cli_dircli = datos.direccion;
            proveedor.cli_nomcom = datos.nombre;
            proveedor.cli_numtlf = datos.numero;
            proveedor.cli_tipper = recepcion.CLI_CODCLI.substring(0, 2) == '20' ? 'J': 'N';
            proveedor.cia_codcia = this._configurationService.obtenerCompaniaCliente();
            proveedor.cli_coduse = this._configurationService.obtenerIdUsuario();
            // const ubigeo = this.ubigeos.find(item => item.ubs_descri == `${ruc.proveedor.departamento}, ${ruc.proveedor.provincia}, ${ruc.proveedor.distrito}`);
            // proveedor.ubs_codubs = '01';
            // proveedor.ubi_codubi = '01';

            this._proveedorService.registrarProveedor(proveedor).subscribe(
              proveedor => {

                this._snackBarService.showSuccess('Nuevo proveedor registrado', 'Ok');

                this.registrarRecepcion$ = this._recepcionService.registrarRecepcion(recepcion).subscribe(
                  response => {

                    const filtrados = this.dataSourceDocumentos.data.filter(item => item.ruc + item.serie + item.numeroDoc != this.documentoAEnviar);
                    console.log(filtrados)
                    this.dataSourceDocumentos = fillTable(filtrados, this.paginatorDocumentos, this.sortDocumentos)
                    this.actualizarRecepciones.emit([]);
                    this._snackBarService.showSuccess('Recepción registrada', 'Ok');
                    this.loaderEnvioRecepcion = false;

                  },
                  error => {
                    this._snackBarService.showError('Error al registrar recepción', 'Ok');
                    this.loaderEnvioRecepcion = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al registrar proveedor', 'Ok');
                this.loaderEnvioRecepcion = false;
              }
            )

          },
          error => {
            this._snackBarService.showError('Error al obtener datos desde RUC', 'Ok');
            this.loaderEnvioRecepcion = false;
          }
        )

      }
    )

  }

  cargarSIRE(): void {

    this.selection.clear();
    this.dataSourceDocumentos = fillTable([], this.paginatorDocumentos, this.sortDocumentos)

    this.loaderSIRE = true;

    const payload = {
      codcia: this._configurationService.obtenerCompaniaCliente(),
      codano: this.anioPeriodo,
      codmes: this.mesPeriodo,
      coduse: this._configurationService.obtenerIdUsuario(),
      codtmo: 'SO',
    }


    this.obtenerRecepciones$ = this._recepcionService.listarRecepcion(this.anioPeriodo, this.mesPeriodo).subscribe(
      recepciones => {
        this.recepciones = recepciones;

        this.obtenerRegistrosCompra$ = this._comprasService.listarRegistrosDeCompraPeriodo(this.anioPeriodo, this.mesPeriodo).subscribe(
          registrosCompra => {
            this.registrosCompra = registrosCompra.filter(item => item.ano_codano == this.anioPeriodo && item.mes_codmes == this.mesPeriodo);

            this.consultaSIRE$ = this._registroCompraService.obtenerComprasSIRE(payload).subscribe(
              data => {

                console.log('DATA SIRE', data)
                console.log('DATA SIRE str', JSON.stringify(data))

                if (data.length == 0) {
                  this.estadoSIRE$ = this._registroCompraService.obtenerEstadoSIRE(this.anioPeriodo, this.mesPeriodo).subscribe(
                    estadoRegistro => {
                      if (estadoRegistro.length > 0) {
                        if (estadoRegistro) {
                          this._snackBarService.showError(`Consulta SIRE, Ticket ${estadoRegistro[0].cst_desepr}, por favor vuelva a intentarlo más tarde.`, 'Ok')

                        }
                      }
                      this.loaderSIRE = false;
                      this.selection.clear();
                    },
                    error => {
                      this.loaderSIRE = false;
                    }
                  )
                } else {

                  data.forEach(element => {
                    element['ruc'] = element.psc_numide;
                    element['codofi'] = element.psc_tipdoc;
                    // element['tipo'] = element.psc_tipdoc;
                    element['serie'] = element.psc_serdoc;
                    element['numeroDoc'] = element.psc_numini;
                    element['razonSocial'] = element.psc_proraz;
                    element['fechaEmision'] = element.psc_fecemi;
                    element['fechaVencimiento'] = element.psc_fecven;
                    element['moneda'] = element.psc_codtmo;
                    element['importe'] = element.psc_imptot;
                    element['id'] = element['ruc'] + element['codofi'] + element['numeroDoc'];
                  });

                  const documentosSire = data.filter(item => item)

                  // filtrar documentos SIRE que no se encuentran en el registro de compras o en recepcion
                  console.log('antes del filtro', documentosSire)
                  const modificados = this.establecerEstadoRecepcionORegistroCompra(documentosSire)
                  console.log('modificados', modificados)
                  const filtrados = this.filtrarPorRecepcionORegistroCompra(documentosSire)

                  this.dataSourceDocumentos = fillTable(filtrados, this.paginatorDocumentos, this.sortDocumentos);
                  console.log('data filtrados', JSON.stringify(filtrados))
                  this.loaderSIRE = false;

                }

              },
              error => {
                console.log('error', error)
                this.loaderSIRE = false;
              }
            )
          })
      }
    )


  }

  agregarDocumento(): void {
    const dialogRef = this.dialog.open(ModalAgregarDocumentoDialog, {
      data: this.tiposDoc,
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataSourceDocumentos.data.push(result);

        const data = this.dataSourceDocumentos.data;

        this.dataSourceDocumentos = fillTable(data, this.paginatorDocumentos, this.sortDocumentos);

        this.documento = new DocumentoADescargar();
      }
    });
  }

  establecerEstadoRecepcionORegistroCompra(documentos: Array<any>): Array<any> {

    console.log('recepciones', this.recepciones)
    console.log('registrosCompra', this.registrosCompra)
    // Filtrar en registro de compras
    for (let index = 0; index < documentos.length; index++) {
      const item = documentos[index];

      const documentoRC = this.registrosCompra.find(doc => {

        return (parseInt(doc.cco_numdoc.substring(4), 10).toString() == item.numeroDoc.toString()) && (item.psc_numide == doc.cli_codcli);
      });

      if (documentoRC) {
        item['estado'] = 'EN_REGISTRO_COMPRA'
        continue;
      } else {
        const documentoRE = this.recepciones.find(doc => {

          // return doc.CCO_NUMDOC == item.serie + this.completarCerosIzquierda(item.numeroDoc)
          return ((parseInt(doc.CCO_NUMDOC.substring(4), 10).toString()) == item.numeroDoc.toString()) && (item.psc_numide == doc.CLI_CODCLI);
        });

        if (documentoRE) {
          item['estado'] = 'EN_RECEPCIÓN'
          continue;

        }

      }

    }

    return documentos;

  }

  filtrarPorRecepcionORegistroCompra(documentos: Array<any>): Array<any> {

    console.log('recepciones', this.recepciones)

    // Filtrar en registro de compras
    const filtrados = documentos.filter(item => item.estado != 'EN_RECEPCIÓN' && item.estado != 'EN_REGISTRO_COMPRA')

    return filtrados;

  }

  completarCerosIzquierda(numero) {
    // Convierte el número a cadena
    let numeroStr = numero.toString();

    // Calcula la cantidad de ceros necesarios
    let cerosNecesarios = 8 - numeroStr.length;

    // Completa ceros a la izquierda
    let numeroCompleto = '0'.repeat(cerosNecesarios) + numeroStr;

    return numeroCompleto;
  }

  obtenerListadoNoSeleccionados(): Array<any> {

    let listaNoSeleccionados = [];

    this.dataSourceDocumentos.data.forEach(item => {
      let existe = false;
      this.selection.selected.forEach(element => {
        if ((item['psc_serdoc'] + item['psc_numini']) == element.psc_serdoc + element.psc_numini) {
          existe = true;
        }
      })

      if (!existe) {
        listaNoSeleccionados.push(item)
      }
    });

    return listaNoSeleccionados;

  }

  async pruebas() {
    const documentos = [
      {
        ruc: "20608862910",
        codofi: '01',
        serie: "E001",
        numeroDoc: "249"
      },
      {
        ruc: "20551093035",
        codofi: '01',
        serie: "F002",
        numeroDoc: "106927"
      },
      {
        ruc: "20101266819",
        codofi: '01',
        serie: "F038",
        numeroDoc: "164191"
      },
    ]

    for (let index = 0; index < documentos.length; index++) {
      const element = documentos[index];

      const a = await this._contefactService.obtenerXMLBase64CONTEFACTIndividual(element);
      console.log('a', a)
    }

  }

  enviarDocumentosACargaMasiva(): void {

    console.log('no listados', this.obtenerListadoNoSeleccionados())

    if (this.selection.selected.length > 10) {
      this._snackBarService.showError('Solo se puede seleccionar hasta 10 documentos', 'Ok');
      return;
    }

    this.loaderEnviarCargaMasiva = true;
    // Seleccionar todas las facturas que comiencen con serie F
    const documentosFiltrados = this.selection.selected.filter(item => item);

    if (documentosFiltrados.length == 0) {
      this._snackBarService.showError('No hay documentos seleccionados para procesar datos', 'Ok')
      this.loaderEnviarCargaMasiva = false;
      return;

    }

    //Convertir XML base64 a string
    const payload = {
      documentos: documentosFiltrados
    }

    this.datosXML$ = this._contefactService.obtenerXMLBase64CONTEFACT(payload).subscribe(
      (documentosXML: Array<{ rspta: string, cpe: string, estado: string, xml: string, cdr: string, documento?: string, error?: any }>) => {
        console.log('documentosXML', documentosXML)

        // Filtrar validez de documentos
        const documentosValidos = [];
        const documentosNoValidos = [];

        documentosXML.forEach(documento => {

          if (documento.rspta == 'SI') {
            documentosValidos.push(documento)
          } else {
            console.log('INFO', documento)
            documentosNoValidos.push({ cpe: documento.error, documento: documento.documento })
          }

        });

        documentosValidos.forEach(item => {

          item['xmlStr'] = atob(item.xml);
          item['cia_codcia'] = this._configurationService.obtenerCompaniaCliente();
          item['cfe_coduse'] = this._configurationService.obtenerIdUsuario();

        })

        console.log('(documentosValidos, documentosNoValidos)', documentosValidos, documentosNoValidos)
        const files = documentosValidos.map(item => FileUpload.mapXMLFromDocument(item));
        console.log('files', files)

        console.log('data', documentosXML);

        if (documentosValidos.length > 0) {
          const zip = new JSZip();
          // Step 1: Decode Base64 data
          const decodedDataArray = documentosValidos.map((documento) => atob(documento.xml));

          // Step 2: Create a Zip archive using JSZip
          decodedDataArray.forEach((decodedData, index) => {
            zip.file(`${documentosValidos[index].cpe}`, decodedData);
          });

          // Step 3: Generate the zip file
          zip.generateAsync({ type: 'blob' }).then((content) => {
            // Step 4: Trigger the download
            const blob = new Blob([content], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = `descarga-xmls-${this.anioPeriodo}-${this.mesPeriodo}`;
            document.body.appendChild(a);
            a.click();

            // Clean up
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          });

        }
        // if (documentosValidos.length > 0) {
        //   this.moverCargaMasiva.emit(documentosValidos);
        // } else {
        //   this._snackBarService.showError('No se encontraron documentos validos', 'Ok')
        // }

        // return;
        this.registrarDocumentosXML$ = this._fileService.registrarDocumentosMasivo({ files }).subscribe(
          response => {

            this.documentosValidos = documentosValidos;
            this.documentosNoValidos = documentosNoValidos;
            console.log('documentosValidos', documentosValidos)
            console.log('documentosNoValidos', documentosNoValidos)

            this.dataSourceDocumentos = fillTable(this.obtenerListadoNoSeleccionados(), this.paginatorDocumentos, this.sortDocumentos);
            this.selection.clear();
            this.loaderEnviarCargaMasiva = false;

            if (documentosValidos.length > 0) {
              this.moverCargaMasiva.emit(documentosValidos);
            } else {
              this._snackBarService.showError('No se encontraron documentos validos', 'Ok')
            }

            return;
          },
          error => {
            this._snackBarService.showError('Error al registrar documentos xml', 'Ok')
            this.loaderEnviarCargaMasiva = false;
          }
        )

      }
    )

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceDocumentos.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    console.log('this.isAllSelected()', this.isAllSelected())
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSourceDocumentos.data.slice(0, 10));
  }

  clearAllRows() {
    this.selection.clear();
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  numeroConDosDecimales(numero): string {

    return numberWithCommas(numero.toFixed(2));

  }

  ngOnDestroy(): void {

    unsubscribeSubscription([
      this.tiposDoc$,
      this.obtenerXMLMasivo$,
      this.serie$,
      this.datosXML$,
      this.consultaSIRE$,
      this.loadingStore$,
      this.periodStore$,
      this.registrarDocumentosXML$,
      this.obtenerRecepciones$,
      this.obtenerRegistrosCompra$,
    ])

  }

}
