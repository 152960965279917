import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { Proveedor } from 'src/app/models';
import { AuthenticationService, GeneralService, ComprasService, VentasService, ConfigurationService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { fillTable } from '@utils/tables/table';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { DialogService } from '@shared/services/dialog.service';
import { ClienteContactoFormDialog, ClienteEstablecimientoFormDialog } from 'src/app/admin/apps/ventas/pages/maestros/cliente';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { BancosCBP, ServiceSupplier } from 'src/app/models/compras/provider';
import { SunatService } from 'src/app/services/api/sunat/sunat.service';
import { ProveedorService } from 'src/app/services/api/compras/maestros/proveedor.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { CargoService } from 'src/app/services/api/nomina/maestros/cargo.service';
import { ContefactService } from 'src/app/services/api/others/contefact.service';

@Component({
  selector: 'app-proveedor-form',
  templateUrl: './proveedor-form.component.html',
  styleUrls: ['./proveedor-form.component.css']
})
export class ProveedorFormComponent implements OnInit, OnDestroy, OnChanges {

  @Input() id: string;
  @Input() idARegistrar: string;
  @Input() ngClassTitle: string = 'pav-form';
  @Input() ngStyleControls: any;
  @Input() isDialog: boolean = false;
  @Output() closeDialog: EventEmitter<boolean>;

  loaderReg: boolean = false;
  loaderData: boolean = false;
  loaderBuscarRUC: boolean = false;

  proveedor: Proveedor;

  tiposPersona: any[] = [];
  tiposDocumento: any[] = [];
  paises: any[] = [];
  ubicaciones: any[] = [];
  condiciones: any[] = [];
  ubigeos: any[] = [];
  bancos: any[] = [];
  zonas: any[] = [];
  cargos: any[] = [];
  servicios: any[] = [];

  fecha: Date = new Date();
  usuario: any;

  displayedColumnsServicios: string[] = ['acciones', 'codigo'];
  dataSourceServicios: MatTableDataSource<any>;

  @ViewChild('paginatorServicios') paginatorServicios: MatPaginator;
  @ViewChild(MatSort) sortServicios: MatSort;

  displayedColumnsContactos: string[] = ['acciones', 'codigo', 'nombre', 'cargo', 'telefono', 'correo', 'fecha_nacimiento', 'referencia', 'cobranza'];
  dataSourceContactos: MatTableDataSource<any>;

  @ViewChild("paginatorContactos") paginatorContactos: MatPaginator;
  @ViewChild(MatSort) sortContactos: MatSort;

  displayedColumnsEstablecimientos: string[] = ['acciones', 'codigo', 'principal', 'direccion', 'referencia', 'pais', 'ubicacion', 'zona'];
  dataSourceEstablecimientos: MatTableDataSource<any>;

  @ViewChild("paginatorEstablecimientos") paginatorEstablecimientos: MatPaginator;
  @ViewChild(MatSort) sortEstablecimientos: MatSort;

  displayedColumnsCuenta: string[] = ['acciones', 'correlativo', 'banco', 'moneda', 'nro_cuenta', 'cuenta_interbancaria', 'glosa'];
  dataSourceCuenta: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorCuenta: MatPaginator;
  @ViewChild(MatSort) sortCuenta: MatSort;

  tipoPersona$: Subscription;
  tipoDocumentoIdentidad$: Subscription;
  pais$: Subscription;
  ubicacion$: Subscription;
  condicionPago$: Subscription;
  ubigeo$: Subscription;
  cargos$: Subscription;
  zonas$: Subscription;
  bancos$: Subscription
  loading$: Subscription;
  datosRuc$: Subscription;
  sendForm$: Subscription

  condicionPago: any
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  constructor(
    public dialog: MatDialog,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _generalService: GeneralService,
    private _proveedorService: ProveedorService,
    private _ventasService: VentasService,
    private _contefactService: ContefactService,
    private _cargoService: CargoService,
    private _configurationService: ConfigurationService,
    private _comprasService: ComprasService,
    private _sunatService: SunatService,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _clienteService: ClienteService,
    private store: Store<PavsoState>
  ) {

    this.closeDialog = new EventEmitter<boolean>();

    this.proveedor = new Proveedor();

    this.proveedor.cli_indpro = 1;
    this.proveedor.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.proveedor.cli_coduse = this._configurationService.obtenerIdUsuario();
    this.proveedor.dato_clc.CLC_CODUSE = this._configurationService.obtenerIdUsuario();

    this.usuario = this._authService.getUsuarioSistema();

    this.dataSourceServicios = fillTable([], this.paginatorServicios, this.sortServicios);

    this.dataSourceContactos = fillTable([], this.paginatorContactos, this.sortContactos);

    this.dataSourceCuenta = fillTable([], this.paginatorCuenta, this.sortCuenta);

    this.dataSourceEstablecimientos = fillTable([], this.paginatorEstablecimientos, this.sortEstablecimientos);

    this.condicionPago = "";
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes proveedor', changes)
    this.isDialog = changes.isDialog.currentValue;

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) {
        this.loadData()
      };
    })
    console.log('id', this.id)
    console.log('idARegistrar', this.idARegistrar)
    this.id ? console.log('es un dialogo', this.id) : console.log('no es un dialogo', this.id);
    console.log('uid', this.uid)
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;

      if (!this.uid) this.uid = this.id;

      this.loadMaestro();

      if (this.uid == '0') return;

    })
  }

  loadMaestro() {
    this.loaderData = true;

    this.tipoPersona$ = this._generalService.listarTipoDePersona().subscribe(
      tiposPersona => {
        this.tiposPersona = tiposPersona;
        console.log('tiposPersona', this.tiposPersona)

        this.tipoDocumentoIdentidad$ = this._generalService.listarTipoDocumentoIdentidad().subscribe(
          tiposDocumento => {
            this.tiposDocumento = tiposDocumento;
            this.tiposDocumento.forEach(item => {
              item['DID_CODDID'] = item['DID_CODDID'].trim();
              item['DID_DESABR'] = item['DID_DESABR'].trim();
              item['DID_DESCRI'] = item['DID_DESCRI'].trim();
            })
            console.log('tiposDocumento', this.tiposDocumento)

            this.pais$ = this._generalService.listarPaises().subscribe(
              paises => {
                this.paises = paises;

                this.ubicacion$ = this._generalService.listarUbicacion('01').subscribe(
                  ubicaciones => {
                    this.ubicaciones = ubicaciones;
                    console.log('ubicaciones', this.ubicaciones)

                    this.condicionPago$ = this._comprasService.obtenerCondicionesPago().subscribe(
                      condiciones => {
                        this.condiciones = condiciones;

                        this.ubigeo$ = this._generalService.listarUbigeos().subscribe(
                          ubigeos => {
                            console.log('ubigeos', ubigeos)
                            this.ubigeos = ubigeos;

                            this.cargos$ = this._cargoService.listarCargos().subscribe(
                              cargos => {
                                this.cargos = cargos;

                                this.bancos$ = this._generalService.listarBancos().subscribe(
                                  bancos => {
                                    this.bancos = bancos;
                                    console.log('bancos', this.bancos)

                                    this.zonas$ = this._ventasService.obtenerZonasVenta().subscribe(
                                      zonas => {
                                        this.zonas = zonas;
                                        this.loaderData = false;

                                        if (this.uid != '0') {
                                          this.obtenerProveedor();
                                          return;
                                        };

                                        if (this.id = '0' && this.idARegistrar) {
                                          console.log('INGRESOOOO')
                                          this.proveedor.cli_codcli = this.idARegistrar;
                                          this.proveedorARegistrar();
                                          return;
                                        }
                                      },
                                      error => {
                                        this._snackBarService.showError('Error al obtener zonas', 'Ok');
                                        this.loaderData = false;
                                      }
                                    )
                                  },
                                  error => {
                                    this._snackBarService.showError('Error al obtener bancos', 'Ok');
                                    this.loaderData = false;
                                  }
                                )
                              },
                              error => {
                                this._snackBarService.showError('Error al obtener cargos', 'Ok');
                                this.loaderData = false;
                              }
                            )
                          },
                          error => {
                            this._snackBarService.showError('Error al obtener ubigeos', 'Ok');
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener condiciones de pago', 'Ok');
                        this.loaderData = false;
                      }
                    ),
                      error => {
                        this._snackBarService.showError('Error al obtener condiciones de pago', 'Ok');
                        this.loaderData = false;
                      }
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener ubicaciones', 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener paises', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener tipos de documento', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener tipos de persona', 'Ok');
        this.loaderData = false;
      }
    )

  }

  proveedorARegistrar(): void {
    this.datosRuc$ = this._sunatService.obtenerDatosDesdeRUC(this.idARegistrar).subscribe(
      datos => {
        console.log('datos desde ruc', datos)

        this.proveedor.cli_nomcli = datos.nombre;

        this.proveedor.cli_tipper = "J";
        this.proveedor.did_coddid = "001";
        this.proveedor.cli_numruc = datos.numeroDocumento;
        this.proveedor.ubs_codubs = datos.ubigeo;
        this.proveedor.cli_dircli = datos.direccion;
        this.proveedor.pai_codpai = '01';
        this.proveedor.cli_indpro = 1;
        this.proveedor.cia_codcia = this._configurationService.obtenerCompaniaCliente();
        this.proveedor.cli_coduse = this._configurationService.obtenerIdUsuario();

      },
      error => {
        this._snackBarService.showError('Error al obtener datos desde RUC', 'Ok');
      }
    )
  }

  obtenerProveedor(): void {
    this._clienteService.obtenerCliente(this.uid).subscribe(
      proveedor => {
        console.log('proveedor', proveedor)
        this.proveedor = proveedor;
      },
      error => {
        console.log('error', error)
      }
    )
    // forkObs(this._clienteService.obtenerCliente(this.uid)).then(([cliente]) => {
    // })
  }

  prueba(): void {
    this.closeDialog.emit(true);
  }

  buscarEnRENIEC(): void {
    this.loaderBuscarRUC = true;
    this._contefactService.obtenerDatosRUCCONTEFACT(this.proveedor.cli_codcli).subscribe(
      response => {
        console.log('response cliente', response)
        this.loaderBuscarRUC = false;
        if(response.ruc && response.ruc != '') {
          this.proveedor = Proveedor.fromCONEFACTToProvider(response);

          this.proveedor.cia_codcia = this._configurationService.obtenerCompaniaCliente();
          this.proveedor.cli_coduse = this._configurationService.obtenerIdUsuario();

          this.seleccionarTipoPersona('001');
          this.proveedor.did_coddid = "001";

          this.proveedor.pai_codpai = '01';
          const ubicacion = this.ubicaciones.find(item => item.desubi == `${response.departamento}-${response.provincia}-${response.distrito}`);
          const ubigeo = this.ubigeos.find(item => item.ubs_descri == `${response.departamento}, ${response.provincia}, ${response.distrito}`);
          console.log('ubicacion', ubicacion)
          console.log('ubigeo', ubigeo)
          if(ubicacion) this.proveedor.ubi_codubi = ubicacion.codubi;
          if(ubigeo) this.proveedor.ubs_codubs = ubigeo.ubs_codubs;

        }
      },
      error => {
        this._snackBarService.showError('No se encontraron datos para este documento', 'Ok');
        this.loaderBuscarRUC = false;
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarProveedor(f) : this.actualizarProveedor(f);
  }

  registrarProveedor(f: NgForm): void {

    if (this.proveedor.cli_numruc) {
      this.proveedor.cli_codcli = this.proveedor.cli_numruc;
    }

    if (this.proveedor.cli_numdni) {
      this.proveedor.cli_codcli = this.proveedor.cli_numdni;
    }

    // console.log('proveedor', this.proveedor)
    // this.loaderReg = false;
    // return;

    this.sendForm$ = this._proveedorService.registrarProveedor(this.proveedor).subscribe(
      response => {
        console.log('response', response)
        const dialog = this._dialogService.openDialog(SuccessComponent, 'Proveedor Registrado', '350px', '', '');

        if (this.idARegistrar) {
          dialog.subscribe(result => {
            this.closeDialog.emit(true);
          })
        }
      },
      error => {
        this._snackBarService.showError('Error al registrar proveedor', 'Ok');
      }
    )
  }

  actualizarProveedor(f: NgForm): void {

    this.sendForm$ = this._proveedorService.editarProveedor(this.proveedor).subscribe(
      response => {
        console.log('response', response)
        this._dialogService.openDialog(SuccessComponent, 'Proveedor Actualizado', '300px', '', '');
      },
      error => {
        this._snackBarService.showError('Error al actualizar proveedor', 'Ok');
      }
    )

  }

  validarDni(): void {
    let dniStr = this.proveedor.cli_numdni.toString();

    if (dniStr.length > 8) this.proveedor.cli_numdni = dniStr.substr(0, dniStr.length - 1);
  }

  validarRuc(): void {

    let rucStr = this.proveedor.cli_numruc.toString();

    if (rucStr.length > 11) this.proveedor.cli_numruc = rucStr.substr(0, rucStr.length - 1);

  }

  seleccionarTipoDocumento(value): void {

    // if (value == "001") {
    //   this.proveedor.cli_tipper = "J";
    //   return;
    // }

    // this.proveedor.cli_tipper = "N";

  }

  seleccionarPais(codpai): void {
    if (codpai != '01') this.proveedor.ubi_codubi = null;
    if (codpai != '01') this.proveedor.ubs_codubs = null;
  }

  seleccionarTipoPersona(valor): void {
    // if (valor == 'J') {
    //   this.proveedor.did_coddid = '001';
    //   return;
    // }

    // this.proveedor.did_coddid = '000';
  }

  seleccionarServicio($event, row: ServiceSupplier): void {
    const servicio = this.servicios.find(item => item.TSE_CODTSE == $event.value);
    row.tse_descri = servicio.TSE_DESTSE;
  }

  agregarCuenta(): void {
    const cuentaBancaria = new BancosCBP();

    this.proveedor.detalle_bancos_cbp.push(cuentaBancaria);

    this.dataSourceCuenta = fillTable(this.proveedor.detalle_bancos_cbp, this.paginatorCuenta, this.sortCuenta);

    this.proveedor.detalle_bancos_cbp.forEach((element, key) => {
      element.cbp_corcbp = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    console.log('proveedor.detalle_cuenta_bancaria', this.proveedor.detalle_bancos_cbp)

  }

  quitarCuenta(row): void { }

  agregarContacto(contacto): void {

    const dialogRef = this.dialog.open(ClienteContactoFormDialog, {
      data: { contacto, cargos: this.cargos, cliente: this.proveedor },
      width: '750px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(({ contacto, esNuevo }) => {
      console.log('contacto', contacto)
      console.log('esNuevo', esNuevo)
      if (esNuevo) {
        const cargo = this.cargos.find(item => item.cca_codcca == contacto.cca_codcca);
        contacto.cca_desabr = cargo ? cargo.cca_descri : "";

        this.proveedor.detalle_contacto.push(contacto);
        this.dataSourceContactos = fillTable(this.proveedor.detalle_contacto, this.paginatorContactos, this.sortContactos);
        return;
      }

      this.proveedor.detalle_contacto.forEach(item => {
        if (item.ccl_codccl == contacto.ccl_codccl) {
          item = contacto;
        }
      })
    });

  }

  quitarContacto(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if (result) {
        const filtered = this.dataSourceContactos.data.filter(item => item.ccl_codccl != row.ccl_codccl);
        filtered.forEach((element, key) => {
          element['ccl_codccl'] = key;
        })
        this.proveedor.detalle_contacto = filtered;
        this.dataSourceContactos = fillTable(this.proveedor.detalle_contacto, this.paginatorContactos, this.sortContactos);
      }
    })
  }

  agregarEstablecimiento(establecimiento): void {

    console.log('establecimiento', establecimiento)
    const dialogRef = this.dialog.open(ClienteEstablecimientoFormDialog, {
      data: { establecimiento, paises: this.paises, ubicaciones: this.ubicaciones, zonas: this.zonas, cliente: this.proveedor },
      width: '750px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(({ establecimiento, esNuevo }) => {
      console.log('establecimiento', establecimiento)
      if (esNuevo) {
        const pais = this.paises.find(item => item.PAI_CODPAI == establecimiento.PAI_CODPAI);
        establecimiento.PAI_DESPAI = pais ? pais.pai_nomlar : '';
        console.log('pais', pais)
        const ubicacion = this.ubicaciones.find(item => item.codubi == establecimiento.UBI_CODUBI);
        establecimiento.UBI_DESUBI = ubicacion ? ubicacion.desubi : '';
        console.log('ubicacion', ubicacion)
        const zona = this.zonas.find(item => item.zve_codzve == establecimiento.ZVE_CODZVE);
        establecimiento.ZVE_DESZVE = zona ? zona.zve_nomzve : '';
        console.log('zona', zona)
        console.log('establecimiento', establecimiento);
        this.proveedor.detalle_lugar_despacho.push(establecimiento);
        this.dataSourceEstablecimientos = fillTable(this.proveedor.detalle_lugar_despacho, this.paginatorEstablecimientos, this.sortEstablecimientos);
        return;
      }

      this.proveedor.detalle_lugar_despacho.forEach(item => {
        if (item.LDE_CODLDE == establecimiento.LDE_CODLDE) {
          item = establecimiento;
        }
      })

    });

  }

  confirmarEstablecimiento(row): void {
    if (!row.LDE_CODLDE || !row.LDE_DIRLDE || !row.LDE_REFDIR || !row.PAI_CODPAI || !row.UBI_CODUBI || !row.ZVE_CODZVE) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

  }

  quitarEstablecimiento(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if (result) {
        const filtered = this.dataSourceEstablecimientos.data.filter(item => item.LDE_CODLDE != row.LDE_CODLDE);
        filtered.forEach((element, key) => {
          element['LDE_CODLDE'] = key;
        })
        this.proveedor.detalle_lugar_despacho = filtered;
        this.dataSourceEstablecimientos = fillTable(this.proveedor.detalle_lugar_despacho, this.paginatorEstablecimientos, this.sortEstablecimientos);
      }
    })
  }

  agregarServicio(): void {

    let item = new ServiceSupplier();

    this.proveedor.detalle_tipser_prov.push(item);

    this.proveedor.detalle_tipser_prov.forEach((element, key) => {
      element['correlativo'] = key;
    })

    this.dataSourceServicios = fillTable(this.proveedor.detalle_tipser_prov, this.paginatorServicios, this.sortServicios);
  }

  editarServicio(row): void {
    row.isEditing = !row.isEditing;
  }

  confirmarServicio(row: ServiceSupplier): void {
    if (!row.tse_codtse) {
      this._snackBarService.showError('Todos los campos de fila deben estar completos', 'Ok')
      return;
    }

    row.isEditing = !row.isEditing;

  }

  quitarServicio(row): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {
      if (result) {
        const filtered = this.dataSourceServicios.data.filter(item => item.tse_codtse != row.tse_codtse);

        this.proveedor.detalle_tipser_prov = filtered;

        this.dataSourceServicios = fillTable(filtered, this.paginatorServicios, this.sortServicios);
      }
    })
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoPersona$,
      this.tipoDocumentoIdentidad$,
      this.pais$,
      this.ubicacion$,
      this.condicionPago$,
      this.ubigeo$,
    ])

  }

}
