export class FiltroReporteCobranza {
  clientes: Array<any>;
  vendedores: Array<any>;
  tiposDocumento: Array<any>;
  saldo: any;
  ordenadoPor: any;
  rango: any;
  criterio: 'ABONADO' | 'CON_SALDO' | 'CANCELADOS' | 'TODOS';
  soloCuenta12: boolean;

  constructor () {
    this.saldo = 'todos';
    this.clientes = [];
    this.vendedores = [];
    this.tiposDocumento = [];
    this.criterio = 'CON_SALDO';
    this.soloCuenta12 = true;
  }

}
