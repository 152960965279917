import { NgModule } from '@angular/core';
import { VentasComponent } from './ventas.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { informesComponentsVentas } from './pages/informes';
import { maestrosComponentsVentas, maestrosVentasModules } from './pages/maestros';
import { operacionesComponentsVentas, operacionesVentasModules } from './pages/operaciones';
import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { PanelVentasComponent } from './pages/panel/panel-ventas.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { configuracionModulesVentas, ventasConfiguracionesComponents } from './pages/configuracion';
import { ventasMonitoreoComponents } from './pages/monitoreo';
import { ventasConsultasComponents } from './pages/consultas';
import { ventasActualizacionesComponents } from './pages/actualizaciones';
import { pdpComponentsVentas } from './pages/precio-descuento-promocion';
import { NgApexchartsModule } from 'ng-apexcharts';
import { GraficoResumenAnualComponent } from './pages/panel/grafico-resumen-anual/grafico-resumen-anual.component';
import { DiagramaParetoComponent } from './pages/panel/diagrama-pareto/diagrama-pareto.component';
import { tablasApoyoComponentsVentas, tablasApoyoModulesVentas } from './pages/tabla-de-apoyo';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ProductoModule } from './pages/maestros/producto/producto.module';
import { ProductoSelect } from '../almacen/pages/maestros/producto';

@NgModule({
  declarations: [
    VentasComponent,
    PanelVentasComponent,
    GraficoResumenAnualComponent,
    DiagramaParetoComponent,
    ...informesComponentsVentas,
    ...tablasApoyoComponentsVentas,
    ...maestrosComponentsVentas,
    ...operacionesComponentsVentas,
    ...ventasConfiguracionesComponents,
    ...ventasMonitoreoComponents,
    ...ventasConsultasComponents,
    ...ventasActualizacionesComponents,
    ...pdpComponentsVentas
  ],
  exports: [],
  imports: [
    SharedModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxMatSelectSearchModule,
    NgApexchartsModule,
    ClipboardModule,
    MatTableExporterModule,
    ...maestrosVentasModules,
    ...operacionesVentasModules,
    ...tablasApoyoModulesVentas,
    ...configuracionModulesVentas,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ]
})
export class VentasModule {}
