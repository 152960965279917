<app-pavso-title-section [title]="'Dinámicos'" [module]="'Reporte'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <select-report [disabled]="loaderFields" [reportes]="reportes"
    (seleccionarReporte)="seleccionarReporte($event)" [reporteSeleccionado]="reporteSeleccionado[0]"></select-report>

  <div align="center" [ngStyle]="{marginBottom: '12px'}">
    <button class="pav-button-action" mat-flat-button color="primary" [class.spinner]="loading" [disabled]="loading"
      (click)="verReporteDinamico()">{{buttonsName.BTN_SHOW_REPORT | translate}}</button>
  </div>

  <div class="row">
    <div class="col s12 m12 l6">
      <div class="pav-filtro-header">
        <div class="pav-filtro-header-icon"></div>
        <div class="pav-filtro-header-title">Cabeceras</div>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderFields"></mat-progress-bar>
      <div class="pav-filtro-container">
        <div class="row">
          <div class="col s12">
            <section class="example-section" *ngIf="!loaderFields">
              <span class="example-list-section">
                <mat-checkbox class="example-margin" [checked]="allComplete" [indeterminate]="someComplete()"
                  (change)="setAll($event.checked)">
                  {{task.name}}
                </mat-checkbox>
              </span>
              <span class="example-list-section">
                <div class="row">
                  <ul>
                    <div *ngFor="let subtask of task.subtasks" class="col s12 m12 l4">
                      <li>
                        <mat-checkbox class="pav-cb-item" [(ngModel)]="subtask.completed"
                          (ngModelChange)="updateAllComplete()">
                          {{subtask.name}}
                        </mat-checkbox>
                      </li>

                    </div>
                  </ul>

                </div>
              </span>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div class="col s12 m12 l6">
      <div class="pav-filtro-header">
        <div class="pav-filtro-header-icon"></div>
        <div class="pav-filtro-header-title">Filtros</div>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderFields"></mat-progress-bar>
      <div class="pav-filtro-container">
        <div class="row">
          <div class="col s12 m12 l3" *ngIf="opcionFiltro.s26_indcli == 1">
            <select-search [label]="'Cliente'" [placeholder]="'Ej. PEREZ'" [name]="'cli_codcli'"
              [description]="'cli_nomcli'" [data]="clientes" (cambioSelect)="filtro.clientes = $event"
              [value]="filtro.clientes"></select-search>
          </div>
          <div class="col s12 m12 l3" *ngIf="opcionFiltro.s26_indvde == 1">

            <select-search [label]="'Vendedor'" [placeholder]="'Ej. PEREZ'" [name]="'VDE_CODVDE'"
              [description]="'VDE_NOMVDE'" [data]="vendedores" (cambioSelect)="filtro.vendedores = $event"
              [value]="filtro.vendedores"></select-search>

          </div>
          <div class="col s12 m12 l3" *ngIf="opcionFiltro.s26_indtdo == 1">

            <select-search [label]="'Tipo de documento'" [placeholder]="'Ej. BVE'" [name]="'TDO_CODTDO'"
              [description]="'TDO_DESLAR'" [data]="tiposDocumento" (cambioSelect)="filtro.tiposDocumento = $event"
              [value]="filtro.tiposDocumento"></select-search>

          </div>
          <div class="col s12 m12 l3" *ngIf="opcionFiltro.s26_indfec == 1">
            <mat-form-field>
              <mat-label>Rango de Fechas</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Saldo</mat-label>
              <mat-select [(ngModel)]="filtro.saldo" name="saldo">
                <mat-option value="todos">
                  Todos
                </mat-option>
                <mat-option value="con_saldo">
                  Con Saldo
                </mat-option>
                <mat-option value="cancelados">
                  Cancelados
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Ordenar por</mat-label>
              <mat-select>
                <mat-option value="vencimiento">
                  Vencimiento
                </mat-option>
                <mat-option value="emision">
                  Emisión
                </mat-option>
                <mat-option value="documento">
                  Documento
                </mat-option>
                <mat-option value="nro_registro">
                  Nro. Registro
                </mat-option>
                <mat-option value="cliente">
                  Cliente
                </mat-option>
                <mat-option value="vendedor">
                  Vendedor
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l3">
            <mat-form-field>
              <mat-label>Modo</mat-label>
              <mat-select>
                <mat-option value="solo12clientes">
                  Solo 12 Clientes
                </mat-option>
                <mat-option value="diferente12clientes">
                  Diferente 12 Clientes
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
