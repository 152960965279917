<div style="height: 1px" *ngIf="isMobile || isTablet"></div>
<app-pavso-title-section [title]="'Pedido'" [module]="'Ventas'" [usuario]="order.PCC_CODUSE" [fecha]="order.PCC_FECUPD"
  [ngClass]="'pav-form'" [estado]="order.PCC_INDSTA"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-tab-group [selectedIndex]="tabSeleccionado">
      <mat-tab label="Datos Generales" labelClass="mat-tab-label-0-0">
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div *ngIf="isTablet || isMobile" [ngStyle]="{display: 'flex'}">

              <div [ngStyle]="{width: '32%', marginRight: '5px'}">
                <mat-form-field class="example-full-width">
                  <mat-label>Pedido</mat-label>
                  <input [(ngModel)]="order.PCC_NUMPCC" type="number" matInput placeholder="Pedido" readonly
                    name="PCC_NUMPCC">
                </mat-form-field>
              </div>

              <div [ngStyle]="{width: '40%', marginRight: '5px'}">

                <mat-form-field>
                  <mat-label>Fecha</mat-label>
                  <input [min]="minDate" [max]="maxDate" [disabled]="loaderData" [(ngModel)]="order.PCC_FECDOC"
                    name="PCC_FECDOC" matInput [matDatepicker]="picker" placeholder="Fecha"
                    (dateChange)="seleccionarFecha()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

              </div>

              <div [ngStyle]="{width: '20%'}">
                <mat-form-field class="example-full-width" *ngIf="configuracionTecnicaVentas.s71_vertca == 1">
                  <mat-label>T.C.</mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" [value]="order.PCC_TIPCAM ? order.PCC_TIPCAM : 0"
                    name="PCC_TIPCAM" type="text" matInput placeholder="T.C." step="any" readonly>
                </mat-form-field>
              </div>
            </div>

            <div class="col s4 m6 l2" *ngIf="!isTablet && !isMobile">
              <mat-form-field class="example-full-width">
                <mat-label>Pedido</mat-label>
                <input [(ngModel)]="order.PCC_NUMPCC" type="number" matInput placeholder="Pedido" readonly
                  name="PCC_NUMPCC">
              </mat-form-field>
            </div>

            <div class="col s4 m6 l2" *ngIf="!isTablet && !isMobile">
              <mat-form-field>
                <mat-label>Fecha</mat-label>
                <input [min]="minDate" [max]="maxDate" [disabled]="loaderData" [(ngModel)]="order.PCC_FECDOC"
                  name="PCC_FECDOC" matInput [matDatepicker]="picker" placeholder="Fecha"
                  (dateChange)="seleccionarFecha()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col s4 m6 l1" *ngIf="!isTablet && !isMobile && configuracionTecnicaVentas.s71_vertca == 1">
              <mat-form-field class="example-full-width">
                <mat-label>T.C.</mat-label>
                <input [ngStyle]="{textAlign: 'right'}" [value]="order.PCC_TIPCAM ? order.PCC_TIPCAM : 0"
                  name="PCC_TIPCAM" type="text" matInput placeholder="T.C." step="any" readonly>
              </mat-form-field>
            </div>

            <div class="col s12 m6 l2" *ngIf="configuracionTecnicaVentas.s71_motped == 1">

              <select-search [disabled]="loaderData" [label]="'Motivo'" [placeholder]="'Ej. COMPRAS LOCALES'"
                [name]="'mmo_codmmo'" [value]="'mmo_codmmo'" [description]="'mmo_descri'" [data]="motivos"
                (cambioSelect)="order.MMO_CODMMO = $event; seleccionarMotivo()" [model]="order.MMO_CODMMO">
              </select-search>

            </div>

            <div class="col s6 m6 l1">
              <mat-form-field class="example-full-width">
                <mat-label>IGV (%)</mat-label>
                <input [disabled]="loaderData" [ngStyle]="{textAlign: 'right'}" [(ngModel)]="order.PCC_TASIGV"
                  name="PCC_TASIGV" type="number" matInput placeholder="Tas. IGV" step="any">
              </mat-form-field>
            </div>

            <div class="col s6 m6 l2">
              <select-search [disabled]="loaderData" [label]="'Vendedor'" [placeholder]="'Ej. GERENCIA'"
                [name]="'VDE_CODVDE'" [value]="'VDE_CODVDE'" [description]="'VDE_NOMVDE'" [data]="vendedores"
                (cambioSelect)="order.VDE_CODVDE = $event" [model]="order.VDE_CODVDE"></select-search>

            </div>

            <div class="col s6 m6 l4">
              <pav-input-search label="Cliente" placeholder="ej. Plaza Vea"
                (valueChange)="cliente = $event; seleccionarCliente($event.codigo); " [codigo]="order.CLI_CODCLI"
                [descripcion]="order.cliente.cli_nomcli" width="'100%'" [program]="'CLIENTES'"></pav-input-search>

              <!-- <cliente-select [disabled]="loaderData" [label]="'Cliente'" [placeholder]="'Ej. GERENCIA'"
                [name]="'cli_codcli'" [value]="'cli_codcli'" [description]="'description'" [data]="clientes"
                (cambioSelect)="order.CLI_CODCLI = $event;
                        seleccionarCliente($event)" [model]="order.CLI_CODCLI" [canAdd]="false" [canEdit]="false">
              </cliente-select> -->

            </div>

            <ng-container *ngIf="isMobile || isTablet">
              <div class="col s6 m6 l4">
                <mat-form-field class="example-full-width">
                  <mat-label>Direc. Fiscal</mat-label>
                  <input [disabled]="loaderData" type="text" matInput placeholder="Direc. Fiscal"
                    [(ngModel)]="direccion" name="direccion" readonly>
                </mat-form-field>

              </div>

            </ng-container>
            <!-- <div class="col s12 m2 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Documento de Identidad</mat-label>
                <input [disabled]="loaderData" readonly type="text" matInput placeholder="Ej. 20202020" [(ngModel)]="order.CLI_CODCLI" name="nroDocumento" soloNumerosText maxlength="11">
              </mat-form-field>
            </div> -->

            <div *ngIf="isMobile || isTablet" class="col s12" [ngStyle]="{display: 'flex'}">
              <div [style]="{paddingRight: '15px', width: '35%'}">

                <select-search [disabled]="loaderData" [label]="'Emitir Doc.'" [placeholder]="'Ej. BVE'"
                  [name]="'TDO_CODTDO'" [value]="'TDO_CODTDO'" [description]="'TDO_CODTDO'" [data]="tiposDocumento"
                  (cambioSelect)="order.TDO_CODTDO = $event" [model]="order.TDO_CODTDO"></select-search>

                <!-- <mat-form-field class="example-full-width">
                  <mat-label>Emitir Doc.</mat-label>
                  <input [disabled]="loaderData" type="text" matInput [(ngModel)]="order.TDO_CODTDO" name="TDO_CODTDO"
                    placeholder="Ej. FAC">
                </mat-form-field> -->
              </div>
              <div style="width: 60%;" *ngIf="configuracionTecnicaVentas.s71_vercot == 1">
                <select-search [disabled]="this.uid != '0' && !this.uid.includes('cotizacion')"
                  [label]="'Copiar de Cotizacion'" [placeholder]="'Ej. 2323323'" [name]="'PCC_NUMPCC'"
                  [value]="'PCC_NUMPCC'" [description]="'description'" [data]="cotizaciones"
                  (cambioSelect)="order.pcc_numcot = $event;" [model]="order.pcc_numcot" iconAction="get_app"
                  tooltipAction="Obtener datos de cotización" (clickAction)="obtenerDatosCotizacion()">
                </select-search>
              </div>
            </div>

            <ng-container *ngIf="!isMobile && !isTablet">
              <div class="col s4 m4 l1">
                <!-- <mat-form-field class="example-full-width">
                  <mat-label>Emitir Doc.</mat-label>
                  <input [disabled]="loaderData" type="text" matInput [(ngModel)]="order.TDO_CODTDO" name="TDO_CODTDO"
                    placeholder="Ej. FAC">
                </mat-form-field> -->
                <select-search [disabled]="loaderData" [label]="'Emitir Doc.'" [placeholder]="'Ej. BVE'"
                  [name]="'TDO_CODTDO'" [value]="'TDO_CODTDO'" [description]="'TDO_CODTDO'" [data]="this.tiposDocumento"
                  (cambioSelect)="order.TDO_CODTDO = $event" [model]="order.TDO_CODTDO"></select-search>
              </div>

              <div class="col s7 m7 l2" *ngIf="configuracionTecnicaVentas.s71_vercot == 1">
                <select-search [disabled]="this.uid != '0' && !this.uid.includes('cotizacion')"
                  [label]="'Copiar de Cotizacion'" [placeholder]="'Ej. 2323323'" [name]="'PCC_NUMPCC'"
                  [value]="'PCC_NUMPCC'" [description]="'description'" [data]="cotizaciones"
                  (cambioSelect)="order.pcc_numcot = $event;" [model]="order.pcc_numcot" iconAction="get_app"
                  tooltipAction="Obtener datos de cotización" (clickAction)="obtenerDatosCotizacion()">
                </select-search>
              </div>
            </ng-container>

            <div class="col s6 m6 l1">
              <app-currency-select [disable]="loaderData" [moneda]="order.TMO_CODTMO"
                (cambioMoneda)="order.TMO_CODTMO = $event"></app-currency-select>
            </div>

            <div class="col s6 m6 l2">
              <select-search [disabled]="loaderData || configuracionTecnicaVentas.s71_edicpa == 0"
                [label]="'Condición de pago'" [placeholder]="'Ej. CONTACTO'" [name]="'CPA_CODCPA'"
                [value]="'CPA_CODCPA'" [description]="'CPA_DESCRI'" [data]="condiciones"
                (cambioSelect)="order.CPA_CODCPA = $event" [model]="order.CPA_CODCPA"></select-search>

            </div>

            <div class="col s6 m6 l2">
              <select-search [disabled]="loaderData" [label]="'Almacén'" [placeholder]="'Ej. ALMACÉN P.T.'"
                [name]="'ALM_CODALM'" [value]="'ALM_CODALM'" [description]="'ALM_DESCRI'" [data]="almacenes"
                (cambioSelect)="order.ALM_CODALM = $event;" [model]="order.ALM_CODALM">
              </select-search>

            </div>

            <ng-container *ngIf="isMobile || isTablet">

              <div class="col s6 m6 l2">

                <mat-form-field>
                  <mat-label>Lugar de despacho</mat-label>
                  <mat-select [disabled]="loaderData" [(ngModel)]="order.LDE_CODFAC" name="LDE_CODFAC">
                    <mat-option *ngFor="let lugar of lugaresDespacho" [value]="lugar.LDE_CODLDE">
                      {{lugar.LDE_NOMLDE}} - {{lugar.LDE_DIRLDE}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
            </ng-container>

          </div>

          <div class="row">
            <ng-container *ngIf="!isMobile && !isTablet">
              <div class="col s12 m6 l4">
                <mat-form-field class="example-full-width">
                  <mat-label>Direc. Fiscal</mat-label>
                  <input [disabled]="loaderData" type="text" matInput placeholder="Direc. Fiscal"
                    [(ngModel)]="direccion" name="direccion" readonly>
                </mat-form-field>

              </div>

            </ng-container>

            <ng-container *ngIf="!isMobile && !isTablet">

              <div class="col s6 m6 l2">

                <mat-form-field>
                  <mat-label>Lugar de despacho</mat-label>
                  <mat-select [disabled]="loaderData" [(ngModel)]="order.LDE_CODFAC" name="LDE_CODFAC">
                    <mat-option *ngFor="let lugar of lugaresDespacho" [value]="lugar.LDE_CODLDE">
                      {{lugar.LDE_NOMLDE}} - {{lugar.LDE_DIRLDE}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
            </ng-container>

            <div class="col s6 m6 l2" *ngIf="configuracionTecnicaVentas.s71_verocm == 1">
              <mat-form-field class="example-full-width">
                <mat-label>Orden de Compra</mat-label>
                <input [disabled]="loaderData" [(ngModel)]="order.PCC_ORDCOM" name="PCC_ORDCOM" type="number" matInput
                  placeholder="O.Compra">
              </mat-form-field>
            </div>

            <div class="col s6 m6 l2">
              <mat-form-field>
                <mat-label>Fecha de entrega</mat-label>
                <input [min]="order.PCC_FECDOC" [disabled]="loaderData" [(ngModel)]="order.PCC_FECENT" name="PCC_FECENT"
                  matInput [matDatepicker]="picker1" placeholder="Entregar">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Observación</mat-label>
                <input [disabled]="loaderData" matInput placeholder="Observación" [(ngModel)]="order.PCC_OBSPCC"
                  name="PCC_OBSPCC" />
              </mat-form-field>

            </div>

          </div>
        </mat-card>

        <br>
        <mat-card class="mat-elevation-z0" [ngStyle]="{paddingTop: '0px', paddingBottom: '0px'}">
          <div class="row" style="display: flex; align-items: center;">
            <div class="col s12 m12 l2" [ngStyle]="{display: 'block', alignItems: 'center'}"
              style="border: 2px solid #dbdbdb; border-radius: 5px; padding: 8px 10px !important;  ">
              <div>
                <span class="pav-title-tag">Stk. Fisico: </span>
                <span class="pav-title-tag-content" [ngStyle]="{marginLeft: '5px'}">{{fisico}}</span>

              </div>
              <div>
                <span class="pav-title-tag">Stk. Disponible: </span>
                <span class="pav-title-tag-content" [ngStyle]="{marginLeft: '5px'}">{{disponible}}</span>
              </div>
              <div>
                <span class="pav-title-tag">Stk. Reservado: </span>
                <span class="pav-title-tag-content" [ngStyle]="{marginLeft: '5px'}">{{reservado}}</span>

              </div>
            </div>
            <ng-container *ngIf="!isMobile && !isTablet">
              <div class="col s12 m12 l2" [ngStyle]="{marginTop: '16px'}">
                <mat-form-field class="pav-total-field">
                  <mat-label>Imp. Bruto</mat-label>
                  <input type="number" matInput placeholder="Imp. Bruto" [ngStyle]="{textAlign: 'right'}"
                    [(ngModel)]="order.PCC_IMPBRU" name="PCC_IMPBRU" step="any" readonly>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l2" [ngStyle]="{marginTop: '16px'}">
                <mat-form-field class="pav-total-field">
                  <mat-label>Descuento</mat-label>
                  <input type="number" matInput placeholder="Desc. Detalle" [ngStyle]="{textAlign: 'right'}"
                    [(ngModel)]="order.PCC_IMPDES" name="PCC_IMPDES" step="any" readonly>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l2" [ngStyle]="{marginTop: '16px'}">
                <mat-form-field class="pav-total-field">
                  <mat-label>Valor venta</mat-label>
                  <input type="number" matInput placeholder="Valor venta" [ngStyle]="{textAlign: 'right'}"
                    [(ngModel)]="order.PCC_VALVTA" name="PCC_VALVTA" step="any" readonly>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l2" [ngStyle]="{marginTop: '16px'}">
                <mat-form-field class="pav-total-field">
                  <mat-label>Imp. IGV</mat-label>
                  <input type="number" matInput placeholder="Imp. IGV" [ngStyle]="{textAlign: 'right'}"
                    [(ngModel)]="order.PCC_IMPIGV" name="PCC_IMPIGV" step="any" readonly>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l2" [ngStyle]="{marginTop: '16px'}">
                <mat-form-field class="pav-total-field">
                  <mat-label>Imp. Total</mat-label>
                  <input type="number" matInput placeholder="Imp. Total" [ngStyle]="{textAlign: 'right'}"
                    [(ngModel)]="order.PCC_IMPTOT" name="PCC_IMPTOT" step="any" readonly>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
          <!-- <label class="pav-title-tag">Producto : </label>
          <span class="pav-title-tag-content">{{cod_prod ? cod_prod + ' ' + prod: ''}}</span>
          <span> | </span> -->

          <!-- <div class="row">
            <div class="col s12 m6 l2">
              <mat-form-field>
                <mat-label>Cod. Prod.</mat-label>
                <input [disabled]="loaderData" type="text" matInput placeholder="Cod. Prod." readonly="true" [value]="cod_prod ? cod_prod + ' ' + prod: ''" name="cod_prod">
              </mat-form-field>
            </div>
            <div class="col s12 m6 l2">
              <mat-form-field>
                <mat-label>Disponible</mat-label>
                <input [disabled]="loaderData" type="number" matInput placeholder="Disponible" readonly="true" [(ngModel)]="disponible" name="disponible">
              </mat-form-field>
            </div>
          </div> -->
        </mat-card>

        <br>
        <div (paste)="onPaste($event)">
          <div [ngStyle]="{display: 'flex', justifyContent: 'space-between'}">
            <div [ngStyle]="{display: 'flex', alignItems: 'center', marginBottom: '5px'}">
              <mat-icon matTooltip="Seleccionar tabla para pegar datos copiados desde Excel">info_outline</mat-icon>

              <div type="button" [ngStyle]="{cursor: 'pointer', fontSize: '14px', color: '#4792f9', marginLeft: '5px'}"
                (click)="seleccionarTabla()" color="primary">
                Seleccionar tabla
              </div>

            </div>
            <div>
              <a type="button" mat-button color="primary"
                href="https://docs.google.com/spreadsheets/d/1SI_K4Sc1YPNdKBjUP6OjnLg2ZblkwB5TIP2-59BkzWY/edit?usp=sharing"
                target="_blank">Descargar plantilla</a>

            </div>
          </div>

          <div [ngStyle]="{border: tablaSeleccionada ? '3px solid #32b17b': '', borderRadius: '18px'}">
            <div class="pav-table-header-container">
              <div class="pav-table-header-icon">
              </div>
              <div class="pav-table-header-title">
                <div [ngStyle]="{display: 'flex', alignItems: 'center'}">
                  <div>
                    Detalle
                  </div>
                  <div [ngStyle]="{marginLeft: '10px'}">
                    <!-- <button class="pav-btn-action"
                      style="width: 30px !important; height: 30px !important; margin-right: 0px !important; margin-bottom: 0px !important;"
                      color="primary" matTooltip="Consultar almacen" type="button" (click)="consultarStock()">
                      <mat-icon class="pav-icon-btn" style="width: 20px !important; height: 20px"
                        svgIcon="stock"></mat-icon>
                    </button> -->
                    <!-- <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                      <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Ver stock</span>
                    </p> -->
                  </div>

                </div>

              </div>
              &nbsp;
              &nbsp;
              &nbsp;

              &nbsp;
              <div>
                <div class="pav-button-icon-add-modal" matTooltip="Agregar ítem" (click)="agregarProductoModal()"
                  [ngStyle]="{display: loaderData? 'none': ''}">
                  <mat-icon svgIcon="add-modal-detail"></mat-icon>
                </div>
                <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                  <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Modal</span>
                </p>
              </div>
              &nbsp;
              <div>
                <div class="pav-button-icon-add-multiple" matTooltip="Agregar múltiple"
                  (click)="agregarProductoMultiple()" [ngStyle]="{display: loaderData? 'none': ''}">
                  <mat-icon [ngStyle]="(isMobile || isTablet) ? {
                    color: 'white',
                    width: '26px !important',
                    height: '18px !important',
                    fontSize: '18px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'}:
                    {color: 'white'}">library_add</mat-icon>
                </div>
                <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                  <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Múltiple</span>
                </p>
              </div>
              &nbsp;
              <div>
                <div class="pav-button-icon-add" matTooltip="Agregar ítem barra" (click)="agregarProducto()"
                  [ngStyle]="{display: loaderData? 'none': ''}">
                  <mat-icon svgIcon="add-circle"></mat-icon>
                </div>
                <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                  <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
                </p>
              </div>
              &nbsp;
              <div>
                <div class="pav-button-icon-delete" matTooltip="Eliminar ítem" (click)="eliminarProducto()"
                  [ngStyle]="{display: loaderData? 'none': ''}">
                  <mat-icon svgIcon="remove"></mat-icon>
                </div>
                <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                  <span [ngStyle]="{color: 'white', fontSize: '8px'}">Eliminar</span>
                </p>
              </div>
              &nbsp;
              <div>
                <div class="pav-button-icon-insert" matTooltip="Insertar ítem" (click)="insertarProducto()"
                  [ngStyle]="{display: loaderData? 'none': ''}">
                  <mat-icon svgIcon="add-outline"></mat-icon>
                </div>
                <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
                  <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Insertar</span>
                </p>
              </div>

            </div>

            <div class="mat-elevation-z0 overflow-x">
              <table mat-table [dataSource]="dataSource" matSort class="table-cell">

                <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                    <div class="pav-btns-container">
                      <!-- <div [ngStyle]="{marginTop: '4px', marginRight: '10px'}">
                        <mat-checkbox (change)="checkProducto($event.checked, row)" class="example-margin" [(ngModel)]="row.seleccionado" [name]="'seleccionado' + i"></mat-checkbox>
                      </div> -->

                      <!-- <div matTooltip="Eliminar producto" class="pav-btn-circle-delete" (click)="eliminarProducto()">
                        <mat-icon svgIcon="delete"></mat-icon>
                      </div> -->
                      <!-- <div matTooltip="Ver stock" class="pav-btn-circle-edit" (click)="consultarStock(row)">
                        <mat-icon svgIcon="inventario"></mat-icon>
                      </div> -->
                      <!-- <div matTooltip="Ver descuentos" class="pav-btn-circle-edit" (click)="verDescuentos(row)">
                        <mat-icon svgIcon="descuento"></mat-icon>
                      </div> -->
                      <!-- <button type="button" matTooltip="Ver Información" (click)="consultarStock(row)" mat-icon-button
                        color="primary">
                        <mat-icon>outbound</mat-icon>
                      </button> -->

                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PCD_CORPCD">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> It. </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="item">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '40px'}">
                      <mat-label></mat-label>
                      <input readonly [value]="row.PCD_CORPCD" [name]="'PCD_CORPCD' + i" matInput placeholder="Ej. 001">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PRD_CODPRD">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                    <div [ngStyle]="{display: 'flex', width: isMobile || isTablet ? '240' : '880px'}"
                      (contextmenu)="openMenu($event)" #selectContainer>

                      <pav-input-search label="''" placeholder="ej. producto 01"
                        [class]="row === selected? 'form-field-small form-field-cell select-width-all mat-form-field-row': 'form-field-small form-field-cell select-width-all'"
                        (valueChange)="row.PRD_CODPRD = $event.codigo; seleccionarProducto($event, row)"
                        [codigo]="row.PRD_CODPRD" width="880px" [name]="'prd_codprd' + i" [descripcion]="''"
                        [program]="'PRODUCTOS'"></pav-input-search>


                      <!-- <select-search [ngStyle]="{width: '100%'}"
                        [clase]="row === selected? 'form-field-small form-field-cell select-width-all mat-form-field-row': 'form-field-small form-field-cell select-width-all'"
                        [label]="''" [placeholder]="'Ej. CANASTA'" [name]="'prd_codprd'" [value]="'prd_codprd'"
                        [description]="'description'" [data]="productos"
                        (cambioSelect)="row.PRD_CODPRD = $event; seleccionarProducto($event, row)"
                        [model]="row.PRD_CODPRD">
                      </select-search> -->

                      <!-- TODO: NO ELIMINAR <div [ngStyle]="{paddingTop: '10px', marginLeft: '5px', marginRight: '5px'}"
                        matTooltip="Ver imagen del producto">
                        <mat-icon (click)="verImagen()" [ngStyle]="{cursor: 'pointer'}">photo_camera</mat-icon>
                      </div> -->
                      <mat-menu #contextMenu="matMenu">
                        <button mat-menu-item (click)="verImagen()">Ver imágen del producto</button>
                      </mat-menu>
                      <button #contextMenuTrigger [matMenuTriggerFor]="contextMenu" style="display: none;"></button>

                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="sec">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Sec. </th>
                  <td mat-cell *matCellDef="let row; let i = index;" data-label="Sec.">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '35px'}">
                      <mat-label></mat-label>
                      <input readonly [value]="row.PCD_SECPCD" [name]="'PCD_SECPCD' + i" matInput placeholder="Ej. 01">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PRM_CODPRM">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Prom. </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Prom.">
                    <div [ngStyle]="{width: '55px'}">
                      <select-search [label]="''"
                        [clase]="row === selected? 'form-field-small form-field-cell select-width-all mat-form-field-row': 'form-field-small form-field-cell select-width-all'"
                        [placeholder]="'Ej. PROMOCIÓN'" [name]="'prm_codprm'" [value]="'prm_codprm'"
                        [description]="'prm_descri'" [data]="promociones" [showTooltip]="true"
                        (cambioSelect)="row.PRM_CODPRM = $event; seleccionarPromocion($event, row)"
                        [model]="row.PRM_CODPRM">
                      </select-search>
                    </div>

                  </td>
                </ng-container>

                <ng-container matColumnDef="UME_CODVEN">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="U.M.">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '50px'}">
                      <mat-label></mat-label>
                      <input readonly [(ngModel)]="row.UME_CODVEN" [name]="'UME_CODVEN' + i" matInput
                        placeholder="Ej. UND">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PCD_CANSOL">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Cantidad">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '80px'}">
                      <mat-label></mat-label>
                      <input (keyup)="calcularMontosFila(row)" (focus)="focusCampo($event)"
                        (change)="calcularMontosFila(row)" min="0" [ngStyle]="{textAlign: 'right'}" type="number"
                        [(ngModel)]="row.PCD_CANSOL" [name]="'PCD_CANSOL' + i" matInput placeholder="Ej. 10">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PCD_PREUNI">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Precio">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '100px'}">
                      <mat-label></mat-label>
                      <input (keyup)="calcularMontosFila(row)" (focus)="focusCampo($event)" appDecimalPlaces
                        (change)="calcularMontosFila(row)" min="0" (blur)="row.PCD_PREUNI = row.PCD_PREUNI.toFixed(2)"
                        [ngStyle]="{textAlign: 'right'}" step="any" type="number" [(ngModel)]="row.PCD_PREUNI"
                        [readonly]="configuracionTecnicaVentas.s71_edipre == 0" [name]="'PCD_PREUNI' + i" matInput
                        [disabled]="order.PCC_INDCAL == '1'"
                        placeholder="Ej. 10">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PCD_PRUIGV">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio inc. IGV </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Precio inc. IGV">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '100px'}">
                      <mat-label></mat-label>
                      <input [ngStyle]="{textAlign: 'right'}" min="0" type="number" (keyup)="calculaMontosFilaIncIGV(row)"
                        [(ngModel)]="row.PCD_PRUIGV" step="any" [name]="'PCD_PRUIGV' + i" matInput [disabled]="order.PCC_INDCAL == '0'"
                        placeholder="Ej. 10">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PCD_IMPBRU">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Bruto </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Importe Bruto">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '100px'}">
                      <mat-label></mat-label>
                      <input [ngStyle]="{textAlign: 'right'}" min="0" type="number" readonly
                        [value]="row.PCD_IMPBRU.toFixed(2)" [name]="'PCD_IMPBRU' + i" matInput placeholder="Ej. 10"
                        step="any">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PCD_PORDES">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> % Desc. </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="% Desc.">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '70px'}">
                      <mat-label></mat-label>
                      <input [ngStyle]="{textAlign: 'right'}" type="number" min="0" [value]="row.PCD_PORDES.toFixed(2)"
                        step="any" [name]="'PCD_PORDES' + i" matInput placeholder="Ej. 10" readonly>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PCD_IMPDES">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Desc. </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Importe Desc.">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '100px'}">
                      <mat-label></mat-label>
                      <input [ngStyle]="{textAlign: 'right'}" type="number" readonly min="0" step="any"
                        [value]="row.PCD_IMPDES.toFixed(2)" [name]="'PCD_IMPDES' + i" matInput readonly
                        placeholder="Ej. 10">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PCD_VALVTA">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor venta </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Valor venta">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '100px'}">
                      <mat-label></mat-label>
                      <input [ngStyle]="{textAlign: 'right'}" type="number" min="0" step="any"
                        [value]="row.PCD_VALVTA.toFixed(2)" [name]="'PCD_VALVTA' + i" matInput readonly
                        placeholder="Ej. 10">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PCD_IMPIGV">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. IGV. </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Imp. IGV.">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '100px'}">
                      <mat-label></mat-label>
                      <input [ngStyle]="{textAlign: 'right'}" type="number" min="0" step="any"
                        [value]="row.PCD_IMPIGV.toFixed(2)" [name]="'PCD_IMPIGV' + i" matInput readonly
                        placeholder="Ej. 10">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PCD_IMPTOT">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Total </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Imp. Total">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '100px'}">
                      <mat-label></mat-label>
                      <input [ngStyle]="{textAlign: 'right'}" type="number" min="0" step="any"
                        [value]="row.PCD_IMPTOT.toFixed(2)" [name]="'PCD_IMPTOT' + i" readonly matInput
                        placeholder="Ej. 10">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PCD_CANATE">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Atendida </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Cantidad Atendida">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '100px'}">
                      <mat-label></mat-label>
                      <input type="number" [readonly]="uid=='0'" [ngStyle]="{textAlign: 'right'}"
                        [value]="row.PCD_CANATE.toFixed(2)" [name]="'PCD_CANATE' + i" matInput placeholder="Ej. ">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PCD_CANSUS">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Suspendida </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Cantidad Suspendida">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell" [ngStyle]="{width: '100px'}">
                      <mat-label></mat-label>
                      <input type="number" [readonly]="uid=='0'" [ngStyle]="{textAlign: 'right'}"
                        [value]="row.PCD_CANSUS.toFixed(2)" [name]="'PCD_CANSUS' + i" matInput placeholder="Ej. ">
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="PCD_GLOPCD">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
                  <td mat-cell *matCellDef="let row; let i = index" data-label="Glosa">
                    <mat-form-field [class.mat-form-field-row]="row === selected"
                      class="form-field-small form-field-cell">
                      <mat-label></mat-label>
                      <input type="text" [(ngModel)]="row.PCD_GLOPCD" [name]="'PCD_GLOPCD' + i" matInput
                        placeholder="Ej. descripción">
                    </mat-form-field>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="row === selected"
                  (click)="selectRow(row)"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="20" *ngIf="!loaderProductos">No se encontraron registros</td>
                  <td class="mat-cell" colspan="20" *ngIf="loaderProductos">
                    <mat-spinner diameter="40"></mat-spinner>
                  </td>
                </tr>

              </table>
            </div>
          </div>

          <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>

        <ng-container *ngIf="isMobile || isTablet">
          <mat-card class="mat-elevation-z0">

            <div class="row">
              <div class="col s6 m6 l2" [ngStyle]="{marginTop: '16px'}">
                <mat-form-field class="pav-total-field">
                  <mat-label>Imp. Bruto</mat-label>
                  <input type="number" matInput placeholder="Imp. Bruto" [ngStyle]="{textAlign: 'right'}"
                    [(ngModel)]="order.PCC_IMPBRU" name="PCC_IMPBRU" step="any" readonly>
                </mat-form-field>
              </div>
              <div class="col s6 m6 l2" [ngStyle]="{marginTop: '16px'}">
                <mat-form-field class="pav-total-field">
                  <mat-label>Descuento</mat-label>
                  <input type="number" matInput placeholder="Desc. Detalle" [ngStyle]="{textAlign: 'right'}"
                    [(ngModel)]="order.PCC_IMPDES" name="PCC_IMPDES" step="any" readonly>
                </mat-form-field>
              </div>
              <div class="col s6 m6 l2" [ngStyle]="{marginTop: '16px'}">
                <mat-form-field class="pav-total-field">
                  <mat-label>Valor venta</mat-label>
                  <input type="number" matInput placeholder="Valor venta" [ngStyle]="{textAlign: 'right'}"
                    [(ngModel)]="order.PCC_VALVTA" name="PCC_VALVTA" step="any" readonly>
                </mat-form-field>
              </div>
              <div class="col s6 m6 l2" [ngStyle]="{marginTop: '16px'}">
                <mat-form-field class="pav-total-field">
                  <mat-label>Imp. IGV</mat-label>
                  <input type="number" matInput placeholder="Imp. IGV" [ngStyle]="{textAlign: 'right'}"
                    [(ngModel)]="order.PCC_IMPIGV" name="PCC_IMPIGV" step="any" readonly>
                </mat-form-field>
              </div>
              <div class="col s6 m6 l2" [ngStyle]="{marginTop: '16px'}">
                <mat-form-field class="pav-total-field">
                  <mat-label>Imp. Total</mat-label>
                  <input type="number" matInput placeholder="Imp. Total" [ngStyle]="{textAlign: 'right'}"
                    [(ngModel)]="order.PCC_IMPTOT" name="PCC_IMPTOT" step="any" readonly>
                </mat-form-field>
              </div>
              <div class="col s6 m6 l2" [ngStyle]="{marginTop: '16px'}">
                <mat-form-field class="pav-total-field">
                  <mat-label>Imp. Total US$</mat-label>
                  <input type="number" matInput placeholder="Imp. Total" [ngStyle]="{textAlign: 'right'}"
                    [(ngModel)]="order.PCC_IMPTOT" name="PCC_IMPTOT" step="any" readonly>
                </mat-form-field>
              </div>
            </div>

          </mat-card>
        </ng-container>
        <br>
        <div class="row">
          <div class="col s12 m12 l5"
            *ngIf="!((vendedorId != undefined && vendedorId != null && vendedorId != '') && rucCompania == '20603066538')">
            <div class="pav-table-header-container">
              <div class="pav-table-header-icon">
              </div>
              <div class="pav-table-header-title">
                Descuentos item: {{selected? selected.PCD_CORPCD: ''}}
              </div>
              <div class="pav-separator">

              </div>
              <div>
                <div class="pav-button-icon-add" (click)="agregarDescuento()" matTooltip="Agregar descuento"
                  [ngStyle]="{display: loaderData? 'none': ''}">
                  <mat-icon svgIcon="add"></mat-icon>
                </div>

                <!-- <button [disabled]="loaderData" type="button" mat-flat-button class="pav-button-add-item"
                (click)="agregarDescuento()">{{'general.button.add' | translate}}</button> -->
              </div>
            </div>
            <div class="mat-elevation-z0">

              <div class="overflow-x">

                <table mat-table [dataSource]="dataSourceDescuento" matSort>

                  <ng-container matColumnDef="item">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                    <td mat-cell *matCellDef="let row; let i = index" data-label="ITEM"> {{i + 1}} </td>
                  </ng-container>

                  <ng-container matColumnDef="cod_desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Desc. </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="TIPO DESC.">
                      <mat-form-field class="form-field-small" [ngStyle]="{width: '150px', marginTop: '5px'}">
                        <mat-label></mat-label>
                        <mat-select [name]="'dco_coddco' + i" [(ngModel)]="row.dco_coddco">
                          <mat-option *ngFor="let descuento of descuentos" [value]="descuento.dco_coddco">
                            {{descuento.dco_desdco}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. </th>
                    <td mat-cell *matCellDef="let row" data-label="DESCUENTO">
                      {{(row.dco_coddco == '0001')? "DESCUENTO ESPECIAL" : (row.dco_coddco) ? "DESCUENTO POR REGALO":
                      ''}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="porc_desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> % Desc </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="% DESC">
                      <mat-form-field class="form-field-small" [ngStyle]="{width: '100px', marginTop: '5px'}">
                        <mat-label></mat-label>
                        <input [ngStyle]="{textAlign: 'right'}" (focus)="focusCampo($event)"
                          (change)="cambiarFilaDescuento(row)" (keyup)="cambiarFilaDescuento(row)" type="number" min="0"
                          [(ngModel)]="row.pds_pordes" step="any" [name]="'pds_pordes' + i" matInput
                          placeholder="Ej. 10">
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="importe_desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Desc. </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="IMPORTE DESC">
                      <mat-form-field class="form-field-small" [ngStyle]="{width: '100px', marginTop: '5px'}">
                        <mat-label></mat-label>
                        <input readonly [ngStyle]="{textAlign: 'right'}" type="number" min="0"
                          [value]="row.pds_impdes.toFixed(2)" step="any" [name]="'pds_impdes' + i" matInput
                          placeholder="Ej. 10">

                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let row; let i = index" data-label="Acciones">

                      <div class="pav-btns-container">

                        <div matTooltip="Eliminar descuento" class="pav-btn-circle-delete"
                          (click)="eliminarDescuento(i)">
                          <mat-icon svgIcon="delete"></mat-icon>
                        </div>
                      </div>

                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsDescuento"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsDescuento;"></tr>

                  <tr class="mat-row" *matNoDataRow>

                    <td class="mat-cell" colspan="5">No se encontraron registros</td>

                  </tr>
                </table>

              </div>
              <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

            </div>
          </div>
          <div class="col s12 m12 l7">
            <mat-card class="mat-elevation-z0">
              <div class="row">
                <div class="col s12 m12 l6">

                  <mat-form-field class="example-full-width">
                    <mat-label>Observación Comprobante</mat-label>
                    <textarea matInput placeholder="Observación" [(ngModel)]="order.PCC_OBSPED"
                      name="PCC_OBSPED"></textarea>
                  </mat-form-field>
                  <div class="row" [ngStyle]="{paddignBottom: '10px'}"
                    *ngIf="!((vendedorId != undefined && vendedorId != null && vendedorId != '') && rucCompania == '20603066538')">
                    <div class="col s12">
                      <mat-checkbox class="example-margin" color="primary" name="PCC_INDTERM"
                        [(ngModel)]="order.PCC_INDTER" [checked]="order.PCC_INDTER == '1'">Puede ser
                        aprobado</mat-checkbox>
                    </div>
                  </div>


                </div>

                <!-- <button mat-button color="primary" (click)="volver2()">Volver</button> -->
                <div class="col s12 m12 l6">
                  <div class="mat-elevation-z0 overflow-x">
                    <h4 class="pav-titulo-documentos">Documentos generados del pedido</h4>
                    <table style="padding: 0px !important;" mat-table [dataSource]="documentos" matSort>

                      <ng-container matColumnDef="campo1">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. </th>
                        <td mat-cell *matCellDef="let row" data-label="DOC"> FAC </td>
                      </ng-container>

                      <ng-container matColumnDef="campo2">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Número </th>
                        <td mat-cell *matCellDef="let row" data-label="NUMERO"> F239234234782 </td>
                      </ng-container>

                      <ng-container matColumnDef="campo3">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                        <td mat-cell *matCellDef="let row" data-label="Usuario creador"> 07/01/2021 </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="columnsDocumentos"></tr>
                      <tr mat-row *matRowDef="let row; columns: columnsDocumentos;"></tr>

                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="5" *ngIf="!loaderDocumentos">No se encontraron registros</td>
                        <td class="mat-cell" colspan="5" *ngIf="loaderDocumentos">
                          <mat-spinner diameter="40"></mat-spinner>
                        </td>
                      </tr>

                    </table>

                  </div>

                </div>
              </div>
            </mat-card>

            <mat-card class="mat-elevation-z0" [ngStyle]="{marginTop: '15px'}">

              <mat-checkbox #aplicarCB class="example-margin">Aplicar (%) descuento general</mat-checkbox>

              <mat-form-field class="example-full-width" [ngStyle]="{marginTop: '20px'}">
                <mat-label>(%) Descuento</mat-label>
                <input #descuentoGeneral matInput placeholder="Ej. 20" type="number" value="0" min="0"
                  [disabled]="!aplicarCB.checked">
                <button type="button" mat-icon-button matSuffix [disabled]="!aplicarCB.checked"
                  matTooltip="Buscar desde Consulta SUNAT" (click)="aplicarDescuentoGeneral(descuentoGeneral.value)">
                  <mat-icon>percent</mat-icon>
                </button>
              </mat-form-field>

            </mat-card>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Datos de Despacho" labelClass="mat-tab-label-0-1">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l4">
              <mat-form-field>
                <mat-label>Lugar Desp.</mat-label>
                <mat-select [(ngModel)]="order.LDE_CODLDE" name="LDE_CODLDE"
                  (selectionChange)="seleccionarLugarDespacho($event.value)">
                  <mat-option *ngFor="let lugar of lugaresDespacho" [value]="lugar.LDE_CODLDE">
                    {{lugar.LDE_NOMLDE}} - {{lugar.LDE_DIRLDE}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l4">
              <select-search [label]="'Transportista'" [placeholder]="'Ej. GONZALES'" [name]="'cli_codcli'"
                [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="transportistas"
                (cambioSelect)="order.CLI_CODPRO = $event" [model]="order.CLI_CODPRO"></select-search>

            </div>


            <div class="col s12 m12 l4">
              <div>
                <mat-checkbox class="example-margin" name="tkt_indrec">Atender Completo</mat-checkbox>
                &nbsp; &nbsp;
                <mat-checkbox class="example-margin" name="tkt_indrec">Entregar Vendedor</mat-checkbox>
              </div>
            </div>



          </div>

          <div class="row">
            <div class="col s12 m12 l4">
              <select-search [label]="'Agen. Prov.'" [placeholder]="'Ej. A'" [name]="'cli_codcli'"
                [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="agencias"
                (cambioSelect)="order.CLI_AGETRA = $event" [model]="order.CLI_AGETRA"></select-search>

            </div>

            <div class="col s12 m12 l4">
              <select-search [label]="'Suc. Age.'" [placeholder]="'Ej. Agencia'" [name]="'CODIGO'" [value]="'CODIGO'"
                [description]="'DIRECCION'" [data]="sucursales" (cambioSelect)="order.LDE_AGETRA = $event;"
                [model]="order.LDE_AGETRA">
              </select-search>
            </div>


            <div class="col s12 m12 l4">

              <fieldset [ngStyle]="{borderRadius: '8px'}">
                <legend>Días de Entrega</legend>
                <div>
                  <mat-checkbox class="example-margin">Lunes</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Martes</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Miércoles</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Jueves</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Viernes</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Sábado</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Domingo</mat-checkbox>
                </div>
              </fieldset>

            </div>

          </div>
        </mat-card>

      </mat-tab>
      <mat-tab label="Datos complementarios" labelClass="mat-tab-label-0-2">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l4">
              <mat-form-field>
                <mat-label>Calcula Item</mat-label>
                <mat-select (selectionChange)="seleccionarCalculaItem($event.value)" [disabled]="loaderData"
                  name="moneda" [(ngModel)]="order.PCC_INDCAL" name="PCC_INDCAL">
                  <mat-option [value]="item.fci_codfci" *ngFor="let item of formasCalculoItem">
                    {{item.fci_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col s12 m6 l3">

              <select-search [disabled]="loaderData || configuracionTecnicaVentas.s71_editlp == 0"
                [label]="'Tipo de lista'" [placeholder]="'Ej. 01'" [name]="'TLP_CODTLP'" [value]="'TLP_CODTLP'"
                [description]="'description'" [data]="tiposPrecio"
                (cambioSelect)="order.TLP_CODTLP = $event; seleccionDeLista($event)"
                [model]="order.TLP_CODTLP"></select-search>

              <!-- <mat-form-field>
                <mat-label>Tipo de lista</mat-label>
                <mat-select [disabled]="loaderData" name="TLP_CODTLP" [(ngModel)]="order.TLP_CODTLP"
                  (selectionChange)="seleccionDeLista($event.value)">
                  <mat-option *ngFor="let tipo of tiposPrecio" [value]="tipo.TLP_CODTLP">
                    {{tipo.description}}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
            </div>

            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Nro</mat-label>
                <input [disabled]="loaderData" type="text" matInput placeholder="Nro" [(ngModel)]="order.LPC_CODLPC"
                  name="LPC_CODLPC">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3" *ngIf="configuracionTecnicaVentas.s71_verccs == 1">
              <select-search [disabled]="loaderData" [label]="'Proyecto o CeCo'" [placeholder]="'Ej. ASCENSOR'"
                [name]="'ccs_codccs'" [value]="'ccs_codccs'" [description]="'description'" [data]="centros"
                (cambioSelect)="order.ccs_codccs = $event;" [model]="order.ccs_codccs">
              </select-search>

            </div>

            <!-- <div class="col s12 m12 l3">
              <mat-form-field class="example-full-width">
                <mat-label>Suscripción</mat-label>
                <input matInput placeholder="Suscripción">
              </mat-form-field>
            </div> -->

          </div>
          <!-- TODO: Falta ingresar los campos -->
          <div class="row">
            <div class="col s12 m12 l4">
              <mat-checkbox class="example-margin" [(ngModel)]="order.pcc_impgui" name="pcc_impgui">Imprime
                guía</mat-checkbox>
            </div>
            <div class="col s12 m12 l4">
              <mat-checkbox class="example-margin" [(ngModel)]="order.pcc_impfac" name="pcc_impfac">Imprime
                comprobante</mat-checkbox>
            </div>
            <!-- Solo se activa para el cliente Barker -->
            <div class="col s12 m12 l4" *ngIf="rucCompania == '20603066538'">
              <mat-checkbox class="example-margin" [(ngModel)]="order.pcc_rutext" name="pcc_rutext">Ruta
                externa</mat-checkbox>
            </div>

          </div>
        </mat-card>
      </mat-tab>
    </mat-tab-group>

  </div>

  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" [loaderFormato]="loaderFormato"
    [loaderImprimir]="loaderImprimir" [loaderAnular]="loaderAnular" urlBack="/modulo-ventas/pedidos"
    (clickMasOpciones)="clickMasOpciones()" (clickReset)="reset()" (clickFormato)="generarFormato()"
    (clickImprimir)="imprimirFormato()" (clickNuevo)="nuevoRegistro()" (anular)="anularPedido()">
    <ng-container id="botonesAdicionales">
      <button class="pav-btn-action" color="primary" matTooltip="Copiar pedido" type="button"
        (click)="copiarDesdePedido()">
        <mat-icon class="pav-icon-btn">content_copy</mat-icon>
      </button>
      <button class="pav-btn-action" color="primary" matTooltip="Extraer Promoción" type="button"
        (click)="extraerPromociones()">
        <mat-icon class="pav-icon-btn" svgIcon="promocion"></mat-icon>
      </button>
      <button class="pav-btn-action" color="primary" matTooltip="Historial de Precios" type="button"
        (click)="verHistorialPrecios()">
        <mat-icon class="pav-icon-btn" svgIcon="document-payment"></mat-icon>
      </button>
      <button class="pav-btn-action" color="primary" matTooltip="Suspender Pedido" type="button"
        (click)="suspenderPedido()">
        <mat-icon class="pav-icon-btn" svgIcon="alert"></mat-icon>
      </button>
      <button [disabled]="this.uid == '0' || this.uid.includes('cotizacion')" class="pav-btn-action" color="primary"
        matTooltip="Generar factura" type="button" (click)="generarFacturaDesdePedido()">
        <mat-icon class="pav-icon-btn" svgIcon="generate"></mat-icon>
      </button>
    </ng-container>
  </pav-form-actions>
</form>
