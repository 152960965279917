import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subscription } from "rxjs";
import { CajaBancoService } from "src/app/services";

@Component({
  selector: 'grupo-flujo-list',
  templateUrl: './grupo-flujo-caja-list.component.html',
  styleUrls: ['./grupo-flujo-caja-list.component.scss']
})
export class GrupoFlujoCajaListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'CFU_CODCFU', 'CFU_DESCRI', 'CFU_INDSTA'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;
  flujoCaja$: Subscription;

  constructor(
    private _cajaBancoService: CajaBancoService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.flujoCaja$ = this._cajaBancoService.obtenerGrupoFlujoCajas().subscribe(
      grupos => this.dataSource = fillTable(grupos, this.paginator, this.sort),
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.flujoCaja$
    ])
  }

}
