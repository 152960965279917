import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@shared/shared.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MaterialModule } from "src/app/material.module";
import { ContabilidadComponent } from "./contabilidad.component";
import { cierreComponentsContabilidad } from "./pages/cierre";
import { conciliacionComponentsContabilidad } from "./pages/conciliacion-bancaria";
import { consultasComponentsContabilidad } from "./pages/consultas";
import { informesComponentsContabilidad } from "./pages/informes";
import { maestrosComponentContabilidad, maestrosModulesContabilidad } from "./pages/maestros";
import { operacionesComponentContabilidad } from "./pages/operaciones";
import { PanelContabilidadComponent } from "./pages/panel/panel-contabilidad.component";
import { procesoComponentsContabilidad } from "./pages/procesos";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { HttpClient } from "@angular/common/http";
import { sireComponentsContabilidad } from "./pages/sire";

@NgModule({
  declarations: [
    ContabilidadComponent,
    PanelContabilidadComponent,
    ...cierreComponentsContabilidad,
    ...conciliacionComponentsContabilidad,
    ...consultasComponentsContabilidad,
    ...informesComponentsContabilidad,
    ...maestrosComponentContabilidad,
    ...operacionesComponentContabilidad,
    ...procesoComponentsContabilidad,
    ...sireComponentsContabilidad
  ],
  exports: [],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    ...maestrosModulesContabilidad,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ]
})
export class ContabilidadModule {}
