<div>
  <mat-card class="mat-elevation-z0 pavso-bottom-actions" [ngStyle]="{paddingTop: isMobileLandscape ? '0px': '15px', paddingBottom: isMobileLandscape ? '0px': '15px'}">

    <div class="pav-content-form-actions" [ngStyle]="{flexDirection: isMobile ? isMobileLandscape ? 'row' : 'column': 'row', paddingRight: isMobileLandscape && '20px'}">
      <div class="pav-content-buttons-aditionals" [ngStyle]="{marginLeft: isTablet || isMobile ? '45px': '330px'}">
        <ng-content select="#botonesAdicionales">

        </ng-content>
      </div>
      <div class="pav-separator"></div>
      <div [ngStyle]="{display: 'flex'}">
        <div>
          <button mat-button type="button" matTooltip="Nuevo" (click)="clickNuevo.emit(true)"
            class="mat-elevation-z4 pav-button-action-general" [ngStyle]="{backgroundColor: '#5A9BD8'}">
            <mat-icon>note_add</mat-icon>
          </button>
          <p class="pav-tag-button" *ngIf="isMobile">Nuevo</p>
        </div>
        <div>
          <button mat-button type="button" matTooltip="Refrescar" (click)="clickReset.emit(true)"
            class="mat-elevation-z4 pav-button-action-general" [ngStyle]="{backgroundColor: 'rgb(80 223 235)'}">
            <mat-icon svgIcon="refresh"></mat-icon>
          </button>
          <p class="pav-tag-button" *ngIf="isMobile">Refrescar</p>
        </div>
        <div>
          <button mat-button type="button" matTooltip="Más Opciones" (click)="clickMasOpciones.emit(true)"
            class="mat-elevation-z4 pav-button-action-general" [ngStyle]="{backgroundColor: '#F7C91B'}">
            <mat-icon svgIcon="mas-opciones"></mat-icon>
          </button>
          <p class="pav-tag-button" *ngIf="isMobile">Opciones</p>
        </div>
        <div>
          <button mat-button type="button" matTooltip="Volver" (click)="volver()"
            class="mat-elevation-z4 pav-button-action-general" [ngStyle]="{backgroundColor: '#4493FF'}">
            <mat-icon svgIcon="volver"></mat-icon>
          </button>
          <p class="pav-tag-button" *ngIf="isMobile">Volver</p>
        </div>
        <div *ngIf="permisos.canDelete">
          <button mat-button type="button" [disabled]="!permisos.canDelete" matTooltip="Eliminar"
            class="mat-elevation-z4 pav-button-action-general" [ngStyle]="{backgroundColor: '#ad9b9b'}">
            <mat-icon svgIcon="eliminar"></mat-icon>
          </button>
          <p class="pav-tag-button" *ngIf="isMobile">Eliminar</p>
        </div>
        <div>
          <button [class.spinner]="loaderAnular" mat-button type="button" (click)="anular.emit(true)"
            [disabled]="loaderAnular" matTooltip="Anular" class="mat-elevation-z4 pav-button-action-general"
            [ngStyle]="{backgroundColor: '#d58b77'}">
            <mat-icon svgIcon="anular"></mat-icon>
          </button>
          <p class="pav-tag-button" *ngIf="isMobile">Anular</p>
        </div>
        <div>
          <button [class.spinner]="loaderFormato" [disabled]="loaderFormato" mat-button type="button"
            matTooltip="Formato" (click)="clickFormato.emit(true)" class="mat-elevation-z4 pav-button-action-general"
            [ngStyle]="{backgroundColor: '#63C99A'}">
            <mat-icon svgIcon="formato"></mat-icon>
          </button>
          <p class="pav-tag-button" *ngIf="isMobile">Formato</p>
        </div>
        <div>
          <button [class.spinner]="loaderImprimir" [disabled]="loaderImprimir" mat-button type="button" matTooltip="Imprimir" (click)="clickImprimir.emit(true)" class="mat-elevation-z4 pav-button-action-general"
            [ngStyle]="{backgroundColor: '#2360FF'}">
            <mat-icon svgIcon="print"></mat-icon>
          </button>
          <p class="pav-tag-button" *ngIf="isMobile">Imprimir</p>
        </div>
        <div>
          <button [class.spinner]="isLoading" mat-button matTooltip="Grabar" [disabled]="isLoading"
            class="mat-elevation-z4 pav-button-action-general" [ngStyle]="{backgroundColor: '#00205F'}" type="submit">
            <mat-icon svgIcon="grabar" *ngIf="!isLoading"></mat-icon>
          </button>
          <p class="pav-tag-button" *ngIf="isMobile">Grabar</p>
        </div>
      </div>
    </div>
  </mat-card>

</div>
