import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Factura } from "src/app/models/ventas";
import { ConfigurationService } from "src/app/services/configuration.service";
import { IParam, generarParams } from "../../utilities/params";
import { DetalleProduc, FacturaXML } from "src/app/models/ventas/factura-xml";
import { NumeroALetras } from "@utils/conversions/number2letters.conversion";

@Injectable({
  providedIn: 'root'
})
export class FacturaService {

  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  obtenerFacturas({ params = [] }: { params?: Array<IParam> } = {}): Observable<Factura[]> {

    const paramsStr = generarParams(params);


    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/facturas/listar/${this._configurationService.obtenerCompaniaCliente()}${paramsStr}`
    );
  }

  obtenerFactura(codFactura): Observable<Factura> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/facturas/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${codFactura}`
    );
  }

  registrarFactura(payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/facturas/crear`,
      payload
    );
  }

  editarFactura(payload): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/facturas/modificar`,
      payload
    );
  }

  obtenerMonitoreoFactura({ params = [] }: { params?: Array<IParam> } = {}): Observable<Array<any>> {

    const paramsStr = generarParams(params);

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/facturas/monitoreo/${this._configurationService.obtenerCompaniaCliente()}${paramsStr}`
    );
  }

  generarPayload(factura: Factura): FacturaXML {

    const detalle: DetalleProduc[] = factura.detalle_dcd.map(item => {

      const descuentos = item.descuento_ddd.map((descuento, index) => ({
        "ItemDescuento": descuento.ddd_corddd,
        "PorcentajeDescuento": descuento.ddd_pordes,
        "ImporteDescuente": descuento.ddd_impdes,
        "ConceptoDescuento": '0',
        "BaseDescuento": 0
      }))

      return {
        Correaltivo: item.DCD_CORDCD,
        CodigoProducto: item.PRD_CODPRD,
        CodigoProductoSunat: '',
        CantidadItem: item.DCD_CANDCD,
        DescripcionItem: 'string',
        UnidadMedidaItem: item.producto.unidadmedida.ume_codofi,
        ValorUnitarioItem: item.DCD_PREUNI,
        ValorUnitarioIncluidoIgvItem: item.DCD_PRUIGV,
        TipoPrecioCat16: '01',
        ValorPrecioReferencial: '0',
        ImporteBrutoItem: item.DCD_IMPBRU,
        ValorVentaItem: item.DCD_VALVTA,
        DescuentoProduc: descuentos,
        Impuesto: [
          // {
          //   "CodigoTributo": "1000",
          //   "CodigoAfectacionIGV": "10",
          //   "Importe": 6.63,
          //   "NombreTributo": "IGV",
          //   "TasaImpuesto": 18,
          //   "ImporteValorVenta": 36.84,
          //   "TipoAfectacionTab07": "10",
          //   "CodigoInternacional": "VAT"
          // }
        ],
      }
    })

    const facturaXML: FacturaXML = {
      RazonSocial: this._configurationService.obtenerNombreCompaniaCliente(),
      NumeroRUC: this._configurationService.obtenerClienteId(),
      NombreComercial: this._configurationService.obtenerNombreCliente(),
      DomicilioFiscal: 'AV. DE LAS ARTES NORTE NRO. 269 INT. 101-URB. SAN BORJA NORTE LIMA-LIMA-SAN BORJA',
      Urbanizacion: '',
      CiudadCia: '',
      ProvinciaCia: 'SANTIAGO DE SURCO',
      DistritoCia: 'LIMA',
      Ubigeo: '150140',
      TipoDocumentoIdentidad: this._configurationService.obtenerClienteId().length == 11 ? '6' : '1',
      Cli_NumeroRuc: factura.CLI_CODCLI,
      Cli_RazonSocial: factura.CLI_NOMCLI,
      Cli_TipoDocumentoIdentidad: factura.CLI_CODCLI.length == 11 ? '6' : '1',
      IndDetrac: '0',
      IndTransferenciaGratuita: '0',
      PorcDetra: factura.docu_dcc.dcc_tasdet,
      MontoDetra: factura.docu_dcc.dcc_impdet,
      ProdDetra: '',
      CuenBancoDetracc: 'string',
      CodEmiFiscal: '0000',
      Moneda: factura.TMO_CODTMO == 'SO' ? 'PEN' : 'USD',
      TipoFacturacat51: '0101',
      NumLineas: factura.detalle_dcd.length.toString(),
      TipoDocumento: '01',
      MotivoVenta: factura.MMO_CODMMO,
      NumeroDocumento: `${factura.CCO_NUMDOC.substring(0, 4)}-${factura.CCO_NUMDOC.substring(5, 12)}`,
      Exportacion: '0',
      TotalFactura: Number(factura.CCO_IMPDOC),
      FechaEmision: factura.CCO_FECEMI,
      CuentaCorriente: factura.CCO_CODCCO,
      NumeroReferenciaNCR: '-',
      DescripcionReferenciaNCR: 'VENTA NACIONAL',
      DocumentoReferenciaNCR: null,
      TipoNota: '',
      ImporteLetra: NumeroALetras(factura.CCO_IMPDOC),
      ImpuestoFactura: Number(factura.CCO_IMPIGV),
      TotalValorVenta: Number(factura.CCO_VALVTA),
      TotalValorVentaGrabada: factura.CCO_IMPAFE,
      TotalValorVentaInafecta: factura.CCO_IMPINA,
      TotalValorVentaExonerada: 0,
      TotalVentaOperacionesGratu: 0,
      FormaPago: 'Contado',
      ImporteDePago: Number(factura.CCO_IMPDOC),
      TotalDescuento: Number(factura.docu_dcc.DCC_IMPDES),
      TotalAnticipo: 0,
      DetalleProduc: detalle,
      Anticipos: [],
      DocuReferencia: [],
      Impuesto: {
        "CodigoTributo": "1000",
        "CodigoAfectacionIGV": "10",
        "Importe": factura.CCO_IMPIGV,
        "NombreTributo": "IGV",
        "TasaImpuesto": factura.CCO_TASIGV,
        "ImporteValorVenta": factura.CCO_VALVTA,
        "TipoAfectacionTab07": "10",
        "CodigoInternacional": "VAT"
      }
    };

    return facturaXML;
  }

  generarXML(payload): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',  // Cambia a 'application/xml' si estás enviando XML en el cuerpo
      'Accept': 'application/xml'           // Indica que esperas una respuesta XML
    });

    return this.http.post<any>(
      `https://pavservice.com/pavso-factura-electronica/public/api/facturacion/documento`,
      payload, { headers: headers, responseType: 'text' as 'json' }
    );
  }
}
