<app-pavso-title-section [title]="'Descargar Excel'" [module]="'Informe'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <select-report [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event)"></select-report>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Cabeceras</div>
  </div>
  <div class="pav-filtro-container">
    <div class="row">
      <div class="col s12">
        <section class="example-section" *ngIf="ind_cabecera == 1" style="padding: 20px 0px;">
          <span class="example-list-section">
            <mat-checkbox [ngStyle]="{fontSize: '12px'}" class="example-margin" [checked]="allComplete"
              [indeterminate]="someComplete()" (change)="setAll($event.checked)" [disabled]="loaderData">
              {{task.name | uppercase}}
            </mat-checkbox>
          </span>
          <span class="example-list-section">
            <ul>
              <div class="row">
                <div *ngFor="let subtask of task.subtasks" class="col s12 m6 l4">
                  <li>
                    <mat-checkbox [ngStyle]="{fontSize: '12px'}" [name]="subtask.name" [(ngModel)]="subtask.completed"
                      [disabled]="loaderData" (ngModelChange)="updateAllComplete()">
                      {{subtask.name}}
                    </mat-checkbox>
                  </li>
                </div>
              </div>
            </ul>
          </span>
        </section>
      </div>
    </div>

  </div>

  <br>


  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Filtros</div>
  </div>
  <div class="pav-filtro-container">

    <div class="row">
      <div class="col s3" *ngIf="ind_monedaS == 0">
        <mat-form-field>
          <mat-label>Moneda</mat-label>
          <mat-select [(ngModel)]="filtro.monedaSelected" name="monedaSelect" [disabled]="loaderData">
            <mat-option *ngFor="let moneda of monedas" [value]="moneda.TMO_CODTMO">
              {{moneda.tmo_deslar}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s3" *ngIf="ind_monedaS == 1">
        <mat-form-field>
          <mat-label>Moneda</mat-label>
          <mat-select required [(ngModel)]="filtro.monedaSelected" name="monedaSingle" [disabled]="loaderData">
            <mat-option value="SO">En Nuevos Soles</mat-option>
            <mat-option value="DO">En Dólares</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s3" *ngIf="ind_fecha == 1">
        <mat-form-field>
          <mat-label>Rango de Fechas</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date" [disabled]="loaderData">
            <input matEndDate formControlName="end" placeholder="End date" [disabled]="loaderData">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
      <div class="col s3" *ngIf="ind_cuenta == 1">
        <select-search [label]="'Cuenta'" [placeholder]="'Ej. 42'" [name]="'cuenta'" [value]="'cuenta'"
          [description]="'description'" [data]="cuentas"></select-search>
      </div>
      <div class="col s3" *ngIf="ind_subdiario == 1">
        <select-search [label]="'Subdiario'" [placeholder]="'Ej. sub'" [name]="'sco_codsco'"
          [description]="'sco_descri'" [data]="subdiarios">
        </select-search>

      </div>
      <div class="col s3" *ngIf="ind_centrocosto == 1">
        <mat-form-field>
          <mat-label>Centro de Costo</mat-label>
          <mat-select [(ngModel)]="filtro.centroSelected" name="centroSelect" [disabled]="loaderData" multiple>
            <mat-option *ngFor="let centro of centros" [value]="centro.ccs_codccs">{{centro.ccs_codccs}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s3" *ngIf="ind_flujocaja == 1">
        <mat-form-field>
          <mat-label>Flujo Caja</mat-label>
          <mat-select [(ngModel)]="filtro.flujoSelected" name="flujoSelect" [disabled]="loaderData" multiple>
            <mat-option *ngFor="let flujo of flujosCaja" [value]="flujo.codigo_flujocaja">{{flujo.descri_flujocaja}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s3" *ngIf="ind_centrocosto == 1">
        <mat-form-field>
          <mat-label>Cuentas Banco</mat-label>
          <mat-select [(ngModel)]="filtro.cuentasBancoSelected" name="cuentasBancoSelect" [disabled]="loaderData"
            multiple>
            <mat-option *ngFor="let cuentaBanco of cuentasBanco" [value]="cuentaBanco.CBA_CODCBA">
              {{cuentaBanco.CBA_DESCRI}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s3" *ngIf="ind_auxiliar == 1 && reporteSeleccionado == 'AC'">

        <select-search [label]="'Auxiliares'" [placeholder]="'Ej. TORRES'" [name]="'CODIGO'" [value]="'CODIGO'"
          [description]="'NOMBRE'" [data]="auxiliares"></select-search>

      </div>
      <div class="col s3" *ngIf="ind_auxiliar == 1 && reporteSeleccionado == 'RV'">

        <select-search [label]="'Clientes'" [placeholder]="'Ej. TORRES'" [name]="'CODIGO'" [value]="'CODIGO'"
          [description]="'NOMBRE'" [data]="clientes"></select-search>

      </div>
      <div class="col s3" *ngIf="ind_auxiliar == 1 && reporteSeleccionado == 'RC'">

        <select-search [label]="'Proveedores'" [placeholder]="'Ej. TORRES'" [name]="'CODIGO'" [value]="'CODIGO'"
          [description]="'NOMBRE'" [data]="proveedores"></select-search>

      </div>
      <div class="col s3" *ngIf="ind_digitos == 1">
        <mat-form-field>
          <mat-label>Dígitos</mat-label>
          <mat-select [(ngModel)]="filtro.digitoSelected" name="digitoSelected" [disabled]="loaderData">
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
            <mat-option value="6">6</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <br>
</div>

<div align="center">
  <button class="pav-button-action" mat-flat-button color="primary" [class.spinner]="loaderReg" [disabled]="loaderData"
    (click)="verReporte()">{{buttonsName.BTN_SHOW_REPORT | translate}}</button>
</div>
