import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { forkObs } from '@utils/observables/fork';
import { fillTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { ConfigureMotive } from 'src/app/models';
import { AlmacenService, AuthenticationService } from 'src/app/services';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';

@Component({
  selector: 'app-configure-motive-create',
  templateUrl: './configure-motive-create.component.html',
  styleUrls: ['./configure-motive-create.component.css']
})
export class ConfigureMotiveCreateComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  displayedColumns: string[] = ['tin_codtin', 'ccn_coddeb', 'ccn_codhab', 'ccn_coddeb2', 'ccn_codhab2', 'ccn_coddeb3', 'ccn_codhab3'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  configureMotive: ConfigureMotive;

  motivos: any[] = [];
  almacenes: any[] = [];

  NAMES_CONSTANT: INameConstant;

  loading$: Subscription;
  motivo$: Subscription;
  almacen$: Subscription;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _almacenService: AlmacenService,
    private store: Store<PavsoState>
  ) {
    this.dataSource = fillTable([], this.paginator, this.sort);
    this.configureMotive = new ConfigureMotive();

    this.NAMES_CONSTANT = NAMES_CONSTANTS;
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }



  loadData(): void {
    this.loaderData = true;
    this.configureMotive.mic_indsta = "1";
    this.configureMotive.mic_coduse = this._authService.getUsuarioSistema();
    this.configureMotive.mic_fecupd = new Date();

    this._activatedRoute.params.subscribe(({id}) => {
      this.loadMaestros();
      if(id == '0') return;

      const [alm, tdo, mmo] = [id.substring(0, 4), id.substring(4, 7), id.substring(7, 11)];
      this._almacenService.verConfiguraEnlaceMotivoAlmacen(alm, tdo, mmo).subscribe(
        ([configuracion]) => {
          this.configureMotive.alm_codalm = configuracion.alm_codalm;
          this.configureMotive.tdo_codtdo = configuracion.tdo_codtdo;
          this.configureMotive.mmo_codmmo = configuracion.mmo_codmmo;
          this.configureMotive.mic_indsta = configuracion.mic_indsta;
          this.configureMotive.mic_ordpro = configuracion.mic_ordpro;
          this.configureMotive.mic_glomic = configuracion.mic_glomic;
          this.configureMotive.alm_codtra = configuracion.alm_codtra;

          this.dataSource = fillTable(configuracion.detalle, this.paginator, this.sort);
        },
        error => this._snackBarService.showError(error.error.msg, "OK")

      )

    })
  }

  loadMaestros(): void {

    forkObs(
      this._almacenService.obtenerMotivos(),
      this._almacenService.obtenerAlamacenes()
    ).then(([motivos, almacenes]) => {

      this.motivos = motivos;
      this.almacenes = almacenes;
      this.loaderData = false;

    }).catch(err => {

      this.loaderData = false;
      this._snackBarService.showError("Error al obtener maestros", 'OK');

    })

  }

  registrarConfiguracion(f: NgForm): void {

  }

  volver(): void {
    this._router.navigate(['/modulo-almacen/configurar-enlace-motivos-almacen']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.motivo$,
      this.almacen$,
      this.loading$
    ])

  }
}
