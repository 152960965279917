<app-pavso-title-section [title]="'Canje por letra'" [module]="'Cuentas por cobrar'" [estado]="driver.CHO_INDSTA"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

    <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarCanjeLetra(f)" (keydown.enter)="$event.preventDefault()">

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <mat-card class="mat-elevation-z0">
          <div class="row">
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Período</mat-label>
                      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start date">
                        <input matEndDate formControlName="end" placeholder="End date">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>

                      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l6">
                  <mat-form-field>
                      <mat-label>Sub. Diario</mat-label>
                      <mat-select required name="tipo_doc" [disabled]="loaderData">
                          <mat-option *ngFor="let subdiario of subdiarios" [value]="subdiario.sco_codsco">
                              {{subdiario.sco_descri}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Voucher</mat-label>
                      <input type="text" matInput placeholder="Voucher" name="voucher" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Fecha</mat-label>
                      <input matInput [matDatepicker]="fecha" name="fecha">
                      <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
                      <mat-datepicker #fecha></mat-datepicker>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>T.C.</mat-label>
                      <input type="number" appTipoCambioFormat step="any" min="0" matInput placeholder="T.C." name="tc" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Cliente</mat-label>
                      <mat-select required name="cliente" [disabled]="loaderData">
                          <mat-option *ngFor="let cliente of clientes" [value]="cliente.fasd">
                              {{cliente.fadsf}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Nro. Letras</mat-label>
                      <input type="number" min="0" matInput placeholder="T.C." name="tc" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <app-currency-select [moneda]="'SO'" [disable]="loaderData"></app-currency-select>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Valor</mat-label>
                      <input type="number" min="0" matInput placeholder="Valor" name="valor" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Vendedor</mat-label>
                      <mat-select required name="vendedor" [disabled]="loaderData">
                          <mat-option *ngFor="let vendedor of vendedores" [value]="vendedor.sad">
                              {{vendedor.assa}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Glosa</mat-label>
                      <textarea matInput placeholder="Glosa"></textarea>
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Importe Nacional</mat-label>
                      <input type="number" min="0" matInput placeholder="Importe Nacional" name="importe_nacional" required [disabled]="loaderData">
                  </mat-form-field>
              </div>

              <div class="col s12 m12 l3">
                  <mat-form-field>
                      <mat-label>Importe Dólares</mat-label>
                      <input type="number" min="0" matInput placeholder="Importe Dólares" name="importe_dolares" required [disabled]="loaderData">
                  </mat-form-field>
              </div>
          </div>

      </mat-card>
      <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
        <div id="botonesAdicionales">
        </div>

      </pav-form-actions>

    </form>
</div>
