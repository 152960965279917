<app-pavso-title-section [title]="'Lista de precios'" [module]="'Ventas'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">
      <table-title-action title="Todas las listas" url="/modulo-ventas/lista-de-precio" entity="" [params]="['0', '0']"></table-title-action>

      <table mat-table [dataSource]="dataSource" matSort >

        <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
              <div class="pav-btns-container">
                <div matTooltip="Editar lista de precio"
                  class="pav-btn-circle-edit"
                  (click)="editarListaPrecio(row.TLP_CODTLP, row.LPC_CODLPC)">
                  <mat-icon svgIcon="edit-white"></mat-icon>
                </div>
                <div matTooltip="Ver PDF"
                  class="pav-btn-circle-edit"
                  (click)="verPdf(row.LPC_CODLPC)">
                  <mat-icon svgIcon="paper"></mat-icon>
                </div>
              </div>
            </td>
        </ng-container>

          <ng-container matColumnDef="LPC_CODLPC">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
              <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.LPC_CODLPC}} </td>
          </ng-container>

          <ng-container matColumnDef="LPC_GLOLPC">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
              <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.LPC_GLOLPC}} </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

          </tr>

      </table>

      <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>
