export class Subdiario {
  cia_codcia: any;
  sco_codsco: any;
  sco_descri: any;
  sco_desabr: any;
  gsc_codgsc: any;
  suc_codsuc: any;
  sco_coduse: any;
  sco_fecupd: any;
  sco_indsta: string;
  sco_indcom: any;
  ccn_codmn: any;
  ccn_codme: any;
  ccn_coddet: any;
  ccn_codimp: any;
  sco_indprv: any;
  ccn_codmn1: any;
  ccn_codme1: any;
  sco_indtes: any;
  sco_indtee: any;
  sco_indcob: any;
  sco_indban: any;
  sco_indcre: any;
  sco_indtex: any;
  sco_indcch: any;
  sco_indtap: any;
  SCO_INDMOD: any;
  sco_idsco: any;
  sco_dcatra: any;

  constructor() {
    this.sco_indsta = '1';
    this.sco_fecupd = new Date();
    this.sco_indban = 0;
  }
}
