import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfigurationService, CrmService, ExportExcelService, VentasService } from 'src/app/services';
import { formatDateWithSlash, getHourMinuteSecondsCurrent } from 'src/app/utils/formats/date.format';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { REPORTES_CRM } from '@data/json/reportes';
import { CrmReportService } from 'src/app/services/reports/modules/crm-report.service';
import { HeaderReport } from '@data/interfaces/headers.report';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { SnackBarService } from '@shared/services/snackbar.service';
import { FiltroReporteCRM } from 'src/app/models/crm/filtro-reporte-crm';
import { fillTable } from '@utils/tables/table';
import { CanalService } from 'src/app/services/api/crm/maestros/canal.service';
import { LineaNegocioService } from 'src/app/services/api/crm/maestros/linea-negocio.service';
import { EtapaService, ResponsableService, TipoAtencionService, TipoNegociacionService } from 'src/app/services/api/crm/maestros';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';

@Component({
  selector: 'app-crm-report',
  templateUrl: './crm-report.component.html',
  styleUrls: ['./crm-report.component.css']
})
export class CrmReportComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['cli_codcli', 'tdo_codtdo', 'cco_numdoc', 'CCO_FECEMI', 'lpd_descri', 'prd_codprd', 'prd_desesp'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataForExcel = [];

  dataDetalleFacturacion = [];
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  year: string;
  month: string;

  reportes: any[] = REPORTES_CRM;

  reporteSeleccionado: any = ['CRM_RO'];

  loading: boolean;
  loaderData: boolean;


  // Filtros de reporte de CRM
  filtro: FiltroReporteCRM;
  canal: string;
  vendedor: string;
  lineaNegocio: string;
  tipoNegociacion: string;
  cliente: string;
  bienServicio: string;

  period$: Subscription;
  loading$: Subscription;
  verReporte$: Subscription;
  dataForm$: Subscription;

  /** filtrar canales */
  canales: any[] = [];

  /** filtrar vendedores */
  vendedores: any[] = [];
  lineas: any[] = [];
  tiposNegociacion: any[] = [];
  clientes: any[] = [];
  bienes: any[] = [];
  responsables: any[] = [];
  etapas: any[] = [];
  tiposAtencion: any[] = [];

  constructor(
    private readonly _configurationService: ConfigurationService,
    private readonly _crmReportService: CrmReportService,
    private readonly _snackBarService: SnackBarService,
    private readonly _canalService: CanalService,
    private readonly _crmService: CrmService,
    private readonly _ventasService: VentasService,
    private readonly _lineaNegocioService: LineaNegocioService,
    private readonly _tipoNegociacion: TipoNegociacionService,
    private readonly _clienteService: ClienteService,
    private readonly _productService: ProductoService,
    private readonly _etapaService: EtapaService,
    private readonly _tipoAtencionService: TipoAtencionService,
    private readonly _responsableService: ResponsableService,
    public ete: ExportExcelService,
    private store: Store<PavsoState>,
  ) {
    this.filtro = new FiltroReporteCRM();

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  canales$: Subscription;
  vendedores$: Subscription;
  lineasNegocio$: Subscription;
  tiposNegociacion$: Subscription;
  clientes$: Subscription;
  productos$: Subscription;
  etapas$: Subscription;
  tiposAtencion$: Subscription;
  responsables$: Subscription;


  loadData(): void {

    this.canales$ = this._canalService.obtenerCanales().subscribe(
      canales => {
        this.canales = canales;

        this.vendedores$ = this._ventasService.obtenerVendedores().subscribe(
          vendedores => {

            this.vendedores = vendedores;

            this.lineasNegocio$ = this._lineaNegocioService.obtenerLineasNegocio().subscribe(
              lineas => {
                this.lineas = lineas;

                this.tiposNegociacion$ = this._tipoNegociacion.obtenerTiposNegociacion().subscribe(
                  tiposNegociacion => {
                    this.tiposNegociacion = tiposNegociacion;

                    this.clientes$ = this._clienteService.obtenerClientes().subscribe(
                      clientes => {
                        this.clientes = clientes;

                        this.productos$ = this._productService.obtenerProductos().subscribe(
                          productos => {
                            this.bienes = productos;

                            this.etapas$ = this._etapaService.obtenerEtapas().subscribe(
                              etapas => {
                                this.etapas = etapas;

                                this.tiposAtencion$ = this._tipoAtencionService.obtenerTiposAtencion().subscribe(
                                  tiposAtencion => {
                                    this.tiposAtencion = tiposAtencion;

                                    this.responsables$ = this._responsableService.obtenerResponsables().subscribe(
                                      responsables => {
                                        this.responsables = responsables;
                                      },
                                      error => {
                                        this._snackBarService.showError('Error al obtener responsables', 'Ok');
                                      }
                                    )
                                  },
                                  error => {
                                    this._snackBarService.showError('Error al obtener productos', 'Ok');
                                  }
                                )
                              },
                              error => {
                                this._snackBarService.showError('Error al obtener productos', 'Ok');
                              }
                            )
                          },
                          error => {
                            this._snackBarService.showError('Error al obtener productos', 'Ok');
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener clientes', 'Ok');
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener tipos de negociación', 'Ok');
                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener vendedores', 'Ok');
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener vendedores', 'Ok');
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener canales', 'Ok');
      }
    )

  }

  seleccionarReporte(event): void {

    this.reporteSeleccionado[0] = event.id;

    let indicador = "";
    switch (this.reporteSeleccionado[0]) {
      case "CRM_RO":
        indicador = "51002"
        break;
      case "CRM_SO":
        indicador = "51002"
        break;
      case "CRM_VP":
        indicador = "51002"
        break;
      default:
        break;
    }
  }

  trackByCanal(index, canal): string {
    return canal? canal.idmtk: undefined;
  }

  trackByVendedor(index, vendedor): string {
    return vendedor? vendedor.VDE_CODVDE: undefined;
  }

  trackByLinea(index, linea): string {
    return linea? linea.codoln: undefined;
  }

  trackByTipoNegociacion(index, tipo): string {
    return tipo? tipo.codotn: undefined;
  }

  trackByCliente(index, cliente): string {
    return cliente? cliente.cli_codcli: undefined;
  }

  trackByProducto(index, producto): string {
    return producto? producto.prd_codprd: undefined;
  }

  trackByTipoAtencion(index, tipoAtencion): string {
    return tipoAtencion? tipoAtencion.prd_codprd: undefined;
  }

  trackByEtapa(index, etapa): string {
    return etapa? etapa.prd_codprd: undefined;
  }

  verReporte(): void {

    console.log('filtro', this.filtro)

    this.loading = true;

    const headers: HeaderReport = {
      year: this.year,
      month: this.month,
      clientName: this._configurationService.obtenerNombreCompaniaCliente(),
      client: this._configurationService.obtenerClienteId(),
      date: formatDateWithSlash(new Date()),
      time: getHourMinuteSecondsCurrent(new Date()),
      currency: '',
      username: this._configurationService.obtenerNombreCompaniaCliente(),
      codePage: this._configurationService.obtenerCodigoPagina()
    }

    this._crmReportService.verReporte(this.reporteSeleccionado[0], headers, this.filtro, null)
    .then(_ => this.loading = false)
    .catch(_ => this.loading = false)
    .finally(() => this.loading = false)

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.period$, this.dataForm$, this.loading$, this.verReporte$, this.dataForm$]);
  }
}
