<div [ngStyle]="{width: isResponsive? '100%': '800px'}">
  <mat-form-field [ngClass]="class">
    <mat-label>{{label}}</mat-label>
    <input [name]="name" matInput [placeholder]="placeholder" [value]="(codigo? codigo: '') + (codigo? ' | ': '') + (codigo? descripcion: '')">
    <button *ngIf="codigo" type="button" mat-icon-button matSuffix [attr.aria-label]="'Hide password'" (click)="editar()">
      <mat-icon>edit</mat-icon>
    </button>
    <button *ngIf="!codigo" type="button" mat-icon-button matSuffix [attr.aria-label]="'Hide password'" (click)="agregar()">
      <mat-icon>add</mat-icon>
    </button>
    <button type="button" mat-icon-button matSuffix [attr.aria-label]="'Hide password'" (click)="buscar()">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
</div>
