import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { SnackBarService } from "@shared/services/snackbar.service";
import { CrmService, VentasService } from "src/app/services";
import { fillTable, searchInTable } from '@utils/tables/table';
import { Store } from "@ngrx/store";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Subscription } from "rxjs";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { NgForm } from "@angular/forms";
import { CanalService } from "src/app/services/api/crm/maestros/canal.service";
import { TipoNegociacionService } from "src/app/services/api/crm/maestros/tipo-negociacion.service";
import { LineaNegocioService } from "src/app/services/api/crm/maestros/linea-negocio.service";
import { ResponsableService } from "src/app/services/api/crm/maestros/responsable.service";
import { ApiCRMOperacionesSeguimientoService } from "src/app/services/api/crm/operaciones/crm.operaciones.seguimiento.service";
import { FiltroConsultaSeguimiento } from "src/app/models/crm";
import { forkObs } from "@utils/observables/fork";

@Component({
  selector: 'app-consulta-seguimiento-oportunidad',
  templateUrl: './consulta-seguimiento-oportunidad.component.html',
  styleUrls: ['./consulta-seguimiento-oportunidad.component.scss']
})
export class ConsultaSeguimientoOportunidad implements OnInit, OnDestroy{

  buttonsName: INameConstant = NAMES_CONSTANTS;
  loaderReg: boolean = false;
  loaderSearch: boolean = false;
  loaderData: boolean = false;

  displayedColumns: string[] = ['acciones', 'otk_idtkt', 'otk_fecreg', 'cli_nomcli', 'oms_descri', 'ccl_nomccl', 'oln_descri', 'otn_descri', 'otk_asunto', 'oet_descri'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns2: string[] = ['acciones', 'osd_corosd', 'osd_desact', 'osd_fecpro', 'osd_nomres', 'osd_desdet'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild("paginator2") paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  displayedColumnsContactar: string[] = ['acciones', 'otk_idtkt', 'ost_idset', 'otk_fecreg', 'ccl_nomccl', 'ost_descri', 'ors_nombre'];
  dataSourceContactar: MatTableDataSource<any>;

  @ViewChild('MatPaginatorEtapas') paginatorContactar: MatPaginator;
  @ViewChild(MatSort) sortContactar: MatSort;

  displayedColumnsReunion: string[] = ['acciones', 'otk_idtkt', 'ost_idset', 'otk_fecreg', 'ost_numins', 'ost_descri', 'ors_nombre', 'btn_lista'];
  dataSourceReunion: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorReunion: MatPaginator;
  @ViewChild(MatSort) sortReunion: MatSort;

  displayedColumnsAlcance: string[] = ['acciones', 'otk_idtkt', 'ost_idset', 'otk_fecreg', 'ost_descri', 'ors_nombre', 'btn_desc'];
  dataSourceAlcance: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorAlcance: MatPaginator;
  @ViewChild(MatSort) sortAlcance: MatSort;

  displayedColumnsPresupuesto: string[] = ['acciones', 'otk_idtkt', 'ost_idset', 'otk_fecreg', 'opc_numpoc', 'ost_descri', 'ors_nombre'];
  dataSourcePresupuesto: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorPresupuesto: MatPaginator;
  @ViewChild(MatSort) sortPresupuesto: MatSort;

  displayedColumnsCotizacion: string[] = ['acciones', 'otk_idtkt', 'ost_idset', 'otk_fecreg', 'pcc_numcot', 'ost_descri', 'ors_nombre'];
  dataSourceCotizacion: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorCotizacion: MatPaginator;
  @ViewChild(MatSort) sortCotizacion: MatSort;

  displayedColumnsNegociacion: string[] = ['acciones', 'otk_idtkt', 'ost_idset', 'otk_fecreg', 'ost_descri', 'ors_nombre'];
  dataSourceNegociacion: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorNegociacion: MatPaginator;
  @ViewChild(MatSort) sortNegociacion: MatSort;

  loading$: Subscription;
  etapa$: Subscription;
  linea$: Subscription;
  canal$: Subscription;
  cliente$: Subscription;
  responsables$: Subscription;
  tiposNegociacion$: Subscription;

  totalMN: number = 0;
  totalME: number = 0;

  listaEtapa: any [] = [];

  clientes: any[] = [];

  etapas: any[] = [];
  responsables: any[] = [];

  lineas: any[] = [];
  tipos: any[] = [];
  canales: any[] = [];

  filtro: FiltroConsultaSeguimiento;

  oportunidadSeleccionada: any;

  constructor(
    private _crmService: CrmService,
    private _canalService: CanalService,
    private _tipoNegociacionService: TipoNegociacionService,
    private _lineaNegocioService: LineaNegocioService,
    private _responsableService: ResponsableService,
    private _apiSeguimientoCrmServivce: ApiCRMOperacionesSeguimientoService,
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
  ){

    this.filtro = new FiltroConsultaSeguimiento();
    console.log('filtro', this.filtro)

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource2 = fillTable([], this.paginator2, this.sort2);

    this.dataSourceContactar = fillTable([], this.paginatorContactar, this.sortContactar);

    this.dataSourceReunion = fillTable([], this.paginatorReunion, this.sortReunion);

    this.dataSourceAlcance = fillTable([], this.paginatorAlcance, this.sortAlcance);

    this.dataSourcePresupuesto = fillTable([], this.paginatorPresupuesto, this.sortPresupuesto);

    this.dataSourceCotizacion = fillTable([], this.paginatorCotizacion, this.sortCotizacion);

    this.dataSourceNegociacion = fillTable([], this.paginatorNegociacion, this.sortNegociacion);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.loadMaestros();
    })
  }

  obtenerSeguimientosPorEtapas(row): void {
    this.oportunidadSeleccionada = row.otk_idtkt

    this.etapa$ = this._apiSeguimientoCrmServivce.obtenerSeguimientosPorEtapa(this.oportunidadSeleccionada).subscribe(
      etapas => {
        this.etapas = etapas;
        this.listaEtapa = etapas;

        this.dataSourceContactar = fillTable(etapas[0].seguimientos, this.paginatorContactar, this.sortContactar);
      },
      error => {
        this._snackBarService.showError('Error al obtener seguimientos por etapa', 'Ok');
      }
    )
  }

  loadMaestros(): void {
    this.loaderData = true;
    forkObs(
      this._apiSeguimientoCrmServivce.obtenerSeguimientosPorEtapa(this.oportunidadSeleccionada),
      this._canalService.obtenerCanales(),
      this._lineaNegocioService.obtenerLineasNegocio(),
      this._ventasService.obtenerClientes(),
      this._responsableService.obtenerResponsables(),
      this._tipoNegociacionService.obtenerTiposNegociacion()
    ).then(data => {
      this.etapas = data[0];
      this.listaEtapa = data[0];
      this.dataSourceContactar = fillTable(data[0][0].seguimientos, this.paginatorContactar, this.sortContactar);

      this.canales = data[1];
      this.lineas = data[2];
      this.clientes = data[3];
      this.responsables = data[4];
      this.tipos = data[5];

      this.loadData();
    })

  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  trackByCliente(index, cliente): string {
    return cliente? cliente.cli_codcli: undefined;
  }

  trackByEtapa(index, etapa): string {
    return etapa? etapa.codoet: undefined;
  }

  trackByResponsable(index, responsable): string {
    return responsable? responsable.idres: undefined;
  }

  trackByLinea(index, linea): string {
    return linea? linea.codoln: undefined;
  }

  trackByTipoNegociacion(index, tipo): string {
    return tipo? tipo.codotn: undefined;
  }

  trackByCanal(index, canal): string {
    return canal? canal.idmtk: undefined;
  }

  loadData(): void {
    this._crmService.obtenerConsultaSeguimientoOportunidad().subscribe(
      ({result, result2, result3}) => {

        console.log('OPORTUNIDADES', result)
        this.dataSource = fillTable(result.reverse(), this.paginator, this.sort);

        console.log('detalleseguimiento', result3)

        this.dataSource2 = fillTable(result3.reverse(), this.paginator2, this.sort2);
        this.loaderData = false;

      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK");
        this.loaderData = false;
      }
    )
  }

  seleccionarEtapa(event): void {
    const etapa = this.etapas[event.index];

    this.dataSourceContactar = fillTable(etapa.seguimientos, this.paginatorContactar, this.sortContactar);
  }

  limpiarOportunidades(): void {
    this.filtro = new FiltroConsultaSeguimiento();
  }

  filtrarOportunidades(f: NgForm): void {
    this.loaderSearch = true;

    console.log('payload', this.filtro)

    this._crmService.obtenerRegistrosOportunidadConFiltro(this.filtro).subscribe(
      response => {
        this.loaderSearch = false;

        if(response.length > 0) this.dataSource = fillTable(response.reverse(), this.paginator, this.sort);

        console.log('resultados de busqueda', response);
      },
      error => {
        this.loaderSearch = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.etapa$,
      this.linea$,
      this.canal$,
      this.cliente$,
      this.responsables$,
      this.tiposNegociacion$,
    ])

  }

}
