<app-pavso-title-section [title]="'Inventario Físico'" [module]="'Almacén'" [estado]="'1'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <mat-card class="mat-elevation-z0">
    <div class="row">
      <div class="col s12 m12 l12 xl3">
        <select-search [label]="'Almacén'" [placeholder]="'Ej. Toribio'" [name]="'ALM_CODALM'" [value]="'ALM_CODALM'"
          [description]="'ALM_DESABR'" [data]="almacenes">
        </select-search>

        <!-- <mat-form-field>
                    <mat-label>Almacén</mat-label>
                    <mat-select [disabled]="loaderData">
                      <mat-option *ngFor="let almacen of almacenes" [value]="almacen.ALM_CODALM">
                        {{almacen.ALM_DESABR}}
                      </mat-option>
                    </mat-select>
                </mat-form-field> -->
      </div>
      <div class="col s12 m12 l12 xl3">
        <mat-form-field>
          <mat-label>Nro. Registro</mat-label>
          <input matInput placeholder="Nro. Registro" [disabled]="loaderData">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l12 xl3">
        <mat-form-field>
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker" [disabled]="loaderData">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col s12 m12 l12 xl6">
        <mat-form-field>
          <mat-label>Observación</mat-label>
          <input matInput placeholder="Observación" [disabled]="loaderData">
        </mat-form-field>
      </div>
      <div class="col s12 m12 l12 xl3">
        <button mat-stroked-button color="primary" [disabled]="loaderData">Cargar Saldo de Productos No
          Ingresados</button>
      </div>
    </div>
  </mat-card>
  <br>
  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Detalle</div>
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
    &nbsp;
    &nbsp;
    <div class="pav-button-icon-add" matTooltip="Agregar ítem" (click)="agregarItem()"
      [ngStyle]="{display: loaderData? 'none': ''}">
      <mat-icon svgIcon="add-circle"></mat-icon>
    </div>
    &nbsp;
    <div class="pav-button-icon-delete" matTooltip="Eliminar ítem" (click)="quitarItem()"
      [ngStyle]="{display: loaderData? 'none': ''}">
      <mat-icon svgIcon="remove"></mat-icon>
    </div>
    &nbsp;
    <div class="pav-button-icon-insert" matTooltip="Insertar ítem" (click)="insertarItem()"
      [ngStyle]="{display: loaderData? 'none': ''}">
      <mat-icon svgIcon="add-outline"></mat-icon>
    </div>
  </div>

  <div class="mat-elevation-z0 overflow-x">

    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
        <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
          <div class="pav-btns-container">
            <div class="pav-btn-circle-edit" matTooltip="Editar"
              [routerLink]="['/modulo-almacen/editar-cliente-comercial', row.cli_codcli]">
              <mat-icon svgIcon="edit-white"></mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="item">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
        <td mat-cell *matCellDef="let row; let i = index" data-label="item"> {{i + 1}} </td>
      </ng-container>

      <ng-container matColumnDef="codigo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
        <td mat-cell *matCellDef="let row" data-label="Cliente"> {{row.PRD_CODPRD}} </td>
      </ng-container>

      <ng-container matColumnDef="descripcion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono">
          <div class="pavso-container-description">
            {{row.DESC_ESPA}}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="cod_anterior">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Anterior </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
      </ng-container>

      <ng-container matColumnDef="marca">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Marca </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.COD_MARCA}} </td>
      </ng-container>

      <ng-container matColumnDef="part_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Part Number </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.cli_numtlf}} </td>
      </ng-container>

      <ng-container matColumnDef="um">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.UNIDAD}} </td>
      </ng-container>

      <ng-container matColumnDef="cantidad_inventario">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Inventario </th>
        <td mat-cell *matCellDef="let row" data-label="Teléfono"> {{row.DESC_INVENTARIO}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">No se encontraron registros</td>

      </tr>

    </table>

  </div>
  <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

</div>
<pav-form-actions [disabled]="loaderData" [btnName]="'Grabar'" [isLoading]="loaderReg"
  urlBack="/modulo-almacen/inventario-fisico"
  >
  <div id="botonesAdicionales">

  </div>
</pav-form-actions>
