import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { RegistroCompra } from '../../models';
import { TipoServicioProveedor } from 'src/app/models/compras/tipo-servicio-proveedor';
import { TerminoCompra } from 'src/app/models/compras/termino-compra';
import { AutorizadoAprobarCompras } from 'src/app/models/compras/autorizado-aprobar-compras';
import { LugarDespacho } from 'src/app/models/compras/lugar-despacho';
import { AutorizadoAprobarSolicitud } from 'src/app/models/compras/autorizado-aprobar-solicitud';
import { CondicionPago } from 'src/app/models/compras/condicion-pago';
import { map } from 'rxjs/operators';
import { formatDateWithDash, formatDateWithSlash } from '@utils/formats/date.format';
import { IParam, generarParams } from './utilities/params';

@Injectable({
  providedIn: 'root',
})
export class ComprasService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) {}

  /**
   *
   * @returns
   */
  obtenerProveedores(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascompras/listarproveedor/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerLugaresDeEntrega(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascompras/listarlugarentregacia/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerSolicitantes(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascompras/listarsolicitante/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerAprobadores(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascompras/listaraprobadores/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerTipoOrdenCompra(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascompras/tipoordencompra`
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  obtenerPdfOrdenCompra(id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascompras/mostrarordenescomprapdf/${this._configurationService.obtenerCompaniaCliente()}/${id}`
    );
  }

  /**
   * Retorna todos los registros de compra
   * @returns
   */
  listarRegistrosDeCompra({params = []}: {params?: Array<IParam>} = {}): Observable<any> {

    const paramsStr = generarParams(params);

    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/listar/${this._configurationService.obtenerCompaniaCliente()}${paramsStr}`
    );
  }

  /**
   * Obtener registros de compra periodo
   * @returns
   */
  listarRegistrosDeCompraPeriodo(anio: string, mes: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/listar/${this._configurationService.obtenerCompaniaCliente()}?ano_codano=${anio}&mes_codmes=${mes}`
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  mostrarRegistroDeCompra(id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/mostrar/${this._configurationService.obtenerCompaniaCliente()}/${id}`
    );
  }

  /**
   *
   * @param payload
   * @returns
   */
  crearRegistroDeCompra(payload: RegistroCompra): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/crear`,
      payload
    );
  }

  /**
   * Editar registro de compras
   * @param payload
   * @returns
   */
  editarRegistroDeCompra(payload: RegistroCompra): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/editar`,
      payload
    );
  }

  obtenerUltimoRegistroCompra(subdiario, cliente): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/historial/${this._configurationService.obtenerCompaniaCliente()}/${subdiario}/${cliente}`
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @returns
   */
  listaResumidaRegistroCompras(ano, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompra/listar/resumido/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`
    );
  }

  /**
   *
   * @returns
   */
  listarTiposRetencion(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/tiporetenciones`
    );
  }

  obtenerTiposOperacionRetencion(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tipos-operacion-retencion`
    );
  }

  /**
   * Obtiene los tipos de impuesto de retencion de manera general
   * @returns
   */
  listarTiposImpuestoRetencion(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/tipoimpuestoretenciones`
    );
  }

  obtenerTiposImpuestoRetencionPorFechaEmision(fecemi: Date): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tipos-impuesto-retencion/${formatDateWithDash(fecemi)}`
    );
  }

  /**
   *
   * @returns
   */
  obtenerCondicionesPago(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/condicionpagos`
    ).pipe(
      map((condiciones: any) => {
        return condiciones.map(condicion => {
          condicion['description'] = `${condicion.CPA_CODCPA} | ${condicion.CPA_DESCRI} `
          condicion['description_reverse'] = `${condicion.CPA_DESCRI} | ${condicion.CPA_CODCPA}`
          return condicion;
        })
      })
    );
  }

  /**
   *
   * @param condicionPago
   * @returns
   */
  registrarCondicionPago(condicionPago: CondicionPago): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/condicion-de-pago`,
      condicionPago
    );
  }

  /**
   *
   * @param condicionPago
   * @returns
   */
  actualizarCondicionPago(condicionPago: CondicionPago): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/condicion-de-pago`,
      condicionPago
    );
  }

  /**
   *
   * @param codigo
   * @returns
   */
  listarColumnasInformeRegCompras(codigo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/listacamposreporte/${codigo}/001`
    );
  }

  /**
   *
   * @returns
   */
  listarTiposServicioProveedor(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablas/tiposervicioproveedor/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param tipo
   * @returns
   */
  registrarTipoServicioProveedor(tipo: TipoServicioProveedor): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/tipo-servicio-proveedor`,
      tipo
    );
  }

  /**
   *
   * @param tipo
   * @returns
   */
  actualizarTipoServicioProveedor(tipo: TipoServicioProveedor): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/tipo-servicio-proveedor`,
      tipo
    );
  }

  /**
   *
   * @param terminoCompra
   * @returns
   */
  registrarTerminoCompra(terminoCompra: TerminoCompra): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/termino-de-compra`,
      terminoCompra
    );
  }

  /**
   *
   * @param terminoCompra
   * @returns
   */
  actualizarTerminoCompra(terminoCompra: TerminoCompra): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/termino-de-compra`,
      terminoCompra
    );
  }

  /**
   *
   * @param autorizado
   * @returns
   */
  registrarAutorizadoAprobarCompra(autorizado: AutorizadoAprobarCompras): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/autorizado-aprobar-compras`,
      autorizado
    );
  }

  /**
   *
   * @param autorizado
   * @returns
   */
  actualizarAutorizadoAprobarCompra(autorizado: AutorizadoAprobarCompras): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/autorizado-aprobar-compras`,
      autorizado
    );
  }

  /**
   *
   * @param autorizado
   * @returns
   */
  registrarAutorizadoAprobarSolicitud(autorizado: AutorizadoAprobarSolicitud): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/autorizado-aprobar-solicitud`,
      autorizado
    );
  }

  /**
   *
   * @param autorizado
   * @returns
   */
  actualizarAutorizadoAprobarSolicitud(autorizado: AutorizadoAprobarSolicitud): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/autorizado-aprobar-solicitud`,
      autorizado
    );
  }

  /**
   *
   * @returns
   */
  listarLugarDespacho(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/lugar-de-despacho/${this._configurationService.obtenerCompaniaCliente()}`,
    );
  }

  /**
   *
   * @param lugarDespacho
   * @returns
   */
  registrarLugarDespacho(lugarDespacho: LugarDespacho): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/lugar-de-despacho`,
      lugarDespacho
    );
  }

  /**
   *
   * @param lugarDespacho
   * @returns
   */
  actualizarLugarDespacho(lugarDespacho: LugarDespacho): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/lugar-de-despacho`,
      lugarDespacho
    );
  }

  obtenerDataReportePedidosVsStock(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/compras/reporte-pedidos-vs-stock/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  listarFacturasProveedor(codcli): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/proveedor/factura/verifica/${this._configurationService.obtenerCompaniaCliente()}/${codcli}`
    );

  }

  obtenerUltimoRegistroTipoOperacion(cli_codcli: string, sco_codsco: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/registrocompras/ultimoregistro/${this._configurationService.obtenerCompaniaCliente()}/${cli_codcli}/${sco_codsco}`
    );
  }
}
