export class PlanCuenta {
  cia_codcia: string;
  ccn_codccn: string;
  ccn_descri: string;
  ccn_desabr: string;
  ccn_tipccn: number;
  ccn_indcco: string;
  tcn_codtcn: string;
  tmo_codtmo: string;
  ccn_indccs: number;
  ccn_indana: number;
  ccn_inddes: number;
  ccn_tipdes: any;
  ccn_codtra: any;
  ccn_coddbe: any;
  ccn_indtra: number;
  ccn_indcie: number;
  ccn_codcie: any;
  ccn_indpls: number;
  ccn_indclb: string;
  ccn_tipaju: string;
  ccn_tcaaju: string;
  ccn_indsta: string;
  ccn_coduse: string;
  ccn_fecupd: Date;
  ccn_tipacm: any;
  ccn_banco: any;
  ccn_ctaban: any;
  ccn_ctaequ: any;
  ccn_indpcg: any;
  ccn_glopcg: any;
  ccn_indexr: number;
  csu_codcsu: any;
  ccn_indesg: any;
  fca_codfca: any;
  ccn_codclb: any;
  ccs_codccs: any;
  ccn_indnat: number;
  ccn_indccf: number;
  ccn_uselog: string;
  ccn_feclog: any;
  ccn_logope: any;

  constructor() {
    this.ccn_indsta = '1';
    this.ccn_fecupd = new Date();
  }
}
