import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, Input, OnDestroy, Output, EventEmitter, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { ConfirmationComponent } from "@shared/components/dialogs/confirmation/confirmation.component";
import { DialogService } from "@shared/services/dialog.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { Permisos } from "src/app/models/permisos";

@Component({
  selector: 'pav-form-actions',
  templateUrl: './pav-form-actions.component.html',
  styleUrls: ['./pav-form-actions.component.scss']
})
export class PavFormActionsComponent implements OnDestroy, OnInit {

  @Input() disabled: boolean;
  @Input() btnName: string;
  @Input() isLoading: boolean;
  @Input() loaderAnular: boolean;
  @Input() loaderFormato: boolean;
  @Input() loaderImprimir: boolean;
  @Input() urlBack: string;
  @Input() ngStyle: any = {};
  buttonsName: INameConstant = NAMES_CONSTANTS;
  @Output() clickMasOpciones: EventEmitter<boolean>;
  @Output() clickReset: EventEmitter<boolean>;
  @Output() clickFormato: EventEmitter<boolean>;
  @Output() clickImprimir: EventEmitter<boolean>;
  @Output() clickNuevo: EventEmitter<boolean>;
  @Output() anular: EventEmitter<boolean>;

  periodoStore$: Subscription;

  permisos: Permisos;

  isMobile: boolean = false;
  isMobileLandscape: boolean = false;
  isTablet: boolean = false;

  constructor(
    private _router: Router,
    private store: Store<PavsoState>,
    private _dialogService: DialogService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.clickMasOpciones = new EventEmitter<boolean>();
    this.clickReset = new EventEmitter<boolean>();
    this.clickFormato = new EventEmitter<boolean>();
    this.clickImprimir = new EventEmitter<boolean>();
    this.clickNuevo = new EventEmitter<boolean>();
    this.anular = new EventEmitter<boolean>();

    this.permisos = new Permisos();
    this.periodoStore$ = this.store.select('permissions').subscribe(state => {
      this.permisos.canCreate = false; //state.isCreate;
      this.permisos.canEdit = true; //state.isEdit;
      this.permisos.canDelete = state.isDelete;
      this.permisos.canCancel = true; // state.isCancel;
    })

  }
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

    this.breakpointObserver.observe([Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.isMobileLandscape = result.matches;
      });


  }

  volver(): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Desea volver al listado de registros?', '', '', '').subscribe(response => {

      if (response) {

        this._router.navigate([this.urlBack]);

      }
    })

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.periodoStore$])
  }

}
