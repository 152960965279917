import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { REPORTES_COBRANZAS_DINAMICOS } from "@data/json/reportes/cobranzas.reporte.json";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subject, Subscription } from "rxjs";
import { FiltroReporteCobranza } from "src/app/models/cobranzas";
import { OpcionFiltro } from "src/app/models/general/opciones-filtro";
import { CuentaCobrarService, GeneralService, VentasService } from "src/app/services";
import { CuentaCobrarReportService } from "src/app/services/reports/modules/cuenta-cobrar-report.service";

@Component({
  selector: 'dinamicos-cuentas-cobrar',
  templateUrl: './dinamicos-cuentas-cobrar.component.html',
  styleUrls: ['./dinamicos-cuentas-cobrar.component.scss']
})
export class DinamicosCuentasCobrar implements OnInit, OnDestroy {

  clientes: any[] = [];
  vendedores: any[] = [];
  tiposDocumento: any[] = [];

  reportes: any[] = REPORTES_COBRANZAS_DINAMICOS;
  reporteSeleccionado: any = ['COBRANZAS_ECC'];

  loading: boolean;
  loaderFields: boolean;

  columnas$: Subscription;
  dataForm$: Subscription;
  filtros$: Subscription;
  loading$: Subscription;
  periodo$: Subscription;

  columnas: any[] = [];
  opcionesFiltro: any[] = [];
  opcionFiltro: OpcionFiltro;

  task: any = {
    name: 'Campos',
    completed: false,
    color: 'primary',
    subtasks: []
  };

  allComplete: boolean = false;

  updateAllComplete(): void {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) return false;

    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean): void {
    this.allComplete = completed;

    if (this.task.subtasks == null) return;

    this.task.subtasks.forEach(t => t.completed = completed);
  }

  year: string;
  month: string;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  protected _onDestroy = new Subject<void>();

  filtro: FiltroReporteCobranza;

  range: FormGroup;

  constructor(
    private _ventasService: VentasService,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private _cuentaCobrarReporteService: CuentaCobrarReportService,
    private _cuentaCobrarService: CuentaCobrarService,
    private store: Store<PavsoState>
  ) {

    this.filtro = new FiltroReporteCobranza();
    this.opcionFiltro = new OpcionFiltro();

    const today = new Date();

    const month = today.getMonth();
    const year = today.getFullYear();

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, today.getDate()))
    });

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    });

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    });
  }

  loadData(): void {
    this.loaderFields = true;
    this.loading = true;
    this.columnas$ = this._generalService.listarColumnasInformeDinamico('16', '002').subscribe(
      columnas => {
        this.columnas = columnas;

        this.columnas.forEach(element => {
          element['name'] = element.TDR_DESTDR;
          element['completed'] = true;
        });

        this.task.subtasks = this.columnas;

        this.dataForm$ = this._cuentaCobrarService.obtenerDataFiltroCuentaCobrar().subscribe(
          dataForm => {
            console.log('dataForm', dataForm)
            this.clientes = dataForm.clientes;
            this.vendedores = dataForm.vendedores;
            this.tiposDocumento = dataForm.tiposDocumento;

            this.filtros$ = this._generalService.obtenerOpcionesFiltrosReporteModulo().subscribe(
              response => {
                console.log(response)

                this.opcionesFiltro = response;

                this.loaderFields = false;
                this.loading = false;
              },
              error => {
                this._snackBarService.showError(error.error.msg, 'Ok');
                this.loaderFields = false;
                this.loading = false;
              }
            )
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderFields = false;
            this.loading = false;
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderFields = false;
      }
    )
  }

  seleccionarReporte(event) {

    this.reporteSeleccionado[0] = event.id;

    switch (this.reporteSeleccionado[0]) {
      case 'value':

        break;

      default:
        break;
    }
  }

  verReporteDinamico(): void {

    this.loading = true;

    let headers = {
      codano: this.year,
      codmes: this.month,
    };

    let filtros = {};
    let otherData = {};

    this._cuentaCobrarReporteService.verReporteDinamico(this.reporteSeleccionado[0], headers, filtros, otherData)
    .then(_ => this.loading = false)
    .catch(_ => this.loading = false)
    .finally(() => this.loading = false)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.columnas$,
      this.dataForm$,
      this.filtros$,
      this.loading$,
      this.periodo$,
    ])
  }
}
