import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { AddDetailProductComponent } from '@shared/components/bottom_sheets/purchase_order/add-detail-product/add-detail-product.component';
import { PurchaseOrderProductEditComponent } from '@shared/components/dialogs/purchase-order-product-edit/purchase-order-product-edit.component';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import * as formatoPDFBuilder from '@utils/pdfmaker/builder/compras/operaciones/orden-compra.pdfmaker';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Subscription } from 'rxjs';
import { DetalleProd } from 'src/app/models';
import { DetalleOcd, EntregaOce, OrdenCompra } from 'src/app/models/compras/purchase_order';
import { AlmacenService, AuthenticationService, ComprasService, ConfigurationService, ContabilidadService, VentasService } from 'src/app/services';
import { OrdenCompraService } from 'src/app/services/api/compras/operaciones/orden-compra.service';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';
import { formatDateWithDash, formatDateWithDashLocal } from 'src/app/utils/formats/date.format';
import { validarPeriodo } from 'src/app/utils/validations/period';
import { OrdenCompraBottomSheet } from '../orden-compra-bottom-sheet/orden-compra-bottom-sheet.component';
import { ConfiguracionTecnica } from 'src/app/models/almacen/inventario/configuracion-tecnica';
import { KardexService } from 'src/app/services/api/almacen/operaciones/kardex.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ProductoMultipleDialog } from 'src/app/admin/apps/ventas/pages/operaciones/pedido';
import { numberWithCommas } from '@utils/formats/number.format';

@Component({
  selector: 'app-orden-compra-form',
  templateUrl: './orden-compra-form.component.html',
  styleUrls: ['./orden-compra-form.component.scss']
})
export class OrdenCompraFormComponent implements OnInit, OnDestroy {

  selected: any;

  selectRow(row: any) {
    this.selected = row;
  }

  selectedCuota: any;

  selectRowCuota(row: any) {
    this.selectedCuota = row;
  }

  selectedDetalle: any;

  selectRowDetalle(row: any) {
    this.selectedDetalle = row;
  }

  displayedColumns: string[] = ['item', 'codigo', 'descripcion', 'um'/*, 'part_number'*/, 'texto', 'sec', 'cantidad', 'pu_prov', 'por_prov', 'por_prov2', 'precio', 'valor_venta', 'mot_compra', 'not', 'fecreg', 'peso', 'precio_igv', 'imp_igv', 'imp_total', 'suspendida', 'atendida'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns1: string[] = ['item', 'descripcion'];
  dataSource1: MatTableDataSource<any>;

  @ViewChild("matPagDetalleProducto") paginator1: MatPaginator;
  @ViewChild(MatSort) sort1: MatSort;

  loader1: boolean = false;

  displayedColumnsCuota: string[] = ['item', 'descripcion', 'imp_cuota', 'fecha_pago'];
  dataSourceCuota: MatTableDataSource<any>;

  @ViewChild("matPagDetalleCuotas") paginatorCuota: MatPaginator;
  @ViewChild(MatSort) sortCuota: MatSort;

  loader2: boolean = false;

  displayedColumns3: string[] = ['acciones', 'requerimiento'];
  dataSource3: MatTableDataSource<any>;

  @ViewChild('requerimiento') paginator3: MatPaginator;
  @ViewChild(MatSort) sort3: MatSort;

  loader3: boolean = false;

  loaderReg: boolean = false;

  ordenCompra: OrdenCompra;


  clientes: any[] = [];
  proveedores: any[] = [];
  solicitantes: any[] = [];
  lugaresEntrega: any[] = [];
  aprobadores: any[] = [];
  condiciones: any[] = [];
  monedas: any[] = [];
  centros: any[] = [];
  formas: any[] = [];
  ordenesCompra: any[] = [];
  productos: any[] = [];
  almacenes: any[] = [];

  direccion: any = "";
  telefono: any = "";

  loaderData: boolean = false;
  loaderFormato: boolean = false;

  minDate: Date;
  maxDate: Date;

  year: string;
  month: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  period$: Subscription;
  loading$: Subscription;
  almacenes$: Subscription;
  config$: Subscription;
  tipoCambio$: Subscription;
  clientes$: Subscription;
  lugaresEntrega$: Subscription;
  solicitantes$: Subscription;
  centrosCosto$: Subscription;
  formaCalculoItem$: Subscription;
  condicionesPago$: Subscription;
  aprobadores$: Subscription;
  tiposOrdenCompra$: Subscription;
  productos$: Subscription;
  configuracion$: Subscription;

  uid: string;

  logo: string;

  configuracionTecnica: ConfiguracionTecnica;

  isTablet: boolean;
  isMobile: boolean;

  constructor(
    public dialog: MatDialog,
    private _dialogService: DialogService,
    private _snackBarService: SnackBarService,
    private _comprasService: ComprasService,
    private _clienteService: ClienteService,
    private _authService: AuthenticationService,
    private _configurationService: ConfigurationService,
    private _contabilidadService: ContabilidadService,
    private _ordenCompraService: OrdenCompraService,
    private readonly _kardexService: KardexService,
    private readonly _almacenService: AlmacenService,
    private _router: Router,
    private _bottomSheet: MatBottomSheet,
    private _ventasService: VentasService,
    private _productoService: ProductoService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
    private breakpointObserver: BreakpointObserver,
  ) {

    this.ordenCompra = new OrdenCompra();

    this.configuracionTecnica = new ConfiguracionTecnica();

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

  }

  initialize(): void {
    this.period$ = this.store.select('period').subscribe(state => {
      console.log('AAAAA')
      this.year = state.year;
      this.month = state.month;


      this.minDate = new Date(parseInt(this.year), parseInt(this.month) - 1, 1);
      this.maxDate = new Date(parseInt(this.year), parseInt(this.month), 0);

      console.log('mindate', this.minDate)
      console.log('maxdate', this.maxDate)
      const now = new Date();
      const mes = now.getMonth() + 1 > 9 ? now.getMonth() + 1 : `0${now.getMonth() + 1}`;
      const anio = now.getFullYear().toString();

      this.ordenCompra.ocm_fecocm = this.maxDate;

      console.log('ingreso a fecha =', mes == this.month && anio == this.year)
      if (mes == this.month && anio == this.year) {
        this.ordenCompra.ocm_fecocm = new Date();
        console.log('ingreso a fechassss', this.ordenCompra.ocm_fecocm)

        this.seleccionarFecha();
      }

      this.ordenCompra.ocm_fecocm = formatDateWithDashLocal(this.ordenCompra.ocm_fecocm);

      this.ordenCompra.ano_codano = state.year;
      this.ordenCompra.mes_codmes = state.month;

    })

    this.config$ = this.store.select('config').subscribe(state => {
      this.logo = state.logo;
    })

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource1 = fillTable([], this.paginator1, this.sort1);

    this.dataSourceCuota = fillTable([], this.paginatorCuota, this.sortCuota);

    this.dataSource3 = fillTable([], this.paginator3, this.sort3);

    this.ordenCompra.ocm_coduse = this._authService.getUsuarioSistema();
    this.ordenCompra.cia_codcia = this._configurationService.obtenerCompaniaCliente();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData();
    })

    this.initialize();
  }

  formatoMonto(valor: number): string {
    console.log('valor', valor)
    console.log('numberWithCommas(valor)', numberWithCommas(valor))
    return numberWithCommas(valor);
  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(
      ({ id }) => {

        this.loadMaestros();
        this.uid = id;
        console.log('uid', id)

        this.btnName = this.uid == '0' ? this.buttonsName.BTN_STORE : this.buttonsName.BTN_UPDATE;

      }
    )
  }

  obtenerOrdenDeCompra(): void {

    this._ordenCompraService.obtenerOrdenCompra(this.uid).subscribe(
      response => {
        this.ordenCompra = response;
        this.ordenCompra.detalle_ocd = this.ordenCompra.detalle_ocd ? this.ordenCompra.detalle_ocd : [];
        this.ordenCompra.entrega_oce = this.ordenCompra.entrega_oce ? this.ordenCompra.entrega_oce : [];

        this.dataSource = fillTable(this.ordenCompra.detalle_ocd, this.paginator, this.sort);
        this.dataSourceCuota = fillTable(this.ordenCompra.entrega_oce, this.paginator, this.sort);

        this.ordenCompra.entrega_oce = response.entrega_oce;
        this.seleccionarFecha();
        this.calcularTotales();

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderData = false;
      }
    )
  }



  loadMaestros(): void {
    this.loaderData = true;
    console.log('cargando maestros');

    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.ordenCompra.ocm_fecocm)).subscribe(
      tipoCambio => {
        this.ordenCompra.ocm_tipcam = tipoCambio[0].tipcam;

        this.clientes$ = this._clienteService.obtenerClientes().subscribe(
          clientes => {
            this.clientes = clientes;
            this.proveedores = clientes.filter(item => item.cli_indpro == 1);

            this.lugaresEntrega$ = this._comprasService.obtenerLugaresDeEntrega().subscribe(
              lugaresEntrega => {
                this.lugaresEntrega = lugaresEntrega;

                this.solicitantes$ = this._comprasService.obtenerSolicitantes().subscribe(
                  solicitantes => {
                    this.solicitantes = solicitantes;

                    this.centrosCosto$ = this._contabilidadService.listarCentroCostos().subscribe(
                      centrosCosto => {
                        this.centros = centrosCosto;

                        this.formaCalculoItem$ = this._ventasService.obtenerFormaCalculaItem().subscribe(
                          formasCalculaItem => {
                            this.formas = formasCalculaItem;
                            console.log('formas', this.formas)

                            this.condicionesPago$ = this._comprasService.obtenerCondicionesPago().subscribe(
                              condicionesPago => {
                                this.condiciones = condicionesPago;

                                this.aprobadores$ = this._comprasService.obtenerAprobadores().subscribe(
                                  aprobadores => {
                                    this.aprobadores = aprobadores;

                                    this.tiposOrdenCompra$ = this._comprasService.obtenerTipoOrdenCompra().subscribe(
                                      tiposOrdenCompra => {
                                        this.ordenesCompra = tiposOrdenCompra;

                                        this.productos$ = this._productoService.obtenerProductos().subscribe(
                                          productos => {
                                            this.productos = productos;

                                            this.configuracion$ = this._kardexService.obtenerConfiguracionTecnicaAlmacen().subscribe(
                                              configuracion => {
                                                this.configuracionTecnica = configuracion[0];

                                                this.almacenes$ = this._almacenService.obtenerAlamacenes().subscribe(
                                                  almacenes => {
                                                    this.almacenes = almacenes;
                                                    if (this.uid != '0') {
                                                      this.obtenerOrdenDeCompra();
                                                      return;
                                                    };

                                                    this.provieneDetalleDeSolicitud();
                                                    this.loaderData = false;

                                                  },
                                                  error => {

                                                    this._snackBarService.showError('Error al obtener configuración', 'Ok');
                                                    this.loaderData = false;

                                                  }
                                                )
                                              },
                                              error => {
                                                this._snackBarService.showError('Error al obtener configuración', 'Ok');
                                                this.loaderData = false;
                                              }
                                            )
                                          },
                                          error => {
                                            this._snackBarService.showError('Error al obtener productos', 'Ok');
                                            this.loaderData = false;
                                          }
                                        )
                                      },
                                      error => {
                                        this._snackBarService.showError('Error al obtener tipos de orden de compra', 'Ok');
                                        this.loaderData = false;
                                      }
                                    )
                                  },
                                  error => {
                                    this._snackBarService.showError('Error al obtener aprobadores', 'Ok');
                                    this.loaderData = false;
                                  }
                                )
                              },
                              error => {
                                this._snackBarService.showError('Error al obtener condiciones de pago', 'Ok');
                                this.loaderData = false;
                              }
                            )
                          },
                          error => {
                            this._snackBarService.showError('Error al obtener formas de calculo', 'Ok');
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError('Error al obtener centros de costo', 'Ok');
                        this.loaderData = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError('Error al obtener solicitantes', 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError('Error al obtener lugares de entrega', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener proveedores', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener tipo de cambio', 'Ok');
        this.loaderData = false;
      }
    )

  }

  provieneDetalleDeSolicitud(): void {
    if (localStorage.getItem('detalle_solicitud')) {
      this.ordenCompra.detalle_ocd = JSON.parse(localStorage.getItem('detalle_solicitud'));
      this.dataSource = fillTable(this.ordenCompra.detalle_ocd, this.paginator, this.sort);
      localStorage.removeItem('detalle_solicitud');
    }
  }

  calcularTotalesFila(row: DetalleOcd): void {
    // row.ocd_preuni = parseFloat((row.ocd_preprv - (row.ocd_desprv* row.ocd_preprv/ 100) - ).toFixed(2));
    // row.ocd_preprv
    // row.ocd_desprv
    // row.ocd_desprv2
    console.log('row.ocd_preuni', row.ocd_preuni)
    console.log('row.ocd_desprv', row.ocd_desprv)
    row.ocd_preuni = parseFloat(((row.ocd_preprv - row.ocd_preprv * row.ocd_desprv / 100) - (row.ocd_preprv - (row.ocd_preprv * row.ocd_desprv / 100)) * row.ocd_desprv2 / 100).toFixed(2));
    console.log('row.ocd_preuni', row.ocd_preuni)
    // if (this.ordenCompra.ocm_indcal == '1') {
    // }
    row.ocd_pruigv = parseFloat((row.ocd_preuni * (this.ordenCompra.ocm_tasigv / 100)).toFixed(2));
    row.ocd_impigv = parseFloat((row.ocd_pruigv * row.ocd_canocd).toFixed(2));
    row.ocd_valvta = parseFloat((row.ocd_preuni * row.ocd_canocd).toFixed(2));
    row.ocd_imptot = parseFloat((row.ocd_valvta + row.ocd_impigv).toFixed(2));

    this.calcularTotales();
  }

  esValidoFormulario(): boolean {

    if (!this.ordenCompra.cli_codcli) {
      this._snackBarService.showError('Debe seleccionar un proveedor', 'OK');
      return false;
    }

    if (!this.ordenCompra.toc_codtoc) {
      this._snackBarService.showError('Debe seleccionar un tipo O/C', 'OK');
      return false;
    }

    if (!this.ordenCompra.cpa_codcpa) {
      this._snackBarService.showError('Debe seleccionar un condicion de pago', 'OK');
      return false;
    }

    if (!this.ordenCompra.tmo_codtmo) {
      this._snackBarService.showError('Debe seleccionar una moneda', 'OK');
      return false;
    }

    if (!this.ordenCompra.ldc_codldc) {
      this._snackBarService.showError('Debe seleccionar un lugar de entrega', 'OK');
      return false;
    }

    if (!this.ordenCompra.ocm_tipcam) {
      this._snackBarService.showError('Debe seleccionar un tipo de cambio', 'OK');
      return false;
    }

    if (this.ordenCompra.detalle_ocd.length == 0) {
      this._snackBarService.showError('Debe agregar al menos un producto', 'OK');
      return false;
    }

    return true;

  }


  enviarFormulario(f: NgForm): void {

    this.loaderReg = true;

    if (!this.esValidoFormulario()) {
      this.loaderReg = false;
      return;
    }


    this.uid == '0' ? this.registrarOrdenDeCompra(f) : this.editarOrdenDeCompra(f);
  }

  editarOrdenDeCompra(f: NgForm): void {

    if (this.ordenCompra.detalle_ocd.length > 0) {
      if (this.validarPrecios(this.ordenCompra.detalle_ocd)) {
        if (validarPeriodo(this.ordenCompra.ocm_fecocm, this.year, this.month)) {

          this.ordenCompra.ocm_indtex = (this.ordenCompra.ocm_indtex == true) ? 1 : 0;
          this.ordenCompra.ocm_indnot = (this.ordenCompra.ocm_indnot == true) ? 1 : 0;

          let cantidadTotal = 0;
          this.ordenCompra.detalle_ocd.forEach(element => {
            cantidadTotal = cantidadTotal + element.ocd_canocd
          })

          this.ordenCompra.ocm_cantot = cantidadTotal;
          this.ordenCompra.entrega_oce.forEach((element, index) => {
            if (index < 11) {
              element.OCE_COROCE = `00${index + 1}`;
            } else if (index > 10 && index < 101) {
              element.OCE_COROCE = `0${index + 1}`;
            } else {
              element.OCE_COROCE = `${index + 1}`;
            }
          })
          this.ordenCompra.detalle_ocd.forEach((producto, index) => {

            if (index < 11) {
              producto.ocd_corocd = `00${index + 1}`;
            } else if (index > 10 && index < 101) {
              producto.ocd_corocd = `0${index + 1}`;
            } else {
              producto.ocd_corocd = `${index + 1}`;
            }

          })

          this._ordenCompraService.editarOrdenCompra(this.ordenCompra).subscribe(
            response => {
              this.loaderReg = false;
              this._dialogService.openDialog(SuccessComponent, 'Orden de Compra actualizada', '300px', '', '');
            },
            error => {
              this.loaderReg = false;
              this._snackBarService.showError('Error al Editar Orden de Compra', 'OK');
            }
          )
        } else {
          this.loaderReg = false;
          this._snackBarService.showError('La Fecha de Registro Debe de Coincidir con la Fecha de Actividad', 'OK');
        }
      } else {
        this.loaderReg = false;
        this._snackBarService.showError('Verifique que Todos los Productos tengan Precio', 'OK');
      }
    } else {
      this.loaderReg = false;
      this._snackBarService.showError('Ingresar al menos un producto', 'OK');
    }

  }

  registrarOrdenDeCompra(f: NgForm): void {

    if (validarPeriodo(this.ordenCompra.ocm_fecocm, this.year, this.month)) {

      this.ordenCompra.ocm_indtex = (this.ordenCompra.ocm_indtex == true) ? 1 : 0;
      this.ordenCompra.ocm_indnot = (this.ordenCompra.ocm_indnot == true) ? 1 : 0;

      let cantidadTotal = 0;
      this.ordenCompra.detalle_ocd.forEach(element => {
        cantidadTotal = cantidadTotal + element.ocd_canocd
      })

      this.ordenCompra.ocm_cantot = cantidadTotal;
      this.ordenCompra.entrega_oce.forEach((element, index) => {
        if (index < 11) {
          element.OCE_COROCE = `00${index + 1}`;
        } else if (index > 10 && index < 101) {
          element.OCE_COROCE = `0${index + 1}`;
        } else {
          element.OCE_COROCE = `${index + 1}`;
        }

      })

      this.ordenCompra.detalle_ocd.forEach((producto, index) => {

        if (index < 11) {
          producto.ocd_corocd = `00${index + 1}`;
        } else if (index > 10 && index < 101) {
          producto.ocd_corocd = `0${index + 1}`;
        } else {
          producto.ocd_corocd = `${index + 1}`;
        }

      })

      this._ordenCompraService.registrarOrdenCompra(this.ordenCompra).subscribe(
        response => {
          this.loaderReg = false;
          this.ordenCompra.ocm_numocm = response.ocm_numocm;
          this.uid = response.ocm_numocm;
          console.log('orden registrada', response)
          this._dialogService.openDialog(SuccessComponent, 'Orden de compra registrado', '300px', '', '');
        },
        error => {
          this.loaderReg = false;
          this._snackBarService.showError('Error al registrar orden de compra', 'OK');
        }
      )
    } else {
      this.loaderReg = false;
      this._snackBarService.showError('La Fecha de Registro Debe de Coincidir con la Fecha de Actividad', 'OK');
    }

  }

  nuevoRegistro(): void {
    this.ordenCompra = new OrdenCompra();
    this.uid = '0';
    this.initialize();

    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.ordenCompra.ocm_fecocm)).subscribe(
      tipoCambio => {
        this.ordenCompra.ocm_tipcam = tipoCambio[0].tipcam;
      },
      error => {
        this._snackBarService.showError('Error al obtener tipo de cambio', 'OK');
      }
    )
  }

  agregarProductoMultiple(): void {
    const dialogRef = this.dialog.open(ProductoMultipleDialog, {
      width: 'auto',
      data: {
        products: this.productos,
        almacen: this.ordenCompra.alm_codalm,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('result', result)
      result.forEach(item => {
        const producto = new DetalleOcd();
        producto.prd_codprd = item.prd_codprd;
        producto.eum_codume = item.UNIDAD;
        // producto.ocd_canate = 0;
        // producto.PCD_PREUNI = 0;

        this.ordenCompra.detalle_ocd.push(producto);

      })

      this.ordenCompra.detalle_ocd.forEach((element, key) => {
        element.ocd_corocd = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
      })

      this.calcularTotales();

      this.dataSource = fillTable(this.ordenCompra.detalle_ocd, this.paginator, this.sort);

    })
  }

  insertarProducto(): void {

    const registro = new DetalleOcd();

    registro.cia_codcia = this._configurationService.obtenerCompaniaCliente();

    let i = 0;

    if (this.selected) {
      this.ordenCompra.detalle_ocd.forEach((item, index) => {
        if (item.ocd_corocd == this.selected.ocd_corocd) i = index;
      })

      this.ordenCompra.detalle_ocd.splice(i, 0, registro);
    } else {
      this.ordenCompra.detalle_ocd.push(registro);
      this.ordenCompra.detalle_ocd = this.ordenCompra.detalle_ocd.reverse();
    }

    this.ordenCompra.detalle_ocd.forEach((element, key) => {
      element.ocd_corocd = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    console.log('detalle', this.ordenCompra.detalle_ocd)

    this.dataSource = fillTable(this.ordenCompra.detalle_ocd, this.paginator, this.sort);
  }

  agregarProducto(): void {

    const producto = new DetalleOcd();

    this.ordenCompra.detalle_ocd.push(producto);

    this.ordenCompra.detalle_ocd.forEach((element, key) => {
      element.ocd_corocd = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.calcularTotales();

    this.dataSource = fillTable(this.ordenCompra.detalle_ocd, this.paginator, this.sort);

  }

  agregarDetalleProducto(): void { }

  seleccionarProducto(value, row): void {

    const producto = this.productos.find(item => item.prd_codprd == value);

    if (producto) {
      row.eum_codume = producto.UNIDAD
    }

  }

  agregarDetalle(): void {
    if (false) {

      this._snackBarService.showError('Seleccionar una fila de producto', 'OK');
      return;

    }

    let producto: DetalleOcd = null;

    this.ordenCompra.detalle_ocd.forEach(element => {
      if (element.prd_codprd == 'a') {
        producto = element;
      }
    })

    const bottomSheet = this._bottomSheet.open(AddDetailProductComponent, {
      data: producto,
      disableClose: true
    });

    bottomSheet.afterDismissed().subscribe(result => {

      this.dataSource1 = fillTable(result.detalle_item, this.paginator1, this.sort1);

    })

  }

  agregarCuota(): void {
    if (this.ordenCompra.detalle_ocd.length == 0) {

      this._snackBarService.showError('Agregar productos', 'OK');
      return;

    }

    const cuota = new EntregaOce();
    cuota.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();

    this.ordenCompra.entrega_oce.push(cuota);

    this.dataSourceCuota = fillTable(this.ordenCompra.entrega_oce, this.paginatorCuota, this.sortCuota);
  }

  agregarRequerimiento(): void { }

  obtenerTipoDeCambio(): void {

    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.ordenCompra.ocm_fecocm)).subscribe(
      response => {
        this.ordenCompra.ocm_tipcam = response[0].tipcam;
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  obtenerProveedores(): void {
    this._comprasService.obtenerProveedores().subscribe(
      response => {
        this.proveedores = response;
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  seleccionarProveedor(id): void {
    const proveedor = this.proveedores.find(item => item.cli_codcli == id)
    console.log('proveedor seleccionado', proveedor)

    if (proveedor) {

      this.direccion = proveedor.cli_dircli;
      this.telefono = proveedor.cli_numtlf;
      this.ordenCompra.ocm_nomcon = proveedor.cli_nomcon;
    }

  }

  verMasOpciones(): void {
    this._bottomSheet.open(OrdenCompraBottomSheet, {}).afterDismissed().subscribe(() => { })
  }

  obtenerSolicitantes(): void {
    this._comprasService.obtenerSolicitantes().subscribe(
      response => {
        this.solicitantes = response;
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  obtenerAprobadores(): void {
    this._comprasService.obtenerAprobadores().subscribe(
      response => this.aprobadores = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  obtenerCalculaItem(): void {
    this._ventasService.obtenerFormaCalculaItem().subscribe(
      response => this.formas = response,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  seleccionarFecha(): void {
    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.ordenCompra.ocm_fecocm)).subscribe(
      response => this.ordenCompra.ocm_tipcam = response[0].tipcam,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  editarProducto(row: DetalleProd): void {
    const dialogRef = this.dialog.open(PurchaseOrderProductEditComponent, {
      width: '800px',
      data: row,
      disableClose: true
    })

    dialogRef.afterClosed().subscribe(result => this.calcularTotales());
  }

  eliminarProducto(): void {

    let row = this.ordenCompra.detalle_ocd.find(item => item == this.selected);

    if (!row && this.ordenCompra.detalle_ocd.length > 0) row = this.ordenCompra.detalle_ocd[0];

    let details = [];
    this.ordenCompra.detalle_ocd.forEach(element => {
      if (element.ocd_corocd != row.ocd_corocd) {
        details.push(element)
      }
    });

    this.ordenCompra.detalle_ocd = details;

    this.ordenCompra.detalle_ocd.forEach((element, key) => {
      element.ocd_corocd = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.dataSource = fillTable(this.ordenCompra.detalle_ocd, this.paginator, this.sort);

    this.calcularTotales();

  }

  eliminarCuota(): void {

    let row = this.ordenCompra.entrega_oce.find(item => item == this.selectedCuota);

    if (!row && this.ordenCompra.entrega_oce.length > 0) row = this.ordenCompra.entrega_oce[0];

    let details = [];
    this.ordenCompra.entrega_oce.forEach(element => {
      if (element.OCE_COROCE != row.OCE_COROCE) {
        details.push(element)
      }
    });

    this.ordenCompra.entrega_oce = details;

    this.ordenCompra.entrega_oce.forEach((element, key) => {
      element.OCE_COROCE = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.dataSourceCuota = fillTable(this.ordenCompra.entrega_oce, this.paginatorCuota, this.sortCuota);

    this.calcularTotales();

  }

  eliminarDetalle(): void { }

  selectContent(event: FocusEvent) {
    // Selecciona el contenido del input al hacer focus
    if (event.target instanceof HTMLInputElement) {
      event.target.select();
    }
  }

  generarFormatoOrdenCompra(): void {

    if (this.ordenCompra.ocm_numocm == '0' || !this.ordenCompra.ocm_numocm) return;


    this.loaderFormato = true;

    this._comprasService.obtenerPdfOrdenCompra(this.ordenCompra.ocm_numocm).subscribe(
      response => {
        console.log('response', response)

        const headers = {
          username: this._configurationService.obtenerIdUsuario(),
          logo: this.logo,
          moneda: response[0].tmo_codtmo
        }

        if (response.length > 0 && response != null) {
          // verPdfReport(headers, response[0]);
          response[0].detalle_produc = response[0].detalle_produc ? response[0].detalle_produc : []
          formatoPDFBuilder.generarFormatoOrdenCompra(headers, response[0]);
        }

        this.loaderFormato = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderFormato = false;
      }
    )
  }

  calcularTotales(): void {

    let totalCantidad = 0;
    let totalVta = 0;
    let totalIgv = 0;
    let total = 0;
    // let totalCompra = 0;
    this.ordenCompra.detalle_ocd.forEach(element => {
      totalCantidad = totalCantidad + element.ocd_canocd;
      totalVta = totalVta + element.ocd_valvta;
      totalIgv = totalIgv + element.ocd_impigv;
      total = total + element.ocd_imptot;
    })

    this.ordenCompra.ocm_cantot = totalCantidad;
    this.ordenCompra.ocm_valvta = totalVta;
    this.ordenCompra.ocm_impigv = totalIgv;
    this.ordenCompra.ocm_imptot = total;

  }

  validarPrecios(productos: Array<DetalleOcd>): boolean {
    let validator = true;
    this.ordenCompra.detalle_ocd.forEach(element => {
      if (element.ocd_preuni == 0) {
        validator = false;
      }
    })

    return validator;
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  volver(): void {
    this._router.navigate(['/modulo-compras/ordenes-de-compra']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$,
      this.almacenes$,
      this.config$,
      this.tipoCambio$,
      this.clientes$,
      this.lugaresEntrega$,
      this.solicitantes$,
      this.centrosCosto$,
      this.formaCalculoItem$,
      this.condicionesPago$,
      this.aprobadores$,
      this.tiposOrdenCompra$,
      this.productos$,
      this.configuracion$,
    ]);

  }
}
