import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { CustomerComercial, Proveedor } from 'src/app/models/compras/provider';
import { SnackBarService } from '@shared/services/snackbar.service';
import { AuthenticationService, ConfigurationService, VentasService, GeneralService, ComprasService } from 'src/app/services';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '../success/success.component';
import { fillTable } from '@utils/tables/table';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-add-provider',
  templateUrl: './add-provider.component.html',
  styleUrls: ['./add-provider.component.css']
})
export class AddProviderComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  tiposPersona: any[] = [];
  tiposDocumento: any[] = [];
  paises: any[] = [];
  ubicaciones: any[] = [];
  condiciones: any[] = [];
  ubigeos: any[] = [];

  fecha: Date = new Date();
  usuario: any;

  displayedColumns: string[] = ['acciones', 'codigo', 'descripcion'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsContacto: string[] = ['acciones', 'codigo', 'trabajador', 'dni', 'cargo', 'correo', 'telefono', 'celular', 'web', 'referencia', 'estado'];
  dataSourceContacto: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorContacto: MatPaginator;
  @ViewChild(MatSort) sortContacto: MatSort;

  displayedColumnsEstablecimiento: string[] = ['acciones', 'codigo', 'establecimiento', 'direccion', 'pais', 'ubicacion'];
  dataSourceEstablecimiento: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorEstablecimiento: MatPaginator;
  @ViewChild(MatSort) sortEstablecimiento: MatSort;

  displayedColumnsCuenta: string[] = ['acciones', 'codigo', 'banco', 'moneda', 'nro_cuenta', 'cuenta_interbancaria', 'glosa'];
  dataSourceCuenta: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorCuenta: MatPaginator;
  @ViewChild(MatSort) sortCuenta: MatSort;

  tipoPersona$: Subscription;
  tipoDocumentoIdentidad$: Subscription;
  pais$: Subscription;
  ubicacion$: Subscription;
  condicionPago$: Subscription;
  ubigeo$: Subscription;
  clienteComercial$: Subscription;

  condicionPago: any
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  estado: boolean;

  proveedor: Proveedor;

  esJuridico: boolean;

  constructor(
    private _dialogService: DialogService,
    private _router: Router,
    public dialogRef: MatDialogRef<AddProviderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _comprasService: ComprasService,
    private _ventasService: VentasService,
    private _generalService: GeneralService,
    private _configurationService: ConfigurationService,
    public dialog: MatDialog
  ) {

    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSourceContacto = fillTable([], this.paginatorContacto, this.sortContacto);

    this.dataSourceCuenta = fillTable([], this.paginatorCuenta, this.sortCuenta);

    this.dataSourceEstablecimiento = fillTable([], this.paginatorEstablecimiento, this.sortEstablecimiento);

    this.esJuridico = true;
    this.condicionPago = "";
    this.proveedor = new Proveedor();

    this.proveedor.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.proveedor.cli_indcli = 0;
    this.proveedor.cli_indpro = 1;
    this.proveedor.cli_indtra = 0;
    this.proveedor.cli_indgas = 0;
    this.proveedor.cli_tipper = "J";
    this.proveedor.did_coddid = "001";
    this.proveedor.cli_coduse = this._authService.getUsuarioSistema();
    this.proveedor.cli_fecupd = new Date();
    this.proveedor.cli_indpnj = 0;
    this.proveedor.cli_inddom = 1;
    this.proveedor.cli_indvin = 0;
    this.proveedor.cli_idecli = 22351;
    this.proveedor.cli_indigv = 1;
    this.proveedor.cli_inddis = 0;
    this.proveedor.cli_indind = 0;
    this.proveedor.cli_indsta = "1";
    this.proveedor.dato_clc = new CustomerComercial();
    this.proveedor.detalle_lugar_despacho = [];
    this.proveedor.detalle_contacto = [];
    this.proveedor.detalle_tipser_prov = [];

    // campos auxiliares
    this.proveedor.cli_refcli = "";
    this.proveedor.cli_indind = 1;
    this.proveedor.cli_inddis = 1;
  }

  establecerValores(): void {
    this.proveedor.cli_codcli = this.data.numeroDocumento;
    this.proveedor.cli_nomcli = this.data.nombre;
    this.proveedor.cli_dircli = `${this.data.distrito} - ${this.data.direccion}`;
    this.proveedor.ubs_codubs = this.data.ubigeo;
    const ubigeo = this.ubigeos.find(item => item.ubs_codubs == this.data.ubigeo);
    this.proveedor.ubi_codubi = ubigeo.ubi_codubi;

    this.proveedor.pai_codpai = '01';

    if (this.data.tipoDocumento == "6"){
      this.esJuridico = true;
      this.proveedor.cli_tipper = "J";
      this.proveedor.cli_numruc = this.proveedor.cli_codcli;
      return;
    }

    this.esJuridico = false;
    this.proveedor.cli_tipper = "N";
    this.proveedor.cli_numdni = this.proveedor.cli_codcli;

  }

  ngOnInit(): void {
    this.loadData()
  }

  onNoClick(): void {

    this.dialogRef.close();

  }

  loadData(): void {
    this.loaderData = true;

    forkObs(
      this._generalService.listarTipoDePersona(),
      this._generalService.listarTipoDocumentoIdentidad(),
      this._generalService.listarPaises(),
      this._generalService.listarUbicacion(null),
      this._comprasService.obtenerCondicionesPago(),
      this._generalService.listarUbigeos()
    ).then(data => {

      this.tiposPersona = data[0];

      this.tiposDocumento = data[1];
      const cod_ofi = (this.data.tipoDocumento.length == 1)? `0${this.data.tipoDocumento}`: this.data.tipoDocumento;
      const tipo = this.tiposDocumento.find(item => item.did_codofi == cod_ofi);
      this.proveedor.did_coddid = tipo.DID_CODDID;

      this.paises = data[2];

      this.ubicaciones = data[3];

      this.condiciones = data[4];

      this.ubigeos = data[5];
      this.loaderData = false;
      this.establecerValores();

    }).catch(err => {
      this._snackBarService.showError('Error al obtener ubigeos', 'OK');
      this.loaderData = false;
    })

  }

  registrarProveedor(f: NgForm): void {

    this.clienteComercial$ = this._ventasService.agregarClienteComercial(this.proveedor).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Nuevo Proveedor Registrado', '300px', '', '');

        this.dialogRef.close();
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )
  }

  volver(): void {
    this._router.navigate(['/proveedores']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoPersona$,
      this.tipoDocumentoIdentidad$,
      this.pais$,
      this.ubicacion$,
      this.condicionPago$,
      this.ubigeo$,
      this.clienteComercial$,
    ])
  }

}
