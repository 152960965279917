<app-pavso-title-section [title]="'Factura'" [module]="'Ventas'" [usuario]="factura.CCO_CODUSE"
  [fecha]="factura.CCO_FECUPD" [ngClass]="'pav-form'" [estado]="factura.CCO_INDSTA"></app-pavso-title-section>

<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <mat-tab-group [selectedIndex]="tabSeleccionado">
      <mat-tab label="Datos Generales" labelClass="mat-tab-label-0-0">
        <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m6 l1">
              <select-search [disabled]="loaderData" [label]="'Tipo Doc.'" [placeholder]="'Ej. FAC'"
                [name]="'TDO_CODTDO'" [value]="'TDO_CODTDO'" [description]="'description'" [data]="tiposDocumento"
                (cambioSelect)="factura.TDO_CODTDO = $event; seleccionarTipoDoc($event)" [model]="factura.TDO_CODTDO">
              </select-search>

            </div>

            <div class="col s12 m12 l3 xl1">
              <select-search [disabled]="loaderData" [label]="'Serie'" [placeholder]="'Ej. F001'" [name]="'sdo_codsdo'"
                [value]="'sdo_codsdo'" [description]="'sdo_codsdo'" [data]="series"
                (cambioSelect)="factura.serie = $event; generarNumeroDocumento();" [model]="factura.serie">
              </select-search>

              <!-- <mat-form-field>
                <mat-label>Serie</mat-label>
                <input required #iSerie type="text" maxlength="4" matInput placeholder="Serie" [disabled]="loaderData"
                  name="sad_codsad" [(ngModel)]="factura.serie" (keyup.enter)="establecerSerie()">
              </mat-form-field> -->
            </div>

            <div class="col s12 m12 l4 xl2">
              <mat-form-field>
                <mat-label>Nro. Doc.</mat-label>
                <input #iNroDoc type="text" maxlength="12" matInput placeholder="Nro. Doc." [required]="uid!='0'"
                  [(ngModel)]="factura.CCO_NUMDOC" name="cco_numdoc" [disabled]="loaderData"
                  (keyup)="autocompletarNroDoc()" (keyup.enter)="establecerNroDoc($event)">
              </mat-form-field>
            </div>

            <div class="col s12 m6 l1">
              <mat-form-field>
                <mat-label>Fecha</mat-label>
                <input [min]="minDate" [max]="maxDate" [disabled]="loaderData" [(ngModel)]="factura.CCO_FECEMI"
                  name="CCO_FECEMI" matInput [matDatepicker]="picker" placeholder="Fecha"
                  (dateChange)="seleccionarFecha()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col s12 m6 l1">
              <mat-form-field class="example-full-width">
                <mat-label>T.C.</mat-label>
                <input [ngStyle]="{textAlign: 'right'}" [(ngModel)]="factura.CCO_TIPCAM" name="CCO_TIPCAM" type="text"
                  matInput placeholder="T.C." step="any">
              </mat-form-field>
            </div>

            <div class="col s12 m6 l2">

              <select-search [disabled]="loaderData" [label]="'Motivo'" [placeholder]="'Ej. COMPRAS LOCALES'"
                [name]="'mmo_codmmo'" [value]="'mmo_codmmo'" [description]="'mmo_descri'" [data]="motivos"
                (cambioSelect)="factura.MMO_CODMMO = $event;" [model]="factura.MMO_CODMMO">
              </select-search>

            </div>

            <div class="col s12 m6 l1">
              <mat-form-field class="example-full-width">
                <mat-label>IGV (%)</mat-label>
                <input [disabled]="loaderData" [ngStyle]="{textAlign: 'right'}" [(ngModel)]="factura.CCO_TASIGV"
                  name="PCC_TASIGV" type="number" matInput placeholder="Tas. IGV" step="any">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l2">
              <select-search [disabled]="loaderData" [label]="'Vendedor'" [placeholder]="'Ej. GERENCIA'"
                [name]="'VDE_CODVDE'" [value]="'VDE_CODVDE'" [description]="'VDE_NOMVDE'" [data]="vendedores"
                (cambioSelect)="factura.docu_dcc.VDE_CODVDE = $event"
                [model]="factura.docu_dcc.VDE_CODVDE"></select-search>

            </div>

            <div class="col s12 m6 l1">
              <app-currency-select [disable]="loaderData" [moneda]="factura.TMO_CODTMO"
                (cambioMoneda)="factura.TMO_CODTMO = $event"></app-currency-select>
            </div>

            <div class="col s12 m12 l4">
              <cliente-select [canAdd]="true" [canEdit]="true" [disabled]="loaderData" [label]="'Cliente'"
                [placeholder]="'Ej. GERENCIA'" [name]="'cli_codcli'" [value]="'cli_codcli'"
                [description]="'description'" [data]="clientes" (cambioSelect)="factura.CLI_CODCLI = $event;
                        seleccionarCliente()" [model]="factura.CLI_CODCLI">
              </cliente-select>

            </div>

            <div class="col s12 m12 l1">

              <mat-form-field class="example-full-width">
                <mat-label>Doc. Identidad</mat-label>
                <input [disabled]="loaderData" type="text" matInput [(ngModel)]="factura.TDO_CODTDO" name="TDO_CODTDO"
                  placeholder="Ej. 70389596">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l1">
              <mat-form-field class="example-full-width">
                <mat-label>Cta. Cte.</mat-label>
                <input [disabled]="loaderData" name="CCO_CODCCO" [(ngModel)]="factura.CCO_CODCCO" type="text" matInput
                  placeholder="Ej. 7038959623223">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l2">
              <select-search [disabled]="loaderData" [label]="'Condición de pago'" [placeholder]="'Ej. CONTACTO'"
                [name]="'CPA_CODCPA'" [value]="'CPA_CODCPA'" [description]="'description'" [data]="condiciones"
                (cambioSelect)="factura.CPA_CODCPA = $event; seleccionarCondicionPago()" [model]="factura.CPA_CODCPA"></select-search>

            </div>
            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Vencimiento</mat-label>
                <input [min]="factura.CCO_FECREG" [disabled]="loaderData" [(ngModel)]="factura.CCO_FECVEN"
                  name="CCO_FECVEN" matInput [matDatepicker]="picker1" placeholder="Entregar">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">

              <select-search [disabled]="loaderData" [label]="'Almacén'" [placeholder]="'Ej. ALMACÉN P.T.'"
                [name]="'ALM_CODALM'" [model]="factura.docu_dcc.ALM_CODALM" (cambioSelect)="factura.docu_dcc.ALM_CODALM"
                [setFocus]="factura.generaParteAlmacen" [value]="'ALM_CODALM'" [description]="'description'"
                [data]="almacenes">
              </select-search>
            </div>

            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>Direc. Fiscal</mat-label>
                <input [disabled]="loaderData" type="text" matInput placeholder="Direc. Fiscal" [(ngModel)]="direccion"
                  name="direccion">
              </mat-form-field>

            </div>

            <div class="col s12 m12 l2">

              <mat-form-field>
                <mat-label>Lugar de despacho</mat-label>
                <mat-select [disabled]="loaderData" name="LDE_CODFAC">
                  <mat-option *ngFor="let lugar of lugaresDespacho" [value]="lugar.LDE_CODLDE">
                    {{lugar.LDE_NOMLDE}} - {{lugar.LDE_DIRLDE}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>

            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Orden de Compra</mat-label>
                <input [disabled]="loaderData" name="DCC_ORDCOM" [(ngModel)]="this.factura.docu_dcc.DCC_ORDCOM"
                  type="number" matInput placeholder="O.Compra">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>Observación</mat-label>
                <input [disabled]="loaderData" matInput placeholder="Observación" [(ngModel)]="factura.CCO_GLOCCO"
                  name="PCC_OBSPCC" />
              </mat-form-field>

            </div>

          </div>
          <div class="row">
            <div class="col s12 m6 l3">
              <select-search [disabled]="this.uid != '0' && !this.uid.includes('pedido')" [label]="'Copiar de Pedido'"
                [placeholder]="'Ej. 2323323'" [name]="'PCC_NUMPCC'" [value]="'PCC_NUMPCC'" [description]="'description'"
                [data]="pedidos" [model]="factura.docu_dcc.PCC_NUMPCC" [disabled]="loaderData"
                (cambioSelect)="factura.docu_dcc.PCC_NUMPCC = $event" iconAction="get_app"
                tooltipAction="Obtener datos desde pedido" (clickAction)="obtenerDatosPedido()">
              </select-search>
            </div>

            <div class="col s12 m12 l7">

              &nbsp;
              <mat-checkbox [(ngModel)]="factura.generaParteAlmacen" (change)="seleccionarCBParteAlmacen()"
                class="example-margin" name="generaParteAlmacen" [ngStyle]="{marginTop: '10px'}">Genera
                parte de almacén</mat-checkbox>
            </div>
          </div>

        </mat-card>

        <br>
        <mat-card class="mat-elevation-z0">
          <div class="row">

            <div class="col s12 m12 l2">
              <mat-form-field class="pav-total-field">
                <mat-label>Imp. Bruto</mat-label>
                <input type="number" matInput placeholder="Imp. Bruto" [ngStyle]="{textAlign: 'right'}"
                  name="PCC_IMPBRU" [value]="factura.docu_dcc.DCC_IMPBRU" step="any" readonly>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field class="pav-total-field">
                <mat-label>Descuento</mat-label>
                <input type="number" matInput placeholder="Desc. Detalle" [ngStyle]="{textAlign: 'right'}"
                  name="DCC_IMPDES" [value]="factura.docu_dcc.DCC_IMPDES" step="any" readonly>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field class="pav-total-field">
                <mat-label>Valor venta</mat-label>
                <input type="number" matInput placeholder="Valor venta" [ngStyle]="{textAlign: 'right'}"
                  name="PCC_VALVTA" [value]="factura.CCO_VALVTA" step="any" readonly>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field class="pav-total-field">
                <mat-label>Imp. IGV</mat-label>
                <input type="number" matInput [value]="factura.CCO_IMPIGV" placeholder="Imp. IGV"
                  [ngStyle]="{textAlign: 'right'}" name="PCC_IMPIGV" step="any" readonly>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field class="pav-total-field">
                <mat-label>Imp. Total ({{factura.TMO_CODTMO == 'DO' ? 'US$': 'S/.'}})</mat-label>
                <input type="number" matInput [value]="factura.CCO_IMPDOC" placeholder="Imp. Total"
                  [ngStyle]="{textAlign: 'right'}" name="CCO_IMPDOC" step="any" readonly>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field class="pav-total-field">
                <mat-label>({{factura.TMO_CODTMO == 'SO' ? 'US$': 'S/.'}})</mat-label>
                <input type="number" matInput [value]="factura.TMO_CODTMO ? factura.CCO_IMPDOLAR : factura.CCO_IMPSOLES"
                  placeholder="Ej. 100.00" [ngStyle]="{textAlign: 'right'}" name="CCO_IMPDOLAR" step="any" readonly>
              </mat-form-field>
            </div>

            <!-- <div class="col s12 m12 l1">
              <mat-form-field class="pav-total-field">
                <mat-label>US$</mat-label>
                <input type="number" matInput [value]="factura.CCO_IMPDOLAR" placeholder="Ej. 100.00"
                  [ngStyle]="{textAlign: 'right'}" name="CCO_IMPDOLAR" step="any" readonly>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l1">
              <mat-form-field class="pav-total-field">
                <mat-label>S/.</mat-label>
                <input type="number" matInput [value]="factura.CCO_IMPSOLES" placeholder="Ej. 100.00"
                  [ngStyle]="{textAlign: 'right'}" name="CCO_IMPSOLES" step="any" readonly>
              </mat-form-field>
            </div> -->
          </div>

        </mat-card>

        <br>

        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">
          </div>
          <div class="pav-table-header-title">
            Detalle
          </div>
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          <div class="pav-button-icon-add" matTooltip="Agregar ítem" (click)="agregarProducto()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="add-circle"></mat-icon>
          </div>
          &nbsp;
          <div class="pav-button-icon-delete" matTooltip="Eliminar ítem" (click)="eliminarProducto()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="remove"></mat-icon>
          </div>
          &nbsp;
          <div class="pav-button-icon-insert" matTooltip="Insertar ítem" (click)="insertarProducto()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="add-outline"></mat-icon>
          </div>
          &nbsp;
          <div class="pav-button-icon-insert" matTooltip="Consultar stock" (click)="consultarStock()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="stock"></mat-icon>
          </div>
        </div>

        <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
                <div class="pav-btns-container">

                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCD_CORPCD">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> item </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="item">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '60px'}">
                  <mat-label></mat-label>
                  <input readonly [value]="row.DCD_CORDCD" [name]="'DCD_CORDCD' + i" matInput placeholder="Ej. 001">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="PRD_CODPRD">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
                <select-search [label]="''"
                  [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
                  [placeholder]="'Ej. CANASTA'" [name]="'prd_codprd'" [value]="'prd_codprd'"
                  [description]="'description'" [data]="productos"
                  (cambioSelect)="row.PRD_CODPRD = $event; seleccionarProducto($event, row)" [model]="row.PRD_CODPRD">
                </select-search>

              </td>
            </ng-container>

            <ng-container matColumnDef="sec">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Sec. </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Sec.">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '50px'}">
                  <mat-label></mat-label>
                  <input readonly [value]="row.DCD_SECDCD" [name]="'DCD_SECDCD' + i" matInput placeholder="Ej. 01">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="PRM_CODPRM">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Prom. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Prom.">

                <select-search [label]="''"
                  [clase]="row === selected? 'form-field-small form-field-cell select-width-100 mat-form-field-row': 'form-field-small form-field-cell select-width-100'"
                  [placeholder]="'Ej. PROMOCIÓN'" [name]="'prm_codprm'" [value]="'prm_codprm'"
                  [description]="'prm_descri'" [data]="promociones"
                  (cambioSelect)="row.PRM_CODPRM = $event; seleccionarPromocion($event, row)" [model]="row.PRM_CODPRM">
                </select-search>

              </td>
            </ng-container>

            <ng-container matColumnDef="UME_CODVEN">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="U.M.">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '70px'}">
                  <mat-label></mat-label>
                  <input readonly [(ngModel)]="row.UME_CODVEN" [name]="'UME_CODVEN' + i" matInput placeholder="Ej. UND">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="DCD_CANDCD">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Cantidad">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '80px'}">
                  <mat-label></mat-label>
                  <input (keyup)="calcularMontosFila(row)" (focus)="focusCampo($event)"
                    (change)="calcularMontosFila(row)" min="0" [ngStyle]="{textAlign: 'right'}" type="number"
                    [(ngModel)]="row.DCD_CANDCD" [name]="'DCD_CANDCD' + i" matInput placeholder="Ej. 10">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCD_PREUNI">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Precio">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '100px'}">
                  <mat-label></mat-label>
                  <input (keyup)="calcularMontosFila(row)" (focus)="focusCampo($event)" appDecimalPlaces
                    (change)="calcularMontosFila(row)" min="0" (blur)="row.DCD_PREUNI = row.DCD_PREUNI.toFixed(2)"
                    [ngStyle]="{textAlign: 'right'}" step="any" type="number" [(ngModel)]="row.DCD_PREUNI"
                    [name]="'DCD_PREUNI' + i" matInput placeholder="Ej. 10">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCD_PRUIGV">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio inc. IGV </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Precio inc. IGV">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '100px'}">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" min="0" type="number" readonly
                    [value]="row.DCD_PRUIGV.toFixed(2)" step="any" [name]="'DCD_PRUIGV' + i" matInput
                    placeholder="Ej. 10">
                </mat-form-field>
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="UME_CODVEN2">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. Venta </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="U.M. Venta">

                  <select-search
                  [clase]="row === selected? 'form-field-small form-field-cell select-width-100 mat-form-field-row': 'form-field-small form-field-cell select-width-100'"
                  [label]="''" [placeholder]="'Ej. UND'" [name]="'UME_CODVEN' + i" [value]="'ume_codume'"
                  [description]="'ume_descri'" [data]="unidadesMedida" (cambioSelect)="row.UME_CODVEN = $event;" [model]="row.UME_CODVEN">
                  </select-search>

              </td>
            </ng-container>

            <ng-container matColumnDef="PCD_CANVEN">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Venta </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Cantidad Venta">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small" [ngStyle]="{width: '100px', marginTop: '5px'}">
                  <mat-label></mat-label>
                  <input type="number" appDecimalPlaces (focus)="focusCampo($event)" decimalPlaces="4" min="0" [ngStyle]="{textAlign: 'right'}" [(ngModel)]="row.PCD_CANVEN" [name]="'PCD_CANVEN' + i" matInput placeholder="Ej. 10">
                </mat-form-field>
              </td>
            </ng-container> -->

            <ng-container matColumnDef="PCD_IMPBRU">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Bruto </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Importe Bruto">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '100px'}">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" min="0" type="number" readonly
                    [value]="row.DCD_IMPBRU.toFixed(2)" [name]="'DCD_IMPBRU' + i" matInput placeholder="Ej. 10"
                    step="any">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCD_PORDES">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> % Desc. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="% Desc.">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '100px'}">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" type="number" min="0" [value]="row.DCD_PORDES.toFixed(2)"
                    step="any" [name]="'DCD_PORDES' + i" matInput placeholder="Ej. 10" readonly>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCD_IMPDES">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Desc. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Importe Desc.">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '100px'}">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" type="number" readonly min="0" step="any"
                    [value]="row.DCD_IMPDES.toFixed(2)" [name]="'DCD_IMPDES' + i" matInput readonly
                    placeholder="Ej. 10">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCD_VALVTA">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor venta </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Valor venta">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '100px'}">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" type="number" min="0" step="any"
                    [value]="row.DCD_VALVTA.toFixed(2)" [name]="'DCD_VALVTA' + i" matInput readonly
                    placeholder="Ej. 10">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCD_IMPIGV">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. IGV. </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Imp. IGV.">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '100px'}">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" type="number" min="0" step="any"
                    [value]="row.DCD_IMPIGV.toFixed(2)" [name]="'DCD_IMPIGV' + i" matInput readonly
                    placeholder="Ej. 10">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCD_IMPTOT">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Total </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Imp. Total">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell"
                  [ngStyle]="{width: '100px'}">
                  <mat-label></mat-label>
                  <input [ngStyle]="{textAlign: 'right'}" type="number" min="0" step="any"
                    [value]="row.DCD_IMPTOT.toFixed(2)" [name]="'PCD_IMPTOT' + i" readonly matInput
                    placeholder="Ej. 10">
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="PCD_GLOPCD">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="Glosa">
                <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
                  <mat-label></mat-label>
                  <input type="text" [(ngModel)]="row.DCD_GLODCD" [name]="'DCD_GLODCD' + i" matInput
                    placeholder="Ej. descripción">
                </mat-form-field>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="row === selected"
              (click)="selectRow(row)"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="20" *ngIf="!loaderProductos">No se encontraron registros</td>
              <td class="mat-cell" colspan="20" *ngIf="loaderProductos">
                <mat-spinner diameter="40"></mat-spinner>
              </td>
            </tr>

          </table>

        </div>
        <mat-paginator class="mat-elevation-z0" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        <br>
        <div class="row">
          <div class="col s12 m12 l5">
            <div class="pav-table-header-container">
              <div class="pav-table-header-icon">
              </div>
              <div class="pav-table-header-title">
                Descuentos item: {{selected? selected.DCD_CORDCD: ''}}
              </div>
              <div class="pav-separator">

              </div>
              <!-- <div class="pav-button-icon-add" (click)="agregarDescuento()" matTooltip="Agregar descuento"
                [ngStyle]="{display: loaderData? 'none': ''}">
                <mat-icon svgIcon="add"></mat-icon>
              </div> -->
              <div class="pav-button-icon-add" matTooltip="Agregar ítem" (click)="agregarDescuento()"
                [ngStyle]="{display: loaderData? 'none': ''}">
                <mat-icon svgIcon="add-circle"></mat-icon>
              </div>
              &nbsp;
              <div class="pav-button-icon-delete" matTooltip="Eliminar ítem" (click)="eliminarDescuento()"
                [ngStyle]="{display: loaderData? 'none': ''}">
                <mat-icon svgIcon="remove"></mat-icon>
              </div>
              &nbsp;
              <div class="pav-button-icon-insert" matTooltip="Insertar ítem" (click)="insertarDescuento()"
                [ngStyle]="{display: loaderData? 'none': ''}">
                <mat-icon svgIcon="add-outline"></mat-icon>
              </div>
              <div>
                <!-- <button [disabled]="loaderData" type="button" mat-flat-button class="pav-button-add-item"
                (click)="agregarDescuento()">{{'general.button.add' | translate}}</button> -->
              </div>
            </div>
            <div class="mat-elevation-z0">

              <div class="overflow-x">

                <table mat-table [dataSource]="dataSourceDescuento" matSort>

                  <ng-container matColumnDef="item">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                    <td mat-cell *matCellDef="let row; let i = index" data-label="ITEM"> {{i + 1}} </td>
                  </ng-container>

                  <ng-container matColumnDef="cod_desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Desc. </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="TIPO DESC.">
                      <mat-form-field [class.mat-form-field-row]="row === selectedDescuento"
                        class="form-field-small form-field-cell" [ngStyle]="{width: '150px'}">
                        <mat-label></mat-label>
                        <mat-select [name]="'dco_coddco' + i" [(ngModel)]="row.dco_coddco">
                          <mat-option *ngFor="let descuento of descuentos" [value]="descuento.dco_coddco">
                            {{descuento.dco_desdco}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc. </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="DESCUENTO">

                      <mat-form-field [class.mat-form-field-row]="row === selectedDescuento"
                        class="form-field-small form-field-cell" [ngStyle]="{width: '100px'}">
                        <mat-label></mat-label>
                        <input [class.mat-form-field-row]="row === selectedDescuento" readonly
                          [ngStyle]="{textAlign: 'right'}" type="text" min="0"
                          [value]="(row.dco_coddco == '0001')? 'DESCUENTO ESPECIAL' : (row.dco_coddco) ? 'DESCUENTO POR REGALO': ''"
                          step="any" [name]="'descri' + i" matInput placeholder="Ej. 10">

                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="porc_desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> % Desc </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="% DESC">
                      <mat-form-field [class.mat-form-field-row]="row === selectedDescuento"
                        class="form-field-small form-field-cell" [ngStyle]="{width: '100px'}">
                        <mat-label></mat-label>
                        <input [ngStyle]="{textAlign: 'right'}" (focus)="focusCampo($event)"
                          (change)="cambiarFilaDescuento(row)" (keyup)="cambiarFilaDescuento(row)" type="number" min="0"
                          [(ngModel)]="row.ddd_pordes" step="any" [name]="'ddd_pordes' + i" matInput
                          placeholder="Ej. 10">
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="importe_desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Desc. </th>
                    <td mat-cell *matCellDef="let row; let i = index;" data-label="IMPORTE DESC">
                      <mat-form-field [class.mat-form-field-row]="row === selectedDescuento"
                        class="form-field-small form-field-cell" [ngStyle]="{width: '100px'}">
                        <mat-label></mat-label>
                        <input [class.mat-form-field-row]="row === selectedDescuento" readonly
                          [ngStyle]="{textAlign: 'right'}" type="number" min="0" [value]="row.ddd_impdes.toFixed(2)"
                          step="any" [name]="'ddd_impdes' + i" matInput placeholder="Ej. 10">

                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let row; let i = index" data-label="Acciones">

                      <div class="pav-btns-container">

                        <div matTooltip="Eliminar descuento" class="pav-btn-circle-delete"
                          (click)="eliminarDescuento()">
                          <mat-icon svgIcon="delete"></mat-icon>
                        </div>
                      </div>

                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsDescuento"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsDescuento;"
                    [class.selected-row]="row === selectedDescuento" (click)="selectRowDescuento(row)"></tr>

                  <tr class="mat-row" *matNoDataRow>

                    <td class="mat-cell" colspan="5">No se encontraron registros</td>

                  </tr>
                </table>

              </div>
              <mat-paginator #paginatorDescuento showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

            </div>
          </div>
          <div class="col s12 m12 l7">
            <mat-card class="mat-elevation-z0">
              <div class="row">
                <div class="col s12 m12 l6">

                  <mat-form-field class="example-full-width">
                    <mat-label>Observación Comprobante</mat-label>
                    <textarea matInput placeholder="Observación" name="PCC_OBSPED"></textarea>
                  </mat-form-field>
                  <div class="row" [ngStyle]="{paddignBottom: '10px'}">
                    <div class="col s12">
                      <mat-checkbox class="example-margin" color="primary" name="PCC_INDTERM">Puede ser
                        aprobado</mat-checkbox>
                    </div>
                  </div>


                </div>
                <div class="col s12 m12 l6">
                  <div class="mat-elevation-z0 overflow-x">
                    <h4 class="pav-titulo-documentos">Documentos generados del pedido</h4>
                    <table style="padding: 0px !important;" mat-table [dataSource]="documentos" matSort>

                      <ng-container matColumnDef="campo1">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. </th>
                        <td mat-cell *matCellDef="let row" data-label="DOC"> FAC </td>
                      </ng-container>

                      <ng-container matColumnDef="campo2">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Número </th>
                        <td mat-cell *matCellDef="let row" data-label="NUMERO"> F239234234782 </td>
                      </ng-container>

                      <ng-container matColumnDef="campo3">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                        <td mat-cell *matCellDef="let row" data-label="Usuario creador"> 07/01/2021 </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="columnsDocumentos"></tr>
                      <tr mat-row *matRowDef="let row; columns: columnsDocumentos;"></tr>

                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="5" *ngIf="!loaderDocumentos">No se encontraron registros</td>
                        <td class="mat-cell" colspan="5" *ngIf="loaderDocumentos">
                          <mat-spinner diameter="40"></mat-spinner>
                        </td>
                      </tr>

                    </table>

                  </div>

                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Datos complementarios" labelClass="mat-tab-label-0-2">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l4">
              <mat-form-field>
                <mat-label>Calcula Item</mat-label>
                <mat-select (selectionChange)="seleccionarCalculaItem($event.value)" [disabled]="loaderData"
                  name="moneda" name="DCC_INDCAL" [(ngModel)]="factura.docu_dcc.DCC_INDCAL">
                  <mat-option [value]="item.fci_codfci" *ngFor="let item of formasCalculoItem">
                    {{item.fci_descri}}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>

            <div class="col s12 m6 l3">

              <select-search [disabled]="loaderData" [label]="'Tipo de lista'" [placeholder]="'Ej. 01'"
                [name]="'TLP_CODTLP'" [value]="'TLP_CODTLP'" [description]="'description'" [data]="tiposPrecio"
                (cambioSelect)="factura.docu_dcc.TLP_CODTLP = $event; seleccionDeLista(factura.docu_dcc.TLP_CODTLP);"
                [model]="factura.docu_dcc.TLP_CODTLP"></select-search>

            </div>

            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Nro</mat-label>
                <input [disabled]="loaderData" type="number" matInput placeholder="Nro" name="LPC_CODLPC"
                  [(ngModel)]="factura.docu_dcc.LPC_CODLPC">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3">
              <select-search [disabled]="loaderData" [label]="'Proyecto o CeCo'" [placeholder]="'Ej. ASCENSOR'"
                [name]="'ccs_codccs'" [value]="'ccs_codccs'" [description]="'description'" [data]="centros"
                (cambioSelect)="factura.docu_dcc.CCS_CODCCS" [model]="factura.docu_dcc.CCS_CODCCS">
              </select-search>

            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Obligaciones Tributarias" labelClass="mat-tab-label-0-3">
        <mat-card class="mat-elevation-z0">

          <mat-card-content>

            <div class="row">
              <div class="col s12 m12 l4">
                <mat-form-field>
                  <mat-label>Tipo Retención</mat-label>
                  <mat-select #iTipoRetencion required [disabled]="loaderData" [(ngModel)]="factura.docu_dcc.iri_codiri"
                    name="iri_codiri" (selectionChange)="cambiarTipoRetencion($event)">
                    <mat-option *ngFor="let tipo of tiposRetencion" [value]="tipo.IRI_CODIRI">
                      {{tipo.IRI_DESCRI}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4">

                <select-search [label]="'Tipo Impuesto Detracción'" [placeholder]="'Ej. 0'" [value]="'codigo'"
                  [name]="'codigo'" [disabled]="loaderData" [description]="'descri'" [data]="tiposImpRetencion"
                  (cambioSelect)="factura.docu_dcc.tim_codtim = $event; cambiarTipoImpuestoRetencion()"
                  [model]="factura.docu_dcc.tim_codtim">
                </select-search>

              </div>
              <div class="col s12 m12 l4">
                <select-search [label]="'Tipo Operacion de Retención'" [placeholder]="'Ej. 0'" [value]="'tsd_codtsd'"
                  [name]="'tsd_codtsd'" [disabled]="loaderData" [description]="'tsd_descri'"
                  [data]="tiposOperacionRetencion">
                </select-search>

              </div>
              <div class="col s12 m12 l3" *ngIf="!esPercepcion">
                <mat-form-field>
                  <mat-label>% {{tituloTasa}}</mat-label>
                  <input #iTasa min="0" type="number" [ngStyle]="{textAlign: 'right'}" matInput
                    [placeholder]="'%' + tituloTasa" [(ngModel)]="factura.docu_dcc.dcc_tasdet" name="dpc_tasdet"
                    step="any">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3" *ngIf="!esPercepcion && factura.docu_dcc.iri_codiri != '02'">
                <mat-form-field>
                  <mat-label>Importe</mat-label>
                  <input appDecimalPlaces (focus)="focusInput($event)" #iImporte min="0" type="text" matInput
                    placeholder="Importe" [ngStyle]="{textAlign: 'right'}" [disabled]="loaderData"
                    [value]="factura.docu_dcc.dcc_impdet? factura.docu_dcc.dcc_impdet.toFixed(0) : '0'"
                    name="dpc_impdet" step="any">
                </mat-form-field>
              </div>

              <!-- <div [ngClass]="esPercepcion?'col s12 m12 l3':  'col s12 m12 l3'" *ngIf="!esPercepcion">
                <mat-form-field>
                  <mat-label>Depósito</mat-label>
                  <input appDecimalPlaces (focus)="focusInput($event)" #iDeposito min="0" type="number"
                    [ngStyle]="{textAlign: 'right'}" matInput placeholder="Depósito" [disabled]="loaderData"
                    [(ngModel)]="factura.docu_dcc.dcc_depdet" name="dpc_depdet" step="any">
                </mat-form-field>
              </div>
              <div [ngClass]="esPercepcion?'col s12 m12 l3':  'col s12 m12 l3'" *ngIf="!esPercepcion">
                <mat-form-field>
                  <mat-label>Fecha</mat-label>
                  <input #iFecha matInput [min]="factura.CCO_FECEMI" [matDatepicker]="fechaRetencion"
                    [disabled]="loaderData" [(ngModel)]="factura.docu_dcc.dcc_fecdet" name="dpc_fecdet">
                  <mat-datepicker-toggle matSuffix [for]="fechaRetencion"></mat-datepicker-toggle>
                  <mat-datepicker #fechaRetencion></mat-datepicker>
                </mat-form-field>
              </div> -->
            </div>
          </mat-card-content>
        </mat-card>

      </mat-tab>
      <mat-tab label="Exportaciones" labelClass="mat-tab-label-0-4">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Nave / Viaje</mat-label>
                <input [disabled]="loaderData" type="text" [(ngModel)]="factura.docu_dcc.dcc_expnav" name="dcc_expnav"
                  matInput placeholder="Nro">
              </mat-form-field>
            </div>
            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Container No.</mat-label>
                <input [disabled]="loaderData" type="text" [(ngModel)]="factura.docu_dcc.dcc_expcon" name="dcc_expcon"
                  matInput placeholder="Nro">
              </mat-form-field>
            </div>
            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Fecha de embarque</mat-label>
                <input [disabled]="loaderData" type="text" [(ngModel)]="factura.docu_dcc.dcc_expfee" name="dcc_expfee"
                  matInput placeholder="Nro">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <select-search [label]="'Términos de pago'" [placeholder]="'Ej. GONZALES'"
                [model]="factura.docu_dcc.tpa_codtpa" [name]="'tpa_codtpa'" [value]="'cli_codcli'"
                [description]="'cli_nomcli'" [data]="[]"
                (cambioSelect)="factura.docu_dcc.tpa_codtpa = $event"></select-search>
            </div>
            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Aerolinea - Naviera</mat-label>
                <input [disabled]="loaderData" type="text" [(ngModel)]="factura.docu_dcc.dcc_explin" name="dcc_explin"
                  matInput placeholder="Nro">
              </mat-form-field>
            </div>
            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Fecha llegada</mat-label>
                <input [disabled]="loaderData" type="text" [(ngModel)]="factura.docu_dcc.dcc_expfel" name="dcc_expfel"
                  matInput placeholder="Nro">
              </mat-form-field>
            </div>
            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Puerto Embarque</mat-label>
                <input [disabled]="loaderData" type="text" [(ngModel)]="factura.docu_dcc.dcc_exppue" name="dcc_exppue"
                  matInput placeholder="Nro">
              </mat-form-field>
            </div>
            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>BIL Nro</mat-label>
                <input [disabled]="loaderData" type="text" [(ngModel)]="factura.docu_dcc.dcc_expnbl" name="dcc_expnbl"
                  matInput placeholder="Nro">
              </mat-form-field>
            </div>
            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>Puerto Destino</mat-label>
                <input [disabled]="loaderData" type="text" [(ngModel)]="factura.docu_dcc.dcc_exppud" name="dcc_exppud"
                  matInput placeholder="Nro">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l4">
              <select-search [label]="'Observaciones'" [placeholder]="'Ej. GONZALES'" [name]="'cli_codcli'"
                [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="[]"></select-search>
            </div>

          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Cuotas" labelClass="mat-tab-label-0-6" [disabled]="indCredido == 0">
        <mat-card class="mat-elevation-z0">

          <div class="row">
            <div class="col s12 m12 l8">
              <div class="pav-table-header-container">
                <div class="pav-table-header-icon">
                </div>
                <div class="pav-table-header-title">
                  Cuotas
                </div>
                <div class="pav-separator">

                </div>
                <!-- <div class="pav-button-icon-add" (click)="agregarDescuento()" matTooltip="Agregar descuento"
                  [ngStyle]="{display: loaderData? 'none': ''}">
                  <mat-icon svgIcon="add"></mat-icon>
                </div> -->
                <div class="pav-button-icon-add" matTooltip="Agregar ítem" (click)="agregarCuota()"
                  [ngStyle]="{display: loaderData? 'none': ''}">
                  <mat-icon svgIcon="add-circle"></mat-icon>
                </div>
                &nbsp;
                <div class="pav-button-icon-delete" matTooltip="Eliminar ítem" (click)="eliminarCuota()"
                  [ngStyle]="{display: loaderData? 'none': ''}">
                  <mat-icon svgIcon="remove"></mat-icon>
                </div>
                &nbsp;
                <div class="pav-button-icon-insert" matTooltip="Insertar ítem" (click)="insertarCuota()"
                  [ngStyle]="{display: loaderData? 'none': ''}">
                  <mat-icon svgIcon="add-outline"></mat-icon>
                </div>
                <div>
                  <!-- <button [disabled]="loaderData" type="button" mat-flat-button class="pav-button-add-item"
                  (click)="agregarDescuento()">{{'general.button.add' | translate}}</button> -->
                </div>
              </div>
              <div class="mat-elevation-z0">

                <div class="overflow-x">

                  <table mat-table [dataSource]="dataSourceCuotas" matSort>

                    <ng-container matColumnDef="CCU_CORCCU">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                      <td mat-cell *matCellDef="let row; let i = index" data-label="ITEM">
                        <mat-form-field [class.mat-form-field-row]="row === selectedCuota"
                          class="form-field-small form-field-cell">
                          <mat-label></mat-label>
                          <input [class.mat-form-field-row]="row === selectedCuota" readonly
                            [ngStyle]="{textAlign: 'right'}" type="text" [(ngModel)]="row.CCU_CORCCU"
                            [name]="'CCU_CORCCU' + i" matInput placeholder="Ej. 001">

                        </mat-form-field>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="CCU_FECSUG">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Fecha">
                        <mat-form-field [class.mat-form-field-row]="row === selectedCuota"
                          class="form-field-small form-field-cell">
                          <mat-label></mat-label>
                          <input matInput [matDatepicker]="pickerFecCuota" [(ngModel)]="row.CCU_FECSUG"
                            [name]="'CCU_FECSUG' + i">
                          <mat-datepicker-toggle matSuffix [for]="pickerFecCuota"></mat-datepicker-toggle>
                          <mat-datepicker #pickerFecCuota></mat-datepicker>
                        </mat-form-field>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="CCU_IMPCCU">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="DESCUENTO">

                        <mat-form-field [class.mat-form-field-row]="row === selectedCuota"
                          class="form-field-small form-field-cell">
                          <mat-label></mat-label>
                          <input [class.mat-form-field-row]="row === selectedCuota"
                            [ngStyle]="{textAlign: 'right'}" type="number" min="0" [(ngModel)]="row.CCU_IMPCCU" step="any"
                            [name]="'CCU_IMPCCU' + i" matInput placeholder="Ej. 10">

                        </mat-form-field>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsCuotas"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsCuotas;"
                      [class.selected-row]="row === selectedCuota" (click)="selectRowCuota(row)"></tr>

                    <tr class="mat-row" *matNoDataRow>

                      <td class="mat-cell" colspan="5">No se encontraron registros</td>

                    </tr>
                  </table>

                </div>
                <mat-paginator #paginatorCuotas #paginatorDetalle showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

              </div>
            </div>
            <div class="col s12 m12 l4"></div>
          </div>

        </mat-card>
      </mat-tab>
      <!-- <mat-tab label="Datos de Despacho" labelClass="mat-tab-label-0-1">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l4">
              <mat-form-field>
                <mat-label>Lugar Desp.</mat-label>
                <mat-select name="LDE_CODLDE" (selectionChange)="seleccionarLugarDespacho($event.value)">
                  <mat-option *ngFor="let lugar of lugaresDespacho" [value]="lugar.LDE_CODLDE">
                    {{lugar.LDE_NOMLDE}} - {{lugar.LDE_DIRLDE}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l4">
              <select-search [label]="'Transportista'" [placeholder]="'Ej. GONZALES'" [name]="'cli_codcli'"
                [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="transportistas"></select-search>

            </div>

            <div class="col s12 m12 l4">
              <div>
                <mat-checkbox class="example-margin" name="tkt_indrec">Atender Completo</mat-checkbox>
                &nbsp; &nbsp;
                <mat-checkbox class="example-margin" name="tkt_indrec">Entregar Vendedor</mat-checkbox>
              </div>
            </div>



          </div>

          <div class="row">
            <div class="col s12 m12 l4">
              <select-search [label]="'Agen. Prov.'" [placeholder]="'Ej. A'" [name]="'cli_codcli'"
                [value]="'cli_codcli'" [description]="'cli_nomcli'" [data]="agencias"></select-search>

            </div>

            <div class="col s12 m12 l4">
              <select-search [label]="'Suc. Age.'" [placeholder]="'Ej. Agencia'" [name]="'CODIGO'" [value]="'CODIGO'"
                [description]="'DIRECCION'" [data]="sucursales">
              </select-search>
            </div>


            <div class="col s12 m12 l4">

              <fieldset>
                <legend>Días de Entrega</legend>
                <div>
                  <mat-checkbox class="example-margin">Lunes</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Martes</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Miércoles</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Jueves</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Viernes</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Sábado</mat-checkbox>
                  &nbsp;
                  <mat-checkbox class="example-margin">Domingo</mat-checkbox>
                </div>
              </fieldset>

            </div>

          </div>
        </mat-card>

      </mat-tab> -->
      <mat-tab label="Obligaciones tributarias" labelClass="mat-tab-label-0-3"
        *ngIf="configuracionTecnica.s71_oblvta == 1">

        <mat-card class="mat-elevation-z0">

          <div class="row">
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Tipo Retención</mat-label>
                <mat-select #iTipoRetencion required [disabled]="loaderData" name="iri_codiri"
                  (selectionChange)="cambiarTipoRetencion($event)">
                  <mat-option *ngFor="let tipo of tiposRetencion" [value]="tipo.IRI_CODIRI">
                    {{tipo.IRI_DESCRI}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <select-search [label]="'Tipo Impuesto Retención'" [placeholder]="'Ej. 0'" [name]="'codigo'"
                [name]="'codigo'" [disabled]="loaderData" [description]="'descri'" [data]="tiposImpRetencion">
              </select-search>
            </div>
            <div class="col s12 m12 l3" *ngIf="!esPercepcion">
              <mat-form-field>
                <mat-label>% {{tituloTasa}}</mat-label>
                <input #iTasa min="0" type="number" [ngStyle]="{textAlign: 'right'}" matInput
                  [placeholder]="'%' + tituloTasa" disabled name="dpc_tasdet">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3" *ngIf="!esPercepcion">
              <mat-form-field>
                <mat-label>Importe</mat-label>
                <input appDecimalPlaces (focus)="focusInput($event)" #iImporte min="0" type="number" matInput
                  placeholder="Importe" [ngStyle]="{textAlign: 'right'}" [disabled]="loaderData" name="dpc_impdet"
                  step="any">
              </mat-form-field>
            </div>
            <div *ngIf="esPercepcion" [ngClass]="esPercepcion?'col s12 m12':  'col s12 m12 l6'">
              <mat-form-field>
                <mat-label>Imp. Percepción</mat-label>
                <input #iImpPercepcion min="0" type="number" [ngStyle]="{textAlign: 'right'}" name="cco_impper" matInput
                  placeholder="Imp. Percepción" [disabled]="loaderData" step="any" (keyup)="calcularTotales()">
              </mat-form-field>
            </div>
            <div [ngClass]="esPercepcion?'col s12 m12 l3':  'col s12 m12 l3'" *ngIf="!esPercepcion">
              <mat-form-field>
                <mat-label>Depósito</mat-label>
                <input appDecimalPlaces (focus)="focusInput($event)" #iDeposito min="0" type="number"
                  [ngStyle]="{textAlign: 'right'}" matInput placeholder="Depósito" [disabled]="loaderData"
                  name="dpc_depdet" step="any">
              </mat-form-field>
            </div>
            <div [ngClass]="esPercepcion?'col s12 m12 l3':  'col s12 m12 l3'" *ngIf="!esPercepcion">
              <mat-form-field>
                <mat-label>Fecha</mat-label>
                <input #iFecha matInput [matDatepicker]="fechaRetencion" [disabled]="loaderData" name="dpc_fecdet">
                <mat-datepicker-toggle matSuffix [for]="fechaRetencion"></mat-datepicker-toggle>
                <mat-datepicker #fechaRetencion></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

        </mat-card>

      </mat-tab>
      <mat-tab label="Documento de referencia" labelClass="mat-tab-label-0-4"
        *ngIf="factura.TDO_CODTDO == 'NDB' || factura.TDO_CODTDO == 'NCR'">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m6 l3">
              <select-search [disabled]="loaderData" [label]="'Referencia'" [placeholder]="'Ej. FAC'"
                [name]="'TDO_CODTDO'" [value]="'TDO_CODTDO'" [description]="'description'" [data]="tiposDocumento">
              </select-search>

            </div>
            <div class="col s12 m12 l4 xl3">
              <mat-form-field>
                <mat-label>Fec. Ref.</mat-label>
                <input matInput [matDatepicker]="pickerFecRef">
                <mat-datepicker-toggle matSuffix [for]="pickerFecRef"></mat-datepicker-toggle>
                <mat-datepicker #pickerFecRef></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Aplicación de anticipos" labelClass="mat-tab-label-0-5"
        *ngIf="configuracionTecnica.s71_aplant == 1">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12">
              <div class="pav-table-header-container">
                <div class="pav-table-header-icon">
                </div>
                <div class="pav-table-header-title">
                  Anticipos
                </div>
                <div class="pav-separator">

                </div>
                <div>
                  <div class="pav-button-icon-add" (click)="agregarAnticipo()" matTooltip="Agregar anticipo"
                    [ngStyle]="{display: loaderData? 'none': ''}">
                    <mat-icon svgIcon="add"></mat-icon>
                  </div>

                  <!-- <button [disabled]="loaderData" type="button" mat-flat-button class="pav-button-add-item"
                  (click)="agregarDescuento()">{{'general.button.add' | translate}}</button> -->
                </div>
              </div>
              <div class="mat-elevation-z0">

                <div class="overflow-x">

                  <table mat-table [dataSource]="dataSourceAnticipos" matSort>
                    <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                      <td mat-cell *matCellDef="let row; let i = index" data-label="Acciones">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="td">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> TD </th>
                      <td mat-cell *matCellDef="let row; let i = index" data-label="ITEM"> {{i + 1}} </td>
                    </ng-container>

                    <ng-container matColumnDef="nDocumento">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Documento </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="TIPO DESC.">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="fecha">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                      <td mat-cell *matCellDef="let row" data-label="DESCUENTO">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="mo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> MO. </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="% DESC">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="importe">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="% DESC">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="aplicado">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Aplicado </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="IMPORTE DESC">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="cancela">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cancela </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="IMPORTE DESC">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="codCtaCte">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cod. Cta. Cte. </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="IMPORTE DESC">
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAnticipos"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAnticipos;"></tr>

                    <tr class="mat-row" *matNoDataRow>

                      <td class="mat-cell" colspan="9">No se encontraron registros</td>

                    </tr>
                  </table>

                </div>
                <mat-paginator #paginatorAnticipos showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

              </div>
              <div class="row">
                <div class="col s12 m12 l3"></div>
                <div class="col s12 m12 l3">
                  <mat-form-field class="pav-total-field">
                    <mat-label>Anticipo Bruto</mat-label>
                    <input type="number" matInput placeholder="Ej. 100.00" [ngStyle]="{textAlign: 'right'}"
                      name="dcc_antbru" [value]="factura.docu_dcc.dcc_antbru" step="any" readonly>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l3">
                  <mat-form-field class="pav-total-field">
                    <mat-label>Anticipo</mat-label>
                    <input type="number" matInput placeholder="Ej. 100.00" [ngStyle]="{textAlign: 'right'}"
                      name="DCC_IMPANT" [value]="factura.docu_dcc.DCC_IMPANT" step="any" readonly>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l3">
                  <mat-form-field class="example-full-width">
                    <mat-label>Total</mat-label>
                    <input matInput placeholder="Total">
                  </mat-form-field>
                </div>
              </div>

            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Evento en Caja" labelClass="mat-tab-label-0-1" *ngIf="configuracionTecnica.s71_evecaj == 1">

      </mat-tab>
      <mat-tab label="Detallado de cobranza" labelClass="mat-tab-label-0-6"
        *ngIf="configuracionTecnica.s71_detcob == 1">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12">
              <div class="pav-table-header-container">
                <div class="pav-table-header-icon">
                </div>
                <div class="pav-table-header-title">
                  Cobranzas
                </div>
                <div class="pav-separator">

                </div>
                <div>
                  <div class="pav-button-icon-add" (click)="agregarCobranza()" matTooltip="Agregar anticipo"
                    [ngStyle]="{display: loaderData? 'none': ''}">
                    <mat-icon svgIcon="add"></mat-icon>
                  </div>

                  <!-- <button [disabled]="loaderData" type="button" mat-flat-button class="pav-button-add-item"
                  (click)="agregarDescuento()">{{'general.button.add' | translate}}</button> -->
                </div>
              </div>
              <div class="mat-elevation-z0">

                <div class="overflow-x">

                  <table mat-table [dataSource]="dataSourceCobranzas" matSort>
                    <ng-container matColumnDef="acciones">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                      <td mat-cell *matCellDef="let row; let i = index" data-label="Acciones">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="fecha">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                      <td mat-cell *matCellDef="let row; let i = index" data-label="Fecha"> {{i + 1}} </td>
                    </ng-container>

                    <ng-container matColumnDef="recibo">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Recibo </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Recibo">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="importe">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe </th>
                      <td mat-cell *matCellDef="let row" data-label="Importe">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="planilla">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Planilla </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Planilla">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="cobrador">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cobrador </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Cobrador">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="vendedor">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendedor </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Vendedor">
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="deposito">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Depósito </th>
                      <td mat-cell *matCellDef="let row; let i = index;" data-label="Depósito">
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsCobranzas"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsCobranzas;"></tr>

                    <tr class="mat-row" *matNoDataRow>

                      <td class="mat-cell" colspan="8">No se encontraron registros</td>

                    </tr>
                  </table>

                </div>
                <mat-paginator #paginatorCobranzas showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

              </div>

              <div class="row">
                <div class="col s12 m12 l6"></div>
                <div class="col s12 m12 l3">
                  <mat-form-field class="pav-total-field">
                    <mat-label>Abonado</mat-label>
                    <input type="number" matInput placeholder="Ej. 100.00" [ngStyle]="{textAlign: 'right'}"
                      name="DCC_IMPABO" [value]="factura.docu_dcc.DCC_IMPABO" step="any" readonly>
                  </mat-form-field>
                </div>
                <div class="col s12 m12 l3">
                  <mat-form-field class="pav-total-field">
                    <mat-label>Vuelto</mat-label>
                    <input type="number" matInput placeholder="Ej. 100.00" [ngStyle]="{textAlign: 'right'}"
                      name="PCC_IMPTOT" step="any" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </mat-tab>
    </mat-tab-group>

  </div>

  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
    urlBack="/modulo-ventas/facturas" (clickMasOpciones)="clickMasOpciones()" (clickReset)="reset()"
    (clickFormato)="imprimirFormato()" [loaderFormato]="loaderFormato" (clickNuevo)="nuevoRegistro()">
    <ng-container id="botonesAdicionales">
      <button class="pav-btn-action" color="primary" matTooltip="Cargar guías" type="button"
        (click)="extraerPromociones()" disabled>
        <mat-icon class="pav-icon-btn" svgIcon="promocion"></mat-icon>
      </button>

    </ng-container>
  </pav-form-actions>
</form>
