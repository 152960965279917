import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { SnackBarService } from "@shared/services/snackbar.service";
import { Subscription } from "rxjs";
import { SeguimientoOportunidad } from "src/app/models/crm/seguimiento_oportunidad";
import { ConfigurationService, CrmService } from "src/app/services";
import { EtapaService } from "src/app/services/api/crm/maestros/etapa.service";
import { ResponsableService } from "src/app/services/api/crm/maestros/responsable.service";
import { SituacionService } from "src/app/services/api/crm/maestros/situacion.service";
import { ApiCRMOperacionesSeguimientoService } from "src/app/services/api/crm/operaciones/crm.operaciones.seguimiento.service";
import { NewStageOpportunityComponent } from "../new-stage-opportunity/new-stage-opportunity.component";
import { forkObs } from "@utils/observables/fork";

@Component({
  selector: 'nuevo-seguimiento-etapa',
  templateUrl: './nuevo-seguimiento-etapa.component.html',
  styleUrls: ['./nuevo-seguimiento-etapa.component.scss']
})
export class NuevoSeguimientoEtapa implements OnInit, OnDestroy {

  loaderData: boolean = false;
  loaderReg: boolean = false;

  buttonName: INameConstant = NAMES_CONSTANTS;

  responsables: any[] = [];
  etapas: any[] = [];
  situaciones: any[] = [];
  motivos: any[] = [];

  seguimiento: SeguimientoOportunidad;

  sendForm$: Subscription;
  existMotive: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<NewStageOpportunityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _etapaService: EtapaService,
    private _crmService: CrmService,
    private _apiSeguimientoCrmService: ApiCRMOperacionesSeguimientoService,
    private _responsableService: ResponsableService,
    private _situacionService: SituacionService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService
  ) {
    this.seguimiento = new SeguimientoOportunidad();

    this.seguimiento.codcia = this._configurationService.obtenerCompaniaCliente();
    this.seguimiento.coduse = this._configurationService.obtenerIdUsuario();
    this.seguimiento.idtkt = this.data.idtkt;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.loadMaestros();
  }

  loadMaestros(): void {
    this.loaderData = true;

    forkObs(
      this._responsableService.obtenerResponsables(),
      this._situacionService.obtenerSituacionesOportunidad(),
      this._etapaService.obtenerEtapas(),
      this._crmService.obtenerMotivosVentaSuspendida()
    ).then(([responsables, situaciones, etapas, motivosVentaSuspendida]) => {

      this.responsables = responsables;
      this.situaciones = situaciones;
      this.etapas = etapas;
      this.motivos = motivosVentaSuspendida;
      this.loaderData = false;
    }).catch(err => {
      this.loaderData = false;
      this._snackBarService.showError("Error al obtener maestros", 'Ok');
    })
  }

  seleccionarSituacion(select) {
    const situacion = this.situaciones.find(item => item.idstk == select.value);
    (situacion.indmot == 1) ? this.existMotive = true : this.existMotive = false;
  }

  registrar(f: NgForm): void {
    this.sendForm$ = this._apiSeguimientoCrmService.registrarSeguimientoOportunidad(this.seguimiento).subscribe(
      response => {
        this._snackBarService.showSuccess('Seguimiento de etapa registrado', 'Ok');
        this.loaderReg = false;
        this.dialogRef.close();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {

  }

}
