import { BINARY_TYPE } from "@data/interfaces/binary.type";

export class ConfiguracionTecnicaVentas {
  s71_codref: string;
  s71_descri: string;
  s71_motped: BINARY_TYPE;
  s71_vertca: BINARY_TYPE;
  s71_verocm: BINARY_TYPE;
  s71_vercan: BINARY_TYPE;
  s71_verccs: BINARY_TYPE;
  s71_nverpt: BINARY_TYPE;
  s71_vercot: BINARY_TYPE;
  s71_oblvta: BINARY_TYPE;
  s71_oblpta: BINARY_TYPE;
  s71_aplant: BINARY_TYPE;
  s71_ptoven: BINARY_TYPE;
  s71_evecaj: BINARY_TYPE;
  s71_detcob: BINARY_TYPE;
  s71_edipre: BINARY_TYPE;
  s71_edicpa: BINARY_TYPE;
  s71_editlp: BINARY_TYPE;
  s71_indlpc: BINARY_TYPE;

  constructor() {
    this.s71_codref = "";
    this.s71_descri = "";
    this.s71_motped = 0;
    this.s71_vertca = 0;
    this.s71_verocm = 0;
    this.s71_vercan = 0;
    this.s71_verccs = 0;
    this.s71_nverpt = 0;
    this.s71_vercot = 0;
    this.s71_oblvta = 0;
    this.s71_oblpta = 0;
    this.s71_aplant = 0;
    this.s71_ptoven = 0;
    this.s71_evecaj = 0;
    this.s71_detcob = 0;
    this.s71_edipre = 0;
  }
}
