<app-pavso-title-section title="Inventario físico" [module]="'Almacén'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>


  <div class="mat-elevation-z0">

    <div class="pavso_card_more_options">
      <div class="pav-table-header-icon"></div>
      <p class="pav-table-header-title">
        Listado de inventario físico
      </p>
      <div class="isWeb">
        <div class="pav-table-search">
          <input type="text" (keyup)="applyFilter($event)" [placeholder]="'Buscar'" #input>
        </div>
      </div>
      &nbsp;&nbsp;&nbsp;
      <div class="isMobile">
        <div class="pav-button-icon-download-excel">
          <mat-icon svgIcon="download"></mat-icon>
        </div>
      </div>
      <div class="isWeb">
        <button class="pav-button-download-excel" mat-flat-button color="primary">
          <mat-icon>file_download</mat-icon>
          {{'general.button.download' | translate}}
        </button>
      </div>
      <div class="isMobile">
        <div class="pav-button-icon-add"
          [routerLink]="['/modulo-almacen/inventario-fisico', '0']">
          <mat-icon svgIcon="add"></mat-icon>
        </div>
      </div>
      <div class="isWeb">
        <a>
          <button class="pav-button-add" mat-flat-button color="primary"
            [routerLink]="['/modulo-almacen/inventario-fisico', '0']">
            <mat-icon>add</mat-icon>
            {{'general.button.add' | translate}}
          </button>
        </a>
      </div>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <div class="overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">

            <div class="pav-btns-container">
              <div class="pav-btn-circle-edit" matTooltip="Editar"
                [routerLink]="['/modulo-almacen/inventario-fisico', row.id]"
                mat-icon-button color="primary">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="ALM_DESCRI">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Almacén </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Parte"> {{row.ALM_DESCRI}} </td>
        </ng-container>

        <ng-container matColumnDef="mac_nummac">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Registro </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Parte"> {{row.mac_nummac}} </td>
        </ng-container>

        <ng-container matColumnDef="mmo_codmmo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Parte"> {{row.mmo_codmmo}} </td>
        </ng-container>

        <ng-container matColumnDef="mmo_descri">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Observación </th>
          <td mat-cell *matCellDef="let row" data-label="Nro. Parte"> {{row.motivo.mmo_descri}} </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="7">No se encontraron registros "{{input.value}}"</td>

        </tr>

      </table>
    </div>

    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
