<app-pavso-title-section [title]="'Reporte'" [module]="'Caja y Bancos'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">
  <select-report [disabled]="false" [reportes]="reportes" (seleccionarReporte)="seleccionarReporte($event)"></select-report>

  <div class="pav-filtro-header">
    <div class="pav-filtro-header-icon"></div>
    <div class="pav-filtro-header-title">Filtros</div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loaderFields"></mat-progress-bar>
  <div class="pav-filtro-container">

    <div class="row">
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Moneda</mat-label>
          <mat-select [(ngModel)]="filtro.moneda" name="currency">
            <mat-option *ngFor="let moneda of monedas" [value]="moneda.TMO_CODTMO">
              {{moneda.tmo_deslar}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <select-search
          [label]="'Proveedor'"
          [placeholder]="'Ej. SALAS'"
          [name]="'CODIGO'"
          [description]="'NOMBRE'"
          [data]="proveedores"
          (cambioSelect)="filtro.proveedores = $event"
          [value]="filtro.proveedores">
        </select-search>
      </div>
      <div class="col s12 m12 l4">
        <select-search
          [label]="'Sucursal'"
          [placeholder]="'Ej. sucursal'"
          [name]="'CODIGO'"
          [description]="'NOMBRE'"
          [data]="sucursales"
          (cambioSelect)="filtro.sucursales = $event"
          [value]="filtro.sucursales">
        </select-search>

      </div>
      <div class="col s12 m12 l4">
        <select-search
          [label]="'Tipo de documento'"
          [placeholder]="'Ej. FAC'"
          [name]="'TDO_CODTDO'"
          [description]="'TDO_DESLAR'"
          [data]="tiposDoc"
          (cambioSelect)="filtro.tiposDocumento = $event"
          [value]="filtro.tiposDocumento">
        </select-search>

      </div>
      <div class="col s12 m12 l4">
        <select-search
          [label]="'Cuenta contable'"
          [placeholder]="'Ej. 42'"
          [name]="'ccn_codccn'"
          [description]="'ccn_desabr'"
          [data]="cuentas"
          (cambioSelect)="filtro.cuentasContable = $event"
          [value]="filtro.cuentasContable">
        </select-search>

      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Fecha de Corte</mat-label>
          <input [(ngModel)]="fechaRuta" matInput [matDatepicker]="picker" name="fechaRuta">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Estado</mat-label>
          <mat-select>
            <mat-option value="consaldo">
              Con saldo
            </mat-option>
            <mat-option value="consaldo">
              Con saldo
            </mat-option>
            <mat-option value="consaldo">
              Con saldo
            </mat-option>
            <mat-option value="cancelados">
              Cancelados
            </mat-option>
            <mat-option value="todos">
              Todos
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col s12 m12 l4">
        <mat-form-field>
          <mat-label>Ordenado por</mat-label>
          <mat-select>
            <mat-option value="food.value">
              Vencimiento
            </mat-option>
            <mat-option value="food.value">
              Emisión
            </mat-option>
            <mat-option value="food.value">
              Documento
            </mat-option>
            <mat-option value="food.value">
              Nro. de registro
            </mat-option>
            <mat-option value="food.value">
              Proveedor
            </mat-option>

          </mat-select>
        </mat-form-field>

      </div>
    </div>

  </div>
</div>

<mat-card class="mat-elevation-z0">
  <button class="pav-button-action" mat-flat-button color="primary" [class.spinner]="loading" [disabled]="loading" (click)="verReporte()" >{{LABELS_NAME.BTN_SHOW_REPORT}}</button>
</mat-card>

