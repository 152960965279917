import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, ConfigurationService, AlmacenService, ContabilidadService, ComprasService, VentasService, CuentaCobrarService } from 'src/app/services';
import { RegistroCompra } from 'src/app/models';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { addDaysToDate, formatDateWithDash } from 'src/app/utils/formats/date.format';
import { DetailPurchaseRecord, OrdersPurchaseRecord } from 'src/app/models/compras/purchase_record';
import { CuentaPagarDialogComponent } from '@shared/components/dialogs/cuenta-por-pagar/cuenta-pagar-dialog/cuenta-pagar-dialog.component';
import { MatSelect } from '@angular/material/select';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { MatButton } from '@angular/material/button';
import { roundToDecimals } from 'src/app/utils/formats/round.format';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { generarPdfRCAsientoContable } from '@utils/pdfmaker/builder/compras.pdfmaker';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { OrdenCompraService } from 'src/app/services/api/compras/operaciones/orden-compra.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { RegistroCompraBottomSheet } from '../registro-compra-bottom-sheet/registro-compra-bottom-sheet';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { ProveedorDialog } from '../../../maestros/proveedor';
import { ProveedorService } from 'src/app/services/api/compras/maestros/proveedor.service';
import { NumberFormatDirective } from '@shared/directives/number-format.directive';
import { retornaDiferenciaDias } from '@utils/date/date';
import { ConfiguracionTecnica } from 'src/app/models/contabilidad/configuracion-tecnica';
import { ConfiguracionContabilidad } from 'src/app/models/contabilidad/configuracion-contabilidad';
import { TipoOperacionService } from 'src/app/services/api/contabilidad/maestros/tipo-operacion.service';
import { BINARY_TYPE } from '@data/interfaces/binary.type';
import { IParam } from 'src/app/services/api/utilities/params';
import { IMontoDebeHaber } from '../components/total-contable/total-contable.component';

@Component({
  selector: 'app-registro-compra-form',
  templateUrl: './registro-compra-form.component.html',
  styleUrls: ['./registro-compra-form.component.scss'],
  providers: [NumberFormatDirective]
})
export class RegistroCompraFormComponent implements OnInit, OnDestroy {

  selected: any;

  selectRow(row: any) {
    this.selected = row;
  }

  displayedColumns: string[] = ['ldi_corldi', 'ccn_codccn', 'cli_codcli', 'ccs_codccs', 'tdo_codtdo', 'ldi_docref', 'tmo_codtmo', 'ldi_tipcam', 'ldi_inddha', 'ldi_impnac', 'ldi_impmex'/*'debemn', 'habermn', 'debeme', 'haberme'*/, 'ldi_gloldi', 'cco_codcco'];
  dataSource: MatTableDataSource<DetailPurchaseRecord>;

  @ViewChild('paginator1') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns2: string[] = ['acciones', 'ord_compra', 'fac_importacion', 'num_documento', 'monto'];
  dataSource2: MatTableDataSource<any>;

  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild(MatSort) sort2: MatSort;

  loaderReg = false;
  loaderAnular = false;

  loaderData = false;
  loaderOrdenes = false;

  subsidiarios: any[] = [];
  cuentasContable: any[] = [];
  cuentasContablesTotales: any[] = [];
  tiposBienServicio: any[] = [];
  tiposImpuesto: any[] = [];
  tiposDocumento: any[] = [];
  tiposDocumentoRef: any[] = [];
  monedas: any[] = [];
  ordenesCompra: any[] = [];
  ordenesCompraTotales: any[] = [];
  vouchers: any[] = [];
  centros: any[] = [];
  tiposRetencion: any[] = [];
  tiposImpRetencion: any[] = [];
  tempTiposImpRetencion: any[] = [];
  tiposOperacionRetencion: any[] = [];
  signosctacte: any[] = [];
  cuentasPorCobrar: any[] = [];

  usuario: any;
  fechahoy: any = new Date();

  minDate: Date;
  maxDate: Date;

  nameXml: string = "";

  registro: RegistroCompra;

  configuracionTecnica: ConfiguracionTecnica = new ConfiguracionTecnica();
  configuracionContabilidad: ConfiguracionContabilidad = new ConfiguracionContabilidad();

  permisos$: Subscription;
  subdiarios$: Subscription;
  tipoOperacion$: Subscription;
  cuentas$: Subscription;
  tiposBienServicio$: Subscription;
  tiposRenta$: Subscription;
  tiposImpuesto$: Subscription;
  tiposDocumento$: Subscription;
  proveedor$: Subscription;
  moneda$: Subscription;
  ordenesCompra$: Subscription;
  condiciones$: Subscription;
  voucher$: Subscription;
  centro$: Subscription;
  auxiliar$: Subscription;
  tipoRetencion$: Subscription;
  tipoImpRetencion$: Subscription;
  signoCtaCte$: Subscription;
  tipoCambio$: Subscription;
  configuracionTecnica$: Subscription;
  configuracionContabilidad$: Subscription;
  ultimoRegistroCompra$: Subscription;
  listaFacturasDeProveedor$: Subscription;
  period$: Subscription;
  proveedores$: Subscription;
  registroCompra$: Subscription;
  sendForm$: Subscription;
  tiposOperacionRetencion$: Subscription;


  year: string;
  month: string;

  montosDebeHaber: any;

  cuentasTotales: any[] = [];
  cuentas: any[] = [];
  proveedores: any[] = [];
  auxiliares: any[] = [];
  tiposOperacion: any[] = [];
  tiposRenta: any[] = [];
  condiciones: any[] = [];

  esPercepcion: boolean;

  @ViewChild('iAno') anoElement: ElementRef;
  @ViewChild('iMes') mesElement: ElementRef;
  @ViewChild('iSubdiario') subdiarioElement: MatSelect;
  @ViewChild('iFecha') fechaElement: ElementRef;
  @ViewChild('iProveedor') proveedorElement: MatSelect;
  @ViewChild('iEmision') emisionElement: ElementRef;
  @ViewChild('iTc') tcElement: ElementRef;
  @ViewChild('iMoneda') monedaElement: MatSelect;
  @ViewChild('iIgv') igvElement: ElementRef;
  @ViewChild('iDerCredito') derCreditoElement: MatSelect;
  @ViewChild('iTipoOperacion') tipoOperacionElement: MatSelect;
  @ViewChild('iClasificacion') clasificacionElement: MatSelect;
  @ViewChild('iTipoDoc') tipoDocElement: MatSelect;
  @ViewChild('iNroDoc') nroDocElement: ElementRef;
  @ViewChild('iAnoDua') anoDuaElement: ElementRef;
  @ViewChild('iSerie') serieElement: ElementRef;
  @ViewChild('iCondicion') condicionElement: MatSelect;
  @ViewChild('iVcto') vctoElement: ElementRef;
  @ViewChild('iCuenta') cuentaElement: MatSelect;
  @ViewChild('iOrdenCompra') ordenCompraElement: MatSelect;
  @ViewChild('iTipoRenta') tipoRentaElement: MatSelect;
  @ViewChild('iGlosa') glosaElement: ElementRef;
  @ViewChild('iTipoDocRef') tipoDocRefElement: MatSelect;
  @ViewChild('iDocRef') docRefElement: ElementRef;
  @ViewChild('iFecRef') fecRefElement: ElementRef;

  // Importes
  @ViewChild('iAfectoCD') afectoCDRefElement: ElementRef;
  @ViewChild('iAfectoSD') afectoSDRefElement: ElementRef;
  @ViewChild('iRenta') rentaRefElement: ElementRef;
  @ViewChild('iValRenta') valRentaRefElement: ElementRef;
  @ViewChild('iAjusteIgv') ajusteIgvRefElement: ElementRef;
  @ViewChild('iImporteSise') importeSiseRefElement: ElementRef;
  @ViewChild('iImporteFise') importeFiseRefElement: ElementRef;
  @ViewChild('iInafecto') inafectoRefElement: ElementRef;
  @ViewChild('iAfecto') afectoRefElement: ElementRef;
  @ViewChild('iValCompra') valCompraRefElement: ElementRef;
  @ViewChild('iImpIgv') impIgvRefElement: ElementRef;
  @ViewChild('iTotal') totalRefElement: ElementRef;
  @ViewChild('iTipoRetencion') tipoRetencionElement: MatSelect;
  @ViewChild('iTipoImpuestoRet') tipoImpuestoRetElement: MatSelect;
  @ViewChild('iTasa') tasaRefElement: ElementRef;
  @ViewChild('iImporte') importeRefElement: ElementRef;
  @ViewChild('iImpPercepcion') impPercepcionRefElement: ElementRef;
  @ViewChild('iDeposito') depositoRefElement: ElementRef;
  @ViewChild('iFecha') fechaRefElement: ElementRef;
  @ViewChild('iFob') fobRefElement: ElementRef;
  @ViewChild('iFlete') fleteRefElement: ElementRef;
  @ViewChild('iSeguro') seguroRefElement: ElementRef;
  @ViewChild('iCif') cifRefElement: ElementRef;
  @ViewChild('iAdvalorem') advaloremRefElement: ElementRef;
  @ViewChild('btnAdd') buttonAddRefElement: MatButton;
  @ViewChild('btnGeneraAsiento') buttonGeneraAsientoRefElement: MatButton;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  tituloTasa: string = "Retención";

  disableImportaciones: boolean;

  @ViewChildren("cuentaTotalSelect") selectCuentas: QueryList<MatSelect>;
  @ViewChildren("descripcionInput") inputDescripcion: QueryList<ElementRef>;
  @ViewChildren("costoSelect") selectCosto: QueryList<MatSelect>;

  disableAnoDua: boolean = true;


  documentToUpload: DocumentToUpload;

  uid: string;

  permision: any;

  indDocReferencia: BINARY_TYPE = 0;

  constructor(
    private _dialogService: DialogService,
    private _contabilidadService: ContabilidadService,
    private _tipoOperacionService: TipoOperacionService,
    private _comprasService: ComprasService,
    private _ordenCompraService: OrdenCompraService,
    private _proveedorService: ProveedorService,
    private _configurationService: ConfigurationService,
    private _almacenService: AlmacenService,
    private _clienteService: ClienteService,
    private _ventasService: VentasService,
    private _authService: AuthenticationService,
    private _snackBarService: SnackBarService,
    private _bottomSheet: MatBottomSheet,
    private _cuentaxCobrar: CuentaCobrarService,
    public dialog: MatDialog,
    private store: Store<PavsoState>,
    private _activatedRoute: ActivatedRoute,
  ) {
    this.registro = new RegistroCompra();
    this.initialize();
  }

  initialize(): void {
    this.registro.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    this.registro.cco_coduse = this._configurationService.obtenerIdUsuario();
    this.registro.dpc_coduse = this._configurationService.obtenerIdUsuario();

    this.disableImportaciones = true;

    this.period$ = this.store.select('period').subscribe(({ year, month }) => {
      this.year = year;
      this.month = month;
      this.registro.ano_codano = year;
      this.registro.mes_codmes = month;

      this.minDate = new Date(parseInt(this.year), parseInt(this.month) - 1, 1);
      this.maxDate = new Date(parseInt(this.year), parseInt(this.month), 0);

      this.registro.cco_fecreg = this.maxDate;
      this.registro.cco_fecven = this.maxDate;
      this.registro.cco_fecemi = this.maxDate;
    });

    this.esPercepcion = false;

    this.montosDebeHaber = [
      {
        name: "Debe",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Haber",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Diferencia",
        mn: 0.00,
        me: 0.00
      }
    ];

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource2 = fillTable([], this.paginator2, this.sort2);

    this.usuario = this._authService.getUsuarioSistema();

    this.documentToUpload = new DocumentToUpload();
  }

  setDefaultFieldsPurchaseRecord(): void {

    this.registro.ano_codano = this.year;
    this.registro.mes_codmes = this.month;
    // this.registro.cco_tasigv = this.tiposRetencion[0].porc_det;

    this.registro.cco_coduse = this.usuario;

    this.registro.cia_codcia = this._configurationService.obtenerCompaniaCliente()

    this.registro.dpc_coduse = this._authService.getUsuarioSistema();

    this.obtenerTasaCambio();

  }

  ngOnInit(): void {

    this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

  }

  validarFechaRegistro(): void {
    const today = new Date();
    const month = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : `0${today.getMonth() + 1}`;
    const year = today.getFullYear().toString();

    console.log('hoy', { year, month })

    if (this.year == year && this.month == month) {
      this.registro.cco_fecreg = today;
      this.registro.cco_fecemi = today;
      this.registro.cco_fecven = today;
    }
  }

  loadData(): void {
    this.loaderData = true;

    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;

      if (this.uid == '0') this.validarFechaRegistro();

      this.loadMaestros();

    })
  }

  loadMaestros(): void {
    this.loaderData = true;

    this.signoCtaCte$ = this._contabilidadService.listarSignoCtaCte().subscribe(
      signoCtaCte => {

        this.signosctacte = signoCtaCte;

        this.condiciones$ = this._comprasService.obtenerCondicionesPago().subscribe(
          condiciones => {
            this.condiciones = condiciones;

            this.subdiarios$ = this._contabilidadService.obtenerSubdiarios().subscribe(
              subdiarios => {
                this.subsidiarios = subdiarios.filter(item => item.sco_indcom == 1);
                console.log('subdiarios ', this.subsidiarios)

                this.tipoOperacion$ = this._tipoOperacionService.obtenerTiposOperacion().subscribe(
                  tiposOperaciones => {
                    this.tiposOperacion = tiposOperaciones;

                    this.cuentas$ = this._contabilidadService.listarPlanDeCuentas().subscribe(
                      cuentas => {
                        this.cuentas = cuentas;
                        this.cuentasContable = cuentas;
                        this.cuentasTotales = cuentas;

                        this.tiposBienServicio$ = this._contabilidadService.obtenerTiposBienServicio().subscribe(
                          tiposBienServicio => {
                            this.tiposBienServicio = tiposBienServicio;

                            this.tiposRenta$ = this._contabilidadService.obtenerTiposRenta().subscribe(
                              tiposRenta => {
                                this.tiposRenta = tiposRenta;

                                this.ordenesCompra$ = this._ordenCompraService.obtenerOrdenesCompraGenerico().subscribe(
                                  ordenesCompra => {
                                    this.ordenesCompra = ordenesCompra;
                                    console.log('ordenes de compra', this.ordenesCompra)

                                    this.tiposDocumento$ = this._contabilidadService.obtenerTiposDocumento().subscribe(
                                      tiposDocumento => {
                                        console.log('tipos documento', tiposDocumento)
                                        this.tiposDocumento = tiposDocumento.filter(item => item.tdo_indcom == 1);
                                        this.tiposDocumentoRef = this.tiposDocumento;
                                        // this.tiposDocumento = tiposDocumento.sort(orderKeyStringJSON("TDO_CODTDO"));

                                        this.centro$ = this._contabilidadService.listarCentroCostos().subscribe(
                                          centros => {
                                            this.centros = centros;

                                            this.auxiliar$ = this._clienteService.obtenerClientes().subscribe(
                                              clientes => {
                                                this.auxiliares = clientes.filter(item => item.cli_indtra == 1);
                                                this.proveedores = clientes.filter(item => item);

                                                this.tipoRetencion$ = this._comprasService.listarTiposRetencion().subscribe(
                                                  tiposRetencion => {
                                                    this.tiposRetencion = tiposRetencion;

                                                    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.registro.cco_fecemi)).subscribe(
                                                      tipoCambio => {
                                                        this.registro.cco_tipcam = tipoCambio[0].tipcam;

                                                        this.configuracionTecnica$ = this._contabilidadService.obtenerConfiguracionTecnica().subscribe(
                                                          configuracionTecnica => {

                                                            if (configuracionTecnica && configuracionTecnica.length > 0) {
                                                              this.configuracionTecnica = configuracionTecnica[0];
                                                            }

                                                            this.configuracionContabilidad$ = this._contabilidadService.obtenerConfiguracionContabilidad().subscribe(
                                                              configConta => {

                                                                this.configuracionContabilidad = configConta;

                                                                this._cuentaxCobrar.obtenerCuentaxCobrarPorPeriodo(`${this.year}${this.month}`).subscribe(
                                                                  cuentasPorCobrar => {

                                                                    this.cuentasPorCobrar = cuentasPorCobrar.filter(item => item.tip_doc == 'CRE' && item.importe_saldo != 0);
                                                                    console.log('cuentas por cobrar', this.cuentasPorCobrar)

                                                                    this.tiposOperacionRetencion$ = this._comprasService.obtenerTiposOperacionRetencion().subscribe(
                                                                      tiposOperacionRetencion => {
                                                                        this.tiposOperacionRetencion = tiposOperacionRetencion;
                                                                        this.setDefaultFieldsPurchaseRecord();

                                                                        if (this.uid != '0') {
                                                                          this.obtenerRegistroCompra();
                                                                          return;
                                                                        };

                                                                        this._comprasService.obtenerTiposImpuestoRetencionPorFechaEmision(this.registro.cco_fecemi).subscribe(
                                                                          tiposImpuestoRetencion => {
                                                                            this.tiposImpRetencion = [];
                                                                            this.tempTiposImpRetencion = tiposImpuestoRetencion;
                                                                            this.loaderData = false;
                                                                          },
                                                                          error => {
                                                                            this._snackBarService.showError('Error al obtener configuración de contabilidad', 'Ok')

                                                                            this.loaderData = false;
                                                                          }
                                                                        )
                                                                      },
                                                                      error => {
                                                                        this._snackBarService.showError('Error al obtener tipos de operación retención', 'Ok')

                                                                        this.loaderData = false;
                                                                      }
                                                                    )
                                                                  }
                                                                )



                                                              },
                                                              error => {
                                                                this._snackBarService.showError('Error al obtener configuración de contabilidad', 'Ok')
                                                                this.loaderData = false;
                                                              }

                                                            )

                                                          },
                                                          error => {
                                                            this._snackBarService.showError('Error al obtener configuración de técnica', 'Ok')
                                                            this.loaderData = false;
                                                          }
                                                        )

                                                      },
                                                      error => {
                                                        this.loaderData = false;
                                                      }
                                                    )
                                                  },
                                                  error => {
                                                    this.loaderData = false;
                                                  }
                                                )
                                              },
                                              error => {
                                                this.loaderData = false;
                                              }
                                            )
                                          },
                                          error => {
                                            this.loaderData = false;
                                          }
                                        )
                                      },
                                      error => {
                                        this.loaderData = false;
                                      }
                                    )
                                  },
                                  error => {
                                    this.loaderData = false;
                                  }
                                )
                              },
                              error => {
                                this.loaderData = false;

                              }
                            )
                          },
                          error => {
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this.loaderData = false;
                      }
                    )
                  },
                  error => {
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this.loaderData = false;
              }
            )
          },
          error => {
            this.loaderData = false;
          }
        )
      },
      error => {
        this.loaderData = false;
      }
    )
    // this.permisos$ = this._configurationService.getUserPermisions().subscribe(
    //   permisos => {
    //     this.permision = permisos[0].find(element => element.usuario == this._authService.getUsuarioSistema().toString().toUpperCase());

    //   },
    //   error => {
    //     this.loaderData = false;
    //   }
    // )


  }

  obtenerRegistroCompra(): void {

    this.registroCompra$ = this._comprasService.mostrarRegistroDeCompra(this.uid).subscribe(
      registroCompra => {
        // ===== VALORES POR DEFECTO =======
        console.log('registro de compra obtenido', registroCompra)
        this.registro = registroCompra;
        this.registro.cco_indsta = '1';
        this.registro.cco_coduse = this.usuario;
        this.registro.cco_tipcam = parseFloat(this.registro.cco_tipcam.toFixed(3))

        this.cambiarTipoRetencion({ value: this.registro.iri_codiri });

        this.registro.detalle = (registroCompra.detalle) ? registroCompra.detalle : [];
        this.registro.ordenes = (registroCompra.ordenes) ? registroCompra.ordenes : [];

        this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);

        this.dataSource2 = fillTable(this.registro.ordenes, this.paginator2, this.sort2);

        this.calculaTotal();

        this.establecerDebeHaber();

        // Obtener el indicador de referencia
        let tipo = this.tiposDocumento.find(item => item.TDO_CODTDO == this.registro.tdo_codtdo);
        this.indDocReferencia = tipo.tdo_indref;

        this.loaderData = false;

        this._comprasService.obtenerTiposImpuestoRetencionPorFechaEmision(this.registro.cco_fecemi).subscribe(
          tiposImpuestoRetencion => {
            this.tiposImpRetencion = [];
            this.tempTiposImpRetencion = tiposImpuestoRetencion;
            this.loaderData = false;
          },
          error => {
            this._snackBarService.showError('Error al obtener configuración de contabilidad', 'Ok')

            this.loaderData = false;
          }
        )

      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK");
        this.loaderData = false;
      }
    )
  }

  establecerEntero(): void {
    this.registro.cco_tipcam = Number(this.registro.cco_tipcam);
    this.registro.cco_impafe = Number(this.registro.cco_impafe);
    this.registro.cco_impina = Number(this.registro.cco_impina);

    this.registro.detalle.forEach(element => {
      element.ldi_tipcam = Number(element.ldi_tipcam);
      element.ldi_impnac = Number(element.ldi_impnac);
      element.ldi_impmex = Number(element.ldi_impmex);

    })
  }

  filaSeleccionada(row, event): void {

    this.registro.detalle.forEach(element => {
      if (element.ldi_corldi != row.ldi_corldi) {
        element.isSelected = false;
      }
    });
  }

  focusInput(evento): void {
    evento.target.select();
  }

  focusElement(index: number, evento) {
    console.log('direccionales', index)
    if (!this.registro.detalle[index]) return;
    // this.dataSource.data.forEach(item => (item.focused = false));
    this.registro.detalle[index].focused = true;

    this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort)

  }

  copiarItem(): void {

    if (!this.selected) {
      this._snackBarService.showError('Debe seleccionar un item', "OK");
      return;
    }

    if (this.selected.ldi_corldi[0] == '6' || this.selected.ldi_corldi[0] == '7') {
      this._snackBarService.showError('No es permitido copiar un ítem que comience con el correlativo 6 o 7', "OK");
      return;
    }

    let item = new DetailPurchaseRecord();
    item.cia_codcia = this.selected.cia_codcia;
    item.ano_codano = this.selected.ano_codano;
    item.mes_codmes = this.selected.mes_codmes;
    item.ldi_anoccl = this.selected.ldi_anoccl;
    item.ldi_mesccl = this.selected.ldi_mesccl;
    item.tmo_codtmo = this.selected.tmo_codtmo;
    item.ldi_fecemi = this.selected.ldi_fecemi;
    item.ldi_fecven = this.selected.ldi_fecven;
    item.sco_codsco = this.selected.sco_codsco;
    item.ldi_tipcam = this.selected.ldi_tipcam;
    item.ldi_gloldi = this.selected.ldi_gloldi;
    item.ccn_codccn = this.selected.ccn_codccn;
    item.cli_codcli = this.selected.ccn_codccn;
    item.tdo_codtdo = this.selected.tdo_codtdo;
    item.ldi_docref = this.selected.ldi_docref;
    item.tmo_codtmo = this.selected.tmo_codtmo;
    item.ldi_tipcam = this.selected.ldi_tipcam;
    item.ldi_inddha = this.selected.ldi_inddha;
    item.ldi_gloldi = this.selected.ldi_gloldi;
    item.cco_codcco = this.selected.cco_codcco;

    item.ldi_impnac = 0;
    item.ldi_impmex = 0;
    item.ccs_codccs = null;

    this.borrarAsientosDestino();

    this.registro.detalle.push(item);

    this.registro.detalle.forEach((element, key) => {
      element.ldi_corldi = (key + 1 > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort)

  }

  calculaTotal(): void {
    this.registro.valvta = (this.registro.cco_impafe ? this.registro.cco_impafe : 0) +
      (this.registro.cco_impina ? this.registro.cco_impina : 0) +
      (this.registro.dpc_impadv ? this.registro.dpc_impadv : 0);

    this.registro.dpc_impcif = (this.registro.dpc_impfob ? this.registro.dpc_impfob : 0) +
      (this.registro.dpc_impfle ? this.registro.dpc_impfle : 0) +
      (this.registro.dpc_impseg ? this.registro.dpc_impseg : 0);

    console.log('this.recepcionCompra.DPC_IMPPER', (this.registro.dpc_impper ? this.registro.dpc_impper : 0))
    this.registro.cco_impdoc = (this.registro.cco_impafe ? this.registro.cco_impafe : 0) +
      (this.registro.cco_impina ? this.registro.cco_impina : 0) +
      (this.registro.cco_impigv ? this.registro.cco_impigv : 0) -
      (this.registro.dpc_impren ? this.registro.dpc_impren : 0) +
      (this.registro.dpc_impper ? this.registro.dpc_impper : 0) +
      (this.registro.dpc_impfis ? this.registro.dpc_impfis : 0) +
      (this.registro.dpc_impsis ? this.registro.dpc_impsis : 0);

    // const ln_importe = roundToDecimals(((this.recepcionCompra.CCO_IMPDOC - this.recepcionCompra.CCO_IMPINA) * this.recepcionCompra.DPC_TASDET) / 100, 2);
    // this.recepcionCompra.DPC_IMPDET = (this.recepcionCompra.TMO_CODTMO == "SO") ? ln_importe : roundToDecimals(ln_importe * this.recepcionCompra.CCO_TIPCAM, 1);
    this.registro.cco_impigv = roundToDecimals((this.registro.cco_impafe * (this.registro.cco_tasigv / 100) + this.registro.dpc_difigv), 2)

    console.log({ cco_impina: this.registro.cco_impina, cco_impafe: this.registro.cco_impafe, cco_impigv: this.registro.cco_impigv })

    this.registro.cco_impdoc = (this.registro.cco_impina ? this.registro.cco_impina : 0) +
      (this.registro.cco_impafe ? this.registro.cco_impafe : 0) +
      (this.registro.cco_impigv ? this.registro.cco_impigv : 0) +
      (this.registro.dpc_impren ? this.registro.dpc_impren : 0) +
      (this.registro.dpc_impper ? this.registro.dpc_impper : 0) +
      (this.registro.dpc_impfis ? this.registro.dpc_impfis : 0) +
      (this.registro.dpc_impsis ? this.registro.dpc_impsis : 0);
  }

  obtenerTasaCambio(): void {

    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.registro.cco_fecemi)).subscribe(
      response => this.registro.cco_tipcam = response[0].tipcam,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  cambiarFecha(event): void {
    this.obtenerTasaCambio()
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.proveedorElement.open();
      this.proveedorElement.focus();
    }, 1);
  }

  establecerAno(): void {
    this.establecerSelectElement(this.mesElement);
  }

  establecerMes(): void {
    setTimeout(() => {
      this.subdiarioElement.open();
      this.subdiarioElement.focus();
    }, 1);
  }

  establecerFecha(): void {
    setTimeout(() => {
      this.emisionElement.nativeElement.select();
      this.emisionElement.nativeElement.focus();
    }, 1);
  }

  establecerEmision(): void {
    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.registro.cco_fecemi)).subscribe(
      response => {
        this.registro.cco_tipcam = response[0].tipcam;

        if (this.registro.cco_tipcam == 0) {
          setTimeout(() => {
            this.tcElement.nativeElement.focus();
            this.tcElement.nativeElement.select();
          }, 1)
          return;
        }

        setTimeout(() => {
          this.proveedorElement.open();
          this.proveedorElement.focus();
        }, 1);

      },
    )
  }

  establecerTC(): void {
    setTimeout(() => {
      this.monedaElement.open();
      this.monedaElement.focus();
    }, 1);
  }

  establecerIgv(): void {

    this.calculaTotal();

  }

  establecerGlosa(): void {
    if (this.disableImportaciones) {
      this.establecerSelectElement(this.inafectoRefElement);
    } else {
      this.establecerSelectElement(this.fobRefElement);
    }
  }

  establecerInafecto(): void {

    this.registro.valvta = this.registro.cco_impina + this.registro.cco_impafe;

    this.calculaTotal();
  }

  establecerAfecto(): void {
    this.registro.valvta = this.registro.cco_impina + this.registro.cco_impafe;

    this.calculaTotal();
  }

  establecerSelectElement(elementRef: ElementRef): void {
    setTimeout(() => {
      elementRef.nativeElement.select();
    }, 1);
  }

  establecerDeposito(): void {
    this.establecerSelectElement(this.fobRefElement);
  }

  establecerFob(): void {
    this.establecerSelectElement(this.fleteRefElement);
  }

  establecerFlete(): void {
    this.establecerSelectElement(this.seguroRefElement);
  }

  establecerSeguro(): void {
    this.establecerSelectElement(this.advaloremRefElement);
  }

  establecerAdvalorem(): void {
    setTimeout(() => {
      this.buttonAddRefElement.focus();
    }, 1);
  }

  generarAsiento(): void {

    if (!this.registro.cco_tipcam || this.registro.cco_tipcam == 0) {
      this._snackBarService.showError("Establecer Tipo de Cambio", "OK");
      return;
    }

    if (!this.registro.ccn_codccn) {
      this._snackBarService.showError("Establecer Cuenta", "OK");

      return;
    }

    if (this.registro.detalle.length > 0) {
      this._snackBarService.showError("Es necesario que la tabla se encuentre vacía.", "OK");
      return;
    }

    // let [ps_cia, ps_suc, ps_sco, ps_codtga, ps_cliente] = [this._configurationService.obtenerCompaniaCliente(), "01", this.purchaseRecord.sco_codsco, this.purchaseRecord.tga_codtga, this.purchaseRecord.cli_codcli];

    // Verificar percepcion

    let li_perigv = 0;

    const tipoDoc = this.tiposDocumento.find(item => item.TDO_CODTDO == this.registro.tdo_codtdo);

    if (!this.registro.tdo_codtdo) {
      this._snackBarService.showError("Establecer un Tipo de Documento", "OK")
      return;
    }

    li_perigv = tipoDoc.TDO_PERIGV;

    if (this.registro.cco_codcco) {
      this.registro.detalle.forEach(item => {
        if (item.tdo_codtdo == 'DPP' && item.cco_codcco) {
          this._snackBarService.showError("No se puede generar asiento, tiene cuenta corriente de Detracción ...Modifique manualmente.", "OK");
          return;
        }
      });
    }

    if (!this.registro.tga_codtga) {
      this._snackBarService.showError("Es necesario ingresar el tipo de operación", "OK");
      return;
    }

    this.registro.detalle = [];

    if (!this.registro.sco_codsco) {
      this._snackBarService.showError("Ingresar el subdiario contable", "OK");
      return;
    }

    let tipoOp = this.tiposOperacion.find(element => element.tga_codtga == "0001");

    if (tipoOp) {
      if (!tipoOp.detalle) {
        this._snackBarService.showError("Advertencia : No existe detalle de configuración de asiento automático para la transacción " + this.registro.tga_codtga, "Ok");
        return;
      }
    }

    let ps_inddom;

    if (!this.registro.cli_codcli) {
      this._snackBarService.showError("Error: No existe el proveedor del documento", "OK");
      return;
    }

    console.log('proveedores', this.proveedores)
    const prov = this.proveedores.find(element => element.cli_codcli == this.registro.cli_codcli);

    if (prov) ps_inddom = prov.cli_inddom;

    // Si la cuenta contable de provisión está configurada propiamente para la transaccion
    let ps_ccn_codmn, ps_ccn_codme;

    let tipoOpe = this.tiposOperacion.find(element => element.tga_codtga == this.registro.tga_codtga)

    const subdiario = this.subsidiarios.find(element => element.sco_codsco == this.registro.sco_codsco)

    if (tipoOpe.ccn_codmna && tipoOpe.ccn_codmex) {
      ps_ccn_codmn = tipoOpe.ccn_codmna;
      ps_ccn_codme = tipoOpe.ccn_codmex;
    } else {
      ps_ccn_codmn = subdiario.ccn_codmn;
      ps_ccn_codme = subdiario.ccn_codme;
    }

    if (this.registro.ccn_codccn) {
      const cuenta = this.cuentas.find(element => element.cuenta == this.registro.ccn_codccn)

      if (this.registro.tmo_codtmo != cuenta.moneda) {
        this._snackBarService.showError("Error: Moneda de Cuenta por Pagar no corresponde Moneda de la Cuenta Contable " + this.registro.ccn_codccn, "OK");
        return;
      }

      if (this.registro.tmo_codtmo == "SO") {
        ps_ccn_codmn = this.registro.ccn_codccn;
      } else {
        ps_ccn_codme = this.registro.ccn_codccn;
      }

    } else {
      if (this.registro.tmo_codtmo == "SO") {
        this.registro.ccn_codccn = ps_ccn_codmn;
      } else {
        this.registro.ccn_codccn = ps_ccn_codme;
      }
    }

    // ** Valida cuenta contable de provision
    if (this.registro.tmo_codtmo == "SO" && !ps_ccn_codmn) {
      this._snackBarService.showError(`Error: No existe la cuenta contable de registro de provisión
        Verifique la cuentas contables asignadas para el Subdiario ${this.registro.sco_codsco}`, "OK");
      return;
    }

    if (this.registro.tmo_codtmo == "DO" && !ps_ccn_codme) {
      this._snackBarService.showError(`Error: No existe la cuenta contable de registro de provisión.
        "Verifique la cuentas contables asignadas para el Subdiario ${this.registro.sco_codsco}`, "OK");
      return;
    }

    // * por regularizar
    let ps_igvsde = '401121' //&&tmpval.cct_igvsde
    let ps_igvndo = '401121' //&&tmpval.cct_igvndo
    let ls_ndoapl = '401121' //&&tmpval.cct_ndoapl
    let ls_ndoret = '401141' //&&tmpval.cct_ndoret
    let ls_perigv = '401117' //&&tmpval.cct_perigv

    // *** Contabiliza cuenta 42
    let ps_cuenta, pn_impnac, pn_impmex;
    let ps_tmo_mn = "SO";

    if (this.registro.tmo_codtmo == ps_tmo_mn) {
      ps_cuenta = ps_ccn_codmn
      pn_impnac = this.registro.cco_impdoc;
      pn_impmex = pn_impnac / this.registro.cco_tipcam;
    } else {
      ps_cuenta = ps_ccn_codme
      pn_impmex = this.registro.cco_impdoc;
      pn_impnac = pn_impmex * this.registro.cco_tipcam;
    }

    // *--- Valida documento
    let ps_valor = ps_cuenta.substring(0, 1);
    let ps_codtdo = this.registro.tdo_codtdo;

    console.log(this.signosctacte)
    const signo = this.signosctacte.find(item => item.tdo_codtdo == this.registro.tdo_codtdo && item.cla_codcla == ps_valor);

    if (!signo) {
      this._snackBarService.showError(`Error: Código de Documento ${ps_codtdo} no esta relacionado con la Clase Contable ${ps_valor}, cuenta ${ps_cuenta}`, "OK");
      return;
    }

    let li_item = 1;
    ps_cuenta = this.registro.ccn_codccn;

    if (ps_cuenta) {

      ps_valor = this.registro.cco_codcco;
      let itemDetalle = new DetailPurchaseRecord();
      itemDetalle.isSelected = false;
      itemDetalle.ldi_corldi = '00000' + li_item;
      itemDetalle.ccn_codccn = ps_cuenta.trim();
      itemDetalle.ccs_codccs = null;
      itemDetalle.ldi_gloldi = this.registro.cco_glocco;
      itemDetalle.tdo_codtdo = this.registro.tdo_codtdo;
      itemDetalle.ldi_docref = this.registro.cco_numdoc;
      itemDetalle.tmo_codtmo = this.registro.tmo_codtmo;
      itemDetalle.cli_codcli = this.registro.cli_codcli;
      itemDetalle.ldi_tipcam = this.registro.cco_tipcam;
      itemDetalle.ldi_inddha = (signo.sig_indsig == -1) ? 'H' : 'D';
      itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle.ldi_indcco = 'N';

      console.log('ITEM DETALLE', itemDetalle)
      this.registro.detalle.push(itemDetalle);
      this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);

    }

    //  *** Contabiliza cuenta 40 de Igv
    if (li_perigv != 1) {

      ps_cuenta = (tipoOpe.ccn_impmna) ? tipoOpe.ccn_impmna : subdiario.ccn_codimp;

    } else {
      ps_cuenta = li_perigv;
    }

    console.log('ps_cuenta cuenta 40 igv', ps_cuenta)

    let ps_indder = this.registro.dpc_indder;

    if (ps_cuenta && (ps_indder == '1' || ps_indder == '3') && this.registro.cco_impigv > 0) {
      if (this.registro.tmo_codtmo == ps_tmo_mn) {
        if (ps_indder == '1') {
          pn_impnac = this.registro.cco_impigv;
        } else {
          pn_impnac = this.registro.cco_impigv * this.registro.cco_tasigv / 100;
        }
        pn_impmex = pn_impnac / this.registro.cco_tipcam
      } else {
        if (ps_indder == '1') {
          pn_impmex = this.registro.cco_impigv;
        } else {
          pn_impmex = this.registro.cco_impigv * this.registro.cco_tasigv / 100;
        }
        pn_impnac = pn_impmex * this.registro.cco_tipcam

      }

      console.log('pn_impnac', pn_impnac)
      console.log('pn_impmex', pn_impmex)

      li_item++;
      ps_valor = this.registro.cco_codcco;
      let itemDetalle2 = new DetailPurchaseRecord();
      itemDetalle2.isSelected = false;
      itemDetalle2.ldi_corldi = `0000${li_item}`;
      itemDetalle2.ccn_codccn = ps_cuenta.trim();
      itemDetalle2.ccs_codccs = null;
      itemDetalle2.ldi_gloldi = this.registro.cco_glocco;
      itemDetalle2.tdo_codtdo = this.registro.tdo_codtdo;
      itemDetalle2.ldi_docref = this.registro.cco_numdoc;
      itemDetalle2.tmo_codtmo = this.registro.tmo_codtmo;
      itemDetalle2.ldi_tipcam = this.registro.cco_tipcam;
      itemDetalle2.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
      itemDetalle2.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle2.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle2.ldi_indcco = 'N';
      console.log('ITEM DETALLE 2', itemDetalle2)
      this.registro.detalle.push(itemDetalle2);
      this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);
    }

    // *** Contabiliza cuenta de Igv sin derecho
    ps_cuenta = ps_igvsde

    if (ps_cuenta && (ps_indder == '2' || ps_indder == '3') && this.registro.cco_impigv > 0) {
      if (this.registro.tmo_codtmo == ps_tmo_mn) {
        if (ps_indder == '2') {
          pn_impnac = this.registro.cco_impigv;
        } else {
          pn_impnac = this.registro.cco_impigv - (this.registro.dpc_afcder * this.registro.cco_tasigv / 100); // redondesar a 2
        }
        pn_impmex = pn_impnac / this.registro.cco_tipcam

      } else {
        if (ps_indder == '2') {
          pn_impmex = this.registro.cco_impigv;
        } else {
          pn_impmex = this.registro.cco_impigv - (this.registro.dpc_afcder * this.registro.cco_tasigv / 100); // redondesar a 2
        }
        pn_impnac = pn_impmex * this.registro.cco_tipcam
      }

      li_item++;
      ps_valor = this.registro.cco_codcco;
      let itemDetalle3 = new DetailPurchaseRecord();
      itemDetalle3.isSelected = false;
      itemDetalle3.ldi_corldi = `0000${li_item}`;
      itemDetalle3.ccn_codccn = ps_cuenta.trim();
      itemDetalle3.ccs_codccs = null;
      itemDetalle3.ldi_gloldi = this.registro.cco_glocco;
      itemDetalle3.tdo_codtdo = this.registro.tdo_codtdo;
      itemDetalle3.ldi_docref = this.registro.cco_numdoc;
      itemDetalle3.tmo_codtmo = this.registro.tmo_codtmo;
      itemDetalle3.ldi_tipcam = this.registro.cco_tipcam;
      itemDetalle3.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
      itemDetalle3.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle3.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle3.ldi_indcco = 'N';
      console.log('ITEM DETALLE 3', itemDetalle3)
      this.registro.detalle.push(itemDetalle3);
      this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);
    }

    let valCompra = this.registro.cco_impina + this.registro.cco_impafe;
    // *** Contabiliza cuenta 40 de Igv Para no domiciliados
    ps_cuenta = ps_igvndo;
    if (this.registro.tmo_codtmo == ps_tmo_mn) {
      pn_impnac = valCompra * this.registro.cco_tasigv / 100
      pn_impmex = pn_impnac / this.registro.cco_tipcam
    } else {
      pn_impmex = valCompra * this.registro.cco_tasigv / 100
      pn_impnac = pn_impmex * this.registro.cco_tipcam
    }

    if (ps_cuenta && ps_inddom == 0 && (pn_impnac > 0 || pn_impmex > 0) && ps_indder == '4') {
      li_item++;

      ps_valor = this.registro.cco_codcco;
      let itemDetalle4 = new DetailPurchaseRecord();

      itemDetalle4.isSelected = false;
      itemDetalle4.ldi_corldi = `0000${li_item}`;
      itemDetalle4.ccn_codccn = ps_cuenta.trim();
      itemDetalle4.ccs_codccs = null;
      itemDetalle4.ldi_gloldi = this.registro.cco_glocco;
      itemDetalle4.tdo_codtdo = this.registro.tdo_codtdo;
      itemDetalle4.ldi_docref = this.registro.cco_numdoc;
      itemDetalle4.tmo_codtmo = this.registro.tmo_codtmo;
      itemDetalle4.ldi_tipcam = this.registro.cco_tipcam;
      itemDetalle4.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
      itemDetalle4.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle4.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle4.ldi_indcco = 'N';

      console.log('ITEM DETALLE 4', itemDetalle4)

      this.registro.detalle.push(itemDetalle4);
      this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);

    }

    // *** 2.-Contabiliza cuenta 401120 de Igv Por no domiciliados
    ps_cuenta = ls_ndoapl;
    if (this.registro.tmo_codtmo == ps_tmo_mn) {
      pn_impnac = valCompra * this.registro.cco_tasigv / 100;
      pn_impmex = pn_impnac / this.registro.cco_tipcam;
    } else {
      pn_impmex = valCompra * this.registro.cco_tasigv / 100;
      pn_impnac = pn_impmex * this.registro.cco_tipcam;
    }

    if (ps_cuenta && ps_inddom == 0 && (pn_impnac > 0 || pn_impmex > 0) && ps_indder == '4') {
      li_item++;
      ps_valor = this.registro.cco_codcco;
      let itemDetalle5 = new DetailPurchaseRecord();

      itemDetalle5.isSelected = false;
      itemDetalle5.ldi_corldi = `0000${li_item}`;
      itemDetalle5.ccn_codccn = ps_cuenta.trim();
      itemDetalle5.ccn_codccn = '';
      itemDetalle5.ldi_gloldi = this.registro.cco_glocco;
      itemDetalle5.tdo_codtdo = this.registro.tdo_codtdo;
      itemDetalle5.ldi_docref = this.registro.cco_numdoc;
      itemDetalle5.tmo_codtmo = this.registro.tmo_codtmo;
      itemDetalle5.ldi_tipcam = this.registro.cco_tipcam;
      itemDetalle5.ldi_inddha = (signo.sig_indsig == -1) ? 'H' : 'D';
      itemDetalle5.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle5.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle5.ldi_indcco = 'N';

      console.log('ITEM DETALLE 5', itemDetalle5)

      this.registro.detalle.push(itemDetalle5);
      this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);
    }

    // *** Contabiliza cuenta 40 de Renta

    ps_cuenta == '40172001';

    if (this.registro.tmo_codtmo == ps_tmo_mn) {
      pn_impnac = this.registro.dpc_impren;
      pn_impmex = pn_impnac / this.registro.cco_tipcam;
    } else {
      pn_impmex = this.registro.dpc_impren;
      pn_impnac = pn_impmex * this.registro.cco_tipcam;
    }

    if (ps_cuenta && this.registro.dpc_impren > 0) {
      li_item++;
      ps_valor = this.registro.cco_codcco;

      let itemDetalle6 = new DetailPurchaseRecord();

      itemDetalle6.isSelected = false;
      itemDetalle6.ldi_corldi = `0000${li_item}`;
      itemDetalle6.ccn_codccn = ps_cuenta.trim();
      itemDetalle6.ccs_codccs = null;
      itemDetalle6.ldi_gloldi = this.registro.cco_glocco;
      itemDetalle6.tdo_codtdo = this.registro.tdo_codtdo;
      itemDetalle6.ldi_docref = this.registro.cco_numdoc;
      itemDetalle6.tmo_codtmo = this.registro.tmo_codtmo;
      itemDetalle6.ldi_tipcam = this.registro.cco_tipcam;
      itemDetalle6.ldi_inddha = (signo.sig_indsig == -1) ? 'H' : 'D';
      itemDetalle6.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle6.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle6.ldi_indcco = 'N'

      console.log('ITEM DETALLE 6', itemDetalle6)

      this.registro.detalle.push(itemDetalle6);
      this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);
    }

    let ps_codccs, pn_porcen;

    // *** Contabiliza cuenta Gasto
    let pn_valvta = valCompra;
    if (pn_valvta != 0) {
      if (tipoOpe.detalle) {
        tipoOpe.detalle.forEach(item => {
          ps_cuenta = item.ccn_codccn;
          ps_codccs = item.ccs_codccs;
          pn_porcen = (item.aau_poraau) ? item.aau_poraau : 0;

          if (this.registro.tmo_codtmo == ps_tmo_mn) {
            pn_impnac = pn_valvta * pn_porcen / 100;
            pn_impmex = pn_impnac / this.registro.cco_tipcam;
          } else {
            pn_impmex = pn_valvta * pn_porcen / 100;
            pn_impnac = pn_impmex * this.registro.cco_tipcam;
          }

          if (ps_cuenta) {
            li_item++;
            let itemDetalle7 = new DetailPurchaseRecord();

            itemDetalle7.isSelected = false;
            itemDetalle7.ldi_corldi = `0000${li_item}`;
            itemDetalle7.ccn_codccn = ps_cuenta.trim();
            itemDetalle7.ccs_codccs = ps_codccs;
            itemDetalle7.ldi_gloldi = this.registro.cco_glocco;
            itemDetalle7.tmo_codtmo = this.registro.tmo_codtmo;
            itemDetalle7.tdo_codtdo = this.registro.tdo_codtdo;
            itemDetalle7.ldi_docref = this.registro.cco_numdoc;
            itemDetalle7.cli_codcli = this.registro.cli_codcli;
            itemDetalle7.ldi_tipcam = this.registro.cco_tipcam;
            itemDetalle7.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
            itemDetalle7.ldi_impnac = roundToDecimals(pn_impnac, 2);
            itemDetalle7.ldi_impmex = roundToDecimals(pn_impmex, 2);
            itemDetalle7.ldi_indcco = 'N';
            console.log('ITEM DETALLE 7', itemDetalle7)
            this.registro.detalle.push(itemDetalle7);
            this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);
          }
        });
      }
    }

    // ***************DETRACCION*****************
    // ***Contabilidad cuenta 42 de Detracción***

    if (this.registro.dpc_impdet > 0 && this.registro.iri_codiri == '01') {
      li_item++;
      ps_cuenta = this.registro.ccn_codccn;
      pn_impnac = this.registro.dpc_impdet;
      pn_impmex = this.registro.dpc_impdet / this.registro.cco_tipcam;

      if (ps_cuenta.length > 0 && ps_cuenta) {
        ps_valor = this.registro.cco_codcco;

        let itemDetalle8 = new DetailPurchaseRecord();

        itemDetalle8.isSelected = false;
        itemDetalle8.cco_codcco = ps_valor;
        itemDetalle8.ldi_corldi = `0000${li_item}`;
        itemDetalle8.ccn_codccn = ps_cuenta.trim();
        itemDetalle8.ccs_codccs = null;
        itemDetalle8.ldi_gloldi = this.registro.cco_glocco;
        itemDetalle8.tdo_codtdo = this.registro.tdo_codtdo;
        itemDetalle8.ldi_docref = this.registro.cco_numdoc;
        itemDetalle8.tmo_codtmo = this.registro.tmo_codtmo;
        itemDetalle8.cli_codcli = this.registro.cli_codcli;
        itemDetalle8.ldi_tipcam = this.registro.cco_tipcam;
        itemDetalle8.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
        itemDetalle8.ldi_impnac = roundToDecimals(pn_impnac, 2);
        itemDetalle8.ldi_impmex = roundToDecimals(pn_impmex, 2);
        itemDetalle8.ldi_indcco = 'N';
        console.log('ITEM DETALLE 8', itemDetalle8)
        this.registro.detalle.push(itemDetalle8);
        this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);
      }

      if (subdiario.ccn_coddet && this.registro.dpc_impdet > 0) {
        li_item++;
        ps_cuenta = subdiario.ccn_coddet;

        let itemDetalle9 = new DetailPurchaseRecord();

        itemDetalle9.isSelected = false;
        itemDetalle9.ldi_corldi = `0000${li_item}`;
        itemDetalle9.ccn_codccn = ps_cuenta.trim();
        itemDetalle9.ccs_codccs = null;
        itemDetalle9.ldi_gloldi = this.registro.cco_glocco;
        itemDetalle9.tdo_codtdo = 'DPP';
        itemDetalle9.ldi_docref = this.registro.cco_numdoc;
        itemDetalle9.tmo_codtmo = 'SO';
        itemDetalle9.cli_codcli = this.registro.cli_codcli;
        itemDetalle9.ldi_tipcam = this.registro.cco_tipcam;
        itemDetalle9.ldi_inddha = 'H';
        itemDetalle9.ldi_impnac = roundToDecimals(pn_impnac, 2);
        itemDetalle9.ldi_impmex = roundToDecimals(pn_impmex, 2);
        itemDetalle9.ldi_indcco = 'N';
        console.log('ITEM DETALLE 9', itemDetalle9)
        this.registro.detalle.push(itemDetalle9);
        this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);
      }

    }

    //       ************************************************

    // *!*         ***************PERCEPCION*****************
    // *!*         *** Contabilidad cuenta 40 de Percepción***

    let ls_cuenta = this.configuracionContabilidad.ccn_permna;

    if (this.registro.iri_codiri == '02') {
      ls_cuenta = this.configuracionContabilidad.ccn_retmna;
    }
    if (this.registro.iri_codiri == '03') {
      ls_cuenta = this.configuracionContabilidad.ccn_permna;
    }

    if (this.registro.tmo_codtmo == ps_tmo_mn) {
      pn_impnac = this.registro.dpc_impper;
      pn_impmex = pn_impnac / this.registro.cco_tipcam;
    } else {
      pn_impmex = this.registro.dpc_impper;
      pn_impnac = pn_impmex * this.registro.cco_tipcam;
    }

    if (ls_cuenta && this.registro.dpc_impper > 0) {
      li_item++;
      ps_valor = this.registro.cco_codcco;

      let itemDetalle12 = new DetailPurchaseRecord();

      itemDetalle12.isSelected = false;
      itemDetalle12.ldi_corldi = `0000${li_item}`;
      itemDetalle12.ccn_codccn = ls_cuenta;
      itemDetalle12.cco_codcco = '';
      itemDetalle12.ldi_gloldi = this.registro.cco_glocco;
      itemDetalle12.cli_codcli = this.registro.cli_codcli;
      itemDetalle12.tmo_codtmo = this.registro.tmo_codtmo;
      itemDetalle12.ldi_tipcam = this.registro.cco_tipcam;
      itemDetalle12.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
      itemDetalle12.tdo_codtdo = this.registro.tdo_codtdo;
      itemDetalle12.ldi_docref = this.registro.cco_numdoc;
      itemDetalle12.ldi_impnac = roundToDecimals(pn_impnac, 2);
      itemDetalle12.ldi_impmex = roundToDecimals(pn_impmex, 2);
      itemDetalle12.ldi_indcco = 'N';

      console.log('ITEM DETALLE 12', itemDetalle12)

      this.registro.detalle.push(itemDetalle12);
      this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);
    }

    if (this.registro.dpc_impsis != 0) {
      li_item++;
      let itemDetalle13 = new DetailPurchaseRecord();

      itemDetalle13.isSelected = false;
      itemDetalle13.ldi_corldi = `0000${li_item}`;
      itemDetalle13.ccn_codccn = '64190003';
      itemDetalle13.cco_codcco = '';
      itemDetalle13.ldi_gloldi = this.registro.cco_glocco;
      itemDetalle13.cli_codcli = this.registro.cli_codcli;
      itemDetalle13.tmo_codtmo = this.registro.tmo_codtmo;
      itemDetalle13.ldi_tipcam = this.registro.cco_tipcam;
      itemDetalle13.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
      itemDetalle13.tdo_codtdo = this.registro.tdo_codtdo;
      itemDetalle13.ldi_docref = this.registro.cco_numdoc;
      itemDetalle13.ldi_impnac = (this.registro.tmo_codtmo == 'SO') ? roundToDecimals(this.registro.dpc_impsis, 2) : roundToDecimals(this.registro.dpc_impsis * this.registro.cco_tipcam, 2);
      itemDetalle13.ldi_impmex = (this.registro.tmo_codtmo == 'SO') ? roundToDecimals(this.registro.dpc_impsis / this.registro.cco_tipcam, 2) : roundToDecimals(this.registro.dpc_impsis, 2);
      itemDetalle13.ldi_indcco = 'N';
      console.log('ITEM DETALLE 13', itemDetalle13)

      this.registro.detalle.push(itemDetalle13);
      this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);
    }

    if (this.registro.dpc_impfis != 0) {
      li_item++;
      let itemDetalle14 = new DetailPurchaseRecord();

      itemDetalle14.isSelected = false;
      itemDetalle14.ldi_corldi = `0000${li_item}`;
      // itemDetalle14.ccn_codccn = '601802';
      itemDetalle14.ccn_codccn = '64190002';
      itemDetalle14.cco_codcco = '';
      itemDetalle14.ldi_gloldi = this.registro.cco_glocco;
      itemDetalle14.cli_codcli = this.registro.cli_codcli;
      itemDetalle14.tmo_codtmo = this.registro.tmo_codtmo;
      itemDetalle14.ldi_tipcam = this.registro.cco_tipcam;
      itemDetalle14.ldi_inddha = (signo.sig_indsig == -1) ? 'D' : 'H';
      itemDetalle14.tdo_codtdo = this.registro.tdo_codtdo;
      itemDetalle14.ldi_docref = this.registro.cco_numdoc;
      itemDetalle14.ldi_impnac = (this.registro.tmo_codtmo == 'SO') ? roundToDecimals(this.registro.dpc_impfis, 2) : roundToDecimals(this.registro.dpc_impfis * this.registro.cco_tipcam, 2);
      itemDetalle14.ldi_impmex = (this.registro.tmo_codtmo == 'SO') ? roundToDecimals(this.registro.dpc_impfis / this.registro.cco_tipcam, 2) : roundToDecimals(this.registro.dpc_impfis, 2);
      itemDetalle14.ldi_indcco = 'N';
      console.log('ITEM DETALLE 14', itemDetalle14)

      this.registro.detalle.push(itemDetalle14);
      this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);
    }

    this.establecerDebeHaber();

    // if (this.registro.detalle.length > 0) {
    //   setTimeout(() => {
    //     this.selectCosto.first.open();
    //     this.selectCosto.first.focus();
    //   }, 1);
    // }

  }

  ajusteDiferencia(): void {
    const diferencia_mn = this.montosDebeHaber[2].mn;
    const diferencia_me = this.montosDebeHaber[2].me;

    console.table({ diferencia_mn, diferencia_me })

    if (this.registro.detalle.length == 0) {
      this._snackBarService.showError("Agregar items en el detalle", "OK");
      return;
    }

    if (!((Math.abs(diferencia_mn) <= 0.05 && Math.abs(diferencia_mn) > 0) || (Math.abs(diferencia_me) <= 0.05 && Math.abs(diferencia_me) > 0))) {
      this._snackBarService.showError("La diferencia tiene que ser > 0 y <= a 0.05", "OK");
      return;
    }

    let ps_tmo_mn;
    let pn_impnac;
    let pn_impmex;
    let ps_codtdo;
    let ps_codccs;
    let ps_cuenta;
    let ls_cad;

    ps_tmo_mn = this.registro.tmo_codtmo;

    pn_impnac = Math.abs(diferencia_mn);
    pn_impmex = Math.abs(diferencia_me);

    ps_codtdo = (diferencia_mn < 0 || diferencia_me < 0) ? "AJP" : "AJG";
    ps_codccs = "";

    this._contabilidadService.configuraTipoDocCtaContable(ps_codtdo).subscribe(
      response => {
        console.log('response', response)
        ps_cuenta = response[0].ccn_codmn;
        ps_codccs = response[0].ccs_codccs;

        if (ps_cuenta) {

          let detailItem = new DetailPurchaseRecord();

          detailItem.ldi_inddha = (diferencia_mn < 0 || diferencia_me < 0) ? "D" : "H";
          detailItem.ldi_tcameu = 0;
          detailItem.ldi_inddes = 0;
          detailItem.fca_codfca = "";
          detailItem.ldi_impsal = 0;
          detailItem.ldi_indccl = 0;
          detailItem.ldi_indcco = "N";
          detailItem.ldi_inddes = 0;
          detailItem.ldi_mesccl = "";
          detailItem.sco_codsco = this.registro.sco_codsco;
          detailItem.suc_codsuc = "01";
          detailItem.tdo_docref = "";
          detailItem.vde_codvde = "";

          detailItem.ccn_codccn = ps_cuenta.trim();
          detailItem.ldi_gloldi = this.registro.cco_glocco;
          detailItem.tmo_codtmo = ps_tmo_mn;
          detailItem.tdo_codtdo = ps_codtdo;
          detailItem.ldi_docref = this.registro.cco_numdoc;
          detailItem.ldi_tipcam = 0;
          detailItem.ldi_inddha = (ps_codtdo == "AJP") ? "D" : "H";
          detailItem.ldi_impnac = roundToDecimals(pn_impnac, 2);
          detailItem.ldi_impmex = roundToDecimals(pn_impmex, 2);
          detailItem.ldi_indcco = "N";

          if (ps_codtdo == "AJP" && ps_codccs > 0) {
            detailItem.ccs_codccs = ps_codccs;
          }

          this.registro.detalle.push(detailItem);

          this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);

          this.registro.detalle.forEach((element, key) => {
            element.ldi_corldi = (key + 1 > 9) ? `000${key + 1}` : `0000${key + 1}`;
          })

          // setTimeout(() => {
          //   this.inputDescripcion.last.nativeElement.focus();
          //   this.inputDescripcion.last.nativeElement.slect();
          // }, 1);

          this.establecerDebeHaber()

        }
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )

  }

  borrarAsientosDestino(): void {
    console.log('borrar destino')
    let detalleNuevo = [];
    this.registro.detalle.forEach(item => {
      if (item.ldi_corldi[0] != '6' && item.ldi_corldi[0] != '7') {
        detalleNuevo.push(item);
      }
    });

    this.registro.detalle = detalleNuevo;
    this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);
  }

  imprimir(): void {
    const otherData = {
      ruc: this._configurationService.obtenerClienteId(),
      subdiarios: this.subsidiarios,
      nombre_cliente: this._configurationService.obtenerNombreCompaniaCliente()
    }
    generarPdfRCAsientoContable(otherData, this.registro);
  }

  establecerAnoDua(): void {
    setTimeout(() => {
      this.ordenCompraElement.focus();
      this.ordenCompraElement.open();
    }, 1);
  }

  establecerSerie(): void {
    this.registro.cco_numdoc = this.registro.sad_codsad + this.registro.cco_numdoc.substring(this.registro.sad_codsad.length + 1, this.registro.cco_numdoc.length + 1);

    setTimeout(() => {
      this.nroDocElement.nativeElement.focus();
      this.nroDocElement.nativeElement.focus();
    }, 1);
  }

  autocompletarNroDoc(): void {

    if (!this.registro.sad_codsad) {
      this._snackBarService.showError('Establecer numero de serie', 'Ok');
      return;
    }

    if (this.registro.cco_numdoc <= 4) {
      this._snackBarService.showError('Escribir el número de documento', 'Ok');
      return;
    }

    let completado = '';

    if(this.registro.cco_numdoc.includes(this.registro.sad_codsad)) {
      const letras = this.registro.cco_numdoc.substring(4)
      console.log('letras', letras)
      completado = this.completarDigitosNumDoc(letras);
      console.log('letras', completado)
    } else {
      completado = this.completarDigitosNumDoc(this.registro.cco_numdoc);
    }
    console.log('this.registro.cco_numdoc.slice(4)', this.registro.cco_numdoc.slice(4))

    this.registro.cco_numdoc = this.registro.sad_codsad + completado;
    this.registro.detalle.forEach(item => {
      item.ldi_docref = this.registro.cco_numdoc;
    })

  }

  autocompletarNroDocRef(): void {

    if (this.registro.cco_numref.length <= 4) {
      this._snackBarService.showError('Escribir los 4 primeros digitos', 'Ok');
      return;
    }

    const completado = this.completarDigitosNumDoc(this.registro.cco_numref.slice(4));

    this.registro.cco_numref = this.registro.cco_numref.substring(0, 4) + completado;


    // Establecer fecha de referencia
    this._comprasService.listarRegistrosDeCompraPeriodo(this.year, this.month);

  }

  establecerNroDoc(event): void {

    // const zeroPad = (num, places) => String(num).padStart(places, '0')

    // let numero = zeroPad(this.registro.cco_numdoc.substring(4, this.registro.cco_numdoc.length + 1), 8); // "000005"

    // this.registro.cco_numdoc = this.registro.cco_numdoc.substring(0, 5) + numero;

  }

  establecerVcto(): void {
    setTimeout(() => {
      this.cuentaElement.open();
      this.cuentaElement.focus();
    }, 1);
  }

  cambiarFechaEmision(event): void {
    this._ventasService.obtenerTipoCambio(formatDateWithDash(event.value._d)).subscribe(
      response => {
        this.registro.cco_tipcam = response[0].tipcam;

        if (this.registro.cco_tipcam == 0) {
          setTimeout(() => {
            this.tcElement.nativeElement.focus();
            this.tcElement.nativeElement.select();
          }, 1)
        } else {
          setTimeout(() => {
            this.monedaElement.open();
            this.monedaElement.focus();
          }, 1);
        }
      },
    )
  }

  cambiarFechaVcto(event): void {
    setTimeout(() => {
      this.cuentaElement.open();
      this.cuentaElement.focus();
    }, 1);
  }

  desabilitarImportaciones(): void {
    this.registro.dpc_impfob = 0;
    this.registro.dpc_impfle = 0;
    this.registro.dpc_impseg = 0;
    this.registro.dpc_impcif = 0;
    this.registro.dpc_impadv = 0;

    this.disableImportaciones = true;
  }

  habilitarImportaciones(): void {
    this.registro.dpc_impfob = 0;
    this.registro.dpc_impfle = 0;
    this.registro.dpc_impseg = 0;
    this.registro.dpc_impcif = 0;
    this.registro.dpc_impadv = 0;

    this.disableImportaciones = false;
  }

  seleccionarTipoDoc(): void {

    let tipo = this.tiposDocumento.find(item => item.TDO_CODTDO == this.registro.tdo_codtdo);
    this.indDocReferencia = tipo.tdo_indref;

    // Restablecer a null valores del tipo doc referencia
    if (this.indDocReferencia == 0) {
      this.registro.tdo_docref = null;
      this.registro.cco_numref = null;
      this.registro.cco_fecref = null;
    }

    if (this.registro.tdo_codtdo) {
      console.log('ingresaste', this.registro.tdo_codtdo)



      console.log('tipo de documento seleccionado', tipo);

      (tipo.tdo_indext == 1) ? this.habilitarImportaciones() : this.desabilitarImportaciones();
      (this.registro.tdo_codtdo == "DUA") ? this.disableAnoDua = false : this.disableAnoDua = true;
      console.log('disableAnoDua', this.disableAnoDua)
      this._almacenService.listarSerieDocumentos(this.registro.tdo_codtdo).subscribe(
        series => {
          if (series && series.length > 0) {
            this.registro.cco_numdoc = `${series[0].sdo_codsdo}`;

            this.registro.detalle.forEach(item => item.ldi_docref = this.registro.cco_numdoc)

            this.registro.sad_codsad = series[0].sdo_codsdo;
            setTimeout(() => {
              this.nroDocElement.nativeElement.focus();
              this.nroDocElement.nativeElement.focus();
            }, 1);
          } else {
            setTimeout(() => {
              this.serieElement.nativeElement.focus();
              this.serieElement.nativeElement.focus();
            }, 1);
          }

        },
        error => this._snackBarService.showError(error.error.msg, "OK")

      )
    } else {
      this.nroDocElement.nativeElement.focus()
      this.nroDocElement.nativeElement.select()
    }

    this.registro.detalle.forEach(item => item.tdo_codtdo = this.registro.tdo_codtdo);

  }

  seleccionarCondicionPago(event): void {
    const condicion = this.condiciones.find(item => item.CPA_CODCPA == this.registro.cpa_codcpa);

    if (condicion) {
      this.registro.cco_fecven = addDaysToDate(new Date(this.registro.cco_fecemi), condicion.CPA_DIAPLA)
    }

    this.establecerSelectElement(this.vctoElement);

  }

  nuevoRegistro(): void {
    this.registro = new RegistroCompra();
    this.uid = '0';
    this.initialize();

    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.registro.cco_fecemi)).subscribe(
      tipoCambio => {
        this.registro.cco_tipcam = tipoCambio[0].tipcam;
      },
      error => {
        this._snackBarService.showError('Error al obtener tipo de cambio', 'OK');
      }
    )
  }

  seleccionarDerDebito(event): void {
    setTimeout(() => {
      this.tipoOperacionElement.open();
      this.tipoOperacionElement.focus();
    }, 1);
  }

  seleccionarOrdenCompra(event): void {
    setTimeout(() => {
      this.condicionElement.open();
      this.condicionElement.focus();
    }, 1);
  }

  seleccionarTipoRenta(event): void {
    if (this.disableImportaciones) {
      this.establecerSelectElement(this.inafectoRefElement);
    } else {
      this.establecerSelectElement(this.fobRefElement);
    }
  }

  seleccionarTipoDocRef(): void {

    if (this.registro.tdo_codtdo == this.registro.tdo_docref) {
      this.registro.tdo_docref = null;
      this._snackBarService.showError('Seleccionar un documento diferente al tipo de documento', 'Ok');
      return
    };

    this._almacenService.listarSerieDocumentos(this.registro.tdo_docref).subscribe(
      series => {
        this.registro.cco_numref = series[0].sdo_codsdo;
        this.calcularFechaReferencia();
      },
      error => this._snackBarService.showError(error.error.msg, "OK")
    )

  }

  calcularFechaReferencia(): void {

    if (this.registro.cco_numref && this.registro.tdo_docref) {

      const params: IParam[] = [
        {
          param: 'cliente',
          value: this.registro.cli_codcli
        },
        {
          param: 'tipdocumento',
          value: this.registro.tdo_docref
        },
        {
          param: 'numerodocumento',
          value: this.registro.cco_numref
        },
      ]

      this._comprasService.listarRegistrosDeCompra({ params }).subscribe(
        response => {
          this.registro.cco_fecref = response[0].cco_fecemi;
          this.registro.cco_tipcam = response[0].cco_tipcam;

          this._snackBarService.showSuccess('Documento encontrado.', 'Ok')
        },
        error => this._snackBarService.showError(error.error.msg, "OK")
      )
    }

  }

  seleccionarTipoOperacion(): void {

    console.log('tipoOperacion', this.registro.tga_codtga)

    const tipoOpe = this.tiposOperacion.find(item => item.tga_codtga == this.registro.tga_codtga);

    console.log('tipoOpe', tipoOpe)
    if (tipoOpe) {

      if (tipoOpe && tipoOpe.ccn_codmna && tipoOpe.ccn_codmex && tipoOpe.ccn_codmna != "" && tipoOpe.ccn_codmex != "") {

        (this.registro.tmo_codtmo == "SO") ? this.registro.ccn_codccn = tipoOpe.ccn_codmna : this.registro.ccn_codccn = tipoOpe.ccn_codmex;
      } else {
        let subdiario = this.subsidiarios.find(item => item.sco_codsco == this.registro.sco_codsco)
        if (subdiario && subdiario.ccn_codmn != "" && subdiario.ccn_codme != "" && subdiario.ccn_codme && subdiario.ccn_codmn) {
          (this.registro.tmo_codtmo == "SO") ? this.registro.ccn_codccn = subdiario.ccn_codmn : this.registro.ccn_codccn = subdiario.ccn_codme;
        }
      }

    }

    if (tipoOpe) {
      this.registro.t30_codt30 = tipoOpe["T30_CODT30"];
      console.log('this.registro.t30_codt30', this.registro.t30_codt30)
    }

    // this.registro.t30_codt30 = '5';

    // setTimeout(() => {
    //   this.tipoDocElement.open();
    //   this.tipoDocElement.focus();
    // }, 1);
  }

  calculoImportesMN(row: DetailPurchaseRecord): void {
    row.ldi_impmex = roundToDecimals(Number(row.ldi_impnac) / Number(row.ldi_tipcam), 2);
    this.establecerDebeHaber();
  }

  calculoImportesME(row: DetailPurchaseRecord): void {
    row.ldi_impnac = roundToDecimals(Number(row.ldi_impmex) * Number(row.ldi_tipcam), 2);
    this.establecerDebeHaber();
  }

  cambioTotalImporte(): void {
    this.registro.cco_impdoc = this.registro.cco_impina + this.registro.cco_impafe + this.registro.cco_impigv;
  }

  cambioCondicion(condicion): void {
    this.registro.cpa_codcpa = condicion.codigo;
    this.registro.cco_fecven = addDaysToDate(new Date(this.registro.cco_fecemi), condicion.dias + 1)
  }

  cambiarTipoRetencion(tipo): void {

    console.log('tipo', tipo)
    console.log('tempTiposImpRetencion', this.tempTiposImpRetencion)

    if (tipo.value == "03") {
      this.esPercepcion = true;
      this.tiposImpRetencion = this.tempTiposImpRetencion.filter(item => item.codigo == "PER")
      this.tituloTasa = "Percepción";
      this.registro.dpc_tasdet = 0;
      this.registro.dpc_impdet = 0;
      this.registro.dpc_impper = 0;
      // this.registro.dpc_depdet = 0;
      this.registro.dpc_fecdet = "";
      this.registro.tim_codtim = "PER";
      this.calculaTotal();

      const tipoImpuesto = this.tiposImpRetencion.find(item => this.registro.tim_codtim == item.codigo);
      this.registro.dpc_tasdet = tipoImpuesto.porc_det;
      this.registro.dpc_impper = parseFloat((this.registro.cco_impdoc * this.registro.dpc_tasdet / 100).toFixed(2));

      this.calculaTotal();

    } else if (tipo.value == "00") {

      this.registro.tim_codtim = "NA";
      this.registro.dpc_tasdet = 0;
      this.registro.dpc_impdet = 0;
      this.registro.dpc_impper = 0;
      // this.registro.dpc_depdet = 0;
      this.registro.dpc_fecdet = new Date();
      this.tiposImpRetencion = [];
      this.calculaTotal();
    } else if (tipo.value == "02") {
      this.tituloTasa = "Retención";
      this.tiposImpRetencion = this.tempTiposImpRetencion.filter(item => item.codigo == "RET")
      this.registro.dpc_tasdet = 0;
      this.registro.dpc_impdet = 0;
      this.registro.dpc_impper = 0;
      // this.registro.dpc_depdet = 0;
      this.registro.dpc_fecdet = new Date();
      this.registro.tim_codtim = "RET";

      this.calculaTotal();

      const tipoImpuesto = this.tiposImpRetencion.find(item => this.registro.tim_codtim == item.codigo);
      this.registro.dpc_tasdet = tipoImpuesto.porc_det;
      this.registro.dpc_impper = (this.registro.cco_impdoc * this.registro.dpc_tasdet / 100);
      this.calculaTotal();

    } else {
      this.esPercepcion = false;
      // this.tiposImpRetencion = this.tempTiposImpRetencion.filter(item => item.codigo != "PER");
      this.tiposImpRetencion = this.tempTiposImpRetencion;
      // this.tiposImpRetencion = this.tipos

      this.tituloTasa = "Detracción";

      this.registro.dpc_tasdet = 0;
      this.registro.dpc_impdet = 0;
      this.registro.dpc_impper = 0;
      // this.registro.dpc_depdet = 0;
      this.registro.dpc_fecdet = new Date();
      // this.tipoImpuestoRetElement.open();
      // this.tipoImpuestoRetElement.focus();

      this.calculaTotal();
    }
  }

  cambiarTipoImpuestoRetencion(): void {
    if (this.registro.cco_impafe > 0) {
      const tipoImpuesto = this.tiposImpRetencion.find(item => this.registro.tim_codtim == item.codigo);
      this.registro.dpc_tasdet = tipoImpuesto.porc_det;
      if (this.registro.tmo_codtmo == 'DO') {
        this.registro.dpc_impdet = ((this.registro.cco_impdoc * this.registro.cco_tipcam) * this.registro.dpc_tasdet / 100);
        return;
      }
      this.registro.dpc_impdet = (this.registro.cco_impdoc * this.registro.dpc_tasdet / 100);
      return;
    }

    // this._snackBarService.showError('El afecto tiene que ser mayor que 0', 'Ok');
  }

  applyFilter(event): void {
    this.dataSource = searchInTable(event, this.dataSource);
  }

  applyFilterOC(event): void {
    this.dataSource2 = searchInTable(event, this.dataSource2);
  }

  esValidoDetalle(): boolean {

    let esValido = true;

    this.registro.detalle.forEach(item => {

      const cuenta = this.cuentasTotales.find(cuenta => cuenta.cuenta == item.ccn_codccn);
      console.log('cuenta', cuenta)
      if (cuenta.ind_cencos == 1 && !item.ccs_codccs) {
        this._snackBarService.showError(`Es obligatorio ingresar el centro de costo para la cuenta ${cuenta.cuenta}`, 'OK');
        esValido = false
      }

    });

    return esValido;
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;
    this.registro.detalle.forEach((element, key) => {
      element.ldi_corldi = (key + 1 > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    this.establecerEntero();

    if (!this.registro.tdo_codtdo) {
      this._snackBarService.showError('Seleccionar un tipo de documento', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (!this.registro.ocm_numocm) {
      this._snackBarService.showError('Seleccionar una orden de compra', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (!this.registro.cpa_codcpa && this.configuracionTecnica.s72_indcpa == 1) {
      this._snackBarService.showError('Seleccionar una condición de pago', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (!this.registro.tga_codtga) {
      this._snackBarService.showError('Seleccionar un tipo de operación', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (!this.registro.t30_codt30) {
      this._snackBarService.showError('Seleccionar una clasificación de bien y servicio', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (!this.registro.ccn_codccn) {
      this._snackBarService.showError('Seleccionar una cuenta contable', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (!this.registro.sco_codsco) {
      this._snackBarService.showError('Seleccionar un subdiario contable', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (!this.registro.sad_codsad) {
      this._snackBarService.showError('Establecer un número de serie', 'Ok');
      this.loaderReg = false;
      return;
    }

    if (this.registro.cco_numdoc >= 12) {
      this._snackBarService.showError('El número de documento tiene que tener 12 digitos', 'Ok');
      this.loaderReg = false;
      return;
    }

    if(!this.esValidoDetalle()) {
      this.loaderReg = false;
      return;
    }

    // console.log(this.registro);
    // this.loaderReg = false;
    // return;


    this.uid == '0' ? this.crearRegistroCompra(f) : this.editarRegistroCompra(f);
  }

  crearRegistroCompra(f: NgForm): void {

    console.log('registro compra', this.registro)

    this.sendForm$ = this._comprasService.crearRegistroDeCompra(this.registro).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, `Registro de Compra Creado Exitosamente. Nro Asiento: ${response.cco_codcco}`, '400px', '', '');
        this.registro.cco_codcco = response.cco_codcco;
        this.uid = response.cco_codcco;
        this.loaderReg = false;
      },
      error => {
        this.loaderReg = false;
        console.log(error)
        this._snackBarService.showError(error.error.msg, "OK")
      }
    )

  }

  editarRegistroCompra(f: NgForm): void {

    this.sendForm$ = this._comprasService.editarRegistroDeCompra(this.registro).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Compra Actualizada Exitosamente', '350px', '', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK")
        this.loaderReg = false;
      }
    )
  }

  anularRegistroCompra(): void {
    this.loaderAnular = true;
    this.registro.cco_indsta = '0';
    this.sendForm$ = this._comprasService.editarRegistroDeCompra(this.registro).subscribe(
      response => {
        this.loaderAnular = false;
        this._dialogService.openDialog(SuccessComponent, 'Registro de Compra Anulado Exitosamente', '350px', '', '');
      },
      error => {
        this._snackBarService.showError(error.message, 'Ok');
        this.loaderAnular = false;
      }
    )
  }

  agregarItem(): void {

    if (!this.registro.cco_tipcam || this.registro.cco_tipcam == 0) {
      this._snackBarService.showError("Establecer Tipo de Cambio", "OK");
      return;
    }

    if (!this.registro.ccn_codccn) {
      this._snackBarService.showError("Establecer Cuenta", "OK");

      return;
    }

    const detailItem = new DetailPurchaseRecord();

    detailItem.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    detailItem.ano_codano = this.year;
    detailItem.mes_codmes = this.month;

    detailItem.ldi_anoccl = this.year;
    detailItem.ldi_mesccl = this.month;
    detailItem.ldi_fecemi = this.registro.cco_fecemi;
    detailItem.ldi_fecven = this.registro.cco_fecven;

    detailItem.sco_codsco = this.registro.sco_codsco;

    detailItem.ldi_tipcam = this.registro.cco_tipcam;
    detailItem.ldi_gloldi = this.registro.cco_glocco;
    detailItem.ldi_tipcam = this.registro.cco_tipcam;
    detailItem.tdo_codtdo = this.registro.tdo_codtdo;
    detailItem.ldi_docref = this.registro.cco_numdoc;

    this.registro.detalle.push(detailItem);

    this.registro.detalle.forEach((element, key) => {
      element.ldi_corldi = (key + 1 > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);

    // setTimeout(() => {
    //   // this.cuentaTotalSelect.open();
    //   this.selectCuentas.last.open();
    //   this.selectCuentas.last.focus();
    // }, 1);

  }

  quitarItem(): void {

    let row = this.registro.detalle.find(item => item == this.selected);

    console.log('fila seleccionada', row);

    if (!row && this.registro.detalle.length > 0) row = this.registro.detalle[0];

    let details = [];
    this.registro.detalle.forEach(element => {
      if (element.ldi_corldi != row.ldi_corldi) {
        details.push(element)
      }
    });

    this.registro.detalle = details;

    this.registro.detalle.forEach((element, key) => {
      element.ldi_corldi = (key + 1 > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    this.dataSource = fillTable(details, this.paginator, this.sort);

    this.selected = null;

    this.establecerDebeHaber()
  }

  insertarItem(): void {

    if (!this.registro.cco_tipcam || this.registro.cco_tipcam == 0) {
      this._snackBarService.showError("Establecer Tipo de Cambio", "OK");
      return;
    }

    if (!this.registro.ccn_codccn) {
      this._snackBarService.showError("Establecer Cuenta", "OK");

      return;
    }

    const registro = new DetailPurchaseRecord();

    registro.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    registro.ano_codano = this.year;
    registro.mes_codmes = this.month;

    registro.ldi_anoccl = this.year;
    registro.ldi_mesccl = this.month;
    registro.tmo_codtmo = this.registro.tmo_codtmo;
    registro.ldi_fecemi = this.registro.cco_fecemi;
    registro.ldi_fecven = this.registro.cco_fecven;
    registro.sco_codsco = this.registro.sco_codsco;
    registro.ldi_tipcam = this.registro.cco_tipcam;
    registro.ldi_gloldi = this.registro.cco_glocco;
    registro.tdo_codtdo = this.registro.tdo_codtdo;
    registro.ldi_docref = this.registro.cco_numdoc;

    let i = 0;

    if (this.selected) {
      this.registro.detalle.forEach((item, index) => {
        if (item.ldi_corldi == this.selected.ldi_corldi) i = index;
      })

      this.registro.detalle.splice(i, 0, registro);
    } else {
      this.registro.detalle.push(registro);
      this.registro.detalle = this.registro.detalle.reverse();
    }

    this.registro.detalle.forEach((element, key) => {
      console.log('ids', key)
      element.ldi_corldi = (key + 1 > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    console.log('detalle', this.registro.detalle)

    this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);

  }

  seleccionarSubdiario(event): void {

    const subdiario = this.subsidiarios.find(item => item.sco_codsco == event);
    console.log(subdiario)
    this.registro.ccn_codccn = this.registro.tmo_codtmo == 'SO' ? subdiario.ccn_codmn : subdiario.ccn_codme;

    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.fechaElement.nativeElement.focus();
      this.fechaElement.nativeElement.select();
    }, 1);

  }

  seleccionarProveedor(): void {
    console.log('SELECCIONAR PROVEEDOR', this.registro.cli_codcli)

    const proveedor = this.proveedores.find(item => item.cli_codcli == this.registro.cli_codcli);

    console.log('PROVEEDOR', proveedor)

    // this.ordenesCompra = this.ordenesCompraTotales.filter(item => item.cli_codcli == this.registro.cli_codcli || item.ocm_indgen == 1);

    this.registro.cli_coddoc = this.registro.cli_codcli;

    this._ordenCompraService.obtenerOrdenesCompraPorCliente(this.registro.cli_codcli).subscribe(
      ordenes => {
        this.ordenesCompra = [...this.ordenesCompra, ...ordenes];
        console.log('ordenes totales', this.ordenesCompra)
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
      }
    )
  }

  seleccionarMoneda(event): void {

    const subdiario = this.subsidiarios.find(item => item.sco_codsco == this.registro.sco_codsco);

    this.registro.ccn_codccn = this.registro.tmo_codtmo == 'SO' ? subdiario.ccn_codmn : subdiario.ccn_codme;

    // setTimeout(() => {
    //   this.tipoOperacionElement.open();
    //   this.tipoOperacionElement.focus();
    // }, 1);
  }

  seleccionarCuenta(event): void {
    // setTimeout(() => {
    //   this.glosaElement.nativeElement.focus();
    //   this.glosaElement.nativeElement.select();
    // }, 1);
  }

  seleccionarClasificacion(event): void {
    // setTimeout(() => {
    //   this.tipoRentaElement.open();
    //   this.tipoRentaElement.focus();
    // }, 1);
  }

  establecerDebeHaber(): void {
    let montosDebeHaber: IMontoDebeHaber[] = this.montosDebeHaber;

    montosDebeHaber[0].mn = 0;
    montosDebeHaber[0].me = 0;

    montosDebeHaber[1].mn = 0;
    montosDebeHaber[1].me = 0;

    montosDebeHaber[2].mn = 0;
    montosDebeHaber[2].me = 0;

    this.registro.detalle.forEach(element => {

      if (element.ldi_inddha == "D") {
        montosDebeHaber[0].mn += Number(element.ldi_impnac);
        montosDebeHaber[0].me += Number(element.ldi_impmex);
      } else {
        montosDebeHaber[1].mn += Number(element.ldi_impnac);
        montosDebeHaber[1].me += Number(element.ldi_impmex);
      }
    });

    montosDebeHaber[2].mn = montosDebeHaber[0].mn - montosDebeHaber[1].mn;
    montosDebeHaber[2].me = montosDebeHaber[0].me - montosDebeHaber[1].me;

    this.montosDebeHaber = montosDebeHaber;

  }

  seleccionarDH(value) {
    this.establecerDebeHaber();
  }

  agregarOrdenCompra(): void {
    let ocm_numocm = "00000000000";
    let existe = false;

    this.registro.ordenes.forEach(element => {
      existe = (element.ocm_numocm == ocm_numocm) ? true : false;
    });

    if (!existe) {
      let orden = this.ordenesCompra.find(element => ocm_numocm == element.ocm_numocm);
      let ordenCompra = new OrdersPurchaseRecord()

      ordenCompra.cia_codcia = this._configurationService.obtenerCompaniaCliente();
      ordenCompra.ocm_numocm = orden.ocm_numocm;
      this.registro.ordenes.push(ordenCompra);

      this.dataSource2 = fillTable(this.registro.ordenes, this.paginator2, this.sort2);
    }
  }

  eliminarOrdenCompra(id): void {
    let ordenes = [];
    this.registro.ordenes.forEach(element => {
      if (element.ocm_numocm != id) {
        ordenes.push(element);
      }
    });

    this.registro.ordenes = ordenes;
    this.dataSource2 = fillTable(ordenes, this.paginator2, this.sort2);
  }

  documentosPorPagar(): void {
    const dialogRef = this.dialog.open(CuentaPagarDialogComponent, {
      width: '1000px',
      data: { cli_codcli: (this.registro.cli_codcli) ? this.registro.cli_codcli : '', periodo: `${this.year}${this.month}`, clientes: this.proveedores }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        result.forEach(item => {
          let detailItem = new DetailPurchaseRecord();

          detailItem.cia_codcia = this._configurationService.obtenerCompaniaCliente();
          detailItem.ano_codano = this.year;
          detailItem.mes_codmes = this.month;
          detailItem.ldi_corldi = `0000${this.registro.detalle.length + 1}`;
          detailItem.tmo_codtmo = item.moneda;
          detailItem.ldi_inddha = "D";
          detailItem.tdo_codtdo = item.tip_doc;
          detailItem.ldi_impnac = (item.moneda == "SO") ? item.importe_saldo : item.importe_saldo / item.tipo_cambio;
          detailItem.ldi_impmex = (item.moneda == "SO") ? item.importe_saldo * item.tipo_cambio : item.importe_saldo;
          detailItem.ldi_tcameu = 0;
          detailItem.ldi_inddes = 0;
          detailItem.fca_codfca = "";
          detailItem.ldi_anoccl = item.ano;
          detailItem.ldi_mesccl = item.mes;
          detailItem.ldi_fecemi = item.fec_emision;
          detailItem.ldi_fecven = item.fec_vencimiento;
          detailItem.ldi_impsal = 0;
          detailItem.ldi_indccl = 0;
          detailItem.ldi_indcco = "N";
          detailItem.ldi_inddes = 0;
          detailItem.ldi_mesccl = "";
          detailItem.sco_codsco = item.subdiario;
          detailItem.suc_codsuc = "01";
          detailItem.tdo_docref = "";
          detailItem.vde_codvde = "";
          detailItem.cco_codcco = item.cta_cte;
          detailItem.ccn_codccn = item.cuenta;
          detailItem.cli_codcli = item.auxiliar;
          detailItem.ldi_docref = item.nro_docu;
          detailItem.ldi_tipcam = item.tipo_cambio;
          detailItem.ldi_gloldi = item.glosa;

          this.registro.detalle.push(detailItem);
          this.dataSource = fillTable(this.registro.detalle, this.paginator, this.sort);

          setTimeout(() => {
            this.selectCosto.first.open();
          }, 1);
        });
      }
    })
  }

  resetAutocompleteDocument(): void {
    this.registro.cli_codcli = null;
    this.registro.sad_codsad = null;
    this.registro.cco_numdoc = null;
    this.registro.tdo_codtdo = null;
    this.registro.cco_tasigv = 18;

    this.registro.cco_impina = 0;
    this.registro.cco_impafe = 0;
    this.registro.cco_fecemi = new Date();
    this.registro.cco_fecven = new Date();
    this.registro.tmo_codtmo = null;
    this.registro.cco_impigv = 0;
    this.registro.valvta = 0;
    this.registro.cco_impdoc = 0;

  }

  calcularDiferenciaEnDias(fecha1: Date, fecha2: Date) {
    const unDiaEnMilisegundos = 24 * 60 * 60 * 1000; // Milisegundos en un día
    const tiempo1 = fecha1.getTime(); // Obtener el tiempo en milisegundos
    const tiempo2 = fecha2.getTime();

    const diferenciaEnMilisegundos = Math.abs(tiempo2 - tiempo1);
    const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / unDiaEnMilisegundos);

    return diferenciaEnDias;
  }

  calcularIGV(): void {
    const valvta = this.registro.cco_impdoc - this.registro.cco_impigv
    this.registro.cco_tasigv = (this.registro.cco_impigv / valvta) * 100;
    console.log('igv', this.registro.cco_tasigv)
  }


  generarFechaUTC(fecha): Date {
    const date = new Date(fecha)

    const day = date.getUTCDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return new Date(year, month, day);
  }

  generarFechaFormato(fecha: string): Date {
    const [day, month, year] = fecha.split('/');

    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }


  completarDigitosNumDoc(numdoc: string): string {
    if (numdoc.length < 8) {
      const digitosFaltantes = 8 - numdoc.length;

      for (let index = 0; index < digitosFaltantes; index++) {
        numdoc = '0' + numdoc;
      }

      return numdoc;
    }

    return numdoc;
  }

  verMasOpciones(): void {

    if (!this.registro.sco_codsco) {
      this._snackBarService.showError('Seleccione un subdiario', 'Ok');
      return;
    }

    this.documentToUpload.isNew = this.uid == '0';

    this._bottomSheet.open(RegistroCompraBottomSheet, { data: this.documentToUpload }).afterDismissed().subscribe((result: DocumentToUpload) => {

      if (result == undefined) return;

      if (result.invoiceFile) {
        this.resetAutocompleteDocument();

        this.documentToUpload.invoiceFile = result.invoiceFile;
        this.documentToUpload.invoiceJSON = result.invoiceJSON;

        console.log('generarFecha', result.invoiceJSON.InvoiceDate.value)
        console.log('generarFecha /', this.generarFechaFormato(result.invoiceJSON.InvoiceDate.content))
        console.log('generarFechaUTC', this.generarFechaUTC(result.invoiceJSON.InvoiceDate.value))
        this.registro.cli_codcli = result.invoiceJSON.VendorTaxId ? result.invoiceJSON.VendorTaxId.value : null;
        this.registro.cli_coddoc = result.invoiceJSON.VendorTaxId ? result.invoiceJSON.VendorTaxId.value : null;
        this.registro.cco_fecemi = result.invoiceJSON.InvoiceDate ? this.generarFechaFormato(result.invoiceJSON.InvoiceDate.content) : null;
        this.registro.cco_fecven = result.invoiceJSON.ServiceEndDate ? this.generarFechaFormato(result.invoiceJSON.ServiceEndDate.content) : null;
        this.registro.sad_codsad = result.invoiceJSON.InvoiceId ? result.invoiceJSON.InvoiceId.value.replace(/\s/g, '').split('-')[0] : null;
        console.log('digitos completados', this.completarDigitosNumDoc(result.invoiceJSON.InvoiceId.value.replace(/\s/g, '').split('-')[1]))
        this.registro.cco_numdoc = result.invoiceJSON.InvoiceId ? this.registro.sad_codsad + this.completarDigitosNumDoc(result.invoiceJSON.InvoiceId.value.replace(/\s/g, '').split('-')[1]) : null;
        this.registro.cco_impdoc = result.invoiceJSON.InvoiceTotal ? result.invoiceJSON.InvoiceTotal.value.amount : 0;
        this.registro.cco_impigv = result.invoiceJSON.TotalTax ? result.invoiceJSON.TotalTax.value.amount : 0;

        if (result.invoiceJSON.SubTotal) {

          this.registro.cco_impafe = result.invoiceJSON.SubTotal.value.amount;

        } else {

          this.registro.cco_impafe = this.registro.cco_impdoc - this.registro.cco_impigv;

        }

        this.registro.valvta = this.registro.cco_impafe + this.registro.cco_impina;

        if (!(this.registro.cco_impdoc - this.registro.cco_impigv == this.registro.valvta)) {
          this.registro.cco_impina = this.registro.valvta - this.registro.cco_impafe;
        }

        this.registro.cco_fecven = result.invoiceJSON.DueDate ? this.generarFechaUTC(result.invoiceJSON.DueDate.value) : this.registro.cco_fecemi;
        this.registro.tdo_codtdo = 'FAC';
        this.registro.ocm_numocm = '00000000000';
        this.registro.tmo_codtmo = result.invoiceJSON.InvoiceTotal ? result.invoiceJSON.InvoiceTotal.value.currencyCode == 'USD' ? 'DO' : 'SO' : null;
        this.registro.cco_glocco = result.invoiceJSON.Items ? result.invoiceJSON.Items.values[0].properties.Description ? result.invoiceJSON.Items.values[0].properties.Description.value : '' : '';

        this.registro.cco_tasigv = this.registro.cco_impigv > 0 ? Math.round((this.registro.cco_impigv / this.registro.cco_impafe) * 100) : 0;

        // validacion si la factura cargada en imagen tiene un inafecto


        const diasDiferencia = this.calcularDiferenciaEnDias(new Date(this.registro.cco_fecemi), new Date(this.registro.cco_fecven));

        const subdiario = this.subsidiarios.find(item => item.sco_codsco == this.registro.sco_codsco);

        this.registro.ccn_codccn = this.registro.tmo_codtmo == 'SO' ? subdiario.ccn_codmn : subdiario.ccn_codme;


        console.log('difeerencia', diasDiferencia)
        const condicionPago = this.condiciones.find(item => item.CPA_DIAPLA == diasDiferencia - 1);
        console.log('condicion', condicionPago)

        this.registro.cpa_codcpa = condicionPago ? condicionPago.CPA_CODCPA : null;
        // this.registro.cpa_codcpa = '126';

        const proveedor = this.proveedores.find(item => item.cli_codcli == this.registro.cli_codcli);

        this.listaFacturasDeProveedor$ = this._comprasService.listarFacturasProveedor(this.registro.cli_codcli).subscribe(
          listaFacturasDeProveedor => {
            const encontrado = listaFacturasDeProveedor.find(item => item.cco_numdoc == this.registro.cco_numdoc);

            if (encontrado) this._snackBarService.showError('Esta factura ya ha sido registrada por el proveedor', 'Ok')

            if (!proveedor && this.registro.cli_codcli) {
              this._dialogService.openDialog(ConfirmationComponent, '¿El proveedor encontrado no existe, desea agregar al proveedor?', '', '', '').subscribe(
                result => {
                  if (result) {
                    const dialogRef = this.dialog.open(ProveedorDialog, {
                      data: {
                        id: '0',
                        idARegistrar: this.registro.cli_codcli
                      }
                    })

                    dialogRef.afterClosed().subscribe(result => {
                      this._snackBarService.showSuccess('Actualizando lista de proveedores', 'Ok');

                      this.obtenerProveedores()
                    });
                  }
                }
              )
            } else {
              this.ultimoRegistroCompra$ = this._comprasService.obtenerUltimoRegistroCompra(this.registro.sco_codsco, this.registro.cli_codcli).subscribe(
                historial => {
                  this.registro.tga_codtga = historial.length > 0 ? historial[0].tga_codtga : null;
                  // this.registro.cpa_codcpa = historial[0].cpa_codcpa;
                  // this.registro.tmo_codtmo = result.invoiceJSON.InvoiceTotal ? result.invoiceJSON.InvoiceTotal.value.currencyCode : historial[0].tmo_codtmo;
                  this.registro.t30_codt30 = historial.length > 0 ? historial[0].T30_CODT30 : null;
                  // this.registro.cco_glocco = historial[0].cco_glocco;
                }
              )
            }
          },
          error => {
            if (!proveedor && this.registro.cli_codcli) {
              this._dialogService.openDialog(ConfirmationComponent, '¿El proveedor encontrado no existe, desea agregar al proveedor?', '', '', '').subscribe(
                result => {
                  if (result) {
                    const dialogRef = this.dialog.open(ProveedorDialog, {
                      data: {
                        id: '0',
                        idARegistrar: this.registro.cli_codcli
                      }
                    })

                    dialogRef.afterClosed().subscribe(result => {
                      this._snackBarService.showSuccess('Actualizando lista de proveedores', 'Ok');

                      this.obtenerProveedores()
                    });
                  }
                }
              )
            } else {
              this.ultimoRegistroCompra$ = this._comprasService.obtenerUltimoRegistroCompra(this.registro.sco_codsco, this.registro.cli_codcli).subscribe(
                historial => {
                  this.registro.tga_codtga = historial[0].tga_codtga;
                  this.registro.t30_codt30 = historial[0].T30_CODT30;
                }
              )
            }
          }
        )

      }

      const document = result.xmlJSON;

      if (document.INVOICE) {
        const INVOICE = document.INVOICE;
        this.resetAutocompleteDocument();
        this.documentToUpload.xmlFile = result.xmlFile;
        this.documentToUpload.xmlJSON = result.xmlJSON;
        this.documentToUpload.xmlStr = result.xmlStr;
        console.log('resultado de la carga del xml', result.xmlJSON)
        console.log('resultado de la carga del xml', JSON.stringify(result.xmlJSON))
        this.registro.cli_codcli = INVOICE["CAC:ACCOUNTINGSUPPLIERPARTY"][0]["CBC:CUSTOMERASSIGNEDACCOUNTID"] ? INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'][0] : INVOICE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYIDENTIFICATION'][0]['CBC:ID'][0]['_'];
        this.registro.sad_codsad = INVOICE["CBC:ID"][0].split('-')[0];
        this.registro.cco_numdoc = INVOICE["CBC:ID"][0].split('-')[0] + INVOICE["CBC:ID"][0].split('-')[1];
        const invoiceTypeCode = INVOICE["CBC:INVOICETYPECODE"][0]["_"];
        console.log('invoiceTypeCode', invoiceTypeCode)
        console.log('this.tiposDocumento', this.tiposDocumento)
        this.registro.tdo_codtdo = this.tiposDocumento.find(item => item.TDO_CODOFI == invoiceTypeCode).TDO_CODTDO;
        console.log('tipo documento ====', this.registro.tdo_codtdo)

        this.registro.cco_tasigv = INVOICE["CAC:INVOICELINE"][0]["CAC:TAXTOTAL"][0]["CAC:TAXSUBTOTAL"][0]["CAC:TAXCATEGORY"][0]["CBC:PERCENT"][0];
        this.registro.cco_impigv = parseFloat(INVOICE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_'].toString());
        const monto = INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'] ? parseFloat(INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'][0]['_']) : parseFloat(INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']);

        if (INVOICE["CAC:PAYMENTTERMS"]) {
          const formaPago = INVOICE["CAC:PAYMENTTERMS"].find(item => item["CBC:ID"] == 'FormaPago');
          if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Contado') {
            this.registro.cpa_codcpa = '112';
          }

          if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Credito') {

            console.log('formapago', formaPago)
            console.log('fecha vencimiento', formaPago)
            const fecVen = new Date(INVOICE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]).toUTCString();
            const fecToday = new Date().toUTCString();
            this.registro.cco_fecven = new Date(INVOICE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]);
            console.log('fecha hoy', fecToday)
            console.log('fecha ven', fecVen)

            const dias = retornaDiferenciaDias(fecVen, fecToday);
            console.log('dias', dias)
          }

        }

        if (this.registro.cco_impigv == 0) {
          this.registro.cco_impina = parseFloat(monto.toFixed(2));
          this.registro.cco_impafe = 0;
        } else {
          this.registro.cco_impafe = parseFloat(monto.toFixed(2));
          this.registro.cco_impina = 0;

          this.registro.cco_impdoc = parseFloat(INVOICE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']);

          if (this.registro.cco_impafe + this.registro.cco_impigv != this.registro.cco_impdoc) {
            if (INVOICE["CAC:ALLOWANCECHARGE"]) {
              this.registro.dpc_impfis = parseFloat(INVOICE["CAC:ALLOWANCECHARGE"][0]["CBC:AMOUNT"][0]["_"]);
            } else {
              this.registro.cco_impina = parseFloat((this.registro.cco_impdoc - (this.registro.cco_impafe + this.registro.cco_impigv)).toFixed(2))

            }
          }
        }

        this.registro.cco_fecemi = new Date(INVOICE["CBC:ISSUEDATE"][0]);
        this.registro.cco_fecemi.setDate(this.registro.cco_fecemi.getDate() + 1);

        this.registro.tmo_codtmo = INVOICE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] ? INVOICE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] : INVOICE['CBC:DOCUMENTCURRENCYCODE'][0];
        this.registro.tmo_codtmo = this.registro.tmo_codtmo == "USD" ? "DO" : "SO";

        this.registro.valvta = this.registro.cco_impina + this.registro.cco_impafe + this.registro.dpc_impfis + this.registro.dpc_impsis;

        this.registro.cco_impdoc = this.registro.cco_impafe + this.registro.cco_impina + this.registro.cco_impigv;
        this.serieElement.nativeElement.add;

        const proveedor = this.proveedores.find(item => item.cli_codcli == this.registro.cli_codcli);

        if (!proveedor) {
          this._dialogService.openDialog(ConfirmationComponent, '¿El proveedor encontrado no existe, desea agregar al proveedor?', '', '', '').subscribe(
            result => {
              if (result) {
                const dialogRef = this.dialog.open(ProveedorDialog, {
                  data: {
                    id: '0',
                    idARegistrar: this.registro.cli_codcli
                  }
                })

                dialogRef.afterClosed().subscribe(result => {
                  this._snackBarService.showSuccess('Actualizando lista de proveedores', 'Ok');
                  this.obtenerProveedores()
                });
              }
            }
          )
        }
      }

      if (document.DEBITNOTE) {
        const DEBITNOTE = document.DEBITNOTE;
        this.resetAutocompleteDocument();
        this.documentToUpload.xmlFile = result.xmlFile;
        this.documentToUpload.xmlJSON = result.xmlJSON;
        this.documentToUpload.xmlStr = result.xmlStr;
        console.log('resultado de la carga del xml', result.xmlJSON)
        console.log('resultado de la carga del xml', JSON.stringify(result.xmlJSON))
        this.registro.cli_codcli = DEBITNOTE["CAC:ACCOUNTINGSUPPLIERPARTY"][0]["CBC:CUSTOMERASSIGNEDACCOUNTID"] ? DEBITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CBC:CUSTOMERASSIGNEDACCOUNTID'][0] : DEBITNOTE['CAC:ACCOUNTINGSUPPLIERPARTY'][0]['CAC:PARTY'][0]['CAC:PARTYIDENTIFICATION'][0]['CBC:ID'][0]['_'];
        this.registro.sad_codsad = DEBITNOTE["CBC:ID"][0].split('-')[0];
        this.registro.cco_numdoc = DEBITNOTE["CBC:ID"][0].split('-')[0] + DEBITNOTE["CBC:ID"][0].split('-')[1];
        const invoiceTypeCode = DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] ? DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] : DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0];
        console.log('invoiceTypeCode', invoiceTypeCode)
        console.log('this.tiposDocumento', this.tiposDocumento)
        this.registro.tdo_codtdo = this.tiposDocumento.find(item => item.TDO_CODOFI == invoiceTypeCode).TDO_CODTDO;
        console.log('tipo documento ====', this.registro.tdo_codtdo)

        this.registro.cco_tasigv = DEBITNOTE["CAC:INVOICELINE"][0]["CAC:TAXTOTAL"][0]["CAC:TAXSUBTOTAL"][0]["CAC:TAXCATEGORY"][0]["CBC:PERCENT"][0];
        this.registro.cco_impigv = parseFloat(DEBITNOTE['CAC:TAXTOTAL'][0]['CBC:TAXAMOUNT'][0]['_'].toString());
        const monto = DEBITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'] ? parseFloat(DEBITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:LINEEXTENSIONAMOUNT'][0]['_']) : parseFloat(DEBITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']);

        if (DEBITNOTE["CAC:PAYMENTTERMS"]) {
          const formaPago = DEBITNOTE["CAC:PAYMENTTERMS"].find(item => item["CBC:ID"] == 'FormaPago');
          if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Contado') {
            this.registro.cpa_codcpa = '112';
          }

          if (formaPago["CBC:PAYMENTMEANSID"][0] == 'Credito') {

            console.log('formapago', formaPago)
            console.log('fecha vencimiento', formaPago)
            const fecVen = new Date(DEBITNOTE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]).toUTCString();
            const fecToday = new Date().toUTCString();
            this.registro.cco_fecven = new Date(DEBITNOTE["CAC:PAYMENTTERMS"][1]['CBC:PAYMENTDUEDATE'][0]);
            console.log('fecha hoy', fecToday)
            console.log('fecha ven', fecVen)

            const dias = retornaDiferenciaDias(fecVen, fecToday);
            console.log('dias', dias)
          }

        }

        if (this.registro.cco_impigv == 0) {
          this.registro.cco_impina = parseFloat(monto.toFixed(2));
          this.registro.cco_impafe = 0;
        } else {
          this.registro.cco_impafe = parseFloat(monto.toFixed(2));
          this.registro.cco_impina = 0;

          this.registro.cco_impdoc = parseFloat(DEBITNOTE['CAC:LEGALMONETARYTOTAL'][0]['CBC:PAYABLEAMOUNT'][0]['_']);

          if (this.registro.cco_impafe + this.registro.cco_impigv != this.registro.cco_impdoc) {
            if (DEBITNOTE["CAC:ALLOWANCECHARGE"]) {
              this.registro.dpc_impfis = parseFloat(DEBITNOTE["CAC:ALLOWANCECHARGE"][0]["CBC:AMOUNT"][0]["_"]);
            } else {
              this.registro.cco_impina = parseFloat((this.registro.cco_impdoc - (this.registro.cco_impafe + this.registro.cco_impigv)).toFixed(2))

            }
          }
        }

        this.registro.cco_fecemi = new Date(DEBITNOTE["CBC:ISSUEDATE"][0]);
        this.registro.cco_fecemi.setDate(this.registro.cco_fecemi.getDate() + 1);

        this.registro.tmo_codtmo = DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] ? DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0]['_'] : DEBITNOTE['CBC:DOCUMENTCURRENCYCODE'][0];
        this.registro.tmo_codtmo = this.registro.tmo_codtmo == "USD" ? "DO" : "SO";

        this.registro.valvta = this.registro.cco_impina + this.registro.cco_impafe + this.registro.dpc_impfis + this.registro.dpc_impsis;

        this.registro.cco_impdoc = this.registro.cco_impafe + this.registro.cco_impina + this.registro.cco_impigv;
        this.serieElement.nativeElement.add;

        const proveedor = this.proveedores.find(item => item.cli_codcli == this.registro.cli_codcli);

        if (!proveedor) {
          this._dialogService.openDialog(ConfirmationComponent, '¿El proveedor encontrado no existe, desea agregar al proveedor?', '', '', '').subscribe(
            result => {
              if (result) {
                const dialogRef = this.dialog.open(ProveedorDialog, {
                  data: {
                    id: '0',
                    idARegistrar: this.registro.cli_codcli
                  }
                })

                dialogRef.afterClosed().subscribe(result => {
                  this._snackBarService.showSuccess('Actualizando lista de proveedores', 'Ok');
                  this.obtenerProveedores()
                });
              }
            }
          )
        }
      }
    })

  }

  obtenerProveedores(): void {
    this.loaderData = true;

    this.proveedores$ = this._proveedorService.obtenerProveedores().subscribe(
      proveedores => {
        this.proveedores = proveedores;
        this.loaderData = false;
      },
      error => {
        this.loaderData = false;
      }
    )
  }

  establecerAjusteIGV(): void {
    // this.registro.cco_impigv = this.registro.cco_impigv + this.registro.dpc_difigv;
    this.calculaTotal();
    // this.registro.cco_impigv  = roundToDecimals(this.registro.cco_impafe * (this.registro.cco_tasigv / 100), 2) + this.registro.dpc_difigv
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.permisos$,
      this.subdiarios$,
      this.tipoOperacion$,
      this.cuentas$,
      this.tiposBienServicio$,
      this.tiposRenta$,
      this.tiposImpuesto$,
      this.tiposDocumento$,
      this.proveedor$,
      this.moneda$,
      this.ordenesCompra$,
      this.condiciones$,
      this.voucher$,
      this.centro$,
      this.auxiliar$,
      this.tipoRetencion$,
      this.tipoImpRetencion$,
      this.signoCtaCte$,
      this.tipoCambio$,
      this.configuracionTecnica$,
      this.configuracionContabilidad$,
      this.ultimoRegistroCompra$,
      this.listaFacturasDeProveedor$,
      this.period$,
      this.proveedores$,
      this.registroCompra$,
      this.sendForm$,
      this.tiposOperacionRetencion$,

    ]);
  }
}

export class DocumentToUpload {
  invoiceFile: File;
  invoiceJSON: any;
  xmlFile: File;
  xmlJSON: any;
  xmlStr: string;
  isNew: boolean;

  constructor() { }
}
