<app-pavso-title-section [title]="'Consulta de saldos mensuales'" [module]="'Contabilidad'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <form>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s8">
          <mat-form-field>
            <mat-label>Cuenta Contable</mat-label>
            <mat-select [(ngModel)]="cuenta" name="cuenta" (selectionChange)="seleccionarCuentaContable($event.value)">
              <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta.cuenta">
                {{cuenta.cuenta}} - {{cuenta.descri_cuenta}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s4">
          <mat-form-field>
            <mat-label>Moneda</mat-label>
            <mat-select>
              <mat-option *ngFor="let moneda of monedas" [value]="moneda.TMO_CODTMO">
                {{moneda.tmo_deslar}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <br>
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Tabla</div>
    </div>
    <div class="mat-elevation-z0 overflow-x">

      <table mat-table [dataSource]="dataSource" matSort >

          <ng-container matColumnDef="mes_desmes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Mes </th>
            <td mat-cell *matCellDef="let row" data-label="Mes"> {{row.mes_desmes}} </td>
          </ng-container>

          <ng-container matColumnDef="scu_salini">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo Inicial </th>
            <td mat-cell *matCellDef="let row" data-label="Saldo Inicial"> {{row.scu_salini}} </td>
          </ng-container>

          <ng-container matColumnDef="scu_debmna">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Debe </th>
              <td mat-cell *matCellDef="let row" data-label="Debe"> {{row.scu_debmna}} </td>
          </ng-container>

          <ng-container matColumnDef="scu_habmna">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Haber </th>
            <td mat-cell *matCellDef="let row" data-label="Haber"> {{row.scu_habmna}} </td>
          </ng-container>

          <ng-container matColumnDef="saldo_final">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Saldo Final </th>
            <td mat-cell *matCellDef="let row" data-label="Saldo Final"> {{row.saldo_final}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="5">No se encontraron registros</td>
          </tr>

      </table>

    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    <br>
    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s3">
          <mat-form-field>
            <mat-label>Total Saldo Inicial</mat-label>
            <input readonly matInput min="0" [(ngModel)]="totalSaldoInicial" name="totalSaldoInicial" placeholder="Total Saldo Inicial" type="number">
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Total Debe</mat-label>
            <input readonly matInput min="0" [(ngModel)]="totalDebe" name="totalDebe" placeholder="Total Debe" type="number">
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Total Haber</mat-label>
            <input readonly matInput min="0" [(ngModel)]="totalHaber" name="totalHaber" placeholder="Total Haber" type="number">
          </mat-form-field>
        </div>
        <div class="col s3">
          <mat-form-field>
            <mat-label>Total Saldo Final</mat-label>
            <input readonly matInput min="0" [(ngModel)]="totalSaldoFinal" name="totalSaldoFinal" placeholder="Total Saldo Final" type="number">
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-cobranzas/cobradores">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>

</div>
