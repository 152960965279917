import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { fillTable, searchInTable } from "@utils/tables/table";
import { Subject, Subscription } from "rxjs";
import { CrmService, ExportExcelService } from "src/app/services";
import Plotly from 'plotly.js-dist-min'
import { FiltroOportunidad } from "src/app/models/crm/filtro-oportunidad";
import { ApiCRMOperacionesSeguimientoService } from "src/app/services/api/crm/operaciones/crm.operaciones.seguimiento.service";
import { ApiCRMOperacionesOportunidadService } from "src/app/services/api/crm/operaciones/crm.operaciones.oportunidad.service";

class CuadroEstadoOportunidades {
  enCurso: Array<any>;
  cerrado: Array<any>;

  constructor() {
    this.enCurso = [];
    this.cerrado = [];
  }
}

@Component({
  selector: 'app-panel-crm',
  templateUrl: './panel-crm.component.html',
  styleUrls: ['./panel-crm.component.scss']
})
export class PanelCrmComponent implements OnInit, OnDestroy{

  filtro: FiltroOportunidad;

  loaderTracings: boolean = false;
  loaderSearch: boolean = false;

  displayedColumnsOportunidades: string[] = ['acciones', 'ticket', 'dias', 'requerimiento', 'importe', 'fecha', 'descrioln', 'oet_descri', 'descriotn', 'nombre', 'telefono', 'correo', 'campania', 'canal'];
  dataSourceOportunidades: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorOportunidades: MatPaginator;
  @ViewChild(MatSort) sortOportunidades: MatSort;

  displayedColumnsSeguimientos: string[] = ['item', 'fecha', 'responsable', 'detalle', 'etapa', 'modalidad', 'dias', 'prob', 'actividad'];
  dataSourceSeguimientos: MatTableDataSource<any>;

  @ViewChild("seguimientosTable") paginatorSeguimientos: MatPaginator;
  @ViewChild(MatSort) sortSeguimientos: MatSort;

  displayedColumnsFicha: string[] = ['secopv', 'ordpod', 'otr_descri'];
  dataSourceFicha: MatTableDataSource<any>;

  @ViewChild('matPaginatorFicha') paginatorFicha: MatPaginator;
  @ViewChild(MatSort) sortFicha: MatSort;

  buttonsName: INameConstant = NAMES_CONSTANTS;

  totalOportunidades: number = 0;
  totalSeguimientos: number = 0;
  totalHoras: number = 0;

  loading$: Subscription;
  oportunidades$: Subscription;
  seguimientos$: Subscription;
  clientes$: Subscription;
  etapas$: Subscription;
  responsables$: Subscription;
  situaciones$: Subscription;
  cuadroEstadoOportunidades$: Subscription;
  chartCantidadOportunidadesMes$: Subscription;
  chartOportunidadCerradaAgente$: Subscription;
  chartOportunidadEnCursoAgente$: Subscription;
  chartOportunidadLineaNegocio$: Subscription;
  chartOportunidadTipoNegociacion$: Subscription;
  chartOportunidadResponsable$: Subscription;
  chartOportunidadEtapa$: Subscription;
  tiposNegociacion$: Subscription;
  lineasNegocio$: Subscription;

  dataForm$: Subscription;

  period$: Subscription;

  protected _onDestroy = new Subject<void>();

  anio: string;
  cuadroEstadoOportunidad: CuadroEstadoOportunidades;

  categoriasCantidadOportunidadesMes: Array<string> = [];
  dataCantidadOportunidadesMes: Array<any> = [];
  activoGraficoCantidadOpoMes: boolean = false;

  categoriasOportunidadCerradaAgente: Array<string> = [];
  dataOportunidadCerradaAgente: Array<any> = [];
  activoOportunidadCerradaAgente: boolean = false;

  categoriasOportunidadEnCursoAgente: Array<string> = [];
  dataOportunidadEnCursoAgente: Array<any> = [];
  activoOportunidadEnCursoAgente: boolean = false;

  labelsOportunidadLineaNegocio: Array<string> = ['ERP PAV ', 'FACTEL', 'ERP MYPES', 'ACTUALIZACIÓN', 'xxx'];
  seriesOportunidadLineaNegocio: Array<number> = [9, 2, 1, 1, 17];
  activoOportunidadLineaNegocio: boolean = false;

  labelsOportunidadTipoNegociacion: Array<string> = [];
  seriesOportunidadTipoNegociacion: Array<number> = [];
  activoOportunidadTipoNegociacion: boolean = false;

  labelsOportunidadResponsable: Array<string> = [];
  seriesOportunidadResponsable: Array<number> = [];
  activoOportunidadResponsable: boolean = false;

  filtroDesde: string;
  filtroHasta: string;
  filtroResponsable: any[] = [];
  filtroCliente: any[] = [];
  filtroSituacion: any[] = [];
  filtroEtapa: any[] = [];
  filtroTipoNegociacion: any[] = [];
  filtroProgramado: boolean;
  filtroLinea: boolean;

  oportunidadSeleccionada: any;

  oPerdidas: number = 0;
  nroOportunidades: number = 0;
  oportunidadesAbie: number = 0;
  oportunidadesCerr: number = 0;

  clientes: any[] = [];
  responsables: any[] = [];
  etapas: any[] = [];
  situaciones: any[] = [];
  tiposNegociacion: any[] = [];
  lineas: any[] = [];

  constructor(
    private _crmService: CrmService,
    private _apiSeguimientoCrmService: ApiCRMOperacionesSeguimientoService,
    private _apiOportunidadCrmService: ApiCRMOperacionesOportunidadService,
    private _snackBarService: SnackBarService,
    private store: Store<PavsoState>,
    private _exportExcelService: ExportExcelService,
  ) {
    this.cuadroEstadoOportunidad = new CuadroEstadoOportunidades();

    this.period$ = this.store.select('period').subscribe(state => {
      this.anio = state.year;
    })

    this.dataSourceOportunidades = fillTable([], this.paginatorOportunidades, this.sortOportunidades);

    this.dataSourceSeguimientos = fillTable([], this.paginatorSeguimientos, this.sortSeguimientos);

    this.dataSourceFicha = fillTable([], this.paginatorFicha, this.sortFicha);

    this.filtro = new FiltroOportunidad();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.loadMaestros();
    })

    this.construirFunnelChart()
  }

  construirFunnelChart(): void {
    let gd = document.getElementById('funnelChart');
    let data = [
      {
        type: 'funnel',
        y: ["Contactar", "Alcance", "Presupuesto", "Cotización", "Negociación", "Venta aceptada", "Cesado", "Venta perdida"],
        x: [15, 12, 8, 6, 4, 4, 3,  1],
        hoverinfo: 'x+percent previous+percent initial'
      }
    ];

    let layout = {margin: {l: 150, t: 20, r: 50, b: 20}, width:600, height: 430}

    Plotly.newPlot('funnelChart', data, layout);
  }

  loadMaestros(): void {

    this.dataForm$ = this._crmService.obtenerDataFormPanelCRM(this.anio).subscribe(
      dataForm => {

        this.etapas = dataForm.etapas;

        this.responsables = dataForm.responsables;

        this.situaciones = dataForm.situaciones;

        this.tiposNegociacion = dataForm.tiposNegociacion;

        this.lineas = dataForm.lineasNegocio;

        // CUADRO ESTADO OPORTUNIDAD
        this.cuadroEstadoOportunidad = dataForm.cuadro;

        this.chartCantidadOportunidadesMes$ = this._crmService.obtenerEstadisticoCantidadOportunidadesMes(this.anio).subscribe(
          chart => {
            this.categoriasCantidadOportunidadesMes = chart.categories;
            this.dataCantidadOportunidadesMes = chart.data;
            this.activoGraficoCantidadOpoMes = true;

            this.chartOportunidadCerradaAgente$ = this._crmService.obtenerEstadisticoOportunidadesCerradasAgente(this.anio).subscribe(
              chart2 => {
                this.categoriasOportunidadCerradaAgente = chart2.categorias;
                this.dataOportunidadCerradaAgente = chart2.data;
                this.activoOportunidadCerradaAgente = true;

                this.chartOportunidadEnCursoAgente$ = this._crmService.obtenerEstadisticoOportunidadesEnCursoAgente(this.anio).subscribe(
                  chart3 => {
                    console.log('oportunidades en curso ', chart3)
                    this.categoriasOportunidadEnCursoAgente = chart3.categorias;
                    this.dataOportunidadEnCursoAgente = chart3.data;
                    this.activoOportunidadEnCursoAgente = true;

                    this.chartOportunidadLineaNegocio$ = this._crmService.obtenerEstadisticoOportunidadesLineaNegocio(this.anio).subscribe(
                      chart4 => {
                        // this.labelsOportunidadLineaNegocio = chart4.labels;
                        // this.seriesOportunidadLineaNegocio = chart4.series;
                        this.activoOportunidadLineaNegocio = true;

                        console.log('OPORTUNIDADES LINEA NEGOCIO',  {labels: chart4.labels, series: chart4.series})

                        this.chartOportunidadTipoNegociacion$ = this._crmService.obtenerEstadisticoOportunidadesTipoNegociacion(this.anio).subscribe(
                          chart5 => {
                            console.log()
                            this.labelsOportunidadTipoNegociacion = chart5.labels;
                            this.seriesOportunidadTipoNegociacion = chart5.series;
                            this.activoOportunidadTipoNegociacion = true;

                            this.chartOportunidadEtapa$ = this._crmService.obtenerEstadisticoOportunidadesEtapa(this.anio).subscribe(
                              chart6 => {
                                console.log('cahrt6', chart6)

                                let gd = document.getElementById('funnelChart');
                                let data = [
                                  {
                                    type: 'funnel',
                                    y: chart6.y,
                                    x: chart6.x,
                                    hoverinfo: 'x+percent previous+percent initial'
                                  }
                                ];

                                let layout = {margin: {l: 150, t: 20, r: 50, b: 20}, width:600, height: 430}

                                Plotly.newPlot('funnelChart', data, layout);

                                this.chartOportunidadResponsable$ = this._crmService.obtenerEstadisticoOportunidadesResponsable(this.anio).subscribe(
                                  chart7 => {
                                    console.log('chart 7', chart7)
                                    this.labelsOportunidadResponsable = chart7.labels;
                                    this.seriesOportunidadResponsable = chart7.series;
                                    this.activoOportunidadResponsable = true;

                                    this.obtenerOportunidades();
                                  },
                                  error => this._snackBarService.showError(error.error.msg, 'Ok')
                                )
                              },
                              error => this._snackBarService.showError(error.error.msg, 'Ok')
                            )
                          },
                          error => this._snackBarService.showError(error.error.msg, 'Ok')
                        )
                      },
                      error => this._snackBarService.showError(error.error.msg, 'Ok')
                    )
                  },
                  error => this._snackBarService.showError(error.error.msg, 'Ok')
                )
              },
              error => this._snackBarService.showError(error.error.msg, 'Ok')
            )
          },
          error => this._snackBarService.showError(error.error.msg, 'Ok')
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )

  }

  trackByResponsable(index, responsable): string {
    return responsable? responsable.idres: undefined;
  }

  trackByCliente(index, cliente): string {
    return cliente? cliente.cli_codcli: undefined;
  }

  trackByEtapa(index, etapa): string {
    return etapa? etapa.codoet: undefined;
  }

  obtenerOportunidades(): void {
    this.oportunidades$ = this._apiOportunidadCrmService.obtenerRegistrosOportunidad().subscribe(
      oportunidades => {

        const hoy = new Date();

        const opor = oportunidades.filter(item => {
          const date = new Date(item.fecreg);
          if(date.getFullYear() == hoy.getFullYear()) {
            return item;
          }
        });

        this.nroOportunidades = opor.length;

        const cerradas = opor.filter(item => item.oss_indsit == 2);
        const perdidas = opor.filter(item => item.oss_indsit == 0);
        const abiertas = opor.filter(item => item.oss_indsit == 1);

        this.oPerdidas = perdidas.length;
        this.oportunidadesAbie = abiertas.length;
        this.oportunidadesCerr = cerradas.length;

        this.activoOportunidadEnCursoAgente = true;

        opor.map(item => {
          item['isSelected'] = false;
        })

        this.totalOportunidades = opor.length;
        this.dataSourceOportunidades = fillTable(opor, this.paginatorOportunidades, this.sortOportunidades)

        this.categoriasOportunidadEnCursoAgente = ['En curso', 'Cerradas', 'Perdidas'];

        this.dataOportunidadEnCursoAgente = [
          {
            data: [this.oportunidadesAbie],
            name: 'En curso'
          },
          {
            data: [this.oportunidadesCerr],
            name: 'Cerradas'
          },
          {
            data: [this.oPerdidas],
            name: 'Perdidas'
          }
        ];

      },
      error => this._snackBarService.showError(error.error.msg, 'Ok')
    )
  }

  obtenerSeguimientos(oportunidad): void {

    this.dataSourceOportunidades.data.map(item => {
      item['isSelected'] = false;
    })

    oportunidad.isSelected = !oportunidad.isSelected;

    this.oportunidadSeleccionada = oportunidad;

    this.loaderTracings = true;
    this._apiSeguimientoCrmService.obtenerSeguimientosOportunidad(oportunidad.idtkt).subscribe(
      seguimientos => {
        this.totalSeguimientos = seguimientos.length;
        console.log('seguimientos', seguimientos)
        this.dataSourceSeguimientos = fillTable(seguimientos, this.paginatorSeguimientos, this.sortSeguimientos);
        this.loaderTracings = false;

        this._crmService.obtenerEspecificacionesPorOportunidad(oportunidad.idtkt).subscribe(
          especificaciones => {
            this.dataSourceFicha = fillTable(especificaciones, this.paginatorFicha, this.sortFicha);
          },
          error => this._snackBarService.showError(error.error.msg, 'Ok')
        )
      },
      error => {
        this.loaderTracings = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  limpiarOportunidades(): void {
    this.filtroResponsable = [];
    this.filtroCliente = [];
    this.filtroSituacion = [];
    this.filtroEtapa = [];
  }

  filtrarOportunidades(f: NgForm): void {

    console.log('filtro', this.filtro)

    this.loaderSearch = true;

    this._crmService.obtenerRegistrosOportunidadConFiltro(this.filtro).subscribe(
      response => {

        const hoy = new Date();
        const opor = response.filter(item => {
          const date = new Date(item.fecreg);
          if(date.getFullYear() == hoy.getFullYear()){
            return item;
          }
        })
        this.loaderSearch = false;
        this.dataSourceOportunidades = fillTable(opor, this.paginatorOportunidades, this.sortOportunidades);

        console.log('resultados de busqueda', response);
      },
      error => {
        this.loaderSearch = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  exportarExcelOportunidades(): void {

    let dataForExcel = [];

    this.dataSourceOportunidades.data.forEach((row: any) => {
      dataForExcel.push(Object.values(row))
    })

    let reportData = {
      title: 'Oportunidades',
      data: dataForExcel,
      headers: Object.keys(this.dataSourceOportunidades.data[0]),
    }

    this._exportExcelService.exportExcelTable(reportData);
  }

  exportarExcelSeguimientos(): void {

    let dataForExcel = [];

    this.dataSourceSeguimientos.data.forEach((row: any) => {
      dataForExcel.push(Object.values(row))
    })

    let reportData = {
      title: 'Seguimientos',
      data: dataForExcel,
      headers: Object.keys(this.dataSourceSeguimientos.data[0]),
    }

    this._exportExcelService.exportExcelTable(reportData);
  }

  applyFilter(event: Event): void {
    this.dataSourceOportunidades = searchInTable(event, this.dataSourceOportunidades )
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.oportunidades$,
      this.seguimientos$,
      this.clientes$,
      this.etapas$,
      this.responsables$,
      this.situaciones$,
      this.cuadroEstadoOportunidades$,
      this.chartCantidadOportunidadesMes$,
      this.chartOportunidadCerradaAgente$,
      this.chartOportunidadEnCursoAgente$,
      this.chartOportunidadLineaNegocio$,
      this.chartOportunidadTipoNegociacion$,
      this.chartOportunidadResponsable$,
      this.chartOportunidadEtapa$,
    ])
  }

}
