import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { Store } from '@ngrx/store';
import { CajaBancoService } from 'src/app/services';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { numberWithCommas } from '@utils/formats/number.format';
import { formatDateWithSlash } from '@utils/formats/date.format';
import { estaFechaEsMayorOIgualQue } from '@utils/date/compare';
import { sonCelularesValidos, sonCorreosValidos } from '@utils/validations/mail';
import { SelectionModel } from '@angular/cdk/collections';
import { FiltroConsulta } from 'src/app/models/cobranzas/filtro-consulta';

@Component({
  selector: 'cuentas-por-pagar',
  templateUrl: './cuentas-por-pagar.component.html',
  styleUrls: ['./cuentas-por-pagar.component.scss']
})
export class CuentasPorPagarComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;
  loaderDetalle: boolean = false;

  displayedColumns: string[] = ['select', 'cuenta_corriente', 'td', 'nro_doc', 'fecha_emision', 'razon_social', 'fecha_vencimiento', 'dias', 'mo', 'imp_total_soles', 'abono_soles', 'saldo_soles', 'abono_dolar'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns1: string[] = ['acciones', 'fec_emision', 'req_contable', 'sud_diario', 'doc_operacion', 'num_operacion','fec_operacion', 'imp_nacional', 'imp_extranjero', 'cuenta_contable' , 'tip_cambio', 'linea', 'situacion', 'tip_doc_ref', 'doc_rec', 'glosa'];
  dataSource1: MatTableDataSource<any>;

  @ViewChild('paginatorDetalle') paginator1: MatPaginator;
  @ViewChild(MatSort) sort1: MatSort;

  loader1: boolean = false;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;
  filtroData$: Subscription;
  consultaDocumentoCobranza$: Subscription;
  detalleConsulta$: Subscription;
  enviarMensajesWhatsapp$: Subscription;

  filtro: FiltroConsulta;

  monedas: any[] = [];

  saldoTotalMN: number;
  saldoTotalME: number;

  proveedores: any[] = [];

  tiposDoc: any[] = [];

  selection = new SelectionModel<any>(true, []);

  cuentaSeleccionada: any;

  constructor(
    private _router: Router,
    private store: Store<PavsoState>,
    private _cajaBancoService: CajaBancoService,
    private _snackBarService: SnackBarService
  ) {

    this.filtro = new FiltroConsulta();

    this.dataSource1 = fillTable([], this.paginator1, this.sort1);

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  enviarVariosMensajesMail(): void {

    const mails = this.selection.selected.map(item => item.cli_corele);

    if(!sonCorreosValidos(mails)) {
      this._snackBarService.showError('Verificar si son mails validos', 'Ok');
      return;
    }

    console.log(this.selection.selected)

    let receptores = [];

    this.selection.selected.forEach(item => {
      receptores.push({
        to: item.cli_corele,
        cc: "",
        subject: "Correo de prueba consulta cobranza",
        data: {},
        message: "AAAAA",
      })
    })

    console.log('receptores', receptores)

    const payload = {
      messages: receptores
    }

    this._cajaBancoService.enviarMensajesMasivoMail(payload).subscribe(
      response => {
        this._snackBarService.showSuccess('Mensajes enviados por mail', 'Ok');
      },
      error => {
        this._snackBarService.showError('Error al enviar mensajes por mail', 'Ok');
      }
    )

  }

  enviarVariosMensajesWhatsapp(): void {

    const phones = this.selection.selected.map(item => item.cli_numtlf);

    if(!sonCelularesValidos(phones)) {
      this._snackBarService.showError('Verificar si son números de teléfono validos', 'Ok');
      return;
    }
    console.log(this.selection.selected)

    let receptores = [];

    this.selection.selected.forEach(item => {
      receptores.push({
        number: item.cli_numtlf,
        message: `Hola, buenos días ${item.cli_nomcli}`,
      })
    })

    console.log('receptores', receptores)

    const payload = {
      messages: receptores
    }

    this._cajaBancoService.enviarMensajesMasivoWhatsapp(payload).subscribe(
      response => {
        this._snackBarService.showSuccess('Mensajes enviados por whatsaapp', 'Ok');
      },
      error => {
        this._snackBarService.showError('Error al enviar mensajes por whatsapp', 'Ok');
      }
    )


  }

  seleccionarConsulta(row): void {
    console.log(row)
    this.cuentaSeleccionada = row;
    this.loaderDetalle = true;
    this.detalleConsulta$ = this._cajaBancoService.obtenerDetalleConsultaDocumentoTesoreria(row.CCO_CODCCO).subscribe(
      data => {

        data.forEach(item => {

          item['lbc_fec'] = formatDateWithSlash(item['lbc_fec']);
          item['PBA_FECORD'] = formatDateWithSlash(item['PBA_FECORD']);
          item['LBD_IMPMNA'] = item['LBD_IMPMNA'].toFixed(2);
          item['LBD_IMPMEX'] = item['LBD_IMPMEX'].toFixed(2);
          item['LBD_TIPCAM'] = item['LBD_TIPCAM'].toFixed(3);

          let a = item['Registro'].slice(0, 6);
          let b = item['Registro'].slice(6, 10);
          let c = item['Registro'].slice(10, item['Registro'].length);

          item['Registro'] = `${a}-${b}-${c}`;
          console.log({a, b, c})

        });

        this.dataSource1 = fillTable(data, this.paginator1, this.sort1);

        this.loaderDetalle = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener detalle de consulta cobranza', 'Ok');
        this.loaderDetalle = false;
      }
    )
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule)  this.loadData()
    })
  }

  loadData(): void {
    this.loaderData = true;

    this.filtroData$ = this._cajaBancoService.obtenerDataFiltroConsultaTesoreria().subscribe(
      dataFiltro => {
        console.log('data filtro', dataFiltro)

        this.monedas = dataFiltro.monedas;

        this.proveedores = dataFiltro.clientes;

        this.tiposDoc = dataFiltro.tiposDoc;

        this.consultaDocumentoCobranza$ = this._cajaBancoService.obtenerConsultaDocumentoTesoreria(this.filtro).subscribe(
          response => {
            console.log('response', response)
            this.saldoTotalMN = 0;
            this.saldoTotalME = 0;
            response.forEach(item => {
              this.saldoTotalMN += item['cco_salnac'];
              this.saldoTotalME += item['cco_saldol'];
              item['CCO_FECEMIFormat'] = formatDateWithSlash(item['CCO_FECEMI']);
              item["cli_numtlf"] = item["cli_numtlf"].trim();
              item['cco_fecven1'] = item['cco_fecven'];
              item['cco_fecvenformat'] = formatDateWithSlash(item['cco_fecven']);
              item['cco_impnet'] = this.filtro.moneda == 'SO' ? item['cco_impsol'].toFixed(2): item['cco_impdol'].toFixed(2);
              item['cco_abonet'] = this.filtro.moneda == 'SO' ? item['cco_abosol'].toFixed(2): item['cco_abodol'].toFixed(2);
              item['cco_salnet'] = this.filtro.moneda == 'SO' ? item['cco_salnac'].toFixed(2): item['cco_saldol'].toFixed(2);

            })

            this.saldoTotalMN = parseFloat(numberWithCommas(this.saldoTotalMN.toFixed(2)));
            this.saldoTotalME = parseFloat(numberWithCommas(this.saldoTotalME.toFixed(2)));

            this.loaderData = false;

            // Filtrar por situación
            const registrosFiltrados = this.filtrarPorSituacion(response);

            this.dataSource = fillTable(registrosFiltrados, this.paginator, this.sort);

          },
          error => {
            this._snackBarService.showError('Error al obtener data de consulta de documentos de cobranza', 'Ok')
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener filtros', 'Ok')
        this.loaderData = false;
      }
    )
  }

  filtrarPorSituacion(registros): Array<any> {

    if(this.filtro.situacion == 'pendientes') {
      const campoMoneda = this.filtro.moneda;

      const filtrados = registros.filter(item => item[campoMoneda == 'SO' ? 'cco_salnac': 'cco_saldol' ] != "0.00")
      return filtrados;

    }

    if(this.filtro.situacion == 'vencidos') {

      const filtrados = registros.filter(item => {

        const comparacion = estaFechaEsMayorOIgualQue(new Date(), item.cco_fecven1)
        if( item.cco_impsal != 0 && comparacion) {
          return item;
        }

      })

      return filtrados;
    }

    if(this.filtro.situacion == 'porVencer') {

      let dt = new Date();
      dt.setDate(dt.getDate() + 7);

      const filtrados = registros.filter(item => {

        const comparacion = estaFechaEsMayorOIgualQue(dt, item.cco_fecven1)
        if( item.cco_impsal != 0 && comparacion) {
          return item;
        }

      })

      return filtrados;
    }

    if(this.filtro.situacion == 'cancelados') {
      const campoMoneda = this.filtro.moneda;

      const filtrados = registros.filter(item => item[campoMoneda == 'SO' ? 'cco_salnac': 'cco_saldol' ] == "0.00")
      return filtrados;
    }

    if(this.filtro.situacion == 'anulados') {
      const filtrados = registros.filter(item => item.CCO_INDSTA == '0')
      return filtrados;
    }

    return registros;
  }



  buscarRegistros(): void {
    this.loaderData = true;
    this.consultaDocumentoCobranza$ = this._cajaBancoService.obtenerConsultaDocumentoTesoreria(this.filtro).subscribe(
      response => {
        this.saldoTotalMN = 0;
        this.saldoTotalME = 0;
        response.forEach(item => {
          this.saldoTotalMN += item['cco_salnac'];
          this.saldoTotalME += item['cco_saldol'];
          item['CCO_FECEMIFormat'] = formatDateWithSlash(item['CCO_FECEMI']);
          item['cco_fecven1'] = item['cco_fecven'];
          item['cco_fecvenformat'] = formatDateWithSlash(item['cco_fecven']);
          item['cco_impnet'] = this.filtro.moneda == 'SO' ? item['cco_impsol'].toFixed(2): item['cco_impdol'].toFixed(2);
          item['cco_abonet'] = this.filtro.moneda == 'SO' ? item['cco_abosol'].toFixed(2): item['cco_abodol'].toFixed(2);
          item['cco_salnet'] = this.filtro.moneda == 'SO' ? item['cco_salnac'].toFixed(2): item['cco_saldol'].toFixed(2);

        })

        this.saldoTotalMN = parseFloat(numberWithCommas(this.saldoTotalMN.toFixed(2)));
        this.saldoTotalME = parseFloat(numberWithCommas(this.saldoTotalME.toFixed(2)));

        // Filtrar por situación
        const registrosFiltrados = this.filtrarPorSituacion(response);

        this.dataSource = fillTable(registrosFiltrados, this.paginator, this.sort);

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener data de consulta de documentos de cobranza', 'Ok')
        this.loaderData = false;
      }
    )
  }

  registrarCuenta(f: NgForm): void {

  }


  seleccionarMoneda(moneda): void {
    this.filtro.moneda = moneda;

    this.saldoTotalMN = 0;
    this.saldoTotalME = 0;

    this.dataSource.data.forEach(item => {
      this.saldoTotalMN += item['cco_salnac'];
      this.saldoTotalME += item['cco_saldol'];
      item['CCO_FECEMIFormat'] = formatDateWithSlash(item['CCO_FECEMI']);
      item['cco_fecven1'] = item['cco_fecven'];
      item['cco_fecvenformat'] = formatDateWithSlash(item['cco_fecven']);
      item['cco_impnet'] = this.filtro.moneda == 'SO' ? item['cco_impsol'].toFixed(2): item['cco_impdol'].toFixed(2);
      item['cco_abonet'] = this.filtro.moneda == 'SO' ? item['cco_abosol'].toFixed(2): item['cco_abodol'].toFixed(2);
      item['cco_salnet'] = this.filtro.moneda == 'SO' ? item['cco_salnac'].toFixed(2): item['cco_saldol'].toFixed(2);

    })

    this.saldoTotalMN = parseFloat(numberWithCommas(this.saldoTotalMN.toFixed(2)));
    this.saldoTotalME = parseFloat(numberWithCommas(this.saldoTotalME.toFixed(2)));

    // Filtrar por situación
    const registrosFiltrados = this.filtrarPorSituacion(this.dataSource.data);

    this.dataSource = fillTable(registrosFiltrados, this.paginator, this.sort);

  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.filtroData$,
      this.consultaDocumentoCobranza$,
      this.detalleConsulta$,
    ])
  }
}
