import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OrderPdf } from 'src/app/models';
import { ConfigurationService, VentasService } from 'src/app/services';
import { GenerateExcelComponent } from '@shared/components/dialogs/generate-excel/generate-excel.component';
import { SendEmailComponent } from '@shared/components/dialogs/send-email/send-email.component';
import * as pdfBuilder from '@utils/pdfmaker/builder/ventas.pdfmaker';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { PedidoService } from 'src/app/services/api/ventas/operaciones/pedido.service';
import { IParam } from 'src/app/services/api/utilities/params';
import { ConfiguracionTecnicaVentas } from 'src/app/models/ventas/configuracion-tecnica-ventas';
import { ordenerArregloJSONxLlave } from '@utils/array/order';
import { ConfigState } from '@data/interfaces/state/config-state';

@Component({
  selector: 'app-pedido-list',
  templateUrl: './pedido-list.component.html',
  styleUrls: ['./pedido-list.component.css']
})
export class PedidoListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['acciones', 'PCC_NUMPCC', 'CLI_CODCLI', 'cli_nomcli', 'PCC_FECDOC', 'tmo_codtmo', 'PCC_IMPTOT', 'vde_nomvde', 'PCC_CODUSE', 'PCC_INDSTA'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  orderPdf: OrderPdf;

  finalDate = new Date();
  initialDate = new Date(`${this.finalDate.getMonth() + 1}/01/${this.finalDate.getFullYear()}`);

  pedidosTotales = [];

  year = new Date().getFullYear();
  month = new Date().getMonth();

  range = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date(this.year, this.month + 1, 0))
  });

  buttonsName: INameConstant = NAMES_CONSTANTS;

  cambioEstadoPedido$: Subscription;
  mostrarPedido$: Subscription;
  obtenerPedido$: Subscription;
  loading$: Subscription;
  perido$: Subscription;
  config$: Subscription;
  establecimiento$: Subscription;
  configuracionTecnica$: Subscription;

  visibility: 'visible' | 'hidden' = 'hidden';

  anioActividad: string;
  mesActividad: string;

  loaderData: boolean;

  establecimiento: string;

  configuracionTecnicaVentas: ConfiguracionTecnicaVentas;

  logo: string;
  config: ConfigState;

  constructor(
    private _dialogService: DialogService,
    private _router: Router,
    private _ventasService: VentasService,
    private _configurationService: ConfigurationService,
    private _snackBarService: SnackBarService,
    private _pedidoService: PedidoService,
    public dialog: MatDialog,
    private store: Store<PavsoState>
  ) {
    this.configuracionTecnicaVentas = new ConfiguracionTecnicaVentas();
    this.dataSource = fillTable([], this.paginator, this.sort);

    this.orderPdf = new OrderPdf();

    this.perido$ = this.store.select('period').subscribe(state => {
      this.anioActividad = state.year;
      this.mesActividad = state.month;
    })

    this

    this.establecimiento$ = this.store.select('establishment').subscribe(state => {
      this.establecimiento = state.code;
    })
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.obtenerPedidos();
    })

    this.config$ = this.store.select('config').subscribe(state => {
      this.logo = state.logo;
      this.config = state;
    })
  }

  cambiarEstado(valor): void {
    let pedidosFiltrados = [];
    if (valor == "TODOS") {
      this.dataSource = fillTable(this.pedidosTotales, this.paginator, this.sort);
      return;
    }

    this.pedidosTotales.forEach(element => {

      if (element.PCC_INDSTA == valor) pedidosFiltrados.push(element);

    });

    this.dataSource = fillTable(pedidosFiltrados, this.paginator, this.sort);

  }

  generarFactura(row): void {
    let temp = JSON.stringify({ temp: { order: row } });
    localStorage.setItem('temp', temp);

    this._router.navigate(['generar-factura']);
  }

  seleccionarVendedor(codvde): void {

  }

  obtenerPedidos(): void {
    console.log('pedidos', 'response')
    this.loaderData = true;

    this.configuracionTecnica$ = this._ventasService.obtenerConfiguracionTecnicaVentas().subscribe(
      response => {
        this.configuracionTecnicaVentas = response;
        console.log('configuracionTecnicaVentas', response)
        let params: Array<IParam> = [
          {
            param: 'anio',
            value: this.anioActividad
          },
          {
            param: 'mes',
            value: this.mesActividad
          },
        ];

        this.obtenerPedido$ = this._pedidoService.obtenerPedidos('01', { params }).subscribe(
          response => {

            let pedidos = this.establecimiento != '' && this.establecimiento != undefined && this.establecimiento != null
            ? response.filter(pedido => pedido.TIE_CODTIE.trim() == this.establecimiento)
            : response;

            //Filtrar por el terminado (depende del indicador de configuracion s71_nverpt)
            if(this.configuracionTecnicaVentas.s71_nverpt == 1) {
              pedidos = pedidos.filter(pedido => pedido.PCC_INDTER == '0');
            }

            if(this.config.vendedorId != '' && this.config.vendedorId != null) {
              console.log('vendedorId', this.config.vendedorId)
              pedidos = pedidos.filter(pedido => pedido.VDE_CODVDE == this.config.vendedorId);
            }


            this.dataSource = fillTable(ordenerArregloJSONxLlave( 'PCC_NUMPCC', pedidos ), this.paginator, this.sort);

            this.pedidosTotales = response;
            this.loaderData = false;

          },
          error => {
            this._snackBarService.showError(error.error.msg, 'OK')
            this.loaderData = false;
          }
        )

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK')
        this.loaderData = false;
      }
    )

  }

  imprimirPDF(pedido_id): void {
    this.mostrarPedido$ = this._ventasService.mostrarPdfPedido(pedido_id).subscribe(
      response => {
        console.log(response)
        if (response.length > 0) {
          this.orderPdf = response;
          const headers = {
            CIA_NOMCIA: this._configurationService.obtenerNombreCliente(),
            CIA_NUMRUC: response[0].CIA_NUMRUC,
            CIA_DIRCIA: response[0].CIA_DIRCIA,
            logo: this.logo,
            username: this._configurationService.obtenerIdUsuario()
          }

          pdfBuilder.crearFormatoPedidoPdf(headers, this.orderPdf[0])
          return;

        }

        this._snackBarService.showError('No se encontro el pdf de pedido', 'OK');

      },
      error => {
        console.log('error', error)
        this._snackBarService.showError(error.error.msg, 'OK');
      }
    )
  }

  generarExcel(): void {

    const dialogRef = this.dialog.open(GenerateExcelComponent, {
      width: '800px',
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => { })

    /*
    let pedidos = [];
    this.dataSource.data.forEach(element => {
      pedidos.push({
        Pedido: element.pcc_numpcc,
        Fecha: '',
        Referencia: "",
        "Fecha de entrega": "",
        Vendedor: element.vde_nomvde,
        Cliente: element.cli_nomcli,
        "Precio (Inc. IGV)": "",
        "Id. Producto": "",
        "Producto": "",
        "Cantidad": "",
        "Entregado": "",
        "Facturado": element.PCC_IMPTOT,
        "Cond. Pago": "",
        "Zona de Venta": "",
        "Ubicacion": "",
        "Lugar de despacho": "",
        "Referencia Ubicacion": "",
      })
    });

    this.excelService.exportAsExcelFile(pedidos, 'sample');
    */
  }

  enviarCorreo(pedido_id): void {
    const dialogRef = this.dialog.open(SendEmailComponent, {
      width: '300px',
      data: {}
    })

    dialogRef.afterClosed().subscribe(result => { })
  }

  aprobar(pedido_id): void {
    this.cambioEstadoPedido$ = this._ventasService.cambiarEsdoPedido(pedido_id, 1).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Pedido Aprobado', '300px', '', '');
        this.obtenerPedidos();
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  desaprobar(pedido_id): void {
    this.cambioEstadoPedido$ = this._ventasService.cambiarEsdoPedido(pedido_id, 2).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Pedido Desaprobado', '300px', '', '');
        this.obtenerPedidos();
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  establecerPendiente(pedido_id): void {
    this.cambioEstadoPedido$ = this._ventasService.cambiarEsdoPedido(pedido_id, 5).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Se Estableció el Pedido a Pendiente', '300px', '', '');
        this.obtenerPedidos();
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  obtenerHora(): string {
    let date = new Date()
    const hour = date.getHours();
    const minute = date.getMinutes();
    const seconds = date.getSeconds();
    return `${hour}:${minute}:${seconds}`;
  }

  formatDate(date): string {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  ngOnDestroy(): void {

    unsubscribeSubscription([
      this.cambioEstadoPedido$,
      this.mostrarPedido$,
      this.obtenerPedido$,
      this.loading$,
      this.perido$,
      this.establecimiento$,
      this.configuracionTecnica$
    ])

  }

  cambiar(): void {
    this.visibility = this.visibility == 'hidden' ? 'visible' : 'hidden';
  }

}
