import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { ComprasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { DialogService } from '@shared/services/dialog.service';
import { CondicionPago } from 'src/app/models/compras/condicion-pago';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-condicion-pago-form',
  templateUrl: './condicion-pago-form.component.html',
  styleUrls: ['./condicion-pago-form.component.css']
})
export class CondicionPagoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  condicionPago: CondicionPago;

  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  documento$: Subscription;
  loading$: Subscription;

  uid: string;

  constructor(
    private _router: Router,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
    private _comprasService: ComprasService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute
  ) {

    this.condicionPago = new CondicionPago();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      this.uid == '0' ? this.btnName = this.buttonsName.BTN_STORE : this.btnName = this.buttonsName.BTN_UPDATE;
      if(this.uid == '0') {
        this.loaderData = false;
        return;
      }
      this.obtenerCondicionPago();
    })
  }

  obtenerCondicionPago(): void {
    this._comprasService.obtenerCondicionesPago().subscribe(
      condiciones => {
        this.loaderData = false;
        this.condicionPago = condiciones.find(item => item.CPA_CODCPA == this.uid);
      },
      error => {
        this.loaderData = false;
        this._snackBarService.showError('Error al obtener condicion de pago', 'Ok');
      }
    )
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarCondicion(f): this.actualizarCondicion(f);
  }

  registrarCondicion(f: NgForm): void {
    this.loaderReg = true;
    this._comprasService.registrarCondicionPago(this.condicionPago).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Condición de pago registrado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  actualizarCondicion(f: NgForm): void {
    this.loaderReg = true;
    this._comprasService.actualizarCondicionPago(this.condicionPago).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Condición de pago actualizado', '400px', '400px', '');
        this.loaderReg = false;
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderReg = false;
      }
    )
  }

  volver(): void {
    this._router.navigate(['/modulo-compras/condiciones-de-pago']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([this.documento$, this.loading$])
  }

}
