import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { MensajeWhatsappCuotaPorVencer } from '@data/interfaces/modules/cuentas-x-cobrar/mensaje-whatsapp-cuota-por-vencer';
import { MensajeWhatsappCuotaVencida } from '@data/interfaces/modules/cuentas-x-cobrar/mensaje-whatsapp-cuota-vencida';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { SnackBarService } from '@shared/services/snackbar.service';
import { estaFechaEsMayorOIgualQue } from '@utils/date/compare';
import { esLaMismaFecha } from '@utils/date/date';
import { formatDateWithSlash } from '@utils/formats/date.format';
import { numberWithCommas } from '@utils/formats/number.format';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { sonCelularesValidos, sonCorreosValidos } from '@utils/validations/mail';
import { Subject, Subscription } from 'rxjs';
import { FiltroConsulta } from 'src/app/models/cobranzas/filtro-consulta';
import { ConfigurationService, CuentaCobrarService } from 'src/app/services';
import { ConsultaCobranzaService } from 'src/app/services/api/cuentas-por-cobrar/consulta/consulta-cobranza.service';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-consulta-cobranza',
  templateUrl: './consulta-cobranza.component.html',
  styleUrls: ['./consulta-cobranza.component.css']
})
export class ConsultaCobranzaComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;
  loaderDetalle: boolean = false;

  displayedColumns: string[] = ['select', 'CCO_CODCCO', 'TDO_CODTDO', 'CCO_NUMDOC', 'CCO_FECEMIFormat', 'cli_nomcli', 'cco_fecvenformat', 'cco_diaven', 'TMO_CODREP', 'cco_impnet', 'cco_abonet', 'cco_salnet', 'dcc_indwsp', 'dcc_fecwsp', 'vde_nomvde', 'cli_numtlf', 'cli_corele', 'PCC_NUMPCC', 'CBA_CODCBA', 'ban_nomabr', 'lcc_descri', 'sdc_descri', 'cpa_descpa', 'dcc_notmai'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns1: string[] = ['acciones', 'fec_emision', 'req_contable', 'sud_diario', 'doc_operacion', 'num_operacion', 'fec_operacion', 'imp_nacional', 'imp_extranjero', 'cuenta_contable', 'tip_cambio', 'linea', 'situacion', 'tip_doc_ref', 'doc_rec', 'glosa'];
  dataSource1: MatTableDataSource<any>;

  @ViewChild('paginatorDetalle') paginator1: MatPaginator;
  @ViewChild(MatSort) sort1: MatSort;

  loader1: boolean = false;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  loading$: Subscription;
  filtroData$: Subscription;
  consultaDocumentoCobranza$: Subscription;
  detalleConsulta$: Subscription;
  enviarMensajesWhatsapp$: Subscription;
  establecerLogsMensajesWhatsapp$: Subscription;

  filtro: FiltroConsulta;

  monedas: any[] = [];

  saldoTotalMN: number;
  saldoTotalME: number;

  clientes: any[] = [];
  tiposDoc: any[] = [];

  loaderSendMail: boolean;
  loaderSendWhatsapp: boolean;

  protected _onDestroy = new Subject<void>();

  selection = new SelectionModel<any>(true, []);

  cuentaSeleccionada: any;

  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(
    private _router: Router,
    private store: Store<PavsoState>,
    private _cuentaCobrarService: CuentaCobrarService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private _consultaCobranzaService: ConsultaCobranzaService,
    private _dateService: DateService,
    private breakpointObserver: BreakpointObserver,

  ) {

    this.filtro = new FiltroConsulta();

    this.dataSource1 = fillTable([], this.paginator1, this.sort1);

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  enviarVariosMensajesMail(): void {

    this.loaderSendMail = true;

    const mails = this.selection.selected.map(item => item.cli_corele);

    if (mails.length == 0) {
      this._snackBarService.showError('Seleccione al menos un registro', 'Ok');
      this.loaderSendMail = false;
      return;

    }

    if (!sonCorreosValidos(mails)) {
      this._snackBarService.showError('Verificar si son mails validos', 'Ok');
      this.loaderSendMail = false;
      return;
    }

    console.log(this.selection.selected)

    let receptores = [];

    this.selection.selected.forEach(item => {
      receptores.push({
        email: item.cli_corele,
        cc: "",
        client_name: item.cli_nomcli,
        dias_vencer: this.filtro.diasVencer,
        fecha_vencimiento: item.cco_fecvenformat,
        monto: item.TMO_CODREP + item.CCO_SALCCO,
        banco: item.ban_nomabr,
        cuenta: item.CBA_NUMCTA,
        cci: item.cba_numcci,
        empresa: item.cli_nomcli
      })
    })

    console.log('receptores', receptores)

    const payload = {
      messages: receptores
    }

    this._cuentaCobrarService.enviarCorreoMasivoLetrasPorVencer(payload).subscribe(
      response => {
        this._snackBarService.showSuccess('Mensajes enviados por mail', 'Ok');
        this.loaderSendMail = false;
      },
      error => {
        this._snackBarService.showError('Error al enviar mensajes por mail', 'Ok');
        this.loaderSendMail = false;
      }
    )

    if (this.filtro.situacion == 'porVencer') {
    } else if (this.filtro.situacion == 'vencidos') {
      const documentosNoRepetidos = this.filtrarDocumentosRepetidos(this.selection.selected);

      const rucs = documentosNoRepetidos.map(item => item.CLI_CODCLI);
      this._cuentaCobrarService.generarContenidoTenorLetrasVencidas({ rucs }).subscribe(
        response => {
          console.log('rucs', response)
          console.log('documentosNoRepetidos', documentosNoRepetidos)
          this.loaderSendWhatsapp = false;
        }
      )

    } else {
      this.loaderSendWhatsapp = false;
    }

  }

  enviarVariosMensajesWhatsapp(): void {

    // this.selection.selected.forEach(element => {

    //   console.log('dias_vencer', Math.abs(element.cco_diaven))
    //   console.log('fecha_vencimiento', element.cco_fecven)

    // });

    // return ;

    this.loaderSendWhatsapp = true;

    const phones = this.selection.selected.map(item => item.cli_numtlf);

    if (phones.length == 0) {

      this._snackBarService.showError('Seleccione al menos un registro', 'Ok');
      this.loaderSendWhatsapp = false;
      return;

    }

    if (!sonCelularesValidos(phones)) {
      this._snackBarService.showError('Verificar si son números de teléfono validos', 'Ok');
      return;
    }


    if (this.filtro.situacion == 'porVencer') {
      const payload: { messages: Array<MensajeWhatsappCuotaPorVencer> } = {
        messages: [],
      }

      payload.messages = this.selection.selected.map(item => ({
        phone: item.cli_numtlf,
        client_name: item.cli_nomcli.trim(),
        dias_vencer: Math.abs(item.cco_diaven),
        fecha_vencimiento: item.cco_fecvenformat,
        monto: `${item.TMO_CODREP} ${item.cco_impnet}`,
        cuenta: item.CBA_NUMCTA ? item.CBA_NUMCTA.trim() : '424782348472834723',
        banco: item.ban_nomabr ? item.ban_nomabr.trim() : 'BANCO DE CRÉDITO',
        cci: item.cba_numcci ? item.cba_numcci.trim() : '23423489-234234-23423',
        empresa: item.gci_descri.trim().substring(0, 30)
      }))

      const payloadUpdateLogs: { cia_codcia: string, estado: boolean, documentos: Array<string> } = {
        cia_codcia: this._configurationService.obtenerCompaniaCliente(),
        estado: true,
        documentos: this.selection.selected.map(item => item.CCO_CODCCO)
      };

      // console.log('payload', payload)
      // console.log('payloadUpdateLogs', payloadUpdateLogs)

      // this.loaderSendWhatsapp = false;
      // return;

      this.enviarMensajesWhatsapp$ = this._cuentaCobrarService.enviarMensajesMasivoWhatsappPorVencer(payload).subscribe(
        response => {

          this.loaderSendWhatsapp = false;

          this.establecerLogsMensajesWhatsapp$ = this._consultaCobranzaService.actualizarLogsUltimosMensajesWhatsapp(payloadUpdateLogs).subscribe(
            response => {
              this._snackBarService.showSuccess('Mensajes enviados por whatsaapp', 'Ok');
              this.loaderSendWhatsapp = false;
              this.buscarRegistros();

            }, error => {
              this.loaderSendWhatsapp = false;
            }
          )

        },
        error => {
          this._snackBarService.showError('Error al enviar mensajes por whatsapp', 'Ok');
          this.loaderSendWhatsapp = false;
        }
      )
    } else if (this.filtro.situacion == 'vencidos') {
      const documentosNoRepetidos = this.filtrarDocumentosRepetidos(this.selection.selected);

      const rucs = documentosNoRepetidos.map(item => item.CLI_CODCLI);

      this._cuentaCobrarService.generarContenidoTenorLetrasVencidas({ rucs }).subscribe(
        response => {
          console.log('tenor letras vencidas', response)

          const payload: { messages: Array<MensajeWhatsappCuotaVencida> } = {
            messages: [],
          }

          // Agregar los campos de cuotas y descripcion de cuotas al array de documentos
          documentosNoRepetidos.forEach((documento, index) => {
            const tenor = response[index];
            documento['cuotas'] = tenor.canti;
            documento['descripcion_cuotas'] = tenor.letras;
          });

          // Agregar los campos de cuotas y descripcion de cuotas al payload final
          documentosNoRepetidos.forEach(item => {
            payload.messages.push({
              phone: item.cli_numtlf,
              client_name: item.cli_nomcli.trim(),
              cuotas: item.cuotas,
              descripcion_cuotas: item.descripcion_cuotas,
              cuenta: item.CBA_NUMCTA ? item.CBA_NUMCTA.trim() : '424782348472834723',
              banco: item.ban_nomabr ? item.ban_nomabr.trim() : 'BANCO DE CRÉDITO',
              cci: item.cba_numcci ? item.cba_numcci.trim() : '23423489-234234-23423',
              empresa: item.gci_descri.trim().substring(0, 33)
            })
          });

          const payloadUpdateLogs: { cia_codcia: string, estado: boolean, documentos: Array<string> } = {
            cia_codcia: this._configurationService.obtenerCompaniaCliente(),
            estado: true,
            documentos: this.selection.selected.map(item => item.CCO_CODCCO)
          };

          // console.log('payload', payload)
          // console.log('payloadUpdateLogs', payloadUpdateLogs)
          // this.loaderSendWhatsapp = false;
          // return;

          this.enviarMensajesWhatsapp$ = this._cuentaCobrarService.enviarMensajesMasivoWhatsappVencidas(payload).subscribe(
            respuesta => {

              this.loaderSendWhatsapp = false;

              this.establecerLogsMensajesWhatsapp$ = this._consultaCobranzaService.actualizarLogsUltimosMensajesWhatsapp(payloadUpdateLogs).subscribe(
                response => {
                  this._snackBarService.showSuccess('Mensajes enviados por whatsaapp', 'Ok');
                  this.loaderSendWhatsapp = false;
                  this.buscarRegistros();


                }, error => {
                  this.loaderSendWhatsapp = false;
                }
              )

            },
            error => {
              this._snackBarService.showError('Error al enviar mensajes por whatsapp', 'Ok');
              this.loaderSendWhatsapp = false;
            }
          )
        }
      )
    } else {
      this.loaderSendWhatsapp = false;

    }

  }

  filtrarDocumentosRepetidos(documentos: Array<any>): Array<any> {

    const uniqueCliCodcli = {};
    const uniqueCliCodcliArray = [];

    documentos.forEach(item => {
      const cliCodcli = item.CLI_CODCLI.trim(); // Elimina espacios en blanco al inicio y al final del código
      if (!uniqueCliCodcli[cliCodcli]) {
        uniqueCliCodcli[cliCodcli] = true;
        uniqueCliCodcliArray.push(item);
      }
    });

    return uniqueCliCodcliArray;

  }

  seleccionarConsulta(row): void {
    console.log(row)
    this.cuentaSeleccionada = row;
    this.loaderDetalle = true;
    this.detalleConsulta$ = this._cuentaCobrarService.obtenerDetalleConsultaDocumentoCobranza(row.CCO_CODCCO).subscribe(
      data => {

        data.forEach(item => {

          item['lbc_fec'] = formatDateWithSlash(item['lbc_fec']);
          item['PBA_FECORD'] = formatDateWithSlash(item['PBA_FECORD']);
          item['LBD_IMPMNA'] = item['LBD_IMPMNA'].toFixed(2);
          item['LBD_IMPMEX'] = item['LBD_IMPMEX'].toFixed(2);
          item['LBD_TIPCAM'] = item['LBD_TIPCAM'].toFixed(3);

          let a = item['Registro'].slice(0, 6);
          let b = item['Registro'].slice(6, 10);
          let c = item['Registro'].slice(10, item['Registro'].length);

          item['Registro'] = `${a}-${b}-${c}`;
          console.log({ a, b, c })

        });

        this.dataSource1 = fillTable(data, this.paginator1, this.sort1);

        this.loaderDetalle = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener detalle de consulta cobranza', 'Ok');
        this.loaderDetalle = false;
      }
    )
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData()
    })
  }

  loadData(): void {
    this.loaderData = true;

    this.filtroData$ = this._cuentaCobrarService.obtenerDataFiltroConsultaCuentaCobrar().subscribe(
      dataFiltro => {
        console.log('data filtro', dataFiltro)
        this.clientes = dataFiltro.clientes.filter(item => item.cli_indcli == 1);

        this.monedas = dataFiltro.monedas;
        this.tiposDoc = dataFiltro.tiposDoc;

        this.buscarRegistros();

      },
      error => {
        this._snackBarService.showError('Error al obtener filtros', 'Ok')
        this.loaderData = false;
      }
    )
  }

  verificarNotificacionCheckbox(e, row): void {

    if (!e.checked) {
      e ? this.selection.toggle(row) : null
      return;
    }

    // Verifica si ya se envio notificacion por whatsapp
    if (esLaMismaFecha(new Date(), row.dcc_notwsp)) {
      alert('Ya se realizó la notificación Whatsapp !!!')
    }

    // Verifica si ya se envio notificacion por mail
    if (esLaMismaFecha(new Date(), row.dcc_notmai)) {
      alert('Ya se realizó la notificación por Correo !!!')
    }

    e ? this.selection.toggle(row) : null
  }

  seleccionarSituacion(): void {
    if (this.filtro.situacion != 'porVencer') {
      this.filtro.diasVencer = null;
      return;
    }

    this.filtro.diasVencer = 7;
  }

  filtrarPorSituacion(registros): Array<any> {

    if (this.filtro.situacion == 'pendientes') {
      const campoMoneda = this.filtro.moneda;

      const filtrados = registros.filter(item => item[campoMoneda == 'SO' ? 'cco_salnac' : 'cco_saldol'] != "0.00")
      return filtrados;

    }

    if (this.filtro.situacion == 'vencidos') {

      const filtrados = registros.filter(item => {

        const comparacion = estaFechaEsMayorOIgualQue(new Date(), item.cco_fecven1)
        if (item.cco_salnet > 0 && comparacion) {
          return item;
        }

      })

      return filtrados;
    }

    if (this.filtro.situacion == 'porVencer') {

      const filtrados = registros.filter(item => {

        const diasPorVencer = this.obtenerDiasPorVencer(item.cco_fecven)
        item['diasPorVencer'] = diasPorVencer;
        if (item.CCO_NUMDOC.trim() == '2200003723') {
          console.log('diasvencer', `${diasPorVencer}`)
        }
        if (item.cco_diaven < 0 && item.cco_salnet > 0) {
          if ((Math.abs(item.cco_diaven) <= this.filtro.diasVencer) && (diasPorVencer >= 0)) return item
        }

      })

      return filtrados;
    }

    if (this.filtro.situacion == 'cancelados') {
      const campoMoneda = this.filtro.moneda;

      const filtrados = registros.filter(item => item[campoMoneda == 'SO' ? 'cco_salnac' : 'cco_saldol'] == "0.00")
      return filtrados;
    }

    if (this.filtro.situacion == 'anulados') {
      const filtrados = registros.filter(item => item.CCO_INDSTA == '0')
      return filtrados;
    }



    return registros;
  }

  obtenerDiasPorVencer(date: Date | string): number {
    const fechaEstablecida = new Date(date);
    const fechaActual = new Date();

    // Establece la fecha específica

    // Calcula la diferencia en milisegundos
    var diferenciaEnMilisegundos: number = fechaEstablecida.getTime() - fechaActual.getTime();

    // Convierte la diferencia a días
    var diferenciaEnDias: number = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));

    return diferenciaEnDias;
  }

  limpiarFiltro(): void {
    this.filtro.clientes = [];
    this.filtro.situacion = 'vencidos';
    this.filtro.tiposDocumento = [];
    this.filtro.moneda = 'DO';
    this.filtro.diasVencer = null;
    this.filtro.tiposDocumento = ['LET'];
  }

  buscarRegistros(): void {

    this.loaderData = true;

    // if (this.filtro.clientes.length == 0) {
    //   this._snackBarService.showError('Debe seleccionar al menos un cliente', 'Ok')
    //   this.loaderData = false;

    //   return;
    // }
    this.dataSource = fillTable([], this.paginator, this.sort);

    this.consultaDocumentoCobranza$ = this._cuentaCobrarService.obtenerConsultaDocumentoCobranza(this.filtro).subscribe(
      response => {
        console.log('resulado de consulta', response)
        console.log('resulado de consulta str', JSON.stringify(response))

        response.forEach(item => {
          this.saldoTotalMN += item['cco_salnac'];
          this.saldoTotalME += item['cco_saldol'];

          item['CCO_FECEMIFormat'] = formatDateWithSlash(this._dateService.convertirFechaALocal(item['CCO_FECEMI']));
          item['cco_fecven1'] = item['cco_fecven'];
          item['cco_fecvenformat'] = formatDateWithSlash(this._dateService.convertirFechaALocal(item['cco_fecven']));
          item['cco_impnet'] = this.filtro.moneda == 'SO' ? item['cco_impsol'].toFixed(2) : item['cco_impdol'].toFixed(2);
          item['cco_abonet'] = this.filtro.moneda == 'SO' ? item['cco_abosol'].toFixed(2) : item['cco_abodol'].toFixed(2);
          item['cco_salnet'] = this.filtro.moneda == 'SO' ? item['cco_salnac'].toFixed(2) : item['cco_saldol'].toFixed(2);
          item['seleccionado'] = false;

        })

        console.log('resultado de busqueda', response)

        this.saldoTotalMN = 0;
        this.saldoTotalME = 0;

        // Filtrar por situación
        const registrosFiltrados = this.filtrarPorSituacion(response);

        registrosFiltrados.forEach(element => {
          this.saldoTotalMN += element['cco_salnac'];
          this.saldoTotalME += element['cco_saldol'];
        });

        this.saldoTotalMN = numberWithCommas(this.saldoTotalMN.toFixed(2));
        this.saldoTotalME = numberWithCommas(this.saldoTotalME.toFixed(2));

        // Filtrar notificados
        let filtradoNotificados = [];

        if(this.filtro.notificados == 'SI') {

          filtradoNotificados = registrosFiltrados.filter(item => item.dcc_indwsp == 1)

        }

        if(this.filtro.notificados == 'NO') {

          filtradoNotificados = registrosFiltrados.filter(item => item.dcc_indwsp == 0)

        }

        if(this.filtro.notificados == 'TODOS') {

          filtradoNotificados = registrosFiltrados;

        }

        console.log('filtrados', filtradoNotificados)

        this.dataSource = fillTable(filtradoNotificados, this.paginator, this.sort);
        this.selection.clear();
        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener data de consulta de documentos de cobranza', 'Ok')
        this.loaderData = false;
      }
    )
  }

  registrarCuenta(f: NgForm): void {

  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.filtroData$,
      this.consultaDocumentoCobranza$,
      this.detalleConsulta$,
    ])
  }

}
