import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PavSearchDialog } from '../pav-input-search/pav-search-dialog/pav-search.dialog';

@Component({
  selector: 'pav-input-search-id',
  templateUrl: 'pav-input-search-id.component.html',
  styleUrls: ['pav-input-search-id.component.scss']
})

export class PavInputSearchIdComponent implements OnInit {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() codigo: string = null;
  @Input() descripcion: string = null;
  @Input() type: string;
  @Input() program: string;
  @Input() class: string;
  @Input() name: string;
  @Input() isResponsive: boolean;

  @Output() valueChange = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog
  ) {}

  ngOnInit() { }

  buscar(): void {
    const dialogRef = this.dialog.open(PavSearchDialog, {
      width: '400px',
      data: {
        program: this.program,
        label: this.label
      },
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result) {

        this.codigo = result.codigo;
        this.descripcion = result.descripcion;
        this.valueChange.emit(result);
        console.log('result', result)
      }

    });
  }

}
