import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../configuration.service';
import { DocumentoCtaCte, DocumentoCuentaContable, PlanCuenta, SaldoInicialConciliacion, SpCategoria, TipoCambioCierre, VigenciaImpuesto } from 'src/app/models';
import { TipoImpuesto } from 'src/app/models/contabilidad/tipo-impueto';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContabilidadService {
  constructor(
    private http: HttpClient,
    private _configurationService: ConfigurationService
  ) { }

  /**
   *
   * @returns
   */
  obtenerSubdiarios(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listasubdiario/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((choferes: any) => {
        return choferes.map(chofer => {
          chofer['description'] = `${chofer.sco_codsco} | ${chofer.sco_descri}`
          return chofer;
        })
      })
    );
  }

  /**
   *
   * @param sco
   * @returns
   */
  verSubdiario(sco): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/ver-subdiario/${this._configurationService.obtenerCompaniaCliente()}/${sco}`
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  mostrarSubdiarios(id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/mostrarsubdiario/${this._configurationService.obtenerCompaniaCliente()}/${id}`
    );
  }


  /**
   *
   * @param tga
   * @returns
   */
  verTipoDeOperacion(tga): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/ver-tipo-de-operacion/${this._configurationService.obtenerCompaniaCliente()}/${tga}`
    );
  }

  /**
   *
   * @param tga
   * @returns
   */
  obtenerObligacionesTributarias(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/obligaciones-tributarias`
    );
  }

  /**
   *
   * @returns
   */
  obtenerCuentasContable(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listacuentacon/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }
  obtenerCuentasContablePipe(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listacuentacon/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((cuentaContables: any) => {
        return cuentaContables.map(cuentaContable => {
          cuentaContable['description'] = `${cuentaContable.ccn_codccn} | ${cuentaContable.ccn_descri}`
          return cuentaContable;
        })
      })
    );
  }


  /**
   *
   * @returns
   */
  obtenerTiposBienServicio(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listatipobienserv`
    );
  }

  /**
   *
   * @returns
   */
  obtenerTiposRenta(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listatiporenta`
    ).pipe(
      map((tiposRenta: any) => {
        return tiposRenta.map(tipo => {
          tipo['description'] = `${tipo.t31_codt31} | ${tipo.t31_desct31}`
          return tipo;
        })
      })
    )
  }

  /**
   *
   */
  obtenerTiposImpuesto(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listatipoimpues`
    );
  }//aqui estoy tipo de impuesto

  obtenerTiposImpuestoPipe(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listatipoimpues`
    ).pipe(
      map((tiposImpuestos: any) => {
        return tiposImpuestos.map(tipoImpuesto => {
          tipoImpuesto['description'] = `${tipoImpuesto.tga_codtga} | ${tipoImpuesto.tga_descri}`
          return tipoImpuesto;
        })
      })
    );
  }
  /**
   *
   * @param tipoImpuesto
   * @returns
   */
  registrarTiposImpuesto(tipoImpuesto: TipoImpuesto): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/tipo-impuesto/${this._configurationService.obtenerCompaniaCliente()}`,
      tipoImpuesto
    );
  }

  /**
   *
   * @param tipoImpuesto
   * @returns
   */
  actualizarTiposImpuesto(tipoImpuesto: TipoImpuesto): Observable<any> {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/tipo-impuesto/${this._configurationService.obtenerCompaniaCliente()}`,
      tipoImpuesto
    );
  }

  /**
   *
   * @returns
   */
  obtenerTiposDocumento(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listatipodocum`
    ).pipe(
      map((tiposDocumento: any) => {
        return tiposDocumento.map(tipo => {
          tipo['description'] = `${tipo.TDO_CODTDO} | ${tipo.TDO_DESLAR} | ${tipo.TDO_CODOFI}`;
          return tipo;
        })
      })
    )
  }

  /**
   *
   * @param id
   * @returns
   */
  mostrarTipoDocumento(id): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/mostrartipodocum/${id}`
    );
  }

  /**
   *
   * @param year
   * @param month
   * @returns
   */
  listarTipoCambio(year, month): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listatipocambio/${year}/${month}`
    );
  }

  /**
   *
   * @returns
   */
  listarEEFF() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listaeeff/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  verEEFF(cod) {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/vereeff/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @returns
   */
  listarAnexoEEFF() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listar-anexo-eeff/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param cod
   * @returns
   */
  verAnexoEEFF(cod) {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/ver-anexo-eeff/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param payload
   * @returns
   */
  buscarCuentasEnAnexo(payload) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/buscar-cuenta-anexo-eeff/${this._configurationService.obtenerCompaniaCliente()}`,
      payload
    );
  }

  /**
   *
   * @returns
   */
  listarPlanDeCuentas(): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listaplancuentas/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((cuentas: any) => {
        return cuentas.map(cuenta => {
          cuenta['description'] = `${cuenta.cuenta} | ${cuenta.descri_cuenta}`
          return cuenta;
        })
      })
    )
  }

  /**
   *
   * @param cod
   * @returns
   */
  verPlanDeCuenta(cod) {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/ver-plan-de-cuenta/${this._configurationService.obtenerCompaniaCliente()}/${cod}`
    );
  }

  /**
   *
   * @param planCuenta
   * @returns
   */
  registrarPlanDeCuenta(planCuenta: PlanCuenta) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/plan-de-cuenta/${this._configurationService.obtenerCompaniaCliente()}`,
      planCuenta
    );
  }

  /**
   *
   * @param planCuenta
   * @returns
   */
  actualizarPlanDeCuenta(planCuenta: PlanCuenta) {
    return this.http.put<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/ver-plan-de-cuenta/${this._configurationService.obtenerCompaniaCliente()}`,
      planCuenta
    );
  }

  /**
   *
   * @returns
   */
  listarCentroCostos() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/listar-centro-de-costo/${this._configurationService.obtenerCompaniaCliente()}`
    ).pipe(
      map((centros: any) => {
        return centros.map(centro => {
          centro['description'] = `${centro.ccs_codccs} | ${centro.ccs_descri}`
          return centro;
        })
      })
    );
  }

  /**
   *
   * @param ccs
   * @returns
   */
  verCentroCosto(ccs) {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/ver-centro-de-costo/${this._configurationService.obtenerCompaniaCliente()}/${ccs}`
    );
  }

  /**
   *
   * @returns
   */
  listarConfiguraDocumentoCtaCte() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listarconfiguradocumentoctacte`
    );
  }

  /**
   *
   * @param id
   * @returns
   */
  mostrarPlanDeCuenta(id) {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/mostrarplancuenta/${this._configurationService.obtenerCompaniaCliente()}/${id}`
    );
  }

  /**
   *
   * @param body
   * @returns
   */
  crearTipoCambio(body): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/creareditartipocambio`,
      body
    );
  }

  /**
   *
   * @param body
   * @returns
   */
  editarTipoCambio(body): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/creareditartipocambio`,
      body
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @returns
   */
  obtenerInformeLibroDiario(anio, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/librodiario/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param cuenta
   * @returns
   */
  obtenerInformeLibroDiarioResumen(anio, mes, cuenta): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/librodiarioresumen/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}?cuenta=${cuenta}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param cuenta
   * @returns
   */
  obtenerInformeLibroCajayBanco(anio, mes, cuenta): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/librocajaybanco/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}?cuenta=${cuenta}`
    );
  }

  /**
   *
   * @param anio
   * @returns
   */
  obtenerInformeCentroCostoAnual(anio): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/centrocostoanual/${this._configurationService.obtenerCompaniaCliente()}/${anio}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param moneda
   * @returns
   */
  obtenerInformeBalanceEEFF(anio, mes, moneda): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/balanceeeff/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${moneda}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param moneda
   * @returns
   */
  obtenerInformeBalanceEEFFSunat(anio, mes, moneda): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/balanceeeff/sunat/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${moneda}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param moneda
   * @returns
   */
  obtenerInformeBalanceEEFFAnual(anio, mes, moneda): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/balanceeeff/anual/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${moneda}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param moneda
   * @returns
   */
  obtenerInformeBalanceGeneralAnalitico(anio, mes, moneda): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/balancegeneral/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${moneda}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @returns
   */
  obtenerReporteMayorContable(anio, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/mayorcontable/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @returns
   */
  obtenerReporteSaldoAnaliticoResumen(anio, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/saldoanaliticoresumen/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param cod
   * @returns
   */
  obtenerReporteAnaliticoCuentaContable(anio, mes, cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/analiticocuentacontable/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${cod}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param cod
   * @returns
   */
  obtenerReporteResumenCuentaVsDocumento(anio, mes, cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/resumencuentadocumento/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${cod}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @returns
   */
  obtenerReporteResumenAuxiliarVsCuenta(anio, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/resumenauxiliarcuenta/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param moneda
   * @returns
   */
  obtenerReporteGPporFuncion(anio, mes, moneda): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/eeff-gyp-funcion/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${moneda}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param moneda
   * @returns
   */
  obtenerReporteGPporFuncionAnalitica(anio, mes, moneda): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/eeff-gyp-funcion-analitico/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${moneda}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param moneda
   * @returns
   */
  obtenerReporteGPporNaturaleza(anio, mes, moneda): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/eeff-gyp-naturaleza/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${moneda}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param moneda
   * @returns
   */
  obtenerReporteGPporNaturalezaAnalitica(anio, mes, moneda): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/eeff-gyp-naturaleza-analitico/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${moneda}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @returns
   */
  obtenerReporteConciliacionBancaria(anio, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/conciliacion-bancaria/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @returns
   */
  obtenerReporteMovimientosSaldosBancos(anio, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/movimientos-bancos-saldos/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @returns
   */
  obtenerAsientosContables(anio, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/asientos-contables/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param subdiario
   * @param correlativo
   * @returns
   */
  verAsientoContable(anio, mes, subdiario, correlativo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/asientos-contables/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${subdiario}/${correlativo}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @returns
   */
  obtenerRegistroDeVentas(anio, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/registros-ventas/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @returns
   */
  obtenerRegistroDeCompras(anio, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/registros-compras/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param cod
   * @returns
   */
  obtenerRegistroHonorarios(anio, mes, cod): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/reporte/registro-de-honorarios/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${cod}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param tmo
   * @returns
   */
  extraerInformacionLibroElectronicoCompras(anio, mes, tmo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/extrae-informacion-libro-electronico-compras/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${tmo}`
    );
  }

  /**
   *
   * @param periodo
   * @returns
   */
  generaTxtCompras(periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/genera-txt-compras/${this._configurationService.obtenerCompaniaCliente()}/${periodo}`
    );
  }

  /**
   *
   * @param periodo
   * @returns
   */
  revisaInformacionCompras(periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/revisa-informacion-compras/${this._configurationService.obtenerCompaniaCliente()}/${periodo}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param tmo
   * @returns
   */
  extraerInformacionComprasNoDomiciliados(anio, mes, tmo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/extrae-informacion-compras-no-domiciliados/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${tmo}`
    );
  }

  /**
   *
   * @param periodo
   * @returns
   */
  generaTxtComprasNoDomiciliados(periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/genera-txt-compras-no-domiciliadas/${this._configurationService.obtenerCompaniaCliente()}/${periodo}`
    );
  }

  /**
   *
   * @param periodo
   * @returns
   */
  revisaInformacionComprasNoDomiciliados(periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/revisa-informacion-compras-no-domiciliados/${this._configurationService.obtenerCompaniaCliente()}/${periodo}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @param tmo
   * @returns
   */
  extraeInformacionVentas(anio, mes, tmo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/extrae-informacion-registro-venta/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}/${tmo}`
    );
  }

  /**
   *
   * @param periodo
   * @returns
   */
  generaTxtVentas(periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/genera-txt-ventas/${this._configurationService.obtenerCompaniaCliente()}/${periodo}`
    );
  }

  /**
   *
   * @param periodo
   * @returns
   */
  revisaInformacionVentas(periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/revisa-informacion-ventas/${this._configurationService.obtenerCompaniaCliente()}/${periodo}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @returns
   */
  extraeInformacionLibroDiario(anio, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/extrae-informacion-libro-diario/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}`
    );
  }

  /**
   *
   * @param periodo
   * @returns
   */
  generaTxtLibroDiario(periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/genera-txt-libro-diario/${this._configurationService.obtenerCompaniaCliente()}/${periodo}`
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @returns
   */
  generaArchivoPlanDeCuenta(ano, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/genera-archivo-plan-de-cuenta/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`
    );
  }

  /**
   *
   * @param anio
   * @param mes
   * @returns
   */
  extraeInformacionLibroMayor(anio, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/extrae-informacion-libro-mayor/${this._configurationService.obtenerCompaniaCliente()}/${anio}/${mes}`
    );
  }

  /**
   *
   * @param periodo
   * @returns
   */
  generaTxtLibroMayor(periodo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/genera-txt-libro-mayor/${this._configurationService.obtenerCompaniaCliente()}/${periodo}`
    );
  }

  /**
   *
   * @returns
   */
  listarSignoCtaCte() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/listarsignoctacte/${this._configurationService.obtenerCompaniaCliente()}`
    );
  }

  /**
   *
   * @param tipo_doc
   * @returns
   */
  configuraTipoDocCtaContable(tipo_doc): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/obtieneconfiguratipodocctacontable/${this._configurationService.obtenerCompaniaCliente()}/${tipo_doc}`
    );
  }

  /**
   *
   * @param code
   * @returns
   */
  obtenerInidicadoresFiltro(code): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablascontabilidad/obtenerindicadoresfiltro/${code}`
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @returns
   */
  obtenerRegCompraPdf(ano, mes): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/registro-de-compra/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @param payload
   * @returns
   */
  obtenerReporteDinamicoRegVenta(ano, mes, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/reporte-dinamico-registro-de-venta/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`,
      payload
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @param payload
   * @returns
   */
  obtenerReporteDinamicoRegCompra(ano, mes, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/reporte-dinamico-registro-de-compra/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`,
      payload
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @param payload
   * @returns
   */
  obtenerReporteDinamicoAsientoContable(ano, mes, payload): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/reporte-dinamico-asiento-contable/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}`,
      payload
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @param sco
   * @param tmo
   * @param payload
   * @returns
   */
  obtenerReporteDinamicoRegistroHonorario(
    ano,
    mes,
    sco,
    tmo,
    payload
  ): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/reporte-dinamico-registro-honorarios/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}/${tmo}/${sco}`,
      payload
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @param mon
   * @param payload
   * @returns
   */
  obtenerReporteDinamicoBalanceComprobacion(
    ano,
    mes,
    mon,
    payload
  ): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/reporte-dinamico-balance-comprobacion/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}/${mon}`,
      payload
    );
  }

  /**
   *
   * @param ano
   * @param cod
   * @returns
   */
  verConsultaSaldosMensuales(ano: string, cod: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/consulta-saldos-mensuales/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${cod}`
    );
  }

  /**
   *
   * @param ano
   * @param sco
   * @returns
   */
  verConsultaMovimientoContableStandar(ano: string, sco: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/consulta-movimiento-contable-standar/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${sco}`
    );
  }

  /**
   *
   * @param ano
   * @returns
   */
  verConsultaMovimientoContableBancos(ano: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/consulta-movimiento-contable-bancos/${this._configurationService.obtenerCompaniaCliente()}/${ano}`
    );
  }

  /**
   *
   * @param ano
   * @returns
   */
  verConsultaMovimientoContableCompras(ano: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/consulta-movimiento-contable-compras/${this._configurationService.obtenerCompaniaCliente()}/${ano}`
    );
  }

  /**
   *
   * @param ano
   * @returns
   */
  verConsultaMovimientoContableVentas(ano: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/consulta-movimiento-contable-ventas/${this._configurationService.obtenerCompaniaCliente()}/${ano}`
    );
  }

  /**
   *
   * @param ano
   * @returns
   */
  verConsultaMovimientoContableHonorarios(ano: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/consulta-movimiento-contable-honorarios/${this._configurationService.obtenerCompaniaCliente()}/${ano}`
    );
  }

  /**
   *
   * @param ano
   * @param cco
   * @param tmo
   * @returns
   */
  verConsultaMovimientoCtaCte(ano: string, cco: string, tmo: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/consulta-movimiento-cuenta-corriente/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${cco}/${tmo}`
    );
  }

  /**
   *
   * @param ano
   * @param mes
   * @param sco
   * @param ldc
   * @returns
   */
  verConsultaAsientoContable(ano: string, mes: string, sco: string, ldc: string): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/consulta-asiento-contable/${this._configurationService.obtenerCompaniaCliente()}/${ano}/${mes}/${sco}/${ldc}`
    );
  }

  /**
   *
   * @param codigo
   * @returns
   */
  listarColumnasInformeRegAsientos(codigo): Observable<any> {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/comprobantes/listacamposreporte/${codigo}/002`
    );
  }

  /**
   *
   * @param tipoCambioCierre
   * @returns
   */
  registrarTipoCambioCierre(tipoCambioCierre: TipoCambioCierre): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/tipo-cambio-cierre/${this._configurationService.obtenerCompaniaCliente()}`,
      tipoCambioCierre
    );
  }

  /**
   *
   * @param tipoCambioCierre
   * @returns
   */
  actualizarTipoCambioCierre(tipoCambioCierre: TipoCambioCierre): Observable<any> {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/tipo-cambio-cierre/${this._configurationService.obtenerCompaniaCliente()}`,
      tipoCambioCierre
    );
  }

  /**
   *
   * @param vigenciaImpuesto
   * @returns
   */
  registrarVigenciaImpuesto(vigenciaImpuesto: VigenciaImpuesto) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/vigencia-impuesto/${this._configurationService.obtenerCompaniaCliente()}`,
      vigenciaImpuesto
    );
  }

  /**
   *
   * @param vigenciaImpuesto
   * @returns
   */
  actualizarVigenciaImpuesto(vigenciaImpuesto: VigenciaImpuesto) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/vigencia-impuesto/${this._configurationService.obtenerCompaniaCliente()}`,
      vigenciaImpuesto
    );
  }

  /**
   *
   * @param documentoCtaCte
   * @returns
   */
  registrarDocumentoCtaCte(documentoCtaCte: DocumentoCtaCte) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/documento-cta-cte/${this._configurationService.obtenerCompaniaCliente()}`,
      documentoCtaCte
    );
  }

  /**
   *
   * @param documentoCtaCte
   * @returns
   */
  actualizarDocumentoCtaCte(documentoCtaCte: DocumentoCtaCte) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/documento-cta-cte/${this._configurationService.obtenerCompaniaCliente()}`,
      documentoCtaCte
    );
  }

  /**
   *
   * @param documentoCuentaContable
   * @returns
   */
  registrarDocumentoCuentaContable(documentoCuentaContable: DocumentoCuentaContable) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/documento-cuenta-contable/${this._configurationService.obtenerCompaniaCliente()}`,
      documentoCuentaContable
    );
  }

  /**
   *
   * @param documentoCuentaContable
   * @returns
   */
  actualizarDocumentoCuentaContable(documentoCuentaContable: DocumentoCuentaContable) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/documento-cuenta-contable/${this._configurationService.obtenerCompaniaCliente()}`,
      documentoCuentaContable
    );
  }

  /**
   *
   * @param spCategoria
   * @returns
   */
  registrarSPCategoria(spCategoria: SpCategoria) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/sp-categoria/${this._configurationService.obtenerCompaniaCliente()}`,
      spCategoria
    );
  }

  /**
   *
   * @param spCategoria
   * @returns
   */
  actualizarSPCategoria(spCategoria: SpCategoria) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/sp-categoria/${this._configurationService.obtenerCompaniaCliente()}`,
      spCategoria
    );
  }

  /**
   *
   * @param saldoInicialConciliacion
   * @returns
   */
  registrarSaldoInicialConciliacion(saldoInicialConciliacion: SaldoInicialConciliacion) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/saldo-inicial-conciliacion/${this._configurationService.obtenerCompaniaCliente()}`,
      saldoInicialConciliacion
    );
  }

  /**
   *
   * @param saldoInicialConciliacion
   * @returns
   */
  actualizarSaldoInicialConciliacion(saldoInicialConciliacion: SaldoInicialConciliacion) {
    return this.http.post<any>(
      `${this._configurationService.obtenerUrl()}/api/contabilidad/saldo-inicial-conciliacion/${this._configurationService.obtenerCompaniaCliente()}`,
      saldoInicialConciliacion
    );
  }

  /**
   * @param codigo_sunat
   *** CATALOGO POR CODIGO
   ** 07: Tipos de impuestos
   ** 51: Tipos de Operaciones T51 ej. gravadas
   ** 20: Guia SUNAT
  */
  obtenerCatalogoSUNATporCodigo(codigo_sunat: string) {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/sunat/listar/${codigo_sunat}`
    );
  }

  /**
  * Obtener configuración tecnica
  * @param saldoInicialConciliacion
  * @returns
  */
  obtenerConfiguracionTecnica() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablaconta/listar/${this._configurationService.obtenerCompaniaCliente()}/CONFIGURACIONTECNICA`,
    );
  }

  /**
   * Confiugración de contabilidad
   * @returns
   */
  obtenerConfiguracionContabilidad() {
    return this.http.get<any>(
      `${this._configurationService.obtenerUrl()}/api/tablaconta/listar/${this._configurationService.obtenerCompaniaCliente()}/CONFIGURACIONCONTABILIDAD?registrounico=true`,
    );
  }
}
