import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { searchInTable } from '@utils/tables/table';

@Component({
  selector: 'migracion-registro-compras',
  templateUrl: './migracion-registro-compras.component.html',
  styleUrls: ['./migracion-registro-compras.component.css']
})

export class MigracionRegistroComprasComponent implements OnInit {

  loaderData: boolean = false;
  tablaSeleccionada: boolean = false;

  displayedColumns: string[] = [
    'acciones',
    'ano_codano',
    'mes_codmes',
    'sco_codsco',
    'ldc_corldc',
    'cco_fecreg',
    'tga_codtga',
    'cli_codcli',
    'tdo_codtdo',
    'cco_numdoc',
    'sad_codsad',
    'dpc_anodua',
    'tmo_codtmo',
    'cco_tasigv',
    'cco_tipcam',
    'cco_impina',
    'cco_impafe',
    'valvta',
    'cco_impigv',
    'cco_impdoc',
    'cco_glocco',
    'iri_codiri',
    'tim_codtim',
    'dpc_tasdet',
    'spr_numser',
    'dpc_impper',
    'dpc_fecdet',
    'tdo_docref',
    'cco_numref',
    'cco_fecref',
  ];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isTablet: boolean = false;
  isMobile: boolean = false;


  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });
  }

  ngOnInit() { }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  seleccionarTabla(): void {

    this.tablaSeleccionada = !this.tablaSeleccionada;

  }

  onPaste(event: ClipboardEvent): void {

    if (!this.tablaSeleccionada) return;
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    this.processPastedData(pastedText);

  }

  processPastedData(pastedText: string): void {

    const rows = pastedText.split('\n').filter(row => row.trim().length > 0);
    const newData: any[] = rows.map(row => {
      const cols = row.split('\t');
      return {
        prd_codprd: cols[0],
        descripcion: cols[1],
        ume_codven: cols[2],
        cantidad: cols[3],
        mlo_numlot: cols[4],
        spr_numser: cols[5],
        map_cosmnp: +cols[6],
        map_cosmep: +cols[7],
        referencia: cols[8],
        codigoISOPais: cols[9]
      };
    });

    this.tablaSeleccionada = false;
  }

  limpiar(): void {

  }

  obtenerTipoCambio(row): void {

    // row.cco_tipcam =
  }
}
