import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { fillTable } from '@utils/tables/table';
import { TipoOperacion } from 'src/app/models/contabilidad/tipo_operacion';
import { AuthenticationService, ContabilidadService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';

@Component({
  selector: 'app-tipo-operacion-form',
  templateUrl: './tipo-operacion-form.component.html',
  styleUrls: ['./tipo-operacion-form.component.css']
})
export class TipoOperacionFormComponent implements OnInit {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  displayedColumns: string[] = ['aau_iteaau', 'ccn_codccn', 'ccn_descri', 'ccs_descri', 'aau_inddha', 'aau_poraau'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  tipoOperacion: TipoOperacion;

  usuario: any;
  fecha: Date = new Date();
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  uid: string;

  loading$: Subscription;

  constructor(
    private _router: Router,
    private _authService: AuthenticationService,
    private _contabilidadService: ContabilidadService,
    private _activatedRoute: ActivatedRoute,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService,
  ) {
    this.tipoOperacion = new TipoOperacion();

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({id}) => {
      this.uid = id;
      if(id == '0') return

      this._contabilidadService.verTipoDeOperacion(id).subscribe(
        ([tipo]) => {
          this.tipoOperacion = tipo
          this.usuario = tipo.tga_coduse;
          this.fecha = tipo.tga_fecupd;
          this.tipoOperacion.tga_coduse;

          this.dataSource = fillTable(tipo.detalle, this.paginator, this.sort);
        },
        error => this._snackBarService.showError(error.error.msg, "OK")
      )

    })
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarTipoDeOperacion(f): this.actualizarTipoDeOperacion(f);
  }

  registrarTipoDeOperacion(f: NgForm): void {

  }

  actualizarTipoDeOperacion(f: NgForm): void {
  }

  volver(): void {
    this._router.navigate(['/modulo-contabilidad/tipos-de-operacion']);
  }

}
