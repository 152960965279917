<div class="row">
  <div class="col s12 m12 l6">
    <mat-card class="mat-elevation-z0">
      <div id="chart">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [dataLabels]="chartOptions.dataLabels"
          [plotOptions]="chartOptions.plotOptions"
          [yaxis]="chartOptions.yaxis"
          [legend]="chartOptions.legend"
          [fill]="chartOptions.fill"
          [stroke]="chartOptions.stroke"
          [tooltip]="chartOptions.tooltip"
          [xaxis]="chartOptions.xaxis"
          [title]="chartOptions.title"
        ></apx-chart>
      </div>
    </mat-card>
  </div>
  <div class="col s12 m12 l6">
    <mat-card class="mat-elevation-z0">
      <div id="chart">
        <apx-chart
          [series]="chartOptionsPeriodo.series"
          [chart]="chartOptionsPeriodo.chart"
          [dataLabels]="chartOptionsPeriodo.dataLabels"
          [plotOptions]="chartOptionsPeriodo.plotOptions"
          [yaxis]="chartOptionsPeriodo.yaxis"
          [legend]="chartOptionsPeriodo.legend"
          [fill]="chartOptionsPeriodo.fill"
          [stroke]="chartOptionsPeriodo.stroke"
          [tooltip]="chartOptionsPeriodo.tooltip"
          [xaxis]="chartOptionsPeriodo.xaxis"
          [title]="chartOptionsPeriodo.title"
        ></apx-chart>
      </div>
      <br>
      <mat-form-field>
        <mat-label>Año</mat-label>
        <mat-select (selectionChange)="seleccionarAnio($event)">
          <mat-option *ngFor="let serie of series" [value]="serie.name">
            {{serie.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card>
  </div>
</div>

