import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { ContabilidadService, GeneralService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable } from '@utils/tables/table';
import { forkObs } from '@utils/observables/fork';

@Component({
  selector: 'app-configurar-documento-cobranza',
  templateUrl: './configurar-documento-cobranza.component.html',
  styleUrls: ['./configurar-documento-cobranza.component.css']
})
export class ConfigurarDocumentoCobranzaComponent implements OnInit, OnDestroy {

  usuario: any;
  fecha: Date = new Date();

  displayedColumns: string[] = ['situacion', 'cuenta_sol', 'cuenta_dolar', 'cuenta_enlace_sol', 'cuenta_enlace_dolar', 'solicitante'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loaderData: boolean = false;
  loaderReg: boolean = false;

  subdiarios: any[] = [];
  bancos: any[] = [];
  centros: any[] = [];

  loading$: Subscription;

  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  constructor(
    private _contabilidadService: ContabilidadService,
    private _generalService: GeneralService,
    private store: Store<PavsoState>,
    private _snackBarService: SnackBarService
  ) {

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$
    ])

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    forkObs(
      this._contabilidadService.obtenerSubdiarios(),
      this._generalService.listarBancos(),
      this._contabilidadService.listarCentroCostos()
    ).then(([subdiarios, bancos, centros]) => {

      this.subdiarios = subdiarios;
      this.bancos = bancos;
      this.centros = centros;
      this.loaderData = false;

    }).catch(err => {
      this._snackBarService.showError("Error al obtener maestros", "OK")
      this.loaderData = false;
    })

  }

  registrarConfiguracion(f: NgForm): void {

  }

  volver(): void {}

}
