import { ConfigState } from "@data/interfaces/state/config-state";
import { createReducer, on } from "@ngrx/store";
import { cambiarConfiguracionGeneral } from "../actions/config.actions";


export const initialState: ConfigState = {client: null, company_client: null, logo: '', dircia: '', vendedores: [], vendedorId: ''};

const _configReducer = createReducer(
  initialState,
  on(cambiarConfiguracionGeneral, (state, props) => state = {client: props.client, company_client: props.company_client, logo: props.logo, dircia: props.dircia, vendedores: props.vendedores, vendedorId: props.vendedorId}),
);

export function configReducer(state, action) {
  return _configReducer(state, action)
}
