<app-pavso-title-section [title]="'cotizacions' | translate" [module]="'CRM'"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <div class="pavso-content-form fade-in-image">

    <div class="row">
      <div class="col s12 m12 l12 xl12">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l8"></div>
            <div class="col s12 m12 l4">
              <div class="col s11">
                <mat-form-field class="example-full-width">
                  <mat-label>{{'apps.crm.operaciones.cotizacion.form.cotization-copy' | translate}}</mat-label>
                  <input matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.cotization-copy' | translate" [(ngModel)]="nroCotization" name="nroCotization" (keyup.enter)="copiarCotizacion()">
                </mat-form-field>
              </div>
              <div class="col s1">
                <button mat-mini-fab color="primary" type="button" (click)="copiarCotizacion()">
                  <mat-icon>content_copy</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.nro-quotation' | translate}}</mat-label>
                <input type="number" [(ngModel)]="cotizacion.numpcc" matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.nro-quotation' | translate" name="PCC_NUMPCC"
                  disabled>
              </mat-form-field>
            </div>

            <div class="col s12 m6 l2">
              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.date' | translate}}</mat-label>
                <input
                  name="FECDOC"
                  [(ngModel)]="cotizacion.fecdoc"
                  (dateChange)="cambiarFecha()" name="PCC_FECDOC" matInput
                  [matDatepicker]="picker" [placeholder]="'apps.crm.operaciones.cotizacion.form.date' | translate" (click)="picker.open()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.exchange-rate' | translate}}</mat-label>
                <input class="pav-input-align-right" min="0" name="PCC_TIPCAM" type="number" matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.exchange-rate' | translate" [(ngModel)]="cotizacion.tipcam"
                  step="any">
              </mat-form-field>
            </div>

            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.vat-rate' | translate}}</mat-label>
                <input min="0" class="pav-input-align-right" name="PCC_TASIGV" type="number" (keyup)="cambiarTasaIGV()" matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.vat-rate' | translate" [(ngModel)]="cotizacion.tasigv"
                  step="any">
              </mat-form-field>
            </div>

            <div class="col s12 m6 l2">
              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.channel' | translate}}</mat-label>
                <mat-select name="CPD_CODCPD" [(ngModel)]="cotizacion.codcpd">
                  <mat-option *ngFor="let canal of canales; trackBy: trackByCanal" [value]="canal.idmtk">
                    {{canal.descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col s12 m6 l2">
              <app-currency-select [moneda]="cotizacion.codtmo" (cambioMoneda)="cotizacion.codtmo = $event"></app-currency-select>
            </div>

            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.purchase-order' | translate}}</mat-label>
                <input name="PCC_ORDCOM" [(ngModel)]="cotizacion.ordcom" type="number" matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.purchase-order' | translate">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l4">

              <select-search
                [label]="'apps.crm.maestros.cotizacion.form.client' | translate"
                [placeholder]="'Ej. '"
                [name]="'cli_codcli'"
                [value]="'cli_codcli'"
                [description]="'cli_nomcli'"
                [data]="clientes"
                (cambioSelect)="cotizacion.codcli = $event"
                [multiple]="true"
                [model]="cotizacion.codcli">
              </select-search>

            </div>

            <div class="col s6 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.document-number' | translate}}</mat-label>
                <input type="number" matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.document-number' | translate" [(ngModel)]="cotizacion.codcli" name="rucClient" readonly>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l4">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.fiscal-address' | translate}}</mat-label>
                <input type="text" matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.fiscal-address' | translate" name="direccion" [(ngModel)]="cotizacion.dircli" readonly>
              </mat-form-field>
            </div>

          </div>

        </mat-card>
        <br>
      </div>
    </div>

    <div class="row">
      <div class="col s12 m12">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l4">

              <select-search
                [label]="'apps.crm.maestros.cotizacion.form.seller' | translate"
                [placeholder]="'Ej. '"
                [name]="'VDE_NOMVDE'"
                [value]="'VDE_CODVDE'"
                [description]="'VDE_CODVDE'"
                [data]="vendedores"
                (cambioSelect)="cotizacion.codvde = $event"
                [multiple]="true"
                [model]="cotizacion.codvde">
              </select-search>

            </div>

            <div class="col s12 m12 l2">

              <select-search
                [label]="'apps.crm.maestros.cotizacion.form.payment-condition' | translate"
                [placeholder]="'Ej. '"
                [name]="'CPA_CODCPA'"
                [value]="'CPA_CODCPA'"
                [description]="'CPA_DESCRI'"
                [data]="condiciones"
                (cambioSelect)="cotizacion.codcpa = $event"
                [multiple]="true"
                [model]="cotizacion.codcpa">
              </select-search>

            </div>

            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.calculate-item' | translate}}</mat-label>
                <mat-select [(ngModel)]="cotizacion.indcal" name="moneda" name="PCC_INDCAL">
                  <mat-option [value]="item.fci_codfci" *ngFor="let item of formas; trackBy: trackByFormaCalculo">
                    {{item.fci_descri}}
                  </mat-option>

                </mat-select>
              </mat-form-field>
            </div>

            <div class="col s12 m6 l2">
              <mat-form-field>
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.list' | translate}}</mat-label>
                <mat-select [(ngModel)]="cotizacion.codtlp" name="listaPrecios">
                  <mat-option value="1" *ngFor="let tipo of tiposPrecio; trackBy: trackByListaPrecio" [value]="tipo.TLP_CODTLP">
                    {{tipo.tlp_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col s12 m6 l2">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.nro' | translate}}</mat-label>
                <input [(ngModel)]="cotizacion.codlpc" type="number" matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.nro' | translate"
                  name="LPC_CODLPC">
              </mat-form-field>
            </div>

            <div class="col s12">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.observation' | translate}}</mat-label>
                <textarea matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.observation' | translate" [(ngModel)]="cotizacion.obspcc"
                  name="PCC_OBSPCC"></textarea>
              </mat-form-field>

            </div>
          </div>
        </mat-card>
      </div>

    </div>

    <br>

    <div class="pav-table-search-container">
      <div class="pav-separator"></div>
      <div class="pav-table-search">
        <input type="text" (keyup)="applyFilter($event)" [placeholder]="'apps.crm.operaciones.cotizacion.form.search' | translate" #input>
      </div>
    </div>

    <div class="pav-table-header-container">
      <div class="pav-table-header-icon">
      </div>
      <div class="pav-table-header-title">
        {{'apps.crm.operaciones.cotizacion.form.table' | translate}}
      </div>
      <div class="pav-table-header-add">
        <div class="isMobile">
          <div class="pav-button-icon-add" (click)="agregarItem()">
            <mat-icon svgIcon="add"></mat-icon>
          </div>
        </div>
        <div class="isWeb">
          <button type="button" mat-flat-button class="pav-button-add-item" (click)="agregarItem()">{{'general.button.add' | translate}}</button>
        </div>
      </div>
    </div>

    <div class="mat-elevation-z0 overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.actions' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Acciones">
            <div class="pav-btns-container">
              <div class="pav-btn-cb">
                <mat-checkbox [disabled]="row.isSelect" (change)="seleccionarCB($event, i, row)" [(ngModel)]="row.isSelect" [name]="'isSelect'+i" class="example-margin"></mat-checkbox>
              </div>
              <div *ngIf="!row.isEditing" (click)="editarItem(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                <mat-icon svgIcon="edit-white"></mat-icon>
              </div>
              <div *ngIf="row.isEditing" (click)="confirmarItem(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                <mat-icon svgIcon="confirm"></mat-icon>
              </div>
              <div class="pav-btn-circle-delete" (click)="quitarItem(row)" matTooltip="Eliminar">
                <mat-icon svgIcon="delete"></mat-icon>
              </div>

            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="campo1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.item' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="item"> {{i + 1}} </td>
        </ng-container>

        <ng-container matColumnDef="campo2">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.product' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Código">
            <ng-container *ngIf="row.isEditing">

              <select-search
                [label]=""
                [placeholder]="'Ej. '"
                [name]="'prd_codprd' + i"
                [value]="'prd_codprd'"
                [description]="'DESC_ABRE'"
                [data]="productos"
                (cambioSelect)="row.codprd = $event"
                [multiple]="true"
                [model]="row.codprd">
              </select-search>

            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.descri}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="campo3">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.um' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="U.M.">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-field-input-number">
                <mat-label></mat-label>
                <input class="pav-input-align-right" required type="text" min="0" matInput placeholder="U.M." [(ngModel)]="row.codven"
                [name]="'CODUM' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.codven}}
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="campo4">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.amount' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-field-input-number">
                <mat-label></mat-label>
                <input class="pav-input-align-right" (keyup)="cambiarCantidad(row)" required type="number" min="0" matInput placeholder="Cantidad" [(ngModel)]="row.cansol"
                [name]="'CANSOL' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.cansol}}
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="campo5">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.price' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Precio">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-field-input-number">
                <mat-label></mat-label>
                <input class="pav-input-align-right" (keyup)="cambiarPrecio(row)" required type="number" min="0" matInput placeholder="Precio" [(ngModel)]="row.preuni"
                [name]="'PREUNI' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.preuni.toFixed(2)}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="campo6">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.price-vat' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Precio inc. IGV">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-field-input-number">
                <mat-label></mat-label>
                <input class="pav-input-align-right" disabled required type="number" min="0" matInput placeholder="Precio inc. IGV" [(ngModel)]="row.pruigv"
                [name]="'PRUIGV' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.pruigv.toFixed(2)}}
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="campo7">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.um-sale' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="U.M. Venta">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-field-input-number">
                <mat-label></mat-label>
                <input class="pav-input-align-right" required type="number" min="0" matInput placeholder="U.M. Venta" [(ngModel)]="row.codven"
                [name]="'CODVEN' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.codven}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="campo8">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.sale-amount' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Cantidad Venta">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-field-input-number">
                <mat-label></mat-label>
                <input class="pav-input-align-right" required type="number" min="0" matInput placeholder="Cantidad Venta" [(ngModel)]="row.cansol"
                [name]="'CANSOL' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.cansol}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="campo9">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.gross-amount' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Importe Bruto">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-field-input-number">
                <mat-label></mat-label>
                <input class="pav-input-align-right" disabled required type="text" min="0" matInput placeholder="Importe Bruto" [(ngModel)]="row.impbru"
                [name]="'IMPBRU' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.impbru.toFixed(2)}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="campo10">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.%discount' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="% Desc.">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-field-input-number">
                <mat-label></mat-label>
                <input class="pav-input-align-right" required type="number" min="0" matInput placeholder="% Desc." [(ngModel)]="row.pordes"
                [name]="'PORDES' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.pordes}} %
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="campo11">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.discount-amount' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Importe Desc.">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-field-input-number">
                <mat-label></mat-label>
                <input class="pav-input-align-right" disabled required type="number" min="0" matInput placeholder="Importe Desc." [(ngModel)]="row.impdes"
                [name]="'IMPDES' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.impdes.toFixed(2)}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="campo12">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.sale-value' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Valor venta">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-field-input-number">
                <mat-label></mat-label>
                <input class="pav-input-align-right" required disabled type="number" min="0" matInput placeholder="Valor venta" [(ngModel)]="row.valvta"
                [name]="'VALVTA' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.valvta.toFixed(2)}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="campo13">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.vat-amount' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Imp. IGV.">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-field-input-number">
                <mat-label></mat-label>
                <input class="pav-input-align-right" disabled required step='0.01' type="number" min="0" matInput placeholder="Imp. IGV." [(ngModel)]="row.impigv"
                [name]="'IMPIGV' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.impigv.toFixed(2)}}
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="campo14">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.total' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Imp. Total">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="pav-field-input-number">
                <mat-label></mat-label>
                <input class="pav-input-align-right" disabled required type="number" min="0" matInput placeholder="Imp. Total" [(ngModel)]="row.imptot"
                [name]="'IMPTOT' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.imptot.toFixed(2)}}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="campo15">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.product.gloss' | translate}} </th>
          <td mat-cell *matCellDef="let row; let i = index;" data-label="Glosa">
            <ng-container *ngIf="row.isEditing">
              <mat-form-field class="mat-form-field-item">
                <mat-label></mat-label>
                <input required type="text" min="0" matInput placeholder="Glosa" [(ngModel)]="row.glopcd"
                [name]="'GLOPCD' + i.toString()">
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!row.isEditing">
              {{row.glopcd}}
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="seleccionarFila(row)"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="20" >{{'apps.crm.operaciones.cotizacion.form.table-no-data' | translate}}</td>
        </tr>

      </table>

    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    <br>

    <div class="row">
      <div class="col s12 m12 l6">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon"></div>
          <div class="pav-table-header-title">
            {{'apps.crm.operaciones.cotizacion.form.table-discount' | translate}}
          </div>
          <div class="pav-table-header-add">
            <div class="isMobile">
              <div class="pav-button-icon-add" (click)="agregarDescuento()">
                <mat-icon svgIcon="add"></mat-icon>
              </div>
            </div>
            <div class="isWeb">
              <button type="button" mat-flat-button class="pav-button-add-item" (click)="agregarDescuento()">{{'general.button.add' | translate}}</button>
            </div>
          </div>
        </div>
        <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSourceDescuento" matSort>
            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.discount.actions' | translate}} </th>
              <td mat-cell *matCellDef="let row" data-label="Acciones">
                <div class="pav-btns-container">
                  <div *ngIf="!row.isEditing" (click)="editarDescuento(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </div>
                  <div *ngIf="row.isEditing" (click)="confirmarDescuento(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                    <mat-icon svgIcon="confirm"></mat-icon>
                  </div>
                  <div class="pav-btn-circle-delete" (click)="quitarDescuento(row)" matTooltip="Eliminar">
                    <mat-icon svgIcon="delete"></mat-icon>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="descuento">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.discount.discount-des' | translate}} </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Descuento">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field class="mat-form-field-item">
                    <mat-label></mat-label>
                    <mat-select required [(ngModel)]="row.coddco" [name]="'coddco' + i">
                      <mat-option *ngFor="let descuento of descuentos; trackBy: trackByDescuento" [value]="descuento.dco_coddco">{{descuento.dco_desdco}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.coddco}}
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="porcentaje">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.discount.discount' | translate}} </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="% Descuento">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field class="mat-form-field-item">
                    <mat-label></mat-label>
                    <input class="pav-input-align-right" required type="number" min="0" max="100" matInput placeholder="% Descuento" [(ngModel)]="row.pordes"
                    [name]="'pordes' + i.toString()">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.pordes}}
                </ng-container>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsDescuento"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDescuento;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="20" >{{'apps.crm.operaciones.cotizacion.form.table-no-data' | translate}}</td>
            </tr>

          </table>

        </div>
        <mat-paginator #paginatorDescuento showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
      <div class="col s12 m12 l6">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon">
          </div>
          <div class="pav-table-header-title">
            {{'apps.crm.operaciones.cotizacion.form.table-specs' | translate}}
          </div>
          <div class="pav-table-header-add">
            <div class="isMobile">
              <div class="pav-button-icon-add" (click)="agregarEspecificacion()">
                <mat-icon svgIcon="add"></mat-icon>
              </div>
            </div>
            <div class="isWeb">
              <button type="button" mat-flat-button class="pav-button-add-item" (click)="agregarEspecificacion()">{{'general.button.add' | translate}}</button>
            </div>
          </div>
        </div>
        <div class="mat-elevation-z0 overflow-x">
          <table mat-table [dataSource]="dataSourceEspecificacion" matSort>
            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.specs.actions' | translate}} </th>
              <td mat-cell *matCellDef="let row" data-label="Acciones">
                <div class="pav-btns-container">
                  <div *ngIf="!row.isEditing" (click)="editarEspecificacion(row)" class="pav-btn-circle-edit" matTooltip="Editar">
                    <mat-icon svgIcon="edit-white"></mat-icon>
                  </div>
                  <div *ngIf="row.isEditing" (click)="confirmarEspecificacion(row)" class="pav-btn-circle-confirm" matTooltip="Confirmar">
                    <mat-icon svgIcon="confirm"></mat-icon>
                  </div>
                  <div class="pav-btn-circle-delete" (click)="quitarEspecificacion(row)" matTooltip="Eliminar">
                    <mat-icon svgIcon="delete"></mat-icon>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="item">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.specs.item' | translate}} </th>
              <td mat-cell *matCellDef="let row; let i = index" data-label="item"> {{row.seccci}} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'apps.crm.operaciones.cotizacion.table.header.specs.description' | translate}} </th>
              <td mat-cell *matCellDef="let row; let i = index;" data-label="Descripción">
                <ng-container *ngIf="row.isEditing">
                  <mat-form-field class="mat-form-field-item">
                    <mat-label></mat-label>
                    <input required type="text" min="0" matInput placeholder="Descripción" [(ngModel)]="row.descri"
                    [name]="'descri' + i.toString()">
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!row.isEditing">
                  {{row.descri}}
                </ng-container>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsEspecificacion"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsEspecificacion;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="20" >{{'apps.crm.operaciones.cotizacion.form.table-no-data' | translate}}</td>
            </tr>

          </table>

        </div>
        <mat-paginator #paginatorEspecificacion showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>

    <div class="row">
      <div class="col s12 m12 l3">
        <br>
        <button *ngIf="uid != '0'" mat-flat-button class="pav-button-flex" color="primary" type="button" (click)="generarOrdenPedido()">
          {{'apps.crm.operaciones.cotizacion.button.generate-order' | translate}}
        </button>

      </div>
      <div class="col s12 m12 l5"></div>
      <div class="col s12 m12 l4">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12">
              <mat-checkbox class="example-margin" color="primary" name="PCC_INDTERM" [(ngModel)]="cotizacion.indter">{{'apps.crm.operaciones.cotizacion.form.finished' | translate}}</mat-checkbox>
            </div>
            <br>
            <div class="col s12 m6">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.sale-value' | translate}}</mat-label>
                <input class="pav-input-align-right" disabled type="number" matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.sale-value' | translate" [(ngModel)]="cotizacion.valvta"
                  name="PCC_VALVTA" step="any">
              </mat-form-field>
            </div>
            <div class="col s12 m6">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.gross-amount' | translate}}</mat-label>
                <input class="pav-input-align-right" disabled type="number" matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.gross-amount' | translate" [(ngModel)]="cotizacion.impbru"
                  name="PCC_IMPBRU" step="any">
              </mat-form-field>
            </div>
            <div class="col s12 m6">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.vat-amount' | translate}}</mat-label>
                <input class="pav-input-align-right" disabled type="number" matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.vat-amount' | translate" [(ngModel)]="cotizacion.impigv"
                  name="PCC_IMPIGV" step="any">
              </mat-form-field>
            </div>
            <div class="col s12 m6">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.discount-detail' | translate}}</mat-label>
                <input class="pav-input-align-right" disabled type="number" matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.discount-detail' | translate" [(ngModel)]="cotizacion.impdes"
                  name="PCC_IMPDES" step="any">
              </mat-form-field>
            </div>
            <div class="col s12 m6">
              <mat-form-field class="example-full-width">
                <mat-label>{{'apps.crm.operaciones.cotizacion.form.total' | translate}}</mat-label>
                <input class="pav-input-align-right" type="number" matInput [placeholder]="'apps.crm.operaciones.cotizacion.form.total' | translate" name="PCC_IMPTOT" [(ngModel)]="cotizacion.imptot"
                  step="any" disabled>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <log-updated *ngIf="uid!='0'" [coduse]="cotizacion.coduse" [fecupd]="cotizacion.fecupd"></log-updated>

  </div>

  <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" urlBack="/modulo-crm/cotizacion">
    <div id="botonesAdicionales">

    </div>
  </pav-form-actions>
</form>
