<app-pavso-title-section [title]="'Asientos contables'" [module]="'Contabilidad'" [estado]="asientoContable.LDC_INDSTA"
  [usuario]="asientoContable.LDC_CODUSE" [fecha]="asientoContable.LDC_FECUPD"
  [ngClass]="'pav-form'"></app-pavso-title-section>
<form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">
  <pav-form-actions [disabled]="loaderData" [isLoading]="loaderReg" urlBack="/modulo-contabilidad/asientos-contables">
    <div id="botonesAdicionales">
      <button *ngIf="uid == '0'" (click)="copiarAsientoContable()" class="pav-btn-action-table" color="primary" matTooltip="Copiar Asiento" type="button" [disabled]="loaderData">
        <mat-icon class="pav-icon-btn-table" svgIcon="copy"></mat-icon>
      </button>
    </div>
  </pav-form-actions>
  <div class="pavso-content-form fade-in-image">

    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
    <div class="row">
      <div class="col s12 m12 l8">
        <mat-card class="mat-elevation-z0">
          <div class="row">

            <div class="col s12 m6 l1">
              <mat-form-field class="example-full-width">
                <mat-label>Año</mat-label>
                <input #iAno type="number" [(ngModel)]="asientoContable.ANO_CODANO" name="ano_codano" matInput
                  placeholder="Año" readonly (keyup.enter)="establecerAno()">
              </mat-form-field>
            </div>
            <div class="col s12 m6 l1">
              <mat-form-field class="example-full-width">
                <mat-label>Mes</mat-label>
                <input #iMes type="number" [(ngModel)]="asientoContable.MES_CODMES" name="mes_codmes" matInput
                  placeholder="Mes" readonly (keyup.enter)="establecerMes()">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l4 xl3">
              <mat-form-field>
                <mat-label>SubDiario</mat-label>
                <mat-select #iSubdiario (selectionChange)="seleccionarSubdiario($event)" required
                  [disabled]="loaderData" [(ngModel)]="asientoContable.SCO_CODSCO" name="sco_codsco">
                  <mat-option *ngFor="let subdiario of subdiarios" [value]="subdiario.sco_codsco">
                    {{subdiario.sco_descri}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l4 xl3">
              <mat-form-field>
                <mat-label>Fecha</mat-label>
                <input #iFecha maxlength="10" matInput [matDatepicker]="fecha" [disabled]="loaderData"
                  [(ngModel)]="asientoContable.LDC_FECDIA" name="cco_fecreg" (dateChange)="cambiarFecha($event)"
                  (keyup.enter)="establecerFecha()">
                <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
                <mat-datepicker #fecha></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col s12 m12 l3 xl2">
              <mat-form-field>
                <mat-label>T.C.</mat-label>
                <input #iTc appTipoCambioFormat step="any" min="0" type="number" matInput placeholder="T.C." [disabled]="loaderData"
                  [(ngModel)]="asientoContable.LDC_TIPCAM" (focus)="focusCampo($event)" name="LDC_TIPCAM" (keyup.enter)="establecerTC()">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Nro. Asiento</mat-label>
                <input type="text" matInput placeholder="Nro. Asiento" readonly [(ngModel)]="asientoContable.LDC_CORLDC"
                  name="cco_codcco">
              </mat-form-field>
            </div>

            <div class="col s12 m12 l9 xl12">
              <mat-form-field>
                <mat-label>Glosa</mat-label>
                <input #iGlosa type="text" matInput placeholder="Glosa" [disabled]="loaderData"
                  [(ngModel)]="asientoContable.LDC_GLODIA" name="cco_glocco" (keyup.enter)="establecerGlosa()">
              </mat-form-field>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="col s12 m12 l4">

        <table mat-table [dataSource]="dataSource3">

          <ng-container matColumnDef="name" sticky>
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row" data-label="Código"> {{row.name}} </td>
          </ng-container>

          <ng-container matColumnDef="mn">
            <th mat-header-cell *matHeaderCellDef> M.N. </th>
            <td mat-cell *matCellDef="let row" data-label="Código"> {{row.mn.toFixed(2)}} </td>
          </ng-container>

          <ng-container matColumnDef="me">
            <th mat-header-cell *matHeaderCellDef> M.E. </th>
            <td mat-cell *matCellDef="let row" data-label="Cliente" style="height: 25px !important"> {{row.me.toFixed(2)}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns3; sticky"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns3;" ></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No se encontraron registros</td>
          </tr>

        </table>
      </div>
    </div>

    <br>

    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title" [ngStyle]="{marginRight: '20px'}">Detalle</div>
      <div class="pav-separator">
        <!-- <button class="pav-btn-action-table" #btnGeneraAsiento color="primary" matTooltip="Generar Asiento" type="button"
          (click)="generarAsiento()" (keyup.enter)="generarAsiento()">
          <mat-icon class="pav-icon-btn-table" svgIcon="generate"></mat-icon>
        </button> -->

        <button class="pav-btn-action-table-ajuste" color="primary" matTooltip="Ajuste < 0.05" type="button" (click)="ajusteDiferencia()" [disabled]="loaderData">
          <mat-icon class="pav-icon-btn-table" svgIcon="setting"></mat-icon>
        </button>
        <button class="pav-btn-action-table-borrar-destino" color="primary" matTooltip="Borrar Destinos" type="button"
          (click)="borrarAsientosDestino()" [disabled]="loaderData">
          <mat-icon class="pav-icon-btn-table" svgIcon="document-error"></mat-icon>
        </button>
        <button class="pav-btn-action-table-cuenta-pagar" color="primary" matTooltip="Seleccionar documentos provicionados compras" type="button"
          (click)="documentosPorPagar()" [disabled]="loaderData">
          <mat-icon class="pav-icon-btn-table" svgIcon="document-payment"></mat-icon>
        </button>

        <button class="pav-btn-action-table-cuenta-cobrar" color="primary" matTooltip="Seleccionar documentos provicionados ventas" type="button"
          (click)="documentosPorCobrar()" [disabled]="loaderData">
          <mat-icon class="pav-icon-btn-table" svgIcon="document-payment"></mat-icon>
        </button>

      </div>
      <div class="pav-table-search">
        <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
      </div>
      &nbsp;
      <div class="pav-button-icon-add" matTooltip="Agregar ítem" (click)="agregarItem()"
        [ngStyle]="{display: loaderData? 'none': ''}">
        <mat-icon svgIcon="add-circle"></mat-icon>
      </div>
      &nbsp;
      <div class="pav-button-icon-delete" matTooltip="Eliminar ítem"
        (click)="quitarItem()" [ngStyle]="{display: loaderData? 'none': ''}">
        <mat-icon svgIcon="remove"></mat-icon>
      </div>
      &nbsp;
      <div class="pav-button-icon-insert" matTooltip="Insertar ítem" (click)="insertarItem()" [ngStyle]="{display: loaderData? 'none': ''}">
        <mat-icon svgIcon="add-outline"></mat-icon>
      </div>
    </div>
    <div class="mat-elevation-z0 overflow-x">
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="ldi_corldi">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> It. </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="It.">
            <mat-form-field style="width: 70px;" [class.mat-form-field-row]="row === selected"
              class="form-field-small form-field-cell">
              <mat-label></mat-label>
              <input matInput readonly placeholder="It." [value]="row.LDI_CORLDI" [name]="'LDI_CORLDI' + i.toString()">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="ccn_codccn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Cuenta">

            <select-search
              [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
              [label]="''" [placeholder]="'Ej. 42'" [name]="'cuenta' + i" [value]="'cuenta'"
              [description]="'description'" [data]="cuentasTotales" (cambioSelect)="row.CCN_CODCCN = $event;"
              [model]="row.CCN_CODCCN"></select-search>

          </td>
        </ng-container>

        <ng-container matColumnDef="cli_codcli">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Auxiliar </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Auxiliar">

            <select-search
              [clase]="row === selected? 'form-field-small form-field-cell select-width-140 mat-form-field-row': 'form-field-small form-field-cell select-width-140'"
              [label]="''" [placeholder]="'Ej. TORRES'" [name]="'CLI_CODCLI' + i" [value]="'cli_codcli'"
              [description]="'description'" [data]="auxiliares" (cambioSelect)="row.CLI_CODCLI = $event;"
              [model]="row.CLI_CODCLI"></select-search>

          </td>
        </ng-container>

        <ng-container matColumnDef="ccs_codccs">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> C. Costo </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="C. Costo">
            <select-search
              [clase]="row === selected? 'form-field-small form-field-cell select-width-100 mat-form-field-row': 'form-field-small form-field-cell select-width-100'"
              [label]="''" [placeholder]="'Ej. 102015'" [name]="'ccs_codccs' + i" [value]="'ccs_codccs'"
              [description]="'description'" [data]="centros" (cambioSelect)="row.CCS_CODCCS = $event;"
              [model]="row.CCS_CODCCS"></select-search>

          </td>
        </ng-container>

        <ng-container matColumnDef="tdo_codtdo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Doc.">

            <select-search
              [clase]="row === selected? 'form-field-small form-field-cell select-width-100 mat-form-field-row': 'form-field-small form-field-cell select-width-100'"
              [label]="''" [placeholder]="'Ej. FAC'" [name]="'TDO_CODTDO' + i" [value]="'TDO_CODTDO'"
              [description]="'description'" [data]="tiposDoc" (cambioSelect)="row.TDO_CODTDO = $event;"
              [model]="row.TDO_CODTDO"></select-search>

          </td>
        </ng-container>

        <ng-container matColumnDef="ldi_docref">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Nro. Doc.">

            <mat-form-field style="width: 100px;" [class.mat-form-field-row]="row === selected"
              class="form-field-small form-field-cell">
              <mat-label></mat-label>
              <input matInput placeholder="Nro. Doc." [(ngModel)]="row.LDI_DOCREF" [name]="'LDI_DOCREF' + i.toString()">
            </mat-form-field>

          </td>
        </ng-container>

        <ng-container matColumnDef="tmo_codtmo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Mon. </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Mon.">

            <mat-form-field style="width: 70px;" [class.mat-form-field-row]="row === selected"
              class="form-field-small form-field-cell">
              <mat-label></mat-label>
              <mat-select #iMoneda required [disabled]="loaderData" [(ngModel)]="row.TMO_CODTMO"
                [name]="'TMO_CODTMO' + i.toString()">
                <mat-option value="SO">
                  S/
                </mat-option>
                <mat-option value="DO">
                  $
                </mat-option>
              </mat-select>
            </mat-form-field>

          </td>
        </ng-container>

        <ng-container matColumnDef="ldi_tipcam">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> T. Cambio </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="T. Cambio">

            <mat-form-field style="width: 50px;" [class.mat-form-field-row]="row === selected"
              class="form-field-small form-field-cell">
              <mat-label></mat-label>
              <input min="0" type="number" matInput placeholder="T. Cambio" [(ngModel)]="row.LDI_TIPCAM"
                [name]="'LDI_TIPCAM' + i.toString()" step="any">
            </mat-form-field>

          </td>
        </ng-container>

        <ng-container matColumnDef="ldi_inddha">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> D/H </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="D/H" style="width: 100px;">

            <mat-form-field style="width: 50px;" [class.mat-form-field-row]="row === selected"
              class="form-field-small form-field-cell">

              <mat-select [(ngModel)]="row.LDI_INDDHA" [name]="'LDI_INDDHA' + i.toString()">
                <mat-option value="D">
                  D
                </mat-option>
                <mat-option value="H">
                  H
                </mat-option>
              </mat-select>
            </mat-form-field>

          </td>
        </ng-container>

        <ng-container matColumnDef="ldi_impnac">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe MN </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Importe MN">

            <mat-form-field style="width: 80px;" [class.mat-form-field-row]="row === selected"
              class="form-field-small form-field-cell">
              <mat-label></mat-label>
              <input (focus)="focusCampo($event)" (keyup)="calculoImportesMN(row)" min="0" type="number" matInput placeholder="Importe MN"
                [ngStyle]="{textAlign: 'right'}" [(ngModel)]="row.LDI_IMPNAC" [name]="'LDI_IMPNAC' + i.toString()"
                step="any">
            </mat-form-field>

          </td>
        </ng-container>

        <ng-container matColumnDef="ldi_impmex">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe ME </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Importe ME">

            <mat-form-field style="width: 80px;" [class.mat-form-field-row]="row === selected"
              class="form-field-small form-field-cell">
              <mat-label></mat-label>
              <input (focus)="focusCampo($event)" (keyup)="calculoImportesME(row)" min="0" type="number" [ngStyle]="{textAlign: 'right'}" matInput
                placeholder="Importe ME" [(ngModel)]="row.LDI_IMPMEX" [name]="'LDI_IMPMEX' + i.toString()" step="any">
            </mat-form-field>

          </td>
        </ng-container>

        <ng-container matColumnDef="ldi_gloldi">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Glosa">

            <mat-form-field [class.mat-form-field-row]="row === selected" class="form-field-small form-field-cell">
              <mat-label></mat-label>
              <input (focus)="focusCampo($event)" type="text" matInput placeholder="Glosa" [(ngModel)]="row.LDI_GLOLDI"
                [name]="'LDI_GLOLDI' + i.toString()" (keyup.enter)="confirmarNuevoItem(row)">
            </mat-form-field>

          </td>
        </ng-container>

        <ng-container matColumnDef="cco_codcco">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Cta. Cte. </th>
          <td mat-cell *matCellDef="let row; let i = index" data-label="Cta. Cte.">
            <mat-form-field style="width: 120px;" [class.mat-form-field-row]="row === selected"
              class="form-field-small form-field-cell">
              <mat-label></mat-label>
              <input matInput readonly placeholder="It." [(ngModel)]="row.CCO_CODCCO " [name]="'CCO_CODCCO' + i.toString()">
            </mat-form-field>

          </td>
        </ng-container>

        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
          <td mat-cell *matCellDef="let row; let i = index" [style.color]="row.color" data-label="Acciones"
            style="width: 100px">
            <div style="width: 100px;">
              <mat-checkbox [(ngModel)]="row.isSelected" [name]="'seleccionable' + i" class="example-margin"
                (click)="filaSeleccionada(row, $event)"></mat-checkbox>

              <button *ngIf="!row.isEditing" mat-icon-button color="primary" type="button" matTooltip="Editar Ítem"
                (click)="row.isEditing = !row.isEditing">
                <mat-icon>edit</mat-icon>
              </button>
              <button *ngIf="row.isEditing" mat-icon-button color="primary" type="button" matTooltip="Actualizar Ítem"
                (click)="confirmarNuevoItem(row)">
                <mat-icon>done</mat-icon>
              </button>
              <button mat-icon-button color="primary" type="button" matTooltip="Eliminar Ítem" (click)="quitarItem()">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="row === selected"
          (click)="selectRow(row)"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="15">No se encontraron registros</td>

        </tr>

      </table>

      <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

    <br>

  </div>

</form>
