export class FiltroReporteCobranza {
  clientes: Array<any>;
  vendedores: Array<any>;
  tiposDocumento: Array<any>;
  saldo: any;
  ordenadoPor: any;
  rango: any;

  constructor () {
    this.saldo = 'todos';
    this.clientes = [];
    this.vendedores = [];
    this.tiposDocumento = [];
  }

}
