<app-pavso-title-section [title]="'Consulta'" [module]="'Cuentas por Pagar'"></app-pavso-title-section>
<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarCuenta(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
        <div class="row">
            <div class="col s12 m12 l3">

              <select-search
                [label]="'Proveedor'"
                [placeholder]="'Ej. PEREZ'"
                [name]="'cli_codcli'"
                [value]="'cli_codcli'"
                [description]="'cli_nomcli'"
                [data]="proveedores"
                (cambioSelect)="filtro.clientes = $event"
                [model]="filtro.clientes">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
              <app-currency-select [disable]="loaderData" [moneda]="'SO'" (cambioMoneda)="seleccionarMoneda($event)"></app-currency-select>
            </div>
            <div class="col s12 m12 l3">
              <select-search
                [label]="'Tipo de documento'"
                [placeholder]="'Ej. FAC'"
                [name]="'TDO_CODTDO'"
                [value]="'TDO_CODTDO'"
                [description]="'TDO_CODTDO'"
                [data]="tiposDoc"
                (cambioSelect)="filtro.tiposDocumento = $event"
                [model]="filtro.tiposDocumento">
              </select-search>

            </div>
            <div class="col s12 m12 l3">
                <mat-form-field>
                    <mat-label>Num. Documento</mat-label>
                    <input [disabled]="loaderData" type="text" (keyup)="applyFilter($event)" matInput placeholder="Num. Documento" name="num_documento"required>
                </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field>
                <mat-label>Situación</mat-label>
                <mat-select [(ngModel)]="filtro.situacion" name="situacion" [disabled]="loaderData">
                  <mat-option value="todos">
                    Todos
                  </mat-option>
                  <mat-option value="pendientes">
                    Pendientes
                  </mat-option>
                  <mat-option value="vencidos">
                    Vencidos
                  </mat-option>
                  <mat-option value="porVencer">
                    Por Vencer
                  </mat-option>
                  <mat-option value="cancelados">
                    Cancelados
                  </mat-option>
                  <mat-option value="anulados">
                    Anulados
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <button [disabled]="loaderData" (click)="buscarRegistros()" type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
                <mat-icon>search</mat-icon>
              </button>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="pav-total-field">
                  <mat-label>Saldo Total M.N.</mat-label>
                  <input [disabled]="loaderData" readonly style="text-align: right;" type="text" [(ngModel)]="saldoTotalME" name="saldoTotalMN" matInput placeholder="Saldo Total MN">
              </mat-form-field>
            </div>
            <div class="col s12 m12 l3">
              <mat-form-field class="pav-total-field">
                  <mat-label>Saldo Total M.E.</mat-label>
                  <input [disabled]="loaderData" readonly style="text-align: right;" type="text" [(ngModel)]="saldoTotalMN" name="saldoTotalME" matInput placeholder="Saldo Total ME">
              </mat-form-field>
            </div>
        </div>

    </mat-card>
    <br>
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Datos generales</div>
      <div class="spacer"></div>

      <div class="pav-table-search">
        <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
      </div>
      &nbsp;
      &nbsp;
      <div align="right">
        <button mat-flat-button color="primary" class="pav-button-icon-mail" type="button" (click)="enviarVariosMensajesMail()">
          <mat-icon svgIcon="mail"></mat-icon>
          &nbsp;Enviar por mail
        </button>
        &nbsp;
        <button mat-flat-button color="primary" class="pav-button-icon-whatsapp" type="button" (click)="enviarVariosMensajesWhatsapp()">
          <mat-icon svgIcon="whatsapp"></mat-icon>
          &nbsp;Enviar por Whatsapp
        </button>
      </div>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

    <div class="mat-elevation-z0 overflow-x">

      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" data-label="">
            <mat-checkbox (click)="$event.stopPropagation();"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

          <ng-container matColumnDef="cuenta_corriente">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Corriente </th>
              <td mat-cell *matCellDef="let row" data-label="Cuenta corriente">
                {{row.CCO_CODCCO}}
              </td>
          </ng-container>

          <ng-container matColumnDef="td">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> TD </th>
              <td mat-cell *matCellDef="let row" data-label="TD"> {{row.TDO_CODTDO}} </td>
          </ng-container>

          <ng-container matColumnDef="nro_doc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Doc. </th>
              <td mat-cell *matCellDef="let row" data-label="Nro. Doc."> {{row.CCO_NUMDOC}} </td>
          </ng-container>

          <ng-container matColumnDef="fecha_emision">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Emisión </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha emisión">
                {{row.CCO_FECEMIFormat}}
              </td>
          </ng-container>

          <ng-container matColumnDef="razon_social">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Razón Social </th>
              <td mat-cell *matCellDef="let row" data-label="Razón social"> {{row.cli_nomcli}} </td>
          </ng-container>

          <ng-container matColumnDef="fecha_vencimiento">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Vencimiento </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha Vencimiento"> {{row.cco_fecvenformat}} </td>
          </ng-container>

          <ng-container matColumnDef="dias">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Días </th>
              <td mat-cell *matCellDef="let row" data-label="Días"> {{row.cco_diaven}} </td>
          </ng-container>

          <ng-container matColumnDef="mo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mo. </th>
              <td mat-cell *matCellDef="let row" data-label="Mo."> {{row.TMO_CODREP}} </td>
          </ng-container>

          <ng-container matColumnDef="imp_total_soles">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div [style]="{width: '10px'}">
                  Imp. Total
                </div>
              </th>
              <td mat-cell *matCellDef="let row" data-label="Imp. Total ">
                <div [style]="{textAlign: 'right'}">
                  {{row.cco_impnet}}
                </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="abono_soles">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div [style]="{width: '10px'}">
                  Abono
                </div>
              </th>
              <td mat-cell *matCellDef="let row" data-label="Abono">
                <div [style]="{textAlign: 'right'}">
                  {{row.cco_abonet}}
                </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="saldo_soles">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div [style]="{width: '10px'}">
                  Saldo
                </div>
              </th>
              <td mat-cell *matCellDef="let row" data-label="Saldo">
                <div [style]="{textAlign: 'right'}">
                  {{row.cco_salnet}}
                </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="abono_dolar">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Condi. Pago </th>
              <td mat-cell *matCellDef="let row" data-label="Condi. Pago"> {{row.cpa_descpa}} </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="seleccionarConsulta(row);"></tr>

          <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="18">No se encontraron registros</td>
          </tr>

      </table>

    </div>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    <br>
    <div class="pav-filtro-header">
      <div class="pav-filtro-header-icon"></div>
      <div class="pav-filtro-header-title">Detalle Cuenta Corriente: {{cuentaSeleccionada?.CCO_CODCCO}}</div>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="loaderDetalle"></mat-progress-bar>

    <div class="mat-elevation-z0 overflow-x">
        <table mat-table [dataSource]="dataSource1" matSort>
          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" data-label="Acciones">
              <div class="pav-btns-container">
                <div class="pav-btn-circle-more" matTooltip="Ver">
                  <mat-icon svgIcon="more"></mat-icon>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="fec_emision">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. emisión </th>
              <td mat-cell *matCellDef="let row" data-label="Nro. Doc."> {{row.lbc_fec}} </td>
          </ng-container>

          <ng-container matColumnDef="req_contable">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div [style]="{width: '100px'}">
                  Reg. Contable
                </div>
              </th>
              <td mat-cell *matCellDef="let row" data-label="Pedido"> {{row.Registro}} </td>
          </ng-container>

          <ng-container matColumnDef="sud_diario">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Subdiario </th>
              <td mat-cell *matCellDef="let row" data-label="Fecha emisión"> {{row.sco_descri}} </td>
          </ng-container>

          <ng-container matColumnDef="doc_operacion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. Operación </th>
            <td mat-cell *matCellDef="let row" data-label="Fecha Vencimiento"> {{row.tdo_codpba}} </td>
          </ng-container>

          <ng-container matColumnDef="num_operacion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Número de Operación </th>
            <td mat-cell *matCellDef="let row" data-label="Num. operación"> {{row.PBA_NUMDOC}} </td>
          </ng-container>

          <ng-container matColumnDef="fec_operacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Operación </th>
              <td mat-cell *matCellDef="let row" data-label="Días"> {{row.PBA_FECORD}} </td>
          </ng-container>

          <ng-container matColumnDef="imp_nacional">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div [style]="{width: '25px'}">
                  Importe M.N.
                </div>
              </th>
              <td mat-cell *matCellDef="let row" data-label="Descripción Banco">
                <div [style]="{textAlign: 'right'}">
                  {{row.LBD_IMPMNA}}
                </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="imp_extranjero">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div [style]="{width: '25px'}">
                  Importe M.E.
                </div>
              </th>
              <td mat-cell *matCellDef="let row" data-label="Línea">
                <div [style]="{textAlign: 'right'}">
                  {{row.LBD_IMPMEX}}
                </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="cuenta_contable">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cuenta Contable </th>
              <td mat-cell *matCellDef="let row" data-label="Situación"> {{row.ccn_codccn}} </td>
          </ng-container>

          <ng-container matColumnDef="tip_cambio">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div [style]="{width: '25px'}">
                Tipo de Cambio
              </div>
            </th>
            <td mat-cell *matCellDef="let row" data-label="Cod. Banco">
              <div [style]="{textAlign: 'right'}">
                {{row.LBD_TIPCAM}}
              </div>
            </td>
        </ng-container>

          <ng-container matColumnDef="linea">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Línea </th>
              <td mat-cell *matCellDef="let row" data-label="Mo."> {{row.lcc_des}} </td>
          </ng-container>

          <ng-container matColumnDef="situacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Situación </th>
              <td mat-cell *matCellDef="let row" data-label="Imp. Total S/."> {{row.sdc_des}} </td>
          </ng-container>

          <ng-container matColumnDef="tip_doc_ref">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip. Doc. Ref. </th>
              <td mat-cell *matCellDef="let row" data-label="Abono S/."> {{row.tdo_codtdo}} </td>
          </ng-container>

          <ng-container matColumnDef="doc_rec">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Doc. Ref. </th>
              <td mat-cell *matCellDef="let row" data-label="Saldo S/."> {{row.LBD_DOCREF}} </td>
          </ng-container>

          <ng-container matColumnDef="glosa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
            <td mat-cell *matCellDef="let row" data-label="Glosa"> {{row.LBD_GLODLD}} </td>
          </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="15" *ngIf="!loader1">No se encontraron registros</td>
            </tr>

        </table>

    </div>
    <mat-paginator #paginatorDetalle showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    <br>
    <br>
    <br>
    <br>

  </form>

</div>
