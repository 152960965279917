export const REPORTES_COBRANZAS = [
  {
    id: "COBRANZAS_DC",
    name: "Documentos por cobrar **Doc. con Saldo",
    s26_grupo: "43001",
    esDinamico: false,
  },
  {
    id: "COBRANZAS_DCG",
    name: "Documentos por cobrar **Doc. con Saldo por Grupo",
    s26_grupo: "43001",
    esDinamico: false,
  },
  {
    id: "COBRANZAS_MC",
    name: "Movimientos de cobranza",
    s26_grupo: "43001",
    esDinamico: false,
  },
  {
    id: "COBRANZAS_ECCli",
    name: "Estado cuenta Cliente",
    s26_grupo: "43001",
    esDinamico: false,
  },
  {
    id: "COBRANZAS_TCC",
    name: "Tabulado de cuentas por cobrar",
    s26_grupo: "43001",
    esDinamico: false
  },
];

export const REPORTES_COBRANZAS_DINAMICOS = [
  {
    id: "COBRANZAS_ECC",
    name: "Documentos por cobrar",
    s26_grupo: "43001",
    codigo: '001',
    esDinamico: true
  },
  {
    id: "COBRANZAS_MC",
    name: "Movimientos de cobranza",
    s26_grupo: "43002",
    codigo: '002',
    esDinamico: true
  },
]
