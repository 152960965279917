import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MAT_DATE_FORMATS, MatRipple } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { AddProductDialogData } from '@data/interfaces/modules/almacen/kardex.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';
import { PreferenciaImpresion } from '@shared/components/dialogs/preferencias-impresion/preferencias-impresion.dialog';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { HistorialPreciosDialog } from '@shared/components/dialogs/ventas/historial-precios/historial-precios.dialog';
import { PavShowProductImageDialog } from '@shared/components/pav-show-product-image-dialog/pav-show-product-image.dialog';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { agrupar } from '@utils/array/array';
import { unsubscribeSubscription } from '@utils/others/subscription';
import * as pdfBuilder from '@utils/pdfmaker/builder/ventas.pdfmaker';
import { fillTable, searchInTable } from '@utils/tables/table';
import { Observable, Subscription } from 'rxjs';
import { AddProductDialog } from 'src/app/admin/apps/almacen/pages/operaciones/kardex/dialogs/add-product-dialog/add-product.dialog';
import { CanComponentReturn, CanDeactivateGuard } from 'src/app/guards/return.guard';
import { MaestrosLS, Order } from 'src/app/models';
import { ConfiguracionTecnicaVentas } from 'src/app/models/ventas/configuracion-tecnica-ventas';
import { DescuentosPD, DetallePcd } from 'src/app/models/ventas/order';
import { AlmacenService, AuthenticationService, ComprasService, ConfigurationService, ContabilidadService, VentasService } from 'src/app/services';
import { UnidadMedidaService } from 'src/app/services/api/almacen/configuracion/unidad-medida.service';
import { PrinterService } from 'src/app/services/api/printer.service';
import { CotizacionService } from 'src/app/services/api/ventas/operaciones/cotizacion.service';
import { PedidoService } from 'src/app/services/api/ventas/operaciones/pedido.service';
import { PromocionVentaService } from 'src/app/services/api/ventas/precio-descuento-promociones/promocion-venta.service';
import { ConfirmationReturnService } from 'src/app/services/confirmation-return.service';
import { formatDateWithDash } from 'src/app/utils/formats/date.format';
import { CopyFromOrderComponent } from '../dialogs/copy-from-order/copy-from-order.component';
import { ProductoMultipleDialog } from '../dialogs/producto-multiple/producto-multiple.dialog';
import { PedidoBottomSheet } from '../pedido-bottom-sheet/pedido-bottom-sheet';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-pedido-form',
  templateUrl: './pedido-form.component.html',
  styleUrls: ['./pedido-form.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class PedidoFormComponent implements OnInit, OnDestroy, CanComponentReturn {

  isMobile: boolean = false;
  isTablet: boolean = false;

  selected: any;
  rucCompania: string;
  vendedorId: string;

  selectRow(row: any) {
    this.selected = row;

    this.dataSourceDescuento = fillTable(row.descuentos_pds, this.paginatorDescuento, this.sortDescuento);

    this.consultaStock$ = this._ventasService.consultaStock(this.order.SUC_CODSUC, this.order.ANO_CODANO, this.order.MES_CODMES, this.order.ALM_CODALM, row.PRD_CODPRD).subscribe(
      response => {
        this.fisico = response[0].fisico;
        this.disponible = response[0].disponible;
        this.reservado = response[0].reservado;
      },
      error => {
        this._snackBarService.showError(error, 'error');
      }
    )
  }

  vendedores: any[] = [];
  transportistas: any[] = [];
  agencias: any[] = [];
  condiciones: any[] = [];
  zonas: any[] = [];
  productos: any[] = [];

  loaderDocumentos: boolean = false;
  loaderDiasEntrega: boolean = false;
  loaderProductos: boolean = false;
  loaderDescuentos: boolean = false;
  loaderStock: boolean = false;
  loaderFormato: boolean = false;
  loaderImprimir: boolean = false;

  displayedColumns: string[] = ['PCD_CORPCD', 'PRD_CODPRD', 'sec', 'PRM_CODPRM', 'UME_CODVEN', 'PCD_CANSOL', 'PCD_PREUNI', 'PCD_PRUIGV', 'PCD_IMPBRU', 'PCD_PORDES', 'PCD_IMPDES', 'PCD_VALVTA', 'PCD_IMPIGV', 'PCD_IMPTOT', 'PCD_CANATE', 'PCD_CANSUS', 'PCD_GLOPCD'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsDescuento: string[] = ['acciones', 'cod_desc', 'desc', 'porc_desc', 'importe_desc'];
  dataSourceDescuento: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorDescuento: MatPaginator;
  @ViewChild(MatSort) sortDescuento: MatSort;

  columnsDocumentos: string[] = ['campo1', 'campo2', 'campo3'];
  documentos: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorDocumento: MatPaginator;
  @ViewChild(MatSort) sortDocumento: MatSort;

  columnsDiasEntrega: string[] = ['campo1'];
  dias: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorDiasEntrega: MatPaginator;
  @ViewChild(MatSort) sortDiasEntrega: MatSort;

  order: Order;

  loaderData: boolean;
  loaderAnular: boolean;
  loaderReg: boolean;

  motivos: any = [];
  companias: any = [];
  monedas: any = [];
  zonasVenta: any = [];
  tiposPrecio: any = [];
  clientes: any = [];
  lugaresDespacho: any = [];
  diasEntrega: any = [];
  centros: any = [];
  formasCalculoItem: any = [];
  almacenes: any = [];
  sucursales: any = [];
  unidadesMedida: any = [];
  cotizaciones: any = [];
  promociones: any = [];
  descuentos: any = [];
  tiposDocumento: any = [];

  maestros: MaestrosLS = new MaestrosLS();

  cod_prod: String;
  prod: String;
  fisico: Number = 0;
  disponible: Number = 0;
  reservado: Number = 0;

  @ViewChild(MatRipple) ripple: MatRipple;
  direccion: string = "";

  usuario: any;
  fecha: Date = new Date();

  configuracionTecnicaVentas: ConfiguracionTecnicaVentas;

  tipoCambio$: Subscription;
  consultaStock$: Subscription;
  centroCosto$: Subscription;
  diasEntrega$: Subscription;
  lugarDespacho$: Subscription;
  sucursal$: Subscription;
  formaCalcula$: Subscription;
  listaPrecio$: Subscription;
  clientes$: Subscription;
  sendForm$: Subscription;
  pedido$: Subscription;
  detalleCliente$: Subscription;
  period$: Subscription;
  loading$: Subscription;
  cotizacion$: Subscription;
  motivos$: Subscription;
  companias$: Subscription;
  vendedores$: Subscription;
  condiciones$: Subscription;
  zonas$: Subscription;
  tiposPrecio$: Subscription;
  transportistas$: Subscription;
  almacenes$: Subscription;
  productos$: Subscription;
  unidadesMedida$: Subscription;
  promociones$: Subscription;
  descuentos$: Subscription;
  preferenciasImpresion$: Subscription;
  configuracionTecnica$: Subscription;
  config$: Subscription;
  imprimirFormato$: Subscription;
  establecimiento$: Subscription;
  anularPedido$: Subscription;
  tiposDocumento$: Subscription;

  year: string;
  month: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string = '';

  minDate: Date;
  maxDate: Date

  tabSeleccionado: number;
  establecimiento: string;

  preferenciasImpresion: PreferenciaImpresion;

  tablaSeleccionada: boolean = false;
  logo: string;

  // @ViewChild('contextMenuTrigger') contextMenuTrigger: MatMenuTrigger;
  @ViewChild('contextMenuTrigger', { read: MatMenuTrigger }) contextMenuTrigger: MatMenuTrigger;

  @ViewChild('selectContainer') selectContainer: ElementRef;

  cliente: any;

  constructor(
    private _dialogService: DialogService,
    private _ventasService: VentasService,
    private _pedidoService: PedidoService,
    private _comprasService: ComprasService,
    private _contabilidadService: ContabilidadService,
    private _almacenService: AlmacenService,
    private _unidadMedidaService: UnidadMedidaService,
    private _configurationService: ConfigurationService,
    private _authenticationService: AuthenticationService,
    private _cotizacionService: CotizacionService,
    private _snackBarService: SnackBarService,
    private _promocionVentaService: PromocionVentaService,
    private readonly _printerService: PrinterService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private store: Store<PavsoState>,
    private breakpointObserver: BreakpointObserver,
    private confirmationReturnService: ConfirmationReturnService,
    private canDeactivateGuard: CanDeactivateGuard,
  ) {

    this.configuracionTecnicaVentas = new ConfiguracionTecnicaVentas();
    this.initialize();

  }

  initialize(): void {
    this.order = new Order();

    // Establecer los campos por defecto desde el store redux
    this.store.select('establishment').subscribe(state => {
      this.order.CPA_CODCPA = state.establecimientoObj.cpa_codcpa;
      this.order.MMO_CODMMO = state.establecimientoObj.mmo_codmmo;
      this.order.ALM_CODALM = state.establecimientoObj.alm_codalm;
      this.order.TMO_CODTMO = state.establecimientoObj.tmo_codtmo;
      this.order.PCC_INDCAL = state.establecimientoObj.tie_indcal;
    })

    // this.program.CLIENRW
    this.period$ = this.store.select('period').subscribe(({ year, month }) => {
      this.year = year;
      this.month = month;

      this.minDate = new Date(parseInt(this.year), parseInt(this.month) - 1, 1);
      this.maxDate = new Date(parseInt(this.year), parseInt(this.month), 0);

      const now = new Date();
      const mes = now.getMonth() + 1 > 9 ? now.getMonth() + 1 : `0${now.getMonth() + 1}`;
      const anio = now.getFullYear().toString();

      this.order.PCC_FECDOC = this.maxDate;
      this.order.PCC_FECENT = this.maxDate;

      console.log('ingreso a fecha =', mes == this.month && anio == this.year)
      if (mes == this.month && anio == this.year) {
        console.log('ingreso a fecha')
        this.order.PCC_FECDOC = new Date();
        this.order.PCC_FECENT = new Date();

        this.seleccionarFecha();
      }
    })

    this.config$ = this.store.select('config').subscribe(state => {
      this.logo = state.logo;
      this.rucCompania = this._configurationService.obtenerClienteId();
      this.vendedorId = state.vendedorId;

      if (this.vendedorId != '' && this.vendedorId != undefined && this.vendedorId != null) {
        this.order.VDE_CODVDE = this.vendedorId;
      }
    })

    this.dataSource = fillTable([], this.paginator, this.sort);
    this.dataSourceDescuento = fillTable([], this.paginatorDescuento, this.sortDescuento);

    this.documentos = fillTable([], this.paginatorDocumento, this.sortDocumento);

    this.dias = fillTable([], this.paginatorDiasEntrega, this.sortDiasEntrega);

    this.order.ANO_CODANO = this.year;
    this.order.MES_CODMES = this.month;
    this.order.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();

    this.order.PCC_CODUSE = this._authenticationService.getUsuarioSistema();

  }

  ngOnInit(): void {

    window.scrollTo(0, 0);

    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingModule) this.loadData();
    });

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

    this.establecimiento$ = this.store.select('establishment').subscribe(state => {
      this.establecimiento = state.code;
      this.order.TIE_CODTIE = !this.establecimiento || this.establecimiento == '' ? '01' : this.establecimiento;
    })

  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // Lógica para mostrar el modal de confirmación antes de la navegación
    return this.confirmationReturnService.confirm('Puede volver');
  }

  loadData(): void {
    this.loaderData = true;
    this._activatedRoute.params.subscribe(({ id }) => {
      this.uid = id;
    })

    this.btnName = this.uid == '0' || this.uid.includes('cotizacion') ? this.buttonsName.BTN_STORE : this.buttonsName.BTN_UPDATE;
    this.uid == '0' ? this.canDeactivateGuard.setShowConfirmationDialog(true) : this.canDeactivateGuard.setShowConfirmationDialog(false);

    this.loadMaestros();
  }

  async loadMaestros() {

    this.maestros = this._configurationService.obtenerMaestrosLS();

    if(this._configurationService.obtenerMaestrosLS().vendedores.length == 0) {
      this.vendedores = await this._ventasService.obtenerVendedores().toPromise();
      this.maestros.vendedores = this.vendedores;
      this._configurationService.establecerMaestrosLS(this.maestros);
    } else {
      this.vendedores = this.maestros.vendedores;
    }

    if(this._configurationService.obtenerMaestrosLS().condicionesPago.length == 0) {
      this.condiciones = await this._comprasService.obtenerCondicionesPago().toPromise();
      this.maestros.condicionesPago = this.condiciones;
      this._configurationService.establecerMaestrosLS(this.maestros);
    } else {
      this.condiciones = this.maestros.condicionesPago;
    }

    if(this._configurationService.obtenerMaestrosLS().almacenes.length == 0) {
      this.almacenes = await this._almacenService.obtenerAlamacenes().toPromise();
      this.maestros.almacenes = this.almacenes;
      this._configurationService.establecerMaestrosLS(this.maestros);
    } else {
      this.almacenes = this.maestros.almacenes;
    }

    if(this._configurationService.obtenerMaestrosLS().centrosCosto.length == 0) {
      this.centros = await this._contabilidadService.listarCentroCostos().toPromise();
      this.maestros.centrosCosto = this.centros;
      this._configurationService.establecerMaestrosLS(this.maestros);
    } else {
      this.centros = this.maestros.almacenes;
    }

    if(this._configurationService.obtenerMaestrosLS().tiposDocumento.length == 0) {
      this.tiposDocumento = await this._contabilidadService.obtenerTiposDocumento().toPromise();
      this.maestros.tiposDocumento = this.tiposDocumento;
      this._configurationService.establecerMaestrosLS(this.maestros);
    } else {
      this.tiposDocumento = this.maestros.tiposDocumento;
    }

    this.tiposDocumento = this.maestros.tiposDocumento.filter(item => item.TDO_CODTDO == 'FAC' || item.TDO_CODTDO == 'BVE' || item.TDO_CODTDO == 'GRE');

    if(this._configurationService.obtenerMaestrosLS().tiposPrecio.length == 0) {
      this.tiposPrecio = await this._ventasService.obtenerTiposPrecio().toPromise();
      this.maestros.tiposPrecio = this.tiposPrecio;
      this._configurationService.establecerMaestrosLS(this.maestros);
    } else {
      this.tiposPrecio = this.maestros.tiposPrecio;
    }

    if(this._configurationService.obtenerMaestrosLS().transportistas.length == 0) {
      this.transportistas = await this._almacenService.obtenerTransportistas().toPromise();
      this.maestros.transportistas = this.transportistas;
      this._configurationService.establecerMaestrosLS(this.maestros);
    } else {
      this.transportistas = this.maestros.transportistas;
    }

    if(this._configurationService.obtenerMaestrosLS().unidadesMedida.length == 0) {
      this.unidadesMedida = await this._unidadMedidaService.obtenerUnidadesDeMedida().toPromise();
      this.maestros.unidadesMedida = this.unidadesMedida;
      this._configurationService.establecerMaestrosLS(this.maestros);
    } else {
      this.unidadesMedida = this.maestros.unidadesMedida;
    }

    if(this._configurationService.obtenerMaestrosLS().cotizaciones.length == 0) {
      this.cotizaciones = await this._cotizacionService.obtenerCotizaciones('01').toPromise();
      this.maestros.cotizaciones = this.cotizaciones;
      this._configurationService.establecerMaestrosLS(this.maestros);
    } else {
      this.cotizaciones = this.maestros.cotizaciones;
    }

    if(this._configurationService.obtenerMaestrosLS().promocionesVenta.length == 0) {
      this.promociones= await this._promocionVentaService.obtenerPromocionesVenta().toPromise();
      this.maestros.promocionesVenta = this.promociones;
      this._configurationService.establecerMaestrosLS(this.maestros);
    } else {
      this.promociones = this.maestros.promocionesVenta;
    }

    if(this._configurationService.obtenerMaestrosLS().descuentos.length == 0) {
      this.descuentos = await this._ventasService.obtenerDescuentos().toPromise();
      this.maestros.descuentos = this.descuentos;
      this._configurationService.establecerMaestrosLS(this.maestros);
    } else {
      this.descuentos = this.maestros.descuentos;
    }

    if(this._configurationService.obtenerMaestrosLS().motivos.length == 0) {
      this.motivos = await this._ventasService.obtenerMotivosMovimiento().toPromise();
      this.maestros.motivos = this.motivos;
      this._configurationService.establecerMaestrosLS(this.maestros);
    } else {
      this.motivos = this.maestros.motivos;
    }

    if(this._configurationService.obtenerMaestrosLS().formasCalculo.length == 0) {
      this.formasCalculoItem = await this._ventasService.obtenerFormaCalculaItem().toPromise();
      this.maestros.formasCalculo = this.formasCalculoItem;
      this._configurationService.establecerMaestrosLS(this.maestros);
    } else {
      this.formasCalculoItem = this.maestros.formasCalculo;
    }

    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.order.PCC_FECDOC)).subscribe(
      tipoCambio => {
        this.order.PCC_TIPCAM = tipoCambio[0].tipcam;
        localStorage.setItem("tipo-cambio", this.order.PCC_TIPCAM.toFixed(4));

        this.configuracionTecnica$ = this._ventasService.obtenerConfiguracionTecnicaVentas().subscribe(
          configuracion => {

            this.configuracionTecnicaVentas = configuracion;

            if (this.uid != '0' && !this.uid.includes('cotizacion')) {
              this.obtenerPedido()
              return;
            };

            if (this.uid.includes('cotizacion')) {
              this.obtenerPedidoDesdeCotizacion();
            }

            this.seleccionDeLista(this.order.TLP_CODTLP)

            this.loaderData = false;


          },
          error => {
            this._snackBarService.showError('Error al obtener configuración técnica de ventas', 'Ok');
            this.loaderData = false;
          }
        )

      },
      error => {
        this._snackBarService.showError('Error al obtener tipo de cambio', 'Ok');
        this.loaderData = false;

      }
    )

  }

  openMenu(event: MouseEvent) {

    event.preventDefault();

    const selectContainerRect = this.selectContainer.nativeElement.getBoundingClientRect();
    const top = selectContainerRect.top + window.scrollY;
    const left = selectContainerRect.left + window.scrollX + 120;

    // Abre el menú contextual
    this.contextMenuTrigger.openMenu();

    // Ajusta la posición del menú manualmente
    setTimeout(() => {
      const menuPanel = document.querySelector('.mat-menu-panel') as HTMLElement;
      if (menuPanel) {
        menuPanel.style.position = 'absolute';
        menuPanel.style.top = `${top}px`;
        menuPanel.style.left = `${left}px`;
        menuPanel.style.transform = 'none';
      }
    }, 0);
  }

  anularPedido(): void {

    if (this.uid != '0') {
      this.loaderAnular = true;
      this.order.PCC_INDSTA = '0';
      this.anularPedido$ = this._pedidoService.editarPedido(this.order).subscribe(
        response => {
          this._snackBarService.showSuccess('Pedido anulado correctamente', 'OK');
          this.obtenerPedido();
          this.loaderAnular = false;
        },
        error => {
          this._snackBarService.showError('Error al anular pedido', 'OK');
          this.loaderAnular = false;
        }
      )
    }

  }

  obtenerPedido(): void {

    this.pedido$ = this._pedidoService.obtenerPedido('01', this.uid).subscribe(
      pedido => {
        if (pedido.PCC_INDTER == '1') {
          this.loaderData = false;
          this._snackBarService.showError('El pedido ya no se puede visualizar, consulte con el administrador', 'Ok', () => {
            console.log('volver')
            this._router.navigate(['/modulo-ventas/pedidos']);
          });
          return;
        }

        this.order = pedido;
        this.order.pcc_impfac = pedido.pcc_impfac == 1 ? true : false;
        this.order.pcc_impgui = pedido.pcc_impgui == 1 ? true : false;
        this.order.pcc_rutext = pedido.pcc_rutext == 1 ? true : false;

        console.log('-====== PEDIDO ======', pedido)

        this.seleccionarCliente(this.order.CLI_CODCLI);

        this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);

        this.loaderData = false;
      },
      error => {
        this._snackBarService.showError('Error al obtener pedido', 'Ok');
        this.loaderData = false;
      }
    )

  }

  obtenerPedidoDesdeCotizacion(): void {
    const idCotizacion = this.uid.slice(11)

    this.order.pcc_numcot = idCotizacion;
    this.obtenerDatosCotizacion();
  }

  seleccionarFecha(): void {

    this.order.PCC_FECENT = this.order.PCC_FECDOC;
    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.order.PCC_FECDOC)).subscribe(
      response => {
        this.order.PCC_TIPCAM = response[0].tipcam;
        localStorage.setItem("tipo-cambio", this.order.PCC_TIPCAM.toFixed(4));
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  enviarFormulario(f: NgForm): void {
    this.loaderReg = true;

    if (!this.validarFormulario()) {
      this.loaderReg = false;
      return;
    }

    this.order.TIE_CODTIE = this.establecimiento;

    if(!this.order.TIE_CODTIE) {

      this.order.TIE_CODTIE = '01'
    } else {
      if (this.order.TIE_CODTIE.trim() == '') {
        this.order.TIE_CODTIE = '01'
      }
    }


    this.order.pcc_impgui = this.order.pcc_impgui ? 1 : 0;
    this.order.pcc_impfac = this.order.pcc_impfac ? 1 : 0;
    this.order.pcc_rutext = this.order.pcc_rutext ? 1 : 0;

    // console.log('pedido', this.order)
    // this.loaderReg = false;
    // return null;

    this.uid == '0' ? this.registrarPedido(f) : this.editarPedido(f);

  }

  validarFormulario(): boolean {

    if (!this.order.CPA_CODCPA) {
      this._snackBarService.showError('Seleccionar la condición de pago para el pedido.', 'OK');
      return false;
    }

    if (!this.order.CPD_CODCPD) {
      this._snackBarService.showError('Seleccionar un canal para la cotización.', 'OK');
      return false;
    }

    if (!this.order.VDE_CODVDE) {
      this._snackBarService.showError('Seleccionar un vendedor.', 'OK');
      return false;
    }


    if (!this.order.ALM_CODALM) {
      this._snackBarService.showError('Seleccionar un almacén para el pedido.', 'OK');
      return false;
    }

    if (!this.order.CLI_CODCLI) {
      this._snackBarService.showError('Seleccionar un cliente para el pedido.', 'OK');
      return false;
    }

    if (!(this.order.PCC_TIPCAM > 0)) {
      this._snackBarService.showError('Establecer un tipo de cambio para el pedido.', 'OK');
      return false;
    }

    if (!this.order.LPC_CODLPC) {
      this._snackBarService.showError('Seleccionar un tipo de lista precio y número de lista', 'OK');
      this.loaderReg = false;
      return;
    }

    if (this.order.detalle_pcd.length == 0) {
      this._snackBarService.showError('Ingresar al menos un producto', 'OK');
      return false;
    }

    this.order.detalle_pcd.forEach(item => {
      if (!item.PCD_CANSOL || item.PCD_CANSOL == 0) {
        this._snackBarService.showError('Ingresar cantidad solicitada', 'OK');
        return false;
      }

      if (!item.PCD_PREUNI || item.PCD_PREUNI == 0) {
        this._snackBarService.showError('Ingresar precio solicitada', 'OK');
        return false;
      }

    })

    return true;
  }

  registrarPedido(f: NgForm): void {

    // if(!validarPeriodo(this.order.PCC_FECDOC, this.year, this.month)) {
    //   this._snackBarService.showError('La Fecha de Registro Debe de Coincidir con la Fecha de Actividad', 'OK');
    //   this.loaderReg = false;
    //   return;
    // }

    this.generarCorrelativo();

    this.order.LPC_CODLPC = this.order.LPC_CODLPC.toString();
    this.order.LPC_CODLPC.replace(/"/g, "'");

    this.sendForm$ = this._pedidoService.registrarPedido(this.order).subscribe(
      response => {
        this._dialogService.openDialog(SuccessComponent, 'Pedido Registrado', '350px', '', '');
        this.canDeactivateGuard.setShowConfirmationDialog(false);
        f.resetForm();
        this.volver();
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderReg = false;
      }
    )

  }

  editarPedido(f: NgForm): void {

    this.generarCorrelativo();

    this.sendForm$ = this._pedidoService.editarPedido(this.order).subscribe(
      response => {
        console.log('response', response)
        this._dialogService.openDialog(SuccessComponent, 'Pedido Actualizado', '350px', '', '');
        this.loaderReg = false;

      },
      error => {
        this._snackBarService.showError('Error al actualizar pedido', 'Ok');
        this.loaderReg = false;
      }
    )
  }

  verImagen(): void {
    const dialogRef = this.dialog.open(PavShowProductImageDialog, {
      width: 'auto',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  obtenerDatosCotizacion(): void {

    if (!this.order.pcc_numcot) {
      this._snackBarService.showError('Seleccionar una cotización', 'Ok');
      return;
    }

    this._dialogService.openDialog(ConfirmationComponent, '¿Desea obtener datos de la cotización?', '', '', '').subscribe(result => {
      if (result) {
        this.cotizacion$ = this._cotizacionService.obtenerCotizacion('01', this.order.pcc_numcot).subscribe(
          cotizacion => {
            console.log('cotizacion encontrada', cotizacion)
            const vde_codvde = cotizacion.VDE_CODVDE
            console.log('vendedor', vde_codvde)

            this.order = cotizacion;
            // this.order.VDE_CODVDE = '0002';
            console.log('this.order.VDE_CODVDE', this.order.VDE_CODVDE)
            this.order.PCC_NUMPCC = null;
            console.log('pedido', this.order)


            // cotizacion[0].COTI_COME_PCD.forEach(element => {

            //   const producto = new DetallePcd();
            //   producto.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();
            //   producto.PCD_CODUSE = this._configurationService.obtenerIdUsuario();
            //   producto.PRD_CODPRD = element.codprd;
            //   producto.UME_CODVEN = element.codven;
            //   producto.PCD_CANSOL = element.cansol;
            //   producto.PCD_CANVEN = element.canven;
            //   producto.PCD_GLOPCD = element.glopcd;
            //   producto.PCD_PREUNI = element.preuni;
            //   producto.PCD_PRUIGV = element.pruigv;
            //   producto.PCD_IMPBRU = element.impbru;
            //   producto.PCD_PORDES = element.pordes;
            //   producto.PCD_IMPDES = element.impdes;
            //   producto.PCD_VALVTA = element.valvta;
            //   producto.PCD_IMPIGV = element.impigv;
            //   producto.PCD_IMPTOT = element.imptot;

            //   this.order.detalle_pcd.push(producto);
            // });

            this.order.detalle_pcd.forEach((element, key) => {
              element.PCD_CORPCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
            })

            this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);

            this.seleccionarCliente(this.order.CLI_CODCLI);

            this.calcularTotales();
          },
          error => {
            this._snackBarService.showError('No se encontró los datos de la cotización', 'Ok')
          }
        )
      }
    })

  }

  blurPrecio(row: DetallePcd): void {
    console.log('precio', row.PCD_PREUNI.toFixed(2))
    row.PCD_PREUNI = Number(row.PCD_PREUNI.toFixed(2))
  }

  generarCorrelativo(): void {
    this.order.detalle_pcd.forEach((element, index) => {

      element.PCD_CORPCD = (index + 1).toString();
      element.PCD_CANVEN = element.PCD_CANSOL;
      element.PCD_CODUSE = (this._authenticationService.getIdentity()).usuariosistema;
      element.PCD_PRELIS = element.PCD_PRUIGV;

      if (element.PCD_CORPCD.length == 1) element.PCD_CORPCD = "00" + element.PCD_CORPCD
      if (element.PCD_CORPCD.length == 2) element.PCD_CORPCD = "0" + element.PCD_CORPCD

    });
  }

  seleccionDeLista(tipo_lista): void {
    this.listaPrecio$ = this._ventasService.listaDePrecios(tipo_lista).subscribe(
      response => {
        console.log('LISTA PRECIO', response)
        const listas = response.reverse();
        this.order.LPC_CODLPC = listas.length > 0 ? listas[0].LPC_CODLPC : null;
      },
      error => {

        this._snackBarService.showError(error.error.msg, 'OK')
      }
    )
  }

  agregarProductoMultiple(): void {
    const dialogRef = this.dialog.open(ProductoMultipleDialog, {
      width: 'auto',
      data: {
        products: this.productos,
        almacen: this.order.ALM_CODALM,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('result', result)
      result.forEach(item => {
        const producto = new DetallePcd();
        producto.PRD_CODPRD = item.prd_codprd;
        producto.UME_CODVEN = item.UNIDAD;
        producto.PCD_CANSOL = 1;
        producto.PCD_PREUNI = 0;
        // producto.seleccionado = true;

        producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;

        if (this.order.PCC_INDCAL == '1') producto.PCD_PRUIGV = (this.order.PCC_TASIGV / 100 + 1) * producto.PCD_PREUNI;

        producto.PCD_IMPDES = producto.PCD_IMPBRU * (producto.PCD_PORDES / 100)

        producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;

        producto.PCD_IMPIGV = this.order.PCC_INDCAL == '0' ? 0 : producto.PCD_VALVTA * this.order.PCC_TASIGV / 100;

        producto.PCD_IMPTOT = producto.PCD_IMPIGV + producto.PCD_VALVTA;

        this.order.detalle_pcd.push(producto)

        this.calcularTotales();

        this.order.detalle_pcd.forEach((element, key) => {
          element.PCD_CORPCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
        })

      })

      this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);

    })
  }

  agregarProductoModal(): void {

    if (!this.order.ALM_CODALM) {
      this._snackBarService.showError('Debe seleccionar un almacen', 'Ok');
      return;
    }

    if (!this.order.TLP_CODTLP) {
      this._dialogService.openDialog(ConfirmationComponent, '¿Continuar sin seleccionar una lista de precio?', '', '', '').subscribe(result => {

        if (result) {

          const dialogRef = this.dialog.open(AddProductDialog, {
            width: '400px',
            data: {
              products: this.productos,
              almacen: this.order.ALM_CODALM,
              canSetDiscount: true,
              tipoListaPrecio: this.order.TLP_CODTLP,
              listaPrecio: this.order.LPC_CODLPC,
              moneda: this.order.TMO_CODTMO,
              cliente: this.order.CLI_CODCLI,
              canEditPrice: this.configuracionTecnicaVentas.s71_edipre,
              canAddCost: false,
              canAddDiscount: true,
              canAddPrice: true,
              tasaCambio: this.order.PCC_TIPCAM
            } as AddProductDialogData,
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result) {

              const producto = new DetallePcd();
              producto.PRD_CODPRD = result.product;
              producto.PCD_CANSOL = result.cantidad;
              producto.PCD_PREUNI = result.precio;
              producto.UME_CODVEN = result.um;
              producto.seleccionado = true;


              producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;

              if (this.order.PCC_INDCAL == '1') producto.PCD_PRUIGV = (this.order.PCC_TASIGV / 100 + 1) * producto.PCD_PREUNI;

              producto.PCD_IMPDES = producto.PCD_IMPBRU * (producto.PCD_PORDES / 100)

              producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;

              producto.PCD_IMPIGV = this.order.PCC_INDCAL == '0' ? 0 : producto.PCD_VALVTA * this.order.PCC_TASIGV / 100;

              producto.PCD_IMPTOT = producto.PCD_IMPIGV + producto.PCD_VALVTA;

              this.order.detalle_pcd.push(producto)

              // this.calcularMontosFila(producto)

              this.calcularTotales();

              this.order.detalle_pcd.forEach((element, key) => {
                element.PCD_CORPCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
              })

              this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);
              this.selected = producto;
              this.consultaStock$ = this._ventasService.consultaStock(this.order.SUC_CODSUC, this.order.ANO_CODANO, this.order.MES_CODMES, this.order.ALM_CODALM, producto.PRD_CODPRD).subscribe(
                response => {
                  console.log('stock', response)
                  this.fisico = response[0].fisico;
                  this.disponible = response[0].disponible;
                  this.reservado = response[0].reservado;
                },
                error => {
                  this._snackBarService.showError(error, 'error');
                }
              )

              if (result.porDesc > 0) {
                this.agregarDescuento(result.porDesc);
              }
            }

          });

        } else {
          this.tabSeleccionado = 2;
        }

      })

      return;
    }

    const dialogRef = this.dialog.open(AddProductDialog, {
      width: '400px',
      data: {
        products: this.productos,
        almacen: this.order.ALM_CODALM,
        canAddDiscount: true,
        canAddPrice: true,
        canAddCost: false,
        tipoListaPrecio: this.order.TLP_CODTLP,
        listaPrecio: this.order.LPC_CODLPC,
        moneda: this.order.TMO_CODTMO,
        cliente: this.order.CLI_CODCLI,
        canEditPrice: this.configuracionTecnicaVentas.s71_edipre
      } as AddProductDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        const producto = new DetallePcd();
        producto.PRD_CODPRD = result.product;
        producto.PCD_CANSOL = result.cantidad;
        producto.PCD_PREUNI = result.precio;
        producto.seleccionado = true;


        producto.UME_CODVEN = result.um;

        producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;

        if (this.order.PCC_INDCAL == '1') producto.PCD_PRUIGV = (this.order.PCC_TASIGV / 100 + 1) * producto.PCD_PREUNI;

        producto.PCD_IMPDES = producto.PCD_IMPBRU * (producto.PCD_PORDES / 100)

        producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;

        producto.PCD_IMPIGV = this.order.PCC_INDCAL == '0' ? 0 : producto.PCD_VALVTA * this.order.PCC_TASIGV / 100;

        producto.PCD_IMPTOT = producto.PCD_IMPIGV + producto.PCD_VALVTA;

        this.order.detalle_pcd.push(producto)

        this.calcularTotales();

        this.order.detalle_pcd.forEach((element, key) => {
          element.PCD_CORPCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
        })

        this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);

        this.selectRow(producto)
        // this.selected = producto;

        this.consultaStock$ = this._ventasService.consultaStock(this.order.SUC_CODSUC, this.order.ANO_CODANO, this.order.MES_CODMES, this.order.ALM_CODALM, producto.PRD_CODPRD).subscribe(
          response => {
            this.fisico = response[0].fisico;
            this.disponible = response[0].disponible;
            this.reservado = response[0].reservado;
          },
          error => {
            this._snackBarService.showError(error, 'error');
          }
        )

        if (result.porDesc > 0) {
          this.agregarDescuento(result.porDesc);
        }
      }

    });

  }

  agregarProducto(): void {

    if (!this.order.TLP_CODTLP) {
      this._dialogService.openDialog(ConfirmationComponent, '¿Continuar sin seleccionar una lista de precio?', '', '', '').subscribe(result => {

        if (result) {

          const producto = new DetallePcd();

          producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
          producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
          producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
          producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;

          this.order.detalle_pcd.push(producto)

          this.calcularTotales();

          this.order.detalle_pcd.forEach((element, key) => {
            element.PCD_CORPCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
          })

          this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);

        } else {
          this.tabSeleccionado = 2;
        }

      })

      return;
    }

    const producto = new DetallePcd();

    producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
    producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
    producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
    producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;

    this.order.detalle_pcd.push(producto)

    this.calcularTotales();

    this.order.detalle_pcd.forEach((element, key) => {
      element.PCD_CORPCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);

  }

  generarFacturaDesdePedido(): void {
    this._router.navigate(['/modulo-ventas/factura/pedido=' + this.uid])
  }

  consultarPrecioProducto(producto: DetallePcd): void {
    const now = new Date();

    const fecha = formatDateWithDash(now);

    if (!this.order.TLP_CODTLP) {
      this._snackBarService.showError('Seleccionar lista', 'OK');
    }

    if (!this.order.LPC_CODLPC) {
      this._snackBarService.showError('Establecer Nro de lista', 'OK');
    }

    this.listaPrecio$ = this._ventasService.listaDePreciosProducto(producto.PRD_CODPRD, this.order.TMO_CODTMO, this.order.CLI_CODCLI, this.order.TLP_CODTLP, this.order.LPC_CODLPC, fecha).subscribe(
      response => {
        console.log('precio producto', response)
        if (this.order.TMO_CODTMO == "SO") {
          producto.PCD_PREUNI = response[0].pre_prenac;
          producto.PCD_PRUIGV = response[0].pre_vtanac;
        } else {
          producto.PCD_PREUNI = response[0].pre_predol;
          producto.PCD_PRUIGV = response[0].pre_vtadol;
        }

        producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;

        producto.PCD_IMPDES = producto.PCD_IMPBRU * (producto.PCD_PORDES / 100)

        producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;

        producto.PCD_IMPIGV = this.order.PCC_INDCAL == '0' ? 0 : producto.PCD_VALVTA * this.order.PCC_TASIGV / 100;

        producto.PCD_IMPTOT = producto.PCD_IMPIGV + producto.PCD_VALVTA;

        this.calcularTotales();

      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )

  }

  eliminarProducto(): void {

    let row = this.order.detalle_pcd.find(item => item == this.selected);

    console.log('fila seleccionada', row);

    if (!row && this.order.detalle_pcd.length > 0) row = this.order.detalle_pcd[0];

    let details = [];
    this.order.detalle_pcd.forEach(element => {
      if (element.PCD_CORPCD != row.PCD_CORPCD) {
        details.push(element)
      }
    });

    this.order.detalle_pcd = details;

    this.order.detalle_pcd.forEach((element, key) => {
      element.PCD_CORPCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.dataSource = fillTable(details, this.paginator, this.sort);

    this.selected = null;
    this.calcularTotales();

  }

  consultarStock(): void {
    let row = this.order.detalle_pcd.find(item => item == this.selected);

    if (!row) {
      this._snackBarService.showError('Seleccionar una fila del detalle', 'Ok');
      return;
    };

    const now = new Date();

    if (!this.order.SUC_CODSUC) {
      this._snackBarService.showError('No se seleccionó la sucursal', 'OK');
      return;
    }

    if (!this.order.ALM_CODALM) {
      this._snackBarService.showError('No se seleccionó el almacen', 'OK');
      return;
    }

    if (!row.PRD_CODPRD) {
      this._snackBarService.showError('No se seleccionó el producto', 'OK');
      return;
    }

    this.consultaStock$ = this._ventasService.consultaStock(this.order.SUC_CODSUC, now.getFullYear(), now.getMonth() + 1, this.order.ALM_CODALM, row.PRD_CODPRD).subscribe(
      response => {

        this.cod_prod = row.PRD_CODPRD;
        const producto = this.productos.find(item => item.prd_codprd == row.PRD_CODPRD)
        this.prod = producto ? producto.DESC_ESPA : '';
        this.fisico = response[0].fisico;
        this.disponible = response[0].disponible;
        this.reservado = response[0].reservado;

        const rippleRef = this.ripple.launch({
          persistent: true,
          centered: true
        });

        rippleRef.fadeOut();
      },
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  insertarProducto(): void {

    if (!this.order.TLP_CODTLP) {
      this._dialogService.openDialog(ConfirmationComponent, '¿Continuar sin seleccionar una lista de precio?', '', '', '').subscribe(result => {
        const producto = new DetallePcd();

        producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
        producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
        producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
        producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;

        let i = 0;

        if (this.selected) {
          this.order.detalle_pcd.forEach((item, index) => {
            if (item.PCD_CORPCD == this.selected.PCD_CORPCD) i = index;
          })

          this.order.detalle_pcd.splice(i, 0, producto);
        } else {
          this.order.detalle_pcd.push(producto);
          this.order.detalle_pcd = this.order.detalle_pcd.reverse();
        }

        this.order.detalle_pcd.forEach((element, key) => {
          console.log('ids', key)
          element.PCD_CORPCD = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
        })

        console.log('detalle', this.order.detalle_pcd)

        this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);

        this.calcularTotales();
      });

      return;
    }
    const producto = new DetallePcd();

    producto.PCD_IMPBRU = producto.PCD_CANSOL * producto.PCD_PREUNI;
    producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
    producto.PCD_IMPTOT = producto.PCD_CANSOL * producto.PCD_PRUIGV - producto.PCD_IMPDES;
    producto.PCD_IMPIGV = producto.PCD_IMPTOT - producto.PCD_VALVTA;

    let i = 0;

    if (this.selected) {
      this.order.detalle_pcd.forEach((item, index) => {
        if (item.PCD_CORPCD == this.selected.PCD_CORPCD) i = index;
      })

      this.order.detalle_pcd.splice(i, 0, producto);
    } else {
      this.order.detalle_pcd.push(producto);
      this.order.detalle_pcd = this.order.detalle_pcd.reverse();
    }

    this.order.detalle_pcd.forEach((element, key) => {
      console.log('ids', key)
      element.PCD_CORPCD = (key > 9) ? `000${key + 1}` : `0000${key + 1}`;
    })

    console.log('detalle', this.order.detalle_pcd)

    this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);
    this.calcularTotales();
  }

  nuevoRegistro(): void {
    this.order = new Order();
    this.uid = '0';
    this.initialize();
    this.direccion = null;

    this.tipoCambio$ = this._ventasService.obtenerTipoCambio(formatDateWithDash(this.order.PCC_FECDOC)).subscribe(
      tipoCambio => {
        this.order.PCC_TIPCAM = tipoCambio[0].tipcam;
      },
      error => {
        this._snackBarService.showError('Error al obtener tipo de cambio', 'OK');
      }
    )
  }

  agregarDescuento(porDesc?: number): void {
    const seleccionado = this.order.detalle_pcd.find(item => item == this.selected);

    if (!seleccionado) {
      this._snackBarService.showError('Seleccionar una fila del detalle', 'Ok');
      return;
    }

    if (!seleccionado.PRD_CODPRD) {
      this._snackBarService.showError('Seleccionar un producto', 'Ok');
      return;
    }

    if (seleccionado.PCD_PREUNI == 0) {
      this._snackBarService.showError('Establecer un precio del producto', 'Ok');
      return;
    }

    const descuento = new DescuentosPD();

    seleccionado.descuentos_pds = seleccionado.descuentos_pds ? seleccionado.descuentos_pds : [];
    seleccionado.descuentos_pds.push(descuento);

    if (porDesc) {

      descuento.pds_pordes = porDesc;

      let producto = this.order.detalle_pcd.find(item => item == this.selected);
      descuento.pds_impdes = producto.PCD_IMPBRU * (descuento.pds_pordes / 100);
      descuento.pds_coduse = this._configurationService.obtenerIdUsuario();
      descuento.pds_incdes = (descuento.pds_impdes * (1 + (this.order.PCC_TASIGV / 100)));

      descuento.pds_porori = 0;
      descuento.pds_impcom = 0;

      let totalDes = 1;
      producto.descuentos_pds.forEach(descuento => {

        totalDes *= (1 - descuento.pds_pordes / 100);

      });

      producto.PCD_PORDES = (1 - totalDes) * 100;
      producto.PCD_IMPDES = producto.PCD_IMPBRU * (producto.PCD_PORDES / 100);
      producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
      producto.PCD_IMPIGV = this.order.PCC_INDCAL == '0' ? 0 : producto.PCD_VALVTA * this.order.PCC_TASIGV / 100;
      producto.PCD_IMPTOT = producto.PCD_IMPIGV + producto.PCD_VALVTA;

      this.calcularTotales()
    }

    this.dataSourceDescuento = fillTable(seleccionado.descuentos_pds, this.paginatorDescuento, this.sortDescuento);

    seleccionado.descuentos_pds.forEach((element, index) => {
      element.dco_coddco = (index + 1).toString();
      if (element.dco_coddco.length == 1) {
        element.dco_coddco = "000" + element.dco_coddco;
      }
      if (element.dco_coddco.length == 2) {
        element.dco_coddco = "00" + element.dco_coddco;
      }
      if (element.dco_coddco.length == 3) {
        element.dco_coddco = "0" + element.dco_coddco;
      }
    })
  }

  eliminarDescuento(i): void {

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de eliminar el descuento?', '', '', '').subscribe(result => {
      console.log('result', result)
      if (result) {
        const producto: DetallePcd = this.order.detalle_pcd.find(item => item.seleccionado);


        console.log('descuentos ', producto.descuentos_pds)
        producto.descuentos_pds = producto.descuentos_pds.filter((el, index) => i != index);

        this.dataSourceDescuento = fillTable(producto.descuentos_pds, this.paginator, this.sort);

        let totalDes = 1;

        producto.descuentos_pds.forEach(descuento => {

          totalDes *= (1 - descuento.pds_pordes / 100);
          console.log('totalDes', totalDes);

        });

        producto.PCD_PORDES = (1 - totalDes) * 100;
        producto.PCD_IMPDES = producto.PCD_IMPBRU * (producto.PCD_PORDES / 100);
        producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
        producto.PCD_IMPIGV = this.order.PCC_INDCAL == '0' ? 0 : producto.PCD_VALVTA * this.order.PCC_TASIGV / 100;
        producto.PCD_IMPTOT = producto.PCD_IMPIGV + producto.PCD_VALVTA;

        this.calcularTotales()

        producto.descuentos_pds.forEach((element, index) => {
          element.dco_coddco = (index + 1).toString();
          if (element.dco_coddco.length == 1) {
            element.dco_coddco = "000" + element.dco_coddco;
          }
          if (element.dco_coddco.length == 2) {
            element.dco_coddco = "00" + element.dco_coddco;
          }
          if (element.dco_coddco.length == 3) {
            element.dco_coddco = "0" + element.dco_coddco;
          }
        })

      }

    })

  }

  aplicarDescuentoGeneral(descuento): void {
    if (descuento == 0) {
      this._snackBarService.showError('Establecer un descuento mayor a 0', 'Ok');
      return;
    }

    if (this.order.detalle_pcd.length == 0) {
      this._snackBarService.showError('Agregar al menos un ítem al detalle', 'Ok');
      return;
    }

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de agregar un (%)descuento general?', '', '', '').subscribe(result => {
      if (result) {
        this.order.detalle_pcd.forEach(item => {
          console.log('seleccionado', item)

          if (!item.PRD_CODPRD) {
            this._snackBarService.showError('Seleccionar un producto', 'Ok');
            return;
          }

          if (item.PCD_PREUNI == 0) {
            this._snackBarService.showError('Establecer un precio del producto', 'Ok');
            return;
          }

          item.descuentos_pds = [];

          const desc = new DescuentosPD();
          desc.pds_pordes = descuento;

          item.descuentos_pds = item.descuentos_pds ? item.descuentos_pds : [];
          this.cambiarFilaDescuento(desc);
          item.descuentos_pds.push(desc);

          this.calcularMontosFila(item);

          this.dataSourceDescuento = fillTable(item.descuentos_pds, this.paginatorDescuento, this.sortDescuento);

          item.descuentos_pds.forEach((element, index) => {
            element.dco_coddco = (index + 1).toString();
            if (element.dco_coddco.length == 1) {
              element.dco_coddco = "000" + element.dco_coddco;
            }
            if (element.dco_coddco.length == 2) {
              element.dco_coddco = "00" + element.dco_coddco;
            }
            if (element.dco_coddco.length == 3) {
              element.dco_coddco = "0" + element.dco_coddco;
            }
          })

        });

      }
    })



  }

  cambiarFilaDescuento(row: DescuentosPD): void {

    let producto = this.order.detalle_pcd.find(item => item == this.selected);

    row.pds_impdes = producto.PCD_IMPBRU * (row.pds_pordes / 100);
    row.pds_coduse = this._configurationService.obtenerIdUsuario();
    row.pds_incdes = (row.pds_impdes * (1 + (this.order.PCC_TASIGV / 100)));

    row.pds_porori = 0;
    row.pds_impcom = 0;

    let totalDes = 1;
    producto.descuentos_pds.forEach(descuento => {

      totalDes *= (1 - descuento.pds_pordes / 100);

    });

    producto.PCD_PORDES = (1 - totalDes) * 100;
    producto.PCD_IMPDES = producto.PCD_IMPBRU * (producto.PCD_PORDES / 100);
    producto.PCD_VALVTA = producto.PCD_IMPBRU - producto.PCD_IMPDES;
    producto.PCD_IMPIGV = this.order.PCC_INDCAL == '0' ? 0 : producto.PCD_VALVTA * this.order.PCC_TASIGV / 100;
    producto.PCD_IMPTOT = producto.PCD_IMPIGV + producto.PCD_VALVTA;

    this.calcularTotales()

  }

  seleccionarCliente(valor): void {

    // const cliente: Cliente = this.clientes.find(item => item.cli_codcli.trim() == valor.trim());
    this.order.CLI_CODCLI = valor;
    this.direccion = '';
    console.log('cliente codigo', valor)
    console.log('cliente ENCONTRADO', this.cliente)
    this.order.LDE_CODFAC = null;
    if (this.cliente) {
      if (!this.order.VDE_CODVDE) {
        this.order.VDE_CODVDE = this.cliente.dato_clc.VDE_CODVDE;
      }

      if (this.uid == '0') {
        if (this.vendedorId != '' && this.vendedorId != undefined && this.vendedorId != null) {
          this.order.VDE_CODVDE = this.vendedorId;
        }
      }

      if (!this.order.CPA_CODCPA) {
        this.order.CPA_CODCPA = this.cliente.dato_clc.CPA_CODCPA;
      }

      if (!this.order.ZVE_CODZVE) {
        this.order.ZVE_CODZVE = this.cliente.dato_clc.ZVE_CODZVE;
      }
      // this.order.CPA_CODCPA = cliente.dato_clc.CPA_CODCPA;
      // this.order.ZVE_CODZVE = cliente.dato_clc.ZVE_CODZVE;
      this.direccion = this.cliente.cli_dircli;

      if (this.uid == '0') {
        if (this.cliente.tpl_codtlp) this.order.TLP_CODTLP = this.cliente.tpl_codtlp;

        if (this.cliente.dato_clc.CPA_CODCPA) {
          this.order.CPA_CODCPA = this.cliente.dato_clc.CPA_CODCPA;
        }

        if (this.cliente.dato_clc.TLP_CODTLP) {
          this.order.TLP_CODTLP = this.cliente.dato_clc.TLP_CODTLP;
        }
      }

      if (this.cliente.cli_numruc) {
        this.order.TDO_CODTDO = "FAC";
      }

      if (this.cliente.cli_numdni) {
        this.order.TDO_CODTDO = "BVE";
      }

      this._ventasService.obtenerDiasEntrega(this.order.CLI_CODCLI, this.order.LDE_CODLDE).subscribe(
        diasEntrega => {
          this.diasEntrega = diasEntrega;

          this._contabilidadService.listarCentroCostos().subscribe(
            centrosCosto => {
              this.centros = centrosCosto;

              this._ventasService.obtenerSucursalAgencias(this.order.CLI_AGETRA).subscribe(
                sucursales => {
                  this.sucursales = sucursales;
                  this._ventasService.obtenerLugaresDespacho(this.order.CLI_CODCLI).subscribe(
                    lugaresDespacho => {
                      this.lugaresDespacho = lugaresDespacho;

                      this.seleccionDeLista(this.order.TLP_CODTLP);

                    },
                    error => {
                      this._snackBarService.showError("Error al obtener lugares de despacho", 'OK')

                    }
                  )
                },
                error => {
                  this._snackBarService.showError("Error al obtener sucursales", 'OK')

                }
              )

            },
            error => {
              this._snackBarService.showError("Error al obtener centros de costo", 'OK')

            }
          )

        },
        error => {
          this._snackBarService.showError("Error al obtener dias de entrega", 'OK')
        }
      )

    }

  }

  seleccionarMotivo(): void {
    const motivo = this.motivos.find(item => item.mmo_codmmo == this.order.MMO_CODMMO);

    if (motivo.mmo_indexp == 1) {
      this.order.PCC_TASIGV = 0;

      this.order.detalle_pcd.forEach(item => {
        this.calcularMontosFila(item);
      })
    } else {
      this.order.PCC_TASIGV = 18;
      this.order.detalle_pcd.forEach(item => {
        this.calcularMontosFila(item);
      })
    }
  }

  seleccionarProducto(valor, row: DetallePcd): void {
    console.log('valor', valor)
    row.UME_CODVEN = valor.UNIDAD;
    // const producto = this.productos.find(item => item.prd_codprd == valor);
    // console.log('producto', producto)
    // row.UME_CODVEN = producto.UNIDAD;

    if (this.order.TLP_CODTLP) {
      let now = new Date();

      let fecha = formatDateWithDash(now);

      if (!this.order.TLP_CODTLP) {
        this._snackBarService.showError('Seleccionar lista para calcular el precio', 'OK');
        return;
      }

      if (!this.order.LPC_CODLPC) {
        this._snackBarService.showError('Establecer Nro de lista para calcular el precio', 'OK');
        return;
      }


      if (!row.PRD_CODPRD) {
        this._snackBarService.showError('Seleccionar producto para calcular el precio', 'OK');
        return;
      }

      this.listaPrecio$ = this._ventasService.listaDePreciosProducto(row.PRD_CODPRD, this.order.TMO_CODTMO, null, this.order.TLP_CODTLP, this.order.LPC_CODLPC, fecha).subscribe(
        response => {
          console.log('PRECIO', response)
          if (this.order.TMO_CODTMO == "SO") {
            row.PCD_PREUNI = response[0].pre_prenac;
            row.PCD_PRUIGV = response[0].pre_vtanac;
          } else {
            row.PCD_PREUNI = response[0].pre_predol;
            row.PCD_PRUIGV = response[0].pre_vtadol;
          }

          if (this.configuracionTecnicaVentas.s71_indlpc == 1) {
            if (row.PCD_PREUNI == 0) {
              this._snackBarService.showError('El producto debe de encontrarse agregado en una lista de precio', 'OK');
              row.PRD_CODPRD = null;
              return;
            }
          }

          row.PCD_IMPBRU = row.PCD_CANSOL * row.PCD_PREUNI;

          row.PCD_IMPDES = row.PCD_IMPBRU * (row.PCD_PORDES / 100)

          row.PCD_VALVTA = row.PCD_IMPBRU - row.PCD_IMPDES;

          row.PCD_IMPIGV = this.order.PCC_INDCAL == '0' ? 0 : row.PCD_VALVTA * this.order.PCC_TASIGV / 100;

          row.PCD_IMPTOT = row.PCD_IMPIGV + row.PCD_VALVTA;

          this.calcularTotales();

        },
        error => this._snackBarService.showError(error.error.msg, 'OK')
      )
    };
    // this.calcularTotales;
  }

  checkProducto(value: boolean, productoSeleccionado: DetallePcd): void {
    if (value) {
      this.order.detalle_pcd.forEach(element => element.seleccionado = false);

      productoSeleccionado.seleccionado = value;

      this.dataSourceDescuento = fillTable(productoSeleccionado.descuentos_pds ? productoSeleccionado.descuentos_pds : [], this.paginatorDescuento, this.sortDescuento);
      // this.dataSourceLote = fillTable(productoSeleccionado.detalle_lote, this.paginatorLote, this.sortLote);
      // this.dataSourceSerie = fillTable(productoSeleccionado.detalle_serie, this.paginatorSerie, this.sortSerie);

    }
  }

  seleccionarPromocion(codigo, fila): void {

  }

  seleccionarLugarDespacho(lugar_id): void {
    this.lugaresDespacho.forEach(element => {
      if (element.LDE_CODLDE == lugar_id) this.order.ZVE_CODZVE = element.zve_codzve;
    });
  }



  extraerPromociones(): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de verificar si tiene alguna promoción?', '', '', '').subscribe(result => {

    })
  }

  verHistorialPrecios(): void {
    const dialogRef = this.dialog.open(HistorialPreciosDialog, {
      width: '560px',
      data: this.order,
      // panelClass: 'pav-dialog-container-option-1'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  suspenderPedido(): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de suspender pedido?', '', '', '').subscribe(result => {

    })
  }

  seleccionarTipoDescuento(value, row: DescuentosPD): void {
    row.dco_coddco = (value == "0001") ? "DESCUENTO ESPECIAL" : "DESCUENTO POR REGALO";
  }

  clickMasOpciones(): void {
    this._bottomSheet.open(PedidoBottomSheet).afterDismissed().subscribe(data => {

      if (data) {
        this.loaderData = true;
        this._pedidoService.obtenerPedido('01', data).subscribe(
          pedido => {

            this.order = pedido;
            this.order.pcc_impfac = pedido.pcc_impfac == 1 ? true : false;
            this.order.pcc_impgui = pedido.pcc_impgui == 1 ? true : false;
            this.order.pcc_rutext = pedido.pcc_rutext == 1 ? true : false;

            this.seleccionarCliente(this.order.CLI_CODCLI);

            this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);

            this.loaderData = false;
          },
          error => {

            this.loaderData = false;
            this._snackBarService.showError("Error al obtener pedido", 'OK');

          }
        )
      }

    });
  }


  copiarDesdePedido(): void {

    const dialogRef = this.dialog.open(CopyFromOrderComponent, {
      width: '300px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(pedidoSeleccionado => {
      console.log('The dialog was closed');
      if (pedidoSeleccionado) {
        this.loaderData = true;

        this.pedido$ = this._pedidoService.obtenerPedido('01', pedidoSeleccionado).subscribe(
          pedido => {

            this.order = pedido;
            this.order.pcc_impfac = pedido.pcc_impfac == 1 ? true : false;
            this.order.pcc_impgui = pedido.pcc_impgui == 1 ? true : false;
            this.order.pcc_rutext = pedido.pcc_rutext == 1 ? true : false;

            console.log('-====== PEDIDO ======', pedido)

            this.seleccionarCliente(this.order.CLI_CODCLI);

            this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);

            this.seleccionarMotivo();

            this.order.detalle_pcd.forEach((element, key) => {
              element.PCD_CORPCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
            })

            this.loaderData = false;
          },
          error => {
            this._snackBarService.showError("Error al obtener pedido", 'OK');
            this.loaderData = false;
          }
        )
      }

    });

  }

  generarFormato(): void {
    this.loaderFormato = true;
    if (this.uid == '0') {
      this.loaderFormato = false;
      return;
    }

    this.imprimirFormato$ = this._ventasService.mostrarPdfPedido(this.uid).subscribe(
      response => {

        if (response.length > 0) {

          const headers = {
            CIA_NOMCIA: this._configurationService.obtenerNombreCliente(),
            CIA_NUMRUC: response[0].CIA_NUMRUC,
            CIA_DIRCIA: response[0].CIA_DIRCIA,
            logo: this.logo,
            username: this._configurationService.obtenerIdUsuario()
          }

          pdfBuilder.crearFormatoPedidoPdf(headers, response[0])
          this.loaderFormato = false;
          return;

        }

        this._snackBarService.showError('No se encontro el pdf de pedido', 'OK');
        this.loaderFormato = false;

      },
      error => {
        console.log('error', error)
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderFormato = false;
      }
    )
  }

  imprimirFormato(): void {
    console.log('imprimir formato')
    this.loaderImprimir = true;
    if (this.uid == '0') {
      this.loaderImprimir = false;
      return;
    }
    this.preferenciasImpresion$ = this._printerService.obtenerConfiguracionImpresora(this._configurationService.obtenerIdUsuario(), this._configurationService.obtenerPrograma()).subscribe(
      preferencia => {
        this.preferenciasImpresion = preferencia;

        this.imprimirFormato$ = this._ventasService.mostrarPdfPedido(this.uid).subscribe(
          response => {

            if (response.length > 0) {

              const headers = {
                CIA_NOMCIA: this._configurationService.obtenerNombreCliente(),
                CIA_NUMRUC: response[0].CIA_NUMRUC,
                CIA_DIRCIA: response[0].CIA_DIRCIA,
              }

              this.loaderImprimir = false;

              if (this.preferenciasImpresion.s73_indvpr == 1) {
                if (preferencia.s73_tampap == 'A4') {
                  pdfBuilder.crearFormatoPedidoPdf(headers, response[0])

                }

                if (preferencia.s73_tampap == 'A7') {
                  pdfBuilder.crearFormatoPedidoTicket(headers, response[0])
                }

              }

              if (this.preferenciasImpresion.s73_indvpr == 0) {
                if (preferencia.s73_tampap == 'A4') {
                  pdfBuilder.imprimirFormatoPedidoPdf(headers, response[0]);
                }

                if (preferencia.s73_tampap == 'A7') {
                  pdfBuilder.imprimirFormatoPedidoTicket(headers, response[0]);
                }
              }


              return;

            }

            this._snackBarService.showError('No se encontro el pdf de pedido', 'OK');
            this.loaderImprimir = false;

          },
          error => {
            console.log('error', error)
            this._snackBarService.showError(error.error.msg, 'OK');
            this.loaderImprimir = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener preferencia', 'Ok')
      }
    )
  }

  calculaMontosFilaIncIGV(row: DetallePcd): void {
    row.PCD_IMPBRU = row.PCD_CANSOL * row.PCD_PREUNI;

    if (this.order.PCC_TASIGV != 0) {
      row.PCD_PREUNI = parseFloat((row.PCD_PRUIGV / (this.order.PCC_TASIGV / 100 + 1)).toFixed(2));
    } else {
      row.PCD_PREUNI = 0;
    }

    console.log(row.PCD_PRUIGV)
    row.PCD_IMPIGV = row.PCD_PRUIGV > 0 ? parseFloat(((Math.abs(row.PCD_PRUIGV - row.PCD_PREUNI)) * row.PCD_CANSOL).toFixed(2)) : 0;

    row.PCD_IMPDES = row.PCD_IMPBRU * (row.PCD_PORDES / 100)

    row.PCD_VALVTA = row.PCD_IMPBRU - row.PCD_IMPDES;


    row.PCD_IMPTOT = row.PCD_IMPIGV + row.PCD_VALVTA;

    this.calcularTotales();
  }

  calcularMontosFila(row: DetallePcd): void {

    row.PCD_IMPBRU = row.PCD_CANSOL * row.PCD_PREUNI;

    if (this.order.PCC_TASIGV != 0) {
      row.PCD_PRUIGV = (this.order.PCC_TASIGV / 100 + 1) * row.PCD_PREUNI;
    } else {
      row.PCD_PRUIGV = 0;
    }

    console.log(row.PCD_PRUIGV)
    row.PCD_IMPIGV = row.PCD_PRUIGV > 0 ? parseFloat(((Math.abs(row.PCD_PRUIGV - row.PCD_PREUNI)) * row.PCD_CANSOL).toFixed(2)) : 0;

    row.PCD_IMPDES = row.PCD_IMPBRU * (row.PCD_PORDES / 100)

    row.PCD_VALVTA = row.PCD_IMPBRU - row.PCD_IMPDES;


    row.PCD_IMPTOT = row.PCD_IMPIGV + row.PCD_VALVTA;

    this.calcularTotales();

  }

  calcularTotales(): void {
    let valorVenta = 0;
    let impBruto = 0;
    let impIGV = 0;
    let descuento = 0;
    let impTotal = 0;

    this.order.detalle_pcd.forEach(element => {
      valorVenta += Number(element.PCD_VALVTA);
      impBruto += Number(element.PCD_IMPBRU);
      impIGV += Number(element.PCD_IMPIGV);
      descuento += Number(element.PCD_IMPDES);
      impTotal += Number(element.PCD_IMPTOT);
    })

    this.order.PCC_VALVTA = valorVenta;
    this.order.PCC_VALVTA = this.order.PCC_VALVTA.toFixed(2);

    this.order.PCC_IMPBRU = impBruto;
    this.order.PCC_IMPBRU = this.order.PCC_IMPBRU.toFixed(2);

    this.order.PCC_IMPIGV = impIGV;
    this.order.PCC_IMPIGV = this.order.PCC_IMPIGV.toFixed(2);

    this.order.PCC_IMPDES = descuento;
    this.order.PCC_IMPDES = this.order.PCC_IMPDES.toFixed(2);

    this.order.PCC_IMPTOT = impTotal;
    this.order.PCC_IMPTOT = this.order.PCC_IMPTOT.toFixed(2);
  }

  seleccionarCalculaItem(calculaItem): void {
    if (calculaItem == '0') {
      this.order.detalle_pcd.forEach(item => {
        item.PCD_PRUIGV = 0;
        item.PCD_IMPIGV = 0;
        this.calcularMontosFila(item);
      })

      this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);
      this.calcularTotales();

      return;
    }

    this.order.detalle_pcd.forEach(item => {
      item.PCD_PRUIGV = (this.order.PCC_TASIGV / 100 + 1) * item.PCD_PREUNI;
      item.PCD_IMPIGV = item.PCD_IMPBRU * item.PCD_PRUIGV;
      this.calcularMontosFila(item);
    })

    this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);

    this.calcularTotales();

  }

  reset(): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Desea restablecer el formulario?', '', '', '').subscribe(
      response => {
        if (response) {

          this.direccion = '';

          this.order = new Order();

          this.minDate = new Date(parseInt(this.year), parseInt(this.month) - 1, 1);
          this.maxDate = new Date(parseInt(this.year), parseInt(this.month), 0);

          this.order.PCC_FECDOC = this.minDate;
          this.order.PCC_FECENT = this.order.PCC_FECDOC;

          this.dataSource = fillTable([], this.paginator, this.sort);
          this.dataSourceDescuento = fillTable([], this.paginatorDescuento, this.sortDescuento);

          this.documentos = fillTable([], this.paginatorDocumento, this.sortDocumento);

          this.dias = fillTable([], this.paginatorDiasEntrega, this.sortDiasEntrega);


          this.order.ANO_CODANO = this.year;
          this.order.MES_CODMES = this.month;
          this.order.CIA_CODCIA = this._configurationService.obtenerCompaniaCliente();

          this.order.PCC_CODUSE = this._authenticationService.getUsuarioSistema();

        }
      }
    )
  }

  focusCampo(evento): void {
    evento.target.select();
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource)
  }

  volver(): void {
    this._router.navigate(['/modulo-ventas/pedidos']);
  }

  volver2(): void {
    this._snackBarService.showError('prueba', 'Ok', () => {
      console.log('prueba')
    })
  }

  seleccionarTabla(): void {

    this.tablaSeleccionada = !this.tablaSeleccionada;

  }

  onPaste(event: ClipboardEvent): void {
    console.log('pegar')

    if (!this.tablaSeleccionada) return;
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    this.processPastedData(pastedText);
  }

  processPastedData(pastedText: string): void {
    const rows = pastedText.split('\n').filter(row => row.trim().length > 0);

    const newData: any[] = rows.map(row => {
      const cols = row.split('\t');
      return {
        codigo: cols[0],
        descripcion: cols[1],
        um: cols[2],
        cantidad: +cols[3],
        precio: +cols[4],
        glosa: cols[5],
      };
    });

    if (newData.length == 0) {
      this._snackBarService.showError('No se encontraron datos copiados', 'OK');
      return;
    };

    if (newData[0].codigo == "Codigo *") {
      newData.shift();
    }

    if (newData.length == 0) {
      this._snackBarService.showError('No se encontraron datos copiados', 'OK');
      return;
    }

    console.log('Object.keys(newData[0]).length', Object.keys(newData[0]).length)
    if ((Object.keys(newData[0]).length) != 6) {
      this._snackBarService.showError('Debe de copiar todo el contenido de las 9 filas', 'Ok');
      return
    };

    console.log('new data', newData)

    const agrupado = agrupar(newData, 'codigo');
    const keys = Object.keys(agrupado);

    let primeraAgrupacion = [];

    this.order.detalle_pcd = [];

    newData.forEach(item => {
      const detalleItem = new DetallePcd();
      detalleItem.PRD_CODPRD = item.codigo;
      detalleItem.UME_CODVEN = item.um;
      detalleItem.PCD_CANSOL = item.cantidad;
      detalleItem.PCD_PREUNI = item.precio;
      detalleItem.PCD_GLOPCD = item.glosa;

      detalleItem.PCD_IMPBRU = detalleItem.PCD_CANSOL * detalleItem.PCD_PREUNI;

      if (this.order.PCC_INDCAL == '1') detalleItem.PCD_PRUIGV = (this.order.PCC_TASIGV / 100 + 1) * detalleItem.PCD_PREUNI;

      detalleItem.PCD_IMPDES = detalleItem.PCD_IMPBRU * (detalleItem.PCD_PORDES / 100)

      detalleItem.PCD_VALVTA = detalleItem.PCD_IMPBRU - detalleItem.PCD_IMPDES;

      detalleItem.PCD_IMPIGV = this.order.PCC_INDCAL == '0' ? 0 : detalleItem.PCD_VALVTA * this.order.PCC_TASIGV / 100;

      detalleItem.PCD_IMPTOT = detalleItem.PCD_IMPIGV + detalleItem.PCD_VALVTA;

      this.calcularTotales();

      this.order.detalle_pcd.push(detalleItem);
    })

    this.order.detalle_pcd.forEach((element, key) => {
      element.PCD_CORPCD = (key > 9) ? `0${key + 1}` : `00${key + 1}`;
    })

    this.dataSource = fillTable(this.order.detalle_pcd, this.paginator, this.sort);

    /*
    this.dataSourceLote = fillTable([], this.paginatorLote, this.sortLote);

    this.dataSourceSerie = fillTable([], this.paginatorSerie, this.sortSerie);

    keys.forEach(key => {
      const encontrado = agrupado[key].find(item => item.prd_codprd == key);

      const producto = new PartDetalleMap();
      producto.prd_codprd = key;

      const productoEncontrado = this.productos.find(item => item.prd_codprd == key);

      console.log('producto encontrado', productoEncontrado);


      if (!existeLote && !existeSerie) {
        producto.map_canprd = encontrado.cantidad;
        this._snackBarService.showError(`Producto: ${producto.prd_codprd} no posee lote, ni serie`, 'Ok');
      }

      producto.ume_codven = encontrado.ume_codven;
      producto.map_cosmnp = encontrado.map_cosmnp;
      producto.map_cosmep = encontrado.map_cosmep;
      producto.prd_indlot = productoEncontrado.prd_indlot;
      producto.prd_indser = productoEncontrado.prd_indser;
      producto.prd_indtal = productoEncontrado.prd_indtal;

      producto.tallas_mad[0].lotes_mlo


      if (existeLote && this.configuracionTecnica.s70_indlot == 1 && producto.prd_indlot == 1) {
        let cantidadTotal = 0;
        agrupado[key].forEach(item => {
          const lote = new DetalleLote();
          lote.mlo_numlot = item.mlo_numlot;
          lote.mlo_canprd = item.cantidad;
          lote.mlo_glomlo = item.referencia;
          producto.tallas_mad[0].lotes_mlo.push(lote);
          cantidadTotal += +item.cantidad;
        });
        producto.map_canprd = cantidadTotal;
      }
      this.kardex.detalle_map.push(producto);

      if (existeSerie && this.configuracionTecnica.s70_indser == 1 && producto.prd_indser == 1) {
        agrupado[key].forEach(item => {
          const serie = new DetalleSerie();
          serie.spr_numser = item.spr_numser;
          producto.tallas_mad[0].series_mas.push(serie);

        });
      }
    });

    this.selectRow(this.kardex.detalle_map[0]);

    this.kardex.detalle_map.forEach((element, key) => {
      element.map_cormap = (key > 9) ? `00${key + 1}` : `000${key + 1}`;
    })

    this.dataSource = fillTable(this.kardex.detalle_map, this.paginator, this.sort);
    this.dataSourceLote = fillTable(this.kardex.detalle_map[0].tallas_mad[0].lotes_mlo, this.paginatorLote, this.sortLote);
    this.dataSourceSerie = fillTable(this.kardex.detalle_map[0].tallas_mad[0].series_mas, this.paginatorSerie, this.sortLote);

    this.tablaSeleccionada = false;
    console.log('agrupado', primeraAgrupacion)

    */
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.tipoCambio$,
      this.consultaStock$,
      this.centroCosto$,
      this.diasEntrega$,
      this.lugarDespacho$,
      this.sucursal$,
      this.formaCalcula$,
      this.listaPrecio$,
      this.clientes$,
      this.sendForm$,
      this.pedido$,
      this.detalleCliente$,
      this.period$,
      this.loading$,
      this.cotizacion$,
      this.motivos$,
      this.companias$,
      this.vendedores$,
      this.condiciones$,
      this.zonas$,
      this.tiposPrecio$,
      this.transportistas$,
      this.almacenes$,
      this.productos$,
      this.unidadesMedida$,
      this.promociones$,
      this.descuentos$,
      this.preferenciasImpresion$,
      this.configuracionTecnica$,
      this.config$,
      this.imprimirFormato$,
      this.establecimiento$,
      this.tiposDocumento$
    ])
  }
}
