import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from '@shared/services/snackbar.service';
import { fillTable, searchInTable } from '@utils/tables/table';

@Component({
  selector: 'migracion-registro-ventas',
  templateUrl: './migracion-registro-ventas.component.html',
  styleUrls: ['./migracion-registro-ventas.component.css']
})

export class MigracionRegistroVentasComponent implements OnInit {

  loaderData: boolean = false;
  tablaSeleccionada: boolean = false;

  displayedColumns: string[] = [
    'acciones',
    'ano_codano',
    'mes_codmes',
    'sco_codsco',
    'ldc_corldc',
    'cco_fecreg',
    'tga_codtga',
    'cli_codcli',
    'tdo_codtdo',
    'cco_numdoc',
    'sad_codsad',
    'dpc_anodua',
    'tmo_codtmo',
    'cco_tasigv',
    'cco_tipcam',
    'cco_impina',
    'cco_impafe',
    'valvta',
    'cco_impigv',
    'cco_impdoc',
    'cco_glocco',
    'iri_codiri',
    'tim_codtim',
    'dpc_tasdet',
    'spr_numser',
    'dpc_impper',
    'dpc_fecdet',
    'tdo_docref',
    'cco_numref',
    'cco_fecref',
  ];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isTablet: boolean = false;
  isMobile: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _snackBarService: SnackBarService

  ) {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.breakpointObserver.observe([Breakpoints.Tablet])
      .subscribe(result => {
        this.isTablet = result.matches;
      });

    this.dataSource = fillTable([], this.paginator, this.sort)
  }

  ngOnInit() { }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  seleccionarTabla(): void {

    this.tablaSeleccionada = !this.tablaSeleccionada;

  }

  onPaste(event: ClipboardEvent): void {
    console.log('on paste')
    if (!this.tablaSeleccionada) return;
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    this.processPastedData(pastedText);

  }

  processPastedData(pastedText: string): void {

    const rows = pastedText.split('\n').filter(row => row.trim().length > 0);
    const newData: any[] = rows.map(row => {
      const cols = row.split('\t');
      return {
        ano_codano: cols[0],
        mes_codmes: cols[1],
        sco_codsco: cols[2],
        ldc_corldc: cols[3],
        cco_fecreg: cols[4],
        tga_codtga: cols[5],
        cli_codcli: cols[6],
        tdo_codtdo: cols[7],
        cco_numdoc: cols[8],
        sad_codsad: cols[9],
        dpc_anodua: cols[10],
        tmo_codtmo: cols[11],
        cco_tasigv: cols[12],
        cco_tipcam: cols[13],
        cco_impina: cols[14],
        cco_impafe: cols[15],
        valvta: cols[16],
        cco_impigv: cols[17],
        cco_impdoc: cols[18],
        cco_glocco: cols[19],
        iri_codiri: cols[20],
        tim_codtim: cols[21],
        dpc_tasdet: cols[22],
        spr_numser: cols[23],
        dpc_impper: cols[24],
        dpc_fecdet: cols[25],
        tdo_docref: cols[26],
        cco_numref: cols[27],
        cco_fecref: cols[28],
      };
    });

    if (newData.length == 0) {
      this._snackBarService.showError('No se encontraron datos copiados', 'OK');
      return;
    };

    if ((Object.keys(newData[0]).length) != 29) {
      this._snackBarService.showError('Debe de copiar todo el contenido de las 29 filas', 'Ok');
      return
    };

    console.log('newData', newData)
    this.dataSource = fillTable(newData, this.paginator, this.sort);

    this.tablaSeleccionada = false;
  }

  obtenerTipoCambio(): void {

    // row.cco_tipcam =
  }

  limpiar(): void {

  }
}
