import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExportExcelService, VentasService, CajaBancoService, ConfigurationService, GeneralService } from 'src/app/services';
import { formatDateWithSlash, getHourMinuteSecondsCurrent } from 'src/app/utils/formats/date.format';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subject, Subscription } from 'rxjs';
import { unsubscribeSubscription } from 'src/app/utils/others/subscription';
import { REPORTES_CAJA_Y_BANCOS } from '@data/json/reportes';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { HeaderReport } from '@data/interfaces/headers.report';
import { TesoreriaReportService } from 'src/app/services/reports/modules/tesoreria-report.service';
import { fillTable } from '@utils/tables/table';
import { FiltroReporteCuentaPagar } from 'src/app/models/caja-banco/filtro-reporte-cuenta-pagar';

@Component({
  selector: 'app-caja-banco-report',
  templateUrl: './caja-banco-report.component.html',
  styleUrls: ['./caja-banco-report.component.css']
})
export class CajaBancoReportComponent implements OnInit, OnDestroy {

  loaderFields: boolean = false;

  task: any = {
    name: 'Campos de pedido',
    completed: false,
    color: 'primary',
    subtasks: []
  };

  allComplete: boolean = false;

  updateAllComplete(): void {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) return false;

    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) return;

    this.task.subtasks.forEach(t => t.completed = completed);
  }

  displayedColumns: string[] = ['cli_codcli', 'tdo_codtdo', 'cco_numdoc', 'CCO_FECEMI', 'lpd_descri', 'prd_codprd', 'prd_desesp'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataForExcel = [];

  dataDetalleFacturacion = [];
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  displayedColumnsProveedor: string[] = ['select', 'CODIGO', 'NOMBRE'];
  dataSourceProveedor: MatTableDataSource<any>;

  @ViewChild("MatPaginatorProveedor") paginatorProveedor: MatPaginator;
  @ViewChild(MatSort) sortProveedor: MatSort;

  loaderProveedor: boolean = true;
  selectionProveedor = new SelectionModel<any>(true, []);

  isAllSelectedProveedor(): boolean {
    const numSelected = this.selectionProveedor.selected.length;
    const numRows = this.dataSourceProveedor.data.length;
    return numSelected === numRows;
  }

  masterToggleCliente(): void {
    if (this.isAllSelectedProveedor()) {
      this.selectionProveedor.clear();
      return;
    }

    this.selectionProveedor.select(...this.dataSourceProveedor.data);
  }

  checkboxLabelProveedor(row?: any): string {
    if (!row) return `${this.isAllSelectedProveedor() ? 'deselect' : 'select'} all`;

    return `${this.selectionProveedor.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  displayedColumnsDocumento: string[] = ['select', 'TDO_CODTDO', 'TDO_DESLAR'];
  dataSourceDocumento: MatTableDataSource<any>;

  @ViewChild("MatPaginatorDocumento") paginatorDocumento: MatPaginator;
  @ViewChild(MatSort) sortDocumento: MatSort;

  loaderDocumento: boolean = true;
  selectionDocumento = new SelectionModel<any>(true, []);

  isAllSelectedDocumento(): boolean {
    const numSelected = this.selectionDocumento.selected.length;
    const numRows = this.dataSourceDocumento.data.length;
    return numSelected === numRows;
  }

  masterToggleDocumento(): void {
    if (this.isAllSelectedDocumento()) {
      this.selectionDocumento.clear();
      return;
    }

    this.selectionDocumento.select(...this.dataSourceDocumento.data);
  }

  checkboxLabelDocumento(row?: any): string {
    if (!row) return `${this.isAllSelectedDocumento() ? 'deselect' : 'select'} all`;

    return `${this.selectionDocumento.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  displayedColumnsMoneda: string[] = ['select', 'TMO_CODTMO', 'tmo_deslar'];
  dataSourceMoneda: MatTableDataSource<any>;

  @ViewChild("MatPaginatorMoneda") paginatorMoneda: MatPaginator;
  @ViewChild(MatSort) sortMoneda: MatSort;

  loaderMoneda: boolean = true;
  selectionMoneda = new SelectionModel<any>(true, []);

  isAllSelectedMoneda(): boolean {
    const numSelected = this.selectionMoneda.selected.length;
    const numRows = this.dataSourceMoneda.data.length;
    return numSelected === numRows;
  }

  masterToggleMoneda(): void {
    if (this.isAllSelectedMoneda()) {
      this.selectionMoneda.clear();
      return;
    }

    this.selectionMoneda.select(...this.dataSourceMoneda.data);
  }

  checkboxLabelMoneda(row?: any): string {
    if (!row) return `${this.isAllSelectedMoneda() ? 'deselect' : 'select'} all`;

    return `${this.selectionMoneda.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  range: FormGroup;
  columnas: any[] = [];

  columnaInfoVentas$: Subscription;
  dataFiltro$: Subscription;
  loading$: Subscription;

  reporteSeleccionado: any = ['CAJA_BANCO_MCBSI'];

  reportes: any[] = REPORTES_CAJA_Y_BANCOS;

  year: string;
  month: string;

  loading: boolean;

  proveedores: any[] = [];
  sucursales: any[] = [];
  tiposDoc: any[] = [];
  cuentas: any[] = [];

  monedas: any[] = [];

  protected _onDestroy = new Subject<void>();

  fechaRuta: any;

  period$: Subscription;

  filtro: FiltroReporteCuentaPagar;

  constructor(
    private _snackBarService: SnackBarService,
    public ete: ExportExcelService,
    private _ventasService: VentasService,
    private _tesoreriaReportService: TesoreriaReportService,
    private _configurationService: ConfigurationService,
    private _cajaBancoService: CajaBancoService,
    private readonly _generalService: GeneralService,
    private store: Store<PavsoState>
  ) {
    this.filtro = new FiltroReporteCuentaPagar();

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })
    this.dataSourceProveedor = fillTable([], this.paginatorProveedor, this.sortProveedor);

    this.dataSourceDocumento = fillTable([], this.paginatorDocumento, this.sortDocumento);

    this.dataSourceMoneda = fillTable([], this.paginatorMoneda, this.sortMoneda);

    this.dataSource = fillTable([], this.paginator, this.sort);

    const today = new Date();

    const month = today.getMonth();
    const year = today.getFullYear();

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, today.getDate()))
    });

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this.loaderProveedor = true;
    this.loaderDocumento = true;
    this.loaderMoneda = true;
    this.loaderFields = true;

    this._generalService.listarColumnasInformeDinamico('32', '002').subscribe(
      columnas => {
        this.columnas = columnas;
        this.columnas.forEach(element => {
          element['name'] = element.TDR_DESTDR;
          element['completed'] = true;
        });

        this.task.subtasks = this.columnas;

        this.dataFiltro$ = this._cajaBancoService.obtenerDataFiltroCuentasPagar().subscribe(
          dataForm => {
            console.log('data form', dataForm)
            this.proveedores = dataForm.proveedores;
            dataForm.proveedores.forEach(proveedor => {
              proveedor['description'] = `${proveedor['CODIGO']} | ${proveedor['NOMBRE']}`
            });
            this.tiposDoc = dataForm.tiposDocumento;
            this.cuentas = dataForm.cuentasContable;
            this.monedas = dataForm.monedas;
            this.loaderFields = false;

          },
          error => {
            this._snackBarService.showError('Error al obtener data filtros', 'Ok')
            this.loaderFields = false;

          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderFields = false;
      }
    )

  }

  seleccionarReporte(event): void {
    // let indicador = "";
    this.reporteSeleccionado[0] = event.id;

    switch (this.reporteSeleccionado[0]) {
      case "CAJA_BANCO_ECP":
        this.filtro.criterio = 'TODOS';
        break;
      case "CAJA_BANCO_PEP":
        this.filtro.criterio = 'TODOS';
        break;
      case "CAJA_BANCO_AFCC":
        this.filtro.criterio = 'TODOS';
        break;
      case "CAJA_BANCO_DFC":
        this.filtro.criterio = 'TODOS';
        break;
      case "CAJA_BANCO_MCBSI":
        this.filtro.criterio = 'TODOS';
        break;
      case "CAJA_BANCO_CPTDS":
        this.filtro.criterio = 'CON_SALDO';
        break;
      case "CAJA_BANCO_SPFC":
        this.filtro.criterio = 'TODOS';
        break;
      default:
        break;
    }

  }

  verReporte(): void {
    this.loading = true;

    const headers: HeaderReport = {
      year: this.year,
      month: this.month,
      clientName: this._configurationService.obtenerNombreCompaniaCliente(),
      client: this._configurationService.obtenerClienteId(),
      date: formatDateWithSlash(new Date()),
      time: getHourMinuteSecondsCurrent(new Date()),
      currency: this.filtro.moneda,
      username: this._configurationService.obtenerNombreUsuario(),
      codePage: this._configurationService.obtenerCodigoPagina()
    }

    const otherData = {
      fechaRuta: this.fechaRuta
    }

    this._tesoreriaReportService.verReporte(this.reporteSeleccionado[0], headers, this.filtro, otherData)
    .then(_ => {
      this.loading = false;
    })
    .catch(_ => {
      this.loading = false;
    })
    .finally(() => {
      this.loading = false;
    })
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.columnaInfoVentas$,
      this.period$,
      this.loading$
    ])
  }

}
