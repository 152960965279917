import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { NewStageOpportunityComponent } from '@shared/components/dialogs/crm/new-stage-opportunity/new-stage-opportunity.component';
import { NuevoSeguimientoEtapa } from '@shared/components/dialogs/crm/nuevo-segumiento-etapa/nuevo-segumiento-etapa.component';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { DialogService } from '@shared/services/dialog.service';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable } from '@utils/tables/table';
import { Subject, Subscription } from 'rxjs';
import { RegistroOportunidad } from 'src/app/models/crm/registro_oportunidad';
import { AuthenticationService, ConfigurationService, CrmService, VentasService } from 'src/app/services';
import { CampaniaService } from 'src/app/services/api/crm/maestros/campania.service';
import { CanalService } from 'src/app/services/api/crm/maestros/canal.service';
import { LineaNegocioService } from 'src/app/services/api/crm/maestros/linea-negocio.service';
import { SituacionService } from 'src/app/services/api/crm/maestros/situacion.service';
import { TipoNegociacionService } from 'src/app/services/api/crm/maestros/tipo-negociacion.service';
import { ApiCRMOperacionesOportunidadService } from 'src/app/services/api/crm/operaciones/crm.operaciones.oportunidad.service';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';

@Component({
  selector: 'app-oportunidad-form',
  templateUrl: './oportunidad-form.component.html',
  styleUrls: ['./oportunidad-form.component.css']
})
export class OportunidadFormComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['sec', 'order', 'description'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  vendedores: any[] = [];
  monedas: any[] = [];
  canales: any[] = [];
  tipos: any[] = [];
  situaciones: any[] = [];
  campanias: any[] = [];

  minDate: Date;

  year: string;
  month: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;

  registro: RegistroOportunidad;

  vendedor$: Subscription;
  cliente$: Subscription;
  moneda$: Subscription;
  canal$: Subscription;
  period$: Subscription;
  loading$: Subscription;
  lineaNegocio$: Subscription;
  tipoNegociacion$: Subscription;
  productos$: Subscription;
  registro$: Subscription;
  contactos$: Subscription;
  situacion$: Subscription;
  campanias$: Subscription;

  clientes: any[] = [];
  contactos: any[] = [];
  lineas: any[] = [];
  productos: any[] = [];

  @ViewChild('iCantidadReferencial') inputCantidadReferencial: ElementRef;
  @ViewChild('iCantidadTrabajadoresReferencial') inputCantidadTrabajadoresReferencial: ElementRef;
  @ViewChild('iCantidadUsuariosReferencial') inputCantidadUsuariosReferencial: ElementRef;
  @ViewChild('iValorReferencial') inputValorReferencial: ElementRef;

  protected _onDestroy = new Subject<void>();

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _dialogService: DialogService,
    private _authService: AuthenticationService,
    private _crmService: CrmService,
    private _canalService: CanalService,
    private _apiOportunidadCrmService: ApiCRMOperacionesOportunidadService,
    private _situacionService: SituacionService,
    private _productoService: ProductoService,
    private _activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private _ilumiTableService: VentasService,
    private store: Store<PavsoState>,
    private _configurationService: ConfigurationService,
    private _lineaNegocioService: LineaNegocioService,
    private _campaniaService: CampaniaService,
    private _tipoNegociacionService: TipoNegociacionService,
  ) {

    this.registro = new RegistroOportunidad();

    this.registro.codccs = ' ';

    this.registro.codcia = this._configurationService.obtenerCompaniaCliente();
    this.registro.coduse = this._configurationService.obtenerIdUsuario();

    this.registro.indeva = 0;

    this.period$ = this.store.select('period').subscribe(({ year, month }) => {
      this.year = year;
      this.month = month;
    });

    this.minDate = new Date(parseInt(this.year), parseInt(this.month), new Date().getDate());

    this.usuario = this._authService.getUsuarioSistema();

    this.dataSource = fillTable([], this.paginator, this.sort);

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(({ id }) => {

      this.uid = id;
      this.loadMaestros();
      this.uid == '0' ? this.btnName = 'general.button.store' : this.btnName = 'general.button.update';

      if (id == '0') return;

    })

  }

  /**
   * Obtienes Registros de oportunidad y
   * los contactos por cliente
   */
  obtenerRegistroOportunidad(): void {
    this._apiOportunidadCrmService.obtenerRegistroOportunidad(this.uid).subscribe(
      ([registro]) => {
        console.log('REGISTRO', registro);
        this.registro = registro;
        this.contactos$ = this._crmService.obtenerContactosPorCliente(registro.codcli).subscribe(
          contactos => {
            this.contactos = contactos;
            console.log('contactos', this.contactos)

            this._crmService.obtenerEspecificacionesPorOportunidad(this.uid).subscribe(
              especificaciones => {
                console.log('especificaciones', especificaciones)
                this.loaderData = false;
                this.dataSource = fillTable(especificaciones, this.paginator, this.sort);
              },
              error => {
                this._snackBarService.showError(error.error.msg, 'Ok')
                this.loaderData = false;
              }
            )
          },
          error => {
            this.loaderData = false;
            this._snackBarService.showError(error.error.msg, "Ok")
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, "OK")
        this.loaderData = false;
      }
    )
  }

  loadMaestros(): void {
    this.loaderData = true;
    this.vendedor$ = this._ilumiTableService.obtenerVendedores().subscribe(
      vendedores => {
        this.vendedores = vendedores;

        this.cliente$ = this._ilumiTableService.obtenerClientes().subscribe(
          clientes => {
            this.clientes = clientes;

            this.canal$ = this._canalService.obtenerCanales().subscribe(
              canales => {
                this.canales = canales;

                this.lineaNegocio$ = this._lineaNegocioService.obtenerLineasNegocio().subscribe(
                  lineas => {
                    this.lineas = lineas;

                    this.tipoNegociacion$ = this._tipoNegociacionService.obtenerTiposNegociacion().subscribe(
                      tipos => {
                        this.tipos = tipos;

                        this.productos$ = this._productoService.obtenerProductos().subscribe(
                          productos => {
                            this.productos = productos;

                            this.situacion$ = this._situacionService.obtenerSituacionesOportunidad().subscribe(
                              situaciones => {
                                this.situaciones = situaciones;


                                this.campanias$ = this._campaniaService.obtenerCampanias().subscribe(
                                  campanias => {
                                    this.campanias = campanias;

                                    if(this.uid != '0') {
                                      this.obtenerRegistroOportunidad();
                                      return;
                                    };
                                    this.loaderData = false;
                                    console.log('campanias,', campanias)
                                  },
                                  error => {
                                    this._snackBarService.showError(error.error.msg, 'Ok')
                                    this.loaderData = false;
                                    if (this.uid != '0') {
                                      this.obtenerRegistroOportunidad();
                                      return;
                                    };
                                  }
                                )
                              },
                              error => {
                                this._snackBarService.showError(error.error.msg, 'Ok')
                                this.loaderData = false;
                                if (this.uid != '0') {
                                  this.obtenerRegistroOportunidad();
                                  return;
                                };
                              }
                            )
                          },
                          error => {
                            this._snackBarService.showError(error.error.msg, 'Ok')
                            this.loaderData = false;
                            if (this.uid != '0') {
                              this.obtenerRegistroOportunidad();
                              return;
                            };
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError(error.error.msg, 'Ok')
                        this.loaderData = false;
                        if (this.uid != '0') {
                          this.obtenerRegistroOportunidad();
                          return;
                        };
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError(error.error.msg, 'Ok')
                    this.loaderData = false;
                    if (this.uid != '0') {
                      this.obtenerRegistroOportunidad();
                      return;
                    };
                  }
                )
              },
              error => {
                this._snackBarService.showError(error.error.msg, 'Ok')
                this.loaderData = false;
                if (this.uid != '0') {
                  this.obtenerRegistroOportunidad();
                  return;
                };
              }
            )
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok')
            this.loaderData = false;
            if (this.uid != '0') {
              this.obtenerRegistroOportunidad();
              return;
            };
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok')
        this.loaderData = false;
        if (this.uid != '0') {
          this.obtenerRegistroOportunidad();
          return;
        };
      }

    )

  }

  trackByCanal(index, canal): string {
    return canal ? canal.idmtk : undefined;
  }

  trackByVendedor(index, vendedor): string {
    return vendedor ? vendedor.VDE_CODVDE : undefined;
  }

  trackByLinea(index, linea): string {
    return linea ? linea.codoln : undefined;
  }

  trackByTipoNegociacion(index, tipo): string {
    return tipo ? tipo.codotn : undefined;
  }

  trackByCliente(index, cliente): string {
    return cliente ? cliente.cli_codcli : undefined;
  }

  trackByProducto(index, producto): string {
    return producto ? producto.prd_codprd : undefined;
  }

  trackByContacto(index, contacto): string {
    return contacto ? contacto.codccl : undefined;
  }

  trackBySituacion(index, situacion): string {
    return situacion ? situacion.idstk : undefined;
  }

  seleccionarCliente(clienteId): void {
    console.log('cliente id', clienteId)
    this.contactos = [];
    this.registro.codccl = null;

    this.contactos$ = this._crmService.obtenerContactosPorCliente(clienteId).subscribe(
      contactos => {
        console.log('contactos', contactos);
        this.contactos = contactos;

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {

    if (!this.formularioValido()) return;

    this.uid == '0' ? this.registrar(f) : this.actualizar(f);
  }

  /**
   * Método para registrar una nueva oportunidad
   * @param f
   */
  registrar(f: NgForm): void {

    this.loaderReg = true;
    this._apiOportunidadCrmService.registrarRegistroOportunidad(this.registro).subscribe(
      response => {
        this.registro.idtkt = response[0].otk_idtkt;
        this.uid = response[0].otk_idtkt;
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, `Oportunidad registrada. Nro de Ticket: ${response[0].otk_idtkt}`, '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para actualizar oportunidad
   * @param f
   */
  actualizar(f: NgForm): void {
    this.loaderReg = true;
    this._apiOportunidadCrmService.actualizarRegistroOportunidad(this.registro).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'Oportunidad actualizada', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  formularioValido(): boolean {

    if (!this.registro.indsta) {
      this._snackBarService.showError('Seleccionar el estado', 'Ok');
      return false;
    }

    if (!this.registro.idmtk) {
      this._snackBarService.showError('Seleccionar canal', 'Ok');
      return false;
    }

    if (!this.registro.codvde) {
      this._snackBarService.showError('Seleccionar vendedor', 'Ok');
      return false;
    }

    if (!this.registro.codoln) {
      this._snackBarService.showError('Seleccionar línea de negocio', 'Ok');
      return false;
    }

    if (!this.registro.codotn) {
      this._snackBarService.showError('Seleccionar tipo de negociación', 'Ok');
      return false;
    }

    if (!this.registro.codcli) {
      this._snackBarService.showError('Seleccionar cliente', 'Ok');
      return false;
    }

    if (!this.registro.codprd) {
      this._snackBarService.showError('Seleccionar bien o servicio', 'Ok');
      return false;
    }

    if (!this.registro.codtmo) {
      this._snackBarService.showError('Seleccionar moneda', 'Ok');
      return false;
    }

    return true;

  }

  volver(): void {
    this._router.navigate(['/modulo-crm/registros-de-oportunidad']);
  }

  nuevaEtapa(): void {
    const dialogRef = this.dialog.open(NewStageOpportunityComponent, {
      width: '600px',
      disableClose: true,
      data: {
        idtkt: this.uid
      },
      panelClass: 'pav-dialog-container'
    })

    dialogRef.afterClosed().subscribe(result => { })
  }

  nuevoSeguimientoEtapa(): void {
    const dialogRef = this.dialog.open(NuevoSeguimientoEtapa, {
      width: '600px',
      disableClose: true,
      data: {
        idtkt: this.uid
      },
      panelClass: 'pav-dialog-container'
    })

    dialogRef.afterClosed().subscribe(result => { })
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.vendedor$,
      this.cliente$,
      this.moneda$,
      this.canal$,
      this.period$,
      this.registro$,
      this.loading$,
      this.lineaNegocio$,
      this.tipoNegociacion$,
      this.productos$,
      this.contactos$
    ])

  }
}
