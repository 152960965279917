import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { AuthenticationService, ContabilidadService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { Store } from '@ngrx/store';
import { fillTable } from '@utils/tables/table';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';
import { forkObs } from '@utils/observables/fork';
import { Envasado, Material, OrdenProduccion } from 'src/app/models/produccion/orden-produccion';
import { PedidoService } from 'src/app/services/api/ventas/operaciones/pedido.service';
import { DialogService } from '@shared/services/dialog.service';
import { ConfirmationComponent } from '@shared/components/dialogs/confirmation/confirmation.component';

@Component({
  selector: 'app-orden-produccion',
  templateUrl: './orden-produccion.component.html',
  styleUrls: ['./orden-produccion.component.css']
})
export class OrdenProduccionComponent implements OnInit, OnDestroy {

  displayedColumnsMat: string[] = ['acciones', 'item', 'codigo', 'cod_laboral', 'descripcion', 'um', 'cantidad'];
  dataSourceMat: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorMat: MatPaginator;
  @ViewChild(MatSort) sortMat: MatSort;

  displayedColumnsEnv: string[] = ['acciones', 'item', 'codigo', 'descripcion', 'um', 'cantidad', 'distribucion', 'en_caja', 'orden', 'fecha_fabricacion', 'caja'];
  dataSourceEnv: MatTableDataSource<any>;

  @ViewChild("paginatorEnvasado") paginatorEnv: MatPaginator;
  @ViewChild(MatSort) sortEnv: MatSort;

  displayedColumnsLista: string[] = ['codigo', 'cliente', 'estado'];
  dataSourceLista: MatTableDataSource<any>;

  @ViewChild("paginatorLista") paginatorLista: MatPaginator;
  @ViewChild(MatSort) sortLista: MatSort;

  loaderReg: boolean = false;
  loaderData: boolean = false;

  productos: any[] = [];
  centros: any[] = [];
  pedidos: any[] = [];

  usuario: any;
  fecha: Date = new Date();
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string = this.buttonsName.BTN_STORE;

  loading$: Subscription;
  periodo$: Subscription;

  ordenProduccion: OrdenProduccion;

  anioActividad: string;
  mesActividad: string;

  uid: string;

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _contabilidadService: ContabilidadService,
    private store: Store<PavsoState>,
    private _pedidoService: PedidoService,
    private _productoService: ProductoService,
    private _authService: AuthenticationService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute
  ) {

    this.ordenProduccion = new OrdenProduccion();
    this.usuario = this._authService.getUsuarioSistema();

    this.dataSourceEnv = fillTable([], this.paginatorEnv, this.sortEnv);

    this.dataSourceLista = fillTable([], this.paginatorLista, this.sortLista);

    this.dataSourceMat = fillTable([], this.paginatorMat, this.sortMat);

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.anioActividad = state.year;
      this.mesActividad = state.month;
    })
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this._activatedRoute.params.subscribe(({id}) => this.uid = id);

    this.btnName = this.uid == '0' ? this.buttonsName.BTN_STORE : this.buttonsName.BTN_UPDATE;

    this.loaderData = true;

    this.loadMaestros();

  }

  centros$: Subscription;
  productos$: Subscription;
  pedidos$: Subscription;

  loadMaestros(): void {

    this.centros$ = this._contabilidadService.listarCentroCostos().subscribe(
      centros => {
        this.centros = centros;
        this.productos$ = this._productoService.obtenerProductos().subscribe(
          productos => {
            this.productos = productos;
            this.periodo$ = this._pedidoService.obtenerPedidos('01', {params: [{param: 'anio', value: this.anioActividad}, {param: 'mes', value:this.mesActividad}]}).subscribe(
              pedidos => {
                this.pedidos = pedidos;

                this.loaderData = false;
              },
              error => {
                this._snackBarService.showError('Error al obtener pedidos', 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError('Error al obtener productos', 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError('Error al obtener centros de costo', 'Ok');
        this.loaderData = false;
      }
    )
  }

  agregarMaterial(): void {
    const material = new Material();

    this.ordenProduccion.detalle_material.push(material);

    this.dataSourceMat = fillTable(this.ordenProduccion.detalle_material, this.paginatorMat, this.sortMat);
  }

  eliminarMaterial(index): void {
    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

      if(result) {

        const materiales = this.ordenProduccion.detalle_material.filter((element, i) => index != i );

        this.ordenProduccion.detalle_material = materiales;

        this.dataSourceMat = fillTable(this.ordenProduccion.detalle_material, this.paginatorMat, this.sortMat);
        this.dataSourceEnv = fillTable([], this.paginatorEnv, this.sortEnv);
      }

    })
  }

  seleccionarMaterial(value: boolean, materialSeleccionado): void {
    if(value) {
      this.ordenProduccion.detalle_material.forEach(element => element.seleccionado = false);
      materialSeleccionado.seleccionado = value;

      this.dataSourceEnv = fillTable(materialSeleccionado.detalle_envasado, this.paginatorEnv, this.sortEnv);
    }
  }

  agregarEnvasado(): void {

    const materialSeleccionado = this.ordenProduccion.detalle_material.find(item => item.seleccionado);

    if(!materialSeleccionado) {
      this._snackBarService.showError('Seleccionar un material', 'Ok');
      return;
    }

    const envasado = new Envasado();

    materialSeleccionado.detalle_envasado.push(envasado);

    this.dataSourceEnv = fillTable(materialSeleccionado.detalle_envasado, this.paginatorEnv, this.sortEnv);

  }

  eliminarEnvasado(index): void {
    const materialSeleccionado: Material = this.ordenProduccion.detalle_material.find(item => item.seleccionado);

    if(!materialSeleccionado) {
      this._snackBarService.showError('Seleccionar un material','OK');
      return;
    }

    this._dialogService.openDialog(ConfirmationComponent, '¿Está seguro de quitar este ítem?', '', '', '').subscribe(result => {

      if(result) {

        const rutas = materialSeleccionado.detalle_envasado.filter((element, i) => index != i )

        materialSeleccionado.detalle_envasado = rutas;

        this.dataSourceEnv = fillTable(materialSeleccionado.detalle_envasado, this.paginatorEnv, this.sortEnv);
      }

    })
  }

  enviarFormulario(f: NgForm): void {
    this.uid == '0' ? this.registrarOrdenProduccion(f): this.editarOrdenProduccion(f);
  }

  registrarOrdenProduccion(f: NgForm): void {

  }

  editarOrdenProduccion(f: NgForm): void {

  }

  volver(): void {
    this._router.navigate(['/choferes']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.periodo$,
      this.loading$
    ])
  }
}
