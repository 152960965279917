import { Component, OnDestroy, OnInit } from "@angular/core";
import { NAMES_CONSTANTS } from "@data/constants/names/name.metadata";
import { INameConstant } from "@data/interfaces/constants/name.interface";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { REPORTES_CUENTAS_PAGAR_DINAMICOS } from "@data/json/reportes/cajabanco.reporte.json";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import { unsubscribeSubscription } from "@utils/others/subscription";
import { Subscription } from "rxjs";
import { FiltroReporteCuentaPagar } from "src/app/models/caja-banco/filtro-reporte-cuenta-pagar";
import { OpcionFiltro } from "src/app/models/general/opciones-filtro";
import { CajaBancoService, GeneralService, VentasService } from "src/app/services";
import { TesoreriaReportService } from "src/app/services/reports/modules/tesoreria-report.service";

@Component({
  selector: 'dinamicos-cuentas-pagar',
  templateUrl: './dinamicos-cuentas-pagar.component.html',
  styleUrls: ['./dinamicos-cuentas-pagar.component.scss']
})
export class DinamicosCuentasPagar implements OnInit, OnDestroy {

  reportes: any[] = REPORTES_CUENTAS_PAGAR_DINAMICOS;
  reporteSeleccionado: any = ['CAJA_BANCO_DP'];

  columnas$: Subscription;
  dataForm$: Subscription;
  filtros$: Subscription;
  loading$: Subscription;
  periodo$: Subscription;

  year: string;
  month: string;

  columnas: any[] = [];
  opcionesFiltro: any[] = [];
  opcionFiltro: OpcionFiltro;

  task: any = {
    name: 'Campos',
    completed: false,
    color: 'primary',
    subtasks: []
  };

  allComplete: boolean = false;

  updateAllComplete(): void {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) return false;

    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean): void {
    this.allComplete = completed;

    if (this.task.subtasks == null) return;

    this.task.subtasks.forEach(t => t.completed = completed);
  }

  loading: boolean;

  loaderFields: boolean;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  proveedores: any[] = [];
  sucursales: any[] = [];
  tiposDoc: any[] = [];
  cuentas: any[] = [];

  monedas: any[] = [];

  filtro: FiltroReporteCuentaPagar;

  constructor(
    private _ventasService: VentasService,
    private _cuentaPagarService: CajaBancoService,
    private _snackBarService: SnackBarService,
    private _generalService: GeneralService,
    private _cuentaPagarReporteService: TesoreriaReportService,
    private store: Store<PavsoState>
  ) {
    this.filtro = new FiltroReporteCuentaPagar();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    });

    this.periodo$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    });
  }

  loadData(): void {
    this.loaderFields = true;
    this.loading = true;
    this.dataForm$ = this._cuentaPagarService.obtenerDataFiltroCuentasPagar().subscribe(
      dataForm => {
        console.log('dataform', dataForm)
        this.proveedores = dataForm.proveedores;

        this.tiposDoc = dataForm.tiposDocumento;

        this.cuentas = dataForm.cuentasContable;

        this.monedas = dataForm.monedas;

        this.filtros$ = this._generalService.obtenerOpcionesFiltrosReporteModulo().subscribe(
          response => {
            console.log(response)

            this.opcionesFiltro = response;
            this.obtenerColumnas('001');

            this.loaderFields = false;
            this.loading = false;
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderFields = false;
            this.loading = false;
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderFields = false;
        this.loading = false;
      }
    )
  }

  obtenerColumnas(code): void {
    console.log('codigo de reporte', code)
    this.loaderFields = true;

    this.columnas$ = this._generalService.listarColumnasInformeDinamico('42', code).subscribe(
      columnas => {
        this.columnas = columnas;

        this.columnas.forEach(element => {
          element['id'] = element.TDR_DESTDR;
          element['name'] = element.TDR_COLNOM;
          element['completed'] = true;
        });
        console.log('columnas', this.columnas)
        this.task.subtasks = this.columnas;
        this.loaderFields = false;

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderFields = false;
      }
    )
  }

  seleccionarReporte(event) {

    this.reporteSeleccionado[0] = event.id;

    this.obtenerColumnas(event.codigo);

    switch (this.reporteSeleccionado[0]) {
      case "CAJA_BANCO_DP":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "42002");
        break;
      case "CAJA_BANCO_DPAGOS":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "42001");
        break;
      default:
        break;
    }
  }

  verReporte(): void {
    this.loading = true;

    let headers = {
      codano: this.year,
      codmes: this.month,
    };

    let filtros = {};
    let otherData = {
      columns: this.task.subtasks,
      filtro: this.filtro
    };

    this._cuentaPagarReporteService.verReporteDinamico(this.reporteSeleccionado[0], headers, filtros, otherData)
    .then(_ => this.loading = false)
    .catch(_ => this.loading = false)
    .finally(() => this.loading = false)
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.loading$,
      this.periodo$
    ])
  }
}
