<app-pavso-title-section [title]="'Pedidos'" [module]="'Ventas'"></app-pavso-title-section>

<div class="pav-table-search-container">
  <!-- <mat-form-field class="pav-filter-search-control">
    <mat-label>
      Vendedor
    </mat-label>
    <mat-select (selectionChange)="seleccionarVendedor($event.value)" name="vendedor" value="TODOS">
        <mat-option value="TODOS">
            Todos
        </mat-option>
        <mat-option value="PENDIENTE">
            Pendiente
        </mat-option>
    </mat-select>
  </mat-form-field> -->
  <!-- <mat-form-field class="pav-filter-search-control">
    <mat-label>Fecha Inicio - Fecha Final</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field> -->
  <div>
    <mat-form-field class="pav-filter-search-control">
      <mat-label>Estado</mat-label>
      <mat-select (selectionChange)="cambiarEstado($event.value)" name="estado" value="TODOS">
          <mat-option value="TODOS">
              Todos
          </mat-option>
          <mat-option value="0">
              Pendiente
          </mat-option>
          <mat-option value="1">
              Aprobado
          </mat-option>
          <!-- <mat-option value="DESAPROBADO">
              Desaprobado
          </mat-option> -->
      </mat-select>
    </mat-form-field>
  </div>
  <div class="pav-separator"></div>
  <div class="pav-table-search">
    <input type="text" (keyup)="applyFilter($event)" [placeholder]="buttonsName.BTN_SEARCH" #input>
  </div>
</div>

<div class="pavso-content-form fade-in-image">
    <div class="mat-elevation-z0">

      <table-title-action title="Pedidos" url="/modulo-ventas/pedido" entity="" [params]="['0']" (clickDescargar)="exporter.exportTable('csv')"></table-title-action>
      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter">
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones" class="td-responsive">
                  <div class="pav-btns-container">
                    <a class="pav-btn-circle-edit" matTooltip="Editar" [routerLink]="['/modulo-ventas/pedido', row.PCC_NUMPCC]">
                      <mat-icon svgIcon="edit-white"></mat-icon>
                    </a>
                    <div class="pav-btn-circle-pdf" matTooltip="Generar pdf" (click)="imprimirPDF(row.PCC_NUMPCC)">
                      <mat-icon svgIcon="pdf"></mat-icon>
                    </div>
                    <!-- <div class="pav-btn-circle-more" (click)="cambiar()">
                      <mat-icon svgIcon="more"></mat-icon>
                      <span class="tooltiptext" [style.visibility]="visibility">
                        <div>
                          <mat-icon svgIcon="like"></mat-icon>
                        </div>
                        <div>
                          <mat-icon svgIcon="dislike"></mat-icon>
                        </div>
                        <div>
                          <mat-icon svgIcon="correo"></mat-icon>
                        </div>
                        <div>
                          <mat-icon svgIcon="paper"></mat-icon>
                        </div>
                        <div>
                          <mat-icon svgIcon="more-horizontal"></mat-icon>
                        </div>
                      </span>
                    </div> -->
                    <!-- <button type="button" matTooltip="Imprimir PDF" (click)="imprimirPDF(row.pcc_numpcc)" mat-icon-button color="warn" aria-label="Imprimir PDF">
                      <mat-icon>picture_as_pdf</mat-icon>
                    </button> -->
                  </div>

                    <!-- <div class="pavso-container-action">
                        <button type="button" matTooltip="Editar pedido" [routerLink]="['/modulo-ventas/editar-pedido', row.pcc_numpcc]" mat-icon-button color="primary" aria-label="Editar pedido">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button type="button" matTooltip="Imprimir PDF" (click)="imprimirPDF(row.pcc_numpcc)" mat-icon-button color="warn" aria-label="Imprimir PDF">
                            <mat-icon>picture_as_pdf</mat-icon>
                        </button>
                        <button type="button" matTooltip="Enviar Correo" (click)="enviarCorreo(row.pcc_numpcc)" mat-icon-button color="primary" aria-label="Enviar correo">
                            <mat-icon>email</mat-icon>
                        </button>
                        <button type="button" [disabled]="row.ESTADO_APROBACION == 'APROBADO'" matTooltip="Aprobar" (click)="aprobar(row.pcc_numpcc)" mat-icon-button color="primary" aria-label="Aprobar">
                            <mat-icon>thumb_up_alt</mat-icon>
                        </button>
                        <button type="button" [disabled]="row.ESTADO_APROBACION == 'PENDIENTE'" matTooltip="Establecer a Pendiente" (click)="establecerPendiente(row.pcc_numpcc)" mat-icon-button color="primary">
                            <mat-icon>pending</mat-icon>
                        </button>
                        <button type="button" matTooltip="Desaprobar" (click)="desaprobar(row.pcc_numpcc)" mat-icon-button color="primary" aria-label="Desaprobar">
                            <mat-icon>thumb_down_alt</mat-icon>
                        </button>
                        <button *ngIf="row.ESTADO_APROBACION == 'APROBADO'" type="button" matTooltip="Generar Factura" (click)="generarFactura(row)" mat-icon-button color="primary">
                            <mat-icon>receipt</mat-icon>
                        </button>
                    </div> -->

                </td>
            </ng-container>

            <ng-container matColumnDef="PCC_NUMPCC">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Pedido </th>
                <td mat-cell *matCellDef="let row" data-label="Nro. Pedido" class="td-responsive"> {{row.PCC_NUMPCC}} </td>
            </ng-container>

            <ng-container matColumnDef="CLI_CODCLI">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Documento Cliente </th>
                <td mat-cell *matCellDef="let row" data-label="Documento Cliente" class="td-responsive"> {{row.CLI_CODCLI}} </td>
            </ng-container>

            <ng-container matColumnDef="cli_nomcli">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
                <td mat-cell *matCellDef="let row" data-label="Cliente" class="td-responsive"> {{row.cliente.cli_nomcli}} </td>
            </ng-container>

            <ng-container matColumnDef="PCC_FECDOC">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
                <td mat-cell *matCellDef="let row" data-label="Fecha" class="td-responsive"> {{row.PCC_FECDOC | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="tmo_codtmo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </th>
                <td mat-cell *matCellDef="let row" data-label="Moneda" class="td-responsive"> {{(row.TMO_CODTMO == 'SO')? 'Soles': 'Dolares'}} </td>
            </ng-container>

            <ng-container matColumnDef="PCC_IMPTOT">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Total </th>
                <td mat-cell *matCellDef="let row" data-label="Importe Total" class="td-responsive" [ngStyle]="{textAlign: 'right'}">
                  <div [ngStyle]="{paddingRight: '60px'}">
                    {{row.PCC_IMPTOT.toFixed(2)}}
                  </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="vde_nomvde">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendedor </th>
                <td mat-cell *matCellDef="let row" data-label="Vendedor" class="td-responsive"> {{row.vendedor.vde_nomvde}} </td>
            </ng-container>

            <ng-container matColumnDef="PCC_CODUSE">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Usuario </th>
              <td mat-cell *matCellDef="let row" data-label="Vendedor" class="td-responsive"> {{row.PCC_CODUSE}} </td>
            </ng-container>

            <ng-container matColumnDef="PCC_INDSTA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let row" data-label="Estado" class="td-responsive">

                  <div *ngIf="row.PCC_INDSTA == '0'" class="sc_pending">ANULADO</div>
                  <div *ngIf="row.PCC_INDSTA == '1'" class="sc_approved">VIGENTE</div>

                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="10">No se encontraron registros "{{input.value}}"</td>
            </tr>

        </table>
      </div>

        <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
</div>
