import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExportExcelService, CuentaCobrarService, GeneralService, ConfigurationService } from 'src/app/services';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { FormControl, FormGroup } from '@angular/forms';
import { SnackBarService } from '@shared/services/snackbar.service';
import { Subject, Subscription } from 'rxjs';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Store } from '@ngrx/store';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { REPORTES_COBRANZAS } from '@data/json/reportes';
import { CuentaCobrarReportService } from 'src/app/services/reports/modules/cuenta-cobrar-report.service';
import { FiltroReporteCobranza } from 'src/app/models/cobranzas';
import { OpcionFiltro } from 'src/app/models/general/opciones-filtro';


@Component({
  selector: 'app-cobranza-a-la-fecha',
  templateUrl: './cobranza-a-la-fecha.component.html',
  styleUrls: ['./cobranza-a-la-fecha.component.css']
})
export class CobranzaALaFechaComponent implements OnInit, OnDestroy {

  clientes: any[] = [];
  vendedores: any[] = [];
  tiposDocumento: any[] = [];

  loaderFields: boolean = false;
  loading: boolean = false;

  dataForExcel = [];

  dataDetalleFacturacion = [];
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  range: FormGroup;

  columnas: any[] = [];

  columnasInfoVenta$: Subscription;
  regDetalleVenta$: Subscription;
  dataFiltro$: Subscription;
  filtros$: Subscription;

  filtro: FiltroReporteCobranza;

  year: string;
  month: string;

  reporteSeleccionado: any = ['COBRANZAS_DC'];

  reportes: any[] = REPORTES_COBRANZAS;

  period$: Subscription;
  loading$: Subscription;

  opcionesFiltro: any[] = [];
  opcionFiltro: OpcionFiltro;

  protected _onDestroy = new Subject<void>();

  constructor(
    private _snackBarService: SnackBarService,
    private _cuentaCobrarService: CuentaCobrarService,
    private _cuentaCobrarReportService: CuentaCobrarReportService,
    private _generalService: GeneralService,
    private _configurationService: ConfigurationService,
    public ete: ExportExcelService,
    private store: Store<PavsoState>
  ) {

    this.opcionFiltro = new OpcionFiltro();
    this.filtro = new FiltroReporteCobranza();

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

    const today = new Date();

    const month = today.getMonth();
    const year = today.getFullYear();

    this.range = new FormGroup({
      start: new FormControl(new Date(year, month, 1)),
      end: new FormControl(new Date(year, month, today.getDate()))
    });

  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {

    this.loaderFields = true;

    this.dataFiltro$ = this._cuentaCobrarService.obtenerDataFiltroCuentaCobrar().subscribe(
      dataFiltro => {
        console.log('dataFiltro', dataFiltro)

        this.clientes = dataFiltro.clientes;
        this.clientes.forEach(item => item['description'] = `${item['cli_codcli']} | ${item['cli_nomcli']}`)
        this.vendedores = dataFiltro.vendedores;
        this.tiposDocumento = dataFiltro.tiposDocumento;

        this.filtros$ = this._generalService.obtenerOpcionesFiltrosReporteModulo().subscribe(
          response => {
            console.log(response)

            this.opcionesFiltro = response;

            this.seleccionarReporte({id: this.reporteSeleccionado[0]});

            this.loaderFields = false;
            this.loading = false;
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderFields = false;
            this.loading = false;
          }
        )

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'OK');
        this.loaderFields = false;
      }
    )

  }

  seleccionarReporte(event): void {
    this.reporteSeleccionado[0] = event.id;

    switch (this.reporteSeleccionado[0]) {
      case "COBRANZAS_DC":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "43001")
        this.filtro.criterio = 'CON_SALDO';
        console.log('opcion filtro', this.opcionFiltro)
        break;
      case "COBRANZAS_MC":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "43001")
        console.log('opcion filtro', this.opcionFiltro)
        this.filtro.criterio = 'TODOS';
        break;

      case "COBRANZAS_ECCli":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "43001")
        this.filtro.criterio = 'TODOS';
        console.log('opcion filtro', this.opcionFiltro)
        break;

      case "COBRANZAS_TCC":
        this.opcionFiltro = this.opcionesFiltro.find(item => item.s26_grupo == "43001")
        this.filtro.criterio = 'CON_SALDO';
        break;

      default:
        break;
    }
  }

  verReporte(): void {
    this.loading = true;

    const headers = {
      codano: this.year,
      codmes: this.month,
      coddia: new Date().getDate(),
      codcli: this.filtro.clientes,
      fecini: this.range.value.start,
      fecter: this.range.value.end,
      dia: new Date().getDate(),
      mes: new Date().getMonth() > 9 ? `${new Date().getMonth() +  1}`: `0${new Date().getMonth() +  1}`,
      ano: new Date().getFullYear(),
      saldo: this.filtro.saldo,
      client: this._configurationService.obtenerClienteId(),
      criterio: this.filtro.criterio,
      username: this._configurationService.obtenerIdUsuario(),
      soloCuenta12: this.filtro.soloCuenta12
    }

    const otherData = {}

    this._cuentaCobrarReportService.verReporte(this.reporteSeleccionado[0], headers, this.filtro, otherData)
    .then(_ => {
      this.loading = false;
    })
    .catch(_ => {
      this.loading = false;
    })
    .finally(() => {
      this.loading = false;
    })

  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.columnasInfoVenta$,
      this.regDetalleVenta$,
      this.period$,
      this.loading$
    ])
  }

}
