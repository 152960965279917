import { ProveedorCONEFACTSUNAT } from "../compras/provider";

export class Cliente {
  cia_codcia:             string;
  cli_codcli:             string;
  cli_tipper:             string;
  cli_nomcli:             string;
  cli_apepat:             string;
  cli_apemat:             string;
  cli_nombre:             string;
  cli_dircli:             string;
  cli_discli:             null;
  cli_indciv:             null;
  cli_indsex:             null;
  cli_numruc:             string;
  cli_numdni:             string;
  cli_numtlf:             string;
  cli_numfax:             string;
  t09_coddoc:             null;
  cli_corele:             string;
  cli_nomcon:             string;
  cli_carcon:             string;
  pai_codpai:             string;
  ubi_codubi:             string;
  zve_codzve:             null;
  tpl_codtlp:             string;
  gno_codgno:             null;
  cli_indcli:             number;
  cli_indpro:             number;
  cli_indtra:             number;
  cli_indgas:             number;
  cli_indsta:             string;
  cli_coduse:             string;
  cli_fecupd:             Date;
  cli_indpnj:             number;
  cli_nomcom:             string;
  lde_dirfis:             null;
  suc_codsuc:             string;
  cli_inddom:             number;
  cli_numcel:             null;
  did_coddid:             string;
  cia_refcpa:             null;
  cia_impcre:             null;
  tmo_codtmo:             null;
  cli_doccom:             null;
  cli_banco:              null;
  cli_nrocta:             null;
  cli_fecnac:             Date;
  cli_indper:             null;
  cli_indrfp:             null;
  apf_codafp:             null;
  cli_comafp:             null;
  cli_rutfot:             null;
  cli_indrec:             null;
  cli_codrlj:             null;
  ubs_codubs:             null;
  cli_indvin:             number;
  cli_idecli:             number;
  cli_excper:             null;
  cli_ageper:             null;
  cli_ageret:             null;
  cli_indigv:             number;
  cli_regnac:             null;
  dato_clc:               DatoClc;
  dato_reg:               DatoReg;
  detalle_lugar_despacho: DetalleLugarDespacho[];
  detalle_contacto:       DetalleContacto[];
  detalle_tipser_prov:    DetalleTipserProv[];
  detalle_almacen_cla:    any[];
  detalle_dias_cds:       any[];

  constructor() {
    this.dato_clc = new DatoClc();
    this.detalle_lugar_despacho = [];
    this.detalle_contacto = [];
    this.detalle_tipser_prov = [];
    this.detalle_almacen_cla = [];
    this.detalle_dias_cds = [];

    this.cli_indcli = 1;
    this.cli_indpro = 0;
    this.cli_indtra = 0;
    this.cli_indgas = 0;
    this.cli_tipper = "J";
    this.did_coddid = "001";
    this.cli_fecupd = new Date();
    this.cli_fecnac = new Date();
    this.cli_indpnj = 0;
    this.cli_inddom = 1;
    this.cli_indvin = 0;
    this.cli_idecli = 22351;
    this.cli_indigv = 1;
    this.cli_indsta = "1";

  }

  static fromCONEFACTToCliente(proveedor: ProveedorCONEFACTSUNAT): Cliente {
    const cliente = new Cliente();
    cliente.cli_codcli = proveedor.numeroDocumento;
    cliente.cli_numruc = proveedor.numeroDocumento;
    cliente.cli_nomcli = proveedor.nombre;
    cliente.cli_dircli = proveedor.direccion;
    cliente.cli_nomcom = proveedor.comercial;
    cliente.cli_numtlf = proveedor.telefono;

    return cliente;
  }
}

export class DatoClc {
  CIA_CODCIA:    string;
  CLI_CODCLI:    string;
  TCL_CODTCL :   null;
  ZVE_CODZVE:    null;
  VDE_CODVDE:    string;
  SEC_CODSEC:    null;
  CCL_CODCCL:    null;
  CLC_PORDES:    number;
  CLC_DIAGRA:    number;
  TMO_CODTMO:    null;
  CLC_CREASI:    number;
  CLC_FLAVEN:    number;
  CLC_CODNEM:    null;
  TLP_CODTLP:    null;
  CLI_CODAVA:    null;
  CLI_CODAV1:    null;
  CPA_CODCPA:    null;
  CLC_INDCOD:    null;
  CLC_INDSTA:    string;
  CLC_CODUSE:    string;
  CLC_FECUPD:    Date;
  CLC_CODGRU:    null;
  GCL_CODGCL:    null;
  CLC_INOPED:    number;
  TCC_CODTCC:    null;
  CLI_INDPRD:    string;
  CLC_INDUME:    number;
  VDS_CODVDS:    null;

  constructor() {
    this.CLC_PORDES = 0;
    this.CLC_DIAGRA = 0;
    this.CLC_CREASI = 0;
    this.CLC_FLAVEN = 0;
    this.CLC_INDSTA = "";
    this.CLC_CODUSE = "";
    this.CLC_FECUPD = new Date();
    this.CLC_INOPED = 0;
    this.CLI_INDPRD = "";
    this.CLC_INDUME = 0;
    this.VDE_CODVDE = "";
  }
}

export class DatoReg {
  indage: null;
}

export class DetalleContacto {
  cia_codcia: string;
  cli_codcli: string;
  ccl_codccl: string;
  cca_codcca: string;
  cca_desabr: string;
  ccl_numtlf: string;
  ccl_corele: string;
  ccl_cumple: Date;
  ccl_glosa:  string;
  ccl_nomccl: string;
  ccl_indcob: number;
}

export class DetalleLugarDespacho {
  CIA_CODCIA:       string;
  CLI_CODCLI:       string;
  LDE_CODLDE:       string;
  LDE_NOMLDE:       string;
  LDE_DIRLDE:       string;
  PAI_CODPAI:       string;
  PAI_DESPAI:       string;
  UBI_CODUBI:       string;
  UBI_DESUBI:       string;
  ZVE_CODZVE:       null | string;
  ZVE_DESZVE:       string;
  LDE_INDGUI:       null;
  CTI_CODCTI:       null;
  VDE_CODVDE:       string;
  LDE_CODANT:       string;
  LDE_INDSTA:       string;
  LDE_CODUSE:       string;
  LDE_FECUPD:       Date;
  LDE_ORDVIS:       number;
  LDE_INOTIE:       number;
  TCC_CODTCC:       null;
  LDE_TLFLDE:       string;
  LDE_NOMCON:       string;
  LDE_REFDIR:       string;
  LDE_INDPRI:       number;
  SUC_CODSUC:       string;
  detalle_dias_cds: DetalleDiasCD[];

  constructor() {

    this.LDE_FECUPD = new Date();

  }
}

export class DetalleDiasCD {
  cia_codcia: string;
  cli_codcli: string;
  lde_codlde: string;
  dia_coddia: string;
  dia_coduse: string;
  dia_fecupd: Date;
}

export class DetalleTipserProv {
  cia_codcia: string;
  cli_codcli: string;
  tse_codtse: string;
  tse_descri: string;
  pts_indsta: string;
  pts_fecupd: Date;
  pts_coduse: string;
  correlativo: any;

  constructor() {
    this.pts_indsta = '1';
    this.pts_fecupd = new Date();
  }
}
