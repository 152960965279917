export class TipoOperacion {
  cia_codcia: any;
  tga_codtga: any;
  tga_descri: any;
  tga_coduse: any;
  tga_fecupd: any;
  tga_indsta: string;
  tga_indfij: any;
  fca_codfca: any;
  T30_CODT30: any;
  tga_indrqp: any;
  tdo_codtdo: any;
  tga_indimp: any;
  ccn_codmna: any;
  ccn_codmex: any;
  ccn_impmna: any;
  aau_iteaau: any;
  ccn_codccn: any;
  ccn_descri: any;
  ccs_codccs: any;
  ccs_descri: any;
  aau_poraau: any;
  aau_inddha: any;

  constructor() {
    this.tga_indsta = '1';
    this.tga_fecupd = new Date();
  }
}
