import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Driver, RegistroCompra } from 'src/app/models';
import { Store } from '@ngrx/store';
import { validarPeriodo } from 'src/app/utils/validations/period';
import * as xml2js from 'xml2js';
import { AuthenticationService, ContabilidadService, ConfigurationService, ComprasService, AlmacenService, VentasService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { DetailSaleRecord, SaleRecord } from 'src/app/models/contabilidad/sale_record';
import { formatDateWithDash } from 'src/app/utils/formats/date.format';
import { MatSelect } from '@angular/material/select';
import { MatButton } from '@angular/material/button';
import { roundToDecimals } from 'src/app/utils/formats/round.format';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { orderKeyStringJSON } from 'src/app/utils/sorts/sort';
import { AddProviderComponent } from '@shared/components/dialogs/add-provider/add-provider.component';
import { MatDialog } from '@angular/material/dialog';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { fillTable, searchInTable } from '@utils/tables/table';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { forkObs } from '@utils/observables/fork';
import { TipoOperacionService } from 'src/app/services/api/contabilidad/maestros/tipo-operacion.service';

@Component({
  selector: 'app-sales-record',
  templateUrl: './sales-record.component.html',
  styleUrls: ['./sales-record.component.css'],
})
export class SalesRecordComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  driver: Driver;

  displayedColumns: string[] = ['acciones', 'ldi_corldi', 'ccn_codccn', 'cli_codcli', 'ccs_codccs', 'tdo_codtdo', 'ldi_docref', 'tmo_codtmo', 'ldi_tipcam','ldi_inddha', 'ldi_impnac', 'ldi_impmex', 'ldi_gloldi', 'cco_codcco'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns3: string[] = ['name', 'mn', 'me'];
  dataSource3: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator3: MatPaginator;
  @ViewChild(MatSort) sort3: MatSort;

  monedas: any[] = [];
  subdiarios: any[] = [];
  tiposDocumento: any[] = [];

  usuario: any;
  fecha: Date = new Date();

  minDate: Date;

  year: string;
  month: string;
  LABELS_NAME: INameConstant = NAMES_CONSTANTS;

  saleRecord: SaleRecord;

  isNew: boolean = true;

  valCompra: number;

  @ViewChild('iAno') anoElement: ElementRef;
  @ViewChild('iMes') mesElement: ElementRef;
  @ViewChild('iSubdiario') subdiarioElement: MatSelect;
  @ViewChild('iFecha') fechaElement: ElementRef;
  @ViewChild('iProveedor') proveedorElement: MatSelect;
  @ViewChild('iEmision') emisionElement: ElementRef;
  @ViewChild('iTc') tcElement: ElementRef;
  @ViewChild('iMoneda') monedaElement: MatSelect;
  @ViewChild('iIgv') igvElement: ElementRef;
  @ViewChild('iDerCredito') derCreditoElement: MatSelect;
  @ViewChild('iTipoOperacion') tipoOperacionElement: MatSelect;
  @ViewChild('iClasificacion') clasificacionElement: MatSelect;
  @ViewChild('iTipoDoc') tipoDocElement: MatSelect;
  @ViewChild('iNroDoc') nroDocElement: ElementRef;
  @ViewChild('iAnoDua') anoDuaElement: ElementRef;
  @ViewChild('iSerie') serieElement: ElementRef;
  @ViewChild('iCondicion') condicionElement: MatSelect;
  @ViewChild('iVcto') vctoElement: ElementRef;
  @ViewChild('iCuenta') cuentaElement: MatSelect;
  @ViewChild('iOrdenCompra') ordenCompraElement: MatSelect;
  @ViewChild('iTipoRenta') tipoRentaElement: MatSelect;
  @ViewChild('iGlosa') glosaElement: ElementRef;
  @ViewChild('iTipoDocRef') tipoDocRefElement: MatSelect;
  @ViewChild('iDocRef') docRefElement: ElementRef;
  @ViewChild('iFecRef') fecRefElement: ElementRef;

  // Importes
  @ViewChild('iAfectoCD') afectoCDRefElement: ElementRef;
  @ViewChild('iAfectoSD') afectoSDRefElement: ElementRef;
  @ViewChild('iRenta') rentaRefElement: ElementRef;
  @ViewChild('iValRenta') valRentaRefElement: ElementRef;
  @ViewChild('iAjusteIgv') ajusteIgvRefElement: ElementRef;
  @ViewChild('iImporteSise') importeSiseRefElement: ElementRef;
  @ViewChild('iImporteFise') importeFiseRefElement: ElementRef;
  @ViewChild('iInafecto') inafectoRefElement: ElementRef;
  @ViewChild('iAfecto') afectoRefElement: ElementRef;
  @ViewChild('iValCompra') valCompraRefElement: ElementRef;
  @ViewChild('iImpIgv') impIgvRefElement: ElementRef;
  @ViewChild('iTotal') totalRefElement: ElementRef;
  @ViewChild('iTipoRetencion') tipoRetencionElement: MatSelect;
  @ViewChild('iTipoImpuestoRet') tipoImpuestoRetElement: MatSelect;
  @ViewChild('iTasa') tasaRefElement: ElementRef;
  @ViewChild('iImporte') importeRefElement: ElementRef;
  @ViewChild('iImpPercepcion') impPercepcionRefElement: ElementRef;
  @ViewChild('iDeposito') depositoRefElement: ElementRef;
  @ViewChild('iFecha') fechaRefElement: ElementRef;
  @ViewChild('iFob') fobRefElement: ElementRef;
  @ViewChild('iFlete') fleteRefElement: ElementRef;
  @ViewChild('iSeguro') seguroRefElement: ElementRef;
  @ViewChild('iCif') cifRefElement: ElementRef;
  @ViewChild('iAdvalorem') advaloremRefElement: ElementRef;
  @ViewChild('btnAdd') buttonAddRefElement: MatButton;
  @ViewChild('btnGeneraAsiento') buttonGeneraAsientoRefElement: MatButton;

  cuentasTotales: any[] = [];
  cuentas: any[] = [];
  proveedores: any[] = [];
  auxiliares: any[] = [];
  tiposOperacion: any[] = [];
  tiposRenta: any[] = [];
  condiciones: any[] = [];
  tiposDoc: any[] = [];

  signosctacte: any[] = [];

  @ViewChildren("cuentaTotalSelect") selectCuentas: QueryList<MatSelect>;
  @ViewChildren("descripcionInput") inputDescripcion: QueryList<ElementRef>;
  @ViewChildren("costoSelect") selectCosto: QueryList<MatSelect>;

  montosDebeHaber: any;

  permision: any= {ind_Nuevo: '0'};
  btnName: string = "Registrar";

  cuentasContable: any[] = [];
  tiposImpRetencion: any[] = [];
  tempTiposImpRetencion: any[] = [];
  centros: any[] = [];

  subdiarios$: Subscription;
  tipoOperacion$: Subscription;
  cuentas$: Subscription;
  tiposBienServicio$: Subscription;
  tiposRenta$: Subscription;
  tiposImpuesto$: Subscription;
  tiposDocumento$: Subscription;
  proveedor$: Subscription;
  moneda$: Subscription;
  ordenesCompra$: Subscription;
  condiciones$: Subscription;
  voucher$: Subscription;
  centro$: Subscription;
  auxiliar$: Subscription;
  tipoRetencion$: Subscription;
  tipoImpRetencion$: Subscription;
  loading$: Subscription;

  nameXml: string;

  period$: Subscription;

  constructor(
    private _snackBarService: SnackBarService,
    private _almacenService: AlmacenService,
    private _clienteService: ClienteService,
    private _authService: AuthenticationService,
    private _contabilidadService: ContabilidadService,
    private _tipoOperacionService: TipoOperacionService,
    private _configurationService: ConfigurationService,
    private _activatedRoute: ActivatedRoute,
    private _comprasService: ComprasService,
    private _ventasService: VentasService,
    private _router: Router,
    private store: Store<PavsoState>,
    public dialog: MatDialog
  ) {
    this.saleRecord = new SaleRecord();

    this.montosDebeHaber = [
      {
        name: "DEBE",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "HABER",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "DIFERENCIA",
        mn: 0.00,
        me: 0.00
      }
    ];

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource3 = fillTable(this.montosDebeHaber, this.paginator3, this.sort3);

    this.period$ = this.store.select('period').subscribe(({year, month}) => {
      this.year = year;
      this.saleRecord.ano_codano = year;
      this.month = month;
      this.saleRecord.mes_codmes = month;
    })

    this.minDate = new Date(parseInt(this.year), parseInt(this.month), new Date().getDate());

    this.driver = new Driver();
    this.usuario = this._authService.getUsuarioSistema();
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if(!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })

    this.dataSource3 = new MatTableDataSource([
      {
        name: "Debe",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Haber",
        mn: 0.00,
        me: 0.00
      },
      {
        name: "Diferencia",
        mn: 0.00,
        me: 0.00
      }
    ]);
  }

  setup(): void {
    this._activatedRoute.params.subscribe(
      params => {
        if(!(params["id"] == "0")) {
          this.isNew = false;
          this.btnName = NAMES_CONSTANTS.BTN_UPDATE;
          this._comprasService.mostrarRegistroDeCompra(params["id"]).subscribe(
            registroCompra => {
              // ===== VALORES POR DEFECTO =======
              this.saleRecord.cco_coduse = this.usuario;
              this.saleRecord.cco_indsta = "1";
              this.saleRecord.iri_codiri = "00";

              this.saleRecord.ano_codano = this.year;
              this.saleRecord.mes_codmes = this.month;

              this.saleRecord.dpc_afcder = 0;
              this.saleRecord.dpc_afsder = 0;
              this.saleRecord.dpc_difigv = 0;
              this.saleRecord.dpc_impsis = 0;
              this.saleRecord.dpc_impfis = 0;
              this.saleRecord.cco_impina = 0;
              this.saleRecord.cco_impafe = 0;
              this.saleRecord.cco_impigv = 0;
              this.saleRecord.cco_impdoc = 0;

              this.saleRecord.dpc_tasdet = 0;
              this.saleRecord.dpc_impdet = 0;
              this.saleRecord.cco_impper = 0;
              this.saleRecord.dpc_depdet = 0;
              this.saleRecord.dpc_depdet = 0;

              this.saleRecord.dpc_impfob = 0;
              this.saleRecord.dpc_impfle = 0;
              this.saleRecord.dpc_impseg = 0;
              this.saleRecord.dpc_impcif = 0;
              this.saleRecord.dpc_impadv = 0;



              this.saleRecord.age_codage = null;
              this.saleRecord.cco_aboret = 0
              this.saleRecord.cco_abotra = 0
              this.saleRecord.cco_cbmoti = null
              this.saleRecord.cco_codant = null
              this.saleRecord.cco_code = null
              this.saleRecord.cco_codpag = "P"
              this.saleRecord.cco_condic = null
              this.saleRecord.cco_impabo = 0
              this.saleRecord.cco_impcco = this.saleRecord.cco_impdoc * (-1);
              this.saleRecord.cco_impcor = 0
              this.saleRecord.cco_impret = 0
              this.saleRecord.cco_indtca = ""
              this.saleRecord.cco_movil = ""
              this.saleRecord.cco_numcuo = ""
              this.saleRecord.cco_permna = null
              this.saleRecord.cco_retgas = null
              this.saleRecord.cco_salcco = null
              this.saleRecord.cco_sallba = 0
              this.saleRecord.cco_salres = 0
              this.saleRecord.cia_codcia = this._configurationService.obtenerCompaniaCliente()
              this.saleRecord.cli_coddoc = null
              this.saleRecord.dpc_afepol = 0
              this.saleRecord.dpc_anotri = null
              this.saleRecord.dpc_coduse = this._authService.getUsuarioSistema();
              this.saleRecord.dpc_fecrec = null
              this.saleRecord.dpc_fecupd = new Date();
              this.saleRecord.dpc_impdet = 0
              this.saleRecord.dpc_igvisc = 0
              this.saleRecord.dpc_impdif = 0
              this.saleRecord.dpc_impies = 0
              this.saleRecord.dpc_impisc = 0
              this.saleRecord.dpc_impore = 0
              this.saleRecord.dpc_impper = 0
              this.saleRecord.dpc_inddet = null
              this.saleRecord.dpc_inddet_c = null
              this.saleRecord.dpc_inddif = "0"
              this.saleRecord.dpc_indntc = 0
              this.saleRecord.dpc_indsta = "1"
              this.saleRecord.dpc_mestri = null
              this.saleRecord.dpc_numdua = null
              this.saleRecord.dpc_numpol = null
              this.saleRecord.dpc_otrtri = 0
              this.saleRecord.dpc_parara = null
              this.saleRecord.dpc_perini = null
              this.saleRecord.dpc_reipap = null
              this.saleRecord.dpc_tasies = null
              this.saleRecord.dpc_tasisc = null
              this.saleRecord.dpc_tasore = null
              this.saleRecord.dpc_valare = null
              this.saleRecord.ipi_codipi = null
              this.saleRecord.ldc_corasi = null
              this.saleRecord.ldc_corldc = "00001"
              this.saleRecord.mmo_codmmo = null
              this.saleRecord.oim_numoim = null
              this.saleRecord.sco_codasi = null
              this.saleRecord.suc_codsuc = "01"
              this.saleRecord.dpc_tasren = 0;
              this.saleRecord.dpc_impren = 0;
              this.saleRecord.tdo_docref = "";
              this.saleRecord.cco_numref = "";

              this.saleRecord.cia_codcia = registroCompra.cia_codcia;
              this.saleRecord.cco_codcco = registroCompra.cco_codcco;
              this.saleRecord.cli_codcli = registroCompra.cli_codcli;
              this.saleRecord.cli_coddoc = registroCompra.cli_coddoc;
              this.saleRecord.tdo_codtdo = registroCompra.tdo_codtdo;
              this.saleRecord.cco_numdoc = registroCompra.cco_numdoc;
              this.saleRecord.cco_glocco = registroCompra.cco_glocco;
              this.saleRecord.cco_fecemi = registroCompra.cco_fecemi;
              this.saleRecord.cco_fecven = registroCompra.cco_fecven;
              this.saleRecord.tmo_codtmo = registroCompra.tmo_codtmo;
              this.saleRecord.ccn_codccn = registroCompra.ccn_codccn;
              this.saleRecord.cco_impdoc = registroCompra.cco_impdoc;
              this.saleRecord.cco_impcco = registroCompra.cco_impcco;
              this.saleRecord.cco_salcco = registroCompra.cco_salcco;
              this.saleRecord.cco_sallba = registroCompra.cco_sallba;
              this.saleRecord.cco_salres = registroCompra.cco_salres;
              this.saleRecord.cco_impabo = registroCompra.cco_impabo;
              this.saleRecord.cco_impigv = registroCompra.cco_impigv;
              this.saleRecord.cco_tasigv = registroCompra.cco_tasigv;
              this.saleRecord.cco_impcor = registroCompra.cco_impcor;
              this.saleRecord.cco_impina = registroCompra.cco_impina;
              this.saleRecord.cco_impafe = registroCompra.cco_impafe;
              this.saleRecord.cco_indtca = registroCompra.cco_indtca;
              this.saleRecord.cco_tipcam = registroCompra.cco_tipcam;
              this.saleRecord.cco_codpag = registroCompra.cco_codpag;
              this.saleRecord.mmo_codmmo = registroCompra.mmo_codmmo;
              this.saleRecord.cpa_codcpa = registroCompra.cpa_codcpa;
              this.saleRecord.tdo_docref = registroCompra.tdo_docref;
              this.saleRecord.cco_numref = registroCompra.cco_numref;
              this.saleRecord.ano_codano = registroCompra.ano_codano;
              this.saleRecord.mes_codmes = registroCompra.mes_codmes;
              this.saleRecord.sco_codsco = registroCompra.sco_codsco;
              this.saleRecord.ldc_corldc = registroCompra.ldc_corldc;
              this.saleRecord.sco_codasi = registroCompra.sco_codasi;
              this.saleRecord.ldc_corasi = registroCompra.ldc_corasi;
              this.saleRecord.cco_fecreg = registroCompra.cco_fecreg;
              this.saleRecord.age_codage = registroCompra.age_codage;
              this.saleRecord.cco_abotra = registroCompra.cco_abotra;
              this.saleRecord.cco_codant = registroCompra.cco_codant;
              this.saleRecord.cco_impret = registroCompra.cco_impret;
              this.saleRecord.cco_aboret = registroCompra.cco_aboret;
              this.saleRecord.cco_retgas = registroCompra.cco_retgas;
              this.saleRecord.iri_codiri = registroCompra.iri_codiri;
              this.saleRecord.suc_codsuc = registroCompra.suc_codsuc;
              this.saleRecord.tga_codtga = registroCompra.tga_codtga;
              this.saleRecord.cco_fecref = registroCompra.cco_fecref;
              this.saleRecord.ocm_numocm = registroCompra.ocm_numocm;
              this.saleRecord.cco_numcuo = registroCompra.cco_numcuo;
              this.saleRecord.ipi_codipi = registroCompra.ipi_codipi;
              this.saleRecord.cco_impper = registroCompra.cco_impper;
              this.saleRecord.cco_permna = registroCompra.cco_permna;
              this.saleRecord.cco_movil = registroCompra.cco_movil;
              this.saleRecord.cco_cbmoti = registroCompra.cco_cbmoti;
              this.saleRecord.cco_condic = registroCompra.cco_condic;
              this.saleRecord.cco_code = registroCompra.cco_code;
              this.saleRecord.dpc_indder = registroCompra.dpc_indder;
              this.saleRecord.dpc_fecrec = registroCompra.dpc_fecrec;
              this.saleRecord.dpc_indsta = registroCompra.dpc_indsta;
              this.saleRecord.dpc_fecupd = registroCompra.dpc_fecupd;
              this.saleRecord.dpc_coduse = registroCompra.dpc_coduse;
              this.saleRecord.dpc_tasren = registroCompra.dpc_tasren;
              this.saleRecord.dpc_tasies = registroCompra.dpc_tasies;
              this.saleRecord.dpc_tasore = registroCompra.dpc_tasore;
              this.saleRecord.dpc_impren = registroCompra.dpc_impren;
              this.saleRecord.dpc_impies = registroCompra.dpc_impies;
              this.saleRecord.dpc_impore = registroCompra.dpc_impore;
              this.saleRecord.dpc_difigv = registroCompra.dpc_difigv;
              this.saleRecord.dpc_afcder = registroCompra.dpc_afcder;
              this.saleRecord.dpc_afsder = registroCompra.dpc_afsder;
              this.saleRecord.dpc_impisc = registroCompra.dpc_impisc;
              this.saleRecord.dpc_igvisc = registroCompra.dpc_igvisc;
              this.saleRecord.dpc_tasisc = registroCompra.dpc_tasisc;
              this.saleRecord.dpc_otrtri = registroCompra.dpc_otrtri;
              this.saleRecord.dpc_valare = registroCompra.dpc_valare;
              this.saleRecord.dpc_inddif = registroCompra.dpc_inddif;
              this.saleRecord.dpc_perini = registroCompra.dpc_perini;
              this.saleRecord.dpc_impdif = registroCompra.dpc_impdif;
              this.saleRecord.dpc_numpol = registroCompra.dpc_numpol;
              this.saleRecord.dpc_parara = registroCompra.dpc_parara;
              this.saleRecord.dpc_impfob = registroCompra.dpc_impfob;
              this.saleRecord.dpc_impfle = registroCompra.dpc_impfle;
              this.saleRecord.dpc_impseg = registroCompra.dpc_impseg;
              this.saleRecord.dpc_impcif = registroCompra.dpc_impcif;
              this.saleRecord.dpc_impadv = registroCompra.dpc_impadv;
              this.saleRecord.dpc_reipap = registroCompra.dpc_reipap;
              this.saleRecord.dpc_afepol = registroCompra.dpc_afepol;
              this.saleRecord.dpc_inddet = registroCompra.dpc_inddet;
              this.saleRecord.dpc_inddet_c = registroCompra.dpc_inddet_c;
              this.saleRecord.tim_codtim = registroCompra.tim_codtim;
              this.saleRecord.dpc_tasdet = registroCompra.dpc_tasdet;
              this.saleRecord.dpc_depdet = registroCompra.dpc_depdet;
              this.saleRecord.dpc_impdet = registroCompra.dpc_impdet;
              this.saleRecord.dpc_fecdet = registroCompra.dpc_fecdet;
              this.saleRecord.dpc_indntc = registroCompra.dpc_indntc;
              this.saleRecord.dpc_anotri = registroCompra.dpc_anotri;
              this.saleRecord.dpc_mestri = registroCompra.dpc_mestri;
              this.saleRecord.dpc_impper = registroCompra.dpc_impper;
              this.saleRecord.dpc_numdua = registroCompra.dpc_numdua;
              this.saleRecord.dpc_anodua = registroCompra.dpc_anodua;
              this.saleRecord.oim_numoim = registroCompra.oim_numoim;
              this.saleRecord.sad_codsad = registroCompra.sad_codsad;
              this.saleRecord.dpc_impsis = registroCompra.dpc_impsis;
              this.saleRecord.dpc_impfis = registroCompra.dpc_impfis;
              this.saleRecord.t30_codt30 = registroCompra.t30_codt30;
              this.saleRecord.t31_codt31 = registroCompra.t31_codt31;
              this.saleRecord.detalle = (registroCompra.detalle)? registroCompra.detalle: [];
              this.saleRecord.ordenes = (registroCompra.ordenes)? registroCompra.ordenes: [];

              this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);

              this.calculaTotal();

              this.establecerDebeHaber();

            },
            error => this._snackBarService.showError(error.error.msg, "OK")
          )

        } else {
          this.isNew = true;
          this.btnName = NAMES_CONSTANTS.BTN_STORE;
          this.setDefaultFieldsSaleRecord();
        }
      }
    )
  }

  loadData(): void {
    this.loaderData = true;

    forkObs(
      this._configurationService.getUserPermisions(),
      this._contabilidadService.listarSignoCtaCte(),
      this._comprasService.obtenerCondicionesPago(),
      this._contabilidadService.obtenerSubdiarios(),
      this._tipoOperacionService.obtenerTiposOperacion(),
      this._contabilidadService.listarPlanDeCuentas(),
      this._contabilidadService.obtenerTiposDocumento(),
      this._contabilidadService.listarCentroCostos(),
      this._clienteService.obtenerClientes(),
      this._comprasService.listarTiposImpuestoRetencion()
    ).then(data => {
      this.permision = data[0].find(element => element.usuario == this._authService.getUsuarioSistema().toString().toUpperCase())
      this.signosctacte = data[1];
      this.condiciones = data[2];
      this.subdiarios = data[3].filter(element => element.sco_indcom == 1);
      this.tiposOperacion = data[4];
      this.cuentasTotales = data[5];
      this.cuentasContable = data[5].filter( cuenta=>cuenta.ind_ctacte == "P" );
      this.cuentas = data[5];
      this.tiposDoc = data[6].filter(item => item.indcom == 1);
      this.tiposDoc = data[6].sort(orderKeyStringJSON("TDO_CODTDO"));
      this.centros = data[7];
      this.auxiliares = data[8].filter(cliente => cliente.cli_indtra == 1);
      this.proveedores = data[8].filter(cliente => cliente.cli_indpro == 1);;

      this.tiposImpRetencion = data[9];
      this.tempTiposImpRetencion = data[10];
      this.loaderData = false;
      this.setup();
    }).catch(err => {
      this._snackBarService.showError('Error al obtener maestros', err)
    })

  }

  setDefaultFieldsSaleRecord(): void {
    const now = new Date();

    this.saleRecord.ano_codano = this.year;
    this.saleRecord.mes_codmes = this.month;
    this.saleRecord.cco_tasigv = this.tiposImpRetencion[0].porc_det;

    if(this.year == now.getFullYear().toString() && this.month == (now.getMonth() + 1).toString()) {
      this.saleRecord.cco_fecreg = formatDateWithDash(new Date());
    } else {
      this.saleRecord.cco_fecreg = formatDateWithDash(new Date(parseInt(this.year), parseInt(this.month), 0));
    }

    this.saleRecord.cco_fecemi = moment(); //formatDateWithDash(new Date());
    this.saleRecord.dpc_fecdet = moment();
    this.saleRecord.cco_fecven = moment();

    this.saleRecord.cco_coduse = this.usuario;

    this.saleRecord.detalle = [];
    this.saleRecord.ordenes = [];
    this.saleRecord.tmo_codtmo = "SO";
    this.saleRecord.cco_indsta = "1";
    this.saleRecord.iri_codiri = "00";

    this.saleRecord.ano_codano = this.year;
    this.saleRecord.mes_codmes = this.month;

    this.saleRecord.dpc_afcder = 0;
    this.saleRecord.dpc_afsder = 0;
    this.saleRecord.dpc_difigv = 0;
    this.saleRecord.dpc_impsis = 0;
    this.saleRecord.dpc_impfis = 0;
    this.saleRecord.cco_impina = 0;
    this.saleRecord.cco_impafe = 0;
    this.saleRecord.cco_impigv = 0;
    this.saleRecord.cco_impdoc = 0;

    this.saleRecord.dpc_tasdet = 0;
    this.saleRecord.dpc_impdet = 0;
    this.saleRecord.cco_impper = 0;
    this.saleRecord.dpc_depdet = 0;
    this.saleRecord.dpc_depdet = 0;

    this.saleRecord.dpc_impfob = 0;
    this.saleRecord.dpc_impfle = 0;
    this.saleRecord.dpc_impseg = 0;
    this.saleRecord.dpc_impcif = 0;
    this.saleRecord.dpc_impadv = 0;



    this.saleRecord.age_codage = null;
    this.saleRecord.cco_aboret = 0
    this.saleRecord.cco_abotra = 0
    this.saleRecord.cco_cbmoti = null
    this.saleRecord.cco_codant = null
    this.saleRecord.cco_code = null
    this.saleRecord.cco_codpag = "P"
    this.saleRecord.cco_condic = null
    this.saleRecord.cco_impabo = 0
    this.saleRecord.cco_impcco = this.saleRecord.cco_impdoc * (-1);
    this.saleRecord.cco_impcor = 0
    this.saleRecord.cco_impret = 0
    this.saleRecord.cco_indtca = ""
    this.saleRecord.cco_movil = ""
    this.saleRecord.cco_numcuo = ""
    this.saleRecord.cco_permna = null
    this.saleRecord.cco_retgas = null
    this.saleRecord.cco_salcco = null
    this.saleRecord.cco_sallba = 0
    this.saleRecord.cco_salres = 0
    this.saleRecord.cia_codcia = this._configurationService.obtenerCompaniaCliente()
    this.saleRecord.cli_coddoc = null
    this.saleRecord.dpc_afepol = 0
    this.saleRecord.dpc_anotri = null
    this.saleRecord.dpc_coduse = this._authService.getUsuarioSistema();
    this.saleRecord.dpc_fecrec = null
    this.saleRecord.dpc_fecupd = new Date();
    this.saleRecord.dpc_impdet = 0
    this.saleRecord.dpc_igvisc = 0
    this.saleRecord.dpc_impdif = 0
    this.saleRecord.dpc_impies = 0
    this.saleRecord.dpc_impisc = 0
    this.saleRecord.dpc_impore = 0
    this.saleRecord.dpc_impper = 0
    this.saleRecord.dpc_inddet = null
    this.saleRecord.dpc_inddet_c = null
    this.saleRecord.dpc_inddif = "0"
    this.saleRecord.dpc_indntc = 0
    this.saleRecord.dpc_indsta = "1"
    this.saleRecord.dpc_mestri = null
    this.saleRecord.dpc_numdua = null
    this.saleRecord.dpc_numpol = null
    this.saleRecord.dpc_otrtri = 0
    this.saleRecord.dpc_parara = null
    this.saleRecord.dpc_perini = null
    this.saleRecord.dpc_reipap = null
    this.saleRecord.dpc_tasies = null
    this.saleRecord.dpc_tasisc = null
    this.saleRecord.dpc_tasore = null
    this.saleRecord.dpc_valare = null
    this.saleRecord.ipi_codipi = null
    this.saleRecord.ldc_corasi = null
    this.saleRecord.ldc_corldc = "00001"
    this.saleRecord.mmo_codmmo = null
    this.saleRecord.oim_numoim = null
    this.saleRecord.sco_codasi = null
    this.saleRecord.suc_codsuc = "01"
    this.saleRecord.dpc_tasren = 0;
    this.saleRecord.dpc_impren = 0;
    this.saleRecord.tdo_docref = "";
    this.saleRecord.cco_numref = "";
    this.obtenerTasaCambio();

    setTimeout(() => {
      this.subdiarioElement.focus();
      this.subdiarioElement.open();
    }, 1);
  }

  establecerDebeHaber(): void {
    this.saleRecord.detalle.forEach(element => {
      if(element.ldi_inddha == "D") {
        this.montosDebeHaber[0].mn += element.ldi_impnac;
        this.montosDebeHaber[0].me += element.ldi_impmex;
      } else {
        this.montosDebeHaber[1].mn += element.ldi_impnac;
        this.montosDebeHaber[1].me += element.ldi_impmex;
      }
    });

    this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
    this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

  }

  obtenerTasaCambio(): void {

    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.saleRecord.cco_fecemi)).subscribe(
      response => this.saleRecord.cco_tipcam = response[0].tipcam,
      error => this._snackBarService.showError(error.error.msg, 'OK')
    )
  }

  registrarSeguimiento(f: NgForm): void {
    if(validarPeriodo(new Date(), this.year, this.month)) {

    }
  }

  seleccionarTipoOperacion(tipoOperacion): void {

    let tipoOpe;

    if(tipoOperacion) {
      tipoOpe = this.tiposOperacion.find(element => element.tga_codtga == tipoOperacion.value);
      if(tipoOpe && tipoOpe.ccn_codmna && tipoOpe.ccn_codmex && tipoOpe.ccn_codmna != "" && tipoOpe.ccn_codmex != "") {

        (this.saleRecord.tmo_codtmo == "SO")? this.saleRecord.ccn_codccn = tipoOpe.ccn_codmna: this.saleRecord.ccn_codccn = tipoOpe.ccn_codmex;
      } else {
        let subdiario = this.subdiarios.find(item => item.sco_codsco == this.saleRecord.sco_codsco)
        if(subdiario && subdiario.ccn_codmn != "" && subdiario.ccn_codme != "" && subdiario.ccn_codme && subdiario.ccn_codmn) {
          (this.saleRecord.tmo_codtmo == "SO")? this.saleRecord.ccn_codccn = subdiario.ccn_codmn: this.saleRecord.ccn_codccn = subdiario.ccn_codme;
        }
      }

    }

    if(tipoOpe){
      this.saleRecord.t30_codt30 = tipoOpe["T30_CODT30"];
    }

    setTimeout(() => {
      this.tipoDocElement.open();
      this.tipoDocElement.focus();
    }, 1);
  }

  seleccionarProveedor(event): void {

    setTimeout(() => {
      this.emisionElement.nativeElement.focus();
      this.emisionElement.nativeElement.select();
    }, 1);

  }

  seleccionarCuenta(event): void {
    setTimeout(() => {
      this.glosaElement.nativeElement.focus();
      this.glosaElement.nativeElement.select();
    }, 1);
  }

  seleccionarTipoDoc(event): void {
    let valor;

    if(event) {
      valor = event.value;
      let tipo = this.tiposDoc.find(item => item.TDO_CODTDO == valor);

      this._almacenService.listarSerieDocumentos(valor).subscribe(
        series => {
          if(series && series.length > 0) {
            this.saleRecord.cco_numdoc = `${series[0].sdo_codsdo}-`;
            this.saleRecord.sad_codsad = series[0].sdo_codsdo;
            setTimeout(() => {
              this.nroDocElement.nativeElement.focus();
              this.nroDocElement.nativeElement.select();
            }, 1);
            return;
          }

          setTimeout(() => {
            this.serieElement.nativeElement.focus();
            this.serieElement.nativeElement.select();
          }, 1);

        },
        error => this._snackBarService.showError(error.error.msg, "OK")
      )
    } else {
      this.nroDocElement.nativeElement.focus()
      this.nroDocElement.nativeElement.select()
    }

  }

  seleccionarSubdiario(event): void {
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.fechaElement.nativeElement.focus();
      this.fechaElement.nativeElement.select();
    },1);
  }

  establecerInafecto(): void {
    setTimeout(() => {
      this.afectoRefElement.nativeElement.select();
    }, 1);
  }

  establecerAfecto(): void {
    setTimeout(() => {
      this.tipoRetencionElement.focus();
    }, 1);
  }

  establecerSerie(): void {
    if(this.saleRecord.cco_numdoc && this.saleRecord.cco_numdoc.lenght > 4) {
      this.saleRecord.cco_numdoc = this.saleRecord.sad_codsad + '-' + this.saleRecord.cco_numdoc.substring(this.saleRecord.sad_codsad.length + 1, this.saleRecord.cco_numdoc.length + 1);
    } else {
      this.saleRecord.cco_numdoc = this.saleRecord.sad_codsad + '-'
    }

    setTimeout(() => {
      this.nroDocElement.nativeElement.focus();
      this.nroDocElement.nativeElement.select();
    }, 1);
  }

  cambiarFecha(event): void {
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.proveedorElement.open();
      this.proveedorElement.focus();
    },1);
  }

  cambiarFechaEmision(event): void {

    this._ventasService.obtenerTipoCambio(formatDateWithDash(event.value._d)).subscribe(
      response => {
        this.saleRecord.cco_tipcam = response[0].tipcam;

        if (this.saleRecord.cco_tipcam == 0) {
          setTimeout(()=>{
            this.tcElement.nativeElement.focus();
            this.tcElement.nativeElement.select();
          },1)
          return;
        }

        setTimeout(() => {
          this.monedaElement.open();
          this.monedaElement.focus();
        }, 1);

      },

    )
  }

  establecerEmision(): void {
    this._ventasService.obtenerTipoCambio(formatDateWithDash(this.saleRecord.cco_fecemi)).subscribe(
      response => {
        this.saleRecord.cco_tipcam = response[0].tipcam;

        if (this.saleRecord.cco_tipcam == 0) {
          setTimeout(()=>{
            this.tcElement.nativeElement.focus();
            this.tcElement.nativeElement.select();
          },1)
          return;
        }

        setTimeout(() => {
          this.monedaElement.open();
          this.monedaElement.focus();
        }, 1);

      },

    )

  }

  establecerFecha(): void {
    setTimeout(() => {
      this.proveedorElement.open();
      this.proveedorElement.focus();
    }, 1);
  }

  establecerTC(): void {
    setTimeout(() => {
      this.vctoElement.nativeElement.focus();
      this.vctoElement.nativeElement.select();
    }, 1);
  }

  cambiarFechaVcto(event): void {
    setTimeout(() => {
      this.monedaElement.open();
      this.monedaElement.focus();
    }, 1);
  }

  establecerVcto(): void {
    setTimeout(() => {
      this.cuentaElement.open();
      this.cuentaElement.focus();
    }, 1);
  }

  autocompletarNroDoc(): void {
    if(this.saleRecord.cco_numdoc.length == 4) {
      this.saleRecord.cco_numdoc = this.saleRecord.cco_numdoc + '-';
    }
  }

  establecerNroDoc(event): void {

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    let numero = zeroPad(this.saleRecord.cco_numdoc.substring(5, this.saleRecord.cco_numdoc.length + 1), 8); // "000005"

    this.saleRecord.cco_numdoc = this.saleRecord.cco_numdoc.substring(0, 5) + numero;
    setTimeout(() => {
      this.glosaElement.nativeElement.focus();
      this.glosaElement.nativeElement.select();
    }, 1);
  }

  seleccionarMoneda(event): void {
    setTimeout(() => {
      this.tipoOperacionElement.open();
      this.tipoOperacionElement.focus();
    }, 1);
  }

  establecerIgv(): void {
    setTimeout(() => {
      this.derCreditoElement.open();
      this.derCreditoElement.focus();
    }, 1);
  }

  establecerGlosa(): void {
    setTimeout(() => {
      this.inafectoRefElement.nativeElement.focus();
      this.inafectoRefElement.nativeElement.select();
    }, 1);
  }

  calculaTotal(): void {
    this.valCompra = this.saleRecord.cco_impafe + this.saleRecord.cco_impina + this.saleRecord.dpc_impadv;
    this.saleRecord.dpc_impcif = this.saleRecord.dpc_impfob + this.saleRecord.dpc_impfle + this.saleRecord.dpc_impseg;
    this.saleRecord.cco_impdoc = this.saleRecord.cco_impafe + this.saleRecord.cco_impina + this.saleRecord.cco_impigv - this.saleRecord.dpc_impren + this.saleRecord.cco_impper + this.saleRecord.dpc_impfis + this.saleRecord.dpc_impsis;
    const ln_importe = roundToDecimals(((this.saleRecord.cco_impdoc - this.saleRecord.cco_impina) * this.saleRecord.dpc_tasdet) / 100, 2);
    this.saleRecord.dpc_impdet = (this.saleRecord.tmo_codtmo == "SO")? ln_importe: roundToDecimals(ln_importe * this.saleRecord.cco_tipcam, 1);
    this.saleRecord.cco_impigv = roundToDecimals(this.valCompra * (this.saleRecord.cco_tasigv / 100), 2)
    this.saleRecord.cco_impdoc = this.saleRecord.cco_impina + this.saleRecord.cco_impafe + this.saleRecord.cco_impigv;
  }

  agregarItem(): void {
    let detailItem = new DetailSaleRecord();

    detailItem.cia_codcia = this._configurationService.obtenerCompaniaCliente();
    detailItem.ano_codano = this.year;
    detailItem.mes_codmes = this.month;
    detailItem.ldi_corldi = `0000${this.saleRecord.detalle.length + 1}`;
    detailItem.tmo_codtmo = "SO";
    detailItem.ldi_inddha = "D";
    detailItem.tdo_codtdo = "FAC";
    detailItem.ldi_impnac = 0;
    detailItem.ldi_impmex = 0;
    detailItem.ldi_tcameu = 0;
    detailItem.ldi_inddes = 0;
    detailItem.fca_codfca = "";
    detailItem.ldi_anoccl = this.year;
    detailItem.ldi_mesccl = this.month;
    detailItem.ldi_fecemi = this.saleRecord.cco_fecemi;
    detailItem.ldi_fecven = this.saleRecord.cco_fecven;
    detailItem.ldi_impsal = 0;
    detailItem.ldi_indccl = 0;
    detailItem.ldi_indcco = "N";
    detailItem.ldi_inddes = 0;
    detailItem.ldi_mesccl = "";
    detailItem.sco_codsco = this.saleRecord.sco_codsco;
    detailItem.suc_codsuc = "01";
    detailItem.tdo_docref = "";
    detailItem.vde_codvde = "";
    detailItem.ldi_tipcam = this.saleRecord.cco_tipcam;
    detailItem.ldi_gloldi = this.saleRecord.cco_glocco;
    detailItem.ldi_tipcam = this.saleRecord.cco_tipcam;

    this.saleRecord.detalle.push(detailItem);
    this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);

    this.saleRecord.detalle.forEach((element, key) => {
      element.ldi_corldi = (key > 9)?`000${key + 1}`: `0000${key + 1}`;
    })
    setTimeout(() => {
      // this.cuentaTotalSelect.open();
      this.selectCuentas.last.open();
      this.selectCuentas.last.focus();
    }, 1);

  }

  confirmarNuevoItem(item: DetailSaleRecord): void {
    if(
      item.ccn_codccn &&
      item.tdo_codtdo &&
      item.ldi_docref &&
      item.tmo_codtmo &&
      item.ldi_inddha &&
      item.ldi_impnac &&
      item.ldi_impmex &&
      item.ldi_gloldi
      ) {
      item.isEditing = !item.isEditing;
      this.montosDebeHaber[0].mn = 0;
      this.montosDebeHaber[0].me = 0;
      this.montosDebeHaber[1].mn = 0;
      this.montosDebeHaber[1].me = 0;
      this.montosDebeHaber[2].mn = 0;
      this.montosDebeHaber[2].me = 0;

      this.saleRecord.detalle.forEach(element => {
        if(element.ldi_inddha == "D") {
          this.montosDebeHaber[0].mn += element.ldi_impnac;
          this.montosDebeHaber[0].me += element.ldi_impmex;
        } else {
          this.montosDebeHaber[1].mn += element.ldi_impnac;
          this.montosDebeHaber[1].me += element.ldi_impmex;
        }
      });

      this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
      this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

      this.buttonAddRefElement.focus();
    } else {
      this._snackBarService.showError("Es necesario completar todos los campos", "OK");
    }
  }

  nuevo(): void {
    this._router.navigate(['/registro-de-venta/0']);
    this.isNew = true;
    this.btnName = NAMES_CONSTANTS.BTN_STORE;
    this.saleRecord = new RegistroCompra();

    this.dataSource = fillTable([], this.paginator, this.sort);

    this.dataSource = fillTable(this.montosDebeHaber, this.paginator3, this.sort3);

    this.setDefaultFieldsSaleRecord();
  }


  filaSeleccionada(row, event): void {

  }

  seleccionarTipoDocRef(event): void {}

  xmlInputChange(fileInputEvent: any): void {
    this.loaderData = true;

    let file = fileInputEvent.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (evt) => {
        const xmlData: string = (evt as any).target.result;

        const parser = new xml2js.Parser({ strict: false, trim: true });
        parser.parseString(xmlData, (err, result) => {
          let key = Object.keys(result)[0];
          if(key == "INVOICE") {
            this.saleRecord.cli_codcli = result[key]["CAC:ACCOUNTINGSUPPLIERPARTY"][0]["CAC:PARTY"][0]["CAC:PARTYIDENTIFICATION"][0]["CBC:ID"][0]["_"];
            this.saleRecord.cco_fecemi = result[key]["CBC:ISSUEDATE"][0];
            this.saleRecord.cco_fecven = result[key]["CBC:ISSUEDATE"][0];
            this.saleRecord.tmo_codtmo = (result[key]["CBC:DOCUMENTCURRENCYCODE"][0] == 'PEN')? 'SO': 'DO';
            this.saleRecord.cco_impafe = result[key]["CAC:LEGALMONETARYTOTAL"][0]["CBC:LINEEXTENSIONAMOUNT"][0]["_"];
            this.saleRecord.cco_impigv = result[key]["CAC:TAXTOTAL"][0]["CBC:TAXAMOUNT"][0]["_"];

            this.saleRecord.cco_impdoc = result[key]["CAC:LEGALMONETARYTOTAL"][0]["CBC:PAYABLEAMOUNT"][0]["_"];
            this.saleRecord.cco_glocco = result[key]["CAC:INVOICELINE"][0]["CAC:ITEM"][0]["CBC:DESCRIPTION"][0];

            let data_to_validate = {
              cli_codcli: this.saleRecord.cli_codcli
            }
            this.validaciónCargaXML(data_to_validate);
            this.loaderData = false;
          } else {
            this.loaderData = false;
            this._snackBarService.showError("Debe de cargar un XML de Factura", "OK");
          }
        });
    };
    reader.readAsText(file);

    this.nameXml = fileInputEvent.target.files[0].name;
  }

  validaciónCargaXML({cli_codcli}): void {
    const proveedor = this.proveedores.find(item => item.CODIGO == this.saleRecord.cli_codcli)

    if(!proveedor) {
      this._snackBarService.showError('No se encontró al proveedor en nuestros Registros', 'OK');
      fetch(`http://162.248.55.12/pav-facelectronica/public/api/consulta/ruc/${cli_codcli}`)
      .then(resolve => resolve.json())
      .then(result => {

        const dialogRef = this.dialog.open(AddProviderComponent, {
          width: 'auto',
          data: result,
        });

        dialogRef.afterClosed().subscribe(result => {
          this._clienteService.obtenerClientes().subscribe(
            auxiliares => {
              this.auxiliares = auxiliares;
              this.proveedores = auxiliares;

              setTimeout(() => {
                this.tipoOperacionElement.focus();
                this.tipoOperacionElement.open();
              }, 1);
            },
            error => this._snackBarService.showError(error.error.msg, "OK")
          )
        });

      })
    } else {
      setTimeout(() => {
        this.tipoOperacionElement.focus();
        this.tipoOperacionElement.open();
      }, 1);
    }
  }

  quitarItem(id): void {
    let details = [];
    this.saleRecord.detalle.forEach(element => {
      if(element.ldi_corldi != id) {
        details.push(element)
      }
    });

    this.saleRecord.detalle = details;
    this.dataSource = fillTable(details, this.paginator, this.sort);

    this.saleRecord.detalle.forEach((element, key) => {
      element.ldi_corldi = `0000${key + 1}`;
    });
  }

  generarAsiento(): void {
    if(this.saleRecord.detalle.length == 0) {

      let [ps_cia, ps_suc, ps_sco, ps_codtga, ps_cliente] = [this._configurationService.obtenerCompaniaCliente(), "01", this.saleRecord.sco_codsco, this.saleRecord.tga_codtga, this.saleRecord.cli_codcli];

      // Verificar percepcion
      let li_perigv = 0;
      const tipoDoc = this.tiposDoc.find(item => item.TDO_CODTDO == this.saleRecord.tdo_codtdo);
      if(!tipoDoc) {
        this._snackBarService.showError("Establecer un Tipo de Documento", "OK")
        return;
      }
      li_perigv = tipoDoc.TDO_PERIGV;

      if(this.saleRecord.cco_codcco) {
        this.saleRecord.detalle.forEach(item => {
          if (item.tdo_codtdo == 'DPP' && item.cco_codcco) {
            this._snackBarService.showError("No se puede generar asiento, tiene cuenta corriente de Detracción ...Modifique manualmente.", "OK");
            return;
          }
        });
      }

      if(this.saleRecord.tga_codtga) {
        this.saleRecord.detalle = [];
      } else {
        this._snackBarService.showError("Es necesario ingresar el tipo de operación", "OK");
        return;
      }

      if(!this.saleRecord.sco_codsco) {
        this._snackBarService.showError("Ingresar el subdiario contable", "OK");
        return;
      }

      let tipoOp = this.tiposOperacion.find(element => element.tga_codtga == "0001");

      if(!tipoOp.detalle) {
        this._snackBarService.showError("Advertencia : No existe detalle de configuración de asiento automático para la transacción " + this.saleRecord.tga_codtga, "Ok");
        return;
      }

      let ps_inddom;

      if(!this.saleRecord.cli_codcli) {
        this._snackBarService.showError("Error: No existe el proveedor del documento", "OK");
        return;
      } else {
        const prov = this.proveedores.find(element => element.CODIGO == this.saleRecord.cli_codcli);
        if(prov) {
          ps_inddom = prov.IND_DOMICILIADO;
        }
      }

      // Si la cuenta contable de provisión está configurada propiamente para la transaccion
      let ps_ccn_codmn, ps_ccn_codme;

      let tipoOpe = this.tiposOperacion.find(element => element.tga_codtga == this.saleRecord.tga_codtga)

      const subdiario = this.subdiarios.find(element => element.sco_codsco == this.saleRecord.sco_codsco)

      if(tipoOpe.ccn_codmna && tipoOpe.ccn_codmex) {
        ps_ccn_codmn = tipoOpe.ccn_codmna;
        ps_ccn_codme = tipoOpe.ccn_codmex;
      } else {
        ps_ccn_codmn = subdiario.ccn_codmn;
        ps_ccn_codme = subdiario.ccn_codme;
      }

      if(this.saleRecord.ccn_codccn) {
        const cuenta = this.cuentas.find(element => element.cuenta == this.saleRecord.ccn_codccn)

        if(this.saleRecord.tmo_codtmo == cuenta.moneda) {
          ps_ccn_codmn = (this.saleRecord.tmo_codtmo == "SO")? this.saleRecord.ccn_codccn: this.saleRecord.ccn_codccn;

        } else {
          this._snackBarService.showError("Error: Moneda de Cuenta por Pagar no corresponde Moneda de la Cuenta Contable " + this.saleRecord.ccn_codccn, "OK");
          return;
        }
      } else {

        this.saleRecord.ccn_codccn = (this.saleRecord.tmo_codtmo == "SO")? ps_ccn_codmn: ps_ccn_codme;

      }

      // ** Valida cuenta contable de provision
      if(this.saleRecord.tmo_codtmo == "SO" && !ps_ccn_codmn) {
        this._snackBarService.showError(`Error: No existe la cuenta contable de registro de provisión
        Verifique la cuentas contables asignadas para el Subdiario ${this.saleRecord.sco_codsco}`, "OK");
        return;
      }

      if(this.saleRecord.tmo_codtmo == "DO" && !ps_ccn_codme) {
        this._snackBarService.showError(`Error: No existe la cuenta contable de registro de provisión.
        "Verifique la cuentas contables asignadas para el Subdiario ${this.saleRecord.sco_codsco}`, "OK");
      }

      // * por regularizar
      let ps_igvsde = '401121' //&&tmpval.cct_igvsde
      let ps_igvndo = '401121' //&&tmpval.cct_igvndo
      let ls_ndoapl = '401121' //&&tmpval.cct_ndoapl
      let ls_ndoret = '401141' //&&tmpval.cct_ndoret
      let ls_perigv = '401117' //&&tmpval.cct_perigv

      // *** Contabiliza cuenta 42
      let ps_cuenta, pn_impnac, pn_impmex;
      let ps_tmo_mn = "SO";

      if( this.saleRecord.tmo_codtmo == ps_tmo_mn) {
        ps_cuenta= ps_ccn_codmn
        pn_impnac = this.saleRecord.cco_impdoc;
        pn_impmex = pn_impnac/this.saleRecord.cco_tipcam;
      } else {
        ps_cuenta= ps_ccn_codme
        pn_impmex = this.saleRecord.cco_impdoc;
        pn_impnac = pn_impmex*this.saleRecord.cco_tipcam;
      }

      // *--- Valida documento
      let ps_valor = ps_cuenta.substring(0,1);
      let ps_codtdo = this.saleRecord.tdo_codtdo;

      let signo = this.signosctacte.find(item => item.tdo_codtdo == this.saleRecord.tdo_codtdo && item.cla_codcla == ps_valor);

      if(!signo) {
        this._snackBarService.showError(`Error: Código de Documento ${ps_codtdo} no esta relacionado con la Clase Contable ${ps_valor}, cuenta ${ps_cuenta}`, "OK");
        return;
      }

      let li_item = 1;
      ps_cuenta = this.saleRecord.ccn_codccn;

      if(ps_cuenta) {

        ps_valor = this.saleRecord.cco_codcco;
        let itemDetalle = new DetailSaleRecord();
        itemDetalle.ldi_corldi = '00000' + li_item;
        itemDetalle.ccn_codccn = ps_cuenta;
        itemDetalle.ccs_codccs = '';
        itemDetalle.ldi_gloldi = this.saleRecord.cco_glocco;
        itemDetalle.tdo_codtdo = this.saleRecord.tdo_codtdo;
        itemDetalle.ldi_docref = this.saleRecord.cco_numdoc;
        itemDetalle.tmo_codtmo = this.saleRecord.tmo_codtmo;
        itemDetalle.cli_codcli = this.saleRecord.cli_codcli;
        itemDetalle.ldi_tipcam = this.saleRecord.cco_tipcam;
        itemDetalle.ldi_inddha = (signo == -1)?'H': 'D';
        itemDetalle.ldi_impnac = pn_impnac;
        itemDetalle.ldi_impmex = pn_impmex;
        itemDetalle.ldi_indcco = 'N';
        itemDetalle.isEditing = true;

        this.saleRecord.detalle.push(itemDetalle);
        this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);
      }

      //  *** Contabiliza cuenta 40 de Igv
      if(li_perigv != 1) {

        ps_cuenta = (tipoOpe.ccn_impmna)? tipoOpe.ccn_impmna: subdiario.ccn_codimp;

      } else {
        ps_cuenta = ls_perigv;
      }

      let ps_indder = this.saleRecord.dpc_indder;

      if(ps_cuenta && (ps_indder == '1' || ps_indder == '3') && this.saleRecord.cco_impigv > 0){
        if(this.saleRecord.tmo_codtmo == ps_tmo_mn) {
          pn_impnac = ps_indder == '1'? this.saleRecord.cco_impigv: this.saleRecord.cco_impigv*this.saleRecord.cco_tasigv/100;

          pn_impmex = pn_impnac/this.saleRecord.cco_tipcam
        } else {

          pn_impmex = (ps_indder == '1')? this.saleRecord.cco_impigv: this.saleRecord.cco_impigv*this.saleRecord.cco_tasigv/100;

          pn_impnac = pn_impmex/this.saleRecord.cco_tipcam

        }

        li_item++;
        ps_valor = this.saleRecord.cco_codcco;
        let itemDetalle2 = new DetailSaleRecord();
        itemDetalle2.ldi_corldi = `0000${li_item}`;
        itemDetalle2.ccn_codccn = ps_cuenta;
        itemDetalle2.ccs_codccs = '';
        itemDetalle2.ldi_gloldi = this.saleRecord.cco_glocco;
        itemDetalle2.tdo_codtdo = this.saleRecord.tdo_codtdo;
        itemDetalle2.ldi_docref = this.saleRecord.cco_numdoc;
        itemDetalle2.tmo_codtmo = this.saleRecord.tmo_codtmo;
        itemDetalle2.ldi_tipcam = this.saleRecord.cco_tipcam;
        itemDetalle2.ldi_inddha = (signo == -1)? 'D': 'H';
        itemDetalle2.ldi_impnac = roundToDecimals(pn_impnac, 2);
        itemDetalle2.ldi_impmex = roundToDecimals(pn_impmex, 2);
        itemDetalle2.ldi_impnac = 'N';
        itemDetalle2.isEditing = true;

        this.saleRecord.detalle.push(itemDetalle2);
        this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);
      }

      // *** Contabiliza cuenta de Igv sin derecho
      ps_cuenta = ps_igvsde

      if(ps_cuenta && (ps_indder == '2' || ps_indder == '3') && this.saleRecord.cco_impigv > 0) {
        if(this.saleRecord.tmo_codtmo == ps_tmo_mn) {
          if(ps_indder == '2') {
            pn_impnac = this.saleRecord.cco_impigv;
          } else {
            pn_impnac = this.saleRecord.cco_impigv - (this.saleRecord.dpc_afcder * this.saleRecord.cco_tasigv/100); // redondesar a 2
          }
          pn_impmex = pn_impnac/this.saleRecord.cco_tipcam

        } else {
          if(ps_indder == '2') {
            pn_impmex = this.saleRecord.cco_impigv;
          } else {
            pn_impmex = this.saleRecord.cco_impigv - (this.saleRecord.dpc_afcder * this.saleRecord.cco_tasigv/100); // redondesar a 2
          }
          pn_impnac = pn_impmex/this.saleRecord.cco_tipcam
        }

          li_item++;
          ps_valor = this.saleRecord.cco_codcco;
          let itemDetalle3 = new DetailSaleRecord();
          itemDetalle3.ldi_corldi = `0000${li_item}`;
          itemDetalle3.ccn_codccn = ps_cuenta;
          itemDetalle3.ccs_codccs = '';
          itemDetalle3.ldi_gloldi = this.saleRecord.cco_glocco;
          itemDetalle3.tdo_codtdo = this.saleRecord.tdo_codtdo;
          itemDetalle3.ldi_docref = this.saleRecord.cco_numdoc;
          itemDetalle3.tmo_codtmo = this.saleRecord.tmo_codtmo;
          itemDetalle3.ldi_tipcam = this.saleRecord.cco_tipcam;
          itemDetalle3.ldi_inddha = (signo == -1)? 'D': 'H';
          itemDetalle3.ldi_impnac = roundToDecimals(pn_impnac, 2);
          itemDetalle3.ldi_impmex = roundToDecimals(pn_impmex, 2);
          itemDetalle3.ldi_impnac = 'N';
          itemDetalle3.isEditing = true;

          this.saleRecord.detalle.push(itemDetalle3);
          this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);

      }

      let valCompra = this.saleRecord.cco_impina + this.saleRecord.cco_impafe;
      // *** Contabiliza cuenta 40 de Igv Para no domiciliados
      ps_cuenta = ps_igvndo;
      if(this.saleRecord.tmo_codtmo == ps_tmo_mn) {
        pn_impnac = valCompra * this.saleRecord.cco_tasigv / 100
        pn_impmex = pn_impnac / this.saleRecord.cco_tipcam
      } else {
        pn_impmex = valCompra * this.saleRecord.cco_tasigv / 100
        pn_impnac = pn_impmex / this.saleRecord.cco_tipcam
      }

      if(ps_cuenta && ps_inddom == 0 && (pn_impnac > 0 || pn_impmex > 0) && ps_indder == '4') {
        li_item++;

        ps_valor = this.saleRecord.cco_codcco;
        let itemDetalle4 = new DetailSaleRecord();

        itemDetalle4.ldi_corldi = `0000${li_item}`;
        itemDetalle4.ccn_codccn = ps_cuenta;
        itemDetalle4.ccs_codccs = '';
        itemDetalle4.ldi_gloldi = this.saleRecord.cco_glocco;
        itemDetalle4.tdo_codtdo = this.saleRecord.tdo_codtdo;
        itemDetalle4.ldi_docref = this.saleRecord.cco_numdoc;
        itemDetalle4.tmo_codtmo = this.saleRecord.tmo_codtmo;
        itemDetalle4.ldi_tipcam = this.saleRecord.cco_tipcam;
        itemDetalle4.ldi_inddha = (signo.sig_indsig == -1)? 'D': 'H';
        itemDetalle4.ldi_impnac = roundToDecimals(pn_impnac, 2);
        itemDetalle4.ldi_impmex = roundToDecimals(pn_impmex, 2);
        itemDetalle4.ldi_indcco = 'N';
        itemDetalle4.isEditing = true;

        this.saleRecord.detalle.push(itemDetalle4);
        this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);

      }

      // *** 2.-Contabiliza cuenta 401120 de Igv Por no domiciliados
      ps_cuenta = ls_ndoapl;
      if (this.saleRecord.tmo_codtmo == ps_tmo_mn){
        pn_impnac = valCompra*this.saleRecord.cco_tasigv/100;
        pn_impmex = pn_impnac/this.saleRecord.cco_tipcam;
      } else {
        pn_impmex = valCompra*this.saleRecord.cco_tasigv/100;
        pn_impnac = pn_impmex*this.saleRecord.cco_tipcam;
      }

      if(ps_cuenta && ps_inddom == 0 && (pn_impnac > 0 || pn_impmex > 0) && ps_indder == '4') {
        li_item++;
        ps_valor = this.saleRecord.cco_codcco;
        let itemDetalle = new DetailSaleRecord();

        itemDetalle.ldi_corldi = `0000${li_item}`;
        itemDetalle.ccn_codccn = ps_cuenta;
        itemDetalle.ccn_codccn = '';
        itemDetalle.ldi_gloldi = this.saleRecord.cco_glocco;
        itemDetalle.tdo_codtdo = this.saleRecord.tdo_codtdo;
        itemDetalle.ldi_docref = this.saleRecord.cco_numdoc;
        itemDetalle.tmo_codtmo = this.saleRecord.tmo_codtmo;
        itemDetalle.ldi_tipcam = this.saleRecord.cco_tipcam;
        itemDetalle.ldi_inddha = (signo.sig_indsig == -1)? 'H': 'D';
        itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
        itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
        itemDetalle.ldi_indcco = 'N';
        itemDetalle.isEditing = true;

        this.saleRecord.detalle.push(itemDetalle);
        this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);

      }

      // *** Contabiliza cuenta 40 de Renta


      ps_cuenta == '40172001';

      if(this.saleRecord.tmo_codtmo == ps_tmo_mn) {
        pn_impnac = this.saleRecord.dpc_impren;
        pn_impmex = pn_impnac / this.saleRecord.cco_tipcam;
      } else {
        pn_impmex = this.saleRecord.dpc_impren;
        pn_impnac = pn_impmex*this.saleRecord.cco_tipcam;
      }

      if(ps_cuenta && this.saleRecord.dpc_impren > 0) {
        li_item++;
        ps_valor = this.saleRecord.cco_codcco;

        let itemDetalle = new DetailSaleRecord();
        itemDetalle.ldi_corldi = `0000${li_item}`;
        itemDetalle.ccn_codccn = ps_cuenta;
        itemDetalle.ccs_codccs = '' ;
        itemDetalle.ldi_gloldi = this.saleRecord.cco_glocco;
        itemDetalle.tdo_codtdo = this.saleRecord.tdo_codtdo;
        itemDetalle.ldi_docref = this.saleRecord.cco_numdoc;
        itemDetalle.tmo_codtmo = this.saleRecord.tmo_codtmo;
        itemDetalle.ldi_tipcam = this.saleRecord.cco_tipcam;
        itemDetalle.ldi_inddha = (signo.sig_indsig == -1)?'H':'D';
        itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
        itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
        itemDetalle.ldi_indcco = 'N'
        itemDetalle.isEditing = true;

        this.saleRecord.detalle.push(itemDetalle);
        this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);
      }

      let ps_codccs, pn_porcen;

      // *** Contabiliza cuenta Gasto
      let pn_valvta = valCompra;
      if(pn_valvta != 0) {
        if(tipoOpe.detalle){
          tipoOpe.detalle.forEach(item => {
            ps_cuenta = item.ccn_codccn;
            ps_codccs = item.ccs_codccs;
            pn_porcen = (item.aau_poraau)? item.aau_poraau: 0;

            if(this.saleRecord.tmo_codtmo == ps_tmo_mn) {
              pn_impnac = pn_valvta*pn_porcen/100;
              pn_impmex = pn_impnac/this.saleRecord.cco_tipcam;
            } else {
              pn_impmex = pn_valvta * pn_porcen/100;
              pn_impmex = pn_impmex * this.saleRecord.cco_tipcam;
            }

            if(ps_cuenta) {
              li_item++;
              let itemDetalle = new DetailSaleRecord();

              itemDetalle.ldi_corldi = `0000${li_item}`;
              itemDetalle.ccn_codccn = ps_cuenta;
              itemDetalle.ccs_codccs = ps_codccs;
              itemDetalle.ldi_gloldi = this.saleRecord.cco_glocco;
              itemDetalle.tmo_codtmo = this.saleRecord.tmo_codtmo;
              itemDetalle.tdo_codtdo = this.saleRecord.tdo_codtdo;
              itemDetalle.ldi_docref = this.saleRecord.cco_numdoc;
              itemDetalle.cli_codcli = this.saleRecord.cli_codcli;
              itemDetalle.ldi_tipcam = this.saleRecord.cco_tipcam;
              itemDetalle.ldi_inddha = (signo.sig_indsig == -1)? 'D': 'H';
              itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
              itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
              itemDetalle.ldi_indcco = 'N';
              itemDetalle.isEditing = true;

              this.saleRecord.detalle.push(itemDetalle);
              this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);
            }
          });
        }
      }

      // ***************DETRACCION*****************
      // ***Contabilidad cuenta 42 de Detracción***

      if(this.saleRecord.dpc_impdet > 0 && this.saleRecord.tim_codtim != 'PER') {
        li_item++;
        ps_cuenta = this.saleRecord.ccn_codccn;
        pn_impnac = this.saleRecord.dpc_impdet;
        pn_impmex = this.saleRecord.dpc_impdet / this.saleRecord.cco_tipcam;

        if(ps_cuenta.length > 0 && ps_cuenta) {
          ps_valor = this.saleRecord.cco_codcco;

          let itemDetalle = new DetailSaleRecord();
          itemDetalle.cco_codcco = ps_valor;
          itemDetalle.ldi_corldi = `0000${li_item}`;
          itemDetalle.ccn_codccn = ps_cuenta;
          itemDetalle.ccs_codccs = '';
          itemDetalle.ldi_gloldi = this.saleRecord.cco_glocco;
          itemDetalle.tdo_codtdo = this.saleRecord.tdo_codtdo;
          itemDetalle.ldi_docref = this.saleRecord.cco_numdoc;
          itemDetalle.tmo_codtmo = this.saleRecord.tmo_codtmo;
          itemDetalle.cli_codcli = this.saleRecord.cli_codcli;
          itemDetalle.ldi_tipcam = this.saleRecord.cco_tipcam;
          itemDetalle.ldi_inddha = (signo.sig_indsig == -1)? 'D': 'H';
          itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
          itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
          itemDetalle.ldi_indcco = 'N';
          itemDetalle.isEditing = true;

          this.saleRecord.detalle.push(itemDetalle);
          this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);
        }

        if(subdiario.ccn_coddet) {
          li_item++;
          ps_cuenta = subdiario.ccn_coddet;

          let itemDetalle = new DetailSaleRecord();
          itemDetalle.ldi_corldi = `0000${li_item}`;
          itemDetalle.ccn_codccn = ps_cuenta;
          itemDetalle.ccs_codccs = '';
          itemDetalle.ldi_gloldi = this.saleRecord.cco_glocco;
          itemDetalle.tdo_codtdo = 'DPP';
          itemDetalle.ldi_docref = this.saleRecord.cco_numdoc;
          itemDetalle.tmo_codtmo = 'SO';
          itemDetalle.cli_codcli = this.saleRecord.cli_codcli;
          itemDetalle.ldi_tipcam = this.saleRecord.cco_tipcam;
          itemDetalle.ldi_inddha = 'H';
          itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
          itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
          itemDetalle.ldi_indcco = 'N';
          itemDetalle.isEditing = true;

          this.saleRecord.detalle.push(itemDetalle);
          this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);
        }

      }


      if(this.saleRecord.dpc_impdet > 0 && this.saleRecord.tim_codtim != 'PER') {
        li_item++;
        ps_cuenta  = this.saleRecord.ccn_codccn;
        pn_impnac = this.saleRecord.dpc_impdet;
        pn_impmex = this.saleRecord.dpc_impdet / this.saleRecord.cco_tipcam;

        if( ps_cuenta ) {
          ps_valor = this.saleRecord.cco_codcco;

          let itemDetalle = new DetailSaleRecord();
          itemDetalle.cco_codcco = ps_valor;
          itemDetalle.ldi_corldi = `0000${li_item}`;
          itemDetalle.ccn_codccn = ps_cuenta;
          itemDetalle.ccs_codccs = '';
          itemDetalle.ldi_gloldi = this.saleRecord.cco_glocco;
          itemDetalle.tdo_codtdo = this.saleRecord.tdo_codtdo;
          itemDetalle.ldi_docref = this.saleRecord.cco_numdoc;
          itemDetalle.tmo_codtmo = this.saleRecord.tmo_codtmo;
          itemDetalle.cli_codcli = this.saleRecord.cli_codcli;
          itemDetalle.ldi_tipcam = this.saleRecord.cco_tipcam;
          itemDetalle.ldi_inddha = (signo.sig_indsig == -1)? 'D':'H';
          itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
          itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
          itemDetalle.ldi_indcco = 'N';
          itemDetalle.isEditing = true;

          this.saleRecord.detalle.push(itemDetalle);
          this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);
        }

        if(subdiario.ccn_coddet) {
          li_item++;
          ps_cuenta = subdiario.ccn_coddet;

          let itemDetalle = new DetailSaleRecord();
          itemDetalle.ldi_corldi = `0000${li_item}`;
          itemDetalle.ccn_codccn = ps_cuenta;
          itemDetalle.ccs_codccs = '';
          itemDetalle.ldi_gloldi = this.saleRecord.cco_glocco;
          itemDetalle.tdo_codtdo = 'DPP';
          itemDetalle.ldi_docref = this.saleRecord.cco_numdoc;
          itemDetalle.tmo_codtmo = 'SO';
          itemDetalle.cli_codcli = this.saleRecord.cli_codcli;
          itemDetalle.ldi_tipcam = this.saleRecord.cco_tipcam;
          itemDetalle.ldi_inddha = 'H';
          itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
          itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
          itemDetalle.ldi_indcco = 'N';
          itemDetalle.isEditing = true;

          this.saleRecord.detalle.push(itemDetalle);
          this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);
        }

      }

      //       ************************************************

      // *!*         ***************PERCEPCION*****************
      // *!*         *** Contabilidad cuenta 40 de Percepción***

      let ls_cuenta = '401131';
      if (this.saleRecord.tmo_codtmo == ps_tmo_mn){
        pn_impnac = this.saleRecord.dpc_impper;
        pn_impmex = pn_impnac/this.saleRecord.cco_tipcam;
      } else {
        pn_impmex = this.saleRecord.dpc_impper;
        pn_impnac = pn_impmex*this.saleRecord.cco_tipcam;
      }

      if(ls_cuenta && this.saleRecord.dpc_impper > 0) {
        li_item++;
        ps_valor = this.saleRecord.cco_codcco;

        let itemDetalle = new DetailSaleRecord();
        itemDetalle.ldi_corldi = `0000${li_item}`;
        itemDetalle.ccn_codccn = ls_cuenta;
        itemDetalle.cco_codcco = '';
        itemDetalle.ldi_gloldi = this.saleRecord.cco_glocco;
        itemDetalle.cli_codcli = this.saleRecord.cli_codcli;
        itemDetalle.tmo_codtmo = this.saleRecord.tmo_codtmo;
        itemDetalle.ldi_tipcam = this.saleRecord.cco_tipcam;
        itemDetalle.ldi_inddha = (signo.sig_indsig == -1)? 'D': 'H';
        itemDetalle.tdo_codtdo = this.saleRecord.tdo_codtdo;
        itemDetalle.ldi_docref = this.saleRecord.cco_numdoc;
        itemDetalle.ldi_impnac = roundToDecimals(pn_impnac, 2);
        itemDetalle.ldi_impmex = roundToDecimals(pn_impmex, 2);
        itemDetalle.ldi_indcco = 'N';
        itemDetalle.isEditing = true;

        this.saleRecord.detalle.push(itemDetalle);
        this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);
      }

      if(this.saleRecord.dpc_impsis != 0) {
        li_item++;
        let itemDetalle = new DetailSaleRecord();
        itemDetalle.ldi_corldi = `0000${li_item}`;
        itemDetalle.ccn_codccn = '601801';
        itemDetalle.cco_codcco = '';
        itemDetalle.ldi_gloldi = this.saleRecord.cco_glocco;
        itemDetalle.cli_codcli = this.saleRecord.cli_codcli;
        itemDetalle.tmo_codtmo = this.saleRecord.tmo_codtmo;
        itemDetalle.ldi_tipcam = this.saleRecord.cco_tipcam;
        itemDetalle.ldi_inddha = (signo.sig_indsig == -1)? 'D':'H';
        itemDetalle.tdo_codtdo = this.saleRecord.tdo_codtdo;
        itemDetalle.ldi_docref = this.saleRecord.cco_numdoc;
        itemDetalle.ldi_impnac = (this.saleRecord.tmo_codtmo == 'SO')? roundToDecimals(this.saleRecord.dpc_impsis, 2): roundToDecimals(this.saleRecord.dpc_impsis*this.saleRecord.cco_tipcam, 2);
        itemDetalle.ldi_impmex = (this.saleRecord.tmo_codtmo == 'SO')? roundToDecimals(this.saleRecord.dpc_impsis / this.saleRecord.cco_tipcam, 2) : roundToDecimals(this.saleRecord.dpc_impsis, 2);
        itemDetalle.ldi_indcco = 'N';
        itemDetalle.isEditing = true;

        this.saleRecord.detalle.push(itemDetalle);
        this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);
      }

      if(this.saleRecord.dpc_impfis != 0) {
        li_item++;
        let itemDetalle = new DetailSaleRecord();

        itemDetalle.ldi_corldi = `0000${li_item}`;
        itemDetalle.ccn_codccn = '601802';
        itemDetalle.cco_codcco = '';
        itemDetalle.ldi_gloldi = this.saleRecord.cco_glocco;
        itemDetalle.cli_codcli = this.saleRecord.cli_codcli;
        itemDetalle.tmo_codtmo = this.saleRecord.tmo_codtmo;
        itemDetalle.ldi_tipcam = this.saleRecord.cco_tipcam;
        itemDetalle.ldi_inddha = (signo.sig_indsig == -1)? 'D': 'H';
        itemDetalle.tdo_codtdo = this.saleRecord.tdo_codtdo;
        itemDetalle.ldi_docref = this.saleRecord.cco_numdoc;
        itemDetalle.ldi_impnac = (this.saleRecord.tmo_codtmo == 'SO')? roundToDecimals(this.saleRecord.dpc_impfis, 2): roundToDecimals(this.saleRecord.dpc_impfis*this.saleRecord.cco_tipcam, 2);
        itemDetalle.ldi_impmex = (this.saleRecord.tmo_codtmo == 'SO')? roundToDecimals(this.saleRecord.dpc_impfis/this.saleRecord.cco_tipcam, 2): roundToDecimals(this.saleRecord.dpc_impfis, 2);
        itemDetalle.ldi_indcco = 'N';
        itemDetalle.isEditing = true;

        this.saleRecord.detalle.push(itemDetalle);
        this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);
      }

      if(this.saleRecord.detalle.length > 0) {
        setTimeout(() => {
          this.selectCosto.first.open();
          this.selectCosto.first.focus();
        }, 1);
      }
    } else {
      this._snackBarService.showError("Es necesario que la tabla se encuentre vacía.", "OK");
    }

  }

  ajusteDiferencia(): void {
    const diferencia_mn =  this.dataSource3.data[2].mn;
    const diferencia_me =  this.dataSource3.data[2].me;
    if(Math.abs(diferencia_mn) <= 0.05 && diferencia_mn > 0 && Math.abs(diferencia_me) <= 0.05 && diferencia_me > 0) {
      let ps_tmo_mn;
      let pn_impnac;
      let pn_impmex;
      let ps_codtdo;
      let ps_codccs;
      let ps_cuenta;
      let ls_cad;

      (Math.abs(diferencia_mn) != 0)? ps_tmo_mn = "SO": ps_tmo_mn = "DO";

      pn_impnac = Math.abs(diferencia_mn);
      pn_impmex = Math.abs(diferencia_me);

      ps_codtdo = (diferencia_mn < 0 || diferencia_me < 0)? "AJP": "AJP";
      ps_codccs = "";

      this._contabilidadService.configuraTipoDocCtaContable(ps_codtdo).subscribe(
        response => {

          ps_cuenta = response[0].ccn_codmn;
          ps_codccs = response[0].ccs_codccs;

          if(ps_cuenta) {

            let detailItem = new DetailSaleRecord();

            detailItem.ldi_inddha = "D";
            detailItem.ldi_tcameu = 0;
            detailItem.ldi_inddes = 0;
            detailItem.fca_codfca = "";
            detailItem.ldi_impsal = 0;
            detailItem.ldi_indccl = 0;
            detailItem.ldi_indcco = "N";
            detailItem.ldi_inddes = 0;
            detailItem.ldi_mesccl = "";
            detailItem.sco_codsco = this.saleRecord.sco_codsco;
            detailItem.suc_codsuc = "01";
            detailItem.tdo_docref = "";
            detailItem.vde_codvde = "";

            detailItem.ccn_codccn = ps_cuenta;
            detailItem.ldi_gloldi = this.saleRecord.cco_glocco;
            detailItem.tmo_codtmo = ps_tmo_mn;
            detailItem.tdo_codtdo = ps_codtdo;
            detailItem.ldi_docref = this.saleRecord.cco_numdoc;
            detailItem.ldi_tipcam = roundToDecimals(pn_impmex / pn_impnac, 3);
            detailItem.ldi_inddha = (ps_codtdo == "AJP")? "D": "H";
            detailItem.ldi_impnac = roundToDecimals(pn_impnac, 2);
            detailItem.ldi_impmex = roundToDecimals(pn_impmex, 2);
            detailItem.ldi_indcco = "N";
            detailItem.isEditing = true;



            if(ps_codtdo == "AJP" && ps_codccs > 0) {
              detailItem.ccs_codccs = ps_codccs;
            }

            this.saleRecord.detalle.push(detailItem);

            this.dataSource = fillTable(this.saleRecord.detalle, this.paginator, this.sort);

            this.saleRecord.detalle.forEach((element, key) => {
              element.ldi_corldi = (key > 9)?`000${key + 1}`: `0000${key + 1}`;
            })

            setTimeout(() => {
              this.inputDescripcion.last.nativeElement.focus();
              this.inputDescripcion.last.nativeElement.slect();
            }, 1);

            this.montosDebeHaber[0].mn = 0;
            this.montosDebeHaber[0].me = 0;
            this.montosDebeHaber[1].mn = 0;
            this.montosDebeHaber[1].me = 0;
            this.montosDebeHaber[2].mn = 0;
            this.montosDebeHaber[2].me = 0;

            this.saleRecord.detalle.forEach(element => {
              if(element.ldi_inddha == "D") {
                this.montosDebeHaber[0].mn += element.ldi_impnac;
                this.montosDebeHaber[0].me += element.ldi_impmex;
              } else {
                this.montosDebeHaber[1].mn += element.ldi_impnac;
                this.montosDebeHaber[1].me += element.ldi_impmex;
              }
            });

            this.montosDebeHaber[2].mn = this.montosDebeHaber[0].mn - this.montosDebeHaber[1].mn;
            this.montosDebeHaber[2].me = this.montosDebeHaber[0].me - this.montosDebeHaber[1].me;

          }
        },
        error => this._snackBarService.showError(error.error.msg, "OK")
      )
    } else {
      this._snackBarService.showError("La diferencia tiene que ser > 0 y <= a 0.05", "OK");
    }

  }

  calculoImportesMN(row: DetailSaleRecord): void {
    row.ldi_impmex = roundToDecimals(row.ldi_impnac * row.ldi_tipcam, 2);
  }

  calculoImportesME(row: DetailSaleRecord): void {
    row.ldi_impnac = roundToDecimals(row.ldi_impmex / row.ldi_tipcam, 2);
  }

  applyFilter(event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

  volver(): void {
    this._router.navigate(['/registros-de-venta']);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.subdiarios$,
      this.tipoOperacion$,
      this.cuentas$,
      this.tiposBienServicio$,
      this.tiposRenta$,
      this.tiposImpuesto$,
      this.tiposDocumento$,
      this.proveedor$,
      this.moneda$,
      this.ordenesCompra$,
      this.condiciones$,
      this.voucher$,
      this.centro$,
      this.auxiliar$,
      this.tipoRetencion$,
      this.tipoImpRetencion$,
      this.period$
    ])

  }

}
// Cuando veas los espejos apuntando a tus espaldas sabras que los mitos no son para ti.
