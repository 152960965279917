import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { ComprasService } from 'src/app/services';
import { SnackBarService } from '@shared/services/snackbar.service';

@Component({
  selector: 'app-condi-pago-select',
  templateUrl: './condi-pago-select.component.html',
  styleUrls: ['./condi-pago-select.component.css']
})
export class CondiPagoSelectComponent implements OnInit, OnDestroy {

  condicionesPago: Array<any>;

  @Input() disable: boolean;
  @Input() condicionPago: string;
  @Output() cambioCondicionPago: EventEmitter<Object>;

  condicion$: Subscription;

  constructor(
    private _comprasService: ComprasService,
    private _snackBarService: SnackBarService
  ) {
    this.condicionesPago = [];
    this.cambioCondicionPago = new EventEmitter<string>();
    this.condicionPago = "";
  }

  ngOnInit(): void {
    this.obtenerCondicionesPago();
  }

  obtenerCondicionesPago(): void {
    this.condicion$ = this._comprasService.obtenerCondicionesPago().subscribe(
      condicionesPago => {
        this.condicionesPago = condicionesPago;
      },
      error => {
        this._snackBarService.showError("Error al obtener condiciones de pago", 'OK');
      }
    )
  }

  seleccionarCondicionPago(event): void {

    let condicion = this.condicionesPago.find(element => element.CPA_CODCPA == event.value);

    this.cambioCondicionPago.emit({codigo: condicion.CPA_CODCPA, dias: condicion.CPA_DIAPLA});
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.condicion$
    ])
  }
}
