import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { searchInTable } from '@utils/tables/table';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  displayedColumns: string[] = ['item', 'cod_fp', 'forma_pago', 'mon', 'importe', 'abono', 'cod_tc', 'tarjeta_credito', 'nro_tarjeta', 'forma_pago', 'fec_ven', 'nombre_tarjeta', 'pos', 'acciones'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buttonName: INameConstant = NAMES_CONSTANTS;

  constructor(
    public dialogRef: MatDialogRef<PaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  applyFilter(event: Event): void {
    this.dataSource = searchInTable(event, this.dataSource )
  }

}
