import { Injectable } from "@angular/core";
import { PavsoState } from "@data/interfaces/state/pavso-state";
import { Store } from "@ngrx/store";
import { SnackBarService } from "@shared/services/snackbar.service";
import * as pdfBuilder from "@utils/pdfmaker/builder/almacen/almacen.pdfmaker";
import { Subscription } from "rxjs";
import { OtherDataFiltroAlmacen } from "src/app/admin/apps/almacen/pages/informes/informes-unidades/informes-unidades.component";
import { AlmacenService } from "../../api/almacen.service";
import { IParam } from "../../api/utilities/params";
import { ConfigurationService } from "../../configuration.service";
import { InventarioExcelService } from "../../reporte-excel/inventario-excel.service";
import { FiltroReporteAlmacen } from "src/app/models/almacen";
import { GuiaRemisionService } from "../../api/almacen/operaciones/guia-remision.service";
import { ordenerArregloJSONxLlave } from "@utils/array/order";

@Injectable({
  providedIn: 'root'
})
export class InventariosReportService {

  reporte$: Subscription;
  period$: Subscription;

  year: string;
  month: string;

  constructor(
    private _almacenService: AlmacenService,
    private _snackBarService: SnackBarService,
    private _configurationService: ConfigurationService,
    private _guiaRemisionService: GuiaRemisionService,
    public ete: InventarioExcelService,
    private store: Store<PavsoState>
  ) {

    this.period$ = this.store.select('period').subscribe(state => {
      this.year = state.year;
      this.month = state.month;
    })

  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param otherData
   * @returns
   */
  verReporte(reportSelected: string, headers, otherData: OtherDataFiltroAlmacen): Promise<any> {

    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case "ALMACEN_SP":

          this.reporte$ = this._almacenService.obtenerInformeSaldoPorProducto(this.year, this.month, otherData.filtro, { params: otherData.params }).subscribe(
            data => {

              console.log('saldo producto data', data)

              if (!data) {
                this._snackBarService.showError('Error al generar reporte de saldo por producto', 'Ok');
                reject(false)
                return;
              }

              // if(data.length == 0) {
              //   this._snackBarService.showError('No se encontraron registros en la Base de Datos', 'Ok');
              //   reject(false)
              //   return;
              // }

              pdfBuilder.generarReporteSaldosPorProducto(headers, data)
              resolve(true)
              return;

            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de saldo por producto", "Ok");
              reject(false)
              return;
            }
          )
          break;
        case "ALMACEN_SL":
          this.reporte$ = this._almacenService.obtenerInformeSaldoPorLote(this.year, this.month, otherData.filtro, { params: otherData.params }).subscribe(
            data => {

              console.log('saldo lote data', data)
              if (!data) {
                this._snackBarService.showError('Error al generar reporte de saldo por producto', 'Ok');
                reject(false)
                return;
              }
              pdfBuilder.generarReporteSaldosPorLote(headers, data)
              resolve(true)
              return;

            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de saldo por producto", "Ok");
              reject(false)
              return;
            }
          )
          break;
        case "ALMACEN_SPV":
          this.reporte$ = this._almacenService.obtenerInformeSaldoPorProductoValorizado(this.year, this.month, { params: otherData.params }).subscribe(
            data => {
              console.log('MONEDA', headers.currency)
              if (!headers.currency) {
                this._snackBarService.showError('Establecer el tipo de moneda', 'Ok');
                reject(false);
                return;
              }
              if (!data) {
                this._snackBarService.showError('Error al generar reporte de saldo por producto valorizado', 'Ok');
                reject(false)
                return;
              }

              pdfBuilder.generarReporteSaldosPorProductoValorizado(headers, data);

              resolve(true)
              return;
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de saldo por producto", "Ok");
              reject(false)
              return;
            }
          )
          break;
        case "ALMACEN_RA":
          if (!headers.currency) {
            this._snackBarService.showError('Seleccionar el tipo de moneda', 'Ok');
            reject(false)
            return;
          }
          this.reporte$ = this._almacenService.obtenerInformeResumenAnualTipoInventario(this.year, { params: otherData.params }).subscribe(
            data => {

              if (!data) {
                this._snackBarService.showError('Error al generar reporte de resumen anual por tipo de inventario', 'Ok');
                reject(false)
                return;
              }

              pdfBuilder.generarReporteResumenAnualTipoInventario(headers, data);
              resolve(true)
              return;

            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de resumen anual por tipo de inventario", "Ok");
              reject(false)
              return;
            }
          )
          break;
        case "ALMACEN_KM":
          if (!headers.currency) {
            this._snackBarService.showError('Seleccionar moneda', 'Ok');
            reject(false)
            return;
          }

          this.reporte$ = this._almacenService.obtenerInformeKardexMensual(this.year, this.month, otherData.filtro, { params: otherData.params }).subscribe(
            data => {
              if (!data) {
                this._snackBarService.showError('Error al generar reporte kardex mensual', 'Ok');
                reject(false)
                return;
              }
              pdfBuilder.generarReporteKardexMensual(headers, data)
              console.log('saldo producto data', data)
              resolve(true)
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte kardex mensual", "Ok");
              reject(false)
              return;
            }
          )
          break;
        case "ALMACEN_KMV":

          if (!headers.currency) {
            this._snackBarService.showError('Seleccionar moneda', 'Ok');
            reject(false)
            return;
          }

          this.reporte$ = this._almacenService.obtenerInformeKardexMensual(this.year, this.month, otherData.filtro, { params: otherData.params }).subscribe(
            data => {
              if (!data) {
                this._snackBarService.showError('Error al generar reporte kardex mensual valorizado', 'Ok');
                reject(false)
                return;
              }
              pdfBuilder.generarReporteKardexValorizadoMensual(headers, data)
              console.log('saldo producto data', data)
              resolve(true)
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte kardex mensual valorizado", "Ok");
              reject(false)
              return;
            }
          )
          break;
        case "ALMACEN_RPC":
          console.log('ingreso resumen de producto por clasificación')
          if (!headers.currency) {
            this._snackBarService.showError('Seleccionar moneda', 'Ok');
            reject(false)
            return;
          }

          this.reporte$ = this._almacenService.obtenerInformeResumenProductosClasificados(this.year, this.month, otherData.filtro, { params: otherData.params }).subscribe(
            data => {
              if (!data.data) {
                this._snackBarService.showError('Error al generar reporte Resumen productos por clasificación', 'Ok');
                reject(false)
                return;
              }
              console.log('data', data)

              const dataReport = data.data.filter(item => item.tin_codtin == "01");
              console.log('dataReport', dataReport)
              data['data'] = dataReport;

              pdfBuilder.generarReporteResumenProductoPorClasificados(headers, data)

              resolve(true)
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte Resumen productos por clasificación", "Ok");
              reject(false)
              return;
            }
          )
          break;
        case "ALMACEN_KL":
          console.log('ingreso kardex por lote')
          if (!headers.currency) {
            this._snackBarService.showError('Seleccionar moneda', 'Ok');
            reject(false)
            return;
          }

          this.reporte$ = this._almacenService.obtenerInformeKardexLote(this.year, this.month, otherData.filtro, { params: otherData.params }).subscribe(
            data => {
              if (!data) {
                this._snackBarService.showError('Error al generar kardex por lote', 'Ok');
                reject(false)
                return;
              }
              pdfBuilder.generarReporteKardexPorLote(headers, data)
              console.log('saldo producto data', data)
              resolve(true)
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte kardex por lote", "Ok");
              reject(false)
              return;
            }
          )
          break;
        case "ALMACEN_TPL":
          console.log('ingreso kardex por lote')
          if (!headers.currency) {
            this._snackBarService.showError('Seleccionar moneda', 'Ok');
            reject(false)
            return;
          }

          this._almacenService.consultarStockKardexLote(headers.codano, headers.codmes, { params: otherData.params }).subscribe(
            data => {
              if (!data) {
                this._snackBarService.showError('Error al generar informe trazabilidadx por lote', 'Ok');
                reject(false)
                return;
              }

              pdfBuilder.generarReporteKardexPorLoteTrazabilidad(headers, ordenerArregloJSONxLlave('mlo_numlot', data))
              console.log('saldo producto data', data)
              resolve(true)
            },
            error => {

            }
          )
          break;
        case "ALMACEN_MM":
          this.reporte$ = this._almacenService.obtenerInformeMovimientoPorMotivo(this.year, this.month, otherData.filtro, { params: otherData.params }).subscribe(
            data => {
              console.log('movimiento por motivo', data)
              if (!data) {
                this._snackBarService.showError('Error al generar reporte de movimientos por motivo', 'Ok');
                reject(false)
                return;
              }

              pdfBuilder.generarReporteMovimientosPorMotivo(headers, data);
              resolve(true)
              return;

            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de movimiento por motivo", "Ok");
              reject(false)
              return;
            }
          )
          break;
        case "ALMACEN_MMV":
          if (!headers.currency) {
            this._snackBarService.showError('Seleccionar un tipo de moneda', 'Ok');
            reject(false)
            return;
          }

          this.reporte$ = this._almacenService.obtenerInformeMovimientoPorMotivoValorizado(this.year, this.month, { params: otherData.params }).subscribe(
            data => {
              console.log('data movimiento valorizado', data)
              if (!data) {
                this._snackBarService.showError('Error al generar reporte de mmovimientos por motivo valorizado', 'Ok');
                reject(false)
                return;
              }

              pdfBuilder.generarReporteMovimientosPorMotivoValorizados(headers, data);
              console.log('saldo producto data', data)
              resolve(true)
              return;
            },
            error => {
              console.log('error', error)
              this._snackBarService.showError("Error al obtener reporte de movimiento por motivo valorizado", "Ok");
              reject(false)
              return;
            }
          )
          break;
        default:
          reject(false)
          break;
      }
    })
  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporteDinamico(reportSelected: string, headers, otherData: OtherDataFiltroAlmacen): Promise<any> {
    console.log('reporte seleccionado', reportSelected)
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case "ALMACEN_SUA":

          this._almacenService.obtenerInformeDinamicoSaldoPorProducto(this.year, this.month, { params: otherData.params }).subscribe(
            data => {
              console.log('informe de saldo por producto', data);
              // Filtrar por tienda
              let filtrados = [];

              if (otherData.filtro.establecimiento.trim() != '') {

                filtrados = data.filter(item => item.tie_codtie == otherData.filtro.establecimiento);

              } else {

                filtrados = data;

              }

              if (filtrados.length <= 0) {
                this._snackBarService.showError('No se encontro informacion para el filtro establecido', 'Ok');
                reject(false)
                return;
              }

              let dataForExcel = [];

              const columnasSeleccionadas = filtrados.map(item => {

                let obj = {}
                otherData.columns.forEach(element => {
                  if (element.completed) {
                    obj[element.TDR_COLNOM] = item[element.TDR_COLTDR]
                  }
                })

                return obj;

              })

              let reportData = {
                title: `Informe de saldos por producto ${this.year} ${this.month}`,
                data: columnasSeleccionadas,
              }

              this.ete.exportExcelSaldosProducto(reportData);

              resolve(true)
            },
            error => {
              this._snackBarService.showError('Error al generar reporte dinámico', 'OK');
              reject(false);
            }
          );
          break;
        case "ALMACEN_KUA":
          console.log('movimiento unidades almacen')


          // this._almacenService.consultarStockKardexLote(headers.codano, headers.codmes, { params: otherData.params }).subscribe(
          //   response => {

          //     console.log('otherData', otherData)

          //     // Filtrar por tienda
          //     console.log('tienda', otherData.filtro.establecimiento)
          //     console.log('tienda', typeof otherData.filtro.establecimiento)

          //     let filtrados = [];

          //     if (otherData.filtro.establecimiento.trim() != '') {

          //       filtrados = response.filter(item => item.tie_codtie == otherData.filtro.establecimiento);

          //     } else {

          //       filtrados = response;

          //     }

          //     const columnasSeleccionadas = filtrados.map(item => {

          //       let obj = {}
          //       otherData.columns.forEach(element => {
          //         if (element.completed) {
          //           obj[element.TDR_COLNOM] = item[element.TDR_COLTDR]
          //         }
          //       })

          //       return obj;

          //     })

          //     console.log('columnas seleccionadas', columnasSeleccionadas)

          //     this.ete.exportExcelSaldosLote({ data: columnasSeleccionadas, title: `Reporte Kardex por unidad Almacén | ${headers.codano}-${headers.codmes}` });

          //     resolve(true);
          //   },
          //   error => {
          //     this._snackBarService.showError('Error al obtener reporte de kardex lote.', 'Ok')
          //   }
          // )

          reject(false);

          break;
        case "ALMACEN_KLA":

          const paramsKLA: Array<IParam> = [];

          this._almacenService.consultarStockKardexLote(headers.codano, headers.codmes, { params: otherData.params }).subscribe(
            response => {

              console.log('otherData', otherData)

              // Filtrar por tienda
              console.log('tienda', otherData.filtro.establecimiento)
              console.log('tienda', typeof otherData.filtro.establecimiento)

              let filtrados = [];

              if (otherData.filtro.establecimiento.trim() != '') {

                filtrados = response.filter(item => item.tie_codtie == otherData.filtro.establecimiento);

              } else {

                filtrados = response;

              }

              const columnasSeleccionadas = filtrados.map(item => {

                let obj = {}
                otherData.columns.forEach(element => {
                  if (element.completed) {
                    obj[element.TDR_COLNOM] = item[element.TDR_COLTDR]
                  }
                })

                return obj;

              })

              console.log('columnas seleccionadas', columnasSeleccionadas)

              this.ete.exportExcelSaldosLote({ data: columnasSeleccionadas, title: `Reporte Movimientos por Lote Almacén | ${headers.codano}-${headers.codmes}` });

              resolve(true);
            },
            error => {
              this._snackBarService.showError('Error al obtener reporte de Movimientos lote.', 'Ok')
            }
          )

          break;
        case "ALMACEN_KSA":
          reject(false);
          break;
        case "ALMACEN_SLA":
          console.log('filtro', otherData.filtro)

          this._almacenService.consultarStockDetalleLote(headers.codano, headers.codmes, { params: otherData.params }).subscribe(
            response => {

              // Filtrar por tienda
              let filtrados = [];

              if (otherData.filtro.establecimiento.trim() != '') {

                filtrados = response.filter(item => item.tie_codtie == otherData.filtro.establecimiento);

              } else {

                filtrados = response;

              }

              const columnasSeleccionadas = filtrados.map(item => {

                let obj = {}
                otherData.columns.forEach(element => {
                  if (element.completed) {
                    obj[element.TDR_COLNOM] = item[element.TDR_COLTDR]
                  }
                })

                return obj;

              })

              this.ete.exportExcelSaldosLote({ data: columnasSeleccionadas, title: `Reporte Saldo por Lote Almacén | ${headers.codano}-${headers.codmes}` });

              resolve(true);
            },
            error => {
              this._snackBarService.showError('Error al obtener stock detalle por lote', 'Ok');
              reject(false);
            }
          )

          break;
        case "ALMACEN_SSA":
          reject(false);
          break;
        default:
          reject(false);
          break;
      }
    })
  }

  /**
   *
   * @param reportSelected
   * @param headers
   * @param payload
   * @param otherData
   * @returns
   */
  verReporteDinamicoGuias(reportSelected: string, headers, otherData: OtherDataFiltroAlmacen): Promise<any> {
    console.log('reporte seleccionado', reportSelected)
    return new Promise((resolve, reject) => {
      switch (reportSelected) {
        case "ALMACEN_DLG":

          console.log('headers', headers)

          this._guiaRemisionService.obtenerReporteListadoGuias({ params: otherData.params }).subscribe(
            data => {
              console.log('informe de listado de guias  ', data);
              // Filtrar por tienda
              const columnasSeleccionadas = data.map(item => {

                let obj = {}
                otherData.columns.forEach(element => {
                  if (element.completed) {
                    obj[element.TDR_COLNOM] = item[element.TDR_COLTDR]
                  }
                })

                return obj;

              })

              console.log('columnasSeleccionadas', columnasSeleccionadas)

              let reportData = {
                title: `Listado de guías ${headers.codano} ${headers.codmes}`,
                data: columnasSeleccionadas,
              }

              this.ete.exportExcelSaldosProducto(reportData);

              resolve(true)
            },
            error => {
              this._snackBarService.showError('Error al generar reporte dinámico', 'OK');
              reject(false);
            }
          );

          break;
        default:
          reject(false);
          break;
      }
    })
  }

}
