import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, VentasService, CrmService, ConfigurationService } from 'src/app/services';
import { AddContactComponent } from '@shared/components/dialogs/crm/add-contact/add-contact.component';
import { ListarRequisitosComponent } from '@shared/components/dialogs/crm/listar-requisitos/listar-requisitos.component';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { NAMES_CONSTANTS } from '@data/constants/names/name.metadata';
import { INameConstant } from '@data/interfaces/constants/name.interface';
import { PavsoState } from '@data/interfaces/state/pavso-state';
import { SnackBarService } from '@shared/services/snackbar.service';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { SeguimientoOportunidad } from 'src/app/models/crm/seguimiento_oportunidad';
import { DialogService } from '@shared/services/dialog.service';
import { SuccessComponent } from '@shared/components/dialogs/success/success.component';
import { CanalService } from 'src/app/services/api/crm/maestros/canal.service';
import { EtapaService } from 'src/app/services/api/crm/maestros/etapa.service';
import { ModalidadService } from 'src/app/services/api/crm/maestros/modalidad.service';
import { LineaNegocioService } from 'src/app/services/api/crm/maestros/linea-negocio.service';
import { ActividadService } from 'src/app/services/api/crm/maestros/actividad.service';
import { ResponsableService } from 'src/app/services/api/crm/maestros/responsable.service';
import { ApiCRMOperacionesSeguimientoService } from 'src/app/services/api/crm/operaciones/crm.operaciones.seguimiento.service';
import { ApiCRMOperacionesOportunidadService } from 'src/app/services/api/crm/operaciones/crm.operaciones.oportunidad.service';
import { SituacionService } from 'src/app/services/api/crm/maestros/situacion.service';
import { forkObs } from '@utils/observables/fork';

interface UploadItem {
  id: number;
  name: string;
  weight: string;
}

@Component({
  selector: 'app-seguimiento-form',
  templateUrl: './seguimiento-form.component.html',
  styleUrls: ['./seguimiento-form.component.css']
})
export class SeguimientoFormComponent implements OnInit, OnDestroy {

  loaderReg: boolean = false;
  loaderData: boolean = false;

  usuario: any;
  fecha: Date = new Date();

  vendedores: any[] = [];
  monedas: any[] = [];
  canales: any[] = [];
  lineasNegocio: any[] = [];
  motivos: any[] = [];

  minDate: Date;

  seguimiento$: Subscription;
  period$: Subscription;
  contactos$: Subscription;
  loading$: Subscription;

  seguimiento: SeguimientoOportunidad;

  year: string;
  month: string;
  buttonsName: INameConstant = NAMES_CONSTANTS;
  btnName: string;

  uid: string;
  tkt: string;

  itemsUpload: Array<UploadItem> = [];

  isCotizacion: boolean = false;
  nroSeguimiento: any;

  responsables: any[] = [];
  etapas: any[] = [];
  situaciones: any[] = [];
  actividades: any[] = [];
  modalidades: any[] = [];
  contactos: any[] = [];

  protected _onDestroy = new Subject<void>();

  constructor(
    private _router: Router,
    private _snackBarService: SnackBarService,
    private _authService: AuthenticationService,
    private _dialogService: DialogService,
    private _activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private _ventasService: VentasService,
    private _crmService: CrmService,
    private _actividadService: ActividadService,
    private _responsableService: ResponsableService,
    private _lineaNegocioService: LineaNegocioService,
    private _canalService: CanalService,
    private _etapaService: EtapaService,
    private _modalidadService: ModalidadService,
    private _apiSeguimientoCrmService: ApiCRMOperacionesSeguimientoService,
    private _apiOportunidadCrmService: ApiCRMOperacionesOportunidadService,
    private _situacionService: SituacionService,
    private store: Store<PavsoState>,
    private _configurationService: ConfigurationService
  ) {
    this.seguimiento = new SeguimientoOportunidad();

    this.seguimiento.codcia = this._configurationService.obtenerCompaniaCliente();
    this.seguimiento.coduse = this._configurationService.obtenerIdUsuario();

    this.period$ = this.store.select('period').subscribe(({ year, month }) => {
      this.year = year;
      this.month = month;
    })

    this.minDate = new Date(parseInt(this.year), parseInt(this.month), new Date().getDate());

    this.usuario = this._authService.getUsuarioSistema();

    this.itemsUpload = [
      {
        id: 1,
        name: 'Documento.pdf',
        weight: '1.2 MB'
      },
    ]
  }

  ngOnInit(): void {
    this.loading$ = this.store.select('loading').subscribe(state => {
      if (!state.isLoadingCompany && !state.isLoadingSidenav && !state.isLoadingEstablishment && !state.isLoadingModule) this.loadData();
    })
  }

  loadData(): void {
    this._activatedRoute.params.subscribe(({ tkt, id }) => {
      this.loadMaestro();
      this.seguimiento.idtkt = tkt;
      this.tkt = tkt;
      this.uid = id;
      this.uid == '0' ? this.btnName = 'general.button.store' : this.btnName = 'general.button.update';

      if (id == '0') return;

    })
  }

  obtenerSeguimiento(): void {
    this.seguimiento$ = this._apiSeguimientoCrmService.obtenerSeguimientoOportunidad(this.tkt, this.uid).subscribe(
      ([seguimiento]) => {
        console.log('SEGUIMIENTO', seguimiento)
        this.seguimiento = seguimiento;


        this.contactos$ = this._crmService.obtenerContactosPorCliente(this.seguimiento.codcli).subscribe(
          contactos => {
            this.contactos = contactos
            this.loaderData = false;
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderData = false;
          }
        )

      },
      error => {
        this._snackBarService.showError("Error al obtener seguimiento", "OK")
        this.loaderData = false;
      }
    )
  }

  vendedores$: Subscription;
  responsables$: Subscription;
  canales$: Subscription;
  etapas$: Subscription;
  lineasNegocio$: Subscription;
  situaciones$: Subscription;
  motivosVentaSuspendida$: Subscription;
  actividades$: Subscription;
  modalidades$: Subscription;
  registroOportunidad$: Subscription;

  loadMaestro(): void {

    this.vendedores$ = this._ventasService.obtenerVendedores().subscribe(
      vendedores => {
        this.vendedores = vendedores;

        this.responsables$ = this._responsableService.obtenerResponsables().subscribe(
          responsables => {
            this.responsables = responsables;

            this.canales$ = this._canalService.obtenerCanales().subscribe(
              canales => {
                this.canales = canales;

                this.etapas$ = this._etapaService.obtenerEtapas().subscribe(
                  etapas => {
                    this.etapas = etapas;
                    console.log('etapas', etapas)

                    this.lineasNegocio$ = this._lineaNegocioService.obtenerLineasNegocio().subscribe(
                      lineas => {
                        this.lineasNegocio = lineas;
                        this.situaciones$ = this._situacionService.obtenerSituacionesOportunidad().subscribe(
                          situaciones => {
                            this.situaciones = situaciones;

                            this.motivosVentaSuspendida$ = this._crmService.obtenerMotivosVentaSuspendida().subscribe(
                              motivos => {
                                this.motivos = motivos;

                                this.actividades$ = this._actividadService.obtenerActividades().subscribe(
                                  actividades => {
                                    this.actividades = actividades;

                                    this.modalidades$ = this._modalidadService.obtenerModalidades().subscribe(
                                      modalidades => {
                                        this.modalidades = modalidades;

                                        this.registroOportunidad$ = this._apiOportunidadCrmService.obtenerRegistroOportunidad(this.tkt).subscribe(
                                          registro => {
                                            console.log('registro', registro)
                                            this.contactos$ = this._crmService.obtenerContactosPorCliente(registro[0].codcli).subscribe(
                                              contactos => {
                                                this.contactos = contactos;

                                                if (this.uid != '0') {
                                                  this.obtenerSeguimiento();
                                                  return;
                                                };

                                                this.loaderData = false;
                                              },
                                              error => {
                                                this._snackBarService.showError(error.error.msg, 'Ok');
                                                this.loaderData = false;
                                              }
                                            )
                                          },
                                          error => {
                                            this._snackBarService.showError(error.error.msg, 'Ok');
                                            this.loaderData = false;
                                          }
                                        )

                                      },
                                      error => {
                                        this._snackBarService.showError(error.error.msg, 'Ok');
                                        this.loaderData = false;
                                      }
                                    )

                                  },
                                  error => {
                                    this._snackBarService.showError(error.error.msg, 'Ok');
                                    this.loaderData = false;
                                  }
                                )

                              },
                              error => {
                                this._snackBarService.showError(error.error.msg, 'Ok');
                                this.loaderData = false;
                              }
                            )

                          },
                          error => {
                            this._snackBarService.showError(error.error.msg, 'Ok');
                            this.loaderData = false;
                          }
                        )
                      },
                      error => {
                        this._snackBarService.showError(error.error.msg, 'Ok');
                        this.loaderData = false;
                      }
                    )
                  },
                  error => {
                    this._snackBarService.showError(error.error.msg, 'Ok');
                    this.loaderData = false;
                  }
                )
              },
              error => {
                this._snackBarService.showError(error.error.msg, 'Ok');
                this.loaderData = false;
              }
            )
          },
          error => {
            this._snackBarService.showError(error.error.msg, 'Ok');
            this.loaderData = false;
          }
        )
      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
        this.loaderData = false;
      }
    )

  }

  copiarSeguimiento(): void {
    this.seguimiento$ = this._apiSeguimientoCrmService.obtenerSeguimientoOportunidad(this.tkt, this.nroSeguimiento).subscribe(
      response => {
        if (response.length == 0) {
          this._snackBarService.showError('Seguimiento no encontrado', 'Ok');
          return;
        }

        console.log('seguimiento copiado', response);
        this.seguimiento.idres = response[0].idres;
        this.seguimiento.codoet = response[0].codoet;
        this.seguimiento.idstk = response[0].idstk;
        this.seguimiento.idass = response[0].idass;
        this.seguimiento.idmso = response[0].idmso;
        this.seguimiento.codccl = response[0].codccl;

      },
      error => {
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  trackByResponsable(index, responsable): string {
    return responsable ? responsable.idres : undefined;
  }

  trackByEtapa(index, etapa): string {
    return etapa ? etapa.codoet : undefined;
  }

  trackBySituacion(index, situacion): string {
    return situacion ? situacion.idstk : undefined;
  }

  trackByMotivo(index, motivo): string {
    return motivo ? motivo.codosv : undefined;
  }

  trackByContacto(index, contacto): string {
    return contacto ? contacto.codccl : undefined;
  }

  trackByActividad(index, actividad): string {
    return actividad ? actividad.idass : undefined;
  }

  trackByModalidad(index, modalidad): string {
    return modalidad ? modalidad.fasdf : undefined;
  }

  /**
   * Método para el envío de formulario, se realiza la comprobación si
   * es un registro nuevo o existente con el @uid
   * @param f
   * @returns
   */
  enviarFormulario(f: NgForm): void {
    if (!this.formularioValido()) return;

    this.uid == '0' ? this.registrarSeguimiento(f) : this.actualizarSeguimiento(f);

  }

  /**
   * Método para registrar un nuevo seguimiento
   * @param f
   */
  registrarSeguimiento(f: NgForm): void {
    this.loaderReg = true;
    this._apiSeguimientoCrmService.registrarSeguimientoOportunidad(this.seguimiento).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.operaciones.seguimiento.success-store', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  /**
   * Método para actualizar seguimiento
   * @param f
   */
  actualizarSeguimiento(f: NgForm): void {
    this.loaderReg = true;
    this._apiSeguimientoCrmService.actualizarSeguimientoOportunidad(this.seguimiento).subscribe(
      response => {
        this.loaderReg = false;
        this._dialogService.openDialog(SuccessComponent, 'apps.crm.operaciones.seguimiento.success-update', '400px', '400px', '');
      },
      error => {
        this.loaderReg = false;
        this._snackBarService.showError(error.error.msg, 'Ok');
      }
    )
  }

  seleccionarEtapa(codoet): void {
    console.log('codoet', codoet)
    if (codoet != '09') this.seguimiento.codosv = null;

    if (codoet == '05') {
      this.isCotizacion = true;
      return;
    };
    this.isCotizacion = false;
  }

  formularioValido(): boolean {
    if (!this.seguimiento.idres) {
      this._snackBarService.showError('Seleccionar un responsable', 'Ok');
      return false;
    }

    if (!this.seguimiento.codoet) {
      this._snackBarService.showError('Seleccionar una etapa', 'Ok');
      return false;
    }

    if (!this.seguimiento.idstk) {
      this._snackBarService.showError('Seleccionar una situación', 'Ok');
      return false;
    }

    if (!this.seguimiento.idass) {
      this._snackBarService.showError('Seleccionar una actividad', 'Ok');
      return false;
    }

    if (!this.seguimiento.idmso) {
      this._snackBarService.showError('Seleccionar una modalidad', 'Ok');
      return false;
    }

    // if(!this.seguimiento.codosv) {
    //   this._snackBarService.showError('Seleccionar un motivo de venta suspendida', 'Ok');
    //   return false;
    // }

    return true;
  }

  abrirAgregarContacto(): void {
    const dialogRef = this.dialog.open(AddContactComponent, {
      width: 'auto',
      panelClass: 'pav-dialog-container',
      disableClose: false,
      data: {
        codcli: this.seguimiento.codcli,
        nomcli: this.seguimiento.nomcli,
      }
    })

    dialogRef.afterClosed().subscribe(result => { })
  }

  abrirListaRequisitos(): void {
    const dialogRef = this.dialog.open(ListarRequisitosComponent, {
      width: 'auto',
      panelClass: 'pav-dialog-container',
      data: {
        idtkt: this.tkt,
        idset: this.uid
      },
      autoFocus: false
    })

    dialogRef.afterClosed().subscribe(result => { })
  }

  abrirPresupuesto(): void {
    this._router.navigate(['/modulo-crm/presupuesto', this.tkt, this.uid, '0'])
  }

  abrirListarPresupuestos(): void {

  }

  onFileSelected(event): void {
    const file: File = event.target.files[0];

    console.log('file', file)
    const item: UploadItem = {
      id: this.itemsUpload.length,
      name: file.name,
      weight: `${(file.size / 1000000).toFixed(2)} MB`
    };

    this.itemsUpload.push(item);
  }

  drop(event): void {
    console.log('drop event', event)
  }

  allowDrop(event: Event): void {
    event.preventDefault();
  }

  quitarItem(id): void {
    let items = [];
    this.itemsUpload.forEach(item => {
      if (item.id != id) items.push(item);
    })

    this.itemsUpload = items;
  }

  volver(): void {
    this._router.navigate(['/modulo-crm/seguimientos-de-registro-de-oportunidad', this.seguimiento.idtkt]);
  }

  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.period$,
      this.loading$,
      this.seguimiento$,
      this.contactos$,
    ])
  }

}
