<app-pavso-title-section [title]="'Activo fijo'" [module]="'Activo fijo'"
  [ngClass]="'pav-form'" [estado]="'1'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="enviarFormulario(f)" (keydown.enter)="$event.preventDefault()">

    <mat-card class="mat-elevation-z0">
      <div class="row">
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Código</mat-label>
            <input type="text" matInput placeholder="Código" name="cli_codcli" required>
          </mat-form-field>
        </div>

        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Tipo de activo</mat-label>
            <mat-select required name="tipo_activo">
              <mat-option *ngFor="let tipo of tiposActivo" [value]="tipo.codigo_tipoacti">
                {{tipo.descri_tipoacti}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Grupo activo</mat-label>
            <mat-select required name="grupo_activo">
              <mat-option *ngFor="let grupo of gruposAtivo" [value]="grupo.codigo_grupoacti">
                {{grupo.descri_grupoacti}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Descripción</mat-label>
            <input type="text" matInput placeholder="Descripción" name="descripcion" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>T.C.</mat-label>
            <input type="number" min="0" matInput placeholder="T.C." name="tc" required>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Fecha de Adquisición</mat-label>
            <input matInput [matDatepicker]="fechaadquisicion">
            <mat-datepicker-toggle matSuffix [for]="fechaadquisicion"></mat-datepicker-toggle>
            <mat-datepicker #fechaadquisicion></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Activo Principal</mat-label>
            <mat-select required name="activo_principal">
              <mat-option value="1">
                activo principal 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Situación SUNAT</mat-label>
            <mat-select required name="situacion_sunat">
              <mat-option value="1">
                situacion sunat 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Ubicación</mat-label>
            <mat-select required name="ubicacion">
              <mat-option value="1">
                ubicacion 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Estado SUNAT</mat-label>
            <mat-select required name="estado_sunat">
              <mat-option value="1">
                estado sunat 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Tipo de Compra</mat-label>
            <mat-select required name="tipo_compra">
              <mat-option value="1">
                tipo de compra 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col s12 m12 l4 xl3">
          <mat-form-field>
            <mat-label>Método de Cálculo</mat-label>
            <mat-select required name="metodo_calculo">
              <mat-option value="1">
                metodo de calculo 1
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


    </mat-card>
    <br>
    <mat-card class="mat-elevation-z0">
      <mat-tab-group>
        <mat-tab label="Datos Generales" labelClass="mat-tab-label-0-0">
          <fieldset>
            <legend>Datos Dpc Financiera (1)</legend>
            <div class="row">
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Tipo de Dpo</mat-label>
                  <mat-select required name="tipo_dpo">
                    <mat-option value="1">
                      tipo 1
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Tasa Dpc.</mat-label>
                  <input type="number" min="0" matInput placeholder="Tasa Dpc." name="tasa_dpc" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l2 xl1">
                <mat-form-field>
                  <mat-label>Año Dpc.</mat-label>
                  <input type="number" min="0" matInput placeholder="Año Dpc." name="anio" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l2 xl1">
                <mat-form-field>
                  <mat-label>Mes Dpc.</mat-label>
                  <input type="number" min="0" matInput placeholder="Mes Dpc." name="mes" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Fecha de Alta</mat-label>
                  <input matInput [matDatepicker]="fechaalta">
                  <mat-datepicker-toggle matSuffix [for]="fechaalta"></mat-datepicker-toggle>
                  <mat-datepicker #fechaalta></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Fecha de Baja</mat-label>
                  <input matInput [matDatepicker]="fechabaja">
                  <mat-datepicker-toggle matSuffix [for]="fechabaja"></mat-datepicker-toggle>
                  <mat-datepicker #fechabaja></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Ref. Transferencia/Baja</mat-label>
                  <input type="number" min="0" matInput placeholder="Ref. Transferencia/Baja" name="ref_transferencia"
                    required>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12 l4 xl3">
                <mat-form-field>
                  <mat-label>Cuenta de activo</mat-label>
                  <mat-select required name="cuenta_activo">
                    <mat-option value="1">
                      cuenta activo 1
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4 xl3">
                <mat-form-field>
                  <mat-label>Cuenta de depreciación</mat-label>
                  <mat-select required name="cuenta_depreciacion">
                    <mat-option value="1">
                      cuenta depreciacion 1
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4 xl3">
                <mat-form-field>
                  <mat-label>Cuenta de revaluación</mat-label>
                  <mat-select required name="cuenta_revaluacion">
                    <mat-option value="1">
                      cuenta revaluacion 1
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4 xl3">
                <mat-form-field>
                  <mat-label>Centro de costos</mat-label>
                  <mat-select required name="centro_costos">
                    <mat-option *ngFor="let centro of centros" [value]="centro.ccs_codccs">
                      {{centro.ccs_desesp}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4 xl3">
                <mat-form-field>
                  <mat-label>Distribución de costos</mat-label>
                  <mat-select required name="distribucion_costo">
                    <mat-option value="1">
                      distribucion de costo 1
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </fieldset>
          <br>
          <fieldset>
            <legend>Datos Dpc. Tributaria (2)</legend>
            <div class="row">
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Tipo de Dpc.</mat-label>
                  <mat-select required name="tipo_dpc_2">
                    <mat-option value="1">
                      tipo dpc 1
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Tasa Dpc.</mat-label>
                  <input type="number" min="0" matInput placeholder="Tasa Dpc." name="tasa_dpc_2" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l2 xl1">
                <mat-form-field>
                  <mat-label>Año Dpc.</mat-label>
                  <input type="number" min="0" matInput placeholder="Año Dpc." name="anio_2" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l2 xl1">
                <mat-form-field>
                  <mat-label>Mes Dpc.</mat-label>
                  <input type="number" min="0" matInput placeholder="Mes Dpc." name="mes_2" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Fecha de Alta</mat-label>
                  <input matInput [matDatepicker]="fechaalta2">
                  <mat-datepicker-toggle matSuffix [for]="fechaalta2"></mat-datepicker-toggle>
                  <mat-datepicker #fechaalta2></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Fecha de Baja</mat-label>
                  <input matInput [matDatepicker]="fechabaja2">
                  <mat-datepicker-toggle matSuffix [for]="fechabaja2"></mat-datepicker-toggle>
                  <mat-datepicker #fechabaja2></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Ref. Transferencia/Baja</mat-label>
                  <input type="number" min="0" matInput placeholder="Ref. Transferencia/Baja" name="ref_transferencia_2"
                    required>
                </mat-form-field>
              </div>
            </div>
          </fieldset>
          <br>
          <fieldset>
            <legend>Datos de compra</legend>
            <div class="row">
              <div class="col s12 m12 l4 xl3">
                <mat-form-field>
                  <mat-label>Registro de compra</mat-label>
                  <input type="text" matInput placeholder="Registro de compra" name="registro_compra" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4 xl3">
                <proveedor-select [label]="'Proveedor'" [placeholder]="'Ej. GARCÍA'" [name]="'cli_codcli'"
                  [value]="'cli_codcli'" [description]="'cli_nomcli'" [disabled]="loaderData" [data]="proveedores"
                  [canAdd]="true" [canEdit]="true"
                  >
                </proveedor-select>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Tipo Doc.</mat-label>
                  <mat-select required name="tipo_doc">
                    <mat-option value="1">
                      tipo 1
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Nro. Doc.</mat-label>
                  <input type="text" matInput placeholder="Nro. Doc." name="nro_doc" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <app-currency-select [moneda]="'SO'"></app-currency-select>
              </div>
            </div>
          </fieldset>
        </mat-tab>
        <mat-tab label="Datos del Activo" labelClass="mat-tab-label-0-1">
          <fieldset>
            <legend>Datos Basicos:</legend>
            <div class="row">
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Fabricante</mat-label>
                  <input type="text" matInput placeholder="Fabricante" name="fabricante" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Marca</mat-label>
                  <input type="text" matInput placeholder="Marca" name="marca" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Modelo</mat-label>
                  <input type="text" matInput placeholder="Modelo" name="modelo" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Nro. de Serie</mat-label>
                  <input type="text" matInput placeholder="Nro. de Serie" name="nro_serie" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Nro. de Parte</mat-label>
                  <input type="text" matInput placeholder="Nro. de Parte" name="nro_parte" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Año de Fabricación</mat-label>
                  <input type="text" matInput placeholder="Año de Fabricación" name="anio_fabricacion" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3 xl2">
                <mat-form-field>
                  <mat-label>Pais de fabricación</mat-label>
                  <mat-select required name="pais_fabricacion">
                    <mat-option *ngFor="let pais of paises" [value]="pais.PAI_CODPAI">
                      {{pais.pai_nomlar}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l6">
                <mat-form-field class="example-full-width">
                  <mat-label>Descripción Técnica</mat-label>
                  <textarea matInput placeholder="Descripción Técnica" name="descripcion_tecnica"></textarea>
                </mat-form-field>
              </div>
            </div>
          </fieldset>
          <br>
          <fieldset>
            <legend>Datos Actuales</legend>
            <div class="row">
              <div class="col s12 m12 l6">
                <mat-form-field>
                  <mat-label>Propietario</mat-label>
                  <mat-select required name="propietario">
                    <mat-option value="1">
                      propietario 1
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l6">
                <mat-form-field>
                  <mat-label>Responsable</mat-label>
                  <mat-select required name="responsable">
                    <mat-option value="1">
                      responsable 1
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </fieldset>
          <br>
          <fieldset>
            <legend>Datos del Vehículo</legend>
            <div class="row">
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Nro. de Serie Motor</mat-label>
                  <input type="text" matInput placeholder="Nro. de Serie Motor" name="nro_serie_motor" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Nro. de Chasis</mat-label>
                  <input type="text" matInput placeholder="Nro. de Chasis" name="nro_chasis" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Nro. de Placa</mat-label>
                  <input type="text" matInput placeholder="Nro. de Placa" name="nro_placa" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Nro. de Asientos</mat-label>
                  <input type="text" matInput placeholder="Nro. de Asientos" name="nro_asientos" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Combustible</mat-label>
                  <mat-select required name="combustible">
                    <mat-option value="1">
                      combustible 1
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Nro. tarjeta de</mat-label>
                  <input type="text" matInput placeholder="Nro. tarjeta de" name="nro_tarjeta_de" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Peso Bruto</mat-label>
                  <input type="number" min="0" matInput placeholder="Peso Bruto" name="peso_bruto" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Peso Neto</mat-label>
                  <input type="number" min="0" matInput placeholder="Peso Neto" name="peso_neto" required>
                </mat-form-field>
              </div>
            </div>
          </fieldset>
          <br>
          <fieldset>
            <legend>Datos del inmueble</legend>
            <div class="row">
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Partida Original</mat-label>
                  <input type="text" matInput placeholder="Partida Original" name="partida_original" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Código de Catastro</mat-label>
                  <input type="text" matInput placeholder="Código de Catastro" name="codigo_catastro" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Área Total (m2)</mat-label>
                  <input type="number" min="0" matInput placeholder="Área Total (m2)" name="area_total" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Área Construida (m2)</mat-label>
                  <input type="number" min="0" matInput placeholder="Área Construida (m2)" name="area_construida"
                    required>
                </mat-form-field>
              </div>
            </div>
          </fieldset>
          <br>
          <fieldset>
            <legend>FOTO</legend>
            <div class="col s12">
              <ngx-dropzone (change)="onSelect($event)" [accept]="'image/jpeg, image/jpg, .pdf'">
                <ngx-dropzone-label>Subir imagenes (formatos permitidos JPEG o JPG) o PDF!</ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                  <ngx-dropzone-label>{{ f.name }} </ngx-dropzone-label>
                </ngx-dropzone-preview>
                <mat-spinner diameter="40" *ngIf="loadUpload"></mat-spinner>
              </ngx-dropzone>
            </div>
          </fieldset>
        </mat-tab>
        <mat-tab label="Financiero" labelClass="mat-tab-label-0-2">
          <div class="row">
            <div class="col s12 pavso-container-table">
              <div class="mat-elevation-z0">
                <table mat-table [dataSource]="dataSourceFinanciero" matSort>
                  <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                      <div class="pav-btns-container">
                        <div class="pav-btn-circle-edit" matTooltip="Editar"
                          [routerLink]="['/modulo-activo-fijo/editar-cliente-comercial', row.cli_codcli]">
                          <mat-icon svgIcon="edit-white"></mat-icon>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="anio">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
                    <td mat-cell *matCellDef="let row" data-label="Año"> {{row.cli_codcli}} </td>
                  </ng-container>

                  <ng-container matColumnDef="valor_soles">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor S/. </th>
                    <td mat-cell *matCellDef="let row" data-label="Valor S/."> {{row.cli_nomcli}} </td>
                  </ng-container>

                  <ng-container matColumnDef="valor_dolares">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor US$ </th>
                    <td mat-cell *matCellDef="let row" data-label="Valor US$"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="depre_soles">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Depre. S/. </th>
                    <td mat-cell *matCellDef="let row" data-label="Depre. S/."> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="depre_dolares">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Depre. US$ </th>
                    <td mat-cell *matCellDef="let row" data-label="Depre. US$"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="valor_merc_soles">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Merc. S/. </th>
                    <td mat-cell *matCellDef="let row" data-label="Valor Merc. S/."> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="valor_merc_dolar">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Merc. US$ </th>
                    <td mat-cell *matCellDef="let row" data-label="Valor Merc. US$"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsFinanciero"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsFinanciero;"></tr>

                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="8">No se encontraron registros</td>

                  </tr>

                </table>

                <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Tributario" labelClass="mat-tab-label-0-3">
          <div class="row">
            <div class="col s12 pavso-container-table">
              <div class="mat-elevation-z0">
                <table mat-table [dataSource]="dataSourceTributario" matSort>
                  <ng-container matColumnDef="acciones">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                    <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                      <div class="pav-btns-container">
                        <div class="pav-btn-circle-edit" matTooltip="Editar"
                          [routerLink]="['/modulo-activo-fijo/editar-cliente-comercial', row.cli_codcli]">
                          <mat-icon svgIcon="edit-white"></mat-icon>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="anio">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
                    <td mat-cell *matCellDef="let row" data-label="Año"> {{row.cli_codcli}} </td>
                  </ng-container>

                  <ng-container matColumnDef="valor_soles">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor S/. </th>
                    <td mat-cell *matCellDef="let row" data-label="Valor S/."> {{row.cli_nomcli}} </td>
                  </ng-container>

                  <ng-container matColumnDef="valor_dolares">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor US$ </th>
                    <td mat-cell *matCellDef="let row" data-label="Valor US$"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="depre_soles">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Depre. S/. </th>
                    <td mat-cell *matCellDef="let row" data-label="Depre. S/."> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="depre_dolares">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Depre. US$ </th>
                    <td mat-cell *matCellDef="let row" data-label="Depre. US$"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="valor_merc_soles">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Merc. S/. </th>
                    <td mat-cell *matCellDef="let row" data-label="Valor Merc. S/."> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <ng-container matColumnDef="valor_merc_dolar">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor Merc. US$ </th>
                    <td mat-cell *matCellDef="let row" data-label="Valor Merc. US$"> {{row.cli_numtlf}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsTributario"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsTributario;"></tr>

                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="8">No se encontraron registros</td>
                  </tr>

                </table>

                <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Otros" labelClass="mat-tab-label-0-4">
          <br>
          <fieldset>
            <legend>Datos del Arrendamiento Financiero</legend>
            <div class="row">
              <div class="col s12 m12 l2">
                <mat-form-field>
                  <mat-label>Cod. de Contacto</mat-label>
                  <input type="number" min="0" matInput placeholder="Área Construida (m2)" name="area_construida"
                    required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l2">
                <mat-form-field>
                  <mat-label>Monto Total</mat-label>
                  <input type="number" min="0" matInput placeholder="Monto Total" name="area_construida" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l2">
                <mat-form-field>
                  <mat-label>Número de cuotas</mat-label>
                  <input type="number" min="0" matInput placeholder="Número de cuotas" name="area_construida" required>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Fecha de Contrato</mat-label>
                  <input matInput [matDatepicker]="fechacontrato">
                  <mat-datepicker-toggle matSuffix [for]="fechacontrato"></mat-datepicker-toggle>
                  <mat-datepicker #fechacontrato></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">
                <mat-form-field>
                  <mat-label>Fec. Inicio de Arrendamiento</mat-label>
                  <input matInput [matDatepicker]="fechaarrendamiento">
                  <mat-datepicker-toggle matSuffix [for]="fechaarrendamiento"></mat-datepicker-toggle>
                  <mat-datepicker #fechaarrendamiento></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </fieldset>
          <h4>Documento de Legajo</h4>
          <div class="row">
            <div class="col s12 pavso-container-table"></div>
            <div class="mat-elevation-z0">
              <table mat-table [dataSource]="dataSourceDocumento" matSort>
                <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                  <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                    <div class="pav-btns-container">
                      <div class="pav-btn-circle-edit" matTooltip="Editar"
                        [routerLink]="['/modulo-activo-fijo/editar-cliente-comercial', row.cli_codcli]">
                        <mat-icon svgIcon="edit-white"></mat-icon>
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="item">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Item </th>
                  <td mat-cell *matCellDef="let row" data-label="Año"> {{row.cli_codcli}} </td>
                </ng-container>

                <ng-container matColumnDef="tipo_doc">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Doc. </th>
                  <td mat-cell *matCellDef="let row" data-label="Valor S/."> {{row.cli_nomcli}} </td>
                </ng-container>

                <ng-container matColumnDef="descripcion">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                  <td mat-cell *matCellDef="let row" data-label="Valor US$"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="referencia">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Referencia </th>
                  <td mat-cell *matCellDef="let row" data-label="Depre. S/."> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="fec_ingreso">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Ingreso </th>
                  <td mat-cell *matCellDef="let row" data-label="Depre. US$"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="fec_vigencia">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Vigencia </th>
                  <td mat-cell *matCellDef="let row" data-label="Valor Merc. S/."> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="fec_vcto">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Fec. Vcto. </th>
                  <td mat-cell *matCellDef="let row" data-label="Valor Merc. US$"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="estado">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                  <td mat-cell *matCellDef="let row" data-label="Valor Merc. US$"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="adj">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Adj. </th>
                  <td mat-cell *matCellDef="let row" data-label="Valor Merc. US$"> {{row.cli_numtlf}} </td>
                </ng-container>

                <ng-container matColumnDef="ver">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Ver </th>
                  <td mat-cell *matCellDef="let row" data-label="Valor Merc. US$"> {{row.cli_numtlf}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsDocumento"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsDocumento;"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="8">No se encontraron registros</td>
                </tr>

              </table>

              <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg"
      urlBack="/modulo-activo-fijo/activos-fijo">
      <div id="botonesAdicionales">
      </div>

    </pav-form-actions>

  </form>
</div>
