<app-pavso-title-section [title]="'Configuración para sugerir cuenta contable'" [module]="'Contabilidad'"></app-pavso-title-section>

<!-- <div class="pavso_tiles">
    <span class="pavso_title">CONTABILIDAD</span>
    <h2 class="pavso_subtitle">
        CONFIGURACIÓN PARA SUGERIR CUENTA CONTABLE
        <button [matMenuTriggerFor]="menuInfoTitle" mat-icon-button color="primary" >
            <mat-icon>info</mat-icon>
        </button>
        <mat-menu #menuInfoTitle="matMenu">
            <div class="info-mat-menu">
                Configuración para sugerir cuenta contable por tipo de documento, sin control de cuenta corriente.
            </div>
        </mat-menu>
    </h2>
</div> -->

<div class="pavso-content-form fade-in-image">
  <div class="pav-table-search-container">
    <div class="pav-separator"></div>
    <div class="pav-table-search">
      <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input>
    </div>
  </div>

    <div class="mat-elevation-z0">

      <table-title-action title="Todas las configuraciones" url="/modulo-contabilidad/configuracion-por-tipo-documento" entity="" [params]="['0']"></table-title-action>

      <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

      <div class="overflow-x">
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">
                    <button matTooltip="Editar" [routerLink]="['/modulo-contabilidad/configuracion-por-tipo-documento', row.CHO_CODCHO]" mat-icon-button color="primary">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="CHO_CODCHO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let row" data-label="Código" class="td-responsive"> {{row.CHO_CODCHO}} </td>
            </ng-container>

            <ng-container matColumnDef="CHO_NOMBRE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Chofer </th>
                <td mat-cell *matCellDef="let row" data-label="Chofer" class="td-responsive"> {{row.CHO_NOMBRE}} </td>
            </ng-container>

            <ng-container matColumnDef="CHO_NROLIC">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Licencia </th>
                <td mat-cell *matCellDef="let row" data-label="Nro. Licencia" class="td-responsive"> {{row.CHO_NROLIC}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No se encontraron registros "{{input.value}}"</td>

            </tr>

        </table>

      </div>


        <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>

<map name="infographic">
    <area shape="rect" coords="184,6,253,27"
          href="https://mozilla.org"
          target="_blank" alt="Mozilla" />
    <area shape="circle" coords="130,136,60"
          href="https://developer.mozilla.org/"
          target="_blank" alt="MDN" />
    <area shape="poly" coords="130,6,253,96,223,106,130,39"
          href="https://developer.mozilla.org/docs/Web/Guide/Graphics"
          target="_blank" alt="Graphics" />
    <area shape="poly" coords="253,96,207,241,189,217,223,103"
          href="https://developer.mozilla.org/docs/Web/HTML"
          target="_blank" alt="HTML" />
    <area shape="poly" coords="207,241,54,241,72,217,189,217"
          href="https://developer.mozilla.org/docs/Web/JavaScript"
          target="_blank" alt="JavaScript" />
    <area shape="poly" coords="54,241,6,97,36,107,72,217"
          href="https://developer.mozilla.org/docs/Web/API"
          target="_blank" alt="Web APIs" />
    <area shape="poly" coords="6,97,130,6,130,39,36,107"
          href="https://developer.mozilla.org/docs/Web/CSS"
          target="_blank" alt="CSS" />
</map>

