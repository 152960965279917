export class FiltroConsulta {
  clientes: Array<any>;
  moneda: string;
  diasVencer: number;
  tiposDocumento: Array<any>;
  situacion: string;
  notificados: 'SI' | 'NO' | 'TODOS';

  constructor() {
    this.clientes = [];
    this.moneda = 'DO';
    this.tiposDocumento = ["LET"];
    this.situacion = 'vencidos';
    this.diasVencer = 7;
    this.notificados = 'NO';
  }
}
