import { REPORT_HEADER_COLOR } from "@data/constants/configuration/configuration.constant";
import { PavsoColors } from "@data/enums/color.enum";
import { formatDateWithSlash } from "@utils/formats/date.format";
import { horizontalBorder, noBorders, openLeftBorder, openRightBorder, topBorder } from "@utils/pdfmaker/utils/pdfmake.constant";
import { obtenerNulls } from "@utils/pdfmaker/utils/pdfmake.function";
import { Cell, PdfMakeWrapper, Stack, Table, Txt } from "pdfmake-wrapper";
import { number2month } from '../../conversions/number2month.conversion';

/**
 *
 * @param headers
 * @param data
 */
export async function generarPDFDocumentoCobrarDocConSaldo(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);
  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt('CLIENTE:').fontSize(8).bold().end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.CLI_CODCLI).fontSize(8).end
      ).border(noBorders).colSpan(2).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(element.cli_nomcli).fontSize(8).end
      ).border(noBorders).colSpan(6).end,
      ...obtenerNulls(5),
    ],)

    element.detalle.forEach(item => {
      detalle.push([
        new Cell(
          new Txt(`${item.TDO_CODTDO}/${item.CCO_NUMDOC}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.CCO_FECEMI)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.cco_fecven)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.CCO_CODCCO).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.TMO_CODTMO).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.CCO_IMPDOC * item.TDO_INDSIG).toFixed(2)).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.CCO_IMPABO * item.TDO_INDSIG).toFixed(2)).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.cco_impsal * item.TDO_INDSIG).toFixed(2)).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('Generación Automatica de Pedido').fontSize(7).end
        ).border(noBorders).end,
      ],)
    })
  });

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt("DEMO PAVSO").fontSize(7).end,
            new Txt("RFIN_DOCU_COBR").fontSize(7).end,
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('DOCUMENTOS POR COBRAR **Doc. con Saldo').fontSize(10).bold().alignment('center').end,
            new Txt(`${headers.dia} de ${ number2month(headers.mes) } de ${headers.ano}`).fontSize(8).bold().alignment('center').end
          ]).end
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(
          new Stack([
            new Txt("").end,
            new Txt("").end,
          ]).end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('DOCUMENTO').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F. EMI.').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F. VEN.').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('CTA. CTE.').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('M').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('IMPORTE').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('ABONO').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('SALDO').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('REFERENCIA').fontSize(8).bold().end
        ).color(PavsoColors.White).fillColor(PavsoColors.Blue).end
      ],

      ...detalle,


      [
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('S/.').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('-15.00').alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('0.00').alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('-15.00').alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('TOTAL GRUPO').fontSize(8).bold().end
        ).border(openRightBorder).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt('US$').fontSize(8).bold().end,
            new Txt('S/.').fontSize(8).bold().end
          ]).end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('').end
        ).border(horizontalBorder).end,
        new Cell(
          new Txt('').end
        ).border(horizontalBorder).end,
        new Cell(
          new Stack([
            new Txt('-15.00').alignment('right').fontSize(8).bold().end,
            new Txt('-15.00').alignment('right').fontSize(8).bold().end
          ]).end
        ).border(openLeftBorder).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
      ],
      [
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
        new Cell(
          new Txt('TOTAL GENERAL').fontSize(8).bold().alignment('right').end
        ).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt('US$').fontSize(8).bold().end,
            new Txt('S/.').fontSize(8).bold().end
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('-15.00').alignment('right').fontSize(8).bold().end,
            new Txt('-15.00').alignment('right').fontSize(8).bold().end
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('-15.00').alignment('right').fontSize(8).bold().end,
            new Txt('-15.00').alignment('right').fontSize(8).bold().end
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Stack([
            new Txt('-15.00').alignment('right').fontSize(8).bold().end,
            new Txt('-15.00').alignment('right').fontSize(8).bold().end
          ]).end
        ).border(noBorders).end,
        new Cell(
          new Txt('').end
        ).border(noBorders).end,
      ]
    ]).headerRows(2).widths([120, 65, 65, 85, 45, 70, 70, 70, 120]).end
  );

  pdf.create().open();
}

/**
 *
 * @param headers
 * @param data
 */
export async function generarPDFPagoEfectuadoCliente(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);
  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  pdf.create().open();

}

/**
 *
 * @param headers
 * @param data
 */
export async function generarPDFTabuladoCuentaPorCobrar(headers, data) {
  const pdf = new PdfMakeWrapper();

  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);
  pdf.header(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });
  pdf.footer(function (currentPage, pageCount, pageSize) {
    return [
      { text: `Usuario: ${headers.username}`, fontSize: 10, bold: true, margin: [ 5, 2, 10, 10 ], alignment: 'right'},
    ];
  })

  let detalle = [];

  let [totSal, tot01, tot02, tot03, tot04, tot05] = [0, 0, 0, 0, 0, 0, 0];

  let [totGNac, totGDol] = [0, 0];

  data.forEach(element => {
    detalle.push([
      new Cell(
        new Txt(element.CLI_CODCLI).bold().fontSize(8).end
      ).border(noBorders).end,
      new Cell(
        new Txt(element.cli_nomcli).fontSize(8).bold().end
      ).colSpan(6).border(noBorders).end,
      ...obtenerNulls(5),
      new Cell(
        new Txt(`Vendedor ${element.vde_nomvde}`).bold().fontSize(8).end
      ).colSpan(5).border(noBorders).end,
      ...obtenerNulls(4)
    ],)

    let [tSal, t01, t02, t03, t04, t05] = [0, 0, 0, 0, 0, 0, 0];

    element.detalle.forEach(item => {

      let [imp01, imp02, imp03, imp04, imp05] = [0, 0, 0, 0, 0];

      if(item.grupo_cobranza == '01') {
        imp01 = item.CCO_SALCCO + item.CCO_SALLBA + item.CCO_SALRES;
      }
      if(item.grupo_cobranza == '02') {
        imp02 = item.CCO_SALCCO + item.CCO_SALLBA + item.CCO_SALRES;
      }
      if(item.grupo_cobranza == '03') {
        imp03 = item.CCO_SALCCO + item.CCO_SALLBA + item.CCO_SALRES;
      }
      if(item.grupo_cobranza == '04') {
        imp04 = item.CCO_SALCCO + item.CCO_SALLBA + item.CCO_SALRES;
      }
      if(item.grupo_cobranza == '05') {
        imp05 = item.CCO_SALCCO + item.CCO_SALLBA + item.CCO_SALRES;
      }

      tSal += item.cco_salrea;
      t01 += imp01;
      t02 += imp02;
      t03 += imp03;
      t04 += imp04;
      t05 += imp05;

      totGNac += item.cco_salnac;
      totGDol += item.cco_saldol;

      detalle.push([
        new Cell(
          new Txt(`${item.TDO_CODTDO}/${item.CCO_NUMDOC}`).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.CCO_FECEMI)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(formatDateWithSlash(item.cco_fecven)).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.CPA_DESCRI).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.TMO_CODTMO).fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(( imp01 + imp02 + imp03 + imp04 + imp05 ).toFixed(2)).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cco_diaven2).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(imp01.toFixed(2)).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(imp02.toFixed(2)).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(imp03.toFixed(2)).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(imp04.toFixed(2)).alignment('right').fontSize(7).end
        ).border(noBorders).end,
        new Cell(
          new Txt(imp05.toFixed(2)).alignment('right').fontSize(7).end
        ).border(noBorders).end,
      ],)
    })

    detalle.push([
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt('').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(tSal.toFixed(2)).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt('').alignment('right').bold().fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(t01.toFixed(2)).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt(t02.toFixed(2)).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt(t03.toFixed(2)).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt(t04.toFixed(2)).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt(t05.toFixed(2)).alignment('right').bold().fontSize(7).end
      ).border(topBorder).end,
    ],)

    totSal += tSal;
    tot01 += t01;
    tot02 += t02;
    tot03 += t03;
    tot04 += t04;
    tot05 += t05;
  });

  detalle.push([
    new Cell(
      new Txt('TOTAL SOLES').bold().alignment('right').fontSize(7).end
    ).colSpan(5).border(noBorders).end,
    ...obtenerNulls(4),
    new Cell(
      new Txt(totSal.toFixed(2)).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt(tot01.toFixed(2)).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt(tot02.toFixed(2)).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt(tot03.toFixed(2)).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt(tot04.toFixed(2)).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
    new Cell(
      new Txt(tot05.toFixed(2)).alignment('right').bold().fontSize(7).end
    ).border(topBorder).end,
  ],)

  detalle.push([
    new Cell(
      new Txt('TOTAL GENERAL').bold().alignment('right').fontSize(7).end
    ).colSpan(5).border(noBorders).end,
    ...obtenerNulls(4),
    new Cell(
      new Stack([
        new Txt(`US$ ${totGDol.toFixed(2)}`).alignment('right').bold().fontSize(7).end,
        new Txt(`S/. ${totGNac.toFixed(2)}`).alignment('right').bold().fontSize(7).end
      ]).end
    ).border(topBorder).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
    new Cell(
      new Txt('').alignment('right').bold().fontSize(7).end
    ).border(noBorders).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(
          new Stack([
            new Txt('DEMO PAVSO').bold().fontSize(8).end,
            new Txt('RFIN_FACT_COBR').bold().fontSize(8).end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
        new Cell(
          new Stack([
            new Txt('Cobrar Tabulado **Doc. con Saldo').alignment('center').bold().fontSize(12).end,
            new Txt('01 de Junio de 2023').alignment('center').bold().fontSize(10).end
          ]).end
        ).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(
          new Stack([
            new Txt('Fecha : 1/06/2023').alignment('right').bold().fontSize(8).end,
            new Txt('Hora : 09:53:48').alignment('right').bold().fontSize(8).end,
            new Txt('Pág.: 32').alignment('right').bold().fontSize(8).end
          ]).end
        ).border(noBorders).colSpan(2).end,
        ...obtenerNulls(1),
      ],
      [
        new Cell(
          new Txt('DOCUMENTO').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F.EMI').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('F.VEN').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('CONDICIÓN PAGO').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('M').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('SALDO').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('ATRASO(+Ó-)').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('VIGENTES').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('VENC. <30').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('VENC. <60').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('VENC. <90').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt('VENC. >90').alignment('right').fontSize(8).bold().end
        ).border(noBorders).color(PavsoColors.White).fillColor(PavsoColors.Blue).end,
      ],
      ...detalle,
    ]).widths([80, 40, 40, 80, 30, 60, 60, 60, 60, 60, 60, 60]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Movimientos de cobranza
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function generarPDFMovimientosDeCobranza(headers: any, response) {
  // Variables

  // Diseño
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);
  pdf.footer(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });

  let items = [];

  let totalMNCuenta = 0
  let totalMECuenta = 0

  response.forEach(auxiliar => {
    items.push([
      new Cell(
        new Txt("Auxiliar").bold().fontSize(7).end
      ).fillColor("#eee").colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(auxiliar.auxiliar_code).bold().fontSize(7).end
      ).fillColor("#eee").colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(auxiliar.auxiliar_descri).bold().fontSize(7).end
      ).fillColor("#eee").border(noBorders).colSpan(12).end,
      ...obtenerNulls(11),
    ])

    let totalMNSaldo = 0
    let totalMESaldo = 0

    auxiliar.detalle.forEach(item => {

      let impDebeSoles = (item.ldi_INDDHA == "D")? item.LDI_IMPNAC: 0
      let impDebeDolares = (item.ldi_INDDHA == "D")? item.LDI_IMPMEX: 0
      let impHaberSoles = (item.ldi_INDDHA == "H")? (item.LDI_IMPNAC)*(-1): 0
      let impHaberDolares = (item.ldi_INDDHA == "H")? (item.LDI_IMPMEX)*(-1): 0
      let saldoSoles = (impDebeSoles == 0)? impHaberSoles: impDebeSoles;
      let saldoDolares = (impDebeDolares == 0)? impHaberDolares: impDebeDolares;

      totalMNSaldo += saldoSoles
      totalMESaldo += saldoDolares

      items.push([
        new Cell(
          new Txt((item.cco_fecemi)?item.cco_fecemi.substring(0, 10): "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.cco_fecven)?item.cco_fecven.substring(0, 10): "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${item.ANO_CODANO_LDI}-${item.MES_CODMES_LDI}-${item.sco_codsco}-${item.ldc_CORldc}-${item.ldi_corldi}`).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tdo_codtdo).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_docref).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tdo_codban).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.PBA_NUMDOC).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cba_descri).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.sco_descri).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_gloldi).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impDebeSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impHaberSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(saldoSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impDebeDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impHaberDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(saldoDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
      ])
    });

    items.push([
      new Cell(
        new Txt("TOTAL AUXILIAR          70484848").alignment('right').fontSize(7).end
      ).colSpan(10).border(noBorders).end,
      ...obtenerNulls(9),
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(totalMNSaldo.toFixed(2)).alignment('right').fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(totalMESaldo.toFixed(2)).alignment('right').fontSize(7).end
      ).border(topBorder).end,
    ])

    totalMNCuenta += totalMNSaldo
    totalMECuenta += totalMESaldo
  });

  items.push([
    new Cell(
      new Txt(`TOTAL CUENTA          ${response[0].cuenta}`).alignment('right').fontSize(6).end
    ).colSpan(10).border(noBorders).end,
    ...obtenerNulls(9),
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(totalMNCuenta.toFixed(2)).alignment('right').fontSize(6).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(totalMECuenta.toFixed(2)).alignment('right').fontSize(6).end
    ).border(topBorder).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(10).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(new Txt(headers.date).alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(10).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Movimientos de cobranza").alignment('center').bold().fontSize(14).end).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(new Txt("").alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`Acumulado hasta ${headers.codano} ${headers.codmes}`).alignment('center').bold().fontSize(10).end,
          ]).end,
        ).border(noBorders).colSpan(8).end,
        ...obtenerNulls(7),
        new Cell(new Txt("").alignment('left').end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("FEC. OPERACIÓN").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("FEC. VENC.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("# CORRELATIVO DE ASIENTO").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("TIP. DOC.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("NRO. DOC.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("DOC. BCO.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("# OPERACIÓN").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("BANCO").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("SUBDIARIO").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("GLOSA").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("MOVIMIENTO MONEDA NACIONAL").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("MOVIMIENTO MONEDA EXTRANJERA").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        ...obtenerNulls(10),
        new Cell(
          new Txt("DEBE").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("HABER").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("SALDO").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("DEBE").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("HABER").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("SALDO").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
      ],

      ...items,
    ]).headerRows(5).widths([40, 32, 80, 15, 50, 20, 40, 45, 75, 70, 35, 35, 35, 35, 35, 35]).end
  )

  pdf.create().open();

}

/**
 * Reporte de Estado cuenta cliente
 * @param response data to fill report
 * @param headers data to fill headers
 */
export function generarPDFEstadoCuentaCliente(headers: any, response) {

  // Diseño
  const pdf = new PdfMakeWrapper();
  pdf.pageOrientation('landscape');
  pdf.pageMargins([ 20, 20, 20, 20 ]);
  pdf.footer(function(currentPage, pageCount) { return currentPage.toString() + '/' + pageCount; });

  let items = [];

  let totalMNCuenta = 0
  let totalMECuenta = 0

  response.forEach(auxiliar => {
    items.push([
      new Cell(
        new Txt("Auxiliar").bold().fontSize(7).end
      ).fillColor("#eee").colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(auxiliar.auxiliar_code).bold().fontSize(7).end
      ).fillColor("#eee").colSpan(2).border(noBorders).end,
      ...obtenerNulls(1),
      new Cell(
        new Txt(auxiliar.auxiliar_descri).bold().fontSize(7).end
      ).fillColor("#eee").border(noBorders).colSpan(11).end,
      ...obtenerNulls(10),
    ])

    let totalMNSaldo = 0
    let totalMESaldo = 0

    auxiliar.detalle.forEach(item => {

      let impDebeSoles = (item.ldi_INDDHA == "D")? item.LDI_IMPNAC: 0
      let impDebeDolares = (item.ldi_INDDHA == "D")? item.LDI_IMPMEX: 0
      let impHaberSoles = (item.ldi_INDDHA == "H")? (item.LDI_IMPNAC)*(-1): 0
      let impHaberDolares = (item.ldi_INDDHA == "H")? (item.LDI_IMPMEX)*(-1): 0
      let saldoSoles = (impDebeSoles == 0)? impHaberSoles: impDebeSoles;
      let saldoDolares = (impDebeDolares == 0)? impHaberDolares: impDebeDolares;

      totalMNSaldo += saldoSoles
      totalMESaldo += saldoDolares

      items.push([
        new Cell(
          new Txt((item.cco_fecemi)?item.cco_fecemi.substring(0, 10): "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt((item.cco_fecven)?item.cco_fecven.substring(0, 10): "").fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(`${item.ANO_CODANO_LDI}-${item.MES_CODMES_LDI}-${item.sco_codsco}-${item.ldc_CORldc}-${item.ldi_corldi}`).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.tdo_codtdo).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_docref).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.PBA_NUMDOC).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.cba_descri).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.sco_descri).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(item.ldi_gloldi).fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impDebeSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impHaberSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(saldoSoles.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impDebeDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(impHaberDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
        new Cell(
          new Txt(saldoDolares.toFixed(2)).alignment('right').fontSize(6).end
        ).border(noBorders).end,
      ])
    });

    items.push([
      new Cell(
        new Txt("TOTAL AUXILIAR          70484848").alignment('right').fontSize(7).end
      ).colSpan(9).border(noBorders).end,
      ...obtenerNulls(8),
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(totalMNSaldo.toFixed(2)).alignment('right').fontSize(7).end
      ).border(topBorder).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt("").alignment('right').fontSize(7).end
      ).border(noBorders).end,
      new Cell(
        new Txt(totalMESaldo.toFixed(2)).alignment('right').fontSize(7).end
      ).border(topBorder).end,
    ])

    totalMNCuenta += totalMNSaldo
    totalMECuenta += totalMESaldo
  });

  items.push([
    new Cell(
      new Txt(`TOTAL CUENTA          ${response[0].cuenta}`).alignment('right').fontSize(6).end
    ).colSpan(9).border(noBorders).end,
    ...obtenerNulls(8),
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(totalMNCuenta.toFixed(2)).alignment('right').fontSize(6).end
    ).border(topBorder).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt("").alignment('right').fontSize(6).end
    ).border(noBorders).end,
    new Cell(
      new Txt(totalMECuenta.toFixed(2)).alignment('right').fontSize(6).end
    ).border(topBorder).end,
  ],)

  pdf.add(
    new Table([
      [
        new Cell(new Txt(headers.clientName).fontSize(7).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("").end).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt(headers.date).alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt(`${headers.client}`).fontSize(6).end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(new Txt("Estado de cuenta cliente").alignment('center').bold().fontSize(14).end).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt("").alignment('right').fontSize(10).end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(new Txt("").end).border(noBorders).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Stack([
            new Txt(`Acumulado hasta ${headers.codano} ${headers.codmes}`).alignment('center').bold().fontSize(10).end,
          ]).end,
        ).border(noBorders).colSpan(7).end,
        ...obtenerNulls(6),
        new Cell(new Txt("").alignment('left').end).border(noBorders).colSpan(5).end,
        ...obtenerNulls(4),
      ],
      [
        new Cell(
          new Txt("FEC. OPERACIÓN").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("FEC. VENC.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("# CORRELATIVO DE ASIENTO").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("TIPO DOC.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("NRO. DOC.").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("# OPERACIÓN").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("BANCO").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("SUBDIARIO").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("GLOSA").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).rowSpan(2).end,
        new Cell(
          new Txt("MOVIMIENTO MONEDA NACIONAL").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
        new Cell(
          new Txt("MOVIMIENTO MONEDA EXTRANJERA").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).colSpan(3).end,
        ...obtenerNulls(2),
      ],
      [
        ...obtenerNulls(9),
        new Cell(
          new Txt("DEBE").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("HABER").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("SALDO").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("DEBE").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("HABER").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
        new Cell(
          new Txt("SALDO").alignment('center').bold().fontSize(6).end
        ).border(noBorders).color(REPORT_HEADER_COLOR).fillColor(PavsoColors.Blue).end,
      ],

      ...items,
    ]).headerRows(5).widths([40, 40, 80, 20, 50, 40, 40, 40, 110, 35, 35, 35, 35, 35, 35]).end
  )

  pdf.create().open();

}
