<app-pavso-title-section [title]="'Comprobante'" [module]="'Ventas'" [ngClass]="'pav-form'" [estado]="voucher.CCO_INDSTA">

  <form #f="ngForm" ngNativeValidate (ngSubmit)="registrarComprobante(f)" (keydown.enter)="$event.preventDefault()">
    <div class="pavso-content-form fade-in-image">
    <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>
      <div class="row">
        <div class="col s12 m12 l12 xl9">
          <mat-card class="mat-elevation-z0">
            <div class="row">
              <div class="col s12 m12 l4 xl3">
                <mat-form-field>
                  <mat-label>Tipo de Documento</mat-label>
                  <mat-select [(ngModel)]="voucher.TDO_CODTDO" name="TDO_CODTDO" disabled>
                    <mat-option *ngFor="let tipo of tipoDocumentosVenta" [value]="tipo.tdo_codtdo">
                      {{tipo.tdo_deslar}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col s12 m12 l4 xl1">
                <mat-form-field class="example-full-width">
                  <mat-label>Serie</mat-label>
                  <input type="text" matInput placeholder="Serie" [(ngModel)]="serie" name="serie"
                    >
                </mat-form-field>
              </div>
              <div class="col s12 m12 l2">
                <mat-form-field class="example-full-width">
                  <mat-label>Nro. Doc.</mat-label>
                  <input type="text" matInput placeholder="Nro. Documento" disabled [(ngModel)]="voucher.CCO_NUMDOC"
                    name="CCO_NUMDOC" >
                </mat-form-field>
              </div>

              <div class="col s12 m12 l4 xl3">
                <mat-form-field>
                  <mat-label>Fecha</mat-label>
                  <input  [(ngModel)]="voucher.CCO_FECREG"
                    name="CCO_FECREG" matInput [matDatepicker]="picker" placeholder="Fecha"
                    (dateChange)="seleccionarFecha()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col s12 m12 l1">
                <mat-form-field class="example-full-width">
                  <mat-label>T.C.</mat-label>
                  <input [(ngModel)]="voucher.CCO_TIPCAM" name="CCO_TIPCAM" type="number" matInput placeholder="T.C."
                    step="any" disabled>
                </mat-form-field>
              </div>

              <div class="col s12 m12 l1">
                <mat-form-field class="example-full-width">
                  <mat-label>IGV</mat-label>
                  <input [(ngModel)]="voucher.CCO_TASIGV" name="CCO_TASIGV" type="number" matInput placeholder="Tas. IGV"
                    step="any" >
                  <mat-icon matSuffix>percent</mat-icon>
                </mat-form-field>
              </div>



              <div class="col s12 m12 l3">
                <app-currency-select [moneda]="voucher.TMO_CODTMO"
                  (cambioMoneda)="seleccionarTipoMoneda($event)"></app-currency-select>
              </div>

              <div class="col s12 m12 l6">
                <select-search [label]="'Cliente'" [placeholder]="'Ej. PEREZ'" [name]="'cli_codcli'" [value]="'cli_codcli'" [description]="'description'" [data]="clientes" (cambioSelect)="voucher.CLI_CODCLI = $event; seleccionarCondicionPago($event); seleccionarCliente()" [model]="voucher.CLI_CODCLI"></select-search>

                <!-- <mat-form-field class="example-full-width">
                  <mat-label>Cliente</mat-label>
                  <input type="text" matInput placeholder="Cliente" [ngModel]="nameClient" name="CLI_CODCLI"
                    (click)="listarClientes()" >
                </mat-form-field> -->
              </div>

              <div class="col s6 m12 l3">
                <mat-form-field class="example-full-width">
                  <mat-label>RUC</mat-label>
                  <input type="number" matInput placeholder="RUC" [(ngModel)]="rucClient" name="rucClient" disabled>
                </mat-form-field>
              </div>

              <div class="col s6 m12 l3">
                <mat-form-field class="example-full-width">
                  <mat-label>DNI / Otros</mat-label>
                  <input type="number" matInput placeholder="DNI / Otros" [(ngModel)]="dniClient" name="dniClient"
                    disabled>
                </mat-form-field>
              </div>
              <div class="col s12 m12 l3">

                <select-search [label]="'Vendedor'" [placeholder]="'Ej. PEREZ'" [name]="'VDE_CODVDE'" [value]="'VDE_CODVDE'" [description]="'VDE_NOMVDE'" [data]="vendedores" (cambioSelect)="voucher.detalle_dcc[0].VDE_CODVDE = $event" [model]="voucher.detalle_dcc[0].VDE_CODVDE"></select-search>

              </div>

              <div class="col s12 m12 l6">
                <mat-form-field class="example-full-width">
                  <mat-label>Dirección</mat-label>
                  <input type="text" matInput placeholder="Dirección" [(ngModel)]="direccion" name="direccion"
                    >
                </mat-form-field>
              </div>

            </div>
          </mat-card>
        </div>
        <div class="col s12 m12 l12 xl3">
          <mat-card class="mat-elevation-z0">

            <div class="row">
              <div class="col s12">

                <mat-checkbox class="example-margin" name="exportacion" [(ngModel)]="voucher.detalle_dcc[0].DCC_INDEXP"
                  name="DCC_INDEXP" >Exportación</mat-checkbox>
                &nbsp;
                <mat-checkbox class="example-margin" name="tipo" [(ngModel)]="voucher.detalle_dcc[0].DCC_INDTEX" name="DCC_INDTEX"
                  >Tipo Texto</mat-checkbox>
              </div>

              <div class="col s12">
                <br>
                <section class="example-section">

                </section>
              </div>
              <div class="col s12 m12 l6">
                <section class="example-section">
                  <mat-checkbox class="example-margin" [(ngModel)]="voucher.detalle_dcc[0].DCC_INDPSA" name="DCC_INDPSA"
                    >Genera parte de almacen</mat-checkbox>
                </section>
              </div>

              <div class="col s12">
                <mat-form-field>
                  <mat-label>Almacen</mat-label>
                  <mat-select [(ngModel)]="voucher.detalle_dcc[0].ALM_CODALM" name="ALM_CODALM" required >
                    <mat-option [value]="almacen.ALM_CODALM" *ngFor="let almacen of almacenes">
                      {{almacen.ALM_DESCRI}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

      <br>

      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l4 xl2">
            <mat-form-field>
              <mat-label>Motivo</mat-label>
              <mat-select [(ngModel)]="voucher.MMO_CODMMO" name="MMO_CODMMO" >
                <mat-option *ngFor="let motivo of motivos" [value]="motivo.mmo_codmmo">
                  {{motivo.mmo_descri}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l4 xl2">

            <select-search [label]="'Condi. Pago.'" [placeholder]="'Ej. CONTADO'" [name]="'CPA_CODCPA'" [value]="'CPA_CODCPA'" [description]="'CPA_DESABR'" [data]="condiciones" (cambioSelect)="voucher.CPA_CODCPA = $event; seleccionarCondicionPago($event)" [model]="voucher.CPA_CODCPA"></select-search>

          </div>

          <div class="col s12 m12 l4 xl2">
            <mat-form-field>
              <mat-label>Vencimiento</mat-label>
              <input [(ngModel)]="voucher.CCO_FECVEN"
                name="CCO_FECVEN" matInput [matDatepicker]="picker1" >
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4 xl2">
            <mat-form-field>
              <mat-label>Lista</mat-label>
              <mat-select name="listaPrecios" [(ngModel)]="voucher.detalle_dcc[0].TLP_CODTLP"
                (selectionChange)="seleccionDeLista($event.value)" >
                <mat-option value="1" *ngFor="let tipo of tiposPrecio" [value]="tipo.TLP_CODTLP">
                  {{tipo.tlp_descri}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l4 xl2">
            <mat-form-field class="example-full-width">
              <mat-label>Nro</mat-label>
              <input type="number" matInput placeholder="Nro" [(ngModel)]="voucher.detalle_dcc[0].LPC_CODLPC" name="LPC_CODLPC"
                >
            </mat-form-field>
          </div>

          <div class="col s12 m12 l4 xl2">
            <mat-form-field class="example-full-width">
              <mat-label>Tab. Dscto.</mat-label>
              <input type="text" matInput placeholder="Tab. Dscto." [(ngModel)]="voucher.detalle_dcc[0].TBD_NUMTBD"
                name="TBD_NUMTBD" disabled>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col s12 m12 l4">
            <mat-form-field>
              <mat-label>Calcula Item</mat-label>
              <mat-select [(ngModel)]="voucher.detalle_dcc[0].DCC_INDCAL" name="DCC_INDCAL" >
                <mat-option [value]="item.fci_codfci" *ngFor="let item of formasCalculoItem">
                  {{item.fci_descri}}
                </mat-option>

              </mat-select>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4 xl2" *ngIf="voucher.TDO_CODTDO == 'NDB' || voucher.TDO_CODTDO == 'NCR'">
            <mat-form-field>
              <mat-label>Tipo Doc. Referencia</mat-label>
              <mat-select [disabled]="!estaActivoReferencia">
                <mat-option *ngFor="let tipo of tipoDocumentosVenta" [value]="tipo.tdo_codtdo">
                  {{tipo.tdo_deslar}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col s12 m12 l4 xl2" *ngIf="voucher.TDO_CODTDO == 'NDB' || voucher.TDO_CODTDO == 'NCR'">
            <mat-form-field>
              <mat-label>Num. Doc. Referencia</mat-label>
              <mat-select [disabled]="!estaActivoReferencia" [(ngModel)]="voucher.detalle_dcc[0].CCS_CODCCS" name="CCS_CODCCS">
                <mat-option *ngFor="let centro of almacenes" [value]="centro.ccs_codccs">
                  {{centro.ccs_desesp}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l4 xl1">
            <mat-form-field class="example-full-width">
              <mat-label>Pedido</mat-label>
              <input type="number" matInput placeholder="Pedido" name="PCC_NUMPCC" [(ngModel)]="voucher.detalle_dcc[0].PCC_NUMPCC"
                disabled>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l4 xl1">
            <mat-form-field class="example-full-width">
              <mat-label>Cta. Cte.</mat-label>
              <input type="text" matInput placeholder="Cta. Cte." [(ngModel)]="voucher.CCO_CODCCO" name="CCO_CODCCO"
                disabled>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l6">
            <mat-form-field class="example-full-width">
              <mat-label>Kardex</mat-label>
              <textarea matInput placeholder="Kardex" name="kardex" disabled></textarea>
            </mat-form-field>
          </div>

          <div class="col s12 m12 l6">
            <mat-form-field class="example-full-width">
              <mat-label>Documentos generados</mat-label>
              <textarea matInput placeholder="Documentos generados" name="nota_credito" disabled></textarea>
            </mat-form-field>
          </div>

        </div>

      </mat-card>

      <div class="pav-table-header-container">
        <div class="pav-table-header-icon">
        </div>
        <div class="pav-table-header-title">
          <div [ngStyle]="{display: 'flex', alignItems: 'center'}">
            <div>
              Detalle
            </div>


          </div>

        </div>
        &nbsp;
        &nbsp;
        &nbsp;

        &nbsp;
        <div>
          <div class="pav-button-icon-add-modal" matTooltip="Agregar ítem" (click)="agregarProductoModal()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="add-modal-detail"></mat-icon>
          </div>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
          </p>
        </div>
        &nbsp;
        <div>
          <div class="pav-button-icon-add" matTooltip="Agregar ítem barra" (click)="agregarProducto()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="add-circle"></mat-icon>
          </div>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Agregar</span>
          </p>
        </div>
        &nbsp;
        <div>
          <div class="pav-button-icon-delete" matTooltip="Eliminar ítem"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="remove"></mat-icon>
          </div>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px'}">Eliminar</span>
          </p>
        </div>
        &nbsp;
        <div>
          <div class="pav-button-icon-insert" matTooltip="Insertar ítem" (click)="insertarProducto()"
            [ngStyle]="{display: loaderData? 'none': ''}">
            <mat-icon svgIcon="add-outline"></mat-icon>
          </div>
          <p *ngIf="isMobile || isTablet" [ngStyle]="{margin: '0px', lineHeight: '10px'}">
            <span [ngStyle]="{color: 'white', fontSize: '8px', lineHeight: '10px'}">Insertar</span>
          </p>
        </div>

      </div>

      <div class="mat-elevation-z0 overflow-x">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
            <td mat-cell *matCellDef="let row" data-label="Acciones">
              <div class="pavso-container-action">
                <button type="button" matTooltip="Editar producto" (click)="editarProducto(row)" mat-icon-button
                  color="primary">
                  <mat-icon>edit</mat-icon>
                </button>
                <button type="button" matTooltip="Eliminar producto" (click)="eliminarProducto(row)" mat-icon-button
                  color="warn">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="campo1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> item </th>
            <td mat-cell *matCellDef="let row; let i = index" data-label="item"> {{i + 1}} </td>
          </ng-container>

          <ng-container matColumnDef="campo2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
            <td mat-cell *matCellDef="let row" data-label="Código">
              {{row.PRD_CODPRD}}
            </td>
          </ng-container>

          <ng-container matColumnDef="campo3">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Sec. </th>
            <td mat-cell *matCellDef="let row" data-label="Sec."> {{row.DCD_SECDCD}}</td>
          </ng-container>

          <ng-container matColumnDef="campo4">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
            <td mat-cell *matCellDef="let row" data-label="Descripción">
              <div class="pavso-container-description">
                {{row.prd_desesp}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="campo5">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Prom. </th>
            <td mat-cell *matCellDef="let row" data-label="Prom."> {{row.PRM_CODPRM}} </td>
          </ng-container>

          <ng-container matColumnDef="campo6">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. </th>
            <td mat-cell *matCellDef="let row" data-label="U.M."> {{row.UME_CODVEN}} </td>
          </ng-container>

          <ng-container matColumnDef="campo7">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
            <td mat-cell *matCellDef="let row" data-label="Cantidad"> {{row.DCD_CANVEN}}</td>
          </ng-container>

          <ng-container matColumnDef="campo8">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio </th>
            <td mat-cell *matCellDef="let row" data-label="Precio"> {{row.DCD_PREUNI}} </td>
          </ng-container>

          <ng-container matColumnDef="campo9">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Precio inc. IGV </th>
            <td mat-cell *matCellDef="let row" data-label="Precio inc. IGV"> {{row.DCD_PRUIGV}} </td>
          </ng-container>

          <ng-container matColumnDef="campo10">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> U.M. Venta </th>
            <td mat-cell *matCellDef="let row" data-label="U.M. Venta"> {{row.UME_CODVEN}} </td>
          </ng-container>

          <ng-container matColumnDef="campo11">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad Venta </th>
            <td mat-cell *matCellDef="let row" data-label="Cantidad Venta"> {{row.DCD_CANVEN}} </td>
          </ng-container>

          <ng-container matColumnDef="campo12">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Bruto </th>
            <td mat-cell *matCellDef="let row" data-label="Importe Bruto"> {{row.DCD_IMPBRU}} </td>
          </ng-container>

          <ng-container matColumnDef="campo13">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> % Desc. </th>
            <td mat-cell *matCellDef="let row" data-label="% Desc."> {{row.DCD_PORDES}} % </td>
          </ng-container>

          <ng-container matColumnDef="campo14">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Desc. </th>
            <td mat-cell *matCellDef="let row" data-label="Importe Desc."> {{row.DCD_IMPDES}} </td>
          </ng-container>

          <ng-container matColumnDef="campo15">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor venta </th>
            <td mat-cell *matCellDef="let row" data-label="Valor venta"> {{row.DCD_VALVTA}} </td>
          </ng-container>

          <ng-container matColumnDef="campo16">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. IGV. </th>
            <td mat-cell *matCellDef="let row" data-label="Imp. IGV."> {{row.DCD_IMPIGV}} </td>
          </ng-container>

          <ng-container matColumnDef="campo17">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp. Total </th>
            <td mat-cell *matCellDef="let row" data-label="Imp. Total"> {{row.DCD_IMPTOT}} </td>
          </ng-container>

          <ng-container matColumnDef="campo19">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Glosa </th>
            <td mat-cell *matCellDef="let row" data-label="Glosa"> {{row.PCD_GLOPCD}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="20" *ngIf="!loaderProductos">No se encontraron registros</td>

          </tr>

        </table>

      </div>
      <mat-paginator class="mat-elevation-z0" itemsPerPageLabel="Items por página"
        [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      <br>
      <mat-card class="mat-elevation-z0">
        <div class="row">
          <div class="col s12 m12 l4 xl3">
            <div class="pavso_cobranza">
              <button  class="pavso_boton_cobranza" mat-flat-button color="primary"
                aria-label="Pagos" type="button" (click)="cobranza()">
                <mat-icon>local_atm</mat-icon>&nbsp;COBRANZA
              </button>
            </div>
            <mat-form-field class="example-full-width">
              <mat-label>Obervación</mat-label>
              <textarea  matInput placeholder="Ex. It makes me feel..."
                [(ngModel)]="voucher.CCO_GLOCCO" name="CCO_GLOCCO"></textarea>
            </mat-form-field>

          </div>

          <div class="col s12 m12 l8 xl9">
            <div class="row">

              <div class="col s12 m12 l4 xl2">
                <mat-form-field class="example-full-width">
                  <mat-label>Ord. Compra</mat-label>
                  <input type="text" matInput placeholder="Ord. Compra" [(ngModel)]="voucher.detalle_dcc[0].DCC_ORDCOM"
                    name="DCC_ORDCOM" >
                </mat-form-field>
              </div>

              <div class="col s12 m12 l4 xl2">
                <mat-form-field class="example-full-width">
                  <mat-label>Descuento</mat-label>
                  <input type="number" matInput placeholder="Descuento" [(ngModel)]="voucher.detalle_dcc[0].DCC_IMPDES"
                    name="DCC_IMPDES" step="any" [ngStyle]="{textAlign: 'right'}">
                </mat-form-field>
              </div>

              <div class="col s12 m12 l4 xl2">
                <mat-form-field class="example-full-width">
                  <mat-label>Valor venta</mat-label>
                  <input type="number" matInput placeholder="Valor venta" [(ngModel)]="voucher.detalle_dcc[0].DCC_VALVTA"
                    name="DCC_VALVTA" step="any" [ngStyle]="{textAlign: 'right'}">
                </mat-form-field>
              </div>

              <div class="col s12 m12 l4 xl2">
                <mat-form-field class="example-full-width">
                  <mat-label>IGV</mat-label>
                  <input type="number" matInput placeholder="IGV" [(ngModel)]="voucher.CCO_IMPIGV" name="CCO_IMPIGV"
                    step="any" [ngStyle]="{textAlign: 'right'}">
                </mat-form-field>
              </div>

              <div class="col s12 m12 l4 xl2">
                <mat-form-field class="example-full-width">
                  <mat-label>Imp. Bruto</mat-label>
                  <input type="number" matInput placeholder="Imp. Bruto" [(ngModel)]="voucher.detalle_dcc[0].DCC_IMPBRU"
                    name="DCC_IMPBRU" step="any" [ngStyle]="{textAlign: 'right'}">
                </mat-form-field>
              </div>

              <div class="col s12 m12 l4 xl2">
                <mat-form-field class="example-full-width">
                  <mat-label>Total</mat-label>
                  <input type="number" matInput placeholder="Total" [(ngModel)]="voucher.CCO_IMPDOC" name="CCO_IMPDOC"
                    step="any" [ngStyle]="{textAlign: 'right'}">
                </mat-form-field>
              </div>


              <div class="col s12 m12 l6">
                <mat-form-field class="example-full-width">
                  <mat-label>MONTO EN DOLARES (US$)</mat-label>
                  <input type="number" step="any" disabled matInput placeholder="MONTO EN DOLARES (US$)"
                    [(ngModel)]="montoDolares" name="montoDolares" [ngStyle]="{textAlign: 'right'}">
                </mat-form-field>
              </div>

              <div class="col s12 m12 l6">
                <mat-form-field class="example-full-width">
                  <mat-label>MONTO EN SOLES (S/.)</mat-label>
                  <input type="number" step="any" disabled matInput placeholder="MONTO EN SOLES (S/.)"
                    [(ngModel)]="montoSoles" name="montoSoles" [ngStyle]="{textAlign: 'right'}">
                </mat-form-field>
              </div>

              <div class="col s12 m12 l4 xl3">
                <mat-form-field class="example-full-width">
                  <mat-label>Abonado</mat-label>
                  <input type="number" matInput placeholder="Abonado" disabled step="any"
                    [(ngModel)]="voucher.detalle_dcc[0].DCC_IMPABO" name="DCC_IMPABO" [ngStyle]="{textAlign: 'right'}">
                </mat-form-field>
              </div>
              <div class="col s12 m12 l4 xl3">
                <mat-form-field class="example-full-width">
                  <mat-label>Vuelto</mat-label>
                  <input type="number" matInput placeholder="Vuelto" disabled step="any"
                    [(ngModel)]="voucher.detalle_dcc[0].DCC_IMPVUE" name="DCC_IMPVUE" [ngStyle]="{textAlign: 'right'}">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

    </div>

    <pav-form-actions [disabled]="loaderData" [btnName]="btnName" [isLoading]="loaderReg" urlBack="/modulo-ventas/comprobantes-de-venta">
      <div id="botonesAdicionales" >

      </div>
    </pav-form-actions>
  </form>

</app-pavso-title-section>
