import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { unsubscribeSubscription } from '@utils/others/subscription';
import { Subscription } from 'rxjs';
import { Program } from 'src/app/models/program';
import { ClienteService } from 'src/app/services/api/ventas/maestros/clientes.service';
import { ProductoService } from 'src/app/services/api/ventas/maestros/producto.service';

@Component({
  selector: 'pav-search-dialog',
  templateUrl: 'pav-search.dialog.html'
})

export class PavSearchDialog implements OnInit, OnDestroy {

  listado$: Subscription;

  listado: any[] = [];

  input: string;

  constructor(
    public dialogRef: MatDialogRef<PavSearchDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly _clienteService: ClienteService,
    private readonly _productoService: ProductoService
  ) { }

  ngOnInit() { }

  cerrar(): void {
    this.dialogRef.close();
  }

  keyup(): void {

    if(this.data.program == Program.CLIENTES) {
      this.listado$ = this._clienteService.obtenerClientes({params: [{param: 'buscar', value: this.input}, {param: 'limite', value: 6}]}).subscribe(
        clientes => {
          this.listado = clientes;
        },
        error => {
          console.log(error);
        }
      );
    }

    if(this.data.program == Program.PRODUCTOS) {
      this.listado$ = this._productoService.obtenerProductos({params: [{param: 'buscar', value: this.input}, {param: 'limite', value: 6}]}).subscribe(
        productos => {
          this.listado = productos;
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  seleccionar(item: any): void {
    console.log(item)
    this.dialogRef.close(item);
  }



  ngOnDestroy(): void {
    unsubscribeSubscription([
      this.listado$
    ])
  }
}
