<app-pavso-title-section [title]="'Monitoreo de boleta'" [module]="'Ventas'"></app-pavso-title-section>

<div class="pavso-content-form fade-in-image">
  <mat-progress-bar mode="indeterminate" *ngIf="loaderData"></mat-progress-bar>

  <form #f="ngForm" ngNativeValidate (ngSubmit)="buscarComprobante()" (keydown.enter)="$event.preventDefault()">
    <div class="row">
      <div class="col s9">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12 m12 l4">
              <div [ngStyle]="{display: 'flex'}">
                <mat-checkbox class="example-margin" [(ngModel)]="enableRange" name="enableRange">
                </mat-checkbox>
                <mat-form-field>
                  <mat-label>Desde</mat-label>
                  <input matInput [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

            <div class="col s12 m12 l3">
              <select-search [label]="'Tienda'" [placeholder]="'Ej. LIMA'" [name]="'tie_codtie'" [value]="'tie_codtie'"
                [description]="'tie_destie'" [data]="tiendas" [model]="filtro.tienda"
                (cambioSelect)="filtro.tienda = $event">
              </select-search>

            </div>

            <div class="col s12 m12 l3">
              <select-search [label]="'Tipo de documento'" [placeholder]="'Ej. FAC'" [name]="'tdo_codtdo'"
                [value]="'tdo_codtdo'" [description]="'tdo_deslar'" [data]="tiposDocumento"
                [model]="filtro.tipodocumento" (cambioSelect)="filtro.tipodocumento = $event">
              </select-search>

            </div>

            <div class="col s12 m12 l2">
              <mat-form-field>
                <mat-label>Nro</mat-label>
                <input matInput placeholder="Nro" [(ngModel)]="filtro.nrodocumento" name="nrodocumento" />
              </mat-form-field>
            </div>

            <div class="col s12 m12 l12" align="center">
              <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
                <mat-icon>search</mat-icon>
              </button>
            </div>
          </div>


        </mat-card>
      </div>
      <div class="col s3">
        <mat-card class="mat-elevation-z0">
          <div class="row">

            <div class="col s12">
              <div [ngStyle]="{display: 'flex', alignItems: 'center'}">
                <h1>Certificado Digital</h1>
                <div class="pav-separator"></div>
                <span [ngStyle]="{color: 'green'}">Vigente</span>

              </div>
              <div class="row">
                <div class="col s12">
                  <mat-form-field class="pav-date-input">
                    <mat-label>Fecha Apertura</mat-label>
                    <input matInput [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col s12">
                  <mat-form-field class="pav-date-input">
                    <mat-label>Fecha Finalización</mat-label>
                    <input matInput [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>


          </div>
        </mat-card>
      </div>
    </div>
    <br />

    <div>
      <div class="mat-elevation-z0">
        <div class="pav-table-header-container">
          <div class="pav-table-header-icon"></div>
          <div class="pav-table-header-title">Comprobantes</div>
          <div class="pav-table-search">
            <input type="text" (keyup)="applyFilter($event)" [placeholder]="LABELS_NAME.BTN_SEARCH" #input />
          </div>
          <!-- <div class="pav-table-header-add">
            <button (click)="seleccionarTipo()" mat-flat-button class="pav-button-add" color="primary" type="button">
              <mat-icon>add</mat-icon>
              {{ LABELS_NAME.BTN_ADD }}
            </button>
          </div> -->
        </div>

        <mat-progress-bar *ngIf="loaderMonitoreo" mode="indeterminate"></mat-progress-bar>

        <div class="overflow-x">
          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                              [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)"
                              [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 170px !important;">Acciones</th>
              <td mat-cell *matCellDef="let row" [style.color]="row.color" data-label="Acciones">

                <button type="button" [matMenuTriggerFor]="menu" matTooltip="Mas Opciones" mat-icon-button
                  color="primary">
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <button mat-menu-item>Libera documentos</button>
                  <button mat-menu-item>Actualiza motivo de baja SUNAT</button>
                  <button mat-menu-item>Consultar GetStatusCDR</button>
                  <button mat-menu-item>Mostrar PDF</button>
                </mat-menu>

              </td>
            </ng-container>

            <ng-container matColumnDef="cco_codcco">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Cuenta Corriente
              </th>
              <td mat-cell *matCellDef="let row" data-label="Cuenta Corriente">
                {{ row.cco_codcco }}
              </td>
            </ng-container>

            <ng-container matColumnDef="cco_fecemi">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
              <td mat-cell *matCellDef="let row" data-label="Fecha">
                {{ row.cco_fecemi | date: 'dd/MM/yyyy': 'UTC' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="select_email">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggleEmail() : null"
                              [checked]="selectionEmail.hasValue() && isAllSelectedEmail()"
                              [indeterminate]="selectionEmail.hasValue() && !isAllSelectedEmail()"
                              [aria-label]="checkboxLabelEmail()" color="warn">
                              Envio correo
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selectionEmail.toggle(row) : null"
                              [checked]="selectionEmail.isSelected(row)"
                              [aria-label]="checkboxLabelEmail(row)" color="warn">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="tdo_codtdo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Doc.</th>
              <td mat-cell *matCellDef="let row" data-label="Doc.">
                {{ row.tdo_codtdo }}
              </td>
            </ng-container>

            <ng-container matColumnDef="cco_numdoc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Num. Doc.</th>
              <td mat-cell *matCellDef="let row" data-label="Num. Doc.">
                {{ row.cco_numdoc }}
              </td>
            </ng-container>

            <ng-container matColumnDef="TMO_CODTMO">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Moneda</th>
              <td mat-cell *matCellDef="let row" data-label="Moneda">
                {{ row.TMO_CODTMO }}
              </td>
            </ng-container>

            <ng-container matColumnDef="cco_impdoc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Importe</th>
              <td mat-cell *matCellDef="let row" data-label="Importe">
                {{ row.cco_impdoc.toFixed(2) }}
              </td>
            </ng-container>

            <ng-container matColumnDef="cli_numruc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>RUC</th>
              <td mat-cell *matCellDef="let row" data-label="RUC">
                {{ row.cli_numruc }}
              </td>
            </ng-container>

            <ng-container matColumnDef="cli_nomcli">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Nombre Cliente
              </th>
              <td mat-cell *matCellDef="let row" data-label="Nombre Cliente">
                {{ row.cli_nomcli }}
              </td>
            </ng-container>

            <ng-container matColumnDef="rfe_estado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Estado
              </th>
              <td mat-cell *matCellDef="let row" data-label="Estado">
                {{ row.rfe_estado }}
              </td>
            </ng-container>

            <ng-container matColumnDef="rfe_descri">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Texto
              </th>
              <td mat-cell *matCellDef="let row" data-label="Texto">
                {{ row.rfe_descri }}
              </td>
            </ng-container>

            <ng-container matColumnDef="cco_code">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                cco_code
              </th>
              <td mat-cell *matCellDef="let row" data-label="cco_code">
                {{ row.cco_code }}
              </td>
            </ng-container>

            <ng-container matColumnDef="cco_condic">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                cco_condic
              </th>
              <td mat-cell *matCellDef="let row" data-label="cco_condic">
                {{ row.cco_condic }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="14">
                No se encontraron registros "{{ input.value }}"
              </td>
            </tr>
          </table>
        </div>

        <mat-paginator #paginatorMonitoreo showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>

    <br />

    <div class="row">
      <div class="col s12 m12 l7">
        <div class="mat-elevation-z0">
          <div class="pav-table-header-container">
            <div class="pav-table-header-icon"></div>
            <div class="pav-table-header-title">Documentos</div>
            <div class="pav-table-header-add"></div>
          </div>

          <mat-progress-bar mode="indeterminate" *ngIf="loaderDataDetalle"></mat-progress-bar>

          <div class="overflow-x">
            <table mat-table [dataSource]="dataSourceArchivo" matSort>
              <ng-container matColumnDef="rfe_estado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
                <td mat-cell *matCellDef="let row" data-label="Estado">
                  {{ row.rfe_estado }}
                </td>
              </ng-container>

              <ng-container matColumnDef="rfe_descri">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Texto</th>
                <td mat-cell *matCellDef="let row" data-label="Texto">
                  {{ row.rfe_descri }}
                </td>
              </ng-container>

              <ng-container matColumnDef="rfe_coduse">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
                <td mat-cell *matCellDef="let row" data-label="Usuario">
                  {{ row.rfe_coduse }}
                </td>
              </ng-container>

              <ng-container matColumnDef="rfe_fecupd">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Actualizacion
                </th>
                <td mat-cell *matCellDef="let row" data-label="Actualizacion">
                  {{ row.rfe_fecupd | date: 'dd/MM/yyyy': 'UTC' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="rfe_nomarc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Archivo</th>
                <td mat-cell *matCellDef="let row" data-label="Archivo">
                  {{ row.rfe_nomarc }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsArchivo"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsArchivo"></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9">
                  No se encontraron registros "{{ input.value }}"
                </td>
              </tr>
            </table>
          </div>

          <mat-paginator #paginatorArchivo showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>

      </div>
      <div class="col s12 m12 l5">
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12">
              <button mat-stroked-button color="primary" type="button">
                (1) Resumen Diario
              </button>
              &nbsp;
              <button mat-stroked-button color="primary" type="button">
                (2) Enviar SUNAT
              </button>
              &nbsp;
            </div>

          </div>
        </mat-card>
        <br>
        <mat-card class="mat-elevation-z0">
          <div class="row">
            <div class="col s12">
              <button mat-stroked-button color="accent" type="button">
                Enviar Correo
              </button>
              &nbsp;
              <button mat-stroked-button color="accent" type="button">
                Descargar
              </button>
            </div>
          </div>

        </mat-card>
        <br>
        <mat-card class="mat-elevation-z0">
          <mat-checkbox class="example-margin">Generar solo anulados</mat-checkbox>
          &nbsp;
          <mat-checkbox class="example-margin">Modificar comprobante</mat-checkbox>

        </mat-card>
      </div>

    </div>

    <pav-form-actions [disabled]="loaderData" [btnName]="'Grabar'" [isLoading]="loaderReg"
      urlBack="/modulo-compras/solicitudes-de-compra">
      <div id="botonesAdicionales">
        <button class="pav-btn-action" #btnGeneraAsiento color="primary" matTooltip="Consultar ticket" type="button"
          (click)="consultarTicket()">
          <mat-icon class="pav-icon-btn">article</mat-icon>
        </button>

      </div>
    </pav-form-actions>
  </form>

</div>
